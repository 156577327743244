import { Button, Input } from "antd";
import { useState } from "react";

export default function ManifestScanForm({ callScanManifests, buttonLoading }) {

    const [ manifestCode, SetManifestCode ] = useState(undefined);

    return (
        <div className="flex-box flex-gap-m align-items-center spacer-m white-bg">
            <Input onChange={e => SetManifestCode(e.target.value)} value={manifestCode}
             suffix={(
                <Button icon="barcode" type="primary" className="lsn-btn-primary"
                 style={{ borderRadius: "0px 3px 3px 0px", marginRight: -15 }}
                 loading={buttonLoading}
                 onClick={_ => callScanManifests(manifestCode)}> 
                    Scan
                </Button>
             )}
             onPressEnter={_ => callScanManifests(manifestCode)}
             placeholder="Scan Manifest ID and start unloading"
            />
        </div>
    )
}