import React, { useEffect, useState } from "react";
import formSchema from './ConnectionUpdateFormSchema';
import '../form.scss';
import FormWidgets from '../../../ui/formComponent/formWidgets';
import FormWrapper from '../../../ui/formComponent/form';
import LocationService from "../../../../services/settings/location.service";
import { notifyApiSuccess } from "../../../../common/utils/utils";
import CustomArrayFieldTemplate from '../components/CustomArrayField/CustomArrayFieldTemplate';
import { useParams } from "react-router-dom";

const ConnectionUpdateForm = ({ locationDetails, onSubmitSuccess, existingConnectionDetails }) => {

    let formSchemaCopy = JSON.parse(JSON.stringify(formSchema))

    const params = useParams();
    const locationId = params?.id

    const locationService = new LocationService
    const [formSchemaValue, setFormSchemaValue] = useState(null);
    const [formData, setFormData] = useState({});
    const [locId, setLocId] = useState(null);

    useEffect(() => {

        const locationType = ["FM", "SC"].includes(locationDetails.opsType) ? "origin" : "destination";

        formSchemaCopy.schema.properties.connectionDetails.properties.new.items.properties[locationType].properties.name["default"] = locationDetails?.name
        formSchemaCopy.uiSchema.connectionDetails.new.items[locationType].name["ui:readOnly"] = true;

        if (locationId && existingConnectionDetails && existingConnectionDetails.length > 0) {
            initialProcessingForUpdateFlow()
        } else {
            initialProcessingForCreateFlow()
        }

    }, [])

    const initialProcessingForCreateFlow = () => {
        setLocId(locationDetails?.id)
        setFormSchemaValue(formSchemaCopy)
    }

    const initialProcessingForUpdateFlow = () => {

        setLocId(locationId)

        const existingData = {
            "title": "Existing Connection Details",
            "type": "array",
            "items": {
                "required": ["origin", "destination"],
                "properties": {
                    "origin": {
                        "title": "",
                        "required": ["name"],
                        "properties": {
                            "name": {
                                "title": "Origin",
                                "type": "string"
                            },
                            "type": {
                                "title": "Origin Type",
                                "type": "string",
                                "enum": ["FM", "LM", "FMSC", "LMSC"],
                                "enumNames": ["FM", "LM", "FMSC", "LMSC"]
                            }
                        }
                    },
                    "destination": {
                        "title": "",
                        "required": ["name"],
                        "properties": {
                            "name": {
                                "title": "Destination",
                                "type": "string"
                            },
                            "type": {
                                "title": "Destination Type",
                                "type": "string",
                                "enum": ["FM", "LM", "FMSC", "LMSC"],
                                "enumNames": ["FM", "LM", "FMSC", "LMSC"]
                            }
                        }
                    },
                    "crossDock": {
                        "title": "",
                        "properties": {
                            "name": {
                                "title": "Next Cross Dock",
                                "type": "string"
                            },
                            "type": {
                                "title": "Cross Dock Type",
                                "type": "string",
                                "enum": ["FM", "LM", "FMSC", "LMSC"],
                                "enumNames": ["FM", "LM", "FMSC", "LMSC"]
                            }
                        }
                    }
                }
            }
        }

        formSchemaCopy.schema.properties.connectionDetails.title = ""
        formSchemaCopy.schema.properties.connectionDetails.properties = {
            "existing": existingData,
            ...formSchemaCopy.schema.properties.connectionDetails.properties
        }
        formSchemaCopy.schema.properties.connectionDetails.properties.new.title = "New Connection Details"

        const formDataDetails = {
            connectionDetails: {
                existing: []
            }
        }

        existingConnectionDetails.map((conDetails) => {
            const conData = {
                origin: {
                    name: conDetails.origin.locationName
                },
                destination: {
                    name: conDetails.destination.locationName
                }
            }

            if (conDetails.origin.locationOpsType) {
                conData['origin']['type'] = conDetails.origin.locationOpsType
            }

            if (conDetails.destination.locationOpsType) {
                conData['destination']['type'] = conDetails.destination.locationOpsType
            }

            if (conDetails.crossDock?.locationName) {
                conData["crossDock"] = {
                    name: conDetails.crossDock.locationName,
                }

                if (conDetails.crossDock.locationOpsType) {
                    conData["crossDock"]['type'] = conDetails.crossDock.locationOpsType
                }
            }

            formDataDetails.connectionDetails.existing.push(conData)
        })

        setFormData(formDataDetails)
        setFormSchemaValue(formSchemaCopy)
    }

    const validate = (formDataDetails, errors) => {

        const nameRegex = /^(?=[a-zA-Z])[a-zA-Z\s0-9()\/_-]{3,30}$/;

        if (formDataDetails.connectionDetails.existing?.length > 0) {

            formDataDetails.connectionDetails.existing.map((connectionData, index) => {

                if (connectionData?.origin?.name &&
                    !nameRegex.test(connectionData.origin.name)) {
                    errors.connectionDetails.existing[index].origin.name.addError("Invalid origin name")
                }

                if (connectionData?.destination?.name &&
                    !nameRegex.test(connectionData.destination.name)) {
                    errors.connectionDetails.existing[index].destination.name.addError("Invalid destination name")
                }

                if (connectionData?.crossDock?.name &&
                    !nameRegex.test(connectionData.crossDock.name)) {
                    errors.connectionDetails.existing[index].crossDock.name.addError("Invalid destination name")
                }

            })
        }

        if (formDataDetails.connectionDetails.new?.length > 0) {
            formDataDetails.connectionDetails.new.map((connectionData, index) => {

                if (connectionData?.origin?.name &&
                    !nameRegex.test(connectionData.origin.name.trim())) {
                    errors.connectionDetails.new[index].origin.name.addError("Invalid origin name")
                }

                if (connectionData?.destination?.name &&
                    !nameRegex.test(connectionData.destination.name.trim())) {
                    errors.connectionDetails.new[index].destination.name.addError("Invalid destination name")
                }

                if (connectionData?.crossDock?.name &&
                    !nameRegex.test(connectionData.crossDock.name.trim())) {
                    errors.connectionDetails.new[index].crossDock.name.addError("Invalid destination name")
                }

            })
        }

        return errors
    }

    const onSubmit = fromDataSet => {

        if ((!locationId && !fromDataSet.connectionDetails?.new?.length && !fromDataSet.connectionDetails?.new?.length) ||
            (locationId && !formData?.connectionDetails?.existing?.length &&
                !fromDataSet.connectionDetails?.new?.length && !fromDataSet.connectionDetails?.existing?.length)) {
            return
        }

        const reqBody = {}
        const connectionData = []

        if (fromDataSet.connectionDetails.existing?.length > 0) {
            fromDataSet.connectionDetails.existing.map((connection) => {

                const conReq = {
                    originLocation: {
                        name: connection.origin.name,
                        locType: connection.origin.type
                    },
                    destinationLocation: {
                        name: connection.destination.name,
                        locType: connection.destination.type
                    }
                }

                if (connection.crossDock?.name) {
                    conReq['nextCrossDock'] = {
                        name: connection.crossDock.name,
                        locType: connection.crossDock.type
                    }
                }

                connectionData.push(conReq)
            })
        }

        if (fromDataSet.connectionDetails.new?.length > 0) {
            fromDataSet.connectionDetails.new.map((connection) => {

                const conReq = {
                    originLocation: {
                        name: connection.origin.name.trim(),
                        locType: connection.origin.type
                    },
                    destinationLocation: {
                        name: connection.destination.name.trim(),
                        locType: connection.destination.type
                    }
                }

                if (connection.crossDock?.name) {
                    conReq['nextCrossDock'] = {
                        name: connection.crossDock.name.trim(),
                        locType: connection.crossDock.type
                    }
                }

                connectionData.push(conReq)
            })
        }

        reqBody['connectionDetails'] = connectionData

        locationService.updateLocConnectionDetails(reqBody, locId)
            .then(resp => {
                notifyApiSuccess("Connection updated successfully")
                onSubmitSuccess()
            })
            .catch(error => {
                console.error(error)
                setFormData(fromDataSet)
            });
    }

    return (
        <>
            <div className="connection-info-text">Updating connection details for location <b>{locationDetails?.name}</b></div>
            <div className='flex-box connection-form' style={{ padding: "20px" }}>
                {formSchemaValue &&
                    <FormWrapper {...formSchemaValue} validate={validate}
                        onSubmit={onSubmit} widgets={FormWidgets} formData={formData}
                        ArrayFieldTemplate={CustomArrayFieldTemplate}
                    />
                }
            </div>
        </>
    )

}

export default ConnectionUpdateForm;