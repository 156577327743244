import { useState } from "react";
import "./pod.timeline.scss";
import { Divider, Icon } from "antd";
import moment from "moment";

export default function PodTimeline({ drs }) {

    var consignments = [...drs.consignments].sort((a, b) => {
        if (!a.consignment.updatedAt) return 1;
        if (!b.consignment.updatedAt) return -1;
        return (a.consignment.updatedAt - b.consignment.updatedAt);
    });

    var attemptedCount = drs.waybillCount - drs.unAttemptedCount;
    var gradientPercentage = (100.0 * attemptedCount/drs.waybillCount);

    var gradientColor = `linear-gradient(to right, #50b154 ${gradientPercentage}%, #F28827,  #F22735)`
    if (attemptedCount == 0) {
        gradientColor = `linear-gradient(to right, #F28827,  #F22735)`
    }

    var lastPodCreatedIndex = -1;
    for (let i = 0; i < consignments.length; i++) {
        if (consignments[i]?.consignment?.podStatus === undefined) {
            lastPodCreatedIndex = i - 1;
            break;
        }
    }
    
    var stepFactor = (100 / (drs.waybillCount > 1 ? drs.waybillCount - 1 : 1));

    var riderPosition = ((lastPodCreatedIndex + 0.5) * stepFactor);

    if (lastPodCreatedIndex == -1) {
        riderPosition = 0;
    }

    const [ selectedConsignment, SetSelectedConsignment ] = useState(undefined);

    const handleClick = consignment => SetSelectedConsignment(consignment.consignment);

    return (
        <div className="flex-column flex-gap-xl spacer-l">
            <div className="flex-box flex-gap-m align-items-center font-size-l-1 text-semi-bold" style={{ margin : "-16px 16px 16px -16px" }}> 
                <div>DRS Summary</div>
                <span className="light-grey-text font-size-sm-2">(Attempted/Total)</span> : 
                <div>{attemptedCount}/{drs.waybillCount} </div>
            </div>
            <div className="timeline-line" style={{
                background : gradientColor,
                backgroundColor : "white"
            }}>
                {/* {
                    riderPosition > 0 &&
                    <div className="rider-element" style={{ left : `${riderPosition}%`}}></div>
                } */}
                <div className="timeline-elements-wrapper">
                    {
                        consignments.map(c => {
                            var elemClassName = "timeline-element ";
                            if (c.consignment.updatedAt && c.consignment.podStatus) {
                                if (c.consignment.podStatus == "DEL") {
                                    elemClassName += "success-element";
                                }
                                else if (c.consignment.podStatus == "UNDEL") {
                                    elemClassName += "error-element";
                                }
                            }
                            else {
                                elemClassName += "warning-element";
                            }
                            if (selectedConsignment?.waybillNo == c.consignment.waybillNo) {
                                elemClassName += " selected-element";
                            }
                            return (
                                <div className={elemClassName} onClick={_ => handleClick(c)}>
                                    {
                                        c.consignment.updatedAt &&
                                        <div className="timeline-content text-bold">
                                            {
                                                moment(c.consignment.updatedAt).startOf("date").valueOf() != moment(c.consignment.updatedAt).startOf("date").valueOf() &&
                                                <div> {moment(c.consignment.updatedAt).format("DD/MM/YYYY")} </div>
                                            }
                                            <div> {moment(c.consignment.updatedAt).format("hh:mm:ss A")} </div>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {
                selectedConsignment != undefined &&
                <div className="flex-column timeline-selected-element border-grey grey-bg spacer-vm border-radius-m">
                    <div className="flex-box flex-gap-m justify-content-space-between spacer-hm">
                        <div className="font-size-l-1 text-bold"> Selected Consignment Details </div>
                        <Icon type="close" onClick={_ => SetSelectedConsignment(undefined)} />
                    </div>
                    <Divider/>
                    <div className="flex-box flex-gap-m justify-content-space-between spacer-hm">
                        <div className="flex-column flex-gap-m">
                            <div> Waybill Number </div>
                            <div className="font-size-m-3 text-semi-bold"> {selectedConsignment.waybillNo} </div>
                        </div>
                        <div className="flex-column flex-gap-m">
                            <div> Linked Time </div>
                            <div className="font-size-m-3 text-semi-bold"> {moment(selectedConsignment.createdAt).format("DD/MM/YYYY hh:mm A")} </div>
                        </div>
                        {
                            selectedConsignment.updatedAt &&
                            <div className="flex-column flex-gap-m">
                                <div> POD Created Time </div>
                                <div className="font-size-m-3 text-semi-bold"> {moment(selectedConsignment.updatedAt).format("DD/MM/YYYY hh:mm A")} </div>
                            </div>
                        }
                        <div className="flex-column flex-gap-m">
                            <div> Status </div>
                            <div className={"font-size-m-3 text-semi-bold " +
                                (selectedConsignment.podStatus == "DEL" ? "success-color" : selectedConsignment.podStatus == "UNDEL" ? "error-color" : "warning-color")}> 
                                {
                                    selectedConsignment.podStatus == "DEL" ? "Deliverd" : 
                                    selectedConsignment.podStatus == "UNDEL" ? "Underliverd" : 
                                    (selectedConsignment.podStatus || selectedConsignment.status) == "OFD" ? "Out for Delivery" :
                                    (selectedConsignment.podStatus || selectedConsignment.status)
                                }
                            </div>
                        </div>
                        <div className="flex-column flex-gap-m">
                            <div> Attempt Number </div>
                            <div className="font-size-m-3 text-semi-bold">
                                {selectedConsignment.numberOfAttempts !== undefined ? selectedConsignment.numberOfAttempts + 1 : 1}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}