import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { checkForDuplicateErrors, schemaToAttrs } from "../form.utils";
import { fetchReportType } from "../../../reportsv2/services/fetch.services";

export default function ReportTypes(props) {

    const [ options, SetOptions ] = useState([]);
    const [ value, SetValue ] = useState(props.value);
    const [ loading, SetLoading ] = useState(true);

    const uiAttrs = schemaToAttrs(props.uiSchema, props.readonly);

    const disabled = props.disabled || props.readonly || uiAttrs.disabled;
    delete uiAttrs.disabled;

    useEffect(() => {
        fetchReportType(true)
        .then(reportTypes => {
            const tempOptions = [];
            reportTypes.map(type => {
                tempOptions.push({
                    value   : type.code,
                    label   : type.label
                })
            });
            SetOptions(tempOptions)
            SetValue(props.value);
            SetLoading(false);
        })
    }, [])

    useEffect(() => {
        SetValue(props.value);
    }, [ props.value ])

    return (
        <div className={"input-wrapper flex-column"}>
            {
                loading ?
                <Input className="input-loading"/> :
                <Select 
                    onChange={value => {
                        SetValue(value);
                        props.onChange(value)
                    }} showSearch={true}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0}
                    value={value} disabled={disabled} className={uiAttrs.className}
                    {...uiAttrs} notFoundContent={"No report type exist!"}
                >
                    {
                        options?.length > 0 &&
                        options?.map(option => {
                            if (option.value) {
                                return (
                                    <Select.Option value={option.value} key={option.value}>
                                        {option.label}
                                    </Select.Option>
                                )
                            }
                        })
                    }
                </Select>
            }
            {
                props.rawErrors?.length > 0 &&
                <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
            }
        </div>
    )
}