export const ACTION_TYPES = {
    UPDATE_SCAN_LISTS : "UPDATE_SCAN_LISTS",
    UPDATE_PENDING_SCAN_LIST : "UPDATE_PENDING_SCAN_LIST",
    UPDATE_SCANNED_SCAN_LIST : "UPDATE_SCANNED_SCAN_LIST"
}

const INITIAL_STATE = {}

export const ScanListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_SCAN_LISTS         : return updateScanLists(state, action)
        case ACTION_TYPES.UPDATE_PENDING_SCAN_LIST  : return updatePendingScanLists(state, action)
        case ACTION_TYPES.UPDATE_SCANNED_SCAN_LIST  : return updateScannedScanLists(state, action)
        default  : return state;
    }
}

const updateScanLists = (state, action) => {
    if (action.listId) {
        return {
            ...state,
                [action.listId] : {
                pendingList : action.pendingList ? [...action.pendingList] : [],
                scannedList : action.scannedList ? [...action.scannedList] : []
            }
        }
    }
    return state
}

const updatePendingScanLists = (state, action) => {
    return {
        ...state,
        [action.listId] : {
            ...state[action.listId],
            pendingList : action.payload
        }
    }
}

const updateScannedScanLists = (state, action) => {
    return {
        ...state,
        [action.listId] : {
            ...state[action.listId],
            scannedList : action.payload
        }
    }
}