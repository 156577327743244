import React from "react";
import { Button } from "antd";
import DataCard from "../../../shared-components/data-card/DataCard";

const RTODRSSparkLine = ({data, createRTODRS}) => {
    return(
        <div className="legacy-sparkline">
            <div className="legacy-sparkline-datacards">
                {
                    data && data.length > 0 ? data.map((item, index) => {
                        return (
                            <div key={`data-card-list-${index}`}>
                                <DataCard {...item}/>
                            </div>
                        )
                    }) : null
                }
            </div>
            <div className="legacy-sparkline-ctas">
                <Button icon="plus" className="lsn-btn-primary" type="primary" size="large" onClick={createRTODRS}>
                    Create RTO DRS
                </Button>
            </div>
        </div>
    )
};

export default RTODRSSparkLine;