import { apiService } from "../../../common/apiService/service";
import { apiRequest } from "../../../common/utils/apiGateway";
import { checkHttpStatus, notifyApiError } from "../../../common/utils/utils";

export const fetchTrips = payload => new Promise((resolve, reject) => {
    apiRequest({
        url    : "/hydra/v1/trip/fetch",
        method : "POST",
        data   : payload
    })
    .then(response => {
        if (checkHttpStatus(response.data.status)) {
            resolve(response.data.response);
        }
        else {
            reject(response.data.response);
        }
    })
    .catch(response =>  reject(response?.data?.response))
})

export const fetchShipmentCountByTrips = payload => new Promise((resolve, reject) => {
    apiRequest({
        url    : "/hydra/v1/trip/fetch/shipmentCount",
        method : "POST",
        data   : payload
    })
    .then(response => {
        if (checkHttpStatus(response.data.status)) {
            resolve(response.data.response);
        }
        else {
            reject(response.data.response);
        }
    })
    .catch(response =>  reject(response?.data?.response))
})

export const fetchInboundRoutes = _ => new Promise((resolve, reject) => {
    apiRequest({
        url    : "b2b/v1/routes/inbound-trips",
        method : "GET"
    })
    .then(response => {
        if (checkHttpStatus(response.data.status)) {
            resolve(response.data.response);
        }
        else {
            reject(response.data.response);
        }
    })
    .catch(response =>  reject(response?.data?.response))
})

export const fetchOutboundRoutes = _ => new Promise((resolve, reject) => {
    apiRequest({
        url    : "b2b/v1/routes/outbound-trips",
        method : "GET"
    })
    .then(response => {
        if (checkHttpStatus(response.data.status)) {
            resolve(response.data.response);
        }
        else {
            reject(response.data.response);
        }
    })
    .catch(response =>  reject(response?.data?.response))
})

export const executeTripAction = payload => new Promise((resolve, reject) => {
    apiRequest({
        url    : "hydra/v1/trip/update",
        method : "POST",
        data   : payload
    })
    .then(response => {
        if (checkHttpStatus(response.data.status)) {
            resolve(response.data.response);
        }
        else {
            reject(response.data.response);
        }
    })
    .catch(response =>  reject(response?.data?.response))
});

export const downloadConsignmentsList = (tripId, inboundTrips = true) => new Promise((resolve, reject) => {
    apiRequest({
        url    : `/hydra/v1/trip/fetch/printData/${tripId}/consignments${inboundTrips ? "?inboundTrip=true" : ""}`,
        method : "GET"
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            if (response?.data?.response?.sync) {
                window.open(response?.data?.response?.consignmentPrintURL, "_blank");
                resolve(response?.data?.response);
            }
            else {

                if (response?.data?.response?.errorMessage) {
                    notifyApiError(response?.data?.response?.errorMessage);
                }
                reject(response?.data?.response);
            }
        }
    })
    .catch(response =>  reject(response?.data?.response))
})

export const fetchTripsV2 = (payload) => {
    return apiService.post("hub/trip/outbound-trips", payload)
}

export const fetchOutboundRoutesV2 = () => {
    return apiService.get('hub/trip/outbound/routes')
}

export const dispatchTrip = (tripCode) => {
    return apiService.put(`hub/trip/${tripCode}/dispatch`)
}

export const markTripArrived = (tripCode) => {
    return apiService.put(`hub/trip/${tripCode}/arrived`)
}