export const ACTION_TYPES = {
    TOGGLE_LOADER     : "TOGGLE_LOADER",
    TOGGLE_LOADER_ON  : "TOGGLE_LOADER_ON",
    TOGGLE_LOADER_OFF : "TOGGLE_LOADER_OFF"
}

export const LoaderReducer = (state = { loader : false } , action = {}) => {

    switch (action.type) {
        case ACTION_TYPES.TOGGLE_LOADER     : return {
            ...state,
            loader : !state.loader
        }
        case ACTION_TYPES.TOGGLE_LOADER_ON  : return {
            ...state,
            loader : true
        }
        case ACTION_TYPES.TOGGLE_LOADER_OFF : return {
            ...state,
            loader : false
        }
        default : return state;
    }
}