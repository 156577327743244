import { apiRequest } from "../../../common/utils/apiGateway";
import { Constants, DownloadTags } from "../../../common/utils/constants";
import { checkHttpStatus, removeDuplicateLocations, reportsDownload } from "../../../common/utils/utils";
import { fetchColumnsBasedOnPostObject, userType } from "../utils";
import moment from "moment";

export const fetchPartnerLocations = (partnerId, params = {}) => new Promise((resolve, reject) => {
    apiRequest({
        url    : `b2b/v1/partners/${Constants.PARTNER_ID}/location_by_entityid`,
        method : "GET",
        params : {
            entityId    : partnerId,
            entityType  : "PARTNER",
            partnerId   : partnerId,
            partnerList : partnerId,
            loggedInUserTokken:  true
        }
    })
    .then(response => {
        if (response?.data?.response?.locations) {
            resolve(removeDuplicateLocations( response?.data?.response?.locations, { nameWithRole: "name" } ));
        }
        else {
            reject(response);
        }
    })
    .catch(e => reject(e))
})

export const fetchCodViewDetails  = (params, pageNo, pageSize, actions={}) => new Promise((resolve, reject) => {
    apiRequest({
        url    : `/b2b/v2/cod/view?pageNo=${pageNo ? pageNo : 1}&pageSize=${pageSize ? pageSize : 10}`,
        method : "POST",
        data   : params
    })
    .then(response => {
        resolve({
            data : response?.data?.response,
            columns : fetchColumnsBasedOnPostObject(params, actions)
        });
    })
    .catch(e => reject(e))
})

export const downloadCODReport = params => new Promise((resolve, reject) => {
    const postObject = {
        userId     : Constants.sessionUser.id,
        locationId : Constants.userLocationId,
        partnerId  : Constants.PARTNER_ID,
        documentSubType     : null,
        loggedInUserTokken  : true,
        documentType        : "COD_LSN_COLLECT_FROM_REPORT",
        codViewFetchRequest : params
    }
    apiRequest({
        url    : "/b2b/v1/reports/COD_LSN_COLLECT_FROM_REPORT/get",
        method : "POST",
        data   : postObject
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            reportsDownload(response?.data?.response?.url, DownloadTags.s3, "&title=COD_REPORT");
            resolve(true);
        }
        else {
            reject(false);
        }
    })
    .catch(e => reject(e))
})

export const fetchCodSparklineDetails  = (fromTimeStamp, toTimeStamp, data={}) => new Promise((resolve, reject) => {
    var params = {
        rootPartnerIds : [ 268,269 ],
        remittanceStatus : [
            "PENDING",
            "PARTIAL_APPROVE",
            "REQUEST_FOR_APPROVAL"
        ],
        fromTimeStamp : fromTimeStamp,
        toTimeStamp   : toTimeStamp,
        partnerIds    : [ Number(Constants.PARTNER_ID) ],
        searchText    : data?.searchText
    }
    if (userType() === "ROOT_PARTNER") {
        params.rootPartnerIds = [ Number(Constants.PARTNER_ID) ];
        params.groupCriteria  = "PARTNER";
        delete params.partnerIds
    }
    else if (userType() === "BRANCH") {
        params.locationIds = [ Number(Constants.LOGGED_PARTNER_LOCATION_ID) ];
        params.groupCriteria  = "ROOT_PARTNER";
    }
    else if (userType() === "ROOT_PARTNER_ADMIN") {
        params.groupCriteria  = "PARTNER";
        delete params.partnerIds
    }
    else if (userType() === "PARTNER_ADMIN") {
        params.groupCriteria  = "BRANCH";
    }

    apiRequest({
        url    : `/b2b/v2/cod/sparkline`,
        method : "POST",
        data   : params
    })
    .then(response => {
        resolve(response?.data?.response);
    })
    .catch(e => reject(e))
})

export const generateFetchSettlementsRequest = payload => ({
    dateRange : {
        from  : payload.startDate ? payload.startDate : moment(new Date("Nov 14 2022").getTime()).valueOf(),
        to    : payload.endDate
    },
    startDate    : payload.startDate ? payload.startDate : moment(new Date("Nov 14 2022").getTime()).valueOf(),
    endDate      : payload.endDate,
    approvalType : payload.approvalType,
    approverPartnerId   : payload.rootPartnerId,
    approverPartnerType : payload.rootPartnerId ? [ "PARTNER" ] : undefined,
    pageNo      : payload.pageNo || 1,
    pageSize    : payload.pageSize || 10,
    partnerId   : !payload.partnerId || payload.partnerId === [] ? null : 
                  Array.isArray(payload.partnerId) ? payload.partnerId : [ payload.partnerId ],
    locationIds : !payload.locationIds || payload.locationIds === [] ? null : 
                  Array.isArray(payload.locationIds) ? payload.locationIds : [ payload.locationIds ],
    transactionIds  : !payload.transactionIds || payload.transactionIds === [] ? null : 
                      Array.isArray(payload.transactionIds) ? payload.transactionIds : [ payload.transactionIds ],
    approvalStatus  : !payload.approvalStatus || payload.approvalStatus === [] ? null : 
                      Array.isArray(payload.approvalStatus) ? payload.approvalStatus : [ payload.approvalStatus ],
    customerIds     : !payload.customerIds || payload.customerIds === [] ? null : 
                    Array.isArray(payload.customerIds) ? payload.customerIds : [ payload.customerIds ]
})

export const fetchSettlementActivity = payload => new Promise((resolve, reject) => {
    const postObject = { ...generateFetchSettlementsRequest(payload) };
    apiRequest({
        url    : "b2b/v1/cod/settlements",
        method : "POST",
        data   : postObject
    })
    .then(response => {
        resolve(response?.data?.response);
    })
    .catch(e => reject(e))
})
