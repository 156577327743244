import * as apiUtils from '../../../common/utils/apiGateway';
import { Constants } from '../../../common/utils/constants';
import { checkHttpStatus } from '../../../common/utils/utils';
import * as CONSTANTS from './constants';

export const getAssets = (filters) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.GET_ASSETS_LIST_BEGIN
        });
        return apiUtils.apiRequest({
            method: 'POST',
            url: `/b2b/v1/assets/fetch`,
            data: filters
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.GET_ASSETS_LIST_SUCCESS,
                    payload: response.data.response
                })
                return response.response;
            })
            .catch(error => dispatch({
                type: CONSTANTS.GET_ASSETS_LIST_FAILURE,
                payload: error
            }));
    }
}


export const createAsset = (params) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.SET_ASSET_CREATE_BEGIN
        });
        return apiUtils.apiRequest({
            method: 'post',
            url: `/b2b/v4/partners/${Constants.PARTNER_ID}/assets`,
            data: params,
        })

            .then(response => {
                if (checkHttpStatus(response?.data?.status)) {
                    dispatch({
                        type: CONSTANTS.SET_ASSET_CREATE_SUCCESS,
                        payload: response.data.response
                    })
                    return response.response;
                } else {
                    dispatch({
                        type: CONSTANTS.SET_ASSET_CREATE_FAILURE,
                        payload: response?.data
                    })
                }
            })
            .catch(error => dispatch({
                type: CONSTANTS.SET_ASSET_CREATE_FAILURE,
                payload: error
            }));
    }
}

export const updateAsset = (locationId, params) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.SET_ASSET_UPDATE_BEGIN
        });
        return apiUtils.apiRequest({
            method: 'PUT',
            url: `/b2b/v4/partners/${Constants.PARTNER_ID}/assets/${locationId}`,
            data: params,
        })
            .then(response => {
                if (checkHttpStatus(response?.data?.status)) {
                    dispatch({
                        type: CONSTANTS.SET_ASSET_UPDATE_SUCCESS,
                        payload: response.data.response
                    })
                    return response.response;
                } else {
                    dispatch({
                        type: CONSTANTS.SET_ASSET_UPDATE_FAILURE,
                        payload: response?.data
                    })
                }
            })
            .catch(error => dispatch({
                type: CONSTANTS.SET_ASSET_UPDATE_FAILURE,
                payload: error
            }));
    }
}

export const getAssetData = (locationId) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.GET_ASSET_DATA_BEGIN
        });
        return apiUtils.apiRequest({
            url: `/b2b/v2/partners/${Constants.PARTNER_ID}/assets/${locationId}`,
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.GET_ASSET_DATA_SUCCESS,
                    payload: response.data.response
                })
                return response.response;
            })
            .catch(error => dispatch({
                type: CONSTANTS.GET_ASSET_DATA_FAILURE,
                payload: error
            }));
    }
}

export const getVendorData = () => dispatch => {
    dispatch({
        type: CONSTANTS.GET_ASSET_VENDOR_DATA_BEGIN
    });
    apiUtils.apiRequest({
        method: 'get',
        url: `/b2b/v1/partners/${Constants.PARTNER_ID}/vendors`,
    })
        .then(response => {
            dispatch({
                type: CONSTANTS.GET_ASSET_VENDOR_DATA_SUCCESS,
                payload: response.data.response
            })
        })
        .catch(error => dispatch({
            type: CONSTANTS.GET_ASSET_VENDOR_DATA_FAILURE,
            payload: error
        }));
}

export const getLocations = (data) => dispatch => {
    dispatch({
        type: CONSTANTS.GET_ASSETS_LOCATIONS_LIST_BEGIN
    });
    apiUtils.apiRequest({
        method: 'POST',
        url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`,
        data
    })
        .then(response => {

            dispatch({
                type: CONSTANTS.GET_ASSETS_LOCATIONS_LIST_SUCCESS,
                payload: response.data.response
            })
        })
        .catch(error => dispatch({
            type: CONSTANTS.GET_ASSETS_LOCATIONS_LIST_FAILURE,
            payload: error
        }));
}

export const getAssetLocations = (params) => dispatch => {
    dispatch({
        type: CONSTANTS.GET_ASSETS_LIST_LOCATIONS_LIST_BEGIN
    });
    apiUtils.apiRequest({
        method: 'POST',
        url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`,
        data: params
    })
        .then(response => {

            dispatch({
                type: CONSTANTS.GET_ASSETS_LIST_LOCATIONS_LIST_SUCCESS,
                payload: response.data.response
            })
        })
        .catch(error => dispatch({
            type: CONSTANTS.GET_ASSETS_LIST_LOCATIONS_LIST_FAILURE,
            payload: error
        }));
}



export const getUsers = (locationID) => dispatch => {
    dispatch({
        type: CONSTANTS.GET_ASSETS_USERS_LIST_BEGIN
    });
    apiUtils.apiRequest({
        method: 'get',
        url: `b2b/v1/partners/${Constants.PARTNER_ID}/locations/${locationID}/users?fetch_type=ASSIGNED_ASSETS_USERS&loggedInUserTokken=true&user_level=FIELD_EXECUTIVE`,
    })
        .then(response => {
            dispatch({
                type: CONSTANTS.GET_ASSETS_USERS_LIST_SUCCESS,
                payload: response.data.response
            })
        })
        .catch(error => dispatch({
            type: CONSTANTS.GET_ASSETS_USERS_LIST_FAILURE,
            payload: error
        }));
}



export const updateLocation = (params) => dispatch => {
    dispatch({
        type: CONSTANTS.SET_ASSET_LOCATION_UPDATE_BEGIN
    });
    apiUtils.apiRequest({
        method: 'put',
        url: `/b2b/v1/assets/location`,
        data: params
    })
        .then(response => {
            if (checkHttpStatus(response?.data?.status)) {
                dispatch({
                    type: CONSTANTS.SET_ASSET_LOCATION_UPDATE_SUCCESS,
                    payload: response.data.response
                })
            }

        })
        .catch(error => dispatch({
            type: CONSTANTS.SET_ASSET_LOCATION_UPDATE_FAILURE,
            payload: error
        }));
}

export const resetFields = () => dispatch => {
    dispatch({
        type: CONSTANTS.SET_RESET_FIELDS
    })
}

export const resetLocation = () => dispatch => {
    dispatch({
        type: CONSTANTS.SET_LOCATION_RESET
    })
}

// export const getPartnerLocatinDetailsV2 = (params) => {
//     return dispatch => {
//         dispatch({
//             type: CONSTANTS.GET_PARTNER_LOCATION_FETCH_BEGIN
//         });
//         return apiUtils.apiRequest({
//             url: `/b2b/v1/partners/${JSON.parse(localStorage.getItem(USER_LOGGEDIN_INFO.LOGGEDIN_PARTNER_ID))}/location_by_entityid`,
//             params: params,
//             overrideHeader: Constants.HTTP_HEADERS_LOGGED_IN
//         })
//             .then(response => {
//                 dispatch({
//                     type: CONSTANTS.GET_PARTNER_LOCATION_FETCH_SUCCESS,
//                     payload: response.data.response
//                 })
//                 return response.response;
//             })
//             .catch(error => dispatch({
//                 type: CONSTANTS.GET_PARTNER_LOCATION_FETCH_FAILURE,
//                 payload: error
//             }));
//     }
// }

// export const fetchCustomerLocatinDetails = (params) => {
//     return dispatch => {
//         dispatch({
//             type: CONSTANTS.GET_CUSTOMER_LOCATION_FETCH_BEGIN
//         });
//         return apiUtils.apiRequest({
//             method: 'post',
//             url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`,
//             data: params,
//         })
//             .then(response => {
//                 dispatch({
//                     type: CONSTANTS.GET_CUSTOMER_LOCATION_FETCH_SUCCESS,
//                     payload: response.data.response
//                 })
//                 return response.response;
//             })
//             .catch(error => dispatch({
//                 type: CONSTANTS.GET_CUSTOMER_LOCATION_FETCH_FAILURE,
//                 payload: error
//             }));
//     }
// }

// export const fetchCities = () => {
//     return dispatch => {
//         dispatch({
//             type: CONSTANTS.GET_CITIES_FETCH_BEGIN
//         });
//         return apiUtils.apiRequest({
//             url: `/b2b/v1/cities`,
//             overrideHeader: Constants.HTTP_HEADERS_LOGGED_IN
//         })
//             .then(response => {
//                 dispatch({
//                     type: CONSTANTS.GET_CITIES_FETCH_SUCCESS,
//                     payload: response.data.response
//                 })
//                 return response.response;
//             })
//             .catch(error => dispatch({
//                 type: CONSTANTS.GET_CITIES_FETCH_FAILURE,
//                 payload: error
//             }));
//     }
// }

// export const fetchPincodes = () => {
//     return dispatch => {
//         dispatch({
//             type: CONSTANTS.GET_PINCODES_FETCH_BEGIN
//         });
//         return apiUtils.apiRequest({
//             url: `/b2b/v1/pincodes`,
//             overrideHeader: Constants.HTTP_HEADERS_LOGGED_IN
//         })
//             .then(response => {
//                 dispatch({
//                     type: CONSTANTS.GET_PINCODES_FETCH_SUCCESS,
//                     payload: response.data.response
//                 })
//                 return response.response;
//             })
//             .catch(error => dispatch({
//                 type: CONSTANTS.GET_PINCODES_FETCH_FAILURE,
//                 payload: error
//             }));
//     }
// }

// export const createVendor = (params) => {
//     return dispatch => {
//         dispatch({
//             type: CONSTANTS.SET_VENDOR_CREATE_BEGIN
//         });
//         return apiUtils.apiRequest({
//             method: 'post',
//             url: `/b2b/v1/partners/${Constants.PARTNER_ID}/vendors/create`,
//             data: params,
//         })
//             .then(response => {
//                 dispatch({
//                     type: CONSTANTS.SET_VENDOR_CREATE_SUCCESS,
//                     payload: response.data.response
//                 })
//                 return response.response;
//             })
//             .catch(error => dispatch({
//                 type: CONSTANTS.SET_VENDOR_CREATE_FAILURE,
//                 payload: error
//             }));
//     }
// }


// export const updateVendor = (params, id) => {    
//     return dispatch => {
//         dispatch({
//             type: CONSTANTS.SET_VENDOR_UPDATE_BEGIN
//         });
//         return apiUtils.apiRequest({
//             method: 'put',
//             url: `/b2b/v1/partners/${Constants.PARTNER_ID}/vendors/${id}`,
//             data: params,
//         })
//             .then(response => {
//                 dispatch({
//                     type: CONSTANTS.SET_VENDOR_UPDATE_SUCCESS,
//                     payload: response.data.response
//                 })
//                 return response.response;
//             })
//             .catch(error => dispatch({
//                 type: CONSTANTS.SET_VENDOR_UPDATE_FAILURE,
//                 payload: error
//             }));
//     }
// }



// export const getVendorData = (id) => dispatch => {
//     dispatch({
//         type: CONSTANTS.GET_VENDOR_DATA_BEGIN
//     });
//     apiUtils.apiRequest({
//         method: 'get',
//         url: `/b2b/v1/partners/${Constants.PARTNER_ID}/vendors/${id}?fetchMode=BASIC_WITH_IDENTITY_DOCUMENTS`,
//     })
//         .then(response => {
//             dispatch({
//                 type: CONSTANTS.GET_VENDOR_DATA_SUCCESS,
//                 payload: response.data.response
//             })
//         })
//         .catch(error => dispatch({
//             type: CONSTANTS.GET_VENDOR_DATA_FAILURE,
//             payload: error
//         }));
// }