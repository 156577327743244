import { apiRequest } from "../../common/utils/apiGateway";
import { ENTITY_TYPES } from "../../components/inventory/closure/constants";

export default class ManifestClosureService {

    scanConnection(entityType, params) {
        const url = `/b2c/v1/${entityType}/scan`;
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    };

    unloadConnection(entityType, params) {
        const url = `/b2c/v1/${entityType}/unload`;
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    };

    fetchConnection(entityType, params) {
        const url = `/b2c/v1/${entityType}/fetch`;
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    };

    validateEntity(entityType, params) {
        const url = `/b2c/v1/${entityType}/validate`;
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    };

    updateConnection(entityType, params) {
        const url = entityType === ENTITY_TYPES.connection.name ? "/b2c/v1/connection/update" : "/b2c/v1/manifest/update_new";
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    };

    scanConsignment(entityType, params, legacyScan = false) {
        const scanType = entityType === "connection" ? "manifest" : "consignment";
        var url = `/b2c/v1/${entityType}/${scanType}/scan`;
        if (scanType == "consignment" && !legacyScan) {
            url += "/v2";
        }
        return apiRequest({
            url,
            method: "POST",
            data: legacyScan ? params : { ...params, advancedResponse : true },
            ignoreStatus : true,
            showMessage  : legacyScan == false ? false : undefined
        });
    };

    fetchManifestAndConsignment(entityType, params) {
        const url = entityType === ENTITY_TYPES.connection.name ? "/b2c/v1/connection/manifest/fetch" : "/b2c/v1/manifest/consignment/fetch"; 
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    };

    fetchReasons(entityType) {
        const url = entityType === ENTITY_TYPES.connection.name ? "/b2c/v1/manifest/reason" : "/b2c/v1/consignment/reason";
        return apiRequest({
            url,
            method: "GET"
        });
    };

    updateReason(entityType, params) {
        const url = entityType === ENTITY_TYPES.connection.name ? "/b2c/v1/manifest/update/reason" : "/b2c/v1/consignment/update/reason";
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    };


}