import React from "react";
import {
    Tag,
    Button,
} from "antd";
import moment from "moment";
import WaybillLink from "../../shared-components/waybill-link";
import { capitalizeString } from "../../../common/utils/utils";

const status_mapping = {
    DEL: "DEL",
    UNDEL: "UNDEL",
    OFD: "OFD",
    PENDING: "PENDING",
    IN_TRANSIT: "IN_TRANSIT",
    DRS_LINKED: "DRS_LINKED"
};

const status_label = {
    DEL: "Delivered",
    UNDEL: "Undelivered",
    OFD: "OFD",
    SHIPMENT_LOST: "Shipment Lost",
    NOT_HANDED_OVER: "Not Handed over"
}

export const getPODLiveNestedTableColumnsLegacy = (payloads, history) => {
    const { livePermission, rowId, drsStatus, drsCode } = payloads;

    if (!rowId || !drsStatus || !drsCode) {
        return []
    }

    const drsId = "DRS_" + rowId;
    return [
        {
            title: "AWB ID",
            dataIndex: "waybillNo",
            key: "waybillNo",
            render: data => (<WaybillLink>{data}</WaybillLink>)
        },
        {
            title: "Destination",
            dataIndex: "pincode",
            key: "pincode",
            width: 120,
            render: (data, row) => (<div>{row?.pincode?.city?.cityCode} {row?.pincode?.city?.cityCode ? "-" : ""} {row?.pincode?.zipcode} </div>)

        },
        {
            title: "Customer",
            dataIndex: "customer",
            key: "customer",
            render: (data, row) => {
                return (
                    (<div>{row?.customer?.name}</div>)
                )
            }
        },
        {
            title: "Created On",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (data, row) =>
                <div className="flex-box flex-column">{moment(data).format("DD/MM/YYYY hh:mm A")} <br /> <span>{moment(row.createdAt).fromNow()}</span></div>
        },
        {
            title: "Last Updated",
            dataIndex: "consignmentPODBOMap",
            key: "consignmentPODBOMap",
            render: (data, row) => (<div className="flex-box flex-column">
                {
                    Object.keys(row?.consignmentPODBOMap).length > 0 ? <div>{moment(row?.updatedAt).format("DD/MM/YYYY hh:mm A")} <br /> <span>{moment(row?.podUpdatedDate).fromNow()}</span></div> : <div>{moment(row?.updatedAt).format("DD/MM/YYYY h:mm a")} <br /> <span>{moment(row?.updatedAt).fromNow()}</span></div>
                }
            </div>)
        },
        {
            title: "Consignment Status",
            dataIndex: "consignmentPODBOMap",
            key: "consignmentStatus",
            width: 175,
            render: (data, row) => {
                const consignmentId = row.id;
                const consignmentPODBOMap = row?.consignmentPODBOMap;
                const shipmentStatus = consignmentPODBOMap[drsId]?.shipmentStatus;
                const reasonId = consignmentPODBOMap[drsId]?.reasonId;
                const PODEditUrl = `/appv2/pod/form/drs/pod/create/${consignmentId}/${drsCode}/${rowId}`;
                const redirectToCreatePOD = _ => history.push(`/appv2/pod/form/drs/pod/create/${consignmentId}/${drsCode}/${rowId}`)
                return (
                    <span className="flex-box justify-content-center">
                        {
                            shipmentStatus && shipmentStatus === status_mapping.DEL && (
                                <Tag className={"ant-table-tag text-align-center success-tag"}>{(status_label[shipmentStatus].split("_").map(s => capitalizeString(s.toLowerCase()))).join(" ")}</Tag>
                            )
                        }
                        {
                            shipmentStatus && shipmentStatus === status_mapping.UNDEL && (
                                reasonId && reasonId == 5 ?
                                    <Tag className={"ant-table-tag text-align-center error-tag"}>{status_label.SHIPMENT_LOST}</Tag> :
                                    <Tag className={"ant-table-tag text-align-center error-tag"}>{status_label[shipmentStatus]}</Tag>
                            )
                        }
                        {
                            Object.keys(consignmentPODBOMap).length >= 0 && shipmentStatus && shipmentStatus !== status_mapping.UNDEL && shipmentStatus !== status_mapping.DEL && shipmentStatus !== status_mapping.OFD && (
                                <Tag className={"ant-table-tag text-align-center warning-tag"}>{(shipmentStatus.split("_").map(s => capitalizeString(s.toLowerCase()))).join(" ")}</Tag>
                            )
                        }
                        {
                            Object.keys(consignmentPODBOMap).length <= 0 && (drsStatus && drsStatus === status_mapping.IN_TRANSIT) && (
                                <Button type="primary" className="lsn-btn-primary" onClick={redirectToCreatePOD}>
                                    Create POD
                                </Button>
                            )
                        }
                        {
                            Object.keys(consignmentPODBOMap).length <= 0 && livePermission?.create && (drsStatus && drsStatus === status_mapping.PENDING) && (
                                <Button type="primary" className="lsn-btn-primary" onClick={redirectToCreatePOD}>
                                    Create POD
                                </Button>
                            )
                        }
                    </span>
                );

            }
        },
    ]
};

export const getPODLiveNestedTableColumns = (payloads, history) => {

    const { livePermission, drsId, drsStatus, drsCode } = payloads;

    if (!drsId || !drsStatus || !drsCode) {
        return []
    }

    return [
            {
                title: "AWB ID",
                dataIndex: "consignment.waybillNo" ?? "N/A",
                key: "waybillNo",
                render: data => (<WaybillLink>{data}</WaybillLink>)
            },
            {
                title: "Destination",
                dataIndex: "consignment",
                key: "pincode",
                width: 120,
                render: data => <div>{(data?.pincode?.cityCode ? data.pincode.cityCode : "") + (data?.pincode?.cityCode ? " - " : " ") + (data?.pincode?.zipcode ? data.pincode.zipcode : "")}</div>
            },
            {
                title: "Customer",
                dataIndex: "consignment.customer" ?? "N/A",
                key: "customer"
            },
            {
                title: "Linked At",
                dataIndex: "consignment",
                key: "createdAt",
                render: (data, row) =>
                    <div className="flex-box flex-column">
                        {data?.createdAt ?
                            <>
                                {moment(data.createdAt).format("DD/MM/YYYY hh:mm A")} <br /> <span>{moment(data.createdAt).fromNow()}</span></> : "N/A"
                        }
                    </div>
            },
            {
                title: "POD Created At",
                dataIndex: "consignment",
                key: "lastUpdated",
                render: (data, row) =>
                    <div className="flex-box flex-column">
                        {data?.updatedAt ?
                            <> {moment(data.updatedAt).format("DD/MM/YYYY hh:mm A")} <br /> <span>{moment(data.updatedAt).fromNow()}</span></>
                            : "--"
                        }
                    </div>
            },
            {
                title: "Consignment Status",
                dataIndex: "consignment",
                key: "consignmentStatus",
                width: 175,
                render: (data, row) => {

                    const redirectToCreatePOD = _ => history.push(`/appv2/pod/form/drs/pod/create/${data.consignmentId}/${drsCode}/${drsId}`)

                    const shipmentStatus = data?.podStatus ? data.podStatus : data.status

                    return (
                        <span className="flex-box justify-content-center">
                            {
                                shipmentStatus && shipmentStatus === status_mapping.DEL && (
                                    <Tag className={"ant-table-tag text-align-center success-tag"}>{(status_label[shipmentStatus].split("_").map(s => capitalizeString(s.toLowerCase()))).join(" ")}</Tag>
                                )
                            }
                            {
                                shipmentStatus && shipmentStatus === status_mapping.UNDEL && (
                                    data.reasonId && data.reasonId == 5 ?
                                        <Tag className={"ant-table-tag text-align-center error-tag"}>{status_label.SHIPMENT_LOST}</Tag> :
                                        <Tag className={"ant-table-tag text-align-center error-tag"}>{status_label[shipmentStatus]}</Tag>
                                )
                            }
                            {
                                shipmentStatus && shipmentStatus !== status_mapping.UNDEL && shipmentStatus !== status_mapping.DEL && ([status_mapping.OFD, status_mapping.DRS_LINKED].indexOf(shipmentStatus) < 0) && (
                                    <Tag className={"ant-table-tag text-align-center warning-tag"}>{(shipmentStatus.split("_").map(s => capitalizeString(s.toLowerCase()))).join(" ")}</Tag>
                                )
                            }
                            {
                                ((drsStatus && drsStatus === status_mapping.IN_TRANSIT) || (livePermission?.create && (drsStatus && drsStatus === status_mapping.PENDING))) &&
                                ([status_mapping.OFD, status_mapping.DRS_LINKED].indexOf(shipmentStatus)> -1) && (
                                    <Button type="primary" className="lsn-btn-primary" onClick={redirectToCreatePOD}>
                                        Create POD
                                    </Button>
                                )
                            }
                        </span>
                    );

                }
            },
        ]
}