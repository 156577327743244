import { Icon, Button, Spin, Divider, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormWrapper from "../ui/formComponent/form";
import reportTypeSchema from "./form/schema"
import reportsActionConstants from "./state/reportsActionConstants";
import { schemaBlueprint } from "./form/config"
import { gernerateReportFormSchema } from "./util";
import "./reportsv2.scss"
import { fetchReportType } from "./services/fetch.services";
import { initialState } from "./state/reports.reducer";
import { postReports } from "./services/post.service";
import PreviewViewer from "./components/preview.viewer";
import ConfirmDonwloadPopup from "./components/confirm.popup";
import { notifyApiSuccess, notifyApiError } from "../../common/utils/utils";
import moment from "moment";
import { Constants } from "../../common/utils/constants";

var submitAction = undefined;

export default function ReportsV2(props) {

    const dispatch = useDispatch();
    
    const metabaseReports = ["RTO_DRS_REPORT","DRS_REPORT", "COD_COLLECTABLE_REPORT", "MANIFEST_REPORT"];

    const metabaseMapping = {
        "DRS_REPORT"     : {
            questionNo : 2075,
            reportName : "drs-report"
        },
        "RTO_DRS_REPORT" : {
            questionNo : 2144,
            reportName : "rto-drs-report"
        },
        "COD_COLLECTABLE_REPORT" : {
            questionNo : 2145,
            reportName : "cod-collectable-report"
        },
        "MANIFEST_REPORT" : {
            questionNo : 2141,
            reportName : "manifest-report"
        }
    }

    var formData = useSelector(state => state.reportsReducer.reportFormData)
    var reportConfig = useSelector(state => state.reportsReducer.reportConfig)
    
    const [ reportTypeData, SetReportTypeData ] = useState({});
    const [ reportFormSchema, SetReportFormSchema ]= useState(undefined);
    const [ loading, SetLoading ]= useState(false);
    const [ previewColumns, SetPreviewColumns ]= useState(undefined);
    const [ previewData, SetPreviewData ]= useState(false);
    const [ summaryEnum, SetSummaryEnum ]= useState("FE_WISE");
    const [ confirmPopupOpen, SetConfirmPopupOpen ]= useState(false);

    var formRef = useRef();
    
    const transformErrors = errors => {
        errors.map(error => {
            if (error.name !== "required") {
                error.message = undefined;
            }
        })
        return errors;
    }

    const validate = (data, errorsObject) => {
        if ((data.downloadType === "date" || data.downloadType === "pickupdate") && data.dateRange ) {
            const dates = data.dateRange.split(",");
            if(dates[0] == "" || dates[1] == "") {
                errorsObject.dateRange.addError("Date Range is mandatory!")
            }
            else {
                const diff    = moment(Number(dates[1])).diff(moment(Number(dates[0]))).valueOf();
                const weekDiff = moment.duration("7","days").asMilliseconds();
                const monthDiff = moment.duration("31","days").asMilliseconds();
                if (metabaseReports.indexOf(reportTypeData.reportType) > -1) {
                    if (diff > monthDiff) {
                        errorsObject.dateRange.addError("Maximum allowed date range is 31 days!")
                    }
                }
                else if (Constants.PARTNER_ID == 127808) {
                    if (diff > monthDiff) {
                        errorsObject.dateRange.addError("Maximum allowed date range is 31 days!")
                    }
                }
                else if (diff > weekDiff) {
                    errorsObject.dateRange.addError("Maximum allowed date range is 7 days!")
                }
            }
        }
        return errorsObject;
    }

    const disableReportButton = _ => {
        var disableButton = !reportTypeData.reportType || reportTypeData.reportType === "";
        return disableButton;
    }

    useEffect(() => {
        return () => {
            dispatch({
                type : reportsActionConstants.UPDATE_REPORT_FORM_DATA,
                data : initialState.reportFormData
            })
        }
    }, [])

    useEffect(() => {
        if (reportTypeData.reportType && reportTypeData.reportType !== "") {
            onSubmit();
        }
    }, [ summaryEnum ])

    useEffect(() => {
            if (reportTypeData.reportType) {
            fetchReportType()
            .then(reportTypes => {
                const selectedReport = reportTypes.find(type => reportTypeData.reportType == type.code);
                var { metaInfo } = selectedReport;
                const { schema, uiSchema, formData, errorList} = gernerateReportFormSchema(metaInfo, schemaBlueprint);
                dispatch({
                    type : reportsActionConstants.UPDATE_REPORT_CONFIG,
                    data : metaInfo
                });
                dispatch({
                    type : reportsActionConstants.UPDATE_REPORT_FORM_DATA,
                    data : formData
                });
                SetReportFormSchema({ schema, uiSchema, errorList });
            })
        }
        else {
            SetReportFormSchema(undefined);
        }
    }, [ reportTypeData ])

    const onReportTypeChange = data => {
        SetReportTypeData(data);
    }
    
    const invokeSubmit = submitOption => {
        submitAction = submitOption.action;
        formRef.current.formReference.current.submit();
    }

    const confirmDownloadReport = _ => {
        var action = reportConfig.submitOptions.find(s => s.action === submitAction);
        if (reportTypeData.reportType === "COD_COLLECTABLE_REPORT") {
            const [ startDateRange, endDateRange ] = formData.dateRange.split(",");
            const daysDiff = moment(Number(endDateRange)).diff(moment(Number(startDateRange)), "days");
            if (daysDiff >= 1) {
                return Modal.error({ title : "Maximum allowed date range for COD Report is 1 day!" });
            }
        }
        if (action.id === "greport") {        
            if (metabaseReports.indexOf(reportTypeData.reportType) > -1) {
                
                var locations = formData.destinations.map(d => d.key);
                if (locations.indexOf(-1) > -1) {
                    locations = [];
                }
    
                var customers = formData.customers.map(d => d.key);
                if (customers.indexOf(-1) > -1) {
                    customers = [];
                }
    
                var partners = formData.partners.map(d => d.key);
                if (partners.indexOf(-1) > -1) {
                    partners = [];
                }
    
                var employees = formData.employees.map(d => d.key);
                if (employees.indexOf(-1) > -1) {
                    employees = [];
                }
                const [ startDateRange, endDateRange ] = formData.dateRange.split(",");
    
                const metaData = metabaseMapping[reportTypeData.reportType];

                var reportParams = {
                    resource : { question : metaData.questionNo },
                    params   : {
                        location_id : locations.length > 0 ? locations.join(",") : "-1",
                        partner_id  : partners.length > 0 ? partners.join(",") : "-1",
                        start_date  : moment(Number(startDateRange)).format('YYYY-MM-DD'),
                        end_date    : moment(Number(endDateRange)).format('YYYY-MM-DD'),
                        user_id     : employees.length > 0 ? employees.join(",") : "-1",
                        customer_id : customers.length > 0 ? customers.join(",") : "-1"
                    }
                };

                if (["COD_COLLECTABLE_REPORT", "MANIFEST_REPORT"].indexOf(reportTypeData.reportType) > -1) {
                    delete reportParams.params.user_id;
                }
                
                window.open(`/appv2/reports/${metaData.reportName}?reportParams=${btoa(JSON.stringify(reportParams))}`);
                return;
            }
            SetConfirmPopupOpen(true);
        }
        else {
            onSubmit(undefined);
        }
    }

    const onSubmit = emailTo => {
        SetLoading(true);
        SetConfirmPopupOpen(false);
        var action = reportConfig.submitOptions.find(s => s.action === submitAction);
        postReports({ ...formData, ...reportTypeData }, reportConfig, action, summaryEnum, emailTo)
        .then(response => {
            if (response?.url === "") {
                notifyApiError("No data present for the applied filters.", "Empty Report");
                SetPreviewColumns(undefined);
                SetPreviewData(undefined);
                SetLoading(false);
                return;
            }
            if (action.id === "greport") {
                notifyApiSuccess("Report generation initiated succesfully!");
                SetPreviewColumns(false);
                SetPreviewData(false);
                props.history.push("/appv2/reports/dashboard/download");
            }
            else if (action.id === "preview") {
                var {tempColumns,tempPreviewData} = response;
                if (Object.keys(tempColumns)?.length === 0 && Object.keys(tempPreviewData)?.length === 0) {
                    notifyApiError("Kindly select different options and try again.","No data found!")
                    SetPreviewColumns(false);
                    SetPreviewData(false);
                    SetLoading(false);
                    return;
                }
                SetPreviewColumns(tempColumns);
                SetPreviewData(tempPreviewData);
                notifyApiSuccess("Report preview generated succesfully!");
                window.scrollTo({
                    top : document.getElementById("previewWrapper").offsetTop + 120,
                    behavior : "smooth"
                })
            }
            SetLoading(false);
        })
        .catch(_ => {
            SetPreviewColumns(undefined);
            SetPreviewData(undefined);
            SetLoading(false);
        })
    }

    return (
        <Spin spinning={loading}>
            <div className="flex-column flex-gap-l spacer-l border-grey white-bg">
                <div className="flex-box flex-gap-m align-items-center">
                    <Icon type="arrow-left" className="font-size-m-2" onClick={_ => props.history.push("/appv2/reports/dashboard/download")}/>
                    <div className="font-size-l-1"> Download Reports </div>
                </div>
                <Divider style={{ margin : 0 }} />
                <div className="flex-box flex-gap-l">
                    <div className="flex-column flex-gap-xl flex-1">
                        <FormWrapper {...reportTypeSchema} formData={reportTypeData} id="ReportsTypeForm"
                        transformErrors={transformErrors}
                        hideSubmit={true} onChange={data => onReportTypeChange(data)} />

                        {
                            reportFormSchema &&
                            <>
                                <FormWrapper {...reportFormSchema} formData={formData} id="ReportsForm"
                                    transformErrors={transformErrors} validate={validate}
                                    hideSubmit={true}
                                    onChange={data => {
                                        dispatch({
                                            type : reportsActionConstants.UPDATE_PARTNERS,
                                            data : data?.partners?.map(p => p.key || p.value).join(',') !== formData?.partners?.map(p => p.key || p.value).join(',')
                                        });
                                        setTimeout(() => {
                                            dispatch({
                                                type : reportsActionConstants.UPDATE_PARTNERS,
                                                data : false
                                            });
                                        }, 200);
                                        dispatch({
                                            type : reportsActionConstants.UPDATE_REPORT_FORM_DATA,
                                            data : data
                                        })
                                    }}
                                    onSubmit={confirmDownloadReport}
                                ref={formRef} />
                                <div className="flex-box flex-gap-l" style={{
                                    marginTop: Object.keys(reportFormSchema?.schema?.properties).length === 0 ? -60 : undefined
                                }} >
                                    {
                                        reportConfig && reportConfig.submitOptions &&
                                        reportConfig.submitOptions.map(submitOption => (
                                            !(submitOption.id === "preview" && formData.downloadType === "waybill") &&
                                            <Button className={submitOption.buttonClass} key={submitOption.id}
                                            disabled={disableReportButton()}
                                            onClick={_ => invokeSubmit(submitOption)}>
                                                {submitOption.buttonText}
                                            </Button>
                                        ))
                                    }
                                </div>    
                            </>
                        }
                    </div>
                    <div className="flex-1"></div>
                </div>
                {
                    confirmPopupOpen &&
                    <ConfirmDonwloadPopup visible={confirmPopupOpen} onSubmit={onSubmit} closePopup={_ => SetConfirmPopupOpen(false)} reportData={{ ...formData, ...reportTypeData }} />
                }
                {
                    previewData && previewColumns &&
                    <PreviewViewer previewData={previewData} previewColumns={previewColumns} summaryEnum={summaryEnum} SetSummaryEnum={SetSummaryEnum} />
                }
            </div>
        </Spin>
    )
}