const schema = {
    "type": "object",
    "properties": {
        "drsCodes" : {
            "type"  : "string",
            "title" : "DRS ID"
        },
        "drsUserIds" : {
            "type"  : "string",
            "title" : "Employee",
            "enum"  : [],
            "multiple" : true,
            "searchEnabled" : true
        },
        "createdAt" : {
            "type"  : "string",
            "title" : "Created On",
            "disableFutureDates" : true
        },
        "drsStatuses"   : {
            "type"      : "array",
            "title"     : "Status",
            "items"     : {
                "type"       : "string",
                "enum"       : ["PENDING", "IN_TRANSIT", "HANDED_OVER", "COMPLETED", "CLOSED", "DRAFT"],
                "enumNames"  : ["Pending", "In Transit", "Handed Over", "Completed", "Closed", "Draft"]
            },
            "uniqueItems"   : true
        }
    }
}

const uiSchema = {
    "classNames" : "multi-row-field-objects hide-title flex-column",
    "drsCodes"      : {
        "ui:placeholder" : "Enter DRS ID"
    },
    "drsUserIds"    : {
        "ui:placeholder" : "Select Employee"
    },
    "createdAt"  : {
        "ui:placeholder" : "Enter vehicle number",
        "ui:widget"      : "DateRangeWidget"
    },
    "drsStatuses"     : {
        "ui:widget"   : "checkboxes",
        "classNames"   : "lsn-filter-options-checkbox",
        "items"       : {
            "classNames" : "lsn-filter-options-checkbox"
        }
    }
}

const errorList = {
    "drsUserIds"  : {
        "ignoreType" : true
    }
}

export default { schema, uiSchema, errorList };