import { Alert, Button, Icon, Input, Spin } from "antd";
import React from "react";
import StartScan from "../../../assets/img/mweb/startscan.png";

export default function ManifestMwebScan(props) {

    const { manifestData, changeWaybill, waybill, enterWaybill, pendingAwbCount, pendingAwbCountLoading,
        selectedAwb, scanResult, openSelectedModal, selectedLoading, destinationLocation, isRearchEnabled } = props;

    return (
        <div className="flex-column flex-gap-l">
            <div style={{ backgroundColor: "#dfe3f8" }} className="spacer-m flex-column flex-gap-m">
                <div className="flex-box justify-content-space-between font-size-sm-3 text-bold grey-text">
                    <div className="flex-1 flex-box justify-content-flex-start"> Manifest ID </div>
                    -
                    <div className="flex-1 flex-box justify-content-flex-end"> Destination Hub </div>
                </div>
                <div className="flex-box justify-content-space-between font-size-m-3 text-bold">
                    <div className="flex-1 flex-box justify-content-flex-start"> {manifestData.manifestCode} </div>
                    -
                    <div className="flex-1 flex-box justify-content-flex-end"> {destinationLocation} </div>
                </div>
                <div className="flex-box flex-gap-s align-items-center font-size-m-2" style={{ color: "#3142AC" }}>
                    <div className="flex-5"> Scanned Waybills Count  : </div>
                    <div className="flex-4 flex-box justify-content-start align-items-center flex-gap-l">
                        {pendingAwbCountLoading ? <Spin /> : selectedAwb?.length}
                        <Icon onClick={openSelectedModal} type="form" style={{ color: "#000" }} />
                    </div>
                </div>
                <div className="flex-box flex-gap-s align-items-center font-size-m-2" style={{ color: "#3142AC" }}>
                    <div className="flex-5"> Pending Waybills Count  : </div>
                    <div className="flex-4 flex-box justify-content-start">
                        {pendingAwbCountLoading ? <Spin /> : pendingAwbCount}
                    </div>
                </div>
            </div>
            <div className="spacer-m">
                <Input placeholder={"Scan Waybill no."} id="scanWaybillInput" autoComplete={"off"} disabled={selectedLoading}
                    suffix={
                        <Button type="primary" className="lsn-btn-primary" disabled={selectedLoading} onClick={_ => {
                            enterWaybill({
                                target: {
                                    value: waybill
                                }
                            })
                        }}
                            style={{ height: 38, borderRadius: "0px 3px 3px 0px", marginRight: -15 }}>
                            <Icon type="barcode" /> Scan
                        </Button>
                    }
                    onChange={changeWaybill} value={waybill}
                    onPressEnter={enterWaybill} />
            </div>
            {
                scanResult !== undefined && scanResult?.sync !== undefined && scanResult?.action !== "DELINK" ?
                    <div className="spacer-m flex-column flex-gap-m">
                        <div className="grey-text"> LAST SCAN DETAILS </div>
                        <div className="spacer-m white-bg flex-column flex-gap-l">
                            {
                                scanResult?.sync ?
                                    scanResult?.action === "LINK" ?
                                        <>
                                            <Alert message={<div className="text-bold success-color">Successfully scanned waybill!</div>}
                                                showIcon={true} type="success" />
                                            <div className="flex-column flex-gap-m">
                                                <div className="grey-text font-size-m-1"> Waybill Number</div>
                                                <div className="font-size-m-3 text-bold">
                                                    {isRearchEnabled? scanResult?.waybillNo : scanResult.manifestBO?.consignments?.[0]?.waybillNo}
                                                </div>
                                            </div>
                                        </> :
                                        <></> :
                                    <>
                                        <Alert message={<div className="text-bold error-color">Error</div>} showIcon={true} type="error" />
                                        <div className="spacer-s"> {isRearchEnabled? scanResult: scanResult.message} </div>
                                    </>
                            }
                        </div>
                    </div> :
                    <div className="spacer-m flex-column justify-content-center align-items-center">
                        <img src={StartScan} width="50%" height="auto" />
                        <div className="font-size-m-3"> SCAN WAYBILL NO </div>
                        <div className="grey-text"> Scan waybill numbers to add into manifest </div>
                    </div>
            }
        </div>
    )
}