import { Tag } from "antd";
import React from "react";
import WaybillLink from "../../shared-components/waybill-link";

export const columns = [
  {
    title: "AWB No",
    dataIndex: "waybillNumber",
    key: "waybillNumber",
    render: data => <WaybillLink>{data}</WaybillLink>
  },
  {
    title: "Client",
    dataIndex: "customerName",
    key: "customerName"
  },

  {
    title: "Shipper",
    dataIndex: "waybillNumber",
    key: "shipperName",
    render: (data, row) => {
      return (
        <div>
          {row.shipperName ? row.shipperName : row.consignmentContactName}
        </div>
      )
    }
  },
  {
    title: "Address",
    dataIndex: "waybillNumber",
    key: "shipperAddress",
    render: (data, row) => {
      return (
        <div>
          {row.shipperAddress ? row.shipperAddress : row.consignmentContactAddress}
        </div>
      )
    }
  },

  {
    title: "Pincode",
    dataIndex: "pincode",
    key: "pincode",
    width: 75
  },
  {
    title: "Ageing",
    dataIndex: "ageing",
    key: "ageing"
  },
  {
    title: "Weight (in Kg)",
    dataIndex: "weight",
    key: "weight",
    width: 110
  },
  {
    title: "FE",
    dataIndex: "feName",
    key: "feName",
    render: (data, row) => {
      return (
        <div className="flex-column align-items-flex-start">
          <div> {data} </div>
          <div className="font-size-sm-2"> {row.feNumber} </div>
        </div>
      );
    }
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (data, row) => {
      return (
        <Tag className={"ant-table-tag text-align-center " + LeadStatusColors[data]}>
          {LeadStatus[data]?.replace("_", " ")}
        </Tag>
      );
    }
  }
];

const LeadStatusColors = Object.freeze({
  UNASSIGNED: "warning-tag",
  OPEN: "info-tag",
  COMPLETED: "success-tag",
  PICKUP_FAILED: "error-tag",
  PICKEDUP: "tertiary-info-tag",
  CANCELLED: "",
  PPEND: "warning-tag",
  OFP: "info-tag",
  PSUCC: "tertiary-info-tag",
  PCREATED: "warning-tag",
  PCANC: "error-tag",
});

const LeadStatus = Object.freeze({
  OPEN: "Assigned",
  UNASSIGNED: "Unassigned",
  COMPLETED: "Received",
  PICKUP_FAILED: "Not Picked",
  PICKEDUP: "Picked",
  CANCELLED: "Cancelled",
  PPEND: "Unassigned",
  OFP: "Assigned",
  PSUCC: "Picked",
  PCREATED: "Unassigned",
  PCANC: "Not Picked",
});
