import { Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { downloadCODReport, fetchCodViewDetails } from "../../services/fetch.service";
import { genrateFetchObject } from "../../utils";
import CoDViewMenu from "./cod.menu";

export default function ViewComponent ({ postObject, expandedRowRender, actions = {}, className, showMenu, sparklineSummary, isRootView, parentEntityId, showSparklineSummary }) {

    const [ viewDetails, SetViewDetails ] = useState(undefined);
    const [ expandedRowKeys, SetExpandedRowKeys ] = useState("0");

    const [ postRequestObject, SetPostRequestObject ] = useState({ ...postObject })

    const [ downloadingReport, SetDownloadingReport ] = useState(false);

    const fromTimeStamp = useSelector(state => state.codV2Reducer?.fromTimeStamp);
    const toTimeStamp   = useSelector(state => state.codV2Reducer?.toTimeStamp);

    const loadDetails = (params = {}) => {
        SetViewDetails(undefined);

        const fetchObject = genrateFetchObject({
            ...postRequestObject,
            fromTimeStamp  : fromTimeStamp,
            toTimeStamp    : toTimeStamp
        });

        if (actions) {
            Object.keys(actions).map(a => {
                const originalAction = actions[a];
                actions[a] = _ => {
                    loadDetails();
                    originalAction();
                }
            })
        }

        fetchCodViewDetails(fetchObject, params?.pageNo || 1, params?.pageSize || 10, actions)
        .then(response => {
            if (parentEntityId) {
                response.data.viewResponse = response.data.viewResponse.map(item => ({ ...item, parentEntityId : parentEntityId }));
            }
            SetViewDetails(response);
        })
        .catch(e => console.log(e));
    }

    useEffect(() => {
        return _ => SetExpandedRowKeys("0");
    }, []);

    useEffect(() => {
        loadDetails();
    }, [ postRequestObject ])

    const onExpand = (expanded, partnerRow) => SetExpandedRowKeys(expanded ? partnerRow.entityId.toString() : "0")

    const onPageChange = pageNo => loadDetails({ pageNo });

    const exportCoDView = _ => {
        SetDownloadingReport(true);
        const fetchObject = genrateFetchObject({
            ...postRequestObject,
            dateRangeMap   : [ "T", "T-1", "T-2", ">T-2", "NET_OUTSTANDING", "PENDING_FOR_APPROVAL" ],
            fromTimeStamp  : fromTimeStamp,
            toTimeStamp    : toTimeStamp
        });
        downloadCODReport(fetchObject)
        .then(_ => SetDownloadingReport(false))
        .catch(_ => SetDownloadingReport(false))
    }
    
    return (
        <div className="flex-column flex-gap-l">
            {
                showMenu ?
                <CoDViewMenu onSearch={searchText => {
                    SetPostRequestObject(current => {
                        var temp = { ...current };
                        temp.searchText = searchText
                        return temp;
                    });
                }}
                 disableMenu={(showSparklineSummary === true && sparklineSummary === undefined) || viewDetails === undefined}
                 appliedFilters={postRequestObject?.partnerIds}
                 applyPartnerFilters={filteredPartners => {
                    SetPostRequestObject(current => {
                        var temp = { ...current };
                        temp.partnerIds = filteredPartners
                        return temp;
                    });
                 }} 
                 applyLocationFilter={filteredLocations => {
                    SetPostRequestObject(current => {
                        var temp = { ...current };
                        temp.locationIds = filteredLocations
                        return temp;
                    });
                 }} 
                 resetFilter={_ => {
                    if (postRequestObject.partnerIds) {
                        SetPostRequestObject(current => ({
                            ...postObject,
                            searchText : current.searchText
                        }))   
                    }
                 }}
                 exportCoDView={exportCoDView} downloadingReport={downloadingReport}
                 menuType={postObject.groupCriteria} /> :
                 isRootView ?
                 <div className="flex-box justify-content-flex-end">
                    <Button disabled={sparklineSummary === undefined || viewDetails === undefined}
                     onClick={exportCoDView} loadDetails={downloadingReport} icon="download"> Export </Button>
                 </div> : 
                 <></>
            }
            {
                (viewDetails && viewDetails.data && viewDetails.columns) ? 
                <Table dataSource={viewDetails.data.viewResponse} columns={viewDetails.columns}
                 bordered={true} expandedRowRender={expandedRowRender} className={className}
                 onExpand={expandedRowRender ? onExpand : undefined} expandedRowKeys={expandedRowKeys}
                 rowKey="entityId" expandIconAsCell={false}
                 pagination={viewDetails.data.maxPage <=1 ? false : {
                    total    : viewDetails.data.totalCount,
                    onChange : onPageChange,
                    current  : viewDetails.data.currentPageNo,
                    pageSize : 10
                 }} /> :
                 <div className="flex-box justify-content-center">
                    <Spin spinning={true}/>
                 </div>
            }
        </div>
    )
}