import { Button, Checkbox, DatePicker, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { capitalizeString } from "../../../../common/utils/utils";
import CheckboxGroup from "antd/lib/checkbox/Group";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { fetchConnectionDestinationsOptions, fetchDestinationLocations } from "../service";
import { LocationReducer } from "../../../settings/location/reducer";
import { useSelector } from "react-redux";

export default function ConnectionsMenu({ defaultFilterPayload, SetFetchConnectionsPayload, fetchConnectionsPayload, loading }) {

    const appConfig = useSelector(state => state?.app?.configurations)

    const [modalVisible, SetModalVisible] = useState(false);
    const [selectedStartDate, SetSelectedStartDate] = useState(undefined);

    const [filterValues, SetFilterValues] = useState(fetchConnectionsPayload);
    const [destinationLocations, SetDestinationLocations] = useState([]);

    const navigate = useNavigate();

    const searchConnectionByCode = value => {
        SetFetchConnectionsPayload(curr => ({
            ...curr,
            connectionCodeForSearch: value,
            pageNo: 1
        }));
    }

    const statusOptions = ["PENDING", "LINKED", "STARTED", "UNLOADING", "COMPLETED"];

    useEffect(() => {
        if (modalVisible && (!destinationLocations || destinationLocations.length == 0)) {
            if (appConfig?.enableRearch?.enableConnectionWriteOps == "true") {
                fetchDestinationLocations()
                    .then(locations => {
                        SetDestinationLocations(locations)
                    })
            } else {
                fetchConnectionDestinationsOptions()
                    .then(locations => {
                        SetDestinationLocations(locations)
                    })
            }
        }
    }, [modalVisible])

    const handleClose = _ => {
        SetModalVisible(false);
    }

    const handleReset = _ => {
        SetFilterValues({ ...defaultFilterPayload })
        SetFetchConnectionsPayload({ ...defaultFilterPayload });
        SetModalVisible(false);
    }

    const handleOk = _ => {
        SetFetchConnectionsPayload({ ...filterValues });
        SetModalVisible(false);
    }

    const handleFilterValueChange = (value, filterKey, isValueToArray) => {
        var tempValue = value;
        if (isValueToArray) {
            if (value) {
                tempValue = [value];
            }
            else {
                tempValue = [];
            }
        }
        SetFilterValues(curr => {
            var _filterValues = {
                ...curr,
                [filterKey]: tempValue
            };
            return _filterValues;
        });
    }

    const disableDate = date => {
        if (date.valueOf() > moment().endOf("day").valueOf()) return true;
        if (selectedStartDate === undefined) return false;
        const maxEndDate = moment(selectedStartDate).startOf("day").add(15, "days").endOf("day");
        if ((date.valueOf() >= maxEndDate.valueOf()) || date.valueOf() < moment(selectedStartDate).startOf("day").valueOf()) {
            return true;
        }
        return false;
    }

    return (
        <div className="flex-box flex-gap-m align-items-center justify-content-space-between">
            <div className="flex-box flex-2 flex-gap-m align-items-center">
                <Input.Search onSearch={searchConnectionByCode} allowClear={true}
                    placeholder="Search Connection ID" style={{ maxWidth: 400 }} disabled={loading} />
                <Button className="lsn-btn-primary" icon="filter" disabled={loading}
                    onClick={_ => SetModalVisible(true)}>
                    Filter
                </Button>
            </div>

            <div className="flex-1 flex-box justify-content-flex-end">
                <Button className="lsn-btn-primary"
                    onClick={_ => navigate("/appv2/trips/form/connection-sheet")}>
                    Create Connection
                </Button>
            </div>

            <Modal
                title="Filters"
                visible={modalVisible}
                onCancel={handleClose}
                footer={[
                    <Button key="reset" onClick={handleReset}>Reset</Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>Apply</Button>,
                ]}>
                <div className="flex-column flex-gap-l align-items-center">

                    <div className="filter-modal-item">
                        <strong>Destination</strong>
                        <Select placeholder="Select Destination" style={{ width: "100%" }}
                            value={filterValues?.destinationLocationIds?.[0]}
                            onChange={key => handleFilterValueChange(key, "destinationLocationIds", true)}
                            showSearch={true} allowClear={true}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                destinationLocations &&
                                destinationLocations.map(loc => <Select.Option key={loc.id} value={loc.id}>{loc?.name}</Select.Option>)
                            }
                        </Select>
                    </div>

                    <div className="filter-modal-item">
                        <strong>Status</strong>
                        <CheckboxGroup onChange={e => handleFilterValueChange(e, "connectionStatuses", false)}
                            value={filterValues.connectionStatuses} className="lsn-filter-options-checkbox">
                            {
                                statusOptions.map(status => (
                                    <Checkbox key={status} value={status}>
                                        {capitalizeString(status.toLowerCase())}
                                    </Checkbox>
                                ))
                            }
                        </CheckboxGroup>
                    </div>


                    <div className="filter-modal-item">
                        <strong>Created Time</strong>
                        <DatePicker.RangePicker disabledDate={disableDate}
                            format="DD/MM/YYYY" separator={"to"} className="flex-2"
                            onCalendarChange={dates => {
                                if (dates.length > 1) {
                                    SetSelectedStartDate(undefined);
                                    return;
                                }
                                SetSelectedStartDate(dates[0]);
                            }}
                            onChange={dateRange => {
                                SetFilterValues(curr => {
                                    if (dateRange) {
                                        return {
                                            ...curr,
                                            startDate: dateRange[0] ? moment(dateRange[0]).startOf("day").valueOf() : moment().subtract(7, "days").startOf().valueOf(),
                                            endDate: dateRange[1] ? moment(dateRange[1]).startOf("day").valueOf() : moment().startOf().valueOf(),
                                        }
                                    }
                                    else {
                                        return {
                                            ...curr,
                                            startDate: moment().subtract(7, "days").startOf().valueOf(),
                                            endDate: moment().startOf().valueOf()
                                        }
                                    }
                                })
                            }}
                            value={[
                                filterValues?.startDate ? moment(filterValues?.startDate) : undefined,
                                filterValues?.endDate ? moment(filterValues?.endDate) : undefined
                            ]}
                        />
                    </div>

                </div>
            </Modal>
        </div>
    )
}