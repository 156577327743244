import React from 'react';
import TrackingPopup from '../../TrackingPopup/TrackingPopup';
import { Icon, Modal } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { openNotification } from '../../ui/notification/Notification';

const TrackingPopupModal = (props) => {
  return (
    <Modal className="remove-padding" title={
        <div className="font-size-l-3 flex-box flex-gap-l align-items-center spacer-s">
          <div>Waybill Number </div> - <div> {props.waybillNo} </div> 
          <CopyToClipboard text={props.waybillNo} onCopy={_ => {
            openNotification({
              message: 'Copied',
              description: 'Waybill has been copied to clipboard',
              className: 'warning',
            });
          }}>
            <Icon style={{ fontSize : 20 }} type="copy" className="copy-icon" />
          </CopyToClipboard>
        </div>
      }
      onCancel={props.onCancel} visible={props.visible}
      width="85vw" footer={false} centered={true}
      destroyOnClose={true} >
      <TrackingPopup {...props}
        getDetailInput={props.input}
        waybillNo={props.waybillNo}
        source={props.source}
      />
    </Modal>
  )
}

export default TrackingPopupModal;