import { Button, Modal, Select, Spin } from "antd";
import { useState } from "react";
import { fetchPartnerLocations } from "../../services/fetch.service";

export default function CoDActiviyFilter({ partnerId, applyFilter, resetFilters, viewType, buttonDisabled }) {

    const [ openFilterModal, SetOpenFilterModal ] = useState(false);
    const [ locationOptions, SetLocationOptions ] = useState(undefined);
    const [ selectedStatuses, SetSelectedStatuses ] = useState(undefined);
    const [ selectedLocations, SetSelectedLocations ] = useState(undefined);

    const statusOptions = [
        {
            id : "REQUEST_FOR_APPROVAL",
            name : "Pending Approval"
        },
        {
            id : "APPROVED",
            name : "Approved"
        },
        {
            id : "REJECTED",
            name : "Rejected"
        }
    ]

    const loadLocations = _ => {
        if (viewType === "PARTNER" && locationOptions === undefined) {
            fetchPartnerLocations(partnerId)
            .then(locations => {
                SetLocationOptions(locations);
            })
            .catch(_ => {
                SetLocationOptions([])
            })
        }
    }

    return (
        <>
            <Button disabled={buttonDisabled} icon="filter" className="lsn-btn-secondary"
             onClick={ _ => {
                loadLocations();
                SetOpenFilterModal(true);
             }}>
                Filters
            </Button>

            <Modal title="Filter" visible={openFilterModal} okText="Filter" cancelText="Reset" centered={true} closable={false}
             onOk={_ => {
                applyFilter({
                    locationId     : selectedLocations,
                    approvalStatus : selectedStatuses
                });
                SetOpenFilterModal(false);
             }}
             onCancel={_ => {
                SetSelectedLocations([]);
                SetSelectedStatuses([]);
                resetFilters();
                SetOpenFilterModal(false);
             }}>
                <div className="flex-column flex-gap-xl">
                    {
                        viewType === "PARTNER" &&
                        <div className="flex-column flex-gap-m">
                            <div className="light-grey-text font-size-sm-2">
                                Branch
                            </div>
    
                            {
                                locationOptions === undefined ?
                                <div className="flex-box justify-content-center">
                                    <Spin spinning={true} />
                                </div> :
                                <Select value={selectedLocations} placeholder="Select Branch" mode="multiple" onChange={SetSelectedLocations} >
                                    {
                                        locationOptions && locationOptions?.length > 0 &&
                                        locationOptions.map(l => <Select.Option value={l.id} key={l.id}> {l.name} </Select.Option>)
                                    }
                                </Select>
                            }
                        </div>
                    }

                    <div className="flex-column flex-gap-m">
                        <div className="light-grey-text font-size-sm-2">
                            Approval Status
                        </div>
                        <Select placeholder="Select Status" value={selectedStatuses} mode="multiple" onChange={SetSelectedStatuses} >
                            {
                                statusOptions && statusOptions?.length > 0 &&
                                statusOptions.map(l => <Select.Option value={l.id} key={l.id}> {l.name} </Select.Option>)
                            }
                        </Select>
                    </div>
                </div>
            </Modal>
       </>
    )
}