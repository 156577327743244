import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getRedirectedUrl, getInitPath } from "../../../common/utils/utils";

export default function ModuleLauncher(props) {
    
    const navigate = useNavigate();

    useEffect(() => {
        var redirectPath = getRedirectedUrl();

        if (props.module) {
            redirectPath = getInitPath(props.module);
        }
        navigate(redirectPath, { replace : true });
    }, [])

    return <div></div>;
}