import { Card, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import "../pod-drs-closure.styles.scss";
import '../../../../assets/styles/common.scss';
import moment from "moment";
import WaybillLink from "../../../shared-components/waybill-link";
import { Constants } from '../../../../common/utils/constants';
import SuspiciousUndelAction from '../../../operations/drs/components/suspicious.actions';
import { SUSPICIOUS_REASONS_MAP } from '../../../operations/drs/components/transform.data';

const InfoCard = ({ displayData }) => {

    const commonColumns = [
        {
            title: "Waybill Number",
            dataIndex: "waybillNo",
            key: "waybillNo",
            render: (data) => (<WaybillLink>{data}</WaybillLink>)
        }
    ]

    const config = localStorage.getItem("_configrations") && JSON.parse(atob(localStorage.getItem("_configrations")));
    const user = localStorage.getItem("user") && JSON.parse(atob(localStorage.getItem("user")));
    const franchisePartner = user?.partner?.franchisePartner;
    const block_suspicious_undel_scan = config?.block_suspicious_undel_scan?.indexOf(Constants.userLocationId + '') > -1;
    const showSuspiciousColumns = block_suspicious_undel_scan || franchisePartner;

    const undeliveredTableColumns = [
        ...commonColumns,
        {
            title: "Attempts",
            dataIndex: "numberOfAttempts",
            key: "numberOfAttempts",
            width : 100
        },
        {
            title: "POD Created On",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (data, row) => (<div>{moment(data).format("DD/MM/YYYY hh:mm A")}</div>)
        },
        {
            title: "Undelivered Reason",
            dataIndex: "reason",
            key: "reason",
            render: (data, row) => (<div>{data ? data : "N/A"}</div>)
        },
        {
            title: "NDR",
            dataIndex: "shipmentAction",
            key: "shipmentAction",
            render: (data, row) => (<div>{data ? data : "N/A"}</div>)
        },
        ...(showSuspiciousColumns ?
            [
                {
                    title: "Suspicious Reason",
                    dataIndex: "suspiciousReason",
                    key: "suspiciousReason",
                    width : 120,
                    render : data => <>{SUSPICIOUS_REASONS_MAP[data] || data || ""}</>
                },
                {
                    title : "Actions",
                    dataIndex : "suspiciousReason1",
                    render    : (data, row) => {
                        console.log(row);
                        
                        return <SuspiciousUndelAction waybillNo={row.waybillNo} reasonDescription={row.reason} drsCode={row.drsCode}
                         hideActionButtons={!(!row.inScanned && row.suspiciousReason && row.suspiciousReason!= "")}
                         suspiciousReason={SUSPICIOUS_REASONS_MAP[row?.suspiciousReason] || row?.suspiciousReason}/>
                    }
                }
            ] : []
        ),
    ]

    const ofdTableColumns = [
        ...commonColumns,
        {
            title: "Consignee Pincode",
            dataIndex: "pincode",
            key: "pincode",
            render: (data, row) => (
                <span>
                    {data?.cityCode &&
                        <span>{data.cityCode}</span>
                    }
                    {data?.cityCode && data?.zipcode &&
                        <span>{" - "}</span>
                    }
                    {data?.zipcode &&
                        <span>{data.zipcode}</span>
                    }
                </span>
            )
        },
        {
            title: "OFD Time",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (data, row) => (<div>{moment(data).format("DD/MM/YYYY hh:mm A")}</div>)
        }
    ]

    const [countData, setCountData] = useState([])

    useEffect(() => {

        const dataCount = []

        Object.entries(displayData).forEach(([key, value]) => {
            if (["totalDelInfo", "totalUndelInfo", "totalCollectedCodAmountInfo", "totalUnAttemptedInfo"].includes(key)) {
                dataCount.push(value)
            }
        });

        setCountData(dataCount)

    }, [displayData])

    return (
        <Card className='info-card'>
            <div className='flex-box flex-gap-xl'>
                {/* Count Card */}
                {countData.map((countInfo, index) => (
                    <Card size="small" key={index} className='count-card flex-1'>
                        <div className='display-text text-align-center'>{countInfo.displayText}</div>
                        <div className='count-text'>{countInfo.dataCount}</div>
                    </Card>
                ))}
            </div>
            {displayData?.undelShipment?.length > 0 &&
                <div>
                    <div className='sec-title'>Undelivered shipments Inward Scan Pending</div>
                    <Table
                        columns={undeliveredTableColumns}
                        dataSource={displayData.undelShipment}
                        pagination={{ position: ["None"] }}
                        bordered={true}
                    />
                </div>
            }
            {displayData?.ofdShipment?.length > 0 &&
                <div>
                    <div className='sec-title'>Shipments Out for Delivery POD pending</div>
                    <Table
                        columns={ofdTableColumns}
                        dataSource={displayData.ofdShipment}
                        pagination={{ position: ["None"] }}
                        bordered={true}
                    />
                </div>
            }
        </Card>
    )
}

export default InfoCard;