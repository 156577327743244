
import { apiRequest } from '../../common/utils/apiGateway';

export default class ReportsService {

    downloadReports(params = {}, reportType, endPoint, type) {
        if (!params.hasOwnProperty('loggedInUserTokken')) {
            params['loggedInUserTokken'] = true;
        }

        const url = `/b2b/v1/${type}/${reportType}/${endPoint}`;
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    }

    downloadZip(params = {}, reportType, endpoint, type) {
        if (!params.hasOwnProperty('loggedInUserTokken')) {
            params['loggedInUserTokken'] = true;
        }
        const url = `/b2b/v1/${type}/${reportType}/${endpoint}/`;
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    }

    getManifestSheet(params) {
        const url = `/b2b/v1/reports/MANIFEST_SHEET_LITE_GROUPED/get`;
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    }
}