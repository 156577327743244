import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import { SoundNotificationService } from "../../../services/sound.service";
import { summaryCurrentDRS, summaryDRSStatus } from "./common/columns";
import { closeDRS, closeDrsV2 } from "./common/services";
import { DRSCloseSummary, DRSSummary } from "./summary";

const soundService = new SoundNotificationService();

const CloseDrs = props => {
    const { visible, close, data, userInfo } = props;
    const [loading, setLoading] = useState(false);

    const config = useSelector((state) => state?.app?.configurations)

    const handleReload = () => {
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    const drsClosure = async (drsId) => {

            setLoading(true);

            closeDrsV2(userInfo.id)
                .then((data) => {
                    setLoading(false);
                    soundService.playSuccess();
                    notifyApiSuccess(data, 'SUCCESS')
                    close(false);
                    handleReload();
                })
                .catch((error) => {
                    setLoading(false);
                    notifyApiError(error?.errors ? error.errors : error, 'ERROR')
                    soundService.playWarning();
                    close(false);
                    console.error("RTO DRS closure v2 api failed with the following error -> ", error)
                })
    }

    return (
        <Modal
            visible={visible}
            width={400}
            onCancel={() => close(false)}
            footer={
                <Button loading={loading} className="drs-basic-btn" onClick={() => drsClosure(data.drs.id)}>Submit</Button>
            }
            title="Close DRS"
        >
            <div className="drs-close flex-column flex-gap-l">
                <div className="drs-close-heading">
                    Close all shipments for
                </div>
                <div className="flex-column flex-gap-m">
                    <div className="flex-box flex-gap-l">
                        <span className="drs-close-user_label">
                            Employee:
                        </span>
                        <span className="drs-close-user_value">{userInfo.name}</span>
                    </div>
                    <div className="flex-box flex-gap-l">
                        <span className="drs-close-user_label">
                            Phone No:
                        </span>
                        <span className="drs-close-user_value">{userInfo.contactNumber}</span>
                    </div>


                </div>

                <DRSCloseSummary
                    columns={summaryCurrentDRS()}
                    dataSource={data?.drsSummary}
                />
                <DRSSummary
                    columns={summaryDRSStatus()}
                    dataSource={data?.drsSummary}
                />

            </div>
        </Modal>
    )
}

export default CloseDrs;