import { Input, Modal, Button, Icon, Alert } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiRequest } from "../../../common/utils/apiGateway";
import { Constants } from "../../../common/utils/constants";
import { checkHttpStatus, notifyApiError } from "../../../common/utils/utils";
import { apiService } from "../../../common/apiService/service";
import { fetchManifests } from "./actions";
import LoaderService from "../../../services/loader";

export default function ManifestMWebHome(props) {

    const [getManifestCode, SetGetManifestCode] = useState(false);
    const [manifestCodeInput, SetManifestCodeInput] = useState("");
    const [noManifestFound, SetNoManifestFound] = useState(false);

    const appConfig = useSelector(state => state?.app?.configurations);

    useEffect(() => {
        if (props.location?.hash && props.location?.hash.indexOf("getManifestCode") > -1) {
            SetGetManifestCode(true);
        }
        else {
            SetGetManifestCode(false);
        }
    }, [props.location])

    const editManifest = _ => {
        if (!manifestCodeInput || manifestCodeInput.trim() === "") {
            return notifyApiError("Enter Manifest ID to scan Waybills!");
        }

        SetNoManifestFound(false);

        if (appConfig?.enableRearch?.enableManifestReadOps == "true") {
            const payload = {
                pageNo: 1,
                pageSize: 10,
                statuses: ["PENDING"],
                manifestCodeInput: manifestCodeInput
            }
            fetchManifests(payload).then(resp=>{
                if(resp.manifests.length>0){
                    const manifest = resp.manifests[0];
                    if (!manifest.unlockAllowed) {
                        return Modal.error({
                            title: `Manifest ${manifestCodeInput} can not be updated further!`
                        })
                    }
                    if (manifest.isLocked) {
                        return Modal.error({
                            title: (
                                <div>
                                    Manifest {manifestCodeInput} is locked! Kindly unlock it from the
                                    <a onClick={_ => {
                                        Modal.destroyAll();
                                        props.history.push(`/appv2/inventory/dashboard/manifest-partner`)
                                    }}> ERP </a> and try again
                                </div>
                            )
                        })
                    }
                    props.history.push(`/appv2/mweb/inventory/form/manifest-partner/${manifestCodeInput}`)
                }else{
                    SetNoManifestFound(true);
                }
            })
        }
        else
        {
            LoaderService.toggleLoaderOn()

            const postObject = {
                filters: {
                    pageNo: 1,
                    pageSize: 10,
                    isActive: true,
                    statuses: ["PENDING"],
                    flowTypes: ["FORWARD"],
                    fetchObjects: [],
                    manifestCodes: [manifestCodeInput],
                    originatedLocIds: [Constants.userLocationId]
                }
            }

            apiRequest({
                url: "/b2b/v1/manifest/fetch",
                data: postObject,
                method: "POST"
            }).then(response => {
                if (checkHttpStatus(response?.data?.status, false)) {
                    if (response?.data?.response?.manifests?.length > 0) {
                        const manifest = response?.data?.response?.manifests[0];
                        if (!manifest.unlockAllowed) {
                            return Modal.error({
                                title: `Manifest ${manifestCodeInput} can not be updated further!`
                            })
                        }
                        if (manifest.isLocked) {
                            return Modal.error({
                                title: (
                                    <div>
                                        Manifest {manifestCodeInput} is locked! Kindly unlock it from the
                                        <a onClick={_ => {
                                            Modal.destroyAll();
                                            props.history.push(`/appv2/inventory/dashboard/manifest-partner`)
                                        }}> ERP </a> and try again
                                    </div>
                                )
                            })
                        }
                        props.history.push(`/appv2/mweb/inventory/form/manifest-partner/${manifest.id}`)
                    }
                    else {
                        SetNoManifestFound(true);
                    }
                }
                LoaderService.toggleLoaderOff()
            })
            .catch((e) => {
                LoaderService.toggleLoaderOff()
            })
        }
    }
    return (

        <div className="white-bg flex-column flex-gap-l align-items-stretch spacer-l">
            {
                getManifestCode ?
                    <>
                        <Input.Search
                            placeholder="Enter Manifest Code to unlock"
                            onChange={e => {
                                SetNoManifestFound(false);
                                SetManifestCodeInput(e?.target?.value)
                            }}
                            value={manifestCodeInput}
                            autoFocus={true}
                            onSearch={editManifest} />
                        {
                            noManifestFound &&
                            <Alert type="error" message={`No manifest found for Manifest ID - ${manifestCodeInput} `} />
                        }
                        <Button className="lsn-btn-primary" type="primary"
                            onClick={_ => noManifestFound ? props.history.push("/appv2/mweb/inventory/form/manifest-partner") : editManifest()}>
                            {noManifestFound ? "Create Manifest" : "Edit Manifest"}
                        </Button>
                    </> :
                    <>
                        <Button onClick={_ => props.history.push(`${props.location?.pathname}#getManifestCode`)} type="ghost" style={{ height: "auto" }} >
                            <div className="flex-box justify-content-space-between align-items-center spacer-s">
                                <div className="text-bold break-word" style={{ textAlign: "left" }}> Add to existing manifest </div>
                                <Icon type="right" />
                            </div>
                        </Button>
                        <Button onClick={_ => props.history.push("/appv2/mweb/inventory/form/manifest-partner")} type="ghost" style={{ height: "auto" }} >
                            <div className="flex-box justify-content-space-between align-items-center spacer-s">
                                <div className="text-bold break-word" style={{ textAlign: "left" }}> Create Forward Manifest </div>
                                <Icon type="right" />
                            </div>
                        </Button>
                        <Button onClick={_ => props.history.push("/appv2/mweb/rto/form/manifest-partner")} type="ghost" style={{ height: "auto" }} >
                            <div className="flex-box justify-content-space-between align-items-center spacer-s">
                                <div className="text-bold break-word" style={{ textAlign: "left" }}> Create RTO Manifest </div>
                                <Icon type="right" />
                            </div>
                        </Button>
                    </>
            }
        </div>
    )
}