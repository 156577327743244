import { Icon, Input, Modal, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import { createVendor } from "./actions";
import { fetchPartners } from "../../reportsv2/services/fetch.services";

export default function CreateVendor(props){

    const user = JSON.parse(atob(localStorage.user));
    const partner = user?.partner;

    const [ vendorData, SetVendorData ] = useState({
        name          : "",
        contactNumber : "",
        partnerId     : {
            label : partner?.name,
            value : partner?.id,
            key   : partner?.id
        }
    });
    const [ dataError, SetDataError ] = useState({
        name   : false,
        contactNumber : false
    });
    const [ creatingVendor, SetCreatingVendor ] = useState(false);
    const [ partnerOptions, SetPartnerOptions ] = useState(undefined);

    useEffect(_ => {

        fetchPartners()
        .then(_partnerOptions => SetPartnerOptions(_partnerOptions))
        .catch(_ => SetPartnerOptions())

    }, [])

    const onValueChange = (key, value) => {
        SetVendorData(c => ({ ...c, [key] : value }));
        SetDataError(c => ({ ...c, [key] : false }));
    }

    const closeDialog = _ => {
        props.onVendorCancel()
    }

    const onVendorAddition = _ => {
        SetCreatingVendor(true);
        var _dataError = {
            name   : false,
            contactNumber : false 
        }
        if (vendorData.name.trim() == "" || !(/^[a-zA-Z0-9 \-_]*$/.test(vendorData.name.trim()))) {
            _dataError.name = true;
        }
        if (vendorData.contactNumber.trim() == "" || !(/^((\\+91-?)|0)?[0-9]{10}$/.test(vendorData.contactNumber.trim()))) {
            _dataError.contactNumber = true;
        }
        if (_dataError.contactNumber || _dataError.name) {
            return SetDataError(_dataError);
        }

        createVendorApi({
            ...vendorData,
            partnerId : vendorData.partnerId?.key
        })
    }

    const createVendorApi = (payload) => {
        createVendor(payload)
        .then((data) => {
            notifyApiSuccess("Submitted successfully")
            props.onVendorSuccess({
                vendorData
            })
            SetCreatingVendor(false);
        })
        .catch((errors) => {
            notifyApiError(errors.errors)
            SetCreatingVendor(false);
        })
    };

    return (
        <Modal centered={true} visible={true}
         title="Vendor Creation" onOk={onVendorAddition} okText={"Create"} onCancel={closeDialog}
         okButtonProps={{ loading : creatingVendor }}
         cancelButtonProps={{ loading : creatingVendor }}>
            <Spin spinning={partnerOptions === undefined || creatingVendor}>
            <div className="flex-column flex-gap-l align-items-stretch">

                <div className="flex-box align-items-flex-start justify-content-space-evenly">
                    <div className="flex-1"> Partner </div> 
                    <div className="flex-3 flex-column flex-gap-s align-items-stretch">
                        <Select placeholder="Select Partner" labelInValue={true} showSearch={true}
                         filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                         onChange={value => onValueChange("partnerId", value)}
                         defaultValue={vendorData.partnerId}>
                            {
                                (partnerOptions && partnerOptions.length > 0) &&
                                partnerOptions.map(partner => ( 
                                    partner.linkPartnerBO?.name &&
                                    <Select.Option key={partner.linkPartnerBO?.id} value={partner.linkPartnerBO?.id}>{partner.linkPartnerBO?.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                </div>
                
                <div className="flex-box align-items-flex-start justify-content-space-evenly">
                    <div className="flex-1"> Vendor Name </div> 
                    <div className="flex-3 flex-column flex-gap-s align-items-stretch">
                        <Input className={dataError?.name ? "error-field" : ""} placeholder="Enter Vendor Name"
                        value={vendorData.name} onChange={e => onValueChange("name", e.target.value)} onPressEnter={onVendorAddition}/>
                        {
                            dataError?.name ?
                            <div className="flex-box align-items-center flex-gap-m error-text">
                                <Icon type="warning" style={{fontSize : 16}}/>
                                {vendorData.name.trim() === "" ? "Vendor Name cannot be empty!" : "Vendor Name cannot contain special characters!"}
                            </div> :
                            <></>
                        }
                    </div>
                </div>

                <div className="flex-box align-items-flex-start justify-content-space-evenly">
                    <div className="flex-1"> Vendor Number </div>
                    <div className="flex-column flex-3 flex-gap-s align-items-stretch">
                        <Input type="contactNumber" className={dataError?.contactNumber ? "error-field" : ""} placeholder="Enter Vendor phone number"
                        value={vendorData.contactNumber} onChange={e => onValueChange("contactNumber", e.target.value)} onPressEnter={onVendorAddition}/>
                        {
                            dataError?.contactNumber ?
                            <div className="flex-box align-items-center flex-gap-m error-text">
                                <Icon type="warning" style={{fontSize : 16}}/>
                                {vendorData.contactNumber.trim() === "" ? "Vendor Phone number cannot be empty!" : "Invalid Vendor Phone number!"}
                            </div> :
                            <></>
                        }
                    </div>
                </div>
            </div>
            </Spin>
        </Modal>
    )
}