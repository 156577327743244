import { Button, Card, Icon, Input, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "./ManageReversePickup.style.scss";

const ActionModal = ({
    open,
    onClose,
    modalData,
    handleAwbScan,
    handlePrintLabel,
    waybillScanData,
    clearWaybillInput,
    actionLoader
}) => {

    const [waybillNo, setWaybillNo] = useState();

    useEffect(() => {
        setWaybillNo();
    }, [clearWaybillInput])

    return (
        <>
            <Modal
                title="Choose action"
                visible={open}
                footer={[]}
                onCancel={_ => onClose()}
                className="action-modal"
            >
                <Spin spinning={actionLoader}>
                    <div className="flex-box align-items-center option-container flex-gap-xl">
                        <div className="round-circle circle-green">1</div>
                        <div>
                            <div><b>Print label</b></div>
                            <div>Click here to print the label of the shipment</div>
                        </div>
                        <div>
                            <Button className="lsn-btn-primary"
                                onClick={_ => handlePrintLabel()}>Print Label</Button>
                        </div>
                    </div>
                    <div className="vertical-divider"></div>
                    <div disabled={modalData?.showScanButton ? false : true} className="flex-box align-items-center option-container flex-gap-xl">
                        <div className="round-circle circle-blue">2</div>
                        <div className="full-width">
                            <div><b>Scan AWB</b></div>
                            <div className="mt-8">
                                <Input.Search className="scan-awb-input"
                                    placeholder={"Enter/Scan Waybill Number"}
                                    disabled={modalData?.showScanButton ? false : true}
                                    allowClear
                                    autoFocus={true}
                                    value={waybillNo}
                                    onChange={(e) => setWaybillNo(e.target.value.trim())}
                                    onPressEnter={(e) => {
                                        handleAwbScan(e.target.value.trim())
                                    }}
                                    addonAfter={
                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 5
                                            }}
                                            className="scan-code"
                                        >
                                            <Icon type="barcode" /> Scan
                                        </span>
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    {waybillScanData && waybillScanData.length > 0 &&
                        <Card className="scan-res-card" title={<div>Waybill scanned successfully</div>}>
                            <div className="flex-box flex-gap-xl">
                                {waybillScanData.map((scanData) => {
                                    if (scanData.value != null) {
                                        return <div>
                                            <div className="card-subtitle light-grey-text">{scanData.title}</div>
                                            <div className={scanData.textColor + " mt-4"}>{scanData.value}</div>
                                        </div>
                                    }
                                })}
                            </div>
                        </Card>
                    }
                </Spin>
            </Modal>
        </>
    )

}

export default ActionModal;