import reportsActionConstants from "../state/reportsActionConstants";
import store from "../../../utils/store";
import { apiRequest } from "../../../common/utils/apiGateway";
import { checkHttpStatus } from "../../../common/utils/utils";
import { Constants } from "../../../common/utils/constants";
import { generateReportConfig } from "../config.generator";

const dispatch = store.dispatch;

export const fetchReportType = forceFetch => new Promise(resolve => {
    if (store.getState().reportsReducer.reportTypes && store.getState().reportsReducer.reportTypes?.length > 0 && !forceFetch) {
        resolve(store.getState().reportsReducer.reportTypes)
    }
    else {
        const reportTypes = generateReportConfig();
        dispatch({
            type : reportsActionConstants.STORE_REPORT_TYPES_ARRAY,
            data : reportTypes
        });
        resolve(reportTypes)
    }
})

export const fetchPartners = forceFetch => new Promise(resolve => {
    if (store.getState().reportsReducer.partners && store.getState().reportsReducer.partners?.length > 0 && !forceFetch) {
        resolve(store.getState().reportsReducer.partners)
    }
    else {
        apiRequest({
            url     : "/b2b/v1/partners/mapping/fetch",
            method  : "POST",
            data    : {
                filters : {
                    sourcePartnerIds : [ Constants.PARTNER_ID ],
                    fetchObjects     : [ "PARTNER" ]
                }
            }
        })
        .then(response => {
            if (checkHttpStatus(response?.data?.status)) {
                const { partners } = response?.data?.response;
                dispatch({
                    type : reportsActionConstants.STORE_PARTNERS_DATA,
                    data : partners
                });
                resolve(partners);
            }
            else {
                resolve([]);
            }
        })
    }
})