export const transformDrsList = response => {
    var customDRSObject = response.deliveryRunSheets.map(drs => {
        return {
            ...drs,
            id      : drs.drsId,
            drsUser : {
                ...drs.drsUser,
                contactNumber : drs.drsUser.number
            },
            customObj: {
                status         : drs.drsStatus,
                delCount       : drs.delCount,
                shipmentsCount : drs.waybillCount,
                totalUnDel     : drs.undelCount,
                totalDel       : drs.delCount,
                drsPodAllowed  : false,
                consignmentsCount : drs.waybillCount
            }
        };
    });
    var totalCount = response.totalCount;
    var pageSize   = response.pageSize;
    var pageNo     = response.pageNo;
    return {
        maxPage : parseInt(totalCount/pageSize) + (totalCount % pageSize > 0 ? 1 : 0),
        offset  : (pageSize * (pageNo - 1)) + 1,
        limit   : pageSize,
        drses   : customDRSObject,
        totalCount      : totalCount,
        currentPageNo   : pageNo,
        currentPageSize : pageSize
    }
}

export const transformDrsPostObject = legacyObj => {
    if (legacyObj?.request === undefined) {
        return legacyObj;
    }
    return {
        drsCode   : legacyObj?.request?.[0]?.drsCode,
        drsUserId : legacyObj?.request?.[0]?.drs?.drsUser?.id
    }
}