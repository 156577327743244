import React, { useEffect, useState } from "react";

export default function AppliedFilters({
    filteredOptions,
    keyToText,
    dateRange,
    showDateRange,
    showLabel,
    onDataClick,
    isDataClickable
}) { 

    const [ isFilterApplied, SetIsFilterApplied ] = useState(false);
    const [ totalFiltersApplied, SetTotalFiltersApplied ] = useState(0);

    useEffect(() => {
        var totalFiltersAppliedCount = 0;
        if (Object.keys(filteredOptions).filter(filter => keyToText[filter]).length > 0) {
            var appliedFilterCount = Object.keys(filteredOptions).filter(filterKey => filteredOptions[filterKey]?.length && keyToText[filterKey]).length;
            SetIsFilterApplied(appliedFilterCount > 0);
            totalFiltersAppliedCount = Object.keys(filteredOptions).reduce((sum, key) => {
                if (Array.isArray(filteredOptions[key]) && keyToText[key] ) {
                    sum += filteredOptions[key]?.length;
                }
                return sum;
            }, 0);
        }
        if (dateRange && showDateRange) {
            ++totalFiltersAppliedCount;
        }
        SetTotalFiltersApplied(totalFiltersAppliedCount);
    }, [ filteredOptions ])

    const callDataClick = key => typeof onDataClick === "function" ? onDataClick(key) : null;

    
    return (
        isFilterApplied || (dateRange && showDateRange) ?
        <div className="flex-box flex-gap-l align-items-center flex-wrap">
            <div> {totalFiltersApplied || "No"} filter(s) applied {totalFiltersApplied ? ":" : ""} </div>
            <div className="flex-box flex-gap-l align-items-center">
                {
                    showDateRange && dateRange && (
                        <div className="flex-box flex-gap-s align-items-center filtered-option">
                            { dateRange?.from || dateRange?.[0] }
                            <div className="light-grey-text font-size-sm-1"> to </div>
                            { dateRange?.to || dateRange?.[1] }
                        </div>
                    )
                }
                {
                    Object.keys(keyToText).length > 0 &&
                    Object.keys(keyToText).map(key => {
                        if (filteredOptions[key] !== undefined && Array.isArray(filteredOptions[key]) && filteredOptions[key]?.length > 0 ) {
                            return (
                                <div className="flex-box flex-gap-s align-items-center">
                                    <div key={key} className="white-bg flex-box flex-gap-s align-items-center spacer-hm" style={{ borderRadius :  20 }}>
                                        {showLabel && <div className="font-size-m-1"> {keyToText[key]} : </div>}
                                        <div onClick={_ => callDataClick(key)}
                                        className={isDataClickable && onDataClick ? "info-color cursor-pointer" : "" }>
                                            { filteredOptions[key][0]?.label || filteredOptions[key][0] }
                                        </div>
                                    </div>
                                    { filteredOptions[key]?.length > 1 && <div className="font-size-m-1"> + {filteredOptions[key]?.length - 1} more </div> }
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div> :
        <></>
    )
}