export const STATUS_COLORS = {
    pending : "pink",
    open : "orange",
    closed : "green",
    completed : "yellow",
    default : "yellow",
    error : "danger",
    success : "success",
    in_transit : "lsn"
  };

export const MAP_COLOR_STATUS = {
    "PENDING" : "red",
    "CLOSED" : "green",
    "HANDED_OVER" : "#69CEC6",
    "COMPLETED" : "yellow",
};

export const INITIAL_STATE = {
    loading: false,
    error: "",
    drsList: [],
    dataCardList: [],
    paginationData: null,
    drsFormData : {},
    pendingWaybills : undefined,
    selectedWaybills : undefined
};

export const ACTION_TYPES = {
    FETCH_DRS_LIST_BEGIN: "FETCH_DRS_LIST_BEGIN",
    FETCH_DRS_LIST_SUCCESS: "FETCH_DRS_LIST_SUCCESS",
    FETCH_DRS_LIST_FAILURE: "FETCH_DRS_LIST_FAILURE",

    FETCH_SPARK_LINE_DATA__BEGIN: "FETCH_SPARK_LINE_DATA__BEGIN",
    FETCH_SPARK_LINE_DATA__SUCCESS: "FETCH_SPARK_LINE_DATA_SUCCESS",
    FETCH_SPARK_LINE_DATA__FAILURE: "FETCH_SPARK_LINE_DATA__FAILURE",

    UPDATE_RTO_DRS_CODE     : "UPDATE_RTO_DRS_CODE",
    FETCH_FE_EMPLOYEES_RTO  : "FETCH_FE_EMPLOYEES_RTO",
    FETCH_CUSTOMER_LOCATION : "FETCH_CUSTOMER_LOCATION",
    UPDATE_RTO_DRS_FORMDATA : "UPDATE_RTO_DRS_FORMDATA",

    RTO_DRS_PENDING_WAYBILL  : "RTO_DRS_PENDING_WAYBILL",
    RTO_DRS_SELECTED_WAYBILL : "RTO_DRS_SELECTED_WAYBILL",
    RTO_DRS_WAYBILLS         : "RTO_DRS_WAYBILLS"

};

export const RTO_COLORS = {
    yellow: "#FFB64D"
}