export const initialState = {
    loading: false,
    embedUrl: ""
}

export const FETCH_MONITOR_REPORTS_BEGIN = "FETCH_MONITOR_REPORTS_BEGIN";
export const FETCH_MONITOR_REPORTS_SUCCESS = "FETCH_MONITOR_REPORTS_SUCCESS";
export const FETCH_MONITOR_REPORTS_FAILURE = "FETCH_MONITOR_REPORTS_FAILURE";

export const MONITOR_REPORTS_CONFIG = {
    "sc-hub-visibilty" : {
        tabs  : [
            {
                label : "Overview",
                reportName : "mid-mile",
                params : "start_date,end_date"
            },
            {
                label : "Forward",
                reportName : "sc-forward",
                params : "date"
            },
            {
                label : "RTO",
                reportName : "sc-rto",
                params : "date"
            },
            {
                label : "Exceptions",
                reportName : "sc-exceptions"
            }
        ]
    },
    "sort-center-visibility" : {
        tabs  : [
            {
                label : "Overview",
                reportName : "sort-center-visibility-overview"
            },
            {
                label : "Forward",
                reportName : "sort-center-visibility-forward"
            },
            {
                label : "RTO",
                reportName : "sort-center-visibility-rto"
            },
            {
                label : "Exceptions",
                reportName : "sort-center-visibility-exceptions",
                params : "date"
            }
        ]
    },
    "lm-hub-visibilty" : {
        tabs  : [
            {
                label : "Overview",
                reportName : "last-mile"
            },
            {
                label : "Forward",
                reportName : "lm-forward"
            },
            {
                label : "RTO",
                reportName : "lm-rto"
            },
            {
                label : "Exceptions",
                reportName : "lm-exceptions"
            },
            {
                label: "RVP",
                reportName: "rvp-global-pendency-dashboard-partner-level"
            },
            {
                label: "NDR",
                reportName: "ndr-instruction-dashboard"
            }
        ]
    },
    "fm-hub-visibility" : {
        tabs  : [
            {
                label : "Overview",
                reportName : "first-mile"
            },
            {
                label : "Forward",
                reportName : "fm-forward"
            },
            {
                label : "RTO",
                reportName : "fm-rto"
            },
            {
                label : "Exceptions",
                reportName : "fm-exceptions"
            }
        ]
    }
};