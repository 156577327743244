import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { checkForDuplicateErrors, schemaToAttrs } from "../form.utils";
import { apiRequest } from "../../../../common/utils/apiGateway"
import { checkHttpStatus } from "../../../../common/utils/utils";
import { Constants } from "../../../../common/utils/constants";

export default function EmployeeWidget(props) {

    const [ options, SetOptions ] = useState([]);
    const [ value, SetValue ] = useState(props.value);
    const [ loading, SetLoading ] = useState(true);

    const uiAttrs = schemaToAttrs(props.uiSchema, props.readonly);

    const disabled = props.disabled || props.readonly || uiAttrs.disabled;
    delete uiAttrs.disabled;

    useEffect(() => {
        apiRequest({
            url    : `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/${Constants.userLocationId}/users`,
            method : "GET"
        })
        .then(response => {
            if (checkHttpStatus(response?.data?.status)) {
                const { users } = response?.data?.response;
                options.push({
                    key     : -1,
                    value   : -1,
                    label   : "All"
                })
                users.map(u => {
                    options.push({
                        key     : u.id,
                        value   : u.id,
                        label   : u.name
                    })
                })
                SetOptions(options)
                SetValue(props.value);
            }
            SetLoading(false);
        })
    }, [])

    useEffect(() => {
        SetValue(props.value);
    }, [ props.value ])

    return (
        <div className={"input-wrapper flex-column"}>
            {
                loading ?
                <Input className="input-loading"/> :
                <Select labelInValue={true}
                    onChange={updatedValue => {
                        var tempValue = updatedValue;
                        var updatedValueArray = updatedValue.map(v => v.key);
                        var valueArray = value && value?.length ? value.map(v => v.key) : [];
                        if ((updatedValueArray && updatedValueArray.length === 0)) {
                            tempValue = undefined;
                        }
                        else if (valueArray?.indexOf(-1) > -1 && updatedValueArray && updatedValueArray?.length > 1 ) {
                            tempValue = updatedValue.filter(v => v.key != -1);
                        }
                        else if (valueArray?.indexOf(-1) < 0 && updatedValueArray && updatedValueArray?.indexOf(-1) > -1 ) {
                            tempValue = [{
                                key   : -1,
                                label : "All"
                            }]
                        }
                        SetValue(tempValue);
                        props.onChange(tempValue);
                    }}  showSearch={true} placeholder={"Select Employee (FE)"}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0}
                    value={value} disabled={disabled} className={uiAttrs.className}
                    {...uiAttrs} mode={props.schema.mode ? props.schema.mode : "multiple"}
                    notFoundContent={"No employee found!"}
                >
                    {
                        options?.length > 0 &&
                        options?.map(option => {
                            if (option.value) {
                                return (
                                    <Select.Option value={option.value} key={option.value}>
                                        {option.label}
                                    </Select.Option>
                                )
                            }
                        })
                    }
                </Select>
            }
            {
                props.rawErrors?.length > 0 &&
                <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
            }
        </div>
    )
}