export const GET_CONFIGURATIONS_BEGIN = "GET_CONFIGURATIONS_BEGIN";
export const GET_CONFIGURATIONS_SUCCESS = "GET_CONFIGURATIONS_SUCCESS";
export const GET_CONFIGURATIONS_FAILURE = "GET_CONFIGURATIONS_FAILURE";

export const GET_PERMISSIONS_BEGIN = "GET_PERMISSIONS_BEGIN";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAILURE = "GET_PERMISSIONS_FAILURE";

//   CURRENT_USER_LEVEL: 'currentUserLevel',
//     LOGGEDIN_USER_LEVEL: 'loggedInUserLevel',
export const INVALID_USER = "invalidUser";

export const APP_LOGIN_BEGIN = "APP_LOGIN_BEGIN";
export const APP_LOGIN_SUCCESS = "APP_LOGIN_SUCCESS";
export const APP_LOGIN_FAILURE = "APP_LOGIN_FAILURE";

export const IS_LSN_USER = 'IS_LSN_USER';
export const GET_APPLICATION_TYPE = 'GET_APPLICATION_TYPE';

export const S3_SYNC_STATUS_BEGIN= "S3_SYNC_STATUS_BEGIN"
export const S3_SYNC_STATUS_SUCCESS = "S3_SYNC_STATUS_SUCCESS"
export const S3_SYNC_STATUS_FAILURE = "S3_SYNC_STATUS_FAILURE"
export const S3_SYNC_STATUS_RESET= "S3_SYNC_STATUS_RESET"

export const GET_PARTNERS_BEGIN = "GET_PARTNERS_BEGIN";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const GET_PARTNERS_FAILURE = "GET_PARTNERS_FAILURE";

export const GET_VENDOR_BEGIN = "GET_VENDOR_BEGIN";
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS";
export const GET_VENDOR_FAILURE = "GET_VENDOR_FAILURE";

export const GET_CITIES_BEGIN = "GET_CITIES_BEGIN";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAILURE = "GET_CITIES_FAILURE";

export const GET_PINCODE_BEGIN = "GET_PINCODE_BEGIN";
export const GET_PINCODE_SUCCESS = "GET_PINCODE_SUCCESS";
export const GET_PINCODE_FAILURE = "GET_PINCODE_FAILURE";

export const GET_SUMMARY_BEGIN = "GET_SUMMARY_BEGIN";
export const GET_SUMMARY_SUCCESS = "GET_SUMMARY_SUCCESS";
export const GET_SUMMARY_FAILURE = "GET_SUMMARY_FAILURE";

export const GET_REPORT_BEGIN = "GET_REPORT_BEGIN";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAILURE = "GET_REPORT_FAILURE";

export const UPDATE_BATCHING_STATUS = "UPDATE_BATCHING_STATUS";