import { useEffect, useState } from "react";
import { userType } from "../../utils";
import RootPartnerCoDView from "./root.partner.view";
import ShipperCoDView from "./shipper.view";
import ViewComponent from "./view.component";

export default function BranchCoDView({ partnerId, expanded, rootPartnerId, showMenu, sparklineSummary, reloadSparkline, showSparklineSummary }) {

    const expandedRowRender = (branchRow, index, indent, expanded) => {
        if (rootPartnerId) {
            return <ShipperCoDView
             partnerId={partnerId} locationId={branchRow.entityId} 
             rootPartnerId={rootPartnerId} location={branchRow} expanded={expanded}/>
        }
        else {
            return <RootPartnerCoDView className="table-cell-bg-white"
             partnerId={partnerId} locationId={branchRow.entityId} reloadSparkline={reloadSparkline}
             parentEntityId={userType() === "PARTNER_ADMIN" && branchRow.entityId}
             location={branchRow} expanded={expanded} successCallback={_ => {
                SetPostObject(undefined);
                setTimeout(() => {
                    SetPostObject({
                        dateRangeMap   : [ "T", "T-1", "T-2", ">T-2", "NET_OUTSTANDING", "PENDING_FOR_APPROVAL"],
                        partnerIds     : [ partnerId ],
                        rootPartnerIds : rootPartnerId ? [ rootPartnerId ] : [ 268, 269 ],
                        groupCriteria  : "BRANCH"
                    });
                }, 200);
             }}/>
        }
    };

    useEffect(() => {
        if (partnerId && expanded) {
            SetPostObject({
                dateRangeMap   : [ "T", "T-1", "T-2", ">T-2", "NET_OUTSTANDING", "PENDING_FOR_APPROVAL"],
                partnerIds     : [ partnerId ],
                rootPartnerIds : rootPartnerId ? [ rootPartnerId ] : [ 268, 269 ],
                groupCriteria  : "BRANCH"
            });
        }
    }, [ partnerId, expanded]);
    
    const [ postObject, SetPostObject ] = useState({
        dateRangeMap   : [ "T", "T-1", "T-2", ">T-2", "NET_OUTSTANDING", "PENDING_FOR_APPROVAL"],
        partnerIds     : [ partnerId ],
        rootPartnerIds : rootPartnerId ? [ rootPartnerId ] : [ 268, 269 ],
        groupCriteria  : "BRANCH"
    })

    return (
        <>
            {
                postObject && partnerId && expanded &&
                <ViewComponent expandedRowRender={expandedRowRender} showMenu={showMenu} showSparklineSummary={showSparklineSummary}
                 postObject={postObject} className="table-cell-bg-white" sparklineSummary={sparklineSummary} />
            }
        </>
    )

}