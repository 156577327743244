import { Icon, Select } from "antd"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchDRSFilters } from "../service";

export default function DrsDashboardMenu({ locationOptions, clusterNameOptions, selectedLocation, selectedFes, selectedTags, selectedClusters, onFilterChange, sparklineData, ALL_CLUSTER_REF }) {
    
    const [ feOptions, SetFeOptions ] = useState([]);
    const [ filterResponse, SetFilterResponse ] = useState({});

    const userLocationMapping = useSelector(state => state.userLocationMappingReducer.userLocationMapping);

    useEffect(() => {
        var locations = selectedLocation && selectedLocation.length > 0 ? [ ...selectedLocation ] : []
        if (selectedClusters && !(selectedLocation && selectedLocation.length > 0)) {
            selectedClusters.map(ulm => {
                if (userLocationMapping[ulm]) {
                    userLocationMapping[ulm].map(l => {
                        locations.push(l.locationId)
                    })
                }
            })
        }
        fetchDRSFilters({
            locationIds : locations
        })
        .then(response => {
            SetFilterResponse(response);
            SetFeOptions(response.feList.map(fe => {
                return {
                    key   : fe.feId,
                    value : fe.feId,
                    label : fe.feName
                }
            }));
        })
    }, [ selectedLocation, selectedClusters ])

    const toggleSuspiciousfilter = _ => {
        if (selectedTags && selectedTags.indexOf("SUSPICIOUS") > -1) {
            onFilterChange(selectedTags.filter(s => s != 'SUSPICIOUS'), "tags");
        }
        else {
            onFilterChange([ ...selectedTags, 'SUSPICIOUS'], "tags");
        }
    }

    const applyCriticalFilter = _ => {
        if (selectedTags && selectedTags.indexOf("CRITICAL") > -1) {
            onFilterChange(selectedTags.filter(s => s != 'CRITICAL'), "tags");
        }
        else {
            onFilterChange([ ...selectedTags, 'CRITICAL'], "tags");
        }
    }

    return (
        <div className="flex-box flex-gap-l spacer-hm align-items-flex-end">
            {
                clusterNameOptions && clusterNameOptions.length > 1 &&
                <Select className="border-radius-m" placeholder={"Select Clusters"} onChange={value => {
                    if (!value || value.length === 0) {
                        return;
                    }
                    var temp = [ ...value ];
                    if ((selectedClusters.length === clusterNameOptions.length) && (value.length > 1 && value.indexOf(ALL_CLUSTER_REF) > -1) ) {
                        temp = temp.filter(v => v != ALL_CLUSTER_REF);
                    }
                    else if ((value.length === clusterNameOptions.length && value.indexOf(ALL_CLUSTER_REF) < 0) ||
                            (selectedClusters.indexOf(ALL_CLUSTER_REF) < 0 && value.indexOf(ALL_CLUSTER_REF) > -1)) {
                        temp = [ ALL_CLUSTER_REF ];
                    }
                    onFilterChange(temp, "clusterNames");
                }} value={selectedClusters.length === clusterNameOptions.length ? [ ALL_CLUSTER_REF ] : selectedClusters} style={{ minWidth : 250, maxWidth : 400 }} mode={"multiple"} >
                    <Select.Option key={ALL_CLUSTER_REF} value={ALL_CLUSTER_REF}> All Clusters </Select.Option>
                    {
                        clusterNameOptions.map(clusters => <Select.Option {...clusters}> {clusters.label} </Select.Option>)
                    }
                </Select>
            }
            {
                locationOptions && locationOptions.length > 1 &&
                <Select className="border-radius-m" placeholder={"Select Locations"} onChange={value => {
                    if (!selectedClusters && (!value || value.length === 0)) {
                        return;
                    }
                    else {
                        onFilterChange(value, "locationIds");
                    }
                }} value={selectedLocation} style={{ minWidth : 250, maxWidth : 400 }} mode={"multiple"} 
                filterOption={(input, option) => option?.props?.children?.toLowerCase().indexOf(input?.toLowerCase()) > -1}>
                    {
                        locationOptions.map(location =>  <Select.Option value={location.locationId} key={location.locationId}>{location.locationName}</Select.Option>)
                    }
                </Select>
            }

            <Select className="border-radius-m" placeholder={"Select Field Executive"} onChange={value => {
                if (!value || value.length === 0) {
                    onFilterChange([],"feIds");
                }
                else {
                    onFilterChange(value,"feIds");
                }
             }}
             filterOption={(input, option) => option?.props?.label?.toLowerCase().indexOf(input?.toLowerCase()) > -1}
             value={selectedFes} style={{ width : 240 }} mode={"multiple"} >
                {
                    feOptions.map(fe =>  <Select.Option {...fe}> {fe.label} </Select.Option>)
                }
            </Select>

            {/* <div onClick={toggleSuspiciousfilter}
             className={`flex-box flex-gap-m font-size-m-2 align-items-center border-grey border-radius-m spacer-hm cursor-pointer
              ${selectedTags && selectedTags.indexOf("SUSPICIOUS") > -1 ? " primary-info-tag" : "" }`}>
                Suspicious 
                ({
                    filterResponse?.suspiciousShipmentCount || sparklineData?.suspiciousShipmentCount &&
                    filterResponse?.suspiciousShipmentCount || sparklineData?.suspiciousShipmentCount
                })
                {
                    selectedTags && selectedTags.indexOf("SUSPICIOUS") > -1 &&
                    <Icon type="close" />
                }
            </div> */}

            {/* <div onClick={applyCriticalFilter}
             className={`flex-box flex-gap-m font-size-m-2 align-items-center border-grey border-radius-m spacer-hm cursor-pointer
              ${selectedTags && selectedTags.indexOf("CRITICAL") > -1 ? " primary-info-tag" : "" }`}>
                Critical
                ({
                    filterResponse?.criticalShipmentCount || sparklineData?.criticalShipmentCount && 
                    filterResponse?.criticalShipmentCount || sparklineData?.criticalShipmentCount
                }) 
                {
                    selectedTags && selectedTags.indexOf("CRITICAL") > -1 &&
                    <Icon type="close" />
                }
            </div> */}
        </div>
    )   
}