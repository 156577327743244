import { Badge, Button, Card, Icon, Spin, Table, Tag } from 'antd';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { reportTypeLegacyArray } from '../reportsv2/config.generator';
import { openNotification } from '../ui/notification/Notification';
import ReportFilters from "./report.filters";

const formatValidity = date => (moment(Number(date)).format('DD/MM/YYYY hh:mm:ss a'));

function ReportListing(props) {
    const { params, setParams, visible, setVisible, filterIndicator } = props;

    const [ columns, SetColumns ] = useState([]);
    
    useEffect(() => {
        const labelReference = reportTypeLegacyArray.reduce((c, n) => {
            c[n.code] = n.label;
            return c;
        }, {})
        var tempColumns = [
            {
                title: 'Report Type',
                dataIndex: 'reportType',
                key: 'reportType',
                align: 'left',
                render: data => labelReference[data] || data
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                render: data => listingTransformed(data)
    
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                align: 'center',
                render: data => formatValidity(data)
    
            },
            {
                title: 'Updated At',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                align: 'center',
                render: data => formatValidity(data)
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                align: 'center',
                render: (data, record) => (
                    <div className="flex-box align-items-center justify-content-flex-end flex-gap-m">
                        {
                            record.status === 'COMPLETED' &&
                            <Button className="lsn-btn-primary" block={true} icon="download"
                             onClick={() => window.open(record.s3Url, "_blank") }>
                                Download
                            </Button>
                        }
                        <div>
                            <CopyToClipboard text={record.s3Url} onCopy={notifyCopyEvent}>
                                <Button className="lsn-btn-secondary"><Icon type="copy" /> Copy</Button>
                            </CopyToClipboard>
                        </div>
                    </div>
                )
            }
        ];
        SetColumns(tempColumns);
    }, [])

    const listingTransformed = data => {

        if (data === 'INITIATED') {
            return <Tag className="ant-table-tag info-tag text-align-center"><span>In Progress</span></Tag>
        } else if (data === 'COMPLETED') {
            return <Tag className="ant-table-tag success-tag text-align-center"><span>Completed</span></Tag>
        }
        return <Tag className="ant-table-tag warning-tag text-align-center"><span>In Progress</span></Tag>
    }

    const notifyCopyEvent = () => {
        openNotification({
            message: 'Copied',
            description: 'Link has been copied',
            className: 'warning'
        })
    }

    const goToReport = () => {
        props.history.push('/appv2/reports/dashboard/download/reportv2');
    }

    return (
        <Card>
            <div className="flex-column flex-gap-l">
                <div className="flex-box align-items-center spacer-hs">
                    <div className="font-size-l-2 text-bold" > Report Listing </div>
                    <div className="flex-box justify-content-flex-end align-items-center flex-1 flex-gap-m">
                        <Badge dot={filterIndicator} offset={[-4, 1]}>
                            <Button icon="filter" className="lsn-btn-secondary" loading={props.loading || columns?.length == 0}
                             onClick={() => setVisible(true)}>
                                Filters
                            </Button>
                        </Badge>
                        <Button className="lsn-btn-secondary" icon="reload" loading={props.loading || columns?.length == 0}
                         onClick={() => props.reportReload(params)}>
                            Refresh
                        </Button>
                        <Button className="lsn-btn-primary" icon="plus-circle"
                         onClick={() => goToReport()}>
                            Generate New Report
                        </Button>
                    </div>
                </div>
                <Spin spinning={props.loading || columns?.length == 0}>
                    <Table bordered={true}
                        columns={columns}
                        dataSource={props?.reportListing?.documents}
                        rowKey={record => record.id}
                        pagination={{
                            onChange: (page, pageSize) => setParams({ ...params, pageNo: page }),
                            total: props.reportListing?.totalCount,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            pageSize: props.reportListing?.currentPageSize,
                            current: props.reportListing?.currentPageNo,
                            showSizeChanger: true,
                            onShowSizeChange: (_, size) => setParams({ ...params, pageSize: size }),
                        }}
                    />
                </Spin>
            </div>
            {
                visible &&
                <ReportFilters visible={visible} {...props} />
            }
        </Card>
    )
}
export default ReportListing;