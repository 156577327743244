const schema = {
    "type"       : "object",
    "required"   : ["uploadType", "s3path"],
    "properties" : {
        "uploadType" : {
            "type"       : "string",
            "title"      : "Assign Type",
            "enum"       : [ "RTO_BULK_UPLOAD"],
            "enumNames"  : [ "Rto Bulk Upload"],
            "default"    : "RTO_BULK_UPLOAD"
        }
    },
    "dependencies"   : {
        "uploadType" : {
            "oneOf"  : [{
                "properties"     : {
                    "uploadType" : {
                        "enum"   : [ "RTO_BULK_UPLOAD" ]
                    },
                    "s3path"     : {
                        "type"   : "string",
                        "title"  : "Upload Rto bulk upload File"
                    }
                },
                "required"  : [ "s3path" ]
            }]
        }
    }
}

const uiSchema = {
    "classNames": "hide-title",
    "uploadType" : {
        "ui:widget"  : "radio",
        "classNames" : "flex-2-input"
    },
    "s3path" : {
        "ui:widget"  : "file",
        "classNames" : "flex-2-input"
    }
}

const errorList = {
    s3path : {
        required : "Upload a file to rto mark"
    }
}

export default { schema, uiSchema, errorList };