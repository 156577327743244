import { Modal, Select, Tag, Spin } from "antd";
import React from "react";

const AssignFEModal = ({
  isAssignModalOpen,
  onAssignCancel,
  allocateWaybillToFE,
  onFeChange,
  feUserId,
  feloading,
  feList,
  filteredLeadData,
  isFeButtonDisabled
}) => {

  return (

    <Modal title="Assign FE" visible={isAssignModalOpen}
      okText={"Confirm"} className="assign-modal"
      onOk={_ => allocateWaybillToFE()}
      destroyOnClose={onAssignCancel} onCancel={onAssignCancel}
      okButtonProps={{ disabled: isFeButtonDisabled || feUserId == null }}
      cancelButtonProps={{ disabled: isFeButtonDisabled  || feUserId == null }}
    >
      <Spin spinning={feloading}>
        <div className="flex-column flex-gap-l">
          <div className="spacer-m grey-bg border-grey flex-column flex-gap-l border-radius-m">

            <div className="flex-box flex-gap-l">
              <div className="flex-column flex-gap-s flex-1">
                <div> Pincodes Selected </div>
                <div className="text-bold"> {filteredLeadData?.countData?.totalPincodeCount ? filteredLeadData.countData.totalPincodeCount : 0} </div>
              </div>
              <div className="flex-column flex-gap-s flex-1">
                <div> Consignee Selected </div>
                <div className="text-bold"> {filteredLeadData?.countData?.totalConsigneeCount ? filteredLeadData.countData.totalConsigneeCount : 0} </div>
              </div>
            </div>

            <div className="flex-box flex-gap-l">
              <div className="flex-column flex-gap-s flex-1">
                <div> Shipments Selected </div>
                <div className="text-bold"> {filteredLeadData?.countData?.totalWaybillCount ? filteredLeadData.countData.totalWaybillCount : 0} </div>
              </div>
            </div>

          </div>

          <Select className="border-radius-m" showSearch={true} style={{ height: "auto" }}
            dropdownStyle={{ borderRadius: 10, marginTop: 10 }} dropdownMenuStyle={{ padding: 0 }}
            placeholder={"Select FE"} onChange={onFeChange} value={feUserId}
            filterOption={(input, option) => option?.props?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0} >
            {
              feList?.map(fe => (
                <Select.Option value={fe.id} key={fe.id} label={fe.name}>
                  <div className="flex-box justify-content-space-between flex-gap-m align-items-center flex-1">
                    <div>{fe.name}</div>
                    <Tag className={fe.onTrip === false ? "success-tag" : fe.onTrip === true ? "warning-tag" : "info-tag"}>
                      {fe.onTrip === true ? "On Trip" : fe.onTrip === false ? "Available" : "Unavailable"}
                    </Tag>
                  </div>
                </Select.Option>
              ))}
          </Select>
        </div>
      </Spin>
    </Modal>
  )
}

export default AssignFEModal;