import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    Modal,
    Input,
    Table,
} from "antd";

import { ENTITY_STATUS, ENTITY_TYPES, REASONS, STATUS_MAPPING } from "../constants";
import { fetchManifestConsignmentDataByStatus, updateReasonsAndList } from "../actions";

import ManifestReasonDropDown from "../reasons-dropdown";

import "./dialog-info.scss";

const { Search } = Input;

const SparkLineDialogInfo = ({ 
    show=false, 
    title, 
    handleClose, 
    reasonStatus, 
    reasonsObj, 
    entityType, 
    entityInfo, 
    sparkLineDialogData, 
    fetchManifestConsignmentDataByStatus, 
    loading, 
    updateReasonsAndList,
    isMobile,
    onReasonUpdate
}) => {
    const [filteredData, setFilteredData] = useState(null);
    const { code, status } = entityInfo;
    const reasons = reasonStatus !== REASONS.scanned ? reasonsObj[reasonStatus] : [];

    const { statusCount, manifests, consignments } = sparkLineDialogData;

    useEffect(() => {
        if(show){
            let payload = {};
            if(entityType === ENTITY_TYPES.connection.name){
                payload = {
                    manifestStatus: STATUS_MAPPING[reasonStatus],
                    connectionCode: code,
                }
            } else {
                payload = {
                    status: STATUS_MAPPING[reasonStatus],
                    manifestCode: code,
                }
            }
            fetchManifestConsignmentDataByStatus(entityType, payload);
        };
    }, [show, reasonStatus]);

    const handleSearch = (val) => {
        const selectedData = entityType === ENTITY_TYPES.connection.name ? manifests : consignments;
        const filterTable = selectedData.filter(o =>
            Object.keys(o).some(k =>
              String(o[k])
                .toLowerCase()
                .includes(val.toLowerCase())
            )
          );
        setFilteredData(filterTable);
    };

    const handleReasonUpdate = (reasonId, entityCode ) => {
        let payload = {
            reasonId: reasonId
        }
        if(entityType === ENTITY_TYPES.connection.name){
            payload["manifestCode"] = entityCode;
            payload["connectionCode"] = code;
        } else {
            payload["manifestCode"] = code;
            payload["waybillNo"] = entityCode;
        }
        if (typeof onReasonUpdate === "function") {
            onReasonUpdate(entityCode);
        }
        updateReasonsAndList(entityType, payload, sparkLineDialogData);
    }

    let columns = [
        {
          title: entityType === ENTITY_TYPES.manifest.name ? "AWB Number" : "Manifest ID",
          dataIndex: entityType === ENTITY_TYPES.manifest.name ? "waybillNo" : "code",
          key: entityType === ENTITY_TYPES.manifest.name ? "waybillNo" : "code",
        },
        ...(!isMobile ? [{
          title: "Destination",
          dataIndex: entityType === ENTITY_TYPES.manifest.name ? "nextLocation" : "destination",
          key: entityType === ENTITY_TYPES.manifest.name ? "nextLocation" : "destination",
        },
        {
          title: "Pincode",
          dataIndex: entityType === ENTITY_TYPES.manifest.name ? "destinationPincode" : "pincode",
          key: entityType === ENTITY_TYPES.manifest.name ? "destinationPincode" : "pincode",
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
        }] : []),
        {
            title: "Action",
            dataIndex: entityType === ENTITY_TYPES.manifest.name ? "waybillNo" : "code",
            key: "action",
            width: (!isMobile ? "3rem" :  undefined),
            render: (e, v, idx) => {
                if(reasonStatus !== REASONS.scanned){
                    return (
                        <ManifestReasonDropDown 
                            isMobile={isMobile}
                            reasons={reasons}
                            reason={v.reason}
                            entityType={entityType}
                            entityCode={entityType === ENTITY_TYPES.manifest.name ? v.waybillNo : v.code}
                            handleUpdate={handleReasonUpdate}
                            disabled={(v.reason ? true : false) || (status !== ENTITY_STATUS[entityType].unloading) }
                        />
                    )
                }
            }
        },
      ];

        if(reasonStatus === REASONS.scanned){
            columns = columns.filter((column) => column.key !== "reason");
        };

    return(
        <Modal
            title={<span>{title} ( <span className={statusCount > 0 ? "current-count" : "" }>{statusCount}</span> )</span>}
            visible={show}
            onCancel={() =>{handleClose(); setFilteredData(null)}}
            width={isMobile ? "95%" : "800px"}
            style={{
                maxWidth : "initial"
            }}
            footer={null}
            centered={isMobile}
            closable
            destroyOnClose
            className="closure-items-info-dialog"
        >
            <div className="dialog-info-container">
                <div className="dialog-info-search-bar">
                    <Search 
                        placeholder="Search"
                        style={{width: "100%"}}
                        onSearch={handleSearch}
                        enterButton
                        allowClear
                        disabled={!(entityType === ENTITY_TYPES.connection.name ? manifests : consignments)}
                    />
                </div>

                <div className="dialog-info-table">
                    <Table 
                        columns={columns}
                        dataSource={ filteredData === null ? (entityType === ENTITY_TYPES.connection.name ? manifests : consignments ) : filteredData }
                        pagination={false}
                        size="small"
                        scroll={{y: 360}}
                        loading={loading}
                    />
                </div>
            </div>
        </Modal>
    )
};

const mapStateToProps = (state, ownProps) => ({
    reasonsObj: state.inventoryClosure.reasons,
    entityType: state.inventoryClosure.selectedEntityType,
    entityInfo: state.inventoryClosure.entityInfo,
    sparkLineDialogData: state.inventoryClosure.sparkLineDialogData,
    loading: state.inventoryClosure.loading
});

const mapDispatchToProps = dispatch => ({
    fetchManifestConsignmentDataByStatus: (entityType, payload) => dispatch(fetchManifestConsignmentDataByStatus(entityType, payload)),
    updateReasonsAndList: (entityType, payload, sparkLineData) => dispatch(updateReasonsAndList(entityType, payload, sparkLineData)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SparkLineDialogInfo);