import { padding } from 'aes-js';
import bwipjs from 'bwip-js';
import React, { useEffect } from "react";
import Barcode from "../../ui/barcode";


const LabelTemplate = React.forwardRef(({ data, flag }, ref) => {

    useEffect(() => {
        // The return value is the canvas element
        bwipjs.toCanvas('mycanvas', {
            bcid: 'datamatrix',       // Barcode type
            text: data?.waybillNo,    // Text to encode
            scale: 3.5,               // 3x scaling factor
            height: 12,              // Bar height, in millimeters
            includetext: true,            // Show human-readable text
            textxalign: 'center',        // Always good to set this
        });
    }, [data])

    const getDestinationAddress = () => {
        if (data && data.valmoClientWaybillDetailsBO){
            return data.valmoClientWaybillDetailsBO.destinationAddressLine1 + " " + data.valmoClientWaybillDetailsBO.destinationAddressLine2 + " " + data.valmoClientWaybillDetailsBO.destinationCity + " "+ data.valmoClientWaybillDetailsBO.destinationState + " " + data.valmoClientWaybillDetailsBO.destinationPinCode
        } else {
          return ""
        }
    }

    const getReturnAddress = () => {
          if (data && data.valmoClientWaybillDetailsBO){
              return data.valmoClientWaybillDetailsBO.returnAddressLine1 + " " + data.valmoClientWaybillDetailsBO.returnAddressLine2 + " " + data.valmoClientWaybillDetailsBO.returnCity + " "+ data.valmoClientWaybillDetailsBO.returnState + " " + data.valmoClientWaybillDetailsBO.returnPinCode
          } else {
            return ""
          }
      }



    return (
    <div>
        <div className=" flex-box flex-column justify-content-space-between" ref={ref} style={{border: "1px solid #c2cfd9", margin: 8}}>
          <table style={{width: "100%"}}>
            <colgroup>
                <col style={{width: "50%"}}/>
                <col style={{width: "50%"}}/>
            </colgroup>
            <tbody>
                <tr style={{border: "solid 1px #c2cfd9"}}>
                    <td style={{border: "solid 1px #c2cfd9"}}>
                        <div style={{textAlign: "center", padding: 4}}>
                            <b style={{fontSize: "14px", color: "#000000"}}>Customer Address</b>

                            <div>
                                <p style={{fontSize: "12px", color: "#293845"}}>{getDestinationAddress()}</p>
                            </div>
                        </div>

                        <hr style={{backgroundColor: "#c2cfd9"}}></hr>

                        <div style={{textAlign: "center", padding: 4}}>
                            
                        <b style={{fontSize: "14px", color: "#000000"}}>If Undelivered, Return to</b>
                        <div>
                           <p style={{fontSize: "12px", color: "#293845"}}>{getReturnAddress()}</p>
                        </div>
                    </div>
                    </td>
                    <td style={{verticalAlign: "top", border: "solid 1px #c2cfd9"}}>
                    <div style={{backgroundColor: "#000000"}}>
                    <span style={{backgroundColor: "#000000", width: "100%", fontSize: "8px", padding: 4, marginBottom: 4, display: 'inline-block'}}>COD: Check the payable amount of the app</span>

                    </div>


                    <div>
                                     <table>
                                     <colgroup>
                <col style={{width: "40%"}}/>
                <col style={{width: "60%"}}/>
            </colgroup>
            <tr>
                <td>
                <p style={{color: "#000000", fontSize: "10px", padding: 4}}>Valmo</p>

                <p style={{color: "#000000", fontSize: "10px", padding: 4}}>{data.valmoClientWaybillDetailsBO.destinationName}</p>

                </td>
                <td>
                <canvas id="mycanvas"></canvas>
                </td>
            </tr>
                                     </table>
                                    
                                </div>
                    <div style={{textAlign: "center", padding: 8}}>
                                    <b style={{textAlign: "center"}}>VALMO</b>
                                    <div className="flex-wrap  spacer-vxs text-align-center">
                                        <Barcode width={1} format="CODE128"
                                        value={data?.waybillNo}
                                        showText={false} barCodeId="waybillNo"
                                        />
                                        <b>{data?.waybillNo}</b>
                                    </div>
                                </div>
                    </td>
                </tr>
                <tr style={{border: "solid 1px #c2cfd9"}}>
                    
                    <td style={{border: "solid 1px #c2cfd9"}}>
                    <div style={{textAlign: "center"}}>
                    <b>{data?.valmoClientWaybillDetailsBO?.customerName}</b>
                                    <div className="flex-wrap  spacer-vxs text-align-center">
                                        <Barcode width={1} format="CODE128"
                                        value={data?.clientWaybillNo}
                                        showText={false} barCodeId="clinetWaybillNo"
                                        />
                                    </div>
                                    <b>{data?.clientWaybillNo}</b>
                    </div>
                    </td>
                    <td style={{border: "solid 1px #c2cfd9"}}>
                    <div style={{textAlign: "left", padding: 4}}>

<h4 style={{fontSize: "12px", color: "#293845"}}>Paymnet Type: <span><b style={{fontSize: "12px", color: "#000000"}}>{data?.valmoClientWaybillDetailsBO?.paymentType}</b></span></h4>
<h4 style={{fontSize: "12px", color: "#293845"}}>COD Amount: <span><b style={{fontSize: "12px", color: "#000000"}}>{data?.valmoClientWaybillDetailsBO?.codAmount}</b></span></h4>
<h4 style={{fontSize: "12px", color: "#293845"}}>Product Desc: <span style={{fontSize: "10px", color: "#293845"}}>{data?.valmoClientWaybillDetailsBO?.description}</span></h4>

</div>
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
    </div>
    )

});
export default LabelTemplate;