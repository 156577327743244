import React from "react";
import ConnectionPrint from "./components/connection.print";
import TripDetails from "./components/trip.details";
import "./trip-sheet.scss";
import "../dockets/print-dockets.scss";
import moment from "moment";
import { Spin } from "antd";

export default function TripSheetPrint ({ tripDetails }) {

    return (
        <div className="spacer-vl white-bg">
            {
                tripDetails?.trip?.tripId ?
                <>
                    <TripDetails trip={tripDetails?.trip} />
                    {
                        tripDetails?.trip?.connections?.map(connection => {
                            return (
                                <>
                                <div className="page-divider"></div>
                                    <ConnectionPrint trip={tripDetails?.trip} connection={connection} />
                                </>
                            )
                        })
                    }
                </>: 
                <div className="flex-box justify-content-center font-size-l-1">
                    <Spin spinning={true}/>
                </div>
            }
        </div> 
    )
}

export const originDetails = [
    {
        name : "Origin Hub Code",
        value : "locationName"
    },
    {
        name : "Origin Address",
        value : "address"
    },
    {
        name : "Dispatch Time",
        value : "dispatchTime",
        dataType : "TIME"
    }
]

export const orderDetails = [
    {
        name  : "Total Value",
        value : "totalValue"
    },
    {
        name  : "Total Weight",
        value : "totalWeight"
    },
    {
        name  : "Total No. of Manifests",
        value : "totalNoOfManifests"
    },
    {
        name  : "Total No. of Shipments",
        value : "totalNoOfShipments"
    }
]

export const RowDetails = (name, value, dataType) => {
    return (
        <tr key={name+value}>
            <td colSpan={1} className="spacer-s text-bold"> {name} </td>
            <td colSpan={3} className="spacer-s">
                {
                    dataType !== undefined
                    ? (dataType === "TIME" && value ? moment(value).format("YYYY-MM-DD H:mm") : "")
                    : value
                }
            </td>
        </tr>
    )
}