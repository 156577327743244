import moment from "moment";
import { apiRequest } from "../../../common/utils/apiGateway";
import { Constants } from "../../../common/utils/constants";
import { checkHttpStatus } from "../../../common/utils/utils";
import { generateFetchSettlementsRequest } from "./fetch.service";

export const generateRemitPostObject = (params = {}) => {
    return {
        remitter : {
            locationIds : [ params.locationId || Constants.LOGGED_PARTNER_LOCATION_ID  ],
            partnerIds  : [ params.partner?.id || Constants.PARTNER_ID  ],
            customerIds : [ params.customerId ],
            customerAccountTypes: []
        },
        amount   : {
            amount   : params.amount,
            currency : "INR"
        },
        payee   : {
            locationIds : [ params.locationId || Constants.LOGGED_PARTNER_LOCATION_ID  ],
            partnerIds  : [ params.partner?.id || Constants.PARTNER_ID  ],
            customerIds : [ params.customerId ],
            customerAccountTypes: [],
            shipperIds  : [null]
        },
        paymentEntity   : {
            partnerIds  : [ params.partner?.id || Constants.PARTNER_ID  ],
            locationIds : [ params.locationId || Constants.LOGGED_PARTNER_LOCATION_ID  ]
        },
        settlementInfo  : {
            expectedAmount    : params.amount,
            actualAmount      : params.amount,
            receiptReqRemarks : "CD",
            user : {
                id : params.userId || Constants.sessionUser.id
            },
            partner : {
                id  : params.partner?.id || Constants.PARTNER_ID 
            },
            customerAccountNumber : params.customerId, 
            customerAccount       : {
                id : params.customerId, 
                accountType : "PARTNER"
            },
            approverPartner : {
                id : params.customerId,
                accountType : "PARTNER"
            },
            approverPartnerType : "PARTNER",
            paymentInfo : {
                isActive     : true,
                amount       : params.amount,
                actualAmt    : params.amount,
                actualAmount : params.amount,
                remarks      : params.proofFileLink,
                user   : {
                    id : params.userId || Constants.sessionUser.id
                },
                partner : {
                    id  : params.partner?.id || Constants.PARTNER_ID
                },
                paymentMeta   : JSON.stringify({
                    bankName  : params.bankName || "",
                    bankRefNo : params.transactionNumber || "",
                    remarks   : params.transactionRemarks || "",
                    transactionDate : typeof params.transactionDate === "string" ? params.transactionDate : 
                                      typeof params.transactionDate === "object" ? moment(params.transactionDate).format("YYYY-MM-DD") : ""
                }),
                currency : "INR",
            },
            paymentOption: params.paymentMode,
            approvalType: "BRANCH_CUSTOMER",
            approvalStatus: "REQUEST_FOR_APPROVAL",
            isActive: true,
            location: {
                id: params.locationId || Constants.LOGGED_PARTNER_LOCATION_ID
            },
            customerAccountType: "PARTNER"
        },
        remitanceType: "USER_TO_CUSTOMER_REMITTANCE"
    }
}

export const approveRejectSetllement = params => new Promise((resolve, reject) => {
    apiRequest({
        method: "post",
        url: `/b2b/v1/cod/approve`,
        data: params
    })
    .then(response => {
      resolve(response?.data?.response);
    })
    .catch(error => reject(error));  
})

export const remitAmount = data => new Promise((resolve, reject) => {
    apiRequest({
        url    : "/b2b/v1/cod/requestApproval",
        method : "POST",
        data   : data
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status) && response?.data?.response?.sync) {
            resolve(response?.data?.response);
        }
        else {
            reject(response?.data?.status);
        }
    })
    .catch(error => reject(error));  
})

export const exportSetllementInfoReport = data => new Promise((resolve, reject) => {
    const postObject = {
        userId  : Constants.sessionUser.id,
        locationId : localStorage.getItem("loggedPartnerLocationId"),
        partnerId  : Constants.PARTNER_ID,

        fetchSettlementsRequest : { ...generateFetchSettlementsRequest(data) },

        documentType    : "COD_SETTLEMENT_REPORT",
        documentSubType : null,
        isDatePickup    : true,
        loggedInUserTokken : true
    };

    delete postObject.fetchSettlementsRequest.pageNo;
    delete postObject.fetchSettlementsRequest.pageSize;

    apiRequest({
        url    : "b2b/v1/reports/COD_SETTLEMENT_REPORT/get",
        method : "POST",
        data   : postObject
    })
    .then(response => {
      resolve(response?.data?.response);
    })
    .catch(error => reject(error));
})