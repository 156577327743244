import { useEffect, useState } from "react";
import ViewComponent from "./view.component";

export default function ShipperCoDView({ partnerId, locationId, expanded, rootPartnerId }) {

    useEffect(() => {
        if (partnerId && expanded && locationId && rootPartnerId) {
            SetPostObject({
                dateRangeMap   : [ "T", "T-1", "T-2", ">T-2", "NET_OUTSTANDING", "PENDING_FOR_APPROVAL"],
                partnerIds     : [ partnerId ],
                locationIds    : [ locationId ],
                rootPartnerIds : [ rootPartnerId ],
                groupCriteria  : "SHIPPER"
            });
        }
    }, [  partnerId, locationId, expanded, rootPartnerId ]);
    
    const [ postObject, SetPostObject ] = useState({
        dateRangeMap   : [ "T", "T-1", "T-2", ">T-2", "NET_OUTSTANDING", "PENDING_FOR_APPROVAL"],
        partnerIds     : [ partnerId ],
        locationIds    : [ locationId ],
        rootPartnerIds : [ rootPartnerId ],
        groupCriteria  : "SHIPPER"
    })

    return (
        <>
            {
                partnerId && expanded && locationId && rootPartnerId &&
                <ViewComponent postObject={postObject} className="table-cell-bg-white"/>
            }
        </>
    )
}