export default {
    "schema" : {
        "title"       : "Reports",
        "type"        : "object",
        "required"    : [ "reportType" ],
        "properties"  : {
            "reportType"  : {
                "title"   : "Report Type",
                "type"    : "string"
            }
        }
    },

    "uiSchema" : {
        "classNames"  : "hide-title",
        "reportType"      : {
            "ui:placeholder"  : "Select Report Type",
            "ui:widget"       : "ReportTypes"
        }
    }
}