import BranchCoDView from "./branch.view";
import ViewComponent from "./view.component";

export default function PartnerCoDView ({ rootPartnerId, reloadSparkline, sparklineSummary, showSparklineSummary }) {

    const postObject = {
        rootPartnerIds : rootPartnerId ? [ rootPartnerId ] : [],
        groupCriteria  : "PARTNER"
    }

    const expandedRowRender = (partnerRow, index, indent, expanded) => {
        return <BranchCoDView partnerId={partnerRow.entityId} partner={partnerRow}
         rootPartnerId={rootPartnerId} expanded={expanded} />
    }

    return (
        <ViewComponent expandedRowRender={expandedRowRender} sparklineSummary={sparklineSummary} showSparklineSummary={showSparklineSummary}
         postObject={postObject} showMenu={true} reloadSparkline={reloadSparkline}
         actions={{
            onAcceptRejectSuccess : _ => {
                reloadSparkline();
            }
         }} />
    )
}