import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Card,
    Form,
    Select,
    Spin,
    Button,
    Icon,
} from "antd";

import { ENTITY_TYPES } from "./constants";
import { fetchReasons, getEntityScanInfo, setResourceType } from "./actions";

import ManifestUnloadCard from "./unload-card";

import "./manifest-closure.scss";
import ScanForm from "./scan.form";
import { notifyApiError } from "../../../common/utils/utils";
import { SoundNotificationService } from "../../../services/sound.service";

const { Option } = Select;

const soundService = new SoundNotificationService();

const ManifestClosure = ({ loading, entityType, entityInfo,  setResourceType, getEntityScanInfo, fetchReasons, history }) => {
    const [code, setCode] = useState("");

    const handleChange = (e) => {
        const val = (e.target.value).trim();
        setCode(val);
    };

    const handleEntityChange = (val) => {
        setResourceType(val);
        fetchReasons(val);
        setCode("");
    };

    const handleScanCode = isMobile => {
        let payload = {
            scanInputType: "BARCODE_SCANNER"
        };

        if (!code || code.trim() == "") {
            soundService.playGeneralWarning();
            return notifyApiError("Manifest ID cannot be empty!")
        }
        if(entityType === ENTITY_TYPES.connection.name){
            payload["connectionCode"] = code;
        } else if(entityType === ENTITY_TYPES.trip.name){
            if (isMobile) {
                return history.replace(`/appv2/mweb/inventory/dashboard/manifest-closure/trips/${code?.trim()}`)
            }
            else {
                return history.replace(`/appv2/inventory/dashboard/manifest-closure/trips/${code?.trim()}`)
            }
        } else {
            payload.scanInputType = isMobile ? "MOBILE_SCANNER" : "BARCODE_SCANNER";
            payload["manifestCode"] = code;
        }
        getEntityScanInfo(entityType, payload);
        setCode("");
    }

    useEffect(() => {
        return () => {
            handleEntityChange(undefined);
        }
    }, [])

    if (window.outerWidth <= 600 && window.location.pathname?.indexOf("mweb") > -1) {
        return ( 
            <div className="manifest-closure-container mweb-wrapper">
                <Spin spinning={loading}>
                    {
                        !entityInfo ?
                        <>
                            {
                                !entityType ?
                                <div className="white-bg flex-column flex-gap-l align-items-stretch spacer-l">
                                    {
                                        Object.keys(ENTITY_TYPES).map(key => {
                                            const eType = ENTITY_TYPES[key]
                                            return (
                                                <Button key={key} onClick={_ => handleEntityChange(eType.name)} type="ghost" style={{height: "auto"}} >
                                                    <div className="flex-box justify-content-space-between align-items-center spacer-s">
                                                        <div> {eType.label} </div>
                                                        <Icon type="right" />
                                                    </div>
                                                </Button>
                                            )
                                        })
                                    }
                                </div>:
                                <div className="white-bg flex-column spacer-m flex-gap-xl">
                                    <ScanForm isMobile={true} entityType={entityType} handleChange={handleChange} code={code} handleScanCode={handleScanCode} history={history} />
                                </div>
                            }
                        </> :
                        <ManifestUnloadCard isMobile={true} goBack={_ => handleEntityChange(undefined)} loading={loading} />
                    }
                </Spin>
            </div>
        )
    }

    return(
        <div className="manifest-closure-container">
            <Spin spinning={loading}>
                <Card 
                    style={{marginBottom: "1%"}}
                    bodyStyle={{
                        paddingBottom: "0", 
                        paddingTop: "24px", 
                        paddingRight: "24px", 
                        paddingLeft: "24px"
                        }}>
                    <div className="manifest-closure-options">
                        <div className="option-1">
                            <Form layout="horizontal" colon={false}>
                                <Form.Item label="Closure Type" labelCol={{span: 6}} wrapperCol={{span: 18}}>
                                    <Select 
                                        placeholder="Select Closure Type" 
                                        style={{widht: "100%"}}
                                        onChange={handleEntityChange}
                                        value={entityType}
                                    >
                                        <Option value={ENTITY_TYPES.connection.name}>{ENTITY_TYPES.connection.label}</Option>
                                        <Option value={ENTITY_TYPES.manifest.name}>{ENTITY_TYPES.manifest.label}</Option>
                                        <Option value={ENTITY_TYPES.trip.name}>{ENTITY_TYPES.trip.label}</Option>
                                    </Select>
                                </Form.Item>

                                
                            </Form>
                        </div>

                        <div className="option-2">
                        { entityType && <ScanForm entityType={entityType} handleChange={handleChange} code={code} handleScanCode={handleScanCode} /> }
                        </div>
                        
                    </div>
                </Card>
                {entityInfo && (
                    <ManifestUnloadCard loading={loading} />
                )}
            </Spin>
        </div>
    )
};

const mapStateToProps = (state, ownProps) => ({
    loading: state.inventoryClosure.loading,
    entityType: state.inventoryClosure.selectedEntityType,
    entityInfo: state.inventoryClosure.entityInfo,
});

const mapDispatchToProps = dispatch => ({
    setResourceType: (entityType) => dispatch(setResourceType(entityType)),
    getEntityScanInfo: (entityType, payload) => dispatch(getEntityScanInfo(entityType, payload)),
    fetchReasons: (entityType) => dispatch(fetchReasons(entityType)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ManifestClosure);