import PodService from "../../../services/pod/pod.service";
import { ACTION_TYPES } from "./constants";
import activeAwbIcon0 from "../../../assets/img/icons/images/3_1_Inventory.svg";
import activeAwbIcon1 from "../../../assets/img/icons/images/3_2_Inventory.svg";
import activeAwbIcon2 from "../../../assets/img/icons/images/3_3_Inventory.svg";
import MiscService from "../../../services/misc.service";
import { checkConsignments } from "../../../common/utils/utils";

const podService=new PodService();

export const getConsignmentsData= (payload, isReset = false) => dispatch => {
    dispatch(fetchConsignmentsBegin());
    podService.getConsignment(payload)
    .then((res) => {
        dispatch(fetchConsignmentsSuccess({
            ...res?.data?.response, drses: res?.data?.response?.drses?.map(drs => {
                                    drs["customObj"] = checkConsignments(drs);
                                    return drs;
                              })
                           } || res?.data?.viewResponse, isReset));
                       })
      .catch(err => {
        dispatch(fetchConsignmentsFailure(err));
      })
};

const fetchConsignmentsBegin = () => {
    return {
      type: ACTION_TYPES.FETCH_CONSIGNMENTS_BEGIN
    }
  };
  
const fetchConsignmentsSuccess = (payload) => {
    return {
      type: ACTION_TYPES.FETCH_CONSIGNMENTS_SUCCESS,
      payload
    }
  };
  
const fetchConsignmentsFailure = (payload) => {
    return {
      type: ACTION_TYPES.FETCH_CONSIGNMENTS_FAILURE,
      payload
    }
  };
  
export const getSparkLineDataFunction = () => dispatch => {
    dispatch(fetchSparkLineDataBegin());
    MiscService.getSummary("POD_LIVE")
    .then((summaryDetails) => {
        const dataMapping = {
            "OPEN_DRS": {
                 icon: activeAwbIcon0,
                 title: "Open DRS"
            },
            "PENDING_CONSIGNMENT_LOC": {
                icon: activeAwbIcon1,
                title: "Pending AWB"
            },

            "SLA_BREACH_CONSIGNMENTS": {
                icon: activeAwbIcon2,
                title: "SLA Breached AWB"
            }
        }
            let dataCardList = [];
            Object.entries(summaryDetails).forEach((summaryDetail, index) => {
                const key = summaryDetail[0];
                const value = summaryDetail[1];
                dataCardList.push({
                    icon: dataMapping[key].icon,
                    title: dataMapping[key].title,
                    description: value.count, 
                    count: value.count, 
                    downloadType: key,
                    documentType: key
                })
             })
             dispatch(fetchSparkLineDataSuccess(dataCardList));
        })
        .catch(err => {
            dispatch(fetchSparkLineDataFailure(err));
        })
  };

const fetchSparkLineDataBegin = () => {
    return {
        type: ACTION_TYPES.FETCH_SPARK_LINE_DATA__BEGIN
    }
};

const fetchSparkLineDataSuccess = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_SPARK_LINE_DATA__SUCCESS,
        payload
    }
};

const fetchSparkLineDataFailure = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_SPARK_LINE_DATA__FAILURE,
        payload
    }
};

export const isCloseDRSAllowed=(drsStatus) =>  {
    const notAllowedStatus = ["CLOSED", "COMPLETED", "OPEN"];
    if (this.application === "B2B") {
      notAllowedStatus.push("PENDING");
    }
    return notAllowedStatus.indexOf(drsStatus) < 0;
  }
  