import { useEffect, useState } from "react";
import { initiateConsigneeCall } from "../../../TrackingPopup/action";
import { checkHttpStatus, notifyApiSuccess } from "../../../../common/utils/utils";
import ValidateRejectModal from "./validate.reject";
import { Button, Modal } from "antd";

export default function SuspiciousUndelAction({waybillNo, reasonDescription, suspiciousReason, drsCode, hideActionButtons}) {
    
    console.log(drsCode);
    
    const [ validateModalType, SetValidateModalType ] = useState(false);
    const [ validateWaybillDetails, SetValidateWaybillDetails ] = useState();

    useEffect(_ => {
        SetValidateWaybillDetails({
            subStatus : reasonDescription,
            waybillNo : waybillNo,
            suspiciousReason : suspiciousReason,
            entityCode : drsCode
        });
    }, [ waybillNo ])
    
    return (
        <>
            <div className="flex-column flex-gap-m" style={{ lineHeight : 1 }}>
                {
                    !hideActionButtons && 
                    <div className="flex-box flex-gap-m flex-wrap">
                        <Button onClick={_ => SetValidateModalType("ACCEPT")}
                         className="lsn-btn-success"> Accept </Button>
                        <Button onClick={_ => SetValidateModalType("REJECT")}
                         className="lsn-btn-error"> Reattempt </Button>
                    </div> 
                }
                <Button onClick={e => {
                    const buttonElem = e.target;
                    buttonElem.disabled = true;
                    const ogInnerHTML = buttonElem.innerHTML;
                    buttonElem.innerHTML = "Call again in 30s";
                    const resetButton = () => {
                        buttonElem.disabled = false;
                        buttonElem.innerHTML = ogInnerHTML;
                    };
                    initiateConsigneeCall(validateWaybillDetails?.waybillNo, "PRIMARY")
                    .then(response => {
                        if (checkHttpStatus(response?.data?.status)) {
                            notifyApiSuccess("Call initiated successfully", validateWaybillDetails?.waybillNo);
                            setTimeout(resetButton, 30000);
                        }
                        else {
                            resetButton();
                        }
                    })
                    .catch(e => resetButton(e));
                }} icon="phone" className="lsn-btn-primary">
                    Call Customer
                </Button>
            </div>
            <ValidateRejectModal validateModalType={validateModalType} 
                SetValidateModalType={SetValidateModalType}
                onSuccess={_ => Modal.success({
                    content : "In scan shipment again to receive shipment."
                })} 
                validateWaybillDetails={validateWaybillDetails} />
        </>
    )
}