const schema = {
    "type": "object",
    "properties": {
        "connectionDetails": {
            "title": "Connection details",
            "type": "object",
            "properties": {
                "new": {
                    "type": "array",
                    "items": {
                        "required": ["origin", "destination"],
                        "properties": {
                            "origin": {
                                "title": "",
                                "required": ["name"],
                                "properties": {
                                    "name": {
                                        "title": "Origin",
                                        "type": "string"
                                    },
                                    "type": {
                                        "title": "Origin Type",
                                        "type": "string",
                                        "enum": ["FMSC", "LMSC"],
                                        "enumNames": ["FMSC", "LMSC"]
                                    }
                                }
                            },
                            "destination": {
                                "title": "",
                                "required": ["name"],
                                "properties": {
                                    "name": {
                                        "title": "Destination",
                                        "type": "string"
                                    },
                                    "type": {
                                        "title": "Destination Type",
                                        "type": "string",
                                        "enum": ["FMSC", "LMSC"],
                                        "enumNames": ["FMSC", "LMSC"]
                                    }
                                }
                            },
                            "crossDock": {
                                "title": "",
                                "properties": {
                                    "name": {
                                        "title": "Next Cross Dock",
                                        "type": "string"
                                    },
                                    "type": {
                                        "title": "Cross Dock Type",
                                        "type": "string",
                                        "enum": ["FMSC", "LMSC"],
                                        "enumNames": ["FMSC", "LMSC"]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

const uiSchema = {

    "ui:options": {
        "orderable": false
    },
    "connectionDetails": {
        "classNames": "connection-container",
        "existing": {
            "ui:options": {
                "addable": false,
                "displayToggleBtn": true
            },
            "items": {
                "className": "connection-array",
                "origin": {
                    "name": {
                        "ui:placeholder": "Enter origin name.",
                        "ui:readOnly": true
                    },
                    "type": {
                        "ui:placeholder": "Select origin type.",
                        "ui:readonly": true
                    }
                },
                "destination": {
                    "name": {
                        "ui:placeholder": "Enter destination name.",
                        "ui:readOnly": true
                    },
                    "type": {
                        "ui:placeholder": "Enter destination type.",
                        "ui:readonly": true
                    }
                },
                "crossDock": {
                    "name": {
                        "ui:placeholder": "Enter next cross dock name.",
                        "ui:readOnly": true
                    },
                    "type": {
                        "ui:placeholder": "Enter next cross dock type.",
                        "ui:readonly": true
                    }
                }
            }
        },
        "new": {
            "items": {
                "className": "connection-array",
                "origin": {
                    "name": {
                        "ui:placeholder": "Enter origin name."
                    },
                    "type": {
                        "ui:placeholder": "Select origin type."
                    }
                },
                "destination": {
                    "name": {
                        "ui:placeholder": "Enter destination name."
                    },
                    "type": {
                        "ui:placeholder": "Enter destination type."
                    }
                },
                "crossDock": {
                    "name": {
                        "ui:placeholder": "Enter next cross dock name."
                    },
                    "type": {
                        "ui:placeholder": "Enter next cross dock type."
                    }
                }
            }
        }
    }

}

const errorList = {

}

export default { schema, uiSchema, errorList }