import React from 'react';
import { accordian as Accordian, Panel } from '../../components/ui/accordian/Accordian';
import { typography as Typography } from '../../components/ui/typography/Typography';
import { TrackingFooter } from './TrackingFooter/TrackingFooter';
import { connect } from 'react-redux';
import * as actions from './action';
import { expandedRow as ExpandedRow } from './ExpandedRow/ExpandedRow';
import { formatDate, formatTime, notifyApiError, notifyApiSuccess, openWindowWithData, formatDateoption, checkHttpStatus, getValueFromKey, capitalizeString } from '../../common/utils/utils';
import { map as Map } from '../../components/ui/map/Map';
import { Constants, CONTACT_NUMBER } from '../../common/utils/constants';
import { Divider, Button, Icon, Table, Spin } from 'antd';
import EventDetails from './event.details';
import './TrackingPopup.scss';
import { resetWaybillDetails } from '../tracking/action';
import { EventStatusMap } from '../../common/utils/event.status.map';
import { Link } from 'react-router-dom';
import PodOtp from './pod.otp';

const TRACKING_EVENTS = {
    BOOKING: 'BOOKING',
    INSCAN: 'INSCAN',
    DRS_SCAN: 'DRS_SCAN',
    DELIVERED: 'DELIVERED',
    UNDELIVERED: 'UNDELIVERED',
    RESCHEDULED: 'RESCHEDULED',
    INWARD_SCAN: 'INWARD_SCAN',
    BOOKING_RECEIVED: 'BOOKING_RECEIVED',
    THIRD_PARTY_SYNC_EVENT: 'THIRD_PARTY_SYNC_EVENT',
    ARRIVED_AT_LOCATION: 'ARRIVED_AT_LOCATION',
    CALL_RECORD: 'CALL_RECORD',
    POD_STREAM: 'POD_STREAM',
    PICKUP_CREATED: 'PICKUP_CREATED',
    OUT_FOR_PICKUP: 'OUT_FOR_PICKUP',
    PICKUP_ASSIGNED: 'PICKUP_ASSIGNED',
    UPDATED_STATUS: 'UPDATED_STATUS',
    MANIFEST_SCAN: 'MANIFEST_SCAN',
    THC_SCAN: 'THC_SCAN',
    PICKUP_CANCELLED: 'PICKUP_CANCELLED',
    PICKED_UP: 'PICKED_UP',
    UPDATED: 'UPDATED',
    MANIFEST_POD: 'MANIFEST_POD',
    RTO_IN: 'RTO_IN',
    RTO_DRS_SCAN: 'RTO_DRS_SCAN',
    UNDELIVERED_B2B: 'UNDELIVERED_B2B',
    RETURN_B2B: 'RETURN_B2B',
    MANIFEST_RECEIVED: 'MANIFEST_RECEIVED',
    MANIFEST_SHORT: 'MANIFEST_SHORT',
    RTO_DELIVERED: 'RTO_DELIVERED',
    RTO_UNDELIVERED: 'RTO_UNDELIVERED',
    WRONG_FACILITY_SCAN: 'WRONG_FACILITY_SCAN',
    HANDOVER: "HANDOVER",
    TAMPERED: "TAMPERED",
    CONSIGNMENT_MARKED_LOST: "CONSIGNMENT_MARKED_LOST",
    QC_FAILED: "QC_FAILED",
    BOOKING_CANCELLED: "BOOKING_CANCELLED",
    DEL_OTP_VIEWED: "DEL_OTP_VIEWED"
}
class TrackingPopup extends React.Component {
    state = {
        prepareExpandedRowData: true,
        copied: false,
        columns: [{
            title: 'DATE',
            dataIndex: 'eventTime',
            render: (__eventTime, event) => formatDate(event?.eventTime)
        }, {
            title: 'TIME',
            dataIndex: "__eventTime",
            render: (__eventTime, event) => formatTime(event?.eventTime)
        }, {
            title: 'EVENT',
            dataIndex: 'eventType',
            render: (text, row) => <div>{text} {row.isSuspicious ? <Icon type="warning" className="icon-warning" theme="filled" /> : null}</div>
        }, {
            title: 'LOCATION',
            dataIndex: 'data.location_name',
            render: (d, e) => <> {d || getValueFromKey(e, "data.loc_name") || getValueFromKey(e, "data.locationName") || getValueFromKey(e, "user.location.name") || "- NA -"} </>
        }, {
            title: 'USER',
            dataIndex: 'user.name',
        }, {
            title: 'ACTION',
            dataIndex: 'action',
            render: (a, row) => {
                let text = 'View';
                if (row?.data?.attributes?.latitude && row?.data?.attributes?.longitude) {
                    text = 'View Map';
                }
                return <a href="javascript:;" className="expand-link">{text}</a>
            }
        }],
        accordianData: [],
        tableData: []
    }
    componentDidMount() {
        this.props.getTrackingDetails({ data: this.props.getDetailInput, source: this.props.source });
    }
    componentWillUnmount() {
        this.props.resetWaybillDetails();
    }
    componentDidUpdate(prevProps) {
        if (this.props.trackingDetails !== prevProps.trackingDetails) {
            this.prepareAccordianData();
        }
        if (this.state.prepareExpandedRowData && (this.props.trackingDetails !== prevProps.trackingDetails)) {
            this.appendExpandedRowData();
        }
        if (this.props.consignmentPodUpdate && !prevProps.consignmentPodUpdate) {
            notifyApiSuccess('POD Action complete', undefined, this.props.resetNotificationData);
            this.props.onClose(); //close popup
        }
        if (this.props.consignmentPodUpdate === false) {
            notifyApiError(this.props.errorMessage, undefined, this.props.resetNotificationData)
        }
        if (this.props.primaryNumber !== prevProps.primaryNumber || this.props.secondaryNumber !== prevProps.secondaryNumber || this.props.address !== prevProps.address || this.props.shipperPrimaryNumber !== prevProps.shipperPrimaryNumber) {
            this.prepareAccordianData();
        }
    }
    prepareAccordianData = () => {

        var consigneeAddress = [];
        const _address = this.props?.address || this.props?.trackingDetails?.consignment?.consignee?.address;
        if (_address?.address1) {
            consigneeAddress.push(_address?.address1);
        }
        if (_address?.address2) {
            consigneeAddress.push(_address?.address2);
        }
        if (_address?.address3) {
            consigneeAddress.push(_address?.address3);
        }

        var customerId = this.props?.trackingDetails?.consignment.customerId + "";
        var config = localStorage._configrations ? JSON.parse(atob(localStorage._configrations)) : {};
        var { addressMaskEnabledCustomers } = config;
        var maskAddress = addressMaskEnabledCustomers && addressMaskEnabledCustomers.indexOf(customerId) > -1;

        var { sellerInformationEnabledCustomers } = config;
        var maskShellerInfo = sellerInformationEnabledCustomers && sellerInformationEnabledCustomers.indexOf(customerId) > -1;

        var { enabled_seller_pickup_pending_report_tracking_view } = config;
        if (enabled_seller_pickup_pending_report_tracking_view && enabled_seller_pickup_pending_report_tracking_view === "true") {
            maskShellerInfo = false
        }

        const accordianData = [
            {
                id: 0,
                title: 'Shipper Details',
                data: {
                    'Name': this.props?.trackingDetails?.consignment?.shipper?.name,
                    'Address': maskShellerInfo ? "You are not permitted to view Shipper's Address" : [this.props?.trackingDetails?.consignment?.shipper?.address?.address1, this.props?.trackingDetails?.consignment?.shipper?.address?.address2, this.props?.trackingDetails?.consignment?.shipper?.address?.address3].join(','),
                    'Pincode': this.props?.trackingDetails?.consignment?.shipperPincode?.zipcode,
                    'Contact No': maskShellerInfo ? "You are not permitted to view Shipper's Contact No" : <span className="clickable-link"
                        onClick={() => {
                            if (!this.props?.shipperPrimaryNumber) {
                                this.requestForPhoneNumber(CONTACT_NUMBER.SHIPPER_PRIMARY)
                            }
                        }}>
                        {(this.props?.shipperPrimaryNumber) ? this.displayNumber(this.props.shipperPrimaryNumber, CONTACT_NUMBER.SHIPPER_PRIMARY) :
                            (this.props.shipperPrimaryNumber === '') ? 'NA' : <span style={{ color: "#FF0000" }}>Request for primary number.<br />Note: This request will be logged and if misused, you will be severely penalised.</span>}
                    </span>,
                },
                open: this.props.shipperPrimaryNumber ? true : false
            },
            {
                id: 1,
                title: 'Consignee Details',
                data: {
                    'Acc No': 'NA',
                    'Name': this.props?.trackingDetails?.consignment?.consignee?.name,
                    'Address': (
                        <span>
                            {
                                maskAddress ?
                                    !this.props?.address ?
                                        <span className="clickable-link" onClick={_ => {
                                            if (!this.props?.address) {
                                                this.requestForPhoneNumber(CONTACT_NUMBER.ADDRESS)
                                            }
                                        }}>
                                            {<span style={{ color: "#FF0000" }}>Request for Consignee's Address.<br />Note: This request will be logged and if misused, you will be severely penalised.</span>}
                                        </span> :
                                        this.props?.address === "ACCESS_DENIED" ?
                                            <span className="clickable-link">
                                                Access denied. Contact area manager for  address view access
                                            </span> :
                                            consigneeAddress.join(", ") :
                                    consigneeAddress.join(", ")
                            }
                        </span>
                    ),
                    'Pincode': this.props?.trackingDetails?.consignment?.consignee?.address?.pincode?.zipcode,
                    'Primary No': <span className="clickable-link"
                        onClick={() => {
                            if (!this.props?.primaryNumber) {
                                this.requestForPhoneNumber(CONTACT_NUMBER.PRIMARY)
                            }
                        }}>
                        {(this.props?.primaryNumber) ? this.displayNumber(this.props.primaryNumber, CONTACT_NUMBER.PRIMARY) :
                            (this.props.primaryNumber === '') ? 'NA' : <span style={{ color: "#FF0000" }}>Request for primary number.<br />Note: This request will be logged and if misused, you will be severely penalised.</span>}
                    </span>,
                    'Secondary No': <span className="clickable-link"
                        onClick={() => {
                            if (!this.props?.secondaryNumber) {
                                this.requestForPhoneNumber(CONTACT_NUMBER.SECONDARY)
                            }
                        }}>
                        {(this.props?.secondaryNumber) ? this.displayNumber(this.props.secondaryNumber, CONTACT_NUMBER.SECONDARY) :
                            (this.props.secondaryNumber === '') ? 'NA' : ''}
                    </span>,
                },
                open: this.props.primaryNumber ? true : false
            },
            {
                id: 2,
                title: 'Consignment Details',
                data: {
                    'Product Detail': this.props?.trackingDetails?.consignment?.description,
                    'Total Shipment Count': this.props?.trackingDetails?.consignment?.totalShipmentCount,
                    'Total Weight': `${this.props?.trackingDetails?.consignment?.totalWeight} Kg(s)`,
                    'Total Chargeble Weight': `${this.props?.trackingDetails?.consignment?.totalChargableWeight} Kg(s)`
                }
            },
            {
                id: 3,
                title: 'Booking Details',
                data: {
                    'Booking Date': formatDate(this.props?.trackingDetails?.consignment?.bookingDate),
                    'Shipment Value': this.props?.trackingDetails?.consignment?.consignmentAmount,
                    'Payable Amount': this.props?.trackingDetails?.consignment?.payableAmount
                }
            },
            {
                id: 4,
                title: 'Delivery Details',
                data: {
                    'Expected Delivery Date': formatDate(this.props?.trackingDetails?.consignment?.expectedDeliveryDate),
                    'OTP': <PodOtp waybill={this.props.waybillNo} />
                },
            },
            {
                id: 5,
                title: 'POD Images',
                data: {},
                render: () => {
                    const images = [];
                    this.props?.trackingDetails?.consignment?.consignmentPODBO?.map(pod => {
                        if (pod.podImpLink || pod.sigImgLink) {
                            images.push(<div key={pod?.id}>Consignment</div>);
                        }

                        if (pod.podImpLink) {
                            pod.podImpLink.split(',').forEach(imgUrl => {
                                images.push(
                                    <a key={`${pod?.id}__image`} href={imgUrl} target="_blank" >
                                        <img src={imgUrl} className="pod-image" alt="consignment_pod" />
                                    </a>
                                )
                            })
                        }
                        if (pod.sigImgLink) {
                            pod.sigImgLink.split(',').forEach(imgUrl => {
                                images.push(
                                    <a key={`${pod?.id}___image`} href={imgUrl} target="_blank" >
                                        <img src={imgUrl} className="pod-image" alt="consignment_pod" />
                                    </a>
                                )
                            })
                        }
                    });
                    return images
                }
            },
        ]
        this.setState(prevState => {
            let newState = {
                ...prevState,
                accordianData
            }
            if (this.props.trackingDetails) {
                newState.prepareAccordianData = false;
            }
            return newState;
        })
    }
    appendExpandedRowData = () => {
        let jIndex, partner_name, location_name;
        if (!this.state.prepareExpandedRowData) {
            return;
        }
        let track = [...this.props?.trackingDetails?.tracking.reverse()];

        if (Constants.sessionUser.location.entityType === 'CUSTOMER') {
            track = track.map((event) => {
                if (!jIndex && event.eventType === 'BOOKING') {
                    jIndex = event.data.partner_name;
                    partner_name = event.data.partner_name;
                    location_name = event.data.location_name;
                }
                switch (event.eventType) {
                    case 'BOOKING_RECEIVED':
                    case 'INSCAN':
                    case 'BOOKING':
                    case 'DRS_SCAN':
                    case 'UNDELIVERED_B2B':
                    case 'INWARD_SCAN':
                        if (partner_name !== event.data.partner_name) {
                            event.data.partner_name = partner_name + " Partner";
                        }
                        if (location_name !== event.data[event.eventType === 'DRS_SCAN' ? 'loc_name' : 'location_name']) {
                            event.data[event.eventType === 'DRS_SCAN' ? 'loc_name' : 'location_name'] = partner_name + " Partner\'s Location"
                        }
                        break;
                    default:
                }

                return event;
            });
        }
        const expandedRowTrackingEvents = track.reverse()?.map((event) => {
            const row = this.getExpandedRowHtmlByEvent(event);
            event.renderExpandedRow = () => (
                <ExpandedRow row={row} />
            );
            return event;
        });
        this.setState(prevState => {
            let newState = {
                ...prevState,
                tableData: expandedRowTrackingEvents
            }
            if (this.props.trackingDetails) {
                newState.prepareExpandedRowData = false;
            }
            return newState;
        })
    }
    getExpandedRowHtmlByEvent(event) {
        switch (event.eventType) {
            case TRACKING_EVENTS.UNDELIVERED:
                let mapHtml = null;
                let rescheduleDateHtml = null;
                if (event?.data?.attributes?.latitude && event?.data?.attributes?.longitude) {
                    mapHtml = (
                        <Map
                            isMarkerShown
                            googleMapURL={Constants.MAP_URL}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `100px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            lat={event?.data?.attributes?.latitude || 0}
                            lng={event?.data?.attributes?.longitude || 0}
                        />
                    )
                }
                if (event?.data?.reasonBO?.reasonCode === "900") {
                    rescheduleDateHtml = (
                        <div className="expanded-data-row">
                            <p className="heading">Rescheduled Date</p>
                            <Typography className="data">{formatDate(event?.data?.rescheduledDate)}</Typography>
                        </div>
                    )
                }
                return (
                    <div className="expanded-row">
                        <div className="left-col">
                            <div className="expanded-data-row">
                                <p className="heading">Reason</p>
                                <Typography className="data">{event?.data?.reasonBO?.reasonDescription}</Typography>
                            </div>
                            <div className="expanded-data-row">
                                <p className="heading">FE User Details</p>
                                <Typography className="data" condition={!!event?.data?.drsUser?.name}>{event?.data?.drsUser?.name} ({event?.data?.drsUser?.contactNumber})</Typography>
                            </div>
                            <div className="expanded-data-row">
                                <p className="heading">Event Time</p>
                                <Typography className="data">{formatDate(event?.eventTime, formatDateoption())}</Typography>
                            </div>
                            {rescheduleDateHtml}
                            {mapHtml}
                        </div>
                    </div>
                )
            case TRACKING_EVENTS.ARRIVED_AT_LOCATION:
                return (
                    <div className="expanded-row">
                        <div className="left-col">
                            <div className="expanded-data-row">
                                <p className="heading">Event Time</p>
                                <Typography className="data">{formatDate(event?.eventTime, formatDateoption())}</Typography>
                            </div>
                            <Map
                                isMarkerShown
                                googleMapURL={Constants.MAP_URL}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `100px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                lat={event?.data?.latitude}
                                lng={event?.data?.longitude}
                            />
                        </div>
                    </div>
                )
            case TRACKING_EVENTS.CALL_RECORD:
                let callStatus = 'NA';
                let caller;
                let callee;
                const apiTypeItems = typeof event?.data?.apiType == "string" && event?.data?.apiType.split('_');
                if (apiTypeItems.length === 3) {
                    caller = apiTypeItems[0].toLowerCase() == "fe" ? "FE" : capitalizeString(apiTypeItems[0].toLowerCase());
                    callee = apiTypeItems[2].toLowerCase() == "fe" ? "FE" : capitalizeString(apiTypeItems[2].toLowerCase());
                    switch (event?.data?.legStatus2) {
                        case 'no-answer': callStatus = `${callee} Didn't pick up`; break;
                        case 'busy': callStatus = `${callee} Disconnected`; break;
                        case 'canceled': callStatus = ` ${callee} Didn't pick up`; break;
                        case 'completed': callStatus = 'Call attempt successful'; break;
                        case 'failed': callStatus = `Call couldn't connect to ${callee}`; break;
                        default: callStatus = 'NA';
                    }
                }
                return (
                    <div className="expanded-row">
                        <div className="left-col">
                            {/*<div className="expanded-data-row">
                                <p className="heading">Event Time</p>
                                <Typography className="data">{formatDate(event?.eventTime, formatDateoption())}</Typography>
                            </div>
                            Displaying who called whom*/}
                            <div className="expanded-data-row">
                                <p className="heading">Event</p>
                                <Typography condition={callee && caller}><span className="init-caps">{caller}</span> called <span className="init-caps">{callee}</span></Typography>
                            </div>
                            {/* Status text for consignee based on legStatus2 */}
                            <div className="expanded-data-row">
                                <p className="heading">Status</p>
                                <Typography className="data">{callStatus}</Typography>
                            </div>
                            {/* Leg 1 details */}
                            <div className="expanded-data-row">
                                <p className="heading">Leg1</p>
                                <div className="flex">
                                    <span>
                                        <p className="heading inline">Status</p>
                                        <Typography className="data inline">{event?.data?.legStatus1}</Typography>
                                    </span>
                                    <span>
                                        <p className="heading inline">Duration</p>
                                        <Typography className="data inline" condition={event?.data?.leg1Duration}>{event?.data?.leg1Duration} secs</Typography>
                                    </span>
                                </div>
                            </div>

                            { /* Leg2 Details */}
                            <div className="expanded-data-row">
                                <p className="heading">Leg2</p>
                                <div className="flex">
                                    <span>
                                        <p className="heading inline">Status</p>
                                        <Typography className="data inline">{event?.data?.legStatus2}</Typography>
                                    </span>
                                    <span>
                                        <p className="heading inline">Duration</p>
                                        <Typography className="data inline" condition={event?.data?.leg2Duration}>{event?.data?.leg2Duration} secs</Typography>
                                    </span>
                                </div>
                            </div>

                            {/* <div className="right-col"> */}
                            {
                                event?.data?.recordingSourceUrl &&
                                <audio controls class="tracking-audio">
                                    <source src={event?.data?.recordingSourceUrl.replace("https://s3-ap-southeast-1.amazonaws.com", "https://recordings.exotel.com")} type="audio/mp3"></source>
                                    <source src={event?.data?.recordingSourceUrl.replace("https://s3-ap-southeast-1.amazonaws.com", "https://recordings.exotel.com")} type="audio/ogg"></source>
                                    <span className="data">Your browser doesn't support HTML5 audio. Here is a <a href="event?.data?.recordingSourceUrl">link to the audio</a> instead.</span>
                                </audio>
                            }
                            {/* </div> */}
                        </div>
                    </div>
                )
            case TRACKING_EVENTS.DELIVERED:
                const podImgList = getValueFromKey(event, "data.podDetails.imageList")
                return (
                    <div className="expanded-row">
                        <div className="left-col">
                            <div className="expanded-data-row">
                                <p className="heading">Delivered</p>
                                <Typography className="data">{event?.data?.receivedBy}</Typography>
                            </div>

                            <div className="expanded-data-row">
                                <p className="heading">FE Name</p>
                                <Typography className="data" condition={!!event?.data?.drsUser?.name}>{event?.data?.drsUser?.name} ({event?.data?.drsUser?.contactNumber})</Typography>
                            </div>
                            <div className="expanded-data-row">
                                <p className="heading">Payment Mode</p>
                                <Typography className="data">{event?.data?.paymentType}</Typography>
                                {
                                    event?.data?.attributes?.transactionId ? <React.Fragment>
                                        <p className="heading">Transaction ID</p>
                                        <Typography className="data">{event?.data?.attributes?.transactionId}</Typography>
                                    </React.Fragment> : null
                                }
                            </div>
                            <div className="expanded-data-row">
                                <p className="heading">Delivered Time</p>
                                <Typography className="data">{formatDate(event?.data?.podCreatedDate, formatDateoption())}</Typography>
                            </div>
                            <Map
                                isMarkerShown
                                googleMapURL={Constants.MAP_URL}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `100px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                lat={event?.data?.attributes?.latitude}
                                lng={event?.data?.attributes?.longitude}
                            />
                        </div>
                        <div className="left-col">
                            <div className="expanded-data-row">
                                <p className="heading">POD Images</p>
                                {(podImgList && podImgList.length) > 0 ? podImgList.map(
                                    (posImage, index) => posImage && <div key={index} id={index} className='flex-box'>
                                        <div>{`Image - ${index + 1}`} :&nbsp;</div>
                                        <a href={posImage} target='_blank'>Click here to view</a>
                                    </div>) : "NA"
                                }
                            </div>
                            <div className="expanded-data-row">
                                <Button type='primary' disabled={(event?.data?.podImpLink || event?.data?.sigImgLink) ? false : true} onClick={() => this.openPrint(event.data)} className="pull-right" style={{ width: '120px' }}>Download POD</Button>
                            </div>
                        </div>
                    </div>
                )
            case TRACKING_EVENTS.BOOKING:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "Partner Name",
                        dataIndex: "data.partner_name"
                    },
                    {
                        label: "Location Name",
                        dataIndex: "data.location_name"
                    },
                    {
                        label: "Source Pincode",
                        dataIndex: "data.shipper_pincode_zipcode"
                    },
                    {
                        label: "Created At",
                        dataIndex: "data.createdAt"
                    },
                    {
                        label: "Destination Pincode",
                        dataIndex: "data.consignee_pincode_zipcode"
                    }
                ]} />
            case TRACKING_EVENTS.INSCAN:
                return <EventDetails eventType={event.eventType} event={event} singleColumn={true} scanDetails={[
                    {
                        label: "Location Name",
                        dataIndex: "data.location_name"
                    },
                    {
                        label: "User Details",
                        dataIndex: "data.user_name",
                        render: _ => <> {event.data?.user_name} | {event.data?.user_username} </>
                    },
                    {
                        label: "Images",
                        dataIndex: "data.unloadImages",
                        render: _ => {
                            const podImgList = getValueFromKey(event, "data.unloadImages")
                            if (!podImgList) {
                                return undefined
                            }
                            debugger

                            return <>
                                {Object.values(podImgList).length > 0 ? Object.values(podImgList).map(
                                    (posImage, index) => posImage && <div key={index} id={index} className='flex-box'>
                                        <div>{`Image - ${index + 1}`} :&nbsp;</div>
                                        <a href={posImage} target='_blank'>Click here to view</a>
                                    </div>) : undefined
                                }
                            </>
                        }
                    }
                ]} />
            case TRACKING_EVENTS.DEL_OTP_VIEWED:
                return <EventDetails eventType={event.eventType} event={event} singleColumn={true} scanDetails={[
                    {
                        label: "Location Name",
                        dataIndex: "data.location_name"
                    },
                    {
                        label: "User Details",
                        dataIndex: "data.user_name",
                        render: _ => <> {event.data?.user_name} | {event.data?.user_phone_number} </>
                    }
                ]} />
            case TRACKING_EVENTS.MANIFEST_RECEIVED:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "Manifest Code",
                        dataIndex: "data.manifestCode"
                    },
                    {
                        label: "User Details",
                        dataIndex: "user.name",
                        render: _ => <> {event.user.name} | {event.user.username} </>
                    }
                ]} />
            case TRACKING_EVENTS.MANIFEST_SHORT:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "Manifest Code",
                        dataIndex: "data.manifestCode"
                    },
                    {
                        label: "User Details",
                        dataIndex: "user.name",
                        render: _ => <> {event.user.name} | {event.user.username} </>
                    }
                ]} />
            case TRACKING_EVENTS.DRS_SCAN:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "DRS ID",
                        dataIndex: "data.drsCode"
                    },
                    {
                        label: "OFD Marked By",
                        dataIndex: "data.originatedUser_username"
                    },
                    {
                        label: "OFD Marked  For",
                        dataIndex: "data.drsUser_contactNumber"
                    },
                    {
                        label: "Location",
                        dataIndex: "data.loc_name",
                        render: _ => <> {event.data.loc_name} - {event.data.loc_id} </>
                    }
                ]} />
            case TRACKING_EVENTS.RESCHEDULED:
                return <EventDetails eventType={event.eventType} event={event} singleColumn={true} scanDetails={[
                    {
                        label: "Reason",
                        dataIndex: "data.reasonBO.reasonDescription"
                    },
                    {
                        label: "User Details",
                        dataIndex: "data.user_name",
                        render: _ => <> {event?.data?.drsUser?.name} ({event?.data?.drsUser?.contactNumber}) </>
                    }
                ]} />
            case TRACKING_EVENTS.INWARD_SCAN:
                return <EventDetails eventType={event.eventType} event={event} singleColumn={true} scanDetails={[
                    {
                        label: "Scan Location",
                        dataIndex: "data.location_name",
                        render: _ => <> {event?.data?.location_name} - {event?.data?.location_id} </>
                    },
                    {
                        label: "User Details",
                        dataIndex: "data.user_name"
                    }
                ]} />
            case TRACKING_EVENTS.BOOKING_RECEIVED:
                return <EventDetails eventType={event.eventType} event={event} singleColumn={true} scanDetails={[
                    {
                        label: "Consignee Pincode",
                        dataIndex: "data.consignmentBo_consignee_pincode_zipcode"
                    },
                    {
                        label: "Location Address",
                        dataIndex: "data.consignmentBo_location_address_pincode_zipcode"
                    },
                    {
                        label: "Partner Name",
                        dataIndex: "data.partner_name"
                    }
                ]} />
            case TRACKING_EVENTS.POD_STREAM:
                return <EventDetails eventType={event.eventType} event={event} singleColumn={true} scanDetails={[
                    {
                        label: "Event Time",
                        dataIndex: "eventTime",
                        render: _ => <> {formatDate(event?.eventTime, formatDateoption())} </>
                    },
                    {
                        label: "Reason",
                        dataIndex: "data.reason"
                    },
                    {
                        label: "Remarks",
                        dataIndex: "data.remarks"
                    },
                    {
                        label: "Consignment Status",
                        dataIndex: "data.consignmentStatus"
                    },
                    {
                        label: "Status",
                        dataIndex: "data.status"
                    }
                ]} />
            case TRACKING_EVENTS.PICKUP_CREATED:
                return <EventDetails eventType={event.eventType} event={event} singleColumn={true} scanDetails={[
                    {
                        label: "Lead Code",
                        dataIndex: "data.lead_code"
                    }
                ]} />
            case TRACKING_EVENTS.PICKUP_ASSIGNED:
            case TRACKING_EVENTS.OUT_FOR_PICKUP:
                return <EventDetails eventType={event.eventType} event={event} singleColumn={true} scanDetails={[
                    {
                        label: "Name",
                        dataIndex: "data.user_name"
                    }
                ]} />
            case TRACKING_EVENTS.UPDATED_STATUS:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "Status",
                        dataIndex: "data.consignmentStatus"
                    }
                ]} />
            case TRACKING_EVENTS.MANIFEST_SCAN:
            case TRACKING_EVENTS.MANIFEST_POD:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "Manifest ID",
                        dataIndex: "data.manifestCode"
                    }
                ]} />
            case TRACKING_EVENTS.THC_SCAN:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "Truck Hire Chalans ID",
                        dataIndex: "data.thcCode"
                    }
                ]} />
            case TRACKING_EVENTS.PICKUP_CANCELLED:
                return <EventDetails eventType={event.eventType} event={event} singleColumn={true} scanDetails={[
                    {
                        label: "Pickup Cancelation Reason",
                        dataIndex: "data.reason.reasonDescription"
                    }
                ]} />
            case TRACKING_EVENTS.UNDELIVERED_B2B:
            case TRACKING_EVENTS.RETURN_B2B:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "User Details",
                        dataIndex: "data.user_name"
                    },
                    {
                        label: "Reason Category Name",
                        dataIndex: "data.reasonCategory_name"
                    },
                    {
                        label: "Location Name",
                        dataIndex: "data.location_name"
                    },
                    {
                        label: "Reason",
                        dataIndex: "data.reason_reasonDescription"
                    }
                ]} />
            case TRACKING_EVENTS.RTO_DRS_SCAN:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "DRS ID",
                        dataIndex: "data.drsCode"
                    }
                ]} />
            case TRACKING_EVENTS.RTO_UNDELIVERED:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "User Details",
                        dataIndex: "user.name"
                    },
                    {
                        label: "Reason Category Name",
                        dataIndex: "data.reason"
                    }
                ]} />
            case TRACKING_EVENTS.WRONG_FACILITY_SCAN:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "User Details",
                        dataIndex: "user.name",
                        render: _ => <> {event?.user?.name} | {event?.user?.contactNumber} </>
                    }
                ]} />
            case TRACKING_EVENTS.HANDOVER:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "Partner Name",
                        dataIndex: "data.partner_name"
                    }
                ]} />
            case TRACKING_EVENTS.TAMPERED:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "Reason for Tamper",
                        dataIndex: "data.reasonForTamper"
                    }
                ]} />
            case TRACKING_EVENTS.CONSIGNMENT_MARKED_LOST:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "Lost Reason",
                        dataIndex: "data.lostReason"
                    },
                    {
                        label: "Requested By",
                        dataIndex: "data.requester"
                    },
                    {
                        label: "Debit Party",
                        dataIndex: "data.debitParty"
                    }
                ]} />
            case TRACKING_EVENTS.RTO_DELIVERED:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={[
                    {
                        label: "PDF Link",
                        dataIndex: "data.pdfLink",
                        render: _ => <> {getValueFromKey(event, "data.pdfLink") ?
                            <>
                                <a target='_blank' href={getValueFromKey(event, "data.pdfLink")}>
                                    View PDF
                                </a>
                            </>
                            :
                            <>
                                {"NA"}
                            </>
                        } </>
                    },
                    {
                        label: "IS OTP Verified",
                        dataIndex: "data.is_otp_verified",
                        render: _ => <> {getValueFromKey(event, "data.is_otp_verified") ? getValueFromKey(event, "data.is_otp_verified") : getValueFromKey(event, "data.is_otp_verified")} </>,
                    },
                    {
                        label: "Remarks",
                        dataIndex: "data.remarks",
                        render: _ => <> {getValueFromKey(event, "data.remarks") ? "Tampered" : "Intact"} </>,
                    },
                    {
                        label: "POD images",
                        dataIndex: "data.podDetails.id",
                        render: _ => {
                            const podImgList = getValueFromKey(event, "data.podDetails.imageList")
                            if (!podImgList) {
                                return undefined
                            }

                            return <>
                                {podImgList.length > 0 ? podImgList.map(
                                    (posImage, index) => posImage && <div key={index} id={index} className='flex-box'>
                                        <div>{`Image - ${index + 1}`} :&nbsp;</div>
                                        <a href={posImage} target='_blank'>Click here to view</a>
                                    </div>) : undefined
                                }
                            </>
                        }
                    }
                ]} />
            case TRACKING_EVENTS.PICKED_UP:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={this.props?.trackingDetails?.consignment?.flowType == "REVERSE" ?
                    [
                        {
                            label: "IS OTP Verified",
                            dataIndex: "data.isOtpVerified",
                            render: _ => <> {getValueFromKey(event, "data.isOtpVerified") ? getValueFromKey(event, "data.isOtpVerified") : getValueFromKey(event, "data.isOtpVerified")} </>,
                        },
                        {
                            label: "Packet Id",
                            dataIndex: "data.rvpDetails.packetId",
                            render: _ => <> {getValueFromKey(event, "data.rvpDetails.packetId") ? getValueFromKey(event, "data.rvpDetails.packetId") : getValueFromKey(event, "data.rvpDetails.packetId")} </>,
                        },
                        {
                            label: "Location",
                            dataIndex: "data.location.name",
                            render: _ => <>{
                                getValueFromKey(event, "data.location_name") ||
                                getValueFromKey(event, "data.loc_name") ||
                                getValueFromKey(event, "user.location.name") ||
                                "NA"
                            }</>
                        },
                        {
                            label: "Event Time",
                            dataIndex: "data.eventTime",
                            render: _ => <>{formatDate(event?.eventTime, formatDateoption())}</>
                        },
                        {
                            label: "POP images",
                            dataIndex: "data.rvpDetails.id",
                            render: _ => {
                                const objRvpImgList = getValueFromKey(event, "data.rvp_image_list")
                                if (!objRvpImgList) {
                                    return undefined
                                }
                                const keyDisplayTxtMapping = {
                                    frontImage: "Front View Image",
                                    backImage: "Rear View Image",
                                    additionalImage: "Extra View Image",
                                    sideImage: "Side View Image"
                                }

                                const imgKeys = Object.keys(objRvpImgList)

                                return <>
                                    {imgKeys.length > 0 ? imgKeys.map(
                                        (rvpImgKey, index) => objRvpImgList[rvpImgKey] && <div key={index} id={index} className='flex-box'>
                                            <div>{keyDisplayTxtMapping[rvpImgKey]} :&nbsp;</div>
                                            <a href={objRvpImgList[rvpImgKey]} target='_blank'>Click here to view</a>
                                        </div>) : undefined
                                    }
                                </>
                            }
                        }
                    ] : []} />
            case TRACKING_EVENTS.QC_FAILED:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={
                    this.props?.trackingDetails?.consignment?.flowType == "REVERSE" ?
                        [
                            {
                                label: "IS OTP Verified",
                                dataIndex: "data.isOtpVerified",
                                render: _ => <> {getValueFromKey(event, "data.isOtpVerified") ? getValueFromKey(event, "data.isOtpVerified") : getValueFromKey(event, "data.isOtpVerified")} </>,
                            }
                        ] : []} />
            case TRACKING_EVENTS.BOOKING_CANCELLED:
                return <EventDetails eventType={event.eventType} event={event} scanDetails={
                    this.props?.trackingDetails?.consignment?.flowType == "REVERSE" ?
                        [
                            {
                                label: "IS OTP Verified",
                                dataIndex: "data.isOtpVerified",
                                render: _ => <> {getValueFromKey(event, "data.isOtpVerified") ? getValueFromKey(event, "data.isOtpVerified") : getValueFromKey(event, "data.isOtpVerified")} </>,
                            },
                            {
                                label: "Reason Description",
                                dataIndex: "data.reason.id",
                                render: _ => <> {getValueFromKey(event, "data.reason.reasonDescription") ? getValueFromKey(event, "data.reason.reasonDescription") : getValueFromKey(event, "data.reason.reasonDescription")} </>,
                            }
                        ] : []} />
            case TRACKING_EVENTS.RTO_DELIVERED:
            case TRACKING_EVENTS.UPDATED:
            case TRACKING_EVENTS.RTO_IN:
            default: return <EventDetails eventType={event.eventType} event={event} scanDetails={[]} />
        }
    }
    updateConsignmentPod = (formValues, isValid) => {
        const payload = {
            reasonId: formValues.reason,
            remarks: formValues.remarks,
            status: isValid ? 'RESOLVED_VALID' : 'RESOLVED_INVALID',
            action: 'RESOLVED'
        }
        this.props.updateConsignmentPod(this.props.getDetailInput, payload);
    }

    openPrint(val) {
        const head = "<style> body {font-family: 'Roboto', sans-serif;} div { margin-left: 25px} </style>"
        const body = `
        <h2>WayBill No: ${val.waybillNo}</h2>
        <div>
            <p><strong>Delivery Date: </strong>${new Intl.DateTimeFormat('en-GB').format(val.updatedAt)}</p>
            <p><strong>Delivery To: </strong> ${val.receivedBy ? val.receivedBy : 'NA'} (${val.relationshipText === 'SELF' && val.relationshipText ? 'SELF' : val.relationshipText}) ${val.phoneNumber ? '- ' + val.phoneNumber : ''} </p>
            <p><strong>Delivery By: </strong>${val.drsUser.name ? val.drsUser.name : 'NA'} (${val.drsUser.contactNumber ? val.drsUser.contactNumber : 'NA'})</p>
            <p><strong>Delivery Location: </strong>${val?.attributes?.latitude ? val?.attributes?.latitude : 'NA'}, ${val?.attributes?.longitude ? val?.attributes?.longitude : 'NA'} </p>
            ${(val.podImpLink) ? `<p><strong>POD: </strong><p><img width='200px' src=${val.podImpLink} alt='No POD Image' /></p></p>` : ''}
            ${(val.sigImgLink) ? `<p><strong>Signature: </strong><p><img width="200px" src="${val.sigImgLink}" alt="signature"></p></p>` : ''}
        </div>`

        const printConfig = {
            body,
            head,
            isPrint: false
        }
        openWindowWithData(printConfig)
    }

    render() {
        const accordianHtml = this.state.accordianData.map(accordian => {
            return (<Panel header={accordian.title}
                key={accordian.id} style={{ marginBottom: '10px' }}>
                {
                    !accordian.render ? Object.keys(accordian.data).map((key, index) => {
                        return (
                            <div className="row" key={index}>
                                <label className="bold">{key} :</label> <div className="data">{accordian.data[key]}</div>
                            </div>
                        )
                    }) : accordian.render()
                }
            </Panel>
            )
        });
        const consignee = {
            name: this.props.trackingDetails?.consignment?.consignee?.name,
            contactNumber: this.props.trackingDetails?.consignment?.consignee?.phoneNumber,
            key: this.props.trackingDetails?.consignment?.consignee?.key
        };
        const drs = this.props.drses && this.props.drses.length > 0 && this.props.drses[0];
        let fe;
        if (drs) {
            fe = {
                name: drs?.drsUser?.name,
                contactNumber: drs?.drsUser?.contactNumber
            }
        }
        let trackingFooterHtml;
        if ("TrackingListing" === Constants.SUSPICIOUS_CLASSNAME) {
            trackingFooterHtml = (
                <TrackingFooter reasons={this.props.reasons}
                    onMarkClick={this.updateConsignmentPod}
                    selectedReasonId={this.props.reasonId}
                    selectedStatus={this.props.status}
                    consignee={consignee}
                    fe={fe}
                    shouldResolve={this.props.shouldResolve}
                />
            )
        }
        return (
            <div className="TrackingPopup">
                {
                    this.props.trackingDetails && this.props?.trackingDetails?.tracking?.length > 0 ?
                        <>
                            <div className="flex-box">
                                <div className="sidebar spacer-l flex-1">
                                    <Accordian defaultActiveKey={this.props.defaultAccordianActiveKey}>
                                        {accordianHtml}
                                    </Accordian>
                                </div>
                                <div className="main-content spacer-l flex-2">
                                    <Typography>Shipment Activity Log</Typography>
                                    <Table rowKey={"data.id"} columns={this.state.columns}
                                        dataSource={this.state.tableData}
                                        expandedRowRender={row => row.renderExpandedRow()}
                                        bordered={true}
                                        expandRowByClick={true}
                                    />
                                </div>
                            </div>
                            {
                                trackingFooterHtml &&
                                <>
                                    <Divider />
                                    {trackingFooterHtml}
                                </>
                            }
                        </> :
                        <Spin className="flex-box justify-content-center align-items-center" spinning={true} style={{ height: "100%" }} />
                }
            </div>
        );
    }

    requestForPhoneNumber(type) {
        this.props.getCustomerContactNumber({
            id: 1,
            type: type,
            waybillNo: this.props.waybillNo
        })
    }

    displayNumber(data, contactType) {
        if (data) {
            return data === "ACCESS_DENIED" ? 'You are not permitted to view the contact number.' : (
                <Button onClick={e => {
                    const buttonElem = e.target;
                    buttonElem.disabled = true;
                    const ogInnerHTML = buttonElem.innerHTML;
                    buttonElem.innerHTML = "Call again in 30s";
                    const resetButton = () => {
                        buttonElem.disabled = false;
                        buttonElem.innerHTML = ogInnerHTML;
                    };
                    actions.initiateConsigneeCall(this.props.waybillNo, contactType)
                        .then(response => {
                            if (checkHttpStatus(response?.data?.status)) {
                                notifyApiSuccess("Call initiated successfully", this.props.waybillNo);
                                setTimeout(resetButton, 30000);
                            }
                            else {
                                resetButton();
                            }
                        })
                        .catch(e => resetButton(e));

                }}
                    size="small" type="primary" className="lsn-btn-primary">
                    <Icon type="phone" theme="filled" style={{ fill: "#ffffff" }} /> {contactType == CONTACT_NUMBER.SHIPPER_PRIMARY ? "Call Shipper" : "Call Customer"}
                </Button>
            )
        }

        return 'NA';
    }
}

const mapStateToProps = (state, props) => {
    let trackingDetails = state.tracking?.waybillDetails;
    let loading = state.tracking?.loading;
    if (props.source !== undefined && props.source === Constants.SUSPICIOUS_CLASSNAME) {
        trackingDetails = state.suspicious?.waybillDetails;
        loading = state.suspicious?.loading;
    }
    if (trackingDetails?.tracking) {
        trackingDetails.tracking = trackingDetails.tracking.reverse();
        const newTrackingDeatils = trackingDetails.tracking.reverse();
        let newIndex, swapIndex;
        let delivered = newTrackingDeatils.find((tr, index) => {
            if (tr.eventType === 'DELIVERED') {
                newIndex = index;
            }

            return tr.eventType === 'DELIVERED';
        })
        if (delivered) {
            newTrackingDeatils.map((tr, index) => {
                if (tr.eventTime === delivered.eventTime) {
                    if (newIndex < index) {
                        swapIndex = index;
                    }
                }
            });
            if (swapIndex) {
                let temp = newTrackingDeatils[swapIndex];
                newTrackingDeatils[swapIndex] = newTrackingDeatils[newIndex];
                newTrackingDeatils[newIndex] = temp;
            }
        }
        trackingDetails.tracking = newTrackingDeatils.reverse();
    }
    return {
        reasons: state.app?.configurations?.reasons?.UNDELIVERED,
        trackingDetails: trackingDetails,
        consignmentPodUpdate: state.trackingDetails.consignmentPodUpdate,
        errorMessage: state.trackingDetails?.errorMessage,
        drses: trackingDetails?.drses,
        loadingData: loading,
        shipperPrimaryNumber: state.trackingDetails?.shipperPrimary,
        primaryNumber: state.trackingDetails?.primary,
        secondaryNumber: state.trackingDetails?.secondary,
        address: state.trackingDetails?.address
    }
};
const mapDispatchToProps = dispatch => ({
    getTrackingDetails: (params) => dispatch(actions.getTrackingDetails(params)),
    updateConsignmentPod: (streamId, params) => dispatch(actions.updateConsignmentPod(streamId, params)),
    resetNotificationData: () => dispatch(actions.resetNotificationData()),
    getCustomerContactNumber: (params) => dispatch(actions.getCustomerContactNumber(params)),
    resetWaybillDetails: () => dispatch(resetWaybillDetails())
})
export default connect(mapStateToProps, mapDispatchToProps)(TrackingPopup);

