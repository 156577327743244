import { Button, Card, Empty, Icon, Input, Modal, Tooltip } from 'antd';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { canAllow } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import { table as Table } from "../../ui/table/Table";
import * as actions from './actions';
import './dashboard.scss';
import LsnFilters from './LsnFilters.jsx';
import { Constants } from '../../../common/utils/constants';

const { confirm } = Modal;

class LocationDashboard extends PureComponent {
  constructor(props) {
    super(props);
    let sendEntityId = true
    if (props?.state?.app?.configurations?.partnerLocOnboardingEnabledUsers != null &&
      Array.isArray(props?.state?.app?.configurations?.partnerLocOnboardingEnabledUsers) &&
      props.state?.header?.loggedInUser?.id != null &&
      (props.state.app.configurations.partnerLocOnboardingEnabledUsers).includes(props.state.header.loggedInUser.id.toString())
    ) {
      sendEntityId = false
    }

    this.state = {
      pageNo: 1,
      filterVisible: false,
      sendEntityId: sendEntityId,
      locationPermission: {
        read: canAllow(permissionTags.settings.location.read, null, null, this.props.state),
        update: canAllow(permissionTags.settings.location.update, null, null, this.props.state),
        create: canAllow(permissionTags.settings.location.create, null, null, this.props.state),
        delete: canAllow(permissionTags.settings.location.delete, null, null, this.props.state),
        view: canAllow(permissionTags.settings.location.view, null, null, this.props.state),
      },
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
          status: true,
          fetchObjects: ["ADDRESSES", "PINCODES", "CITIES", "PARTNER", "CUSTOMER", "VENDOR", "SERVICEABLE_AREAS", "CUSTOMER_PROMISE_TIME_RULE"],
          fromLocationOnboarding: true
        }
      }
    }
    this.InputSearchRef = React.createRef();
    if (!this.state.locationPermission.read) {
      window.location.replace('/appv2/settings/dashboard/location');
    }
  }

  componentDidMount() {
    this.setState(state => ({
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          width: 150,
          key: 'name',
        },
        {
          title: "Partner",
          dataIndex: 'id',
          key: "partner",
          render: (data, row, index) => {

            const tableData = this.props?.locationsData?.PaginatedLocations[this.state.pageNo]
            const rowData = tableData[index]

            return (
              <>
                {rowData &&
                  <div>{rowData?.entity?.custObject?.name}</div>
                }
              </>
            )
          }
        },
        {
          title: 'City',
          dataIndex: 'address',
          key: 'cityname',
          render: (data, row) => (<div>{data?.pincode?.city?.name}</div>)
        },
        {
          title: 'Pincode',
          dataIndex: 'address',
          key: 'pincode',
          render: (data, row) => (<div>{data?.pincode?.zipcode} </div>)
        },
        {
          title: 'Created On / Updated On',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (data, row, index) => {

            const tableData = this.props?.locationsData?.PaginatedLocations[this.state.pageNo]
            const rowData = tableData[index]

            return (
              <>
                {rowData &&
                  <div>{moment(rowData.createdAt).format('DD-MM-YYYY HH:mm')} / {moment(rowData.updatedAt).format('DD-MM-YYYY HH:mm')} <br /> <small>{moment(rowData.createdAt).fromNow()}</small> / <small>{moment(rowData.updatedAt).fromNow()}</small> </div>
                }
              </>
            )
          }
        },
        {
          title: 'Action',
          dataIndex: '',
          key: 'action',
          render: (_, row) => (
            <div className="flex-box flex-gap-s align-items-center">
              {
                state.locationPermission.update &&
                row?.entityType === 'PARTNER' &&
                <Tooltip placement="top" title={'Edit'} className="flex-1">
                  <Button icon="edit" className="lsn-btn-primary"
                    onClick={() => this.props.history.push(`/appv2/settings/form/location/${row.id}`)} >
                  </Button>
                </Tooltip>
              }
            </div>
          )
        },
      ]
    }));
    if (!this.props?.locationsData?.locations) {
      this.getPaginatedData(this.state.pageNo);
    }
  }

  componentDidUpdate(prevProps) {
    const { PaginatedLocations, locationsData } = this.props;
    if (PaginatedLocations !== prevProps.PaginatedLocations) {
      this.setState({
        PaginatedLocations: PaginatedLocations[locationsData.currentPageNo],
      })
    }
  }

  componentWillMount() {

    const filters = { filters: { ...this.state.filterPayload.filters, entityIds: [Constants.PARTNER_ID], entityTypes: ["PARTNER"] } }

    if (!this.state.sendEntityId) {
      delete filters.filters["entityIds"]
    }

    this.props.fetchLocation(filters, true);
  };

  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }

    let filterVal = { filters: { ...this.state.filterPayload.filters, ...filters, entityTypes: ["PARTNER"], entityIds: [Constants.PARTNER_ID] } }

    if (!this.state.sendEntityId) {
      delete filterVal.filters["entityIds"]
    }

    this.props.fetchLocation(filterVal, isReset);
    this.setState({ partialContentSearch: filters.partialContentSearch })
  }

  toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  reloadLocationList = () => {
    this.setState({
      pageNo: 1,
      selectedValues: {}
    })
    this.applyPagination({ pageNo: 1 }, true);
  }

  getPaginatedData = (pageNo, paginatedData) => {

    const { PaginatedLocations } = this.props;
    if (!PaginatedLocations ? !PaginatedLocations : !PaginatedLocations[pageNo]) {
      let filter = {
        pageNo: pageNo
      };
      if (this.state?.selectedValues?.entityType) {
        filter['entityTypes'] = [this.state?.selectedValues?.entityType]
      }

      if (this.state?.selectedValues?.entityIds && this.state?.selectedValues?.entityIds.length > 0) {
        filter['entityIds'] = this.state?.selectedValues?.entityIds
      } else {
        filter['entityIds'] = [Constants.PARTNER_ID]
      }

      if (this.state?.partialContentSearch) {
        filter['partialContentSearch'] = this.state?.partialContentSearch;
      }

      if (!this.state.sendEntityId) {
        delete filter["entityIds"]
      }

      this.applyPagination(filter);
    }
    this.setState({
      pageNo
    });
  }

  showFilterModal = () => {
    this.setState({
      filterVisible: true,
    });
  };

  submitHandler = (selectboxData) => {

    let filters = {
      pageNo: 1
    }
    if (selectboxData?.entityType) {
      filters["entityTypes"] = [selectboxData.entityType]
    }

    if (selectboxData?.entityIds && selectboxData?.entityIds.length > 0) {
      filters["entityIds"] = selectboxData.entityIds
    }

    if (!this.state.sendEntityId) {
      delete filters["entityIds"]
    }

    this.props.fetchLocation({ filters: { ...this.state.filterPayload.filters, ...filters, entityTypes: ["PARTNER"] } }, true);
    this.setState({
      filterVisible: false,
      selectedValues: selectboxData,
      pageNo: 1
    });
  }

  closeModal = () => {
    this.setState({
      filterVisible: false,
    });
  }

  render() {
    const { columns, locationPermission, pageNo, filterVisible } = this.state;
    const { PaginatedLocations, locationsData } = this.props;

    const actionBtns = (
      <div className="flex-box flex-gap-m align-items-center">
        <Input.Search size={'large'} placeholder="Search by location name" allowClear ref={this.InputSearchRef} className="flex-4"
          onSearch={value => this.applyPagination({ partialContentSearch: value.trim() }, true)} enterButton />
        <Tooltip placement="top" title={'Reload Location List'}>
          <Button block={true} size="large" type="primary" className="lsn-btn-primary" onClick={this.reloadLocationList} icon="reload" />
        </Tooltip>
        {
          locationPermission.create &&
          <Button block={true} size="large" type="primary" className="lsn-btn-primary flex-1" icon="plus"
            onClick={() => { this.props.history.push(`/appv2/settings/form/location`) }}>Create Partner/Location</Button>
        }

        {/* Hiding filter button for now */}
        {/* <Tooltip placement="top" title={'Filter List'} className="flex-1">
          <Button block={true} size="large" className="lsn-btn-secondary" icon="filter"
            onClick={this.showFilterModal}>
            Filter
          </Button>
        </Tooltip> */}
      </div>
    );
    return (
      <>
        <Card className="CustomerDashBoard filterbox" title={<h2>Location List</h2>} extra={actionBtns}>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }}
            loading={locationsData?.loading}
            pagination={{
              onChange: this.getPaginatedData,
              total: locationsData?.totalCount,
              position: 'bottom',
              current: pageNo,
              pageSize: locationsData?.currentPageSize
            }}
            dataSource={PaginatedLocations ? PaginatedLocations[this.state.pageNo] : []}
            columns={columns} />
          {
            filterVisible &&
            <LsnFilters title="Apply Filters" visible={this.state.filterVisible}
              cancelModal={this.closeModal} handleSubmit={this.submitHandler} />
          }
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locationsData: state.settingLocation,
  PaginatedLocations: state.settingLocation?.PaginatedLocations,
  state: state
});
const mapDispatchToProps = dispatch => ({
  fetchLocation: (param, isReset = false) => dispatch(actions.fetchLocation(param, isReset)),
  deleteLocation: (id, payload) => dispatch(actions.deleteLocation(id, payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LocationDashboard);