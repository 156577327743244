import React, { useEffect } from "react";
import { Alert } from "antd";
import { connect } from "react-redux";

import { ENTITY_TYPES, REASONS, STATUS_MAPPING } from "../constants";
import { updateCurrentScannedEntityReason } from "../actions";

import ManifestReasonDropDown from "../reasons-dropdown";
import ClosureScanCardBucket from "./bucket";
import TamperedReasonSelect from "../../../common/tampered-reason-select";

import "./closure-scan-card.scss";

const LABEL_KEY_MAPPING = {
    manifest: {
        waybillNo: {
            label: "Waybill No",
            key: "waybillNo",
        },
        status: {
            label: "Status",
            key: "status",
        },
        cpdDate: {
            label: "CPD Date",
            key: "cpdDate",
        },
        customerPromiseDate: {
            label: "CPD Date",
            key: "customerPromiseDate",
        },
        ageOfShipment: {
            label: "Age of Shipment",
            key: "ageOfShipment",
        },
        shipmentAge: {
            label: "Age of Shipment",
            key: "shipmentAge",
        },
        nextLocation: {
            label: "Next Location",
            key: "nextLocation",
        },
        destinationPincode: {
            label: "Destination Pincode",
            key: "destinationPincode",
        },
        address: {
            label: "Address",
            key: "address",
        },
        consigneeAddress: {
            label: "Address",
            key: "consigneeAddress",
        },
        shipperName: {
            label: "Shipper Name",
            key: "shipperName",
        },
        shipperAddress: {
            label: "Shipper Address",
            key: "shipperAddress",
        },
        bucket: {
            label: "Bucket",
            key: "bucket",
        },
        batchNumber: {
            label: "Batch Number",
            key: "batchNumber"
        }
    },
    connection: {
        code: {
            label: "Manifest No",
            key: "code",
        },
        status: {
            label: "Status",
            key: "status",
        },
        flowType: {
            label: "Flow Type",
            key: "flowType",
        },
        nextLocation: {
            label: "Next Location",
            key: "nextLocation",
        },
        destinationLocation: {
            label: "Destination Location",
            key: "destinationLocation",
        },
        destinationPincode: {
            label: "Destination Pincode",
            key: "destinationPincode",
        },
        nextAction: {
            label: "Next Action",
            key: "nextAction"
        },
    }
}

const ClosureScanCard = ({ entityType, scannedEntityInfo, reasonsObj, updateCurrentScannedEntityReason, entityInfo, isMobile, refocusOnInput, pickupData }) => {
    const status = scannedEntityInfo.status;
    const reasonStatus = [STATUS_MAPPING.pending, STATUS_MAPPING.overage].includes(status)
        ? (status === STATUS_MAPPING.pending ? REASONS.pending : REASONS.overage) : null;
    const reasons = reasonStatus ? reasonsObj[reasonStatus] : [];
    const entityCode = entityType === ENTITY_TYPES.connection.name ? scannedEntityInfo.code : scannedEntityInfo.waybillNo;

    const filteredKeys = entityType === ENTITY_TYPES.connection.name
        ? ["code", "status", "flowType", "nextLocation", "destinationLocation", "destinationPincode", "nextAction"]
        : ["waybillNo", "status", "cpdDate", "ageOfShipment", "nextLocation", "destinationPincode", "address", "shipperName", "shipperAddress", "bucket"]

    const shipmentRows = [
        ["waybillNo", "status", "cpdDate", "ageOfShipment"],
        ["nextLocation", "destinationPincode", "address"],
        ["shipperName", "shipperAddress"],
        ["bucket", "batchNumber"]
    ];


    const handleUpdateReason = (reasonId, entityCode) => {
        let payload = {
            reasonId: reasonId
        };
        if (entityType === ENTITY_TYPES.connection.name) {
            payload["connectionCode"] = entityInfo.code;
            payload["manifestCode"] = entityCode;
        } else {
            payload["manifestCode"] = entityInfo.code;
            payload["waybillNo"] = entityCode;
        }
        updateCurrentScannedEntityReason(entityType, payload);
    };

    if (isMobile) {
        return (
            <div className="flex-column flex-gap-s spacer-hm">
                <div className="grey-text"> Last Scan Details </div>
                <div className="flex-column flex-gap-l spacer-s white-bg">
                    <Alert message="Scan Successful" type="success" showIcon className={"success-color"} />
                    {
                        entityType === ENTITY_TYPES.manifest.name ?
                            <>
                                <div className="flex-column flex-gap-s">
                                    <div className="light-grey-text"> Next Location</div>
                                    <div className="font-size-l-3"> {scannedEntityInfo?.nextLocation} </div>
                                </div>
                                <div className="flex-column flex-gap-m">
                                    <div className="flex-box justify-content-space-between flex-gap-l" style={{ flexWrap: "wrap" }}>
                                        <div className="flex-column flex-gap-s flex-2 align-items-start">
                                            <div className="light-grey-text"> Waybill No </div>
                                            <div className="font-size-m-3 break-all"> {scannedEntityInfo?.waybillNo} </div>
                                        </div>
                                        <div className="flex-column flex-gap-s flex-1 align-items-end">
                                            <div className="light-grey-text"> Status </div>
                                            <div className={"font-size-m-3 " + (scannedEntityInfo?.status.indexOf("IN") > -1 || scannedEntityInfo?.status.indexOf("ARRIVED") > -1 ? "success-color" : "error-color")} >
                                                {scannedEntityInfo?.status}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> :
                            entityType === ENTITY_TYPES.connection.name ?
                                <>
                                    <div className="flex-column flex-gap-s">
                                        <div className="light-grey-text"> Next Location</div>
                                        <div className="font-size-l-3"> {scannedEntityInfo?.nextLocation} </div>
                                    </div>
                                    <div className="flex-column flex-gap-m">
                                        <div className="flex-box justify-content-space-between">
                                            <div className="flex-column flex-gap-s flex-2 align-items-start">
                                                <div className="light-grey-text"> Manifest ID</div>
                                                <div className="font-size-m-3"> {scannedEntityInfo?.code} </div>
                                            </div>
                                            <div className="flex-column flex-gap-s flex-1 align-items-end">
                                                <div className="light-grey-text"> Status </div>
                                                <div className="font-size-m-3"> {scannedEntityInfo?.status} </div>
                                            </div>
                                        </div>
                                    </div>
                                </> : <></>
                    }
                    {
                        scannedEntityInfo &&
                        <TamperedReasonSelect scanType={"UNLOAD"}  onTamperSuccess={refocusOnInput}
                            customerId={scannedEntityInfo?.customerId}
                            waybillNo={scannedEntityInfo?.waybillNo} />
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="closure-scan-card-container">
            {
                entityType === ENTITY_TYPES.connection.name ?
                    (
                        <div className="manifest-scan-card-body">
                            {
                                //eslint-disable-next-line
                                filteredKeys.map((objKey, index) => {
                                    if (objKey in scannedEntityInfo) {
                                        return (
                                            <div key={`${objKey}-${index}`} className={`manifest-scan-card-item ${objKey === "code" ? "span-2" : ""}`}>
                                                <div
                                                    className={"closure-scan-card-key"}>
                                                    {LABEL_KEY_MAPPING[entityType][objKey].label}
                                                </div>
                                                <div
                                                    className={`closure-scan-card-value ${["nextLocation", "destinationLocation", "destinationPincode"].includes(objKey) ? "highlighted-info" : ""}
                                                    ${["nextLocation", "destinationLocation", "destinationPincode"].includes(objKey) ? "highlighted-info-red" : ""}
                                                    ${objKey === "status" && (scannedEntityInfo[objKey] === STATUS_MAPPING.overage || scannedEntityInfo[objKey] === STATUS_MAPPING.misroute) ? "overage-bold" : ""}
                                                `}>
                                                    {scannedEntityInfo[objKey]}
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                            <div className="closure-scan-card-item">
                                <div className="closure-scan-card-key"></div>
                                <div className="closure-scan-card-key dropdown-section">
                                    {
                                        reasonStatus && reasons && reasons.length && (
                                            <ManifestReasonDropDown
                                                reasons={reasons}
                                                entityType={entityType}
                                                entityCode={entityCode}
                                                handleUpdate={handleUpdateReason}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    )
                    : (
                        <>
                        {
                            scannedEntityInfo?.alreadyScanned &&
                            <Alert message="The consignment was already scanned against the manifest" type="info" className={"info-color"} />
                        }
                        <div className="grey-bg spacer-m border-radius-m flex-box justify-content-space-between">
                            <div className="consignment-scan-card-body flex-5">
                                {
                                    shipmentRows.map((row, index) => {
                                        const rowName = index === 0 ? "first" : (index === 1 ? "second" : "third")
                                        return (
                                            <div
                                                className="consignment-scan-card-body-row"
                                                key={`rowName-${rowName}-${index}`}
                                            >

                                                {
                                                    index === 2 ?
                                                        pickupData && pickupData.find(c => c.waybillNo == scannedEntityInfo.waybillNo) &&
                                                        <div className="closure-scan-card-item">
                                                            <div className="closure-scan-card-key"> Pickup FE </div>
                                                            <div className="closure-scan-card-value highlighted-info" style={{ color : "#000"}}>
                                                                {
                                                                    pickupData.find(c => c.waybillNo == scannedEntityInfo.waybillNo)?.feName
                                                                }
                                                            </div>
                                                        </div> :
                                                    <></>
                                                }
                                                {
                                                    row.map((objKey, idx) => (objKey in scannedEntityInfo && scannedEntityInfo[objKey]) ? (
                                                        <div
                                                            className={`closure-scan-card-item ${["waybillNo", "shipperName"].includes(objKey) ? "span-2" : ""} ${["address"].includes(objKey) ? "span-4" : ""} ${["shipperAddress"].includes(objKey) ? "span-3" : ""}`}
                                                            key={`${objKey}-${idx}`}
                                                        >
                                                            {
                                                                objKey === "bucket" && !Object.keys(scannedEntityInfo[objKey]).length ? null : (
                                                                    <div className="closure-scan-card-key">
                                                                        {LABEL_KEY_MAPPING[entityType][objKey].label}
                                                                    </div>
                                                                )
                                                            }


                                                            <div
                                                                className={`
                                                            closure-scan-card-value
                                                            ${["nextLocation", "destinationPincode"].includes(objKey) ? "highlighted-info" : ""}
                                                            ${objKey === "status" && (scannedEntityInfo[objKey] === STATUS_MAPPING.overage || scannedEntityInfo[objKey] === STATUS_MAPPING.misroute) ? "overage-bold" : ""}
                                                        `}>
                                                                {
                                                                    objKey === "bucket"
                                                                        ? (
                                                                            <div className="closure-scan-card-buket">
                                                                                <ClosureScanCardBucket bucket={scannedEntityInfo[objKey]} />
                                                                            </div>

                                                                        ) : (scannedEntityInfo[objKey])
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                        : null)
                                                }
                                                {index === 0 && (
                                                    <div className="closure-scan-card-item">
                                                        <div className="closure-scan-card-key"></div>
                                                        <div className="closure-scan-card-key dropdown-section">
                                                            {
                                                                reasonStatus && reasons && reasons.length && (
                                                                    <ManifestReasonDropDown
                                                                        reasons={reasons}
                                                                        entityType={entityType}
                                                                        entityCode={entityCode}
                                                                        handleUpdate={handleUpdateReason}
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="flex-1">
                                {
                                    scannedEntityInfo &&
                                    <TamperedReasonSelect scanType={"UNLOAD"}
                                        customerId={scannedEntityInfo?.customerId}
                                        waybillNo={scannedEntityInfo?.waybillNo} />
                                }
                            </div>
                        </div>
                        </>
                    )
            }

        </div>
    )
};

const mapStateToProps = (state, ownProps) => ({
    reasonsObj: state.inventoryClosure.reasons,
    entityInfo: state.inventoryClosure.entityInfo,
    config: state.app.configurations
});

const mapDispatchToProps = dispatch => ({
    updateCurrentScannedEntityReason: (entityType, payload) => dispatch(updateCurrentScannedEntityReason(entityType, payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClosureScanCard);