import { Icon, Modal } from "antd";
import { useState } from "react"

export default function SettlementActivityRemarks({ paymentMeta, approvalStatus }) {

    const keyToText = {
        "transactionDate"  : "Transaction Date",
        "bankName"         : "Bank Name",
        "bankRefNo"        : "Reference No.",
        "remarks"          : "Remarks",
        ...( approvalStatus === "REJECTED" ? {
            "rejected_reason"  : "Rejected Reasons",
            "rejected_remarks" : "Rejected Remarks"
        } : {})
    }

    const [ openRemarksModal, SetOpenRemarksModal ] = useState(false);

    const paymentMetaData = paymentMeta ? JSON.parse(paymentMeta) : {};

    return (
        <>
            <div className="flex-box justify-content-center">
                <Icon type="info-circle" onClick={_ => SetOpenRemarksModal(true)} />
            </div>

            <Modal visible={openRemarksModal} width={"40%"}
             cancelButtonProps={{ 
                style : {
                    display : "none"
                }
             }} centered={true} destroyOnClose={true} onCancel={_ => SetOpenRemarksModal(false)}
             title="Remarks" onOk={_ => SetOpenRemarksModal(false)} okText="Close">
                <div className="flex-column flex-gap-l">
                    {
                        Object.keys(keyToText).filter(k => ["rejected_remarks"], ["rejected_reason"]).map((key, _index) =>{
                            return (
                                <div key={_index} className="flex-box align-items-center flex-gap-xl">
                                    <div className="flex-1 text-bold"> {keyToText[key] || key} </div> :
                                    <div className="flex-2"> {paymentMetaData[key] || " -- "} </div>
                                </div>
                            )
                        })
                    } 
                </div>
            </Modal>
        </>
    )

}