import {
    Alert,
    Button,
    Card,
    Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
    closeActionModal,
    closeConnection,
    closeErrorModal,
    resetSelectedSparkLineDialogData,
    scanEntity,
    unloadEntity,
    validateEntity
} from "../actions";
import {
    ENTITY_STATUS,
    ENTITY_TYPES
} from "../constants";

import { destroyNotification } from "../../../ui/notification/Notification";
import SparkLineDialogInfo from "../dialog-info";
import "./manifest-unload-card.scss";
import ManifestUnloadCardNoWrapper from "./manifest.unload.card";
import { checkHttpStatus, notifyApiError } from "../../../../common/utils/utils";
import { apiRequest } from "../../../../common/utils/apiGateway";
import { Constants } from "../../../../common/utils/constants";
import ScanActionRequiredPopupModal from '../../../shared-components/scan-action-required-modal';

const ManifestUnloadCard = ({ 
    entityType, 
    entityInfo, 
    unloadEntity,
    scanEntity,
    currentScannedEntityInfo,
    resetSelectedSparkLineDialogData,
    isValidated,
    validateEntity,
    closeConnection,
    errorModal,
    closeErrorModal,
    isMobile,
    errorPayload,
    config,
    loading,
    deepLikUrl,
    closeActionModal
}) => {
    const { code, status, pendingCount, overageCount, scannedCount, scannedAtDestination=true } = entityInfo;
    const [showDialog, setShowDialog] = useState(false);
    const [failedWaybillList, setFailedWaybillList] = useState([]);
    const [selectedSparkline, setSelectedSparkline] = useState({
        isSelected: false,
        label: "",
        reasonStatus: null,
    });
    const [scannedEntityCode, setScannedEntityCode] = useState("");
    const [pickupData, setPickupData] = useState("");

    const handleScanEntityChange = (e) => {
        const val = (e.target.value).trim();
        setScannedEntityCode(val);
    }

    useEffect(_ => {
        const locationOpsType = localStorage?.user ? JSON.parse(atob(localStorage.user))?.location?.locationOpsType : undefined;
        if (locationOpsType == "FM" && entityType === ENTITY_TYPES.manifest.name && entityInfo?.code) {
            apiRequest({
                url    : `b2c/v1/manifest/${entityInfo?.code}/pickup-fe-data`,
                method : "GET"
            }).then(response => {
                if (checkHttpStatus(response?.data?.status)) {
                    setPickupData(response?.data?.response?.waybillPickupDataBOList);
                }
            })
        }
    }, [ entityInfo ])

    useEffect(_ => {
        if (errorPayload && errorPayload?.reasonCode == "ERR300") {
            destroyNotification();
            Modal.confirm({
                icon    : false,
                width   : isMobile ? 320 : 600,
                title   : "Scan confirmation",
                okText  : "Scan Waybill",
                content : (
                    <div className='flex-column flex-gap-l align-items-stretch'>
                        <Alert type='warning' showIcon={true}
                        message={<div style={{color : "#000"}}>This waybill <strong>{errorPayload?.waybillLocationResponse?.waybillNo}</strong> is not expected in your location.</div>}
                        description={<div style={{color : "#000"}}>Current Location of waybill is 
                                        <strong> {errorPayload?.waybillLocationResponse?.expectedLocationName} </strong> 
                                        and consignee pincode 
                                        <strong> {errorPayload?.waybillLocationResponse?.destinationPincode} </strong>.
                                    </div>} />
                        <div className='font-size-m-3 flex-box justify-content-center text-bold' style={{color : "#000"}}>
                            Are you sure you want to scan this waybill?
                        </div>
                    </div>
                ),
                onOk : _ => {
                    Modal.destroyAll();
                    handleScanEntity(false, true, errorPayload?.waybillLocationResponse?.waybillNo);
                },
                onCancel : Modal.destroyAll
            })
        }
        else if (errorPayload && errorPayload.customErrorCode === "SCANERROR001") {
            handleScanEntity(true, true, errorPayload?.waybillNo);
        }
        else if (errorPayload && errorPayload?.message) {
            notifyApiError(errorPayload?.message);
        }
    }, [ errorPayload ])

    const handleSparklineClick = (sparkLineLabel, reasonStatus) => {
        setSelectedSparkline({
            ...selectedSparkline,
            isSelected: true,
            label: sparkLineLabel,
            reasonStatus
        })
        setShowDialog(true);
    };

    const handleCloseDialogInfo = () => {
        setSelectedSparkline({
            ...selectedSparkline,
            isSelected: false,
            label: "",
            totalCount: 0
        });
        setShowDialog(false);
        resetSelectedSparkLineDialogData();
    };

    const handleUnloadEntity = () => {
        let payload = {
            connectionCode: code
        };
        if(entityType === ENTITY_TYPES.manifest.name){
            payload = {
                "manifestCode": code
            };
        }
        unloadEntity(entityType, payload);
    };

    const handleScanEntity = (enforceLocationCheck = true, legacyScan = false, _scannedEntityCode) => {
        let payload = {
            scanInputType: "BARCODE_SCANNER",
        };
        if(entityType === ENTITY_TYPES.connection.name){
            payload["connectionCode"] = code;
            payload["manifestCode"] = _scannedEntityCode || scannedEntityCode;
        } else {
            payload["manifestCode"] = code;
            payload["waybillNo"] = _scannedEntityCode || scannedEntityCode;
        }
        payload.enforceLocationCheck = config && config?.enableMistypeHandling == 'true' ? enforceLocationCheck : undefined;
        if (config?.enableConsignmentScanV2 === 'true') {
            scanEntity(entityType, payload, isMobile, legacyScan);
        }
        else {
            scanEntity(entityType, payload, isMobile, true);
        }
        setScannedEntityCode("");
    };

    const handleValidate = () => {
        let payload = {};
        if(entityType === ENTITY_TYPES.connection.name){
            payload["connectionCode"] = code;
        } else {
            payload["manifestCode"] = code;
        }
        validateEntity(entityType, payload);
    };

    const handleCloseConnection = () => {
        let payload = {
            status: "COMPLETED"
        }
        if(entityType === ENTITY_TYPES.connection.name){
            payload["connectionCode"] = code;
        } else {
            payload["manifestCode"] = code;
        }

        closeConnection(entityType, payload);
    };

    return(
        <Card className={isMobile ? "flex-column remove-card-body-padding remove-card-bg" : undefined} bordered={!isMobile} >
            {
                isMobile ?
                <div className="flex-column justify-content-space-between flex-gap-m" style={{height: "calc(100vh - 50px)", width: "100vw"}}>
                    <div> 
                        <ManifestUnloadCardNoWrapper 
                         entityType={entityType}
                         entityInfo={entityInfo}
                         status={status}
                         pendingCount={pendingCount}
                         overageCount={overageCount}
                         scannedCount={scannedCount}
                         isValidated={isValidated}
                         handleCloseConnection={handleCloseConnection}
                         handleSparklineClick={handleSparklineClick}
                         isMobile={isMobile}
                         code={code}
                         scannedAtDestination={scannedAtDestination}
                         handleScanEntity={handleScanEntity}
                         handleScanEntityChange={handleScanEntityChange}
                         scannedEntityCode={scannedEntityCode}
                         currentScannedEntityInfo={currentScannedEntityInfo}
                         handleValidate={handleValidate}
                         failedWaybillList={failedWaybillList}
                         pickupData={pickupData}
                         loading={loading}  />
                    </div>
                    <div className="flex-box spacer-m">
                    {
                        isValidated ?
                        <Button type="primary" className="lsn-btn-primary flex-1" disabled={!isValidated} onClick={handleCloseConnection}>
                            Close { entityType === ENTITY_TYPES.connection.name ? "Connection" : "Manifest"}
                        </Button> :
                        <>
                            {
                                status === ENTITY_STATUS[entityType].unloading ? (
                                    <Button onClick={handleValidate}
                                    type="primary" className="lsn-btn-primary flex-1">
                                        Validate
                                    </Button>
                                ) : (
                                    <Button type="primary" className="lsn-btn-primary flex-1"
                                     disabled={status !== (entityType === ENTITY_TYPES.connection.name ? ENTITY_STATUS[entityType].started : ENTITY_STATUS[entityType].arrived) || !scannedAtDestination }
                                     onClick={handleUnloadEntity}>
                                        Unload
                                    </Button>
                                )
                            }
                        </>
                    }
                    </div>
                </div> :
                <ManifestUnloadCardNoWrapper 
                 entityType={entityType}
                 entityInfo={entityInfo}
                 status={status}
                 pendingCount={pendingCount}
                 overageCount={overageCount}
                 scannedCount={scannedCount}
                 isValidated={isValidated}
                 handleCloseConnection={handleCloseConnection}
                 handleSparklineClick={handleSparklineClick}
                 isMobile={isMobile}
                 code={code}
                 scannedAtDestination={scannedAtDestination}
                 handleScanEntity={handleScanEntity}
                 handleScanEntityChange={handleScanEntityChange}
                 scannedEntityCode={scannedEntityCode}
                 currentScannedEntityInfo={currentScannedEntityInfo}
                 handleValidate={handleValidate}
                 handleUnloadEntity={handleUnloadEntity}
                 pickupData={pickupData}
                 loading={loading} />
            }
                <SparkLineDialogInfo 
                    show={showDialog}
                    title={selectedSparkline.label}
                    reasonStatus={selectedSparkline.reasonStatus}
                    isMobile={isMobile}
                    handleClose={() => handleCloseDialogInfo()}
                    onReasonUpdate={waybillNo => {
                        setFailedWaybillList(c => c.filter(awb => awb != waybillNo))}
                    }
                />
                <Modal
                    visible={errorModal.show}
                    title={errorModal.title}
                    onCancel={closeErrorModal}
                    footer={null}
                    closable
                    destroyOnClose
                    width="480px"
                >
                    <div className="error-dialog-container">
                        <div className="error-dialog-title">
                            {errorModal.msg}
                        </div>

                        <div className="error-dialog-footer">
                            <div className="error-dialog-button">
                                <Button
                                    type="primary"
                                    block
                                    onClick={closeErrorModal}
                                >Ok</Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <ScanActionRequiredPopupModal handleClose={closeActionModal}
                    open={deepLikUrl ? true : false}
                    qrText={deepLikUrl || ''} 
                />
        </Card>
    )
};

const mapStateToProps = (state, ownProps) => ({
    entityType: state.inventoryClosure.selectedEntityType,
    entityInfo: state.inventoryClosure.entityInfo,
    scannedEntities: state.inventoryClosure.scannedEntities,
    currentScannedEntityInfo: state.inventoryClosure.currentScannedEntityInfo,
    errorPayload: state.inventoryClosure.error,
    isValidated: state.inventoryClosure.isValidated,
    errorModal: state.inventoryClosure.errorModal,
    deepLikUrl: state.inventoryClosure.deepLikUrl,
    config: state.app.configurations
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    unloadEntity: (entityType, payload) => dispatch(unloadEntity(entityType, payload)),
    scanEntity: (entityType, payload, isMobile, legacyScan) => dispatch(scanEntity(entityType, payload, isMobile, legacyScan)),
    resetSelectedSparkLineDialogData: () => dispatch(resetSelectedSparkLineDialogData()),
    validateEntity: (entityType, payload) => dispatch(validateEntity(entityType, payload)),
    closeConnection: (entityType, payload) => dispatch(closeConnection(entityType, payload)),
    closeErrorModal: () => dispatch(closeErrorModal()),
    closeActionModal: () => dispatch(closeActionModal()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManifestUnloadCard);