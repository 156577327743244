import { Constants } from "../../../common/utils/constants";
import reportsActionConstants from "./reportsActionConstants";

export const initialState = {
    reportFormData : {
        "partners"     : [{
            value : Constants.PARTNER_ID,
            key   : Constants.PARTNER_ID,
            label : "Self"
        }],
        "destinations" : [{
            value : Constants.userLocationId,
            key   : Constants.userLocationId,
            label : localStorage.user ? JSON.parse(atob(localStorage.user))?.location?.name + "  (Partner)" : "Self"
        }],
        "customers"    : [{
            value : -1,
            key   : -1,
            label : "All"
        }],
        "employees"    : [{
            key     : -1,
            value   : -1,
            label   : "All"
        }] 
    },
    reportConfig   : {},
    partnersChanged : false
}
export const ReportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case reportsActionConstants.UPDATE_REPORT_FORM_DATA  : return updateReportFormdata(state, action);

        case reportsActionConstants.UPDATE_REPORT_CONFIG     : return updateReportConfig(state, action);
        
        case reportsActionConstants.STORE_REPORT_TYPES_ARRAY : return storeReportTypes(state, action);
        
        case reportsActionConstants.STORE_PARTNERS_DATA      : return storePartnersData(state, action);

        case reportsActionConstants.STORE_CUSTOMERS_DATA     : return storeCustomersData(state, action);
        
        case reportsActionConstants.UPDATE_PARTNERS          : return partnersChanged(state, action)
    
        default : return state;
    }
}

const updateReportFormdata = (state, action) => ({
    ...state,
    reportFormData : action.data
})

const updateReportConfig = (state, action) => ({
    ...state,
    reportConfig : action.data
})

const storeReportTypes = (state, action) => ({
    ...state,
    reportTypes : action.data
})

const storePartnersData = (state, action) => ({
    ...state,
    partners : action.data
})

const storeCustomersData = (state, action) => ({
    ...state,
    customers : action.data
})


const partnersChanged = (state, action) => ({
    ...state,
    partnersChanged : action.data
})