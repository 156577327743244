import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Freezer from 'freezer-js';
import Editor from './Editor/Editor';
import { getConfig, updateConfig, resetUpdatedData } from './actions';
import { notifyApiSuccess } from '../../../common/utils/utils';
import './config.scss';
import _ from 'lodash';
import { Card, Button, Spin, Table, Row, Col, Tooltip, Icon, Input, Badge, Modal } from 'antd';


const { confirm } = Modal;

class Config extends PureComponent {

    state = {
        data: {},
        viewJsonModal: false,
        jsonConfig: {},
    }

    componentDidMount() {
        this.props.getConfig();
    }

    componentDidUpdate(prevProps) {

        if (this.props.customConfig && this.props.customConfig.configData && prevProps.customConfig.configData !== this.props.customConfig.configData) {
            // this.setState({ data: this.props.customConfig.configData }, () => {
            let newData = this.props.customConfig.configData.response.applicationConfig.map(con => {

                let newConfig = {
                    ...con,
                    ...(_.hasIn(con, 'partner') && { partnerName: con?.partner?.name }),

                    config: JSON.parse(con.config)
                };
                return newConfig
            });
            this.setState({ data: newData })
            // });
        }
        if (this.props.updatedConfig && this.props.updatedConfig?.configData) {
            this.props.resetUpdatedData();
            notifyApiSuccess("Config Data Updated", "SUCCESS")
        }
    }

    updateNewConfig(newData) {
        let diff = _.differenceWith(newData, this.state?.data, _.isEqual);
        let request = {};
        if (diff && diff.length > 0) {
            diff.map(d => {
                request = {
                    "id": d.id,
                    "config": JSON.stringify(d.config)
                }


                this.props.updateConfig(request)
            })

        }
        // if(!_.isEmpty(diff)) {

        // }
    }


    download = () => {
        let filename = "export.json";
        let contentType = "application/json;charset=utf-8;";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(this.state.data)))], { type: contentType });
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            var a = document.createElement('a');
            a.download = filename;
            a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(this.state.data));
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }
    isJson = (str) => {
        try {
            JSON.parse(JSON.stringify(str));
        } catch (e) {
            return false;
        }
        return true;
    }
    search = e => {
        console.log("search ,", e)
    }

    editConfig = row => {
        this.props.history.push('/appv2/settings/dashboard/custom-config/edit', { config: row })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            filtered ?
                <Badge count={1}>
                    <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined, fontSize: 15 }} />
                </Badge> :
                <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined, fontSize: 15 }} />

        ),
        onFilter: (value, record) => {
            if (_.hasIn(record, dataIndex)) {
                return record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
            } else {
                return false
            }

        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            this.state.searchedColumn === dataIndex ? (
                text
            ) : (
                    text
                )
        )

    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    viewConfig = row => {
        this.setState({ viewJsonModal: true, jsonConfig: row?.config });
    }

    cancelConfig = () => {
        this.setState({ viewJsonModal: false }, () => {
            this.setState({ jsonConfig: {} })
        });
    }

    render() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 10,
            },
            {
                title: 'Partner ID',
                dataIndex: 'partnerId',
                key: 'partnerId',
                width: 10,
                ...this.getColumnSearchProps('partnerId'),
            },
            {
                title: 'Partner',
                dataIndex: 'partnerName',
                key: 'partnerName',
                render: (data) => data,
                width: 150,
                ...this.getColumnSearchProps('partnerName'),

            },
            {
                title: 'User ID',
                dataIndex: 'userId',
                key: 'userId',
                width: 10,
            },
            {
                title: 'Config',
                dataIndex: 'config',
                key: 'config',
                render: (data) => _.isObject(data) && this.isJson(data) ? (JSON.stringify(data))?.substring(0, 50) + "...}" : data,
                width: 150,
            },
            {
                title: 'Action',
                dataIndex: '',
                key: 'action',
                width: 10,
                render: (data, row) => (<Row>
                    {
                        <Col span={8}><Tooltip placement="top" title={'Edit'}>
                            {/* <Icon type="edit" onClick={() => this.props.history.push(`/appv2/settings/form/user/${row.id}`)} /> */}
                            <Icon type="edit" onClick={() => this.editConfig(row)} />
                        </Tooltip>
                        </Col>
                    }
                    {
                        <Col span={8}><Tooltip placement="top" title={'View Config'}>
                            {/* <Icon type="edit" onClick={() => this.props.history.push(`/appv2/settings/form/user/${row.id}`)} /> */}
                            <Icon type="eye" onClick={() => this.viewConfig(row)} />
                        </Tooltip>
                        </Col>
                    }

                </Row >)
            },
        ];
        // var data = {
        //     "id": 1,
        //     "name": "Loadshare Networks",
        //     "isActive": true,
        //     "contactDetails": {
        //         "mobile": 9999999999,
        //         "email": "hr@loadshare.net"
        //     },
        //     "branches": [
        //         "Bangalore",
        //         "Guwahati",
        //         "Delhi"
        //     ],
        //     "customers": [
        //         {
        //             "name": "Flipkart",
        //             "active": true,
        //             "services": [
        //                 "LINE_HUAL",
        //                 "LAST_MILE"
        //             ]
        //         },
        //         {
        //             "name": "Swiggy",
        //             "active": true,
        //             "services": [
        //                 "HYPER_LOCAL"
        //             ]
        //         },
        //         {
        //             "name": "Paytm",
        //             "active": true,
        //             "services": [
        //                 "FIRST_MILE",
        //                 "LINE_HUAL"
        //             ]
        //         }
        //     ]
        // };

        if (!_.isEmpty(this.state.data)) {
            const fr = new Freezer({ json: this.state.data });
            return (
                <div className="App">
                    <Card
                        title={"Custom Config"}
                    >
                        {/* <div style={{float: 'right', width: 300}} >
                            <Input.Search  placeholder="Search by Partner ID" onSearch={this.search} />
                        </div> */}
                        <Table columns={columns} dataSource={this.state.data} />
                        {/* <Editor data={fr.get()} updateNewConfig={(data) => this.updateNewConfig(data)} /> */}
                    </Card>
                    <Modal
                        visible={this.state.viewJsonModal}
                        title="View JSON"
                        centered
                        bodyStyle={{ maxHeight: 500 }}
                        closable={false}
                        footer={
                            [
                                <Button type="primary" onClick={this.cancelConfig}>OK</Button>
                            ]
                        }
                    >
                        <div style={{ maxHeight: 450, overflow: 'scroll' }}>
                            <pre style={{width: '30rem'}}>{JSON.stringify(this.state.jsonConfig, null, 2)}</pre>
                        </div>

                    </Modal>

                </div >
            )
        }
        return (
            <div className="App">
                <Card
                    bodyStyle={{ height: 530, position: 'relative' }}
                >
                    <div className="spin-config">
                        <Spin />
                    </div>



                </Card>

            </div>
        )

    }
}

// export default Config;


const mapStateToProps = (state, ownProps) => ({
    customConfig: state?.customConfig?.config,
    updatedConfig: state?.customConfig?.updatedConfig,
});
const mapDispatchToProps = dispatch => ({
    getConfig: () => dispatch(getConfig()),
    updateConfig: (params) => dispatch(updateConfig(params)),
    resetUpdatedData: () => dispatch(resetUpdatedData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Config);
