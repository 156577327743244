import { Icon, Table, Modal, Button, Input } from "antd";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Constants, DownloadTags } from "../../../common/utils/constants";
import * as utils from "../../../common/utils/utils";
import TrackingPopupModal from "../../shared-components/tracking-popup-modal";
import ManifestTrackingPopUp from "../../TrackingPopup/ManifestTrackingPopup";
import { openNotification } from "../../ui/notification/Notification";
import * as actions from "./action";

const cardTitle = {
  "waybills": "Track Waybills",
  "manifests": "Track Manifests",
  "consigneeNumber": "Track Consignee Phone No."
}

const documentType = {
  "waybills": "BOOKING_REPORT",
  "manifests": "MANIFEST_REPORT"
}

class TrackingListing extends PureComponent {
  state = {
    consignmentStatus: undefined,
    showTrackingPopup: false,
    selectedWaybill: "",
    searchText: "",
    columns: [
      {
        title: "WayBill No.",
        dataIndex: "waybillNo",
        render: (waybillNo, consignment) => {
          return (
            <a
              className="selected-waybill"
              onClick={this.showPopup.bind(this, waybillNo, consignment?.consignmentStatus)}
            >
              {waybillNo}
            </a>
          );
        },
        className: "large-col"
      },
      {
        title: "Booking Date & Location",
        dataIndex: "bookingDate",
        render: (text, record) => {
          return (
            <div>
              <p>{new Intl.DateTimeFormat("en-GB").format(text)}</p>
              <p>{record?.bookingOfficeLoc?.name}</p>
            </div>
          );
        },
        className: "large-col"
      },
      {
        title: "Partner Name",
        dataIndex: "partner.name"
      },
      {
        title: "Pickup Location",
        dataIndex: "customerPickupLoc.name",
        className: "large-col"
      },
      {
        title: "CPD",
        dataIndex: "expectedDeliveryDate",
        render: text => utils.formatDate(text),
        className: "small-col",
        width: 110
      },
      {
        title: "Delivery Attempts",
        dataIndex: "attempts",
        className: "small-col"
      },
      {
        title: "Current Location",
        dataIndex: "location.name",
        className: "small-col"
      },
      {
        title: "Status",
        dataIndex: "consignmentStatus",
        className: "small-col",
        render: (text, row) => {
          return (
            <div>
              <p>{text}</p>
              <p className="small-text">{utils.formatDate(row.updatedAt)}</p>
              <p className="small-text">{utils.formatTime(row.updatedAt)}</p>
            </div>
          );
        }
      }
    ],
    manifestsColumns: [
      {
        title: "Manifest No",
        dataIndex: "manifestCode",
        render: text => {
          return (
            <span
              className="selected-waybill"
              onClick={this.showPopup.bind(this, text, undefined)}
            >{text}
            </span>
          )
        },
        className: "large-col"
      },
      {
        title: "Created Date & Location",
        "dataIndex": "createdAt",
        render: (text, record) => {
          return (
            <div>
              <p>{new Intl.DateTimeFormat("en-GB").format(text)}</p>
              <p>{record.originLocation}</p>
            </div>
          );
        },
      },
      {
        title: "Partner",
        dataIndex: "partnerName"
      },
      {
        title: "Next Location",
        dataIndex: "nextLocation",
        className: "large-col"
      },
      {
        title: "Current Location",
        dataIndex: "currentLocation",
        className: "large-col"
      },
      {
        title: "Destination Location",
        dataIndex: "destinationLocation",
        className: "large-col"
      },
      {
        title: "Shipments Count",
        dataIndex: "shipmentsCount",
        className: "large-col"
      },
      {
        title: "Status",
        dataIndex: "status",
        className: "small-col",
        render: (text, row) => {
          return (
            <div>
              <p>{text}</p>
              <p className="small-text">{utils.formatDate(row.eventTime)}</p>
              <p className="small-text">{utils.formatTime(row.eventTime)}</p>
            </div>
          );
        }
      }
    ]
  };
  componentDidMount() {
    Constants.TRACKING_CLASSNAME = this.constructor.name;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.downloadWaybillsLoading &&
      !this.props.downloadWaybillsLoading &&
      !this.props.downloadWaybillError &&
      this.props.downloadWaybillsUrl
    ) {
      utils.reportsDownload(this.props.downloadWaybillsUrl, DownloadTags.s3);
    }

    if ((prevProps.selectedRadioValue != this.props.selectedRadioValue) || (prevProps.clearSearchText != this.props.clearSearchText)) {
      this.setState({
        searchText: ""
      })
    }
  }
  showPopup = (selectedWaybill, consignmentStatus) => {
    this.setState({
      showTrackingPopup: true,
      selectedWaybill,
      consignmentStatus
    });
  };

  handleCancel = e => {
    this.setState({
      showTrackingPopup: false
    });
  };
  onChangeSearchText = e => {
    const searchInput = e.target.value;
    this.setState({
      searchText: searchInput
    });

    var searchField;
    if (this.props.selectedRadioValue == "waybills") {
      searchField = "waybillNo"
    } else if (this.props.selectedRadioValue == "manifests") {
      searchField = "manifestCode"
    }
    this.props.filterRecords(this.props.tableDataShallowCopy, searchInput, searchField);
  };

  printAllWaybillChallans = _ => {
    if (this.props.tableData && this.props.tableData.length > 0) {
      var selectedIds = ""
      this.props.tableData.map(waybill => {
        selectedIds += waybill.id + ","
      })
      var encodedIds = btoa(selectedIds);
      window.open(`/appv2/print_information?consignmentIds=${encodedIds}&formtype=multiplechallans`, "_blank");
    }
    else {
      openNotification({
        message: 'No waybills to print!'
      });
    }
  }

  downloadSearchedWaybills = () => {
    if (!this.props.tableData?.length) {
      return;
    }
    const waybillsToDownload = [];
    this.props.tableData.forEach(row => {
      if (this.props.selectedRadioValue === "manifests") {
        if (row.manifestCode) {
          waybillsToDownload.push(row.manifestCode);
        }
      } else {
        if (row.waybillNo) {
          waybillsToDownload.push(row.waybillNo);
        }
      }
    });

    const data = {
      partnerId: Constants.PARTNER_ID,
      locationId: Constants.userLocationId,
      userId: Constants.sessionUser.id,
      documentType: documentType[this.props.selectedRadioValue],
      drsType: "B2C"
    };

    if (this.props.selectedRadioValue === "manifests") {
      delete data['partnerIds'];
      data["entityCode"] = waybillsToDownload;
    } else {
      data[this.props.selectedRadioValue] = waybillsToDownload;
    }
    this.props.downloadSearchedWaybills(data);
  };
  render() {
    // const { searchText } = this.state;
    const tableLength =
      this.props.filteredTableData?.length ||
      this.props?.tableData?.length ||
      0;

    return (
      <>
        <div className="flex-column border-radius-s align-items-stretch flex-gap-l white-bg spacer-l border-grey">
          <div className="flex-box justify-content-space-between">
            <div className="font-size-l-2 text-semi-bold"> {cardTitle[this.props.selectedRadioValue]} </div>
            <div className="flex-box align-items-center flex-gap-m">
              <Input className="search-bar" placeholder="Search"
                suffix={<Icon type="search"/>} value={this.state.searchText}
                onChange={this.onChangeSearchText} />
              <Button onClick={this.printAllWaybillChallans} className="lsn-btn-secondary" icon="printer"
               disabled={this.props.selectedRadioValue === "manifests"}>
                Print All Challans 
              </Button>
              <Button loading={this.props.downloadWaybillsLoading} 
               onClick={this.downloadSearchedWaybills} className="lsn-btn-primary" icon="download" >
                Download All
              </Button>
            </div>
          </div>

          <Table bordered={true} scroll={{ x: true }}
            columns={this.props.selectedRadioValue === "manifests" ? this.state.manifestsColumns : this.state.columns}
            dataSource={this.state.searchText ? this.props.filteredTableData : this.props.tableData}
            footer={() => `${tableLength} Total`}/>
        </div>
        {
          this.props.selectedRadioValue === "manifests" ?
          <Modal onCancel={this.handleCancel} visible={this.state.showTrackingPopup} destroyOnClose={true}
            width={this.props.selectedRadioValue === "manifests" ? "70vw" : "85vw"} footer={<div></div>} centered >
              <ManifestTrackingPopUp  manifestNo={this.state.selectedWaybill} />
          </Modal> :
          <TrackingPopupModal onCancel={this.handleCancel} consignmentStatus={this.state.consignmentStatus}
            visible={this.state.showTrackingPopup} input={this.state.selectedWaybill}
            waybillNo={this.state.selectedWaybill} source={Constants.TRACKING_CLASSNAME} />
        }
      </>
    );
  }
}

const mapStateToProps = state => ({
  downloadWaybillsLoading: state.trackingListing.downloadWaybillsLoading,
  downloadWaybillsUrl: state.trackingListing.downloadWaybillsUrl,
  downloadWaybillError: state.trackingListing.downloadWaybillError,
  filteredTableData: state.trackingListing.filteredTableData
});
const mapDispatchToProps = dispatch => ({
  downloadSearchedWaybills: params =>
    dispatch(actions.downloadSearchedWaybills(params)),
  filterRecords: (fullTableData, searchInput, searchField) =>
    dispatch(actions.filterRecords(fullTableData, searchInput, searchField))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackingListing);
