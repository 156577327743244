import { Button, Tag } from "antd";
import React from "react";
import WaybillLink from "../../shared-components/waybill-link";
import "./ManageReversePickup.style.scss";

export const columns = (handleActionClick) => {
  return [
    {
      title: "AWB No",
      dataIndex: "waybillNumber",
      key: "waybillNumber",
      width: "40%",
      render: data => <WaybillLink>{data}</WaybillLink>
    },
    {
      title: "Client",
      dataIndex: "customerName",
      key: "customerName"
    },
    {
      title: "Consignee",
      dataIndex: "consigneeName",
      key: "consigneeName"
    },
    {
      title: "Address",
      dataIndex: "consigneeAddress",
      key: "consigneeAddress",
      width: "40%",
      render: (data, row) => {
        return (
          <div className="address-text">{data}</div>
        )
      }
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
      width: 75
    },
    {
      title: "Ageing",
      dataIndex: "ageing",
      key: "ageing"
    },
    {
      title: "Attempt Count",
      dataIndex: "attemptCount",
      key: "attemptCount"
    },
    {
      title: "FE",
      dataIndex: "feName",
      key: "feName",
      width: "60%",
      render: (data, row) => {
        return (
          <>
            <div>{row?.feName}</div>
            <div>{row?.feNumber}</div>
          </>
        )
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data, row) => {
        return (
          <div className="flex-column align-items-center status-cell">
            <Tag className={"ant-table-tag text-align-center " + LeadStatusColors[data]}>
              {LeadStatus[data]?.replace("_", " ")}
            </Tag>
            <b>{row.packetNumber}</b>
          </div>
        );
      }
    },
    {
      title: "",
      dataIndex: "waybillNumber",
      key: "action",
      render: (data, row) => {
        return (
          <div className="flex-box">
            {row?.showPrintButton &&
              <Button className="lsn-btn-primary" onClick={_ => handleActionClick(row)}>Print label</Button>
            }
            {row?.showScanButton &&
              <Button style={{ color: "white", backgroundColor: "#0E9E6B" }} className="lsn-btn-success" onClick={_ => handleActionClick(row)}>Scan AWB</Button>
            }
          </div>
        )
      }
    }
  ]
};

const LeadStatusColors = Object.freeze({
  PCREATED: "warning-tag",
  PPEND: "warning-tag",
  BOOK: "warning-tag",
  OFP: "info-tag",
  PCANC: "error-tag",
  PSUCC: "success-tag"
});

const LeadStatus = Object.freeze({
  PCREATED: "Unassigned",
  PPEND: "Unassigned",
  BOOK: "Unassigned",
  OFP: "Assigned",
  PCANC: "Not Picked",
  PSUCC: "Picked"
});
