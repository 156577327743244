import { Modal, Select, Spin } from 'antd';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { notifyApiSuccess } from "../../../common/utils/utils";
import LoaderService from "../../../services/loader";
import { updateCgnToTamperedStatus } from "./actions";
import "./tampered-reason-select.scss";

const TamperedReasonSelect = ({
    scanType,
    onTamperSuccess,
    customerId,
    waybillNo
}) => {

    const [selectedReason, setSelectedReason] = useState();
    const [waybillNumber, setWaybillNumber] = useState(null);
    const [reasonOptions, setReasonOptions] = useState([]);
    const [loading, SetLoading] = useState(false);

    const config = useSelector(state => state?.app?.configurations);

    const [tamperingEnabled, setTamperingEnabled] = useState(config.enableTamperedHandling == 'true');

    useEffect(() => {
        setTamperingEnabled(config.enableTamperedHandling == 'true');
        setReasonOptions(config.reasons.TAMPERED);
    }, [config]);

    useEffect(() => {
        if (waybillNumber != waybillNo) {
            setSelectedReason();
            setWaybillNumber(waybillNo);
        }
    }, [waybillNo])

    // Triggering api once confirmed through confirmation modal
    const onConfirmationSubmit = (reason) => {
        if (selectedReason || reason) {
            SetLoading(true);
            const requestData = {
                waybillNumber: waybillNo,
                reasonId: reason
            }
            updateCgnToTamperedStatus(requestData)
                .then((res) => {
                    SetLoading(false);
                    if (res?.data?.status?.code == 200) {
                        notifyApiSuccess(res?.data?.status?.message)
                        if (!selectedReason) {
                            setSelectedReason(reason)
                        }
                        if (typeof onTamperSuccess == "function") {
                            onTamperSuccess();
                        }
                    } else {
                        setSelectedReason();
                    }
                })
                .catch((err) => {
                    SetLoading(false);
                    setSelectedReason();
                })
        }
    }

    return (
        tamperingEnabled && customerId && config.enableTamperedHandlingForCustomer && config.enableTamperedHandlingForCustomer.indexOf(customerId + "") > -1
            && reasonOptions && reasonOptions?.length > 0 && reasonOptions?.filter(r => r.customerId == customerId || r.customerId == 0).length > 0 ?
            <>
                <div className="flex-box align-items-center">
                    <Spin spinning={loading}>
                        <Select placeholder="Mark as Tampered" allowClear={true}
                            value={selectedReason} style={{ minWidth: 200 }}
                            showSearch={true} className='select-root'
                            onChange={onConfirmationSubmit}
                            disabled={loading || (selectedReason == null ? false : true)} loading={loading}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                reasonOptions.filter(r => r.customerId == customerId || r.customerId == 0)?.map((tamperedReason) => {
                                    return (
                                        <Select.Option value={tamperedReason?.reasonId} key={tamperedReason?.reasonId}>
                                            {tamperedReason?.reasonDescription}
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Spin>
                </div>
            </> :
            <></>
    )

}

export default TamperedReasonSelect;