export const INITIAL_STATE = {
    loading: false,
    error: null,
    entityLabelTypes: [],
    selectedEntity: undefined,
    isAutoPrintEnabled: true,
    currentScannedEntityInfo: null,
    scanStatus: {
        success: false,
        error: false,
        message: ""
    },
    scannedEntityHistory: [],
    rescanModalState: {
        show: false,
        message: "",
        payload: null
    },
    bulkPrintingLoading: undefined,
    bulkPrinting: undefined,
    labelPrintingPDFUrl: undefined,
    excelUploadResponseUrl: undefined,
};

export const ACTION_TYPES = {
    UPDATE_SELECTED_ENTITY_TYPE: "UPDATE_SELECTED_ENTITY_TYPE",

    FETCH_LABEL_PRINTING_TYPES_BEGIN: "FETCH_LABEL_PRINTING_TYPES_BEGIN",
    FETCH_LABEL_PRINTING_TYPES_SUCCESS: "FETCH_LABEL_PRINTING_TYPES_SUCCESS",
    FETCH_LABEL_PRINTING_TYPES_FAILURE: "FETCH_LABEL_PRINTING_TYPES_FAILURE",

    FETCH_LABEL_PRINTING_DATA_BEGIN: "FETCH_LABEL_PRINTING_DATA_BEGIN",
    FETCH_LABEL_PRINTING_DATA_SUCCESS: "FETCH_LABEL_PRINTING_DATA_SUCCESS",
    FETCH_LABEL_PRINTING_DATA_FAILURE: "FETCH_LABEL_PRINTING_DATA_FAILURE",

    CLEAR_FETCH_PRINT_DATA_ALERT: "CLEAR_FETCH_PRINT_DATA_ALERT",

    UPDATE_RESCAN_MODAL_STATE: "UPDATE_RESCAN_MODAL_STATE",
    CLEAR_RESCAN_MODAL_STATE: "CLEAR_RESCAN_MODAL_STATE",

    UPDATE_AUTO_PRINT_FLAG: "UPDATE_AUTO_PRINT_FLAG",

    FETCH_CUSTOMER_LIST_BEGIN: "GET_CUSTOMER_LIST_BEGIN",
    FETCH_CUSTOMER_LIST_SUCCESS: "GET_CUSTOMER_LIST_SUCCESS",
    FETCH_CUSTOMER_LIST_FAILURE: "GET_CUSTOMER_LIST_FAILURE",

    UPDATE_S3_PATH: "UPDATE_S3_PATH",

    UPLOAD_PRINTING_BEGIN: "UPLOAD_PRINTING_BEGIN",
    UPLOAD_PRINTING_SUCCESS: "UPLOAD_PRINTING_SUCCESS",
    UPLOAD_PRINTING_FAILURE: "UPLOAD_PRINTING_FAILURE",

    PRINT_BULK_AWB_STICKER_BEGIN: "PRINT_BULK_AWB_STICKER_BEGIN",
    PRINT_BULK_AWB_STICKER_SUCCESS: "PRINT_BULK_AWB_STICKER_SUCCESS",
    PRINT_BULK_AWB_STICKER_FAILURE: "PRINT_BULK_AWB_STICKER_FAILURE",

    PRINT_SINGLE_AWB_STICKER_BEGIN: "PRINT_SINGLE_AWB_STICKER_BEGIN",
    PRINT_SINGLE_AWB_STICKER_SUCCESS: "PRINT_SINGLE_AWB_STICKER_SUCCESS",
    PRINT_SINGLE_AWB_STICKER_FAILURE: "PRINT_SINGLE_AWB_STICKER_FAILURE",

    CLEAR_CURRENT_SCANNED_ENTITY : "CLEAR_CURRENT_SCANNED_ENTITY",
    TOGGLE_LOADER: "TOGGLE_LOADER"
}

export const PRINT_ENTITY_TYPES = {
    MANIFEST_STICKER_PRINT: "MANIFEST",
    SHIPMENT_LABEL_PRINT: "SHIPMENT",	
};

export const SCAN_INPUT_TYPES = {
    BARCODE_SCANNER: "BARCODE_SCANNER",
    KEYBOARD: "KEYBOARD"
};

export const PRINT_ENTITY_SCAN_RESPONSE_COLUMNS = {
    "MANIFEST": [
        {
            key: "manifestCode",
            label: "Manifest No"
        },
        {
            key: "manifestStatus",
            label: "Status"
        },
        {
            key: "originLocation",
            label: "Origin"
        },
        {
            key: "destinationLocation",
            label: "Destination"
        },
        {
            key: "nextLocation",
            label: "Next Location"
        }
    ],
    "SHIPMENT": [
        {
            key: "waybillNo",
            label: "Waybill No"
        },
        {
            key: "consignmentStatus",
            label: "Status"
        }
    ]
};