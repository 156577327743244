import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import LabelTemplate from "./label-template";

const LabelValmoXUtilMapping = ({ handlePrintTask, printData }) => {
    let componentRef = useRef(null);
    const pageStyle = `@media print {
        @page {
            size    : 4in 6in; 
            margin  : 0; 
            padding : 0;
        }
    }`;

    const [data, setData] = useState(null);

    useEffect(() => {
        if (data) {
            handlePrint()
        }
    }, [data])

    useEffect(() => {
        if (printData && Object.keys(printData).length > 0) {
            setData(printData)
        }
    }, [printData])


    const handleAfterPrint = () => {
        handlePrintTask()
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: pageStyle,
        documentTitle: "Label",
        removeAfterPrint: true,
        onAfterPrint: handleAfterPrint
    });

    return (
        <div>
            <div style={{ display: "none" }} className="m-20">
                {data && <LabelTemplate data={data} flag={true} ref={componentRef} />}
            </div>
        </div>
    )
}

export default LabelValmoXUtilMapping;