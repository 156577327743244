import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import RTOEligibleTable from "./rto-eligible.table";
import { getConsignmentsData, getConfigData } from "./action";
import { FlowTypes } from "../../../common/utils/constants";

const RTOEligible = ({
  loading,
  consignments,
  getConsignmentsFunction,
  PaginatedRTOEligible,
  stateData,
  getConfigData
}) => {
  const [state, setState] = useState({
    filterPayload: {
    filters: {
        pageNo: 0,
        pageSize: 10,
        totalCount: 0,
        isArrived: true,
        consignments: [],
        isBookedOnly: true,
        isEligibleForRTO: true
         }
      },
    flowTypes: FlowTypes.RTO
  });

  const applyPagination = (filters, isReset = false, isSearched = false) => {
    if (isReset) {
      setState({
        ...state,
        pageNo: 1
      });
    }

  const filter = {
      ...state.filterPayload.filters,
      ...filters,
      totalCount: consignments?.totalCount || 0
    };
    getConsignmentsFunction({ filters: filter }, isReset);
  };

  const getPaginatedData = pageNo => {
    applyPagination({ pageNo: pageNo }, false);
    setState({
      ...state,
      pageNo
    });
  };

  useEffect(() => {
    applyPagination({ pageNo: 0 }, true);
    getConfigData();
  }, []);

  return (
    <div>
      <Spin spinning={loading}>
        <RTOEligibleTable
          data={stateData}
          getPaginatedData={getPaginatedData}
          pageNo={state.pageNo}
          PaginatedRTOEligible={PaginatedRTOEligible}
          applyPagination={applyPagination}
        />
      </Spin>
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.rtoEligible.loading,
  consignments: state.rtoEligible.consignments,
  paginationData: state.rtoEligible.paginationData,
  dataCardList: state.rtoEligible.dataCardList,
  stateValue: state.rtoEligible,
  PaginatedRTOEligible: state.rtoEligible.PaginatedRTOEligible,
  stateData: state.rtoEligible
});

const mapDispatchToProps = dispatch => ({
  getConsignmentsFunction: (payload, isReset) => dispatch(getConsignmentsData(payload, isReset)),
  getConfigData: () => dispatch(getConfigData())
});

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(RTOEligible);
