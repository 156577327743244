import { Alert, Modal, Select, Spin, Tag } from "antd";
import React from "react";

export default function AssignFEModal({
  isAssignModalOpen,
  onAssignCancel,
  allocateWaybillToFE,
  isFeButtonDisabled,
  onFeChange,
  feUserId,
  feloading,
  popupLoading,
  feList,
  filteredLeadData,
  unpaginatedFilteredLeadData
}) {


  return (

    <Modal title="Assign FE" visible={isAssignModalOpen}closable={false}
      okText={"Confirm"} className="assign-modal"
      onOk={allocateWaybillToFE} okButtonProps={{ disabled: isFeButtonDisabled }}
      cancelButtonProps={{ disabled: isFeButtonDisabled }}
      destroyOnClose={onAssignCancel} onCancel={onAssignCancel} >
      <Spin spinning={feloading || popupLoading}>
        <div className="flex-column flex-gap-l">
          <div className="spacer-m grey-bg border-grey flex-column flex-gap-l border-radius-m">

            <div className="flex-box flex-gap-l">
              <div className="flex-column flex-gap-s flex-1">
                <div> Pincodes Selected </div>
                <div className="text-bold"> {unpaginatedFilteredLeadData?.totalPincodes || filteredLeadData?.totalPincodes} </div>
              </div>
              <div className="flex-column flex-gap-s flex-1">
                <div> Shippers Selected </div>
                <div className="text-bold"> {unpaginatedFilteredLeadData?.totalShippers || filteredLeadData?.totalShippers} </div>
              </div>
            </div>

            <div className="flex-box flex-gap-l">
              <div className="flex-column flex-gap-s flex-1">
                <div> Total Weight </div>
                <div className="text-bold"> {unpaginatedFilteredLeadData?.totalWeight || filteredLeadData?.totalWeight} Kg </div>
              </div>
              <div className="flex-column flex-gap-s flex-1">
                <div> Shipments Selected </div>
                <div className="text-bold"> {unpaginatedFilteredLeadData?.totalCount || filteredLeadData?.totalCount} </div>
              </div>
            </div>

          </div>

          <Select className="border-radius-m" showSearch={true} style={{ height: "auto" }}
            dropdownStyle={{ borderRadius: 10, marginTop: 10 }} dropdownMenuStyle={{ padding: 0 }}
            placeholder={"Select FE"} onChange={onFeChange} value={feUserId}
            filterOption={(input, option) => option?.props?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0} >
            {
              feList?.map(fe => (
                <Select.Option value={fe.id} key={fe.id} label={fe.name}>
                  <div className="flex-box justify-content-space-between flex-gap-m align-items-center flex-1">
                    <div>{fe.name}</div>
                    <Tag className={fe.onTrip === false ? "success-tag" : fe.onTrip === true ? "warning-tag" : "info-tag"}>
                      {fe.onTrip === true ? "On Trip" : fe.onTrip === false ? "Available" : "Unavailable"}
                    </Tag>
                  </div>
                </Select.Option>
              ))}
          </Select>
        </div>
      </Spin>
      {
        feloading && isFeButtonDisabled &&
        <Alert style={{ marginTop : 15 }} type="warning" showIcon={true}
         message="Do not close or refresh this window until allocation is completed."/>
      }
    </Modal>
  )
}