import { Button, Tag } from "antd";
import moment from "moment";
import { codTransactionModes, formatCurrency } from "../../common/utils/utils";
import RiskTemplate from "./components/risk.template";
import SettlementActions from "./components/columns/settlement.actions.column";
import PartnerActionColumn from "./components/columns/partner.action.column";
import RemitActionColumn from "./components/columns/remit.action.column";
import SettlementActivityRemarks from "./components/columns/settlement.remarks.column";
import { userType } from "./utils";

export const entityColumns = {
    GROUP_CRITERIA_PARTNER_COLUMNS : [
        {
            title     : "Partner",
            key       : "entityName",
            dataIndex : "entityName",
            render    : d => <strong>{d}</strong>
        },
    ],
    GROUP_CRITERIA_BRANCH_COLUMNS : [
        {
            title     : "Branch",
            key       : "entityName",
            dataIndex : "entityName"
        }
    ],
    GROUP_CRITERIA_SHIPPER_COLUMNS : [
        {
            title     : "Customer",
            key       : "entityName",
            dataIndex : "entityName"
        }
    ],
    GROUP_CRITERIA_ROOT_PARTNER_COLUMNS : [
        {
            title     : "Partner",
            key       : "entityName",
            dataIndex : "entityName",
            render    : d => <strong>{d}</strong>
        },
    ]
}

export const groupCriteriaCoulmns = {
    PARTNER : actions => ([
        {
            title     : "Risk",
            key       : "riskLabel",
            dataIndex : "riskLabel",
            render    : riskType => <RiskTemplate riskType={riskType} />
        },
        {
            title     : "Last Transacted",
            key       : "lastUpdatedAt",
            dataIndex : "lastUpdatedAt",
            render    : date => moment(date).format("DD/MM/YYYY hh:mm A")
        },
        {
            title     : "Action",
            key       : "entityId",
            dataIndex : "entityId",
            render    : (entityId, row) => <PartnerActionColumn actions={actions} partnerId={entityId} rowData={row} />
        }
    ]),
    BRANCH : actions => (userType() === "PARTNER_ADMIN" ? [
        {
            title     : "Action",
            key       : "entityId",
            dataIndex : "entityId",
            render    : (entityId, row) => <RemitActionColumn groupCriteria="BRANCH" actions={actions} entityId={entityId} rootPartnerData={row} />
        }
    ] : []),
    ROOT_PARTNER : actions => [
        {
            title     : "Action",
            key       : "entityId",
            dataIndex : "entityId",
            render    : (entityId, row) => <RemitActionColumn groupCriteria="ROOT_PARTNER" actions={actions} entityId={entityId} parentEntityId={row.parentEntityId} rootPartnerData={row} />
        }
    ]
}

/*
    AMOUNT_MAPPING_KEYS to generate specific timeline from empty objects
    enabling 'overWriteWithTimesStamps' will replace if time stamps are sent
    refer "convertAmountMappings" in utils
 */
export const DATE_RANGE_MAPPING_KEYS = {
    NET_OUTSTANDING : {
        overWriteWithTimesStamps: true,
        timeline : {
            from : moment().subtract(21, "days").valueOf(),
            to   : moment().subtract(15, "days").valueOf(),
        },
        title  : "Total Outstanding",
        render : data => <strong>{formatCurrency(data)}</strong>
    },
    PENDING_FOR_APPROVAL : {
        overWriteWithTimesStamps: true,
        timeline : {
            from : moment().subtract(21, "days").valueOf(),
            to   : moment().subtract(15, "days").valueOf(),
        },
        title  : "Pending For Approval",
        render : data => <strong>{formatCurrency(data)}</strong>
    },
    "T" : {
        timeline : {
            from : moment().startOf("day").valueOf(),
            to   : moment().valueOf(),
        },
        title  : "T",
        render : data => formatCurrency(data)
    },
    "T-1" : {
        timeline : {
            from : moment().subtract(1, "days").startOf("day").valueOf(),
            to   : moment().subtract(1, "days").endOf("day").valueOf(),
        },
        title  : "T-1",
        render : data => formatCurrency(data)
    },
    "T-2" : {
        timeline : {
            from : moment().subtract(2, "days").startOf("day").valueOf(),
            to   : moment().subtract(2, "days").endOf("day").valueOf(),
        },
        title  : "T-2",
        render : data => formatCurrency(data)
    },
    ">T-2" : {
        timeline : {
            from : null,
            to   : moment().subtract(2, "days").startOf("day").valueOf(),
        },
        title  : ">T-2",
        render : data => formatCurrency(data)
    }
}

export const SETTLEMENT_ACTIVITY_COLUMN = actions => ([

    {
        title : "Transaction ID",
        key   : "id",
        dataIndex : "id"
    },
    {
        title : "Transaction Date",
        key   : "paymentInfo",
        dataIndex : "paymentInfo",
        render    : paymentInfo => {
            const paymentMeta = paymentInfo.paymentMeta ? JSON.parse(paymentInfo.paymentMeta) : {}
            if (paymentMeta.transactionDate) {
                return paymentMeta.transactionDate;
            }
            return "--";
        }
    },
    {
        title : "Remit Request Date & Time",
        key   : "createdAt",
        dataIndex : "createdAt",
        render    : time => moment(time).format("DD/MM/YYYY hh:mm A")
    },
    {
        title : "Branch",
        key   : "location.name",
        dataIndex : "location.name"
    },
    {
        title : "Remitted By",
        key   : "user.name",
        dataIndex : "user.name"
    },
    {
        title : "Amount Remitted",
        key   : "actualAmount",
        dataIndex : "actualAmount",
        render    : amount => formatCurrency(amount)
    },
    {
        title : "Payment Mode",
        key   : "paymentOption",
        dataIndex : "paymentOption",
        render    : paymentOption => codTransactionModes() && codTransactionModes()?.length > 1 ?
                                         codTransactionModes().find(mode => mode.value === paymentOption)?.name || paymentOption :
                                         paymentOption
    },
    {
        title : "Remarks",
        key   : "paymentInfo.paymentMeta",
        dataIndex : "paymentInfo.paymentMeta",
        render    : (data, row) => <SettlementActivityRemarks paymentMeta={data} approvalStatus={row.approvalStatus} />
    },
    {
        title : "Proof",
        key   : "paymentInfo.remarks",
        dataIndex : "paymentInfo.remarks",
        render    : data => <Button className="lsn-btn-primary" onClick={_ => window.open(data, "_blank")} type="link">View</Button>
    },
    {
        title : "Status",
        key   : "approvalStatus",
        dataIndex : "approvalStatus",
        hidePartnerView : true,
        render : approvalStatus => (
            <Tag className={`font-size-m-2 ant-table-tag ${approvalStatus === "APPROVED" ? "success-tag" : approvalStatus === "REJECTED" ? "error-tag" : "warning-tag"}`}>
                {
                    approvalStatus === "APPROVED" ? "Approved" : 
                    approvalStatus === "REJECTED" ? "Rejected" : 
                    approvalStatus
                }
            </Tag>
        )
    },
    {
        title : (userType() === "ROOT_PARTNER") ? "Actions" : "Status",
        key   : "actions",
        dataIndex : "actions",
        hideInRootPartnerView : true,
        render    : (data, row) => {
            if (userType() === "ROOT_PARTNER") {
                return <SettlementActions onSettlementActionSuccess={actions?.onSettlementActionSuccess} settlementInfo={row} />
            }
            else {
                const approvalStatus = row.approvalStatus;
                return (
                    <Tag className={`font-size-m-2 ant-table-tag ${approvalStatus === "APPROVED" ? "success-tag" : approvalStatus === "REJECTED" ? "error-tag" : "warning-tag"}`}>
                        {
                            approvalStatus === "APPROVED" ? "Approved" : 
                            approvalStatus === "REJECTED" ? "Rejected" : 
                            approvalStatus
                        }
                    </Tag>
                )
            }
        }
    }
])