import { checkHttpStatus, downloadFile } from "../../../common/utils/utils";
import InventoryPrintService from "../../../services/inventory/print.service";
import { SoundNotificationService } from "../../../services/sound.service";
import { ACTION_TYPES, INITIAL_STATE, PRINT_ENTITY_TYPES } from "./constants";

const inventoryPrintService = new InventoryPrintService();
const soundNotificationService = new SoundNotificationService();

export const printBulkAWBSticker = (payload) => dispatch => {
    dispatch(printBulkAWBStickerBegin());
    inventoryPrintService.printBulkAWBSticker(payload).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            const labelPrintingPDFUrl = res.data.response.labelPrintingPDFUrl;
            const excelUploadResponseUrl = res.data.response.excelUploadResponseUrl;
            downloadFile(labelPrintingPDFUrl, "_blank");
            downloadFile(excelUploadResponseUrl);
            const payload = {
                labelPrintingPDFUrl,
                excelUploadResponseUrl
            }
            dispatch(printBulkAWBStickerSuccess(payload));
        } else {
            dispatch(printBulkAWBStickerFailure(res.data.response));
        }
    });
};
const printBulkAWBStickerBegin = () => {
    return {
        type: ACTION_TYPES.PRINT_BULK_AWB_STICKER_BEGIN
    };
};
const printBulkAWBStickerSuccess = (payload) => {
    return {
        type: ACTION_TYPES.PRINT_BULK_AWB_STICKER_SUCCESS,
        payload: payload
    };
};
const printBulkAWBStickerFailure = (error) => {
    return {
        type: ACTION_TYPES.PRINT_BULK_AWB_STICKER_FAILURE,
        payload: error
    };
};

const getEntityDataFromResponse = (entityType, response) => {
    if (entityType === PRINT_ENTITY_TYPES.MANIFEST_STICKER_PRINT) {
        return response.manifestLabelPrintingDataList;
    } else if (entityType === PRINT_ENTITY_TYPES.SHIPMENT_LABEL_PRINT) {
        return response.consignmentLabelScanBO;
    }
}

export const updateSelectedEntityType = (entityType) => dispatch => {
    dispatch(updateEntityType(entityType));
    if (entityType === PRINT_ENTITY_TYPES.SHIPMENT_LABEL_PRINT) {
        dispatch(updateAutoPrintFlag(false));
    }
};

const updateEntityType = payload => {
    return {
        type: ACTION_TYPES.UPDATE_SELECTED_ENTITY_TYPE,
        payload
    }
};

export const updateAutoPrintFlag = (payload) => dispatch => {
    dispatch(updateAutoPrintFunction(payload));
};

const updateAutoPrintFunction = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_AUTO_PRINT_FLAG,
        payload
    };
};

export const fetchEntityLabelPrintingTypes = () => dispatch => {
    dispatch(fetchEntityLabelTypesBegin());

    inventoryPrintService.getLabelPrintingTypes()
        .then(res => {
            if (checkHttpStatus(res?.data?.status)) {
                const response = res.data.response;
                const printingLabelTypes = response.labelPrintingTypes;
                dispatch(fetchEntityLabelTypesSuccess(printingLabelTypes));
            } else {
                dispatch(fetchEntityLabelTypesFailure(res?.data?.response));
            }
        })
        .catch(err => {
            dispatch(fetchEntityLabelTypesFailure(err))
        });
};

const fetchEntityLabelTypesBegin = () => {
    return {
        type: ACTION_TYPES.FETCH_LABEL_PRINTING_TYPES_BEGIN
    };
};

const fetchEntityLabelTypesSuccess = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_LABEL_PRINTING_TYPES_SUCCESS,
        payload
    };
};

const fetchEntityLabelTypesFailure = (error) => {
    return {
        type: ACTION_TYPES.FETCH_LABEL_PRINTING_TYPES_FAILURE,
        payload: error
    };
};

export const fetchEntityLabelPrintingData = (entityType, payload, isRescan = false) => dispatch => {
    if (isRescan) {
        dispatch(resetRescanModalState());
        payload["manifestScans"].forEach((manifest) => {
            manifest["forceScan"] = true;
        })
    };

    dispatch(fetchEntityLabelPrintingDataBegin());
    inventoryPrintService.getLabelPrintingData(payload)
        .then(res => {
            if (checkHttpStatus(res?.data?.status)) {
                const response = res.data.response;
                const entityData = getEntityDataFromResponse(entityType, response)[0];
                entityData["rescan"] = true;
                entityData["scanTime"] = new Date();
                if (!entityData?.sync) {
                    const modalStatePayload = {
                        ...INITIAL_STATE.rescanModalState,
                        show: true,
                        message: "Manifest already scanned, Do you want to rescan it?",
                        payload: payload
                    }
                    dispatch(openReScanModalState(modalStatePayload));
                    soundNotificationService.playWarning();
                } else {
                    dispatch(fetchEntityLabelPrintingDataSuccess(entityData));
                    soundNotificationService.playSuccess();
                }
            } else {
                dispatch(fetchEntityLabelPrintingDataFailure(res?.data?.response || "Scan Unsuccessful"));
                dispatch(clearFetchLabelPrintingDataFailureAlert());
                soundNotificationService.playGeneralWarning();
            }
        })
        .catch(err => {
            dispatch(fetchEntityLabelPrintingDataFailure(err));
            dispatch(clearFetchLabelPrintingDataFailureAlert());
        });
}

const fetchEntityLabelPrintingDataBegin = () => {
    return {
        type: ACTION_TYPES.FETCH_LABEL_PRINTING_DATA_BEGIN
    };
};

const fetchEntityLabelPrintingDataSuccess = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_LABEL_PRINTING_DATA_SUCCESS,
        payload
    };
};

const fetchEntityLabelPrintingDataFailure = (error) => {
    return {
        type: ACTION_TYPES.FETCH_LABEL_PRINTING_DATA_FAILURE,
        payload: error
    };
};


export const fetchAndPrintSingleStickerDispatcher = (entityType, waybillNo) => async dispatch => {
    dispatch(printSingleAWBStickerBegin());
    let reverseFlowTypePrintLabel = { print: false,  valmoXUtilMapping: false};
    await inventoryPrintService.printSingleAWBSticker(waybillNo)
        .then(res => {
            if (checkHttpStatus(res?.data?.status)) {
                const response = res.data.response;
                const entityData = getEntityDataFromResponse(entityType, response);
                entityData["rescan"] = true;
                entityData["scanTime"] = new Date();
                const labelPrintingPDFUrl = res.data.response.labelPrintingPDFUrl;
                entityData["labelPrintingPDFUrl"] = labelPrintingPDFUrl;
                if (!response?.sync) {
                    const modalStatePayload = {
                        ...INITIAL_STATE.rescanModalState,
                        show: true,
                        message: "Shipment already scanned, Do you want to rescan it?",
                        payload: {
                            waybillNo,
                            labelPrintingPDFUrl,
                        }
                    }
                    dispatch(openReScanModalState(modalStatePayload));
                    soundNotificationService.playWarning();
                } else {
                    if (response?.flowType == "REVERSE") {
                        reverseFlowTypePrintLabel = {
                            print: true,
                            returnCode: response?.returnCode,
                            waybillNo: response?.waybillNo,
                            orderId: response?.waybillNo,
                            packetId: response?.packetId,
                            shipperAddress: response?.shipperAddress
                        }
                    } else if (response?.valmoXUtilMapping == true) {
                        reverseFlowTypePrintLabel = {
                            print: true,
                            valmoXUtilMapping: true,
                            returnCode: response?.returnCode,
                            waybillNo: response?.waybillNo,
                            clientWaybillNo: response?.clientWaybillNo,
                            valmoClientWaybillDetailsBO: response?.valmoClientWaybillDetailsBO,
                            clientWaybillNo: response?.clientWaybillNo,
                            orderId: response?.waybillNo,
                            packetId: response?.packetId,
                            shipperAddress: response?.shipperAddress
                        }
                    } else {
                        downloadFile(labelPrintingPDFUrl, "_blank");
                    }
                    dispatch(printSingleAWBStickerSuccess(entityData));
                    soundNotificationService.playSuccess();
                }
            } else {
                dispatch(printSingleAWBStickerFailure(res?.data?.response || "Scan Unsuccessful"));
                soundNotificationService.playGeneralWarning();
            }
        });

    return reverseFlowTypePrintLabel

};
const printSingleAWBStickerBegin = () => {
    return {
        type: ACTION_TYPES.PRINT_SINGLE_AWB_STICKER_BEGIN,
    };
};
const printSingleAWBStickerSuccess = (payload) => {
    return {
        type: ACTION_TYPES.PRINT_SINGLE_AWB_STICKER_SUCCESS,
        payload: payload
    };
};
const printSingleAWBStickerFailure = (error) => {
    return {
        type: ACTION_TYPES.PRINT_SINGLE_AWB_STICKER_FAILURE,
        payload: error
    };
};
const clearFetchLabelPrintingDataFailureAlert = () => {
    return {
        type: ACTION_TYPES.CLEAR_FETCH_PRINT_DATA_ALERT
    };
};

export const clearCurrentScannedEntityInfo = _ => dispatch => {
    dispatch({
        type: ACTION_TYPES.CLEAR_CURRENT_SCANNED_ENTITY
    });
}

const openReScanModalState = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_RESCAN_MODAL_STATE,
        payload
    };
};

export const closeRescanModal = () => dispatch => {
    dispatch(resetRescanModalState());
};

const resetRescanModalState = () => {
    return {
        type: ACTION_TYPES.CLEAR_RESCAN_MODAL_STATE
    };
};

export const toggleLoader = (payload) => dispatch => {
    dispatch(
        {
            type: ACTION_TYPES.TOGGLE_LOADER,
            payload: payload
        }
    )
}