const schema = {
    "type"       : "object",
    "required"   : ["uploadType", "s3path"],
    "properties" : {
        "uploadType" : {
            "type"       : "string",
            "title"      : "Assign Type",
            "enum"       : [ "SHIPPER", "CONSIGNMENT" ],
            "enumNames"  : [ "Seller", "Shipment" ],
            "default"    : "CONSIGNMENT"
        }
    },
    "dependencies"   : {
        "uploadType" : {
            "oneOf"  : [{
                "properties"     : {
                    "uploadType" : {
                        "enum"   : [ "SHIPPER" ]
                    },
                    "s3path"     : {
                        "type"   : "string",
                        "title"  : "Upload Seller Level Pickup File"
                    }
                },
                "required"  : [ "s3path" ]
            },
            {
                "properties"    : {
                  "uploadType"  : {
                    "enum"      : [ "CONSIGNMENT" ]
                  },
                  "s3path"      : {
                    "type"      : "string",
                    "title"     : "Upload Shipment Level Pickup File"
                  }
                },
                "required" : [ "s3path" ]
            }]
        }
    }
}

const uiSchema = {
    "classNames": "hide-title",
    "uploadType" : {
        "ui:widget"  : "radio",
        "classNames" : "flex-2-input"
    },
    "s3path" : {
        "ui:widget"  : "file",
        "classNames" : "flex-2-input"
    }
}

const errorList = {
    s3path : {
        required : "Upload a file to assign pickup!"
    }
}

export default { schema, uiSchema, errorList };