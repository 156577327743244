import React from "react";
import {
    Modal,
    Button,
    Alert,
    Icon,
} from "antd";
import { reportsDownload } from "../../../common/utils/utils";
import { DownloadTags } from "../../../common/utils/constants";

const BulkTrackingPopUp = ({
    popUpPayload,
    onClose,
    triggeredFor
}) => {
    const { show, currentCount, treshold, s3Url, title } = popUpPayload;
    const handleDownloadReport = () => {
        let queryParams = ""; //passing the page title
        if(title){
            queryParams = `&title=${title.replace(" ", "+")}`;
        }
        reportsDownload(s3Url, DownloadTags.s3, queryParams);
        onClose();
    };
    const handleClose = () => {
        onClose();
    }
    return(
        <Modal
            title={"Download Confirmation"}
            visible={show}
            onCancel={handleClose}
            destroyOnClose
            width={550}
            footer={[
                <Button block type="primary" onClick={handleDownloadReport} icon="download">
                Download Report
                </Button>
            ]}
        >
            <div className="flex-box flex-column flex-gap-l justify-content-center">
                <Alert type="error"
                    message={
                        <div style={{textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Icon type="info-circle" /> <div style={{paddingLeft: "0.5rem"}}>You are trying to track more than <span style={{color: "red", fontWeight: "bold"}}>{treshold}</span></div>
                            { triggeredFor == "waybills" && <> shipments! </> }
                            { triggeredFor == "manifests" && <> manifests! </> }
                        </div>
                    }/>
                <p style={{textAlign: "center"}}>Please download the report directly by clicking on "Download Report" button.</p>
                <div className="flex-box justify-content-center"
                    style={{
                        backgroundColor: "#F1F2F6",
                        borderRadius: "6px",
                        padding: "0.5rem",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "40%",
                        fontWeight: "bold"
                    }}>
                    <span>
                        Selected 
                        { triggeredFor == "waybills" && <> Shipments: </> }
                        { triggeredFor == "manifests" && <> Manifests: </> }
                        <span style={{color: "blue"}}>{currentCount}</span>
                    </span>
                </div>
            </div>
      </Modal>
    )
};

export default BulkTrackingPopUp;