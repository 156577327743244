import { Button, Input, Icon, Tooltip, DatePicker } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import AppliedFilters from "../../common/applied-filters";
import SelectFilters from "../../common/select-filters";

export default function PickupTableMenu({
    assignSuccess,
    handleFilterChange,
    filterPayload,
    filteredLeadData,
    openAssignFEModal,
    onWaybillSearch,
    selectedLength,
    downloadLoading,
    downloadAllLeadInfo,
    resetFilters,
    feList,
    filterNotApplied
}) {

    const config = localStorage._configrations ? JSON.parse(atob(localStorage._configrations)) : {};

    const leadStatusOptions = [
            {
                value: "-1",
                key: "-1",
                label: "All Status"
            },
            {
                value: "OFP",
                key: "OFP",
                label: "Assigned"
            },
            {
                value: "PSUCC",
                key: "PSUCC",
                label: "Picked"
            },
            {
                value: "PCANC",
                key: "PCANC",
                label: "Not Picked"
            },
            {
                value: "PPEND",
                key: "PPEND",
                label: "Unassigned"
            }
        ];

    const [waybillInput, SetWaybillInput] = useState("");
    const [filterOptions, SetFilterOptions] = useState({ createdAt: filterPayload?.createdAt });
    const [selectedStartDate, SetSelectedStartDate] = useState(undefined);

    useEffect(() => {
        if (assignSuccess === true) {
            SetFilterOptions({
                createdAt: {
                    from: moment().subtract(7, "days").startOf("day").valueOf(),
                    to: moment().endOf("day").valueOf()
                }
            });
            SetWaybillInput("");
        }
    }, [assignSuccess])

    var filtersForAssignApplied = filterPayload?.leadStatus?.length === 1 && filterPayload?.leadStatus[0] === "UNASSIGNED"
    var filtersForReassignApplied = filterPayload?.leadStatus?.length > 0 && filterPayload?.leadStatus?.every(status => ["OPEN", "PICKUP_FAILED"].indexOf(status) > -1)

        filtersForAssignApplied = filterPayload?.leadStatus?.length === 1 && filterPayload?.leadStatus[0] === "PPEND";
        filtersForReassignApplied = filterPayload?.leadStatus?.length > 0 && filterPayload?.leadStatus?.every(status => ["OFP", "PCANC"].indexOf(status) > -1);

    var disableFEAssignment = (!selectedLength || (!filtersForAssignApplied && !filtersForReassignApplied))
    var disabledFEAssignmentTitle = !filtersForAssignApplied && !filtersForReassignApplied ?
        <>
            Apply only <strong>'Unassigned'</strong> (or) <strong>'Assigned'/'Not Picked'</strong> status filter to Assign (or) Reassign FE!
        </> : !selectedLength ? "Select rows to Assign FE!" : undefined

    const onFilterChange = (key, value) => {
        var selectedVals = value?.map(val => val.key || val.value);
        if (key == "leadStatus" && selectedVals?.indexOf("-1") > -1) {
            selectedVals = [];
            value = [];
        }
        if (key === "createdAt") {
            selectedVals = {
                from: value?.[0]?.valueOf(),
                to: value?.[1]?.valueOf()
            }
            value = selectedVals;
        }
        else {
            value = value?.map(val => {
                return {
                    ...val,
                    label: typeof val?.label === "string" || val?.label?.props?.children?.[1]
                }
            });
        }
        SetFilterOptions({
            ...filterOptions,
            [key]: value
        }),
            handleFilterChange(key, selectedVals)
    }

    const onSearch = _ => {
        SetFilterOptions(current => {
            return {
                ...current,
                waybills: waybillInput.trim()
            }
        });
        onWaybillSearch(waybillInput.trim());
    }

    const callResetFilters = _ => {
        SetFilterOptions({
            createdAt: {
                from: moment().subtract(7, "days").startOf("day").valueOf(),
                to: moment().endOf("day").valueOf()
            }
        });
        SetWaybillInput("");
        resetFilters();
    }

    const onCalendarChange = dates => {
        if (dates.length > 1) {
            SetSelectedStartDate(undefined);
            return;
        }
        SetSelectedStartDate(dates[0]);
    }

    const disableDate = date => {
        if (date.valueOf() > moment().endOf("day").valueOf()) return true;
        if (selectedStartDate === undefined) return false;
        const maxEndDate = moment(selectedStartDate).startOf("day").add(7, "days").endOf("day");
        if ((date.valueOf() >= maxEndDate.valueOf()) || date.valueOf() < moment(selectedStartDate).startOf("day").valueOf()) {
            return true;
        }
        return false;
    }

    return (
        <div className="flex-column flex-gap-l flex-1 align-items-stretch">
            <div style={{ backgroundColor: "#EEF3FB" }}>
                <div className="align-items-center flex-box flex-gap-m flex-wrap spacer-m">
                    <DatePicker.RangePicker style={{ width: 275 }} className="pickup-date-filter font-size-m-1 border-radius-m" onChange={value => {
                        if (!value || !value?.[0] || !value?.[1]) {
                            onFilterChange("createdAt", [moment().subtract(7, "days").startOf("day").valueOf(), moment().endOf("day").valueOf()])
                        }
                        else {
                            onFilterChange("createdAt", value)
                        }
                    }} separator={"to"} showTime={true} format="DD/MM/YYYY HH:mm"
                        onCalendarChange={onCalendarChange} disabledDate={disableDate}
                        value={[moment(filterOptions?.createdAt?.from), moment(filterOptions?.createdAt?.to)]} />

                    <SelectFilters onChange={onFilterChange} options={filteredLeadData?.customers}
                        placeholder={"Search Customer"}
                        selectedOptions={filterOptions?.customerIds} filterKey={"customerIds"} />

                            <SelectFilters onChange={onFilterChange}
                                options={filteredLeadData?.pincode && filteredLeadData?.pincode?.length > 0 ?
                                    filteredLeadData?.pincode.map(p => ({
                                        id: p.id,
                                        label: p.zipcode
                                    })) : []}
                                placeholder={"Search Pincodes"} selectedOptions={filterOptions?.pincodeIds} filterKey={"pincodeIds"} />

                    <SelectFilters onChange={onFilterChange} placeholder={"Search Shipper"}
                        options={filteredLeadData?.shippers && filteredLeadData?.shippers?.length > 0 ?
                            filteredLeadData?.shippers.map(p => ({
                                id: p.name,
                                label: p.name
                            })) : []}
                        selectedOptions={filterOptions?.shippers} filterKey={"shippers"} />


                    <SelectFilters onChange={onFilterChange} placeholder={"Search FE"}
                        options={feList && feList?.length > 0 ?
                            feList?.map(fe => ({
                                id: fe.id,
                                label: <div className="flex-column">
                                    <div> {fe.name} </div>
                                    <div className="font-size-sm-2"> {fe.contactNumber} </div>
                                </div>,
                                searchText: fe.name + " " + fe.contactNumber
                            })) : []}
                        selectedOptions={filterOptions?.assetIds} filterKey={"assetIds"} />

                    <SelectFilters onChange={onFilterChange} options={leadStatusOptions}
                        filterKey={"leadStatus"} placeholder={"Search Status"}
                        selectedOptions={filterOptions?.leadStatus} />

                    <Button onClick={callResetFilters} className="lsn-btn-error" style={{ padding: "0 25px" }} >
                        Clear All Filters
                    </Button>

                    <AppliedFilters showLabel={true} filteredOptions={{
                        ...filterOptions,
                        waybills: filterPayload?.waybills && filterPayload?.waybills?.length > 0 ? filterPayload?.waybills : false,
                        assetIds: filterOptions?.assetIds && filterOptions?.assetIds?.length > 0 ?
                            filterOptions?.assetIds.map(asset => {
                                var labelArray = asset?.label?.props?.label.split(" ");
                                return {
                                    ...asset,
                                    label: labelArray.splice(0, labelArray.length - 1)?.join(" ")
                                };
                            }) :
                            []
                    }} maxCount={5} keyToText={{
                        "waybills": "Waybills",
                        "leadStatus": "Status",
                        "shippers": "Shippers",
                        "customerIds": "Customers",
                        "pincodeIds": "Pincodes",
                        "assetIds": "FEs"
                    }} />
                </div>
            </div>

            <div className="flex-box justify-content-space-between spacer-hm flex-wrap flex-gap-l">
                <div className="flex-3 flex-box flex-gap-l align-items-center" style={{ minWidth: 300 }}>
                    <Input.TextArea className="border-radius-m flex-1 scan-awb-box"
                        placeholder="Search or Scan AWB(s)" autoSize={false}
                        value={waybillInput} style={{ height: 35, resize: 'none' }}
                        onChange={e => {
                            if (waybillInput.trim() !== "" && e.currentTarget.value.trim() === "") {
                                onWaybillSearch("");
                            }
                            SetWaybillInput(e.currentTarget.value)
                        }} />
                    <Button onClick={onSearch} className="lsn-btn-primary" type="primary" icon="search" > Search </Button>
                    <div style={{ padding: "8px 16px" }}
                        className="border-radius-m flex-box flex-gap-m align-items-center" >
                        <div className="info-color text-bold"> {selectedLength ? selectedLength : 0} </div>
                        AWB(s) selected
                    </div>
                </div>
                <div className="flex-2 flex-box flex-gap-l align-items-center justify-content-flex-end">
                    <Button disabled={filterNotApplied} onClick={downloadAllLeadInfo} loading={downloadLoading} className="lsn-btn-secondary">
                        {downloadLoading ? "Exporting" : "Export"}
                        {!downloadLoading && <Icon type="download" />}
                    </Button>
                    <Tooltip title={disabledFEAssignmentTitle}>
                        <Button onClick={openAssignFEModal} icon="plus" className="lsn-btn-primary" type="primary" disabled={disableFEAssignment} >
                            {filtersForReassignApplied && selectedLength ? "Reassign FE" : "Assign FE"}
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}