import React from 'react';
import { Checkbox, DatePicker, Divider, Icon, Input, Radio, Select, Switch, TimePicker, Tooltip } from "antd";
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import GooglePlaceAutoComplete from '../../ui/google-place-autocomplete/google.place-autocomplete';
import { checkForDuplicateErrors, schemaToAttrs } from './form.utils';
import FileUpload from '../file-upload/file.upload';
import widgets from './widgets';
import reportWidgets from './reportWidgets';

const FormWidgets = {

    CheckboxesWidget : props => {
        const uiAttrs = schemaToAttrs(props.uiSchema);
        if (uiAttrs.className) {
            uiAttrs.className += " input-wrapper";
        }
        else {
            uiAttrs.className = "input-wrapper"
        }
        return (
            <>
                <Checkbox.Group options={props.options.enumOptions}
                    onChange={e => {
                        props.onChange(e)
                    }}
                    disabled={props.disabled || props.readonly} value={props.value} {...uiAttrs} />
                    
                {
                    props.rawErrors?.length > 0 &&
                    <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
                }
            </>
        )
    },

    CheckboxWidget          : props => {
        const uiAttrs = schemaToAttrs(props.uiSchema);
        return (
            <div>
                <Checkbox.Group className={props.value ? "checked" : "unchecked"}
                    onChange={e => {
                        props.onChange(e)
                    }}
                    disabled={props.disabled || props.readonly} value={props.value} {...uiAttrs}>
                    {props.schema.title}
                </Checkbox.Group>
                {
                    props.rawErrors?.length > 0 &&
                    <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
                }
            </div>
        )
    },

    SwitchWidget            : props => {
        const uiAttrs = schemaToAttrs(props.uiSchema);
        const value = props.value?.toString() === 'true';

        return (
            <div className="flex-1">
                <Switch
                    onChange={value => props.onChange(value.toString() === 'true')}
                    disabled={props.disabled || props.readonly} checked={value} {...uiAttrs}>
                    {props.schema.title}
                </Switch>

                {
                    props.rawErrors?.length > 0 &&
                    <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
                }
            </div>
        )
    },

    RadioWidget             : props => {
        const uiAttrs = schemaToAttrs(props.uiSchema);
        return (
            <>
                {
                    <Radio.Group onChange={e => props.onChange(e.target.value)} value={props.value} disabled={props.disabled}
                        {...uiAttrs}>
                        {
                            props.options.enumOptions.map(option => {
                                return (
                                    <Radio value={option.value} checked={option.value == props.value}
                                        key={option.value} className={props.readonly ? "readonly-radio" : ""}>
                                        {option.label}
                                    </Radio>
                                )
                            })
                        }
                    </Radio.Group>
                }
                {
                    props.rawErrors?.length > 0 &&
                    <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
                }
            </>
        )
    },

    SelectWidget            : props => {
        const uiAttrs = schemaToAttrs(props.uiSchema, props.readonly);
        const mode = props.schema.multiple ? "multiple" : "default";

        if ( !(Array.isArray(props.value) && props.schema.multiple) ) { 
            const option = props.options.enumOptions?.length > 0 && props.options.enumOptions.find(opt => opt.value === props.value);
        }
        return (
            <div className={"input-wrapper flex-column ant-select-wrapper" + (props.schema.enableAddOption && typeof props.schema.onAddClick === "function" ? " add-option" : "")}>
                <Select allowClear onChange={value => {
                    var temp = value;
                    if (props.schema.expandOnHover) {
                        temp ={
                            key   : value.key,
                            label : value.label.props.children
                        }
                    }
                    props.onChange(temp)
                }} showSearch={props.schema.searchEnabled}
                    filterOption={props.schema.searchEnabled ? filterFunciton() : undefined}
                    value={!Array.isArray(props.value) && props.schema.multiple ? [] : props.value}
                    disabled={props.disabled || props.readonly} className={uiAttrs.className}
                    {...uiAttrs} mode={mode} showArrow={true}
                    dropdownRender={menu => (
                        props.schema.dropDownFooter && props.schema.dropDownFooterAction ?
                        <div>
                            {menu}
                            <Divider style={{margin : 0}}/>
                            <div onMouseDown={props.schema.dropDownFooterAction} className='spacer-xs cursor-pointer'>
                                <props.schema.dropDownFooter/>
                            </div>
                        </div> :
                        menu
                      )}
                >
                    {
                        props.options.enumOptions?.length > 0 &&
                        props.options.enumOptions?.map(option => {
                            return (
                                <Select.Option value={option.value} key={option.value}>
                                    {
                                        props.schema.expandOnHover ?
                                        <Tooltip title={option.label}>
                                            {option.label}
                                        </Tooltip> :
                                        option.label 
                                    }
                                </Select.Option>
                            )
                        })
                    }
                </Select>

                {
                    props.rawErrors?.length > 0 &&
                    <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
                }
                {
                    props.schema.enableAddOption && typeof props.schema.onAddClick === "function" && !props.readonly &&
                    <div className="option-add-button">
                        <Icon className="font-size-l-1" type="plus-circle" theme="twoTone" twoToneColor="#50b154" onClick={props.schema.onAddClick}/>
                    </div>
                }
            </div>
        )

        function filterFunciton() {
            if (typeof props.schema.filterFunciton == "function") {
                return (input, option) => props.schema.filterFunciton(input, option);
            }
            else if (props.schema.expandOnHover) {
                return (input, option) => option.props.children.props.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
            }
            else {
                return (input, option) => option.props.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
            }
        }
    },

    TextWidget              : props => {
        const uiAttrs = schemaToAttrs(props.uiSchema);
        var { showValueOnHover, maxLen } = uiAttrs;
        delete uiAttrs.showValueOnHover;
        if (uiAttrs.type === "number") {
            delete uiAttrs.maxLen;
        }
        return (
            <div className="input-wrapper">
                <Tooltip title={showValueOnHover ? props.value : undefined}>
                    <Input 
                        value={ props.value ? props.value : ( props.readonly ? "--" : undefined ) }
                        onKeyDown={event => {
                            if (uiAttrs.type === "number" && event.key === "e" || event.key === "+" || event.key === "-") {
                                event.preventDefault();
                            }
                        }}
                        onWheel={e => e.target?.blur()}
                        onChange = { e => {
                            if (uiAttrs.type === "number" && typeof maxLen === "number" && e.target.value?.length > maxLen) {
                                e.target.value = e.target.value.substr(0, maxLen)
                            }
                            props.onChange(e.target.value === "" ? undefined : e.target.value) 
                        }}
                        onBlur   = { _ => props.onBlur(props.id, props.value) } onFocus={props.onFocus}
                        disabled = { props.disabled } readOnly={props.readonly} {...uiAttrs} />
                </Tooltip>
                {
                    props.rawErrors?.length > 0 &&
                    <div className="error-text">
                        {checkForDuplicateErrors(props.rawErrors).join(" ")}
                    </div>
                }
            </div>
        )
    },

    TextareaWidget          : props => {
        const uiAttrs = schemaToAttrs(props.uiSchema);
        if (uiAttrs.resize === false) {
            uiAttrs.className = uiAttrs.className ? uiAttrs.className + " no-resize" : "no-resize";
            delete uiAttrs.resize;
        } 
        return (
            <div className="input-wrapper">
                <TextArea value={props.value ? props.value : (props.readonly ? "--" : undefined)}
                    onChange={e => props.onChange(e.target.value === "" ? undefined : e.target.value)}
                    disabled={props.disabled} readOnly={props.readonly} {...uiAttrs} />
                {
                    props.rawErrors?.length > 0 &&
                    <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
                }
            </div>
        )
    },

    DateWidget              : props => {
        const uiAttrs = schemaToAttrs(props.uiSchema);
        uiAttrs.className = uiAttrs.className ? uiAttrs.className + " ant-input" : "ant-input";
        if (props.readonly && !uiAttrs?.disabled) {
            uiAttrs.className += " ant-date-input-readonly";
        }
        return (
            <div className="input-wrapper">
                <DatePicker value={props.value ? moment(props.value) : undefined}
                    onChange={value => props.onChange(value ? moment(value).valueOf() : undefined)}
                    disabled={props.disabled || props.readonly} {...uiAttrs} />
                {
                    props.rawErrors?.length > 0 &&
                    <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
                }
            </div>
        )
    },

    TimePickerWidget        : props => {
        const format = props?.format ? props?.format : 'HH:mm';
        const uiAttrs = schemaToAttrs(props.uiSchema);
        uiAttrs.className = uiAttrs.className ? uiAttrs.className + " ant-input" : "ant-input";
        if (props.readonly) {
            uiAttrs.className += " ant-date-input-readonly";
        }
        var value = undefined;
        if (props?.value !== undefined && props?.value !== null) {
            value = moment().startOf("day").add("seconds", props?.value);
        }
        return (
            <div className="input-wrapper">
                <TimePicker
                    disabled={props.disabled || props.readonly}
                    value={value ? moment(value, format) : value}
                    onChange={value => {
                        if (value) {
                            var timeInSecs = moment(value).startOf("minute").diff(moment().startOf("day"), "seconds") % 86400;
                            props.onChange(timeInSecs)
                        }
                        else {
                            props.onChange(undefined);
                        }

                    }}
                    addon={props?.addon ? props?.addon : undefined}
                    style={{ width: '100%' }}
                    format={format}
                    {...uiAttrs}
                />
                {
                    props.rawErrors?.length > 0 &&
                    <div className="error-text">
                        {checkForDuplicateErrors(props.rawErrors).join(" ")}
                    </div>
                }

            </div>
        )
    },

    DurationWidget          : props => {
        const format = props?.format ? props?.format : 'HH:mm';
        var [days, time] = props?.value?.indexOf(",") > -1 ? props?.value?.split(",") : ["", ""];
        const uiAttrs = schemaToAttrs(props.uiSchema);
        const timeplaceholder = props.readonly ? "--" : uiAttrs?.timeplaceholder;
        const daysplaceholder = props.readonly ? "--" : uiAttrs?.daysplaceholder;
        delete uiAttrs.placeholder
        uiAttrs.className = uiAttrs.className ? uiAttrs.className + " ant-input flex-1" : "ant-input flex-1";
        if (props.readonly) {
            uiAttrs.className += " ant-date-input-readonly";
        }
        if (time) {
            time = moment().startOf("day").add("seconds", time);
        }
        const onTimeChange = value => {
            const timeInLong = value ? moment(value).startOf("minute").diff(moment().startOf("day"), "seconds") : "";
            if (days === "" && timeInLong === "") {
                props.onChange(undefined);
            }
            else {
                props.onChange(days + "," + timeInLong);
            }
        }
        const onDaysChange = e => {
            if (e.target.value === "" && time === "") {
                props.onChange(undefined);
            }
            else {
                props.onChange(e.target.value + "," + (time ? moment(time).startOf("minute").diff(moment().startOf("day"), "seconds") : ""))
            }
        }
        return (
            <div className="input-wrapper">
                <div className="flex-box flex-gap-l">
                    <Input value={days ? days : undefined} type={"number"}
                        onChange={onDaysChange} placeholder={daysplaceholder}
                        disabled={props.disabled} readOnly={props.readonly} {...uiAttrs} />
                    <TimePicker
                        format={format}
                        value={time ? moment(time, format) : undefined}
                        disabled={props.disabled || props.readonly}
                        onChange={onTimeChange}
                        addon={props?.addon ? props?.addon : undefined}
                        placeholder={timeplaceholder}
                        {...uiAttrs}
                    />
                </div>
                {
                    props.rawErrors?.length > 0 &&
                    <div className="error-text">
                        {checkForDuplicateErrors(props.rawErrors).join(" ")}
                    </div>
                }
            </div>
        )
    },

    GoogleAutoFillAddress   : props => {

        const uiAttrs = schemaToAttrs(props.uiSchema);

        return (
            <div className="input-wrapper">
                <GooglePlaceAutoComplete
                    onBlur={(value) => props.onBlur(props.id, value)}
                    selectedAddress={props.value}
                    location="locationSet"
                    disabled={props.disabled || props.readonly}
                    {...uiAttrs}
                ></GooglePlaceAutoComplete>
                {
                    props.rawErrors?.length > 0 &&
                    <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
                }
            </div>
        )
    },

    DateTimeWidget          : props => {
        const uiAttrs = schemaToAttrs(props.uiSchema);
        uiAttrs.className = uiAttrs.className ? uiAttrs.className + " ant-input" : "ant-input";
        if (props.readonly && !uiAttrs?.disabled) {
            uiAttrs.className += " ant-date-input-readonly";
        }
        return (
            <div className="input-wrapper">
                <DatePicker value={props.value ? moment(props.value) : undefined}
                    onChange={value => props.onChange(value ? moment(value).valueOf() : undefined)}
                    disabled={props.disabled || props.readonly} {...uiAttrs} />
                {
                    props.rawErrors?.length > 0 &&
                    <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
                }
            </div>
        )

    },

    FileWidget: props => {

        const uiAttrs = schemaToAttrs(props.uiSchema);

        const onUploadFinish = (filePath, fileName) => {
            if (typeof props.schema.onUploadFinish === "function") {
                props.schema.onUploadFinish(filePath, fileName);
            }
            props.onChange(filePath)
        }

        var fileUploadProps = {
            path         : props.schema.path,
            objKey       : props.schema.objKey,
            showLoader   : true,
            directUpload : props.schema.directUpload,
            fileUploadType       : props.schema.uploadButtontype,
            disableUploadButton  : props.schema.disableUploadButton,
            clearFileAfterUpload : false
        }
        if (props.schema.fileUploadProps) {
            fileUploadProps = props.schema.fileUploadProps;
        }

        return (
            <div className="input-wrapper file-input">
                {
                    props.schema.downloadSampleLink ? 
                    <div className="flex-box flex-gap-l align-items-center">
                        <FileUpload
                            onUploadFinish={onUploadFinish}
                            handleRemove={_ => onUploadFinish(undefined)}
                            {...fileUploadProps}
                            {...uiAttrs}
                        />
                        <a href={props.schema.downloadSampleLink} className="flex-box flex-gap-m align-items-center">
                            <Icon type="download"/>
                            <div> {props.schema.downloadSampleText || "Download Sample"} </div>
                        </a>
                    </div> : 
                    <FileUpload
                        onUploadFinish={onUploadFinish}
                        handleRemove={_ => onUploadFinish(undefined)}
                        {...fileUploadProps}
                        {...uiAttrs}
                    />
                }
                {
                    props.rawErrors?.length > 0 &&
                    <div className="error-text">
                        {checkForDuplicateErrors(props.rawErrors).join(" ")}
                    </div>
                }
            </div>
        )
    },

    ...widgets,
    ...reportWidgets
}

export default FormWidgets;