import { checkHttpStatus } from "../../../common/utils/utils";
import ScanService from "../../../services/inbound/scan.service";
import * as _Constants from './constants';

const scanService = new ScanService

export const fetchConsigmentUnassigned = (payload, isReset) => dispatch => {
    dispatch(fetchConsigmnetsBegin());
    scanService.fetchConsigmentUnassigned(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchConsigmnetsSuccess(res ?.data ?.response || res ?.data ?.viewResponse, isReset));
        } else {
            dispatch(fetchConsigmnetsFailure(res ?.data ?.response));
        }
    });
};

function fetchConsigmnetsBegin() {
    return {
        type: _Constants.FETCH_CONSIGMENTS_BEGIN
    };
}

function fetchConsigmnetsSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_CONSIGMENTS_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchConsigmnetsFailure(error) {
    return {
        type: _Constants.FETCH_CONSIGMENTS_FAILURE,
        payload: error
    };
}

export const getConsignment = (id) => dispatch => {
    dispatch(fetchConsigmnetBegin());
    scanService.getConsignment(id).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchConsigmnetSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchConsigmnetFailure(res ?.data ?.response));
        }
    });
};

function fetchConsigmnetBegin() {
    return {
        type: _Constants.FETCH_CONSIGMENT_BEGIN
    };
}

function fetchConsigmnetSuccess(payload) {
    return {
        type: _Constants.FETCH_CONSIGMENT_SUCCESS,
        payload: payload,
    };
}

function fetchConsigmnetFailure(error) {
    return {
        type: _Constants.FETCH_CONSIGMENT_FAILURE,
        payload: error
    };
}

export const updateConsignment = (payload) => dispatch => {
    dispatch(updateConsigmnetBegin());
    scanService.consignmentUpdate(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(updateConsigmnetSuccess(res ?.data ?.response));
        } else {
            dispatch(updateConsigmnetFailure(res ?.data ?.response));
        }
    });
};

function updateConsigmnetBegin() {
    return {
        type: _Constants.UPDATE_CONSIGMENT_BEGIN
    };
}

function updateConsigmnetSuccess(payload) {
    return {
        type: _Constants.UPDATE_CONSIGMENT_SUCCESS,
        payload: payload,
    };
}

function updateConsigmnetFailure(error) {
    return {
        type: _Constants.UPDATE_CONSIGMENT_FAILURE,
        payload: error
    };
}





export const getManifest = (payload) => dispatch => {
    dispatch(getManifestBegin());
    scanService.getManifest(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(getManifestSuccess(res ?.data ?.response));
        } else {
            dispatch(getManifestFailure(res ?.data ?.response));
        }
    });
};

function getManifestBegin() {
    return {
        type: _Constants.FETCH_INVENTORY_MANIFEST_BEGIN
    };
}

function getManifestSuccess(payload) {
    return {
        type: _Constants.FETCH_INVENTORY_MANIFEST_SUCCESS,
        payload: payload,
    };
}

function getManifestFailure(error) {
    return {
        type: _Constants.FETCH_INVENTORY_MANIFEST_FAILURE,
        payload: error
    };
}