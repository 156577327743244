import moment from "moment";
import TripsView from "./trips.view";
import { useState } from "react";

export default function OutboundTripsV2() {

    const [ loading, SetLoading ] = useState(true);

    const outboundColumns = [
        {
            title: "Created Time",
            dataIndex: "tripCreatedTime",
            key: "tripCreatedTime",
            render: (data) => data ? moment(data).format("YYYY-MM-DD hh:mm A") : <>--</>
        },
        {
            title: "Dispatched Time",
            dataIndex: "tripDispatchTime",
            key: "tripDispatchTime",
            render: (data) => data ? moment(data).format("YYYY-MM-DD hh:mm A") : <>--</>
        },
        {
            title: "Connections",
            dataIndex: "connectionCount",
            key: "connectionCount"
        },
        {
            title: "Manifests",
            dataIndex: "manifestCount",
            key: "manifestCount"
        },
        {
            title: "Shipments",
            dataIndex: "shipmentCount",
            key: "shipmentCount",
            render: (data) => {
                if (data !== undefined) {
                    return <>{data}</>
                }
                else {
                    return (
                        <div className="flex-box align-items-center justify-content-center">
                            <div className="spinning-loader"></div>
                        </div>
                    )
                }
            }
        }
    ]

    return (
        <TripsView tripFetchType={"OUTBOUND_TRIP"} columns={outboundColumns}
         loading={loading} SetLoading={SetLoading} />
    )
}