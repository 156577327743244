export const ACTION_TYPES = {
    CACHE_USER_LOCATION_MAPPING : "CACHE_USER_LOCATION_MAPPING"
}

export const UserLocationMappingReducer = (state = { userLocationMapping : undefined } , action = {}) => {

    switch (action.type) {
        case ACTION_TYPES.CACHE_USER_LOCATION_MAPPING : return {
            ...state,
            userLocationMapping : action.payload
        }
        default : return state;
    }
}
