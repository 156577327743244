import * as CONSTANTS from './settingConstants';
import * as apiUtils from '../../common/utils/apiGateway';
import { notifyApiError } from '../../common/utils/utils';

export const getStateDetails = (param) => dispatch => {
    dispatch(getStateDetailsBegin);
    apiUtils.apiRequest({
        method: 'get',
        url: '/b2b/v1/areas'
    })
        .then(response => {
            dispatch(getStateDetailsSuccess(response.data.response.stateAreas))
        })
        .catch(error => dispatch(getStateDetailsFailure(error)));
}

export const partnersFileUpload = (data) => dispatch => {
    if (!data) {
        dispatch(partnerUploadFailure(null));
        return
    }
    dispatch(partnerUploadBegin());
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2c/v1/partner/onboarding`,
        overrideHeader: { 
            token: localStorage.getItem("accessToken"),
            tokenId: localStorage.getItem("tokenId"),
            'Content-Type': 'multipart/form-data; boundary=--------------------------771675203505438998378223'
        },
        data
    })
        .then(response => {
            switch (response ?.data ?.status ?.code) {
                case 403:
                    notifyApiError(response ?.data ?.status ?.message, "ERROR:");
                    break;
                case 401:
                    notifyApiError(response ?.data ?.status ?.message, "ERROR:");
                    // window.location.href = '/';
                    break;
                case 500:
                case 503:
                    notifyApiError(response ?.data ?.status ?.message, "ERROR:");
                    break;
                case 200:
                case 201:
                case 202:
                    dispatch(partnerUploadSuccess(response.data))
            }
        })
        .catch(error => dispatch(partnerUploadFailure(error)));
}

function partnerUploadBegin() {
    return {
        type: CONSTANTS.GET_PARTNERS_ONBOARD_FILE_BEGIN,
        payload: null
    }
}

function partnerUploadSuccess(response) {
    return {
        type: CONSTANTS.GET_PARTNERS_ONBOARD_FILE_SUCCESS,
        payload: response
    }
}

function partnerUploadFailure(response) {
    return {
        type: CONSTANTS.GET_PARTNERS_ONBOARD_FILE_FAILURE,
        payload: response
    }
}

function getStateDetailsBegin() {
    return {
        type: CONSTANTS.GET_STATE_DETAILS_BEGIN,
    }
}

function getStateDetailsSuccess(response) {
    return {
        type: CONSTANTS.GET_STATE_DETAILS_SUCCESS,
        payload: response,
    }
}
function getStateDetailsFailure() {
    return {
        type: CONSTANTS.GET_STATE_DETAILS_SUCCESS,
    }
}


export const pincodeUpdate = (data) => dispatch => {
    if(!data){
        dispatch(pincodeUpdateFailure(null));
        return
    }
    dispatch(pincodeUpdateBegin);
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/updateCityPincode`,
        data
    })
        .then(response => {
            dispatch(pincodeUpdateSuccess(response.data.response))
        })
        .catch(error => dispatch(pincodeUpdateFailure(error)));
}

function pincodeUpdateBegin() {
    return {
        type: CONSTANTS.GET_PINCODE_UPDATE_BEGIN,
        payload: null
    }
}

function pincodeUpdateSuccess(response) {
    return {
        type: CONSTANTS.GET_PINCODE_UPDATE_SUCCESS,
        payload: response
    }
}

function pincodeUpdateFailure(response) {
    return {
        type: CONSTANTS.GET_PINCODE_UPDATE_FAILURE,
        payload: response
    }
}