import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { checkForDuplicateErrors, schemaToAttrs } from "../form.utils";
import { debounce } from "lodash";
import { fetchVendorOptions } from "../../../../services/common/fetch.services";

export default function VendorWidget(props) {

    const [ options, SetOptions ] = useState([{
        value   : -1,
        label   : "All"
    }]);
    const [ value, SetValue ] = useState(props.value);
    const [ loading, SetLoading ] = useState(false);
    const [ notFoundContent, SetNotFoundContent ] = useState("No vendor found!");

    const uiAttrs = schemaToAttrs(props.uiSchema, props.readonly);

    const disabled = props.disabled || props.readonly || uiAttrs.disabled;
    delete uiAttrs.disabled;

    const debounceFetchVendors = debounce(value => callVendorFetch(value), 800);

    const callVendorFetch = value => {
        if (value.trim() === "") {
            if (! (/^[a-zA-Z0-9 -_.]*$/.test(value.trim())) ) {
                notifyApiError("Invalid search phrase entered!");
                setFieldOptions([{
                    value   : -1,
                    label   : "All"
                }]);
                SetNotFoundContent("No vendor found!");
            }
        }
        const setFieldOptions = opts => {
            SetOptions(opts);
            SetValue(props.value);
            SetLoading(false);
            SetNotFoundContent("No vendor found!");
        }
        return fetchVendorOptions({
            partialContentSearch : value.trim(),
            isActive             : true
        })
        .then(vendors => {
            vendors.unshift({
                value   : -1,
                label   : "All"
            })
            setFieldOptions(vendors);
        })
        .catch(_ => {
            setFieldOptions([{
                value   : -1,
                label   : "All"
            }]);
        });
    }

    useEffect(() => {
        SetValue(props.value);
    }, [ props.value ])

    return (
        <div className={"input-wrapper flex-column"}>
            {
                loading ?
                <Input className="input-loading"/> :
                <Select 
                    onChange={value => {
                        if (value && value.length === 0) {
                            SetValue(undefined);
                            props.onChange(undefined)
                        }
                        else {
                            SetValue(value);
                            props.onChange(value)
                        }
                    }} showSearch={true} placeholder={"Select vendors"}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0}
                    value={value} disabled={disabled} className={uiAttrs.className}
                    {...uiAttrs} mode={props.schema.mode ? props.schema.mode : "multiple"}
                    notFoundContent={notFoundContent}
                    onInputKeyDown={_ => SetNotFoundContent("Searching Vendors...")}
                    onSearch={debounceFetchVendors}
                >
                    {
                        options?.length > 0 &&
                        options?.map(option => {
                            if (option.value) {
                                return (
                                    <Select.Option value={option.value} key={option.value}>
                                        {option.label}
                                    </Select.Option>
                                )
                            }
                        })
                    }
                </Select>
            }
            {
                props.rawErrors?.length > 0 &&
                <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
            }
        </div>
    )
}