import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const WaybillSparkLine = () => {

    const navigate = useNavigate();

    return(
        <div className="flex-1 flex-box flex-gap-l align-items-center justify-content-flex-end">
            <Button style={{ width : 240 }} onClick={_ => navigate("/appv2/rto/form/drs")} icon="plus" size="large" type="primary" className="lns-btn-primary">
                Create RTO DRS
            </Button>
            <Button style={{ width : 240 }} onClick={_ => navigate("/appv2/rto/form/manifest-partner")} icon="plus" size="large" type="primary" className="lns-btn-primary">
                Create RTO Manifest
            </Button>
        </div>
    )
};

export default WaybillSparkLine;