import BwipJs from "bwip-js";
import React, { useEffect } from "react";

export default function Barcode({ barCodeId, showText, value, height, width }) {

  useEffect(() => {
    const elemId = barCodeId ? barCodeId : "barcodeSVGElem";
    if (value && elemId) {
      BwipJs.toCanvas(elemId, {
        bcid   : 'code128',
        text   : value,
        scale  : width || 2,
        height : height || 16,
        includetext : showText,
        textxalign  : 'center'
      });
    }
  }, [ value ]);

  return (<canvas id={barCodeId ? barCodeId : "barcodeSVGElem"}></canvas>)
}