import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { checkForDuplicateErrors, schemaToAttrs } from "../form.utils";
import { apiRequest } from "../../../../common/utils/apiGateway"
import { Constants } from "../../../../common/utils/constants";
import { capitalizeString } from "../../../../common/utils/utils";
import { useSelector } from "react-redux";

export default function DestinationWidget(props) {

    const [ options, SetOptions ] = useState([]);
    const [ value, SetValue ] = useState(props.value);
    const [ loading, SetLoading ] = useState(true);

    const uiAttrs = schemaToAttrs(props.uiSchema, props.readonly);
    
    var partnerData = useSelector(state => state.reportsReducer.reportFormData.partners)
    var partnersChanged = useSelector(state => state.reportsReducer.partnersChanged)

    const disabled = props.disabled || props.readonly || uiAttrs.disabled;
    delete uiAttrs.disabled;


    const loadOptions = selectedPartnerIds => {
        if (selectedPartnerIds === false) {
            SetOptions([]);
            SetValue(undefined);
            return;
        }
        SetLoading(true);
        apiRequest({
            url     : `b2b/v1/partners/${Constants.PARTNER_ID}/locations/?partnerList=${selectedPartnerIds}&entityType=PARTNER&view=LITE_LOCATION_LIST&loggedInUserTokken=true`,
            method  : "GET"
        })
        .then(response => {
            var optionValues = [];
            var tempOptions = [{
                key   : -1,
                value : -1,
                label : "All"
            }];
            response?.data?.viewResponse?.viewResponse.map(location => {
                optionValues.push(location?.id);
                if (tempOptions.map(x => x.value).indexOf(location?.id) < 0) {
                    tempOptions.push({
                        key   : location?.id,
                        value : location?.id,
                        label : `${location?.name} (${capitalizeString(location?.entityType?.toLowerCase())})`
                    });
                }
            });
            SetOptions(tempOptions);
            
            var tempValues = props.value ? props.value.filter(v => optionValues?.indexOf(v.key || v.value) > -1) : [];
            if (tempValues.length) {
                SetValue(tempValues);
            }
            else {
                SetValue(undefined);
            }
            SetLoading(false);
        })
    }

    useEffect(() => {
        loadOptions(partnerData ? partnerData?.map(p => p.key || p.value).join(",") : Constants.PARTNER_ID)
    }, [])

    useEffect(() => {
        SetValue(props.value);
    }, [ props.value ])

    useEffect(() => {
        if (partnersChanged) {
            loadOptions(partnerData ? partnerData?.map(p => p.key || p.value).join(",") : false);
        }
    }, [ partnersChanged ])

    return (
        <div className={"input-wrapper flex-column"}>
            {
                loading ?
                <Input className="input-loading"/> :
                <Select labelInValue={true}
                    onChange={updatedValue => {
                        var tempValue = updatedValue;
                        var updatedValueArray = updatedValue.map(v => v.key);
                        var valueArray = value && value?.length ? value.map(v => v.key) : [];
                        if ((updatedValueArray && updatedValueArray.length === 0)) {
                            tempValue = undefined;
                        }
                        else if (valueArray?.indexOf(-1) > -1 && updatedValueArray && updatedValueArray?.length > 1 ) {
                            tempValue = updatedValue.filter(v => v.key != -1);
                        }
                        else if (valueArray?.indexOf(-1) < 0 && updatedValueArray && updatedValueArray?.indexOf(-1) > -1 ) {
                            tempValue = [{
                                key   : -1,
                                label : "All"
                            }]
                        }
                        SetValue(tempValue);
                        props.onChange(tempValue);
                    }} showSearch={true} placeholder={options.length === 0 ? "Choose partner(s) to select destination locations" : "Select Destination Locations"}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0}
                    value={value} disabled={options.length === 0 || disabled} className={uiAttrs.className}
                    {...uiAttrs} mode={props.schema.mode ? props.schema.mode : "multiple"}
                >
                    {
                        options?.length > 0 &&
                        options?.map(option => {
                            if (option.value !== null && option.value !== undefined) {
                                return (
                                    <Select.Option value={option.value} key={option.value}>
                                        {option.label}
                                    </Select.Option>
                                )
                            }
                        })
                    }
                </Select>
            }
            {
                props.rawErrors?.length > 0 &&
                <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
            }
        </div>
    )
}