import { apiRequest } from "../../common/utils/apiGateway";
import { checkHttpStatus } from "../../common/utils/utils";



export const getDashboardUrl = (type) => dispatch => {
    dispatch(getDashboardUrlBegin());
    apiRequest({
        url: `/v1/tpdashboard`,
        method: "GET",
        params: {
            type
        }
    }).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(getDashboardUrlSuccess(res?.data?.response.dashboards));
        } else {
            dispatch(getDashboardUrlFailure(res?.data?.response));
        }
    });
};

function getDashboardUrlBegin() {
    return {
        type: 'GET_DASHBOARD_BEGIN'
    };
}

function getDashboardUrlSuccess(payload) {
    return {
        type: 'GET_DASHBOARD_SUCCESS',
        payload: payload
    };
}

function getDashboardUrlFailure(error) {
    return {
        type: 'GET_DASHBOARD_FAILURE',
        payload: error
    };
}