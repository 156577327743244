import React, { useEffect} from "react";
import PendingLists from "./pending-list";
import ScannedList from "./scanned-list";
import { generateID } from "../../../common/utils/utils.js"
import { updateScanLists, updatePendingList, updateScannedList } from "./utils";

export default function ScanList(props) {

    const id = props.listId ? props.listId : generateID(10)

    useEffect(_=> {
        if (props.pendingList && props.scannedList ) {
            updateScanLists(id, [ ...props.pendingList ], [ ...props.scannedList ]);
        }
        else if (props.pendingList) {
            updatePendingList(id, [ ...props.pendingList ])
        }
        else if (props.scannedList) {
            updateScannedList(id, [ ...props.scannedList ])
        }
    }, [ props.pendingList, props.scannedList]);

    return (
        <div className="flex-box flex-gap-m align-items-stretch scan-list-wrapper">
            <PendingLists {...props} listId={id} />
            <ScannedList  {...props} listId={id} />
        </div>
    )
}