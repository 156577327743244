import React from "react";
import { connect } from "react-redux";
import {

} from "antd";
import { PRINT_ENTITY_SCAN_RESPONSE_COLUMNS } from "../../constants";

import "./sticker-scan-response.scss";

function LabelPrintingScanResponse({
    selectedEntity,
    currentScannedEntityInfo
}) {
    return(
        <div className="print-label-scan-response">
            {
                PRINT_ENTITY_SCAN_RESPONSE_COLUMNS[selectedEntity].map((column, index) => {
                    return(
                        <div key={column.key + `${index}`} className="flex-box flex-column justify-content-space-between">
                            <div className="scan-response-key">
                                {column.label}
                            </div>
                            <div className="scan-response-value">
                                {currentScannedEntityInfo[column.key]}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
};

const mapStateToProps = (state) => ({
    selectedEntity: state.inventoryLabelPrinting.selectedEntity,
    currentScannedEntityInfo: state.inventoryLabelPrinting.currentScannedEntityInfo,
});

export default connect(
    mapStateToProps,
    null
)(LabelPrintingScanResponse);