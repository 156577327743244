import React, { useState } from "react";
import { Icon, Divider, Modal, Checkbox, Input } from "antd";
import moment from "moment";
import { reportTypeLegacyArray } from "../config.generator";
import { notifyApiError } from "../../../common/utils/utils";

export default function ConfirmDonwloadPopup({ reportData, closePopup, onSubmit, visible }) {

    const [ checked, SetChecked ] = useState(false);
    const [ emailTo, SetEmailTo ] = useState(localStorage.user ? JSON.parse(atob(localStorage.user)).email : "");

    const details = (label, value) => (
        <div className="flex-column flex-gap-xs">
            <div className="light-grey-text"> {label} </div>
            <div className="text-bold"> {value} </div>
        </div>
    )

    const fromDate = reportData?.dateRange ? parseInt(reportData?.dateRange?.split(",")[0]) : "";
    const toDate   = reportData?.dateRange ? parseInt(reportData?.dateRange?.split(",")[1]) : "";

    const validateEmailAndSubmit = _ => {
        if (checked && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(emailTo)) {
            return notifyApiError("Incorrect e-mail address. Kindly correct and try again.");
        }
        onSubmit(checked ? emailTo : undefined);
    }

    return (
        <Modal width={"40%"} visible={visible} title={"Confirm Generate Report"}
                onCancel={closePopup} onOk={validateEmailAndSubmit}
                okText={"Generate Report"} className="remove-padding" >
            <div className="flex-column flex-gap-m spacer-m">
                <div className="flex-box justify-content-space-between align-items-center">
                    <div className="flex-2 font-size-m-3 break-word text-bold"> Are you sure you want to generate the report? </div>
                    <div className="flex-1 justify-content-flex-end flex-box">
                        <Icon type="file-text" theme="twoTone" style={{ fontSize : 38 }} />
                    </div>
                </div>
                <Divider style={{ margin : "5px 0px" }} />
                <div className="flex-column flex-gap-m spacer-hs">
                    {
                        reportData.reportType &&
                        details("Report Type", reportTypeLegacyArray.find(type => type.code === reportData.reportType)?.label || reportData.reportType)
                    }
                    {
                        reportData.downloadType === "date" &&
                        <>
                            {
                                reportData?.dateRange &&
                                details("Date", <> {moment(fromDate).format("DD/MM/YYYY")} - {moment(toDate).format("DD/MM/YYYY")} </>)
                            }
                            {
                                reportData?.destinations &&
                                details("Destination Branches", <> { reportData?.destinations?.map(d => d.label)?.join(", ") } </>)
                            }
                            {
                                reportData?.customers && 
                                details("Shipper", <> { reportData?.customers?.map(d => d.label)?.join(", ") } </>)
                            }
                        </>
                    }
                </div>
                <div className="flex-column flex-gap-s spacer-hs">
                    <Checkbox checked={checked} onChange={e => SetChecked(e.target.checked)}> Send mail to </Checkbox>
                    <Input value={emailTo} disabled={!checked} style={{ width : "60%" }} onChange={e => SetEmailTo(e.target.value)} />
                </div>
            </div>
        </Modal>
    )
}