import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Constants } from "../../../common/utils/constants";
import { checkHttpStatus, notifyApiError } from "../../../common/utils/utils";
import { menuconfig } from "../../../configs/menu-config";
import { pModules } from "../../../configs/permissionTags";
import AuditService from "../../../services/audit/audit.service";
import { fetchUserLocationMappings } from "../../operations/drs/state/actions";
import { icon as Icon } from '../../ui/icon/Icon';
import { LAYOUT_CONSTANTS } from "../state/reducer";
import "./index.scss";

const auditService = new AuditService;


export default function Sidebar(props) {

    const [ allowedMenuItems, SetAllowedMenuItems ] = useState([]);
    const [ collapsed, SetCollapsed ] = useState(window.outerWidth < 600);

    const userLocationMapping = useSelector(state => state.userLocationMappingReducer.userLocationMapping);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
        var __allowedMenuItems = [ ...menuconfig ];
        const locationOpsType = localStorage?.user ? JSON.parse(atob(localStorage.user))?.location?.locationOpsType : undefined;
        const partnerId = localStorage?.user ? JSON.parse(atob(localStorage.user))?.partner?.id : undefined;
        __allowedMenuItems = __allowedMenuItems.filter(menu => { 
            if (menu.children) {
              menu.children = menu.children.filter(childElem => childElem.canAllow)
            }
            if (menu.path == "sc-ops") {
                return locationOpsType == "SC" || partnerId == 268;
            }
            if (menu.path == "fm-ops") {
                return locationOpsType == "FM" || partnerId == 268 || Constants.userLocationId == 5881185;
            }
            return menu.canAllow;
        });
        SetAllowedMenuItems(__allowedMenuItems);
        fetchUserLocationMappings();

        window.onresize = _ => {
            SetCollapsed(window.outerWidth < 600);
        }

    }, []);

    useEffect(_ => {
        if (allowedMenuItems.length > 0 && userLocationMapping != undefined) {
            const user = localStorage.user ? JSON.parse(atob(localStorage.user)) : {};
            if (Object.keys(userLocationMapping)?.length == 0 && !user?.partner?.franchisePartner) {
                if (user?.partner?.id == 268) return;
                SetAllowedMenuItems(__allowedMenuItems => {
                    return [ ...__allowedMenuItems ].filter(menu => menu.name != pModules.lm_operation.title)
                });
            }
        }

    }, [ userLocationMapping ])

    useEffect(() => {
        if (allowedMenuItems.length > 0 && location.pathname) {
            const modulePath = location.pathname.split("/")[2];
            var moduleIndex  = 0;
            var selectedModule = allowedMenuItems.find((a,i) => {
                if (a.path === modulePath) {
                    moduleIndex = i;
                    return true;
                }
            });
            updateSelectedMenu(selectedModule, moduleIndex);
        }
    }, [ allowedMenuItems, location.pathname ])
    
    const selectedModule = useSelector(state => state?.layoutReducer?.selectedModule)

    const updateSelectedMenu = (menu, index) => {

        if (!menu ) return;
        dispatch({
            type    : LAYOUT_CONSTANTS.UPDATE_SELECTED_MODULE,
            payload : menu.path
        });
        dispatch({
            type    : LAYOUT_CONSTANTS.UPDATE_SUBMODULE_TABS,
            payload : menu
        });
        const inidcator = document.getElementById("selected-indicator");
        const height = (index * 44) + 16;
        inidcator.style.top = height + "px";
    }

    const onTabClick = (menu, index) => {
        
        auditService.getAuditStatus(Constants.userLocationId || Constants.LOGGED_PARTNER_LOCATION_ID)
        .then(response => {
            if (checkHttpStatus(response?.data?.status)) {
                if (response.data?.response?.isAuditOn) {
                    notifyApiError("Audit is still in progress", "Information");
                }
            }
        })
        .catch(e => console.log(e));

        updateSelectedMenu(menu, index);

        var navPath = menu.path + "/dashboard";
        if (menu.children && menu.children.length > 0) {
            navPath += `/${menu.children[0].path}`
        }

        navigate(`${navPath}`);
    }

    const sidebarItemClass = "sidebar-item spacer-hl cursor-pointer flex-box align-items-center flex-gap-l";
    return (
        <aside className="white-bg sidebar">
            {
                allowedMenuItems && allowedMenuItems.length > 0 &&
                <div className="spacer-vm flex-column flex-gap-m font-size-m-2" style={{ width : collapsed ? "auto" : 240 }}>
                    {
                        allowedMenuItems.map((menu, index) => {
                            return (
                                <Tooltip placement="right" key={menu.path} title={collapsed ? menu.label : undefined}
                                 style={{ height : 36 }} onClick={_ => onTabClick(menu, index)}
                                 className={sidebarItemClass + (selectedModule == menu.path ? " selected-sidebar-item" : "")}>
                                    <Icon alt={menu.label} img={selectedModule == menu.path ? menu.activeIcon : menu.inactiveIcon} />
                                    {
                                        !collapsed && 
                                        <div className="sidebar-label"> {menu.label} </div>
                                    }
                                </Tooltip>
                            )
                        })
                    }
                </div>
            }
            <div id="selected-indicator" className="selected-indicator"></div>
        </aside>
    )

}