import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import MonitoringReportsService from "../../../services/monitoring-report/monitoring-report.service";
import { Constants } from "../../../common/utils/constants";

const monitoringReportsService = new MonitoringReportsService();

export default function EmbedComponent() {

    const [ embedUrl, SetEmberUrl ] = useState(false);
    const params   = useParams();
    const location = useLocation();

    useEffect(() => {
        const embedDashboard = params.embedDashboard;
        const reportParams   = location?.search?.substring(location?.search.indexOf("=") + 1);
        const question       = location?.hash === "#question";
        
        if (embedDashboard) {
            if (reportParams) {
                monitoringReportsService.getEmbeddedURLByPayload({
                    reportName      : embedDashboard,
                    embedUrlGeneric : JSON.parse(atob(reportParams)) 
                })
                .then(res => {
                    if (res?.data?.response?.embedUrl) {
                        SetEmberUrl(res?.data?.response?.embedUrl);
                    }
                })
            }
            else if (question) {
                monitoringReportsService.getEmbeddedURLByPayload({
                    reportName  : embedDashboard.substring(embedDashboard.indexOf("-") + 1),
                    embedUrlGeneric : {
                        resource : {
                            question : embedDashboard.substring(0, embedDashboard.indexOf("-"))
                        },
                        params   : { location_id : Constants.userLocationId }
                    }
                })
                .then(res => {
                    if (res?.data?.response?.embedUrl) {
                        SetEmberUrl(res?.data?.response?.embedUrl);
                    }
                })
            }
            else {
                monitoringReportsService.getEmbeddedURL(embedDashboard)
                .then(res => {
                    if (res?.data?.response?.embedUrl) {
                        SetEmberUrl(res?.data?.response?.embedUrl);
                    }
                })
            }
        }
    }, [ ])

    return (
        <div className="flex-column flex-gap-s spacer-vs">
            {
                embedUrl ?
                <iframe src={`${embedUrl}&refresh=60`} frameBorder={0} width="100%" style={{ height: "calc(100vh - 100px)" }} /> :
                <Spin spinning={true}></Spin>
            }
        </div>
    )
}