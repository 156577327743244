import React, { Component } from 'react'
import './style.scss';
import { Button, Empty, Card, Table, Row, Col, Icon, Input, Spin, Modal } from 'antd';
import moment from 'moment';
import { getSummary, callTpApi } from '../../../app/appAction';
import { consigmentScan, uploadBooking, uploadInscan } from '../../inbound/scan/actions';
import { fetchAuditRecords, finishAudit, updateAudit } from './action';
import { SoundNotificationService } from '../../../services/sound.service';
import { notifyApiError, trimByConfig, isScanedOrKeyboardEntry, dataGet, scanResponseDataMap, getLocationData, notifyApiSuccess } from '../../../common/utils/utils';
import { connect } from 'react-redux';
import ScanSticker from '../../shared-components/scan-sticker';
import { Constants } from '../../../common/utils/constants';

const soundNotificationService = new SoundNotificationService;

class AuditForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: 'AWB No',
                    dataIndex: 'waybill_no',
                    key: 1
                },
                {
                    title: 'Destination',
                    dataIndex: 'destination',
                    key: 2
                },
                {
                    title: 'Scan Time',
                    dataIndex: 'createdAt',
                    key: 3,
                    render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
                },
                {
                    title: 'Created On',
                    dataIndex: 'consignmentBO.createdAt',
                    key: 4,
                    render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
                },
                {
                    title: 'Shipment Status',
                    dataIndex: 'consignmentStatus',
                    key: 5
                },
                {
                    title: 'Audit Status',
                    dataIndex: 'auditShipmentStatus',
                    key: 6
                },
            ],
            disableScan: false,
            auditId: props.match.params.id,
            filterPayload: {
                filters: {
                    pageNo: 1,
                    pageSize: 10,
                    totalCount: 0
                }
            },
            pageNo: 1,
            disableScan: false,
            alert: { visible: false, type: '', message: '' },
            scanResponseStatus: { success: false, error: false },
            scanResponse: {},
            scanEntry: false,
            currentScanEntryTime: 0,
            lastScanTime: 0,
            inputEntryTimeList: [],
            scanResponseMetaData: {},
            showMetaData: false,
            application: 'B2C',
            scanResponseDataCurated: {},
            customMessage: 'Start scan waybill to proceed ahead',
            auditRecords: [],
            waybillNumber: ''
        }
        this.InputSearchRef = React.createRef();
    }

    componentDidMount() {
        if (!this.state.auditId) {
            this.props.history.push(`/appv2/inventory/dashboard/audit`)
        } else {
            this.fetchAuditRecords();
        }
    }

    getPaginatedData = (pageNo, paginatedData) => {
        this.setState({
            pageNo
        }, () => this.fetchAuditRecords({ pageNo }));
    }

    clearDataTable() {
        if (this.customClass) {
            this.customMessage = '';
            this.customClass = '';
        }
    }

    fetchAuditRecords = (filters = {}) => {
        this.setState({
            loading: true
        }, () => {
            this.props.fetchAuditRecords({ ...this.state.filterPayload.filters, ...filters }, this.state.auditId)
                .then(({ data }) => {
                    if (data && data.response.auditInfoRecords && data.response.sync) {
                        if (data.response.auditInfoBO &&
                            data.response.auditInfoBO[0] &&
                            data.response.auditInfoBO[0]['isFinished']) {
                            this.redirectToParent();
                        } else {
                            let auditRecords = data.response.auditInfoRecords;
                            let filterPayload = this.state.filterPayload;
                            filterPayload.filters.totalCount = data.response.totalCount;
                            filterPayload.filters.pageSize = data.response.currentPageSize;
                            filterPayload.filters.pageNo = data.response.currentPageNo;
                            if (auditRecords.length) {
                                this.clearDataTable();
                            }

                            this.setState({
                                auditRecords,
                                filterPayload,
                                loading: false
                            })
                        }
                    } else {
                        if (data.response.auditInfoBO && data.response.auditInfoBO.isFinished) {
                            this.redirectToParent();
                        }
                        notifyApiError(data.status.message, 'Error');
                        this.props.history.push(`/appv2/inventory/dashboard/audit`)
                    }
                });
        })
    }

    redirectToParent() {
        notifyApiError('Audit was already closed', 'Error');
        // this.props.history.push(`/appv2/inventory/dashboard/audit`)
        return;
    }

    closeAudit = () => {
        const that = this;
        Modal.confirm({
            title: 'CONFIRM CLOSE AUDIT',
            content: !this.state.auditRecords.length
                ? <h4 style={{ fontWeight: 400 }}>
                    {'Do you want to close the audit with out scanning any shipments? This will discard the current audit.'}
                    <br /><br />
                    {'Are you sure you want to close the audit?'}
                </h4>
                : <h4 style={{ fontWeight: 400 }}>
                    {'Please note that, once submitted you cannot resume this particular Audit in future'}
                    <br /><br />
                    {'Are you sure you want to close the audit?'}
                </h4>,
            width: '40%',
            onOk() {
                that.finishAudit()
            },
            okText: !this.state.auditRecords.length ? 'Discard Audit' : 'Submit Audit',
            cancelText: 'No, Continue Audit',
            onCancel() {
                // that.setState({
                //     createAuditAction: false
                // })
            },
        })
    }

    finishAudit = () => {
        this.setState({
            disableScan: true
        }, () => {
            const payload = {
                'auditInfoBO': { 'id': this.state.auditId },
                'action': 'UPDATE'
            };
            this.props.finishAudit(payload)
                .then(({ data }) => {
                    if (data.response && data.response.sync) {
                        // this.redirectToParent();
                        this.props.history.push(`/appv2/inventory/dashboard/audit`)
                        notifyApiSuccess(data.status.message, 'Success');
                    } else {
                        if (data.response &&
                            data.response.auditInfos
                            && data.response.auditInfos[0]
                            && data.response.auditInfos[0]['isFinished']) {
                            this.redirectToParent();
                        } else {
                            this.setState({
                                disableScan: false
                            })
                            notifyApiError(data.status.message, 'Error');
                            soundNotificationService.playWarning();
                        }
                    }
                })
        })
    }

    scanDrsByKeyboard = (event, value) => {
        const waybillNumber = value.trim();
        // const inputEntryTimeList = [];
        if (!waybillNumber || waybillNumber.length === 0) {
            this.setState({
                scanEntry: false,
                waybillNumber: ''
            })
            return;
        }

        // let lastScanTime = this.state.currentScanEntryTime
        //     ? this.state.currentScanEntryTime
        //     : new Date().getTime();
        // let currentScanEntryTime = new Date().getTime();

        // if (waybillNumber && waybillNumber.length > 1) {
        //     inputEntryTimeList.push(
        //         currentScanEntryTime - lastScanTime
        //     );
        // }

        this.setState({
            waybillNumber,
            // lastScanTime,
            // currentScanEntryTime,
            // inputEntryTimeList: [...this.state.inputEntryTimeList, ...inputEntryTimeList]
        })
    }

    focusInput = () => {
        this.setState({
            inputEntryTimeList: [],
            currentScanEntryTime: new Date().getTime(),
            waybillNumber: ''
        }, () => {
            this.InputSearchRef.current.focus();
        })
    }

    scanWayBill() {
        // this.miscService.clearToast();
        let _awb = this.state.waybillNumber;
        if (!_awb) {
            notifyApiError("Waybill number required!!!", "No Shipment");
            this.focusInput();
            soundNotificationService.playWarning();
            return false;
        }
        _awb = _awb.trim();
        this.state.auditRecords.map(row => {
            if (_awb === row.waybill_no) {
                notifyApiError("Waybill was already scanned", "Duplicate");
            }
        });
        const waybill = { waybillNo: _awb };
        this.manageWaybill(waybill, Constants.ApiActions.link);
        _awb = null;
        this.setState({
            waybillNumber: _awb
        })
    }

    manageWaybill(waybill, action) {
        this.setState({
            disableScan: true,
            scanResponseStatus: { success: false, error: false },
            scanResponseDataCurated: {}
        })
        const scanEntry = isScanedOrKeyboardEntry(this.state.inputEntryTimeList);
        this.setState({
            scanEntry
        })
        waybill['scanInputType'] = scanEntry ? 'BARCODE_SCANNER' : 'KEYBOARD';
        const payload = {
            'consignmentScan': waybill,
            'type': 'AUDIT_SCAN',
            'referenceId': this.state.auditId,
            'valueEntryType': scanEntry ? 'SCAN' : 'KEYBOARD'
        };

        this.props.updateAudit(payload).then(({ data }) => {
            let tempscanResponseDataCurated = {};
            this.setState({
                inputEntryTimeList: [],
                scanResponseMetaData: {},
                showMetaData: false,
                lastScanTime: 0,
                currentScanEntryTime: new Date().getTime(),
                disableScan: false
            })
            if (data && data.response && data.response.sync) {
                tempscanResponseDataCurated = scanResponseDataMap(data.response.scanResponse, data.response);
                if (
                    data.response.auditInfoRecords.consignmentBO &&
                    data.response.auditInfoRecords.consignmentBO &&
                    data.response.auditInfoRecords.consignmentBO.nextLocation
                ) {
                    tempscanResponseDataCurated["nextLocation"] = {
                        value: data.response.auditInfoRecords.consignmentBO.nextLocation
                            ? data.response.auditInfoRecords.consignmentScanBO.consignment.nextLocation.entity.custObject.name + ' | ' +
                            data.response.auditInfoRecords.consignmentScanBO.consignment.nextLocation.name
                            : "",
                        key: "Next Location"
                    };
                }
                if (
                    data.response.auditInfoRecords.consignmentBO &&
                    data.response.auditInfoRecords.consignmentBO &&
                    data.response.auditInfoRecords.consignmentBO.isLocalServiceable
                ) {
                    tempscanResponseDataCurated["isLocalServiceable"] = {
                        value: data.response.auditInfoRecords.consignmentBO.isLocalServiceable
                            ? 'YES' : 'NO',
                        key: 'Local Serviceable'
                    };
                }

                const scanResponseDataCurated = Object.assign(
                    {},
                    tempscanResponseDataCurated
                );

                this.setState({
                    scanResponseDataCurated
                })

                const result = data.response;
                this.state.filterPayload.filters.totalCount = result.auditInfoBO.scannedCount;
                const scanResponseStatus = { success: true, error: false };
                this.setState({
                    scanResponseStatus
                })
                if (result.auditInfoRecords && result.auditInfoRecords.consignmentBO) {
                    this.showMetaData = true;
                    this.scanResponseMetaData = {
                        'waybillNo': result.auditInfoRecords.waybill_no,
                        'criticalShipment': result.auditInfoRecords.consignmentBO.isCritical ? 'YES' : 'NO',
                        'cpd': result.auditInfoRecords.consignmentBO.expectedDeliveryDate,
                        'reason': result.auditInfoRecords.consignmentBO.lastReason ?
                        result.auditInfoRecords.consignmentBO.lastReason.reasonDescription : null,
                        'pincode': result.auditInfoRecords.consignmentBO.pincode.zipcode,
                        'resheduled': result.auditInfoRecords.consignmentBO.resheduled,
                        'attempts': result.auditInfoRecords.consignmentBO.attempts,
                        'updatedOn': result.auditInfoRecords.consignmentBO.updatedAt,
                        'bookingDate': result.auditInfoRecords.consignmentBO.bookingDate,
                    };
                }
                const records = [...this.state.auditRecords];
                if (!records.find((val) => val.waybill_no === result.auditInfoRecords.waybill_no)) {
                    records.unshift(result.auditInfoRecords);
                }
                const scanResponse = data.response;
                
                if (this.state.auditRecords.length) {
                    this.clearDataTable();
                }
                
                this.setState({
                    scanResponse,
                    auditRecords: records
                })
                
                if (scanResponse['auditInfoRecords']['auditStatus'] === 'UNEXPECTED') {
                    soundNotificationService.playWarning();
                } else {
                    notifyApiSuccess(result.auditInfoRecords.waybill_no + ' scanned successfully', 'Success');
                    soundNotificationService.playSuccess();
                }

            } else {
                if (data.response
                    && data.response.auditInfoBO
                    && data.response.auditInfoBO.isFinished) {
                    this.redirectToParent();
                }
                const scanResponseStatus = { success: false, error: true };
                soundNotificationService.playWarning();
                notifyApiError(data.status.message, 'Danger');
                const scanResponse = data.status.message;
                this.setState({
                    scanResponseStatus,
                    scanResponse
                })
            }
            this.focusInput();
        });
    }

    render() {
        const { disableScan, columns, pageNo, application, scanResponseStatus, scanResponse, customMessage, loading, auditRecords, filterPayload } = this.state;
        const { auditData } = this.props;
        return (
            <div className="audit-form">
                <div className="flex-column flex-gap-l">
                    <div className="flex-box justify-content-space-between">
                        <div className="flex-1">
                            <Icon style={{ fontSize: 24 }} type={'arrow-left'} onClick={this.props.history.goBack} />
                        </div>
                        <div className="flex-box justify-content-flex-end">
                            <Button disabled={disableScan} block={true} type="primary"
                             onClick={this.closeAudit} className="lsn-btn-primary" >
                                Finish Audit
                            </Button>
                        </div>
                    </div>
                    
                    <div>
                        <Card>
                            <div className="flex-box flex-wrap">
                                <div className="flex-1">
                                    <h2>Start Audit Scan</h2>
                                    <form onSubmit={e => { e.preventDefault(); this.scanWayBill() }}>
                                        <Input
                                            size="large"
                                            disabled={this.state.disableScan}
                                            placeholder={`Scan Waybill`}
                                            onChange={e => this.scanDrsByKeyboard(e, e.target.value)}
                                            ref={this.InputSearchRef}
                                            value={this.state.waybillNumber}
                                            suffix={<Icon type="barcode" onClick={e => this.scanWayBill()} style={{ fontSize: 30, cursor: 'pointer' }} />}
                                        />
                                        <small>Use Barcode Scanner for faster process or enter manually</small>
                                    </form>
                                </div>
                            </div>
                            <div className="scan-row curernt-scan" style={{ fontSize: '20px' }} gutter={24}>
                                {
                                    scanResponseStatus?.error ?
                                    <div className="flex-box flex-gap-l">
                                        <div> Current Scan </div>
                                        <div style={{ color: "rgb(245, 0, 0)" }}>Added to Overage</div>
                                    </div> :
                                    <></>
                                }
                            </div>
                        </Card>
                        {
                            !this.state.inBoundScanning ?
                            <ScanSticker scanResponseStatus={this.state.scanResponseStatus}
                                scanResponseDataCurated={this.state.scanResponseDataCurated} /> :
                            <div className="scan-box">
                                <Spin spinning={this.props.scanLoading}>
                                </Spin>
                            </div>
                        }
                    </div>

                    <div>
                        <Card style={{ marginBottom: 20 }} className="waybill-list">
                            <h2>Waybill List</h2>
                            <Table bordered={true} loading={loading}
                                pagination={{
                                    onChange: this.getPaginatedData,
                                    total: filterPayload.filters?.totalCount,
                                    position: 'bottom',
                                    current: pageNo,
                                    pageSize: filterPayload.filters?.pageSize
                                }}
                                rowKey={row => row.id} dataSource={auditRecords} columns={columns} />
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    summary: state.app.summary,
    config: state.app.configurations,
    scanedInfo: state.scan.scanedInfo,
    scanLoading: state.scan.loading,
    bulkOperation: state.scan.bulkOperation,
    bulkOperationLoading: state.scan.bulkOperationLoading
});
const mapDispatchToProps = dispatch => ({
    getSummary: page => dispatch(getSummary(page)),
    consigmentScan: payload => dispatch(consigmentScan(payload)),
    uploadBooking: payload => dispatch(uploadBooking(payload)),
    uploadInscan: payload => dispatch(uploadInscan(payload)),
    callTpApi: (ev1, ev2) => dispatch(callTpApi(ev1, ev2)),
    fetchAuditRecords: (payload, id) => dispatch(fetchAuditRecords(payload, id)),
    finishAudit: payload => dispatch(finishAudit(payload)),
    updateAudit: payload => dispatch(updateAudit(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AuditForm);