import { USER_LOGGEDIN_INFO } from "../utils/constants";
import { notifyApiError } from "../utils/utils";

export const generateRequestHeaders = headers => {
    return {
        token   : localStorage.getItem("accessToken"),
        tokenId : localStorage.getItem("tokenId"),
        ...headers
    }
}

export const checkHttpStatus = code => {
    switch (code) {
        case 401:
            notifyApiError("User session invalid. Kindly re-login.")
            forceLogoutUser();
            return false;

        case 403:
            notifyApiError("User does not have permission to perform the operation.")
            return false;
    
        default:
            return true;
    }   
}

const forceLogoutUser = _ => {
    localStorage.clear();
    document.cookie = USER_LOGGEDIN_INFO.LOGGED_IN + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    window.location.href = `/appv2/login?redirectTo=${window.location.pathname}`;
}