import { useEffect, useState } from "react";
import MonitoringReportsService from "../../../services/monitoring-report/monitoring-report.service";
import { getParsedEmbedUrl } from "../../monitoring-report/action";
import { Modal, Tabs } from "antd";

export default function TripStatusModal({ tripCode, onCancel }) {

    const [ embedUrl, SetEmbedUrl ] = useState(false);
    const [ tabKey, SetTabKey ] = useState(false);

    useEffect(() => {
        if (tripCode) {
            SetTabKey("summary");
            getSummaryEmbedUrl();
        }
    }, [ tripCode ]);

    const getSummaryEmbedUrl = _ => {
        (new MonitoringReportsService()).getEmbeddedURLByPayload({
            reportName: "dispatched-trip-dc-level-status",
            embedUrlGeneric: {
                resource : { question : 1583  },
                params   : { trip_id  : tripCode }
            }
        })
        .then(response => {
            SetEmbedUrl(response?.data?.response?.embedUrl);
        })
    }

    const getDetailsEmbedUrl = _ => {
        (new MonitoringReportsService()).getEmbeddedURLByPayload({
            reportName: "dispatched-trip-dc-level-status-detailed-view",
            embedUrlGeneric: {
                resource : { question : 1724 },
                params   : { trip_id: tripCode }
            }
        })
        .then(response => {
            SetEmbedUrl(response?.data?.response?.embedUrl);
        })
    }

    const navigateBetweenTab = key => {
        SetTabKey(key);
        SetEmbedUrl(false);
        if (key == "details") {
            getDetailsEmbedUrl();
        }
        else if (key == "summary") {
            getSummaryEmbedUrl();
        }
    }

    return (
        <Modal centered={true} width={"98%"} title={tripCode}
         maskClosable={false} closable={false} destroyOnClose={true}
         okButtonProps={{ className : "display-none" }} cancelText="Close"
         visible={tripCode} onCancel={onCancel} className="remove-padding" >
            <Tabs className="log10-tabs trip-status-modal-tabs" defaultActiveKey="summary" activeKey={tabKey} onChange={navigateBetweenTab}>
                <Tabs.TabPane tab={<span className="text-semi-bold">Location Level Summary</span>} key="summary" />
                <Tabs.TabPane tab={<span className="text-semi-bold">Detailed View</span>} key="details" />
            </Tabs>
            <iframe height={550} src={getParsedEmbedUrl(embedUrl)} frameBorder={0} width="100%" />
        </Modal>
    )
}