import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { checkForDuplicateErrors, schemaToAttrs } from "../form.utils";
import { Constants } from "../../../../common/utils/constants";
import { fetchPartners } from "../../../reportsv2/services/fetch.services";

export default function PartnerField(props) {

    const [ options, SetOptions ] = useState([]);
    const [ value, SetValue ] = useState(props.value);
    const [ loading, SetLoading ] = useState(true);

    const uiAttrs = schemaToAttrs(props.uiSchema, props.readonly);

    const disabled = props.disabled || props.readonly || uiAttrs.disabled;
    delete uiAttrs.disabled;

    useEffect(() => {
        fetchPartners()
        .then(partners => {
            var tempOptions = [{
                value   : Constants.PARTNER_ID,
                label   : "Self"
            }]
            partners.map(p => {
                if (p.linkPartnerId != Constants.PARTNER_ID && p.linkPartnerBO?.name && p.linkPartnerBO?.contactNumber) {
                    tempOptions.push({
                        value   : p.linkPartnerId,
                        label   : p.linkPartnerBO?.name
                    })
                }
            })
            SetOptions(tempOptions);
            if (props.value) {
                SetValue(props.value);
            }
            else {
                SetValue([{
                    key     : Constants.PARTNER_ID,
                    label   : "Self"
                }]);
                props.onChange([{
                    key     : Constants.PARTNER_ID,
                    label   : "Self"
                }]);
            }
            SetLoading(false);
        })
    }, [])

    useEffect(() => {
        SetValue(props.value);
    }, [ props.value ])

    return (
        <div className={"input-wrapper flex-column"}>
            {
                loading ?
                <Input className="input-loading"/> :
                <Select labelInValue={true}
                    onChange={updateValue => {
                        if (updateValue && updateValue.length === 0) {
                            SetValue(undefined);
                            props.onChange(undefined);
                        }
                        else {
                            SetValue(updateValue);
                            props.onChange(updateValue)
                        }
                    }} showSearch={true} placeholder={"Select Partners"}
                    filterOption={(input, option) => option.props.children === "Parnter Name not available" ? false : option.props.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0}
                    value={value} disabled={disabled} className={uiAttrs.className}
                    {...uiAttrs} mode={props.schema.mode ? props.schema.mode : "multiple"}
                    notFoundContent={"No partner found!"}
                >
                    {
                        options?.length > 0 &&
                        options?.map(option => {
                            if (option.value) {
                                return (
                                    <Select.Option value={option.value} key={option.value}>
                                        {option.label || "Parnter Name not available"}
                                    </Select.Option>
                                )
                            }
                        })
                    }
                </Select>
            }
            {
                props.rawErrors?.length > 0 &&
                <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
            }
        </div>
    )
}