import { apiService } from "../../../common/apiService/service"
import { apiRequest } from "../../../common/utils/apiGateway"

export const callCreateTrip = data => new Promise((resolve, reject) => {
    apiRequest({
        url    : "/hydra/v1/trip/create",
        method : "POST",
        data   : data
    })
    .then(resp => resolve(resp?.data?.response?.trip))
    .catch(resp => reject(resp))
})

export const createTrip = (reqBody) => {
    return apiService.post('hub/trip/create', reqBody)
}