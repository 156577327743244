export const DRS_SHIPMENT_STATUS = {
    DEL : {
        viewText : "Delivered",
        className : "success-color success-bg"
    },
    UNDEL : {
        viewText : "Undelivered",
        className : "error-color error-bg"
    },
    INWARD : {
        viewText : "Undelivered",
        className : "error-color error-bg"
    },
    OFD  : {
        viewText : "Out for Delivery",
        className : "info-color primary-info-bg"
    }
}

export const SUSPICIOUS_REASONS_MAP = {
    NO_CALL_ATTEMPT_BUT_NOT_REACHABLE   : "Call not attempted.",
    CALL_CONVERSATION_BUT_NOT_REACHABLE : "Short call duration.",
    NO_CALL_ATTEMPT_BUT_CUSTOMER_RESCHEDULED : "Call not attempted. But delivery rescheduled for future.",
    NO_CALL_CONVERSATION_BUT_CUSTOMER_RESCHEDULED : "Rescheduled with a short call duration.",
    REASON_NEEDS_VALIDATION : "Undelivered Reason needs validation.",
    NO_OTP_GENERATED_OTP_REASON : "No OTP generated but undelivered reason releated to OTP.",
    CNR_CALL_ATTEMPT_INCOMPLETE : "Last call from rider to customer was invalid",
    CNR_NO_CALL_ATTEMPT : "No call attempts were made by the Rider",
    CNR_CALL_CONVERSATION_MADE : "Customer is reachable. Atleast one call was completed.",
    CNR_CUSTOMER_CALLED_RIDER : "Customer called Rider"
}