import { Icon, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MonitoringReportsService from "../../../services/monitoring-report/monitoring-report.service";

const monitoringReportsService = new MonitoringReportsService();

export default function MetabaseReport({ reportName, autoRefresh, autoRefreshTimetout, hideBackButton, iframeHeight, dateParams }) {

    const [ embedUrl, SetEmbedUrl ] = useState(false);
    const [ loading, SetLoading ] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        SetLoading(true);
        monitoringReportsService.getEmbeddedURL(reportName)
        .then(res => {
            SetLoading(false);
            if (res?.data?.response?.embedUrl) {
                let newUrl = res?.data?.response?.embedUrl
                if(dateParams){
                    let urlParams =""
                    const formattedDate = new Date().toISOString().split('T')[0]
                    const [baseUrl, hashStringFormat] = res?.data?.response?.embedUrl.split('#')
                    if(dateParams.has("startDate")){urlParams += `&start_date=${formattedDate}`}
                    if(dateParams.has("endDate")){urlParams += `&end_date=${formattedDate}`}
                    newUrl = `${baseUrl}${urlParams!=null?'?'+urlParams:urlParams}${hashStringFormat ? "#" + hashStringFormat : ""}`
                }
                SetEmbedUrl(newUrl);
            }
        })
    }, [ reportName ])

    return (
            loading ?
            <Spin spinning={true}/> :
            <div className={`white-bg flex-column flex-gap-s ${!hideBackButton ? "spacer-vs" : ""}`}>
                {
                    !hideBackButton &&
                    <div className="cursor-pointer flex-box flex-gap-m align-items-center spacer-hm" onClick={_ => navigate(-1)}>
                        <Icon type="arrow-left" style={{ fontSize : 18 }}/>
                    </div>
                }
                {
                    embedUrl ?
                    <iframe src={`${embedUrl}${autoRefresh ? `&refresh=${autoRefreshTimetout || 60}` : ""}`} frameBorder={0} width="100%" style={{ height: iframeHeight || "calc(100vh - 180px)" }} /> :
                    <Spin spinning={true}></Spin>
                }
            </div>
    )
}