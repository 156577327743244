import React from "react";
import { connect } from "react-redux";
import {
    Modal,
} from "antd";
import { 
    fetchEntityLabelPrintingData,
    closeRescanModal,
} from "../../action";
import { PRINT_ENTITY_TYPES } from "../../constants";
import { downloadFile } from "../../../../../common/utils/utils";
;
function ReScanModal({
    selectedEntity,
    rescanModalState,
    fetchEntityLabelPrintingData,
    closeRescanModal,
}) {
    const { show, message, payload } = rescanModalState;

    const handleReScan = () => {
        if(selectedEntity === PRINT_ENTITY_TYPES.MANIFEST_STICKER_PRINT){
            fetchEntityLabelPrintingData(selectedEntity, payload, true);
        }else{
            downloadFile(payload.labelPrintingPDFUrl, "_blank");
        }
    };

    const handleCancel = () => {
        closeRescanModal();
    };
    return(
        <Modal
          title="Rescan?"
          visible={show}
          onOk={handleReScan}
          onCancel={handleCancel}
          okText="Yes"
          cancelText="No"
        >
          <p>{message}</p>
        </Modal>
    )
};

const mapStateToProps = state => ({
    selectedEntity: state.inventoryLabelPrinting.selectedEntity,
    rescanModalState: state.inventoryLabelPrinting.rescanModalState
});

const mapDispatchToProps = dispatch => ({
    fetchEntityLabelPrintingData: (entityType, payload, isRescan) => dispatch(fetchEntityLabelPrintingData(entityType, payload, isRescan)),
    closeRescanModal: () => dispatch(closeRescanModal()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReScanModal);