import React, { Component } from "react";
import { connect } from "react-redux";
import { checkHttpStatus } from "../../common/utils/utils";
import ScanService from "../../services/inbound/scan.service";
import { getConsignment, getManifest } from "../inventory/awb/actions";
import { fetchConnectionSheetDetails } from "../trips/connection-sheet/v2/connections.service";
import { fetchPrintRunSheet } from "./actions";
import "./print.scss";
import DocketsTemplate from "./templates/dockets/dockets.template";
import MultipleDocketsChallans from "./templates/dockets/multiple.dockets";
import InventoryManifest from "./templates/inventory-manifest/index";
import { fetchPrintTripData } from "./templates/trip-sheet-print/service";
import TripSheetPrint from "./templates/trip-sheet-print/trip.sheet";

class Print extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: new URLSearchParams(props.location.search)
    }
  }

  componentDidMount() {
    this.setState(state => ({
      consignmentId: state.params.get("consignmentId"),
      consignmentIds : state.params.get("consignmentIds"),
      formType: state.params.get("formtype"),
      requestedBarCode: state.params.get("requestedBarCode"),
      leadId: state.params.get("leadId"),
      prsId: state.params.get("prsId"),
      tripId: state.params.get("tripId"),
      tripDetails : {},
      error: {},
      barcodeList: []
    }), () => this.getRequiredData());
  }

  componentDidUpdate(prevProps) {
    const { consigments } = this.props;
    const { consignmentId } = this.state;
    if (consigments !== prevProps.consigments) {
      if (consigments[consignmentId]) {
        this.setState({
          barcodeList: this.getBarCodes(consigments[consignmentId])
        })
      }
    }
  }

  getHeaderText = () => {
    const { formType, consignmentId } = this.state;
    switch (formType) {
      case "inventory_manifest_information": return "Inventory Manifest Info"; // to do
      case "multiplechallans" : return false
      case "connectionSheet" : return false;
      case "tripSheet" : return false;
      default: this.setState({
        message: "Requested details not available, please contact loadshare"
      });
    }
  }

  getcontext = () => {
    const { formType, consignmentsList, consignmentIds, tripDetails } = this.state;
    switch (formType) {
      case "inventory_manifest_information": return <InventoryManifest consignmentDetail={this.state?.consignmentDetail} />;
      case "multiplechallans": return <MultipleDocketsChallans consignmentIds={consignmentIds} />;
      case "connectionSheet" : return <DocketsTemplate consignments={consignmentsList && consignmentsList?.length ? consignmentsList?.[0] : {} } />;
      case "tripSheet" : return <TripSheetPrint tripDetails={tripDetails} />
      default: this.setState({
        message: "Requested details not available, please contact loadshare"
      });
    }
  }

  getRequiredData = () => {
    
    const { formType } = this.state;

    switch (formType) {
      case "inventory_manifest_information": this.processManifest(); break;
      case "connectionSheet" : this.getConnectionSheet(); break;
      case "tripSheet" : this.getTripDetails(); break;
      default: this.setState({
        message: "Requested details not available, please contact loadshare"
      });
    }
  }

  getBarCodes = consignment => {
    const barcodeList = []
    consignment.consignments["shipmentBOs"].map((data, index) => {
      data["barcodes"].map((barcode) => {
        barcodeList.push(barcode.barcode);
      });
    });
    return barcodeList
  }

  processManifest() {
    const { consignmentId } = this.state;
    (new ScanService()).getManifest(consignmentId).then(({ data }) => {
      this.setState({
        consignmentDetail: {
          ...data.response.manifests[0],
          cityPincode: data.response.manifests[0].loc.address.pincode.city.stateCode +
            " "
            + data.response.manifests[0].loc.address.pincode.zipcode
        }
      })
    });
  }

  getConnectionSheet = () => {
    fetchConnectionSheetDetails([ this.state.consignmentId ])
    .then(response => {
      if (checkHttpStatus(response?.data?.status)) {
        const tempConsignmentsList = response?.data?.response?.connections.map(c => {
          return {
            consignee : c.destinationLocation,
            shipper   : c.originatedLocation,
            waybillNo : c.code,
            totalWeight   : c.totalWeight,
            bookingDate   : c.createdAt,
            movementType  : "PTL",
            totalBagCount : c.manifestCount,
            movementType       : c.movementType,
            connectionCode     : c.code,
            consignmentType    : c.connectionType,
            totalShipmentCount : c.shipmentCount
          }
        })
        this.setState({ consignmentsList : tempConsignmentsList })
      }
      else {
        this.setState({ consignmentsList: [] });
      }
    })
  }

  getTripDetails = () => {
    fetchPrintTripData(this.state.tripId)
    .then(response => {
      if (checkHttpStatus(response?.data?.status)) {
        this.setState({
          tripDetails : response?.data?.response
        });
      }
    })
  }

  render() {
    if (this.state.formType === "tripSheet") {
      return this.getcontext();
    }
    return (
      <div className="Print">
        <div className="row">
          <div className=" center-structure">
            <div className="waybill_information">
              <div className="ant-card padding-10">
                {
                  this.getHeaderText() &&
                  <div className="card-header text-center waybill_heading" style={{ marginTop: 30, marginBottom: 30 }}>
                    <span>
                      {this.getHeaderText()}
                    </span>
                  </div>
                }
                <div className="card-body">
                  {this.getcontext()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  printData: state.print,
  consigments: state.inventoryAwb.selectedConsigment,
});

const mapDispatchToProps = dispatch => ({
  fetchPrintRunSheet: (payload) => dispatch(fetchPrintRunSheet(payload)),
  getConsignment: payload => dispatch(getConsignment(payload)),
  getManifest: payload => dispatch(getManifest(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(Print);