export const constructPaginationObjectFromResponse = (resp) => {
    const currentPageNo = resp?.currentPageNo || 1;
    const currentPageSize = resp?.currentPageSize || 10;
    const limit = resp?.limit || 10;
    const maxPage = resp?.maxPage || 1;
    const offset = resp?.offset || 1;
    const totalCount = resp?.totalCount || 0;
    return {
        currentPageNo,
        currentPageSize,
        limit,
        maxPage,
        offset,
        totalCount
    }
};

export const TRIP_UPDATE_ACTIONS = {
    CONVERT_TO_OUTBOUND: "CONVERT_TO_OUTBOUND",
    DISPATCH: "DISPATCH",
    MARK_ARRIVED: "MARK_ARRIVED",
    UNLOAD: "UNLOAD_TRIP",
    LOAD_TRIP: "LOAD_TRIP",
    COMPLETE_TRIP: "COMPLETE_TRIP",
};

export const TRIP_UPDATE_ACTION_LABELS = {
    CONVERT_TO_OUTBOUND: "Convert to outbound",
    DISPATCH: "Dispatch",
    MARK_ARRIVED: "Mark Arrived",
    UNLOAD_TRIP: "Unload",
    LOAD_TRIP: "Add Load",
    COMPLETE_TRIP: "Complete Trip"
};

export const TRIP_ACTION_CONFIRM_MESSAGE = {
    DISPATCH      : tripCode => <>Are you sure you want to Dispatch trip - <strong>{tripCode}</strong>?</>,
    MARK_ARRIVED  : tripCode => <>Are you sure you want to Mark trip - <strong>{tripCode}</strong> as <strong>Arrived</strong>?</>,
    LOAD_TRIP     : tripCode => <>Are you sure you want to Mark trip - <strong>{tripCode}</strong> as <strong>Loading</strong>?</>,
    COMPLETE_TRIP : tripCode => <>Are you sure you want to Mark trip - <strong>{tripCode}</strong> as <strong>Completed</strong>?</>,
};

export const TRIP_ACTION_SUCCESS_MESSAGE = {
    DISPATCH      : tripCode => <>Successfully Dispatched <strong>{tripCode}</strong>.</>,
    MARK_ARRIVED  : tripCode => <>Successfully marked <strong>{tripCode}</strong> as Arrived.</>,
    LOAD_TRIP     : tripCode => <>Successfully marked <strong>{tripCode}</strong> as Loaded.</>,
    COMPLETE_TRIP : tripCode => <>Successfully marked <strong>{tripCode}</strong> as Completed.</>,
};

export const getActionMessage = actionName => {
    switch (actionName) {
        case TRIP_UPDATE_ACTIONS.MARK_ARRIVED:
            return "Mark as Arrived?";
        case TRIP_UPDATE_ACTIONS.DISPATCH:
            return "Confirm Dispatch?"
        case TRIP_UPDATE_ACTIONS.CONVERT_TO_OUTBOUND:
            return "Convert trip to Outbound trip?";
        case TRIP_UPDATE_ACTIONS.COMPLETE_TRIP:
            return "Mark trip as completed?"
        default:
            return "";
    }
};