import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { canAllow } from "./common/utils/utils";
import { LAYOUT_CONSTANTS } from "./components/layouts/state/reducer";
import AccessDenied from "./components/authentication/access/AccessDenied";

export default function WrappedRoute(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const params   = useParams();
    const dispatch = useDispatch();
    const state = useSelector((state) => state)

    const history = {
        push    : (path, state) => navigate(path, { state }),
        goBack  : _ => navigate(-1),
        replace : path => navigate(path, { replace : true }),
        location 
    }

    useEffect(() => {
        dispatch({
            type    : LAYOUT_CONSTANTS.UPDATE_LAYOUT,
            payload : props
        });
    }, [ location.pathname ])

    if (props.access === undefined || canAllow(props.access, false, false, state)) {
        return <props.component history={history} params={params} match={{ params }} location={location} />
    }
    else {
        return <AccessDenied history={history} params={params} match={{ params }} location={location} />
    }

}