export const ACTION_CONSTANTS = {
    STORE_EXCEPTIONS : "STORE_EXCEPTIONS",
    TOGGLE_LOADING   : "TOGGLE_LOADING"
}

const initialState = {
    loading : false,
    entityExceptions : {}
}

export const ExceptionReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION_CONSTANTS.STORE_EXCEPTIONS : return {
            ...state,
            entityExceptions : action.payload
        }
        default : return state
    }
}