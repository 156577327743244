import { apiRequest } from "../../../../common/utils/apiGateway";
import { Constants } from "../../../../common/utils/constants";
import { checkHttpStatus } from "../../../../common/utils/utils";
import store from "../../../../utils/store";
import { ACTION_TYPES } from "./reducer";

const userLocationMappingReducer = store.getState()?.userLocationMappingReducer;
const dispatch =  store.dispatch;

export const createCluserWiseUserLocationMap = userLocationMappingBOList => {
    const clusterMap = {};
    if (userLocationMappingBOList.length >= 1) {
        userLocationMappingBOList.map(location => {
            var clusterKey = location.clusterName.split(" ").join("__");
            if (clusterMap[clusterKey]) {
                clusterMap[clusterKey].push(location);
            }
            else {
                clusterMap[clusterKey] = [ location ];
            }
        })
    }
    return clusterMap;
}

export const getClusterLocations = clusters =>  new Promise((resolve, reject) => {
    fetchUserLocationMappings()
    .then(userLocationMapping => {
        var locations = [];
        if (Object.keys(userLocationMapping).length > 1) {
            clusters.map(cluster => {
                locations = [ ...userLocationMapping[cluster], ...locations ];
            })
        }
        else {
            locations = Object.values(userLocationMapping)[0];
        }
        resolve(locations);
    })
    .catch(e => reject(e));
})

export const fetchUserLocationMappings = _ => new Promise((resolve, reject) => {

    if (userLocationMappingReducer.userLocationMapping) {
        return userLocationMappingReducer.userLocationMapping;
    }

    var fetchURL = "/b2c/v1/user_location_mappings?fetchLocationDetails=true";
    const config = localStorage.getItem("_configrations") ? JSON.parse(atob(localStorage.getItem("_configrations"))) : {};
    const opsAdminUserIds = config?.opsAdminUserIds || [];
    const valmoAdminUserIds = config?.valmoOpsAdminUserIds || [];

    if (valmoAdminUserIds.indexOf(Constants.sessionUser?.id + "") > -1) {
        fetchURL = "/b2c/v1/all_user_location_mappings?fetchLocationDetails=true&source=VALMO";
    }
    else if (opsAdminUserIds.indexOf(Constants.sessionUser?.id + "") > -1) {
        fetchURL = "/b2c/v1/all_user_location_mappings?fetchLocationDetails=true&source=LSN";
    }

    apiRequest({
        url    : fetchURL,
        method : 'GET'
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            var userLocationMapping = {};
            if (response?.data?.response?.userLocationMappingBOList?.length > 0) {
                var userLocationMapping = createCluserWiseUserLocationMap(response?.data?.response?.userLocationMappingBOList)
            }
            dispatch({
                type    : ACTION_TYPES.CACHE_USER_LOCATION_MAPPING,
                payload : userLocationMapping
            })
            resolve(userLocationMapping);
        }
    })
    .catch(e => reject({}));
})