export const INITIAL_STATE = {
    loading:false,
    error: "",
    consignments: [],
    dataCardList: [],
    paginationData: null,
    rtoAction: false,
    config: null
};

export const ACTION_TYPES = {
    FETCH_CONSIGNMENTS_BEGIN: "FETCH_CONSIGNMENTS_BEGIN",
    FETCH_CONSIGNMENTS_SUCCESS: "FETCH_CONSIGNMENTS_SUCCESS_RTO_ELIGIBLE",
    FETCH_CONSIGNMENTS_FAILURE: "FETCH_CONSIGNMENTS_FAILURE",

    FETCH_CONFIGURATIONS_BEGIN: "FETCH_CONFIGURATIONS_BEGIN",
    FETCH_CONFIGURATIONS_SUCCESS: "FETCH_CONFIGURATIONS_SUCCESS",
    FETCH_CONFIGURATIONS_FAILURE: "FETCH_CONFIGURATIONS_FAILURE",
};

export const RTO_COLORS = {
    yellow: "#FFB64D"
}
