import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { checkForDuplicateErrors, schemaToAttrs } from "../form.utils";

export default function DateRangeWidget(props) {

    var [ startDate, SetStartDate ] = useState(undefined);
    var [ endDate, SetEndDate ] = useState(undefined);
    var [ endDateOpen, SetEndDateOpen ] = useState(undefined);    

    useEffect(() => {
        if (props?.value && typeof props?.value == "string") {
            [startDate, endDate] = props.value.split(",");
            [startDate, endDate] = [parseInt(startDate), parseInt(endDate)];
        } else if (props?.value && props?.value instanceof Array) {
            [startDate, endDate] = [props.value[0], props.value[1]];
            [startDate, endDate] = [parseInt(startDate), parseInt(endDate)];
        } else if (!props?.value) {
            [startDate, endDate] = [undefined, undefined]
        }
        SetStartDate(startDate);
        SetEndDate(endDate);
    }, [ props?.value ]);

    const uiAttrs = schemaToAttrs(props.uiSchema);
    uiAttrs.className = uiAttrs.className ? uiAttrs.className + " ant-input" : "ant-input";
    if (props.readonly) {
        uiAttrs.className += " ant-date-input-readonly";
    }
    
    const disableEndDate = endValue => {
        var disable = false;
        if (props.schema.disableFutureDates) {
            disable = endValue.valueOf() >= moment().endOf("day").valueOf()
        }
        if (!startDate) {
            return disable;
        }
        disable = disable || endValue.valueOf() <= moment(startDate).valueOf()
        if (props.schema.maxDateRange) {
            if (typeof props.schema.maxDateRange === "object" && props.schema.maxDateRange?.unit && props.schema.maxDateRange?.amount) {
                disable = disable || endValue.valueOf() >= moment(startDate).add(props.schema.maxDateRange?.unit, props.schema.maxDateRange?.amount).valueOf()
            }
            else if (typeof props.schema.maxDateRange === "number" || typeof props.schema.maxDateRange === "bigint") {
                disable = disable || endValue.valueOf() >= moment(startDate).add('days', props.schema.maxDateRange?.amount).valueOf()
            }
        }
        return disable;
    }
    const disableStartDate = startValue => {
        var disable = false;
        if (props.schema.disableFutureDates) {
            disable = startValue.valueOf() >= moment().endOf("day").valueOf()
        }
        if (!endDate) {
            return disable;
        }
        disable = startValue.valueOf() >= moment(endDate).valueOf()
        return disable;
    }

    const onChange = (start, end) => {
        const updatedRange = [start ? moment(start).startOf("day").valueOf() : "", end ? moment(end).endOf("day").valueOf() : ""].join(",");
        props.onChange(updatedRange)
    }
    return (
        <div className="input-wrapper">
            <div className="flex-box flex-1 flex-gap-l align-items-center">
                <DatePicker value={startDate ? moment(startDate) : undefined}
                    disabledDate={disableStartDate}
                    onChange={value => {
                        SetEndDateOpen(true);
                        if (!value) { onChange("", ""); }
                        onChange(value, endDate)
                    }}
                    disabled={props.disabled || props.readonly} {...uiAttrs}
                    className={uiAttrs.className ? uiAttrs.className + "" : ""}
                    placeholder={uiAttrs.fromPlaceHolder ? uiAttrs.fromPlaceHolder : "From Date"} />

                { uiAttrs.separator ? uiAttrs.separator : "to" }
                
                <DatePicker value={endDate ? moment(endDate) : undefined}
                    disabledDate={disableEndDate} open={endDateOpen}
                    onOpenChange={SetEndDateOpen}
                    onChange={value => {
                        if (!value) { onChange("", ""); }
                        onChange(startDate, value)
                    }}
                    disabled={props.disabled || props.readonly} {...uiAttrs}
                    className={uiAttrs.className ? uiAttrs.className + "" : ""}
                    placeholder={uiAttrs.toPlaceHolder ? uiAttrs.toPlaceHolder : "To Date"} />
            </div>
            {
                props.rawErrors?.length > 0 &&
                <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
            }
        </div>
    )
}