import { apiService } from '../../common/apiService/service';
import { apiRequest } from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';
export default class LocationService {

    getAreasFetch() {
        return apiRequest({
            url: `/b2b/v1/areas`,
            method: "GET",
        })
    }

    getLocationsFetch(payload = {}) {
        return apiRequest({
            url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`,
            method: "POST",
            data: {
                filters: {
                    ...payload?.filters,
                }
            }
        })
    }

    fetchCustomersData(params) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/fetch?view=${params.view}`;
        return apiRequest({
            url,
            method: "GET"
        })
    }

    getVendorsFetch() {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/vendors`;
        return apiRequest({
            url,
            method: "GET"
        })
    }

    getlocationById(params) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/${params}`;
        return apiRequest({
            url,
            method: "GET",
            showMessage: true
        })
    }
    getLocationDetails(params) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locationDetails/${params}`;
        return apiRequest({
            url,
            method: "GET"
        })
    }

    createLocation(location) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations`;
        return apiRequest({
            url,
            method: "POST",
            data: location
        })
    }

    updateLocation(locationId, location) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/${locationId}`;
        return apiRequest({
            url,
            method: "PUT",
            data: location
        })
    }

    createLocationV2(location) {
        const url = `/b2b/v2/partners/${Constants.PARTNER_ID}/locations`;
        return apiRequest({
            url,
            method: "POST",
            data: location
        })
    }

    updateLocationV2(locationId, location) {
        const url = `/b2b/v2/partners/${Constants.PARTNER_ID}/locations/${locationId}`;
        return apiRequest({
            url,
            method: "PUT",
            data: location
        })
    }

    getlocationByGroup(payload = {}) {
        return apiRequest({
            url: `/b2b/v1/partners/${Constants.PARTNER_ID}/location-groups/fetch`,
            method: "POST",
            data: {
                filters: {
                    ...payload?.filters,
                }
            }
        })
    }

    deleteLocation(locationId) {
        return apiRequest({
            url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/${locationId}`,
            method: "DELETE",
        })
    }

    getLocations(params = {}) {
        if (!params.hasOwnProperty("loggedInUserTokken")) {
            params["loggedInUserTokken"] = true;
        }
        let partnerId = Constants.PARTNER_ID;
        if (params && params["partnerId"]) {
            partnerId = params["partnerId"];
        }
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/`;
        return apiRequest({
            url,
            method: "GET",
            params
        });
    }

    getPickLocations(params) {
        if (!params.hasOwnProperty("loggedInUserTokken")) {
            params["loggedInUserTokken"] = true;
        }
        let partnerId = Constants.PARTNER_ID;
        const url = `/b2b/v1/partners/${partnerId}/locations/?entityType=${params.entityType}&entityId=${params.entityId}&view=${params.view}&loggedInUserTokken=true`;
        return apiRequest({
            url,
            method: "GET"
        });
    }



    getLocations_v2(params = {}) {
        if (!params.hasOwnProperty("loggedInUserTokken")) {
            params["loggedInUserTokken"] = true;
        }
        if (!params["entityId"]) {
            params["entityId"] = Constants.CURRENT_USER_ID;
        }
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/location_by_entityid`;
        return apiRequest({
            url,
            method: "GET",
            params
        });
    }

    getLocation(locationId) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID
            }/locations/${locationId}`;
        return apiRequest({
            url,
            method: "GET"
        });
    }

    getLocationsByChildList(params) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations`;
        return apiRequest({
            url,
            method: "GET",
            params
        });
    }

    fetchLocations(payload = {}, partnerId = Constants.PARTNER_ID) {
        const url = `/b2b/v1/partners/${partnerId}/locations/fetch`;
        return apiRequest({
            url,
            method: 'POST',
            data: payload
        })
    }
    createCustomerPromiseTimeRules(payload = {}) {
        const url = `/b2b/v1/customerPromiseTime/rules`;
        return apiRequest({
            url,
            method: 'POST',
            data: payload
        })
    }
    getCustomerPickupLocations(payload = {}) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID
            }/location-groups/fetch`;
        return apiRequest({
            url,
            method: 'POST',
            data: payload
        })
    }

    fetchAddresses(query) {
        const url = ` https://nominatim.openstreetmap.org/search?format=json&limit=20&q=${query}&country=india`;
        return fetch(url).then(response => {
            return response.json();
        });
    }
    getTestLocations() {
        const url = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=test&inputtype=textquery&fields=name&key=AIzaSyBV_gUgzZyRxqspe6gNnFxIrK7GElIDMxY`;
        return fetch(url).then(res => res.json());
    }

    getCompleteData(data) {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=AIzaSyBV_gUgzZyRxqspe6gNnFxIrK7GElIDMxY`;
        return fetch(url).then(res => res.json());
    }

    getPartnersList() {
        return apiService.get("/b2b/v1/partners/fetch/all")
    }

    getCustomerList() {
        return apiService.get("/b2b/v1/customer/fetch/all")
    }

    createLocationOnboarding(payload) {
        return apiService.post("b2c/v1/entity-onboarding/partner-location", payload)
    }

    updateLocationV3(payload = {}, locationId) {
       return apiService.put(`hub/entity/update-location/${locationId}`, payload)
    }

    fetchStaffPayCities() {
        return apiService.get("/b2b/v2/staffPayCities")
    }

    updateVendorForPartner(payload = {}, partnerId) {
        return apiService.put(`hub/entity/${partnerId}/vendor/update`, payload)
    }
    
    updateLocConnectionDetails(payload = {}, locationId) {
        return apiService.put(`hub/entity/${locationId}/connection/update`, payload)
    }

    fetchPartnerVendorList(partnerId) {
        return apiService.get(`hub/entity/${partnerId}/get-all-vendor`)
    }
}
