import React, { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import {
    Button,
    Icon,
} from "antd";

import { LabelSticker } from  "../../inventory/label-print/components/label-sticker/label-sticker";

function PrintButton({entityData, buttonText, isIconButton=false, isDisabled=false, isAutoPrintEnabled=false, isPrintTriggered=false, onAfterPrint}) {
    const ref = useRef();
    const handlePrint = useReactToPrint({
        content: () => ref.current,
        onAfterPrint : typeof onAfterPrint === "function" ? onAfterPrint : _ => {}
    });

    useEffect(() => {
        if((entityData && isAutoPrintEnabled) || (entityData && isPrintTriggered)){
            handlePrint();
        }
    }, [entityData, isAutoPrintEnabled, isPrintTriggered === true]);

    return(
        <>
            <div style={{display: "none"}}>
                {
                    entityData && (
                        <LabelSticker ref={ref} entityData={entityData} />
                    )
                }
            </div>
            
            {
                isIconButton ? (
                    <Icon
                        onClick={handlePrint}
                        shape="circle-outline"
                        type="printer"
                        theme="twoTone"
                        disabled={isDisabled}
                    />
                ) : (
                    <Button 
                        onClick={handlePrint}
                        type="primary"
                        size="default"
                        disabled={isDisabled}
                    >{buttonText}</Button>
                )
            }
            
        </>
    )
};

export default PrintButton;