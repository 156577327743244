import moment from "moment";
import TripsView from "./trips.view";

export default function InboundTripsV2() {

    const inboundColumns = [
        {
            title: "Started Time",
            dataIndex: "tripDispatchTime",
            key: "tripDispatchTime",
            render: (data) => data ? moment(data).format("YYYY-MM-DD hh:mm A") : <>--</>
        },
        {
            title: "Completed Time",
            dataIndex: "tripCompleteTime",
            key: "tripCompleteTime",
            render: (data) => data ? moment(data).format("YYYY-MM-DD hh:mm A") : <>--</>
        },
        {
            title: "Incoming Manifests",
            dataIndex: "manifestCount",
            key: "manifestCount"
        },
        {
            title: "Incoming Shipments",
            dataIndex: "shipmentCount",
            key: "shipmentCount",
            render: (data) => {
                if (data !== undefined) {
                    return <>{data}</>
                }
                else {
                    return (
                        <div className="flex-box align-items-center justify-content-center">
                            <div className="spinning-loader"></div>
                        </div>
                    )
                }
            }
        }
    ]

    return <TripsView tripFetchType={"INBOUND_TRIP"} columns={inboundColumns}/>
}