import { Button, Icon, Input, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import LoaderService from "../../../services/loader";
import { SoundNotificationService } from '../../../services/sound.service';
import { destroyNotification } from "../../ui/notification/Notification";
import ScanListInput from "../../ui/scan-list-input";
import { fetchPaginationObject, notifyRemoveSuccess, notifyScanSuccess } from "../../ui/scan-list/utils";
import "../connection-sheet/create-connection/index.scss";
import lastScanConnection from "./last.scan.connection";
import * as outboundActions from "./service";

const soundService = new SoundNotificationService();

export default function OutboundLoad(props) {

    const listId = "connectionFromInbound";
    const [updatingConnections, SetUpdatingConnections] = useState(false);
    const [pendingConnectionsInfo, SetPendingConnectionsInfo] = useState({});
    const [scannedConnectionsInfo, SetScannedConnectionsInfo] = useState({});
    const [lastScannedConnection, SetLastScannedConnection] = useState(undefined);
    const [tripInfo, SetTripInfo] = useState(undefined);
    const loader = useSelector(state => state.loaderReducer?.loader)
    const config = useSelector(state => state?.app?.configurations)

    useEffect(_ => {
        if (tripInfo?.tripCode) {
            getPaginationPendingConnections();
            getPaginationScannedConnections();
        }
    }, [tripInfo?.tripCode])

    useEffect(_ => {
        LoaderService.toggleLoaderOn();
        if (props?.match?.params?.tripCode) {
            if (config?.enableRearch?.outboundTripReadOps === 'true') {
                outboundActions.fetchSingleTrip(props?.match?.params?.tripCode)
                    .then(response => {
                        SetTripInfo(response);
                        LoaderService.toggleLoaderOff();
                    })
                    .catch(e => console.error(e))
            } else {
                outboundActions.fetchOutboundTripByCode(props?.match?.params?.tripCode)
                    .then(response => {
                        if (response.trips[0] === undefined) {
                            notifyApiError("Trip not found!", props?.match?.params?.tripCode)
                            return props.history.push("/appv2/trips/dashboard/outbound");
                        }
                        SetTripInfo(response.trips[0]);
                        LoaderService.toggleLoaderOff();
                    })
                    .catch(r => LoaderService.toggleLoaderOff())
            }
        }
    }, [])

    const getPaginationPendingConnections = params => new Promise((resolve, reject) => {

        SetUpdatingConnections(true);
        // config?.enableRearch?.outboundTripReadOps
        if (config?.enableRearch?.outboundTripReadOps === 'true') {

            const reqBody = {
                tripCode: tripInfo?.tripCode,
                pageNo: params && params.pageNo ? params.pageNo : 1,
                pageSize: 10,
                connectionStatus: ["PENDING"]
            }

            if (params?.connectionCodeForSearch) {
                reqBody['connectionCode'] = params.connectionCodeForSearch
            }

            outboundActions.fetchTripConnList(reqBody)
                .then(response => {
                    SetPendingConnectionsInfo(formatConnResponse(response))
                    resolve(response)
                    SetUpdatingConnections(false);
                })
                .catch(err => {
                    console.error("Error while fetching trip pending connection", err)
                    SetUpdatingConnections(false);
                    reject(err);
                })

        } else {
            outboundActions.fetchPendingConnections(tripInfo?.tripId, params ? params : {})
                .then(response => {
                    if (response.currentPageNo > response.maxPage) {
                        getPaginationPendingConnections({ pageNo: response.maxPage })
                            .then(scannedManifests => {
                                SetPendingConnectionsInfo(scannedManifests);
                                resolve(scannedManifests);
                            })
                    }
                    else {
                        SetPendingConnectionsInfo(response);
                        resolve(response)
                    }
                    SetUpdatingConnections(false);
                })
                .catch(exp => {
                    SetUpdatingConnections(false);
                    reject(exp);
                })
        }
    })

    const getPaginationScannedConnections = params => new Promise((resolve, reject) => {
        if (config?.enableRearch?.outboundTripReadOps === 'true') {

            const reqBody = {
                tripCode: tripInfo?.tripCode,
                pageNo: params && params.pageNo ? params.pageNo : 1,
                pageSize: 10,
                connectionStatus: ["LINKED"]
            }

            if (params?.connectionCodeForSearch) {
                reqBody['connectionCode'] = params.connectionCodeForSearch
            }

            outboundActions.fetchTripConnList(reqBody)
                .then(response => {
                    SetScannedConnectionsInfo(formatConnResponse(response))
                    resolve(response)
                })
                .catch(err => {
                    console.error("Error while fetching trip pending connection", err)
                    reject(err);
                })

        } else {
            SetUpdatingConnections(true);
            outboundActions.fetchScannedConnections(tripInfo?.tripId, params ? params : {})
                .then(response => {
                    if (response.currentPageNo > response.maxPage) {
                        getPaginationScannedConnections({ pageNo: response.maxPage })
                            .then(scannedManifests => {
                                SetScannedConnectionsInfo(scannedManifests);
                                resolve(scannedManifests);
                            })
                    }
                    else {
                        SetScannedConnectionsInfo(response);
                        resolve(response)
                    }
                    SetUpdatingConnections(false);
                })
                .catch(exp => {
                    SetUpdatingConnections(false);
                    reject(exp);
                })
        }
    })

    const formatConnResponse = (response) => {

        if (!response) {
            return {}
        }

        const formattedRes = {
            totalCount: response.totalCount,
            connectionSheets: response.connectionDetails,
            maxPage: response.maxPage,
            currentPageNo: response.currentPageNo
        }

        return formattedRes

    }

    const columns = [
        {
            title: "Connection ID",
            dataIndex: "connectionCode"
        },
        {
            title: "Manifest Count",
            dataIndex: "manifestCount"
        }
    ]

    const updateLists = async _ => {
        await Promise.all([
            getPaginationPendingConnections({ pageNo: pendingConnectionsInfo.currentPageNo }),
            getPaginationScannedConnections({ pageNo: scannedConnectionsInfo.currentPageNo })
        ])
        SetUpdatingConnections(false);
    }

    const linkConnection = data => new Promise((resolve, reject) => {
        if ((typeof data === "string" && data.trim() === "") || typeof data === "undefined") {
            soundService.playGeneralWarning();
            return notifyApiError("Connection ID is mandatory!");
        }

        destroyNotification();

        if (config?.enableRearch?.outboundTripWriteOps === 'true') {

            SetUpdatingConnections(true);

            const reqBody = {
                tripCode: tripInfo?.tripCode,
                connectionCode: typeof data === "string" ? data : data.connectionCode
            }

            outboundActions.linkConnection(reqBody)
                .then(response => {
                    notifyScanSuccess("connection");
                    const formattedResponse = {
                        awbCount: response.waybillCount,
                        connectionCode: response.connectionCode,
                        connectionStatus: response.connectionStatus,
                        destinationLocation: response.connectionDestinationLocation,
                        manifestCount: response.manifestCount
                    }
                    SetLastScannedConnection(formattedResponse);
                    SetUpdatingConnections(false)
                    resolve(formattedResponse);
                })
                .catch(e => {
                    console.error(e)
                    SetUpdatingConnections(false)
                    reject(e)
                })

        } else {
            SetUpdatingConnections(true);

            outboundActions.connectiontionToTrip(tripInfo?.tripId, typeof data === "string" ? data : data.connectionCode)
                .then(response => {
                    notifyScanSuccess("connection");
                    SetLastScannedConnection(response);
                    resolve(response);
                })
                .catch(exp => {
                    soundService.playWarning();
                    SetUpdatingConnections(false);
                    reject(exp)
                })
        }
    })


    const delinkConnection = data => new Promise((resolve, reject) => {
        destroyNotification();

        if (config?.enableRearch?.outboundTripWriteOps === 'true') {

            SetUpdatingConnections(true);
            outboundActions.delinkConnectionV2(tripInfo?.tripCode, data.connectionCode)
                .then(response => {
                    notifyRemoveSuccess("connection")
                    SetUpdatingConnections(false);
                    resolve(response);
                })
                .catch(e => {
                    console.error(e)
                    reject(e)
                    SetUpdatingConnections(false);
                })

        } else {
            SetUpdatingConnections(true);

            outboundActions.delinkConnection(tripInfo?.tripId, data.connectionCode)
                .then(response => {
                    notifyRemoveSuccess("connection");
                    resolve(response);
                })
                .catch(exp => {
                    soundService.playWarning();
                    reject(exp)
                    SetUpdatingConnections(false);
                })
        }
    })

    const loadTrip = _ => {
        if (scannedConnectionsInfo?.connectionSheets && scannedConnectionsInfo?.connectionSheets?.length > 0) {
            Modal.confirm({
                title: "Are you sure to load this trip?",
                okText: "Yes",
                cancelText: "No",
                onOk: _ => {

                    if (config?.enableRearch?.outboundTripWriteOps === 'true') {

                        outboundActions.loadTripV2(tripInfo?.tripCode)
                            .then(_ => {
                                Modal.destroyAll();
                                notifyApiSuccess("Load trip success!", tripInfo?.tripCode);
                                props.history.push("/appv2/trips/dashboard/outbound");
                            })
                            .catch(e => console.error(e))

                    } else {
                        outboundActions.loadTrip(tripInfo?.tripId)
                            .then(_ => {
                                Modal.destroyAll();
                                notifyApiSuccess("Load trip success!", tripInfo?.tripCode);
                                props.history.push("/appv2/trips/dashboard/outbound");
                            })
                    }
                },
                onCancel: _ => {
                    Modal.destroyAll();
                }
            })
        }
        else {
            return Modal.warn({
                title: "Cannot load trip with no connection sheet!"
            })
        }
    }

    const cancelLoadAdd = _ => {
        Modal.confirm({
            title: "Are you sure to cancel load trip?",
            onOk: _ => {
                Modal.destroyAll();
                props.history.push("/appv2/trips/dashboard/outbound");
            }
        })
    }

    return (
        <Spin spinning={loader} className="flex-column flex-gap-l">
            <div className="white-bg border-grey flex-column flex-gap-xl spacer-m create-connection-header">
                <div className="flex-box justify-content-space-between align-items-center">
                    <div className="flex-box align-items-center flex-gap-m">
                        <Icon type="arrow-left" onClick={cancelLoadAdd} />
                        <div className="font-size-l-1 text-bold"> Add Load </div>
                    </div>
                    {
                        props?.match?.params?.tripCode &&
                        <div className="flex-box flex-gap-m align-items-center">
                            <Button onClick={cancelLoadAdd} className="lsn-btn-secondary">
                                Cancel
                            </Button>
                            <Button onClick={loadTrip} type="primary" className="lsn-btn-primary">
                                Update Trip
                            </Button>
                        </div>
                    }
                </div>
                <div className="flex-box flex-gap-l">
                    <div className="flex-1 flex-box align-items-center" >
                        <div className="flex-1"> Trip ID </div>
                        <Input className="flex-6" disabled={true} value={tripInfo?.tripCode} />
                    </div>
                    <div className="flex-1 flex-box align-items-center" >
                        <div className="flex-1"> Route </div>
                        <Input className="flex-6" disabled={true} value={tripInfo?.routeName} />
                    </div>
                </div>
            </div>
            {
                tripInfo?.tripCode && pendingConnectionsInfo.connectionSheets !== undefined &&
                <Spin spinning={updatingConnections}>
                    <ScanListInput listId={listId} showCount={true} entityType={"Connection Sheet"} enableSound={true}

                        pendingColumns={[...columns]}
                        pendingList={pendingConnectionsInfo.connectionSheets}
                        pendingCount={pendingConnectionsInfo?.totalCount}
                        pendingPagination={fetchPaginationObject(pendingConnectionsInfo, pageNo => getPaginationPendingConnections({ pageNo: pageNo }), "Connection Sheet")}
                        beforeAdd={linkConnection}
                        pendingTableProps={{ scroll: { y: 120 } }}
                        enablePendingSearch={true}
                        onPendingSearch={searchText => getPaginationPendingConnections({ connectionCodeForSearch: searchText })}
                        onAdd={updateLists}
                        skipAdd={true}

                        scannedColumns={[...columns]}
                        scannedList={scannedConnectionsInfo.connectionSheets}
                        scannedCount={scannedConnectionsInfo?.totalCount}
                        scannedPagination={fetchPaginationObject(scannedConnectionsInfo, pageNo => getPaginationScannedConnections({ pageNo: pageNo }), "Connection Sheet")}
                        beforeRemove={delinkConnection}
                        scannedTableProps={{ scroll: { y: 120 } }}
                        enableScannedSearch={true}
                        onScannedSearch={searchText => getPaginationScannedConnections({ connectionCodeForSearch: searchText })}
                        onRemove={updateLists}
                        skipRemove={true}

                        onInputScan={linkConnection}
                        inputHeader={"Scan Connection Sheet"}
                        inputPlaceholder={"Enter Connection Sheet ID to scan"}
                        lastScanBlock={lastScanConnection({ lastScannedConnection: lastScannedConnection })} />
                </Spin>
            }
        </Spin>
    )
}