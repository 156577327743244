import _ from "lodash";
import { apiUrl } from "../../environments/node-react-environment";
import { Constants, DownloadTags } from "./constants";
import { checkHttpStatus } from "./utils";
import axios from "axios";

let singletonAxios;

export function apiRequest(options = {}) {
  
  const axios = getAxiosInstance(options);
  axios.interceptors.response.use(
    response => handleAxiosResponseSuccess(response, options.showMessage !== undefined ? options.showMessage : true, options.ignoreStatus),
    error => handleAxiosResponseError(error, options?.url)
  );


  return axios(options);
}

function handleAxiosResponseSuccess(response, showMessage, ignoreStatus) {
  if ((_.hasIn(response, "data") && _.hasIn(response?.data, "status") &&
      checkHttpStatus(response?.data?.status, showMessage)) || response?.data?.length > 0 || ignoreStatus === true) {
    return response;
  }
  else {
    return false;
  }
}

function handleAxiosResponseError(error, url) {
  return Promise.reject(error);
}

function getAxiosInstance(options) {
  if (options.overrideHeader) {
    singletonAxios = axios.create({
      baseURL: getBaseURL(options),
      timeout: 1000 * Constants.HEADER_TIMEOUT_MS,
      headers: options.overrideHeader
    });
  } else {
    singletonAxios = axios.create({
      baseURL: getBaseURL(options),
      timeout: 1000 * Constants.HEADER_TIMEOUT_MS,
      headers: Constants.HTTP_HEADERS
    });
  }
  singletonAxios.defaults.timeout = 600000;
  return singletonAxios;
}

function getBaseURL(options) {
  let baseUrl = apiUrl;
  if (options && options.hasOwnProperty("baseUrlType")) {
    switch (options.baseUrlType) {
      default:
        baseUrl = apiUrl;
    }
  }
  return baseUrl;
}

export function getPostFilterPayload() {
  return {
    filters: {
      pageNo: 0,
      pageSize: 10,
      totalCount: 0
    }
  };
}

export function thirdPartyGet(path, type) {
  const url = `${path}`;
  if (type === DownloadTags.type) {
    return axios({ url, method: "GET" });
  }
}
