import { apiRequest } from "../../../common/utils/apiGateway";
import { checkHttpStatus } from "../../../common/utils/utils";

export const updateCgnToTamperedStatus = (payload) => {

    return apiRequest({
        url: "/b2c/v1/exceptions/tampered",
        method: "POST",
        data: payload,
        showMessage: true
    })

}