import * as CONSTANTS from './constants';
import * as apiUtils from '../../../common/utils/apiGateway';
import { Constants } from '../../../common/utils/constants'
import { checkHttpStatus } from '../../../common/utils/utils';
import {apiService} from "../../../common/apiService/service";


export const getReportTypes = (params) => dispatch => {
    dispatch(reportTypeBegin());
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/reports/getConfig`,
        data: params
    })
        .then(response => {
            dispatch(reportTypeSuccess(response.data.response))
        })
        .catch(error => dispatch(reportTypeFailure(error)));
}
export const getCustomerList = () => dispatch => {
    dispatch(customerListBegin());
    const data = {
        "filters": {                           
          "customerTypes": [
            "CUSTOMER"
          ],
          "status": [
            "ACTIVE"
          ],
          "fetchMode": "BASIC",
          "partnerIds": [
            Constants.PARTNER_ID
          ]
        }
      }
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/post/fetch`,
        data
    })
        .then(response => {
            dispatch(customerListSuccess(response?.data?.response))
        })
        .catch(error => dispatch(customerListFailure(error)));
}

export const getLocationList = (data) => dispatch => {

    dispatch(locationListBegin());
    apiUtils.apiRequest({
        method: 'POST',
        data,
        url: `b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`,
    })
        .then(response => {
            dispatch(locationListSuccess(response.data.response.locations))
        })
        .catch(error => dispatch(locationListFailure(error)));
}

export const getAllLocations = (data) => {
    const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`;
    return apiUtils.apiRequest({
        url,
        method: "POST",
        data
    });
}

export const getPartnerList = () => dispatch => {
    dispatch(partnerListBegin());
    dispatch(partnerListSuccess(JSON.parse(atob(localStorage.getItem('_partnerDetails')))))
}

export const ingestApiCall = (url, payload) => dispatch =>{
    dispatch(customApiCallBeign());
    apiService.post(url, payload).then(response => {
        dispatch(customApiCallSuccess(response))
    }).catch(error=>{
        dispatch(customApiCallFailure(error))
    });
}

export const customUrlApiCall = (url, method, data) => dispatch => {
    dispatch(customApiCallBeign());
    apiUtils.apiRequest({
        method: method,
        url: url,
        data

    })
        .then(response => {
            if (checkHttpStatus(response?.data?.status)) {
                dispatch(customApiCallSuccess(response.data))
            } else {
                dispatch(customApiCallFailure(response.data));
            }
        })
        .catch(error => dispatch(customApiCallFailure(error)));
}

export const uploadIngestFile = (report_type, data) => dispatch => {
    dispatch(uploadReportFileBegin());
    apiUtils.apiRequest({
        method: 'post',
        url: `b2b/v1/uploads/ingest/${report_type}/execute`,
        data
    })
        .then(response => {
            dispatch(uploadReportFileSucces(response.data.response))
        })
        .catch(error => dispatch(uploadReportFileFailure(error)));
}

export const getEmplyeesList = () => dispatch => {

    dispatch(employeesListBegin());
    apiUtils.apiRequest({
        method: 'get',
        url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/${Constants.userLocationId}/users`
    })
        .then(response => {
            dispatch(employeesListSuccess(response.data.response.users))
        })
        .catch(error => dispatch(employeesListFailure(error)));
}

function reportTypeBegin() {
    return {
        type: CONSTANTS.GET_REPORT_TYPE_BEGIN,
        payload: null
    }
}

function reportTypeSuccess(response) {
    return {
        type: CONSTANTS.GET_REPORT_TYPE_SUCCESS,
        payload: response.reportTypes
    }
}

function reportTypeFailure(response) {
    return {
        type: CONSTANTS.GET_REPORT_TYPE_FAILURE,
        payload: response
    }
}

function uploadReportFileBegin() {
    return {
        type: CONSTANTS.POST_UPLOAD_REPORT_TYPE_FILE_BEGIN,
        payload: null
    }
}

function uploadReportFileSucces(resp) {
    return {
        type: CONSTANTS.POST_UPLOAD_REPORT_TYPE_FILE_SUCCESS,
        payload: resp
    }
}

function uploadReportFileFailure(resp) {
    return {
        type: CONSTANTS.POST_UPLOAD_REPORT_TYPE_FILE_FAILURE,
        payload: resp
    }
}

function customerListBegin() {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_BEGIN,
        payload: null
    }
}

function customerListSuccess(resp) {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_SUCCESS,
        payload: resp
    }
}

function customerListFailure(resp) {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_FAILURE,
        payload: null
    }
}

function locationListBegin() {
    return {
        type: CONSTANTS.GET_LOCATION_LIST_BEGIN,
        payload: null
    }
}

function locationListSuccess(resp) {
    return {
        type: CONSTANTS.GET_LOCATION_LIST_SUCCESS,
        payload: resp
    }
}

function locationListFailure(resp) {
    return {
        type: CONSTANTS.GET_LOCATION_LIST_FAILURE,
        payload: null
    }
}

function customApiCallBeign() {
    return {
        type: CONSTANTS.GET_CUSTOM_API_CALL_BEGIN,
        payload: null
    }
}

function customApiCallSuccess(resp) {
    return {
        type: CONSTANTS.GET_CUSTOM_API_CALL_SUCCESS,
        payload: resp
    }
}


function customApiCallFailure(resp) {

    return {
        type: CONSTANTS.GET_CUSTOM_API_CALL_FAILURE,
        payload: resp
    }
}


function partnerListBegin() {
    return {
        type: CONSTANTS.GET_PARTNER_LIST_BEGIN,
        payload: null
    }
}

function partnerListSuccess(resp) {
    return {
        type: CONSTANTS.GET_PARTNER_LIST_SUCCESS,
        payload: resp
    }
}

function employeesListBegin() {
    return {
        type: CONSTANTS.GET_EMPLOYEES_LIST_BEGIN,
        payload: null
    }
}

function employeesListSuccess(resp) {
    return {
        type: CONSTANTS.GET_EMPLOYEES_LIST_SUCCESS,
        payload: resp
    }
}

function employeesListFailure(resp) {
    return {
        type: CONSTANTS.GET_EMPLOYEES_LIST_FAILURE,
        payload: null
    }
}

export const getReportListing = (data) => dispatch => {
    dispatch({
        type: CONSTANTS.GET_REPORT_LISTING_BEGIN
    });
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/reports/getReportsList`,
        data
    })
        .then(response => {
            dispatch({
                type: CONSTANTS.GET_REPORT_LISTING_SUCCESS,
                payload: response.data.response
            })
        })
        .catch(error => dispatch({
            type: CONSTANTS.GET_REPORT_LISTING_FAILURE,
            payload: "Something went wrong"
        }));
}