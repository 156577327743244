import { Alert, Button, Icon, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { apiRequest } from "../../../../common/utils/apiGateway";
import { Constants } from "../../../../common/utils/constants";
import { notifyApiError, notifyApiSuccess } from "../../../../common/utils/utils";
import { fetchInboundTripByCode } from "../../../trips/outbound-trips/service"
import ManifestUnloadSparkline from "../sparkline";
import * as tripClosureActions from "./service";
import TripManifests from "./trip.manifests";
import { SoundNotificationService } from "../../../../services/sound.service";
import LastScanManifestBlock from "../../../trips/connection-sheet/create-connection/last.scan.manifest";
import { TRIP_UPDATE_ACTIONS } from "../../../trips/trips.utils";
import { executeTripAction } from "../../../trips/v2/trips.service";

const soundService = new SoundNotificationService();

export default function TripManifestClosure(props) {

    const isMobile = window.outerWidth < 600 && window.location.pathname.indexOf("mweb") > -1;
    const [ reasons, SetReasons ] = useState(undefined);
    const [ tripData, SetTripData ] = useState(undefined);
    const [ scanning, SetScanning ] = useState(undefined); 
    const [ inputValue, SetInputValue ] = useState(undefined); 
    const [ modalStatus, SetModalStatus ] = useState(undefined);
    const [ errorModalShow, SetErrorModalShow ] = useState(undefined);
    const [ errorModalMsg, SetErrorModalMsg ] = useState(undefined);
    const [ startUnloading, SetStartUnloading ] = useState(undefined);
    const [ scanFailMessage, SetScanFailMessage ] = useState(undefined);
    const [ manifestSummary, SetManifestSummary ] = useState(undefined);
    const [ lastScannedManifest, SetLastScannedManifest ] = useState(undefined);

    const loadSumaryData = tripId => {
        apiRequest({
            url    : "/hydra/v1/trip/fetch/manifest/summary",
            method : "POST",
            data   : {
                locationIds : [ Constants.userLocationId ],
                tripId      : tripId
            }
        })
        .then(summaryresponse => {
            var tempSummary = summaryresponse?.data?.response;
            SetManifestSummary(tempSummary);
            SetStartUnloading(Boolean(tempSummary?.scannedManifestCount) || Boolean(tempSummary?.overageManifestCount));
        })
        .catch(r => {
            console.log(r);
        })
    }

    useEffect(_ => {
        fetchInboundTripByCode(props?.match?.params?.tripCode)
        .then(response => {
            if (response.trips[0] === undefined) {
                soundService.playWarning();
                notifyApiError("Trip not found!", props?.match?.params?.tripCode)
                return props.history.replace("/appv2/inventory/dashboard/manifest-closure")
            }
            loadSumaryData(response.trips[0]?.tripId)
            SetTripData(response.trips[0]);
        })
        .catch(r => {
            soundService.playWarning();
            notifyApiError("Trip not found!", props?.match?.params?.tripCode)
            return props.history.replace("/appv2/inventory/dashboard/manifest-closure")
        })
        tripClosureActions.fetchReasons()
        .then(response => {
            SetReasons(response)
        })
    }, []);

    const onPressEnter = _ => {
        if (inputValue === undefined || inputValue.trim() === "") {
            return notifyApiError("Manifest code is mandatory!");
        }
        SetScanFailMessage(undefined);
        SetLastScannedManifest(undefined);
        SetScanning(true);
        tripClosureActions.unloadManifestTrip(tripData?.tripId, inputValue.trim())
        .then(response => {
            if (response?.status?.code == 500) {
                SetScanFailMessage(response?.status?.message);
                soundService.playWarning();
            }
            else {
                if (response?.response?.bucketName === "OVERAGE" || response?.response?.bucketName === "MISROUTE") {
                    SetLastScannedManifest({
                        code   : inputValue,
                        status : response?.response?.bucketName
                    });
                    soundService.playSuccess();
                }
                else if (response?.response?.bucketName === "SCANNED" && response?.response?.manifests?.[0]) {
                    SetLastScannedManifest(response?.response?.manifests?.[0]);
                    if (response?.response?.manifests?.[0].flowType && response?.response?.manifests?.[0].flowType === "RTO") {
                        soundService.playRTOSuccess();
                    } else {
                        soundService.playForwardSuccess();
                    }
                }
                else {
                    SetLastScannedManifest(response?.response?.message);
                    soundService.playSuccess();
                }
                loadSumaryData(tripData?.tripId);
                notifyApiSuccess("Successfully scanned manifest!");
            }
            SetScanning(false);
            SetInputValue(undefined);
            const scanInput = document.getElementById("scanInput");
            if (scanInput) {
                scanInput.blur();
                scanInput.setAttribute("readonly", "readonly");
                scanInput.focus();
                scanInput.removeAttribute("readonly");
            }
        })
        .catch(exp => {
            soundService.playWarning();
            SetScanning(false);
        })
    }

    const openManifestModal = status => {
        SetModalStatus(status)
    }
    
    const callValidateUnloadTrip = _ => tripClosureActions.validateUnloadTrip(tripData?.tripId).then(response => {
        if (response?.isValidated) {
            notifyApiSuccess(response?.message, tripData?.tripCode);
            if (isMobile) {
                props.history.push("/appv2/mweb/inventory/dashboard/manifest-closure");
            }
            else {
                props.history.push("/appv2/trips/dashboard/inbound");
            }
        }
        else {
            SetErrorModalMsg(response?.message);
            SetErrorModalShow(true);
        }
    })

    const unloadTrip = _ => {
        if(tripData.tripStatus && ["ARRIVED", "COMPLETED"].indexOf(tripData.tripStatus) < 0) {
            const payload = {
                tripId : tripData?.tripId,
                action : TRIP_UPDATE_ACTIONS.MARK_ARRIVED
            };
    
            executeTripAction(payload)
            .then(_ => SetStartUnloading(true))
            .catch(e => console.log(e))
        }
        else {
            SetStartUnloading(true);
        }
    }

    return (
        <>
        <div className="flex-column justify-content-space-between" style={{height : isMobile ? "calc(100vh - 50px)" : undefined}}>
            <div className="flex-column flex-gap-l">
            {
                tripData && !isMobile &&
                <div className="white-bg border-grey flex-column flex-gap-xl spacer-m">
                    <div className="flex-box flex-gap-l">
                        <div className="flex-1 flex-box align-items-center" >
                            <div className="flex-1"> Closure Type </div>
                            <Input className="flex-3" disabled={true} value={"Trip"} />
                        </div>
                        <div className="flex-1 flex-box align-items-center" >
                            <div className="flex-1"> Trip ID </div>
                            <Input className="flex-3" disabled={true} value={tripData?.tripCode} />
                        </div>
                    </div>
                </div>
            }
            {
                tripData &&
                <div className={isMobile ? "manifest-unload-card-body-container mweb-wrapper" :"white-bg border-grey flex-column flex-gap-xl spacer-m"}>
                    {
                        isMobile ?
                        <div className="mainfest-unload-card-info-container">
                            <div className="manifest-unload-card-header">
                                <span>
                                    Trip ID : {tripData?.tripCode}
                                    <span className="manifest-unload-card-flag-span"> Unloading </span>
                                </span>
                                <div style={{color: "#2233A5"}}>
                                    Total No. of manifests -
                                    {manifestSummary?.pendingManifestCount + manifestSummary?.scannedManifestCount}
                                </div>
                            </div>
                        </div> :
                        <div className="flex-box flex-gap-l justify-content-space-between align-items-center">
                            <div className="flex-1 flex-box align-items-center flex-gap-l" >
                                <div className="text-bold"> TRIP ID </div>
                                <div> {tripData?.tripCode} </div>
                                { startUnloading && <div className="info-color"> Unloading </div> }
                            </div>
                            <div className="flex-1 flex-box flex-gap-l align-items-center justify-content-end" >
                                {
                                    startUnloading &&
                                    <Button onClick={callValidateUnloadTrip} disabled={scanning}
                                     type="primary" className="lsn-btn-primary" >
                                        Validate
                                    </Button>
                                }
                                <Button onClick={unloadTrip} disabled={scanning || startUnloading}
                                 type="primary" className="lsn-btn-primary" >
                                    Unload
                                </Button>
                            </div>
                        </div>
                    }

                    <div className={"flex-box flex-gap-l justify-content-space-between align-items-center" + (isMobile ? " manifest-unload-card-sparkline-container" : "")}>

                        <ManifestUnloadSparkline
                         title={"Pending Manifests"}
                         count={manifestSummary?.pendingManifestCount} 
                         handleSparklineClick={_ => openManifestModal("PENDING")}
                         disabled={scanning} isMobile={isMobile}
                        />
                        
                        <ManifestUnloadSparkline
                         title={"Scanned Manifests"}
                         count={manifestSummary?.scannedManifestCount} 
                         handleSparklineClick={_ => openManifestModal("SCANNED")}
                         disabled={scanning} isMobile={isMobile}
                        />

                        <ManifestUnloadSparkline
                         title={"Overage Manifests"}
                         count={manifestSummary?.overageManifestCount} 
                         handleSparklineClick={_ => openManifestModal("OVERAGE")}
                         disabled={scanning} isMobile={isMobile}
                        />

                    </div>

                    {
                        startUnloading &&
                        <div className={"flex-column flex-gap-l" + (isMobile ? " spacer-hs" : "")} style={{marginBottom: 40}}>
                            <div className="font-size-m-3 text-bold"> Scan Manifest Number </div>
                            <Input className="scan-list-input-box" id={"scanInput"}
                             placeholder={"Scan Manifest Number"} value={inputValue}
                             onChange={e => SetInputValue(e.target.value)}
                             onPressEnter={onPressEnter}
                             disabled={scanning} style={{width : isMobile ? "100%" : "50%" }}
                             suffix={
                                <Button onClick={onPressEnter} type="primary" className="lsn-btn-primary"
                                style={{ height: "100%", borderRadius: "0px 3px 3px 0px"}} disabled={scanning}>
                                    <Icon type="barcode" /> Scan
                                </Button>
                             }
                             />
                            { scanFailMessage && <Alert message={scanFailMessage} type="error" /> }
                            { 
                                typeof lastScannedManifest === "string" ?
                                <Alert message={lastScannedManifest} type="success" /> :
                                <LastScanManifestBlock lastScannedManifest={lastScannedManifest} isMobile={isMobile} />
                            }
                        </div>
                    }

                    
                </div>
            }
            </div>
            {
                tripData && isMobile &&
                <div className="spacer-s flex-box">
                    {
                        startUnloading ?
                        <Button onClick={callValidateUnloadTrip} disabled={scanning}
                        type="primary" className="lsn-btn-primary flex-1" >
                            Validate
                        </Button> :
                        <Button onClick={_ => SetStartUnloading(true)} disabled={scanning}
                        type="primary" className="lsn-btn-primary flex-1" >
                            Unload
                        </Button>
                    }
                </div>
            }
        </div>
        {
            modalStatus &&
            <TripManifests closeModal={_ => SetModalStatus(false)} modalStatus={modalStatus} tripId={tripData?.tripId}
                reasons={reasons}  isMobile={isMobile} />
        }
        {
            <Modal
                visible={errorModalShow}
                title={"Validation Unsuccessful"}
                onCancel={_ => SetErrorModalShow(false)}
                footer={null}
                closable
                destroyOnClose
                width="480px"
            >
                <div className="error-dialog-container">
                    <div className="error-dialog-title">
                        {errorModalMsg}
                    </div>

                    <div className="error-dialog-footer">
                        <div className="error-dialog-button">
                            <Button type="primary"
                                block onClick={_ => SetErrorModalShow(false)}>
                                Ok
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        }
        </>
    )
}