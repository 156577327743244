import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom";

export default function Redirect({ path, to}) {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!to) return;
        if ((path && path === location.pathname) || path === undefined) {
            navigate(to);
        }
    }, []);

    return <></>
}