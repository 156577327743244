export const INITIAL_STATE = {
    loading: false,
    error: "",
    consignments: [],
    dataCardList: [],
    paginationData: null
};

export const ACTION_TYPES = {
    FETCH_CONSIGNMENTS_BEGIN: "FETCH_CONSIGNMENTS_BEGIN",
    FETCH_CONSIGNMENTS_SUCCESS: "FETCH_CONSIGNMENTS_SUCCESS_RTO_WAYBILL",
    FETCH_CONSIGNMENTS_FAILURE: "FETCH_CONSIGNMENTS_FAILURE",

    FETCH_SPARK_LINE_DATA__BEGIN: "FETCH_SPARK_LINE_DATA__BEGIN",
    FETCH_SPARK_LINE_DATA__SUCCESS: "FETCH_SPARK_LINE_DATA_SUCCESS",
    FETCH_SPARK_LINE_DATA__FAILURE: "FETCH_SPARK_LINE_DATA__FAILURE",

    RESET_PAGINATED_CACHE_DATA: "RESET_PAGINATED_CACHE_DATA",
};


export const RTO_COLORS = {
    yellow: "#FFB64D"
}
