import React from "react";
import {
    Modal
} from "antd";

const RTODownloadModal = ({
    show,
    shipmentParams,
    handleConfirm,
    handleCancel
}) => {
    const waybillNo = shipmentParams['request'][0]['consignment']['waybillNo'];
    const consignmentStatus= shipmentParams["request"][0]["consignment"]["consignmentStatus"];
   return(
    <div>
        <Modal
          title="PLEASE CONFIRM YOUR ACTION"
          visible={show}
          onOk={handleConfirm}
          onCancel={handleCancel}
        >
          <p>
          Are you sure you want to mark the
          &nbsp;<strong>{waybillNo}</strong>&nbsp;
          shipment as
          &nbsp;<strong>{consignmentStatus}</strong>&nbsp;
          ?
          </p>
        </Modal>
    </div>
   )
};

export default RTODownloadModal;
