const statusToTextMap = {
    RTODEL   : "RTO DELIVERED",
    RTOUNDEL : "RTO UNDELIVERED"
}

export const transformRtoDrsList = response => {
    var customDRSObject = response.rtoDeliveryRunSheets.map(rtoDrs => {
        if (rtoDrs.consignments && rtoDrs.consignments.length > 0) {
            rtoDrs.consignments = rtoDrs.consignments.map(consignment => {
                return {
                    ...consignment.consignment,
                    consignmentStatus : consignment?.consignment?.podStatus ? (statusToTextMap[consignment?.consignment?.podStatus] || consignment?.consignment?.podStatus) : consignment?.consignment?.status
                }
            })
        }
        return {
            ...rtoDrs,
            id      : rtoDrs.rtoDrsId,
            drsCode : rtoDrs.rtoDrsCode,
            drsUser : {
                ...rtoDrs.rtoDrsUser,
                contactNumber : rtoDrs.rtoDrsUser.number
            },
            drsStatus      : rtoDrs.rtoDrsStatus,
            customObj: {
                drsStatus      : rtoDrs.rtoDrsStatus,
                delCount       : rtoDrs.delCount,
                shipmentsCount : rtoDrs.waybillCount,
                totalUnDel     : rtoDrs.undelCount,
                totalDel       : rtoDrs.delCount,
                drsPodAllowed  : false,
                consignmentsCount : rtoDrs.waybillCount
            }
        };
    });
    var totalCount = response.totalCount;
    var pageSize   = response.pageSize;
    var pageNo     = response.pageNo;
    return {
        maxPage : parseInt(totalCount/pageSize) + (totalCount % pageSize > 0 ? 1 : 0),
        offset  : (pageSize * (pageNo - 1)) + 1,
        limit   : pageSize,
        drses   : customDRSObject,
        totalCount      : totalCount,
        currentPageNo   : pageNo,
        currentPageSize : pageSize
    }
}

export const formatRtoDrsData = (rtoDrsData) => {

    const formattedData = {}

    formattedData["drsCode"] = rtoDrsData.rtoDrsCode
    formattedData["id"] = rtoDrsData.rtoDrsId
    formattedData["drsStatus"] = rtoDrsData.rtoDrsStatus
    formattedData["drsUser"] = rtoDrsData.rtoDrsUser

    if (rtoDrsData?.consignments?.length > 0) {
        formattedData["consignments"] = rtoDrsData.consignments
    }

    return formattedData;

}