import * as CONSTANTS from './constant';

export const trackingListingReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSTANTS.DOWNLOAD_SEARCHED_WAYBILLS_BEGIN: return downloadWaybillsBegin(state);
        case CONSTANTS.DOWNLOAD_SEARCHED_WAYBILLS_SUCCESS: return downloadWaybillsSuccess(state, action);
        case CONSTANTS.DOWNLOAD_SEARCHED_WAYBILLS_FAILURE: return downloadWaybillsFailure(state);
        case CONSTANTS.FILTER_WAYBILLS: return filterRecords(state, action);
        default: return state;
    }
}

function downloadWaybillsBegin(state) {
    return {
        ...state,
        downloadWaybillsLoading: true
    }
}

function downloadWaybillsSuccess(state, action) {
    return {
        ...state,
        downloadWaybillsLoading: false,
        downloadWaybillsUrl: action.payload,
        downloadWaybillError: undefined
    }
}

function downloadWaybillsFailure(state, action) {
    return {
        ...state,
        downloadWaybillsLoading: false,
        downloadWaybillError: action.payload
    }
}

function filterRecords(state, action) {

    let filteredTableData = [];

    if (action && action.payload && action.payload.fullTableData) {
        let val = action.payload.searchInput.toLowerCase();
        if (val) {
            val = val.trim();
        }
         // filter our data
        filteredTableData = action.payload.fullTableData.filter((item) => {
            return item[action.payload.searchField].toLowerCase().indexOf(val) !== -1 || !val;
        });
    }
    return {
        ...state,
        filteredTableData
    }
}