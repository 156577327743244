import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Constants, FlowTypes } from "../../../common/utils/constants";
import MetabaseReport from "../../operations/components/metabase.report";
import { getConsignmentsData, getSparkLineDataFunction, resetPaginatedCacheData } from "./action";
import WaybillSparkLine from "./waybill-spark-line";
import WaybillTable from "./waybill-table";

const RTOWaybill = ({
    loading, 
    consignments, 
    getConsignmentsFunction, 
    PaginatedWaybill,
    stateData,
    resetPaginatedCacheData
}) => {
    const [state, setState] = useState({
        filterPayload: {
            filters: {
                pageNo: 1,
                pageSize: 10,
                totalCount: 0,
                is_arrived: true,
                consignments: []
            }
        },
        pageNo: 1,
        flowTypes: FlowTypes.RTO
    })

    const applyPagination = (filters, isReset = false, isSearched = false) => {
        if (isReset) {
            setState({
                ...state,
                pageNo: 1
            })
        }

        const filter = { ...state.filterPayload.filters, ...filters, totalCount: consignments?.totalCount || 0 }

        if (state.flowType === FlowTypes.RTO) {
            filter["flowTypes"] = [FlowTypes.RTO];
        } else {
            filter["flowTypes"] = [FlowTypes.RTO, FlowTypes.RTS];
        }

        getConsignmentsFunction({ filters: filter }, isReset);
    }

    const getPaginatedData = (pageNo) => {
        applyPagination({ pageNo: pageNo }, false)

        setState({
            ...state,
            pageNo
        });
    }

    useEffect(() => {
        if (!Constants.isProdEnv) {
            if(PaginatedWaybill) {
                resetPaginatedCacheData();
            };
            applyPagination({ pageNo: 1 }, true)
        }
    }, []);
    return (
        <div>
            <Spin spinning={loading}>
                <div className="flex-box flex-column flex-gap-l">
                    <WaybillSparkLine />
                    {
                        Constants.isProdEnv ?
                        <MetabaseReport hideBackButton={true} reportName={"rto-waybill"} /> :
                        <WaybillTable
                            data={stateData}
                            getPaginatedData={getPaginatedData}
                            pageNo={state.pageNo}
                            PaginatedWaybill={PaginatedWaybill}
                        />
                    }
                </div>
            </Spin>
        </div>
    )
};

const mapStateToProps = (state) => ({
    loading: state.rtoWaybill.loading,
    consignments: state.rtoWaybill.consignments,
    paginationData: state.rtoWaybill.paginationData,
    dataCardList: state.rtoWaybill.dataCardList,
    stateValue: state.rtoWaybill,
    PaginatedWaybill: state.rtoWaybill.PaginatedWaybill,
    stateData: state.rtoWaybill
});

const mapDispatchToProps = dispatch => ({
    getConsignmentsFunction: (payload, isReset) => dispatch(getConsignmentsData(payload, isReset)),
    getSparkLineDataFunction: () => dispatch(getSparkLineDataFunction()),
    resetPaginatedCacheData: () => dispatch(resetPaginatedCacheData()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RTOWaybill);