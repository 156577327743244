export default function SummaryData({ data }) {
    return (
        <div className="flex-box flex-gap-xl justify-content-space-between align-items-center spacer-vm">
            <div className="flex-box flex-gap-xl align-items-center flex-1">
                {
                    data && data?.length > 0 &&
                    data.map((d, i) => (
                        <div key={i} className="flex-1 flex-column flex-gap-s">
                            <div className="grey-text"> {d.label} : </div>
                            <div className="font-size-m-3 text-bold"> {d.value} </div>
                        </div>
                    ))
                }
            </div>
            <div className="flex-1"></div>
        </div>
    )
}