import * as Constants from './constants';
export const trackingReducer = (state = {
    heliosDataLoading: false,
    manifestEvents: {
        loading: false,
        error: "",
        events: []
    },
    bulkTrackingPopup: {
        show: false,
        s3Url: "",
        currentCount: 0,
        treshold: 0,
        title: ""
    }
}, action) => {
    switch (action.type) {
        case Constants.GET_WAYBILL_DETAILS_BEGIN: return getWaybillDetailsBegin(state);
        case Constants.GET_WAYBILL_DETAILS_SUCCESS: return getWaybillDetailsSuccess(action, action);
        case Constants.HELIOS_TRACKING_BEGIN: return heliosTrackingBegin(state);
        case Constants.HELIOS_TRACKING_END: return heliosTrackingEnd(state);
        case Constants.GET_WAYBILL_DETAILS_FAILURE: return getWaybillDetailsFailure(state);
        case Constants.GET_WAYBILL_TRACKING_DETAILS_BEGIN: return getWaybillTrackingDetailsBegin(state);
        case Constants.GET_WAYBILL_TRACKING_DETAILS_SUCCESS: return getWaybillTrackingDetailsSuccess(state, action);
        case Constants.GET_WAYBILL_TRACKING_DETAILS_FAILURE: return getWaybillTrackingDetailsFailure(state, action);
        case Constants.GET_VEHICLE_TRACKING_DETAILS_SUCCESS: return getVehicleTrackingData(state, action);
        case Constants.GET_ROUTE_TRSCKING_DETAILS_SUCCESS: return getRouteTrackingData(state, action);
        case Constants.GET_VEHICLE_TRACKING_TELEMATIC_DETAILS_SUCCESS: return getVehicleTrackingTelematicData(state, action);
        case Constants.GET_VEHICLE_TRACKING_SIM_DETAILS_SUCCESS: return getVehicleTrackingTelematicData(state, action);
        case Constants.GET_VEHICLE_TRACKING_SIM_DETAILS_BEGIN: return getVehicleTrackingTelematicDataBegin(state, action);
        case Constants.GET_VEHICLE_TRACKING_SIM_DETAILS_FAILURE: return getVehicleTrackingTelematicData(state, action);
        case Constants.GET_VEHICLE_TRACKING_TELEMATIC_DETAILS_SOCKET: return getVehicleTrackingTelematicSocketData(state, action);
        case Constants.GET_VEHICLE_TRACKING_HISTORIC_DETAILS_SUCCESS: return getVehicleHistoricTrackingData(state, action);
        case Constants.GET_VEHICLE_TRACKING_SIM_HISTORIC_DETAILS_SUCCESS: return getVehicleSimHistoricTrackingData(state, action);
        case Constants.GET_VEHICLE_TRACKING_ONTRIP_SIM_DETAILS_SUCCESS: return getVehicleOnTripTrackingTelematicData(state, action);
        case Constants.GET_VEHICLE_TRACKING_TYPE: return getVehicleTrackingTypeTelematicData(state, action);
        case Constants.GET_VEHICLE_TRACKING_ONTRIP_SIM_DETAILS_BEGIN: return getVehicleTrackingOnTripTelematicDataBegin(state, action);
        case Constants.GET_HELIOS_INITIAL_DATA: return getHeliosInitialData(state, action);
        case Constants.GET_HELIOS_CUSTOMERS_DATA: return getHeliosCustomerData(state, action)
        case Constants.GET_HELIOS_FILTERED_DATA: return getHeliosFilteredData(state, action)
        case Constants.GET_HELIOS_TRACKING_DATA_BEGIN: return getHeliosTrackingDataBegin(state, action)
        case Constants.GET_HELIOS_DISTANCE_DATA: return getHeliosDistanceData(state, action)
        case Constants.GET_HELIOS_VEHICLE_HISTORY_DATA: return getHeliosHistoryVehicleData(state, action)
        case Constants.GET_HELIOS_EMPTY_DATA: return getEmptyData(state, action)
        case Constants.GET_HELIOS_DATA_LOADING: return setHeliosDataLoading(state, action)
        case Constants.GET_MANIFESTS_EVENTS_BEGIN: return getManifestEventsBegin(state, action)
        case Constants.GET_MANIFESTS_EVENTS_SUCCESS: return getManifestEventsSuccess(state, action)
        case Constants.GET_MANIFESTS_EVENTS_FAILURE: return getManifestEventsFailure(state, action);
        case Constants.BULK_TRACKING_POPUP_OPEN: return bulkTrackingPopupOpen(state, action);
        case Constants.BULK_TRACKING_POPUP_CLOSE: return bulkTrackingPopupClose(state, action);
        case Constants.WAYBILL_DETAILS_RESET: return waybillTrackingDetailsReset(state);
        default: return state;
    }
}
function setHeliosDataLoading(state, action) {
    return {
        ...state,
        heliosDataLoading: action.payload
    }
}
function getWaybillDetailsBegin(state) {
    return {
        ...state,
        loadingWaybills: true
    }
}
function heliosTrackingBegin(state,) {
    return {
        ...state,
        loadingHeliosTrack: true
    }
}
function heliosTrackingEnd(state) {
    return {
        ...state,
        loadingHeliosTrack: false
    }
}

function getWaybillDetailsSuccess(state, action) {
    return {
        ...state,
        consignments: action.payload,
        loadingWaybills: false
    }
}

function getWaybillDetailsFailure(state) {
    return {
        ...state,
        loadingWaybills: false
    }
}
function getWaybillTrackingDetailsBegin(state) {
    return {
        ...state,
        loading: true
    }
}

function waybillTrackingDetailsReset(state) {
    return {
        ...state,
        waybillDetails: undefined
    }
}

function getWaybillTrackingDetailsSuccess(state, action) {
    let response = {
        ...state,
        loading: false,
        waybillDetails: action.payload,
        trackingDetailsError: undefined,
        source: action.source
    }
    if (action.source) {
        response.source = action.source;
    }
    return response
}
function getWaybillTrackingDetailsFailure(state, action) {
    return {
        ...state,
        loading: false,
        waybillDetails: undefined,
        waybillDetailsError: action.payload
    }
}

function getVehicleTrackingData(state, action) {
    return {
        ...state,
        vehicleData: action.payload
    }
}

function getRouteTrackingData(state, action) {
    return {
        ...state,
        routeData: action.payload
    }
}

function getVehicleTrackingTelematicData(state, action) {
    return {
        ...state,
        vehicleTelematicData: action.payload.res,
        tripStatus: action.payload.tripStatus
    }
}

function getVehicleOnTripTrackingTelematicData(state, action) {
    return {
        ...state,
        vehicleOnTripTelematicData: action.payload.res,
        tripStatus: action.payload.tripStatus
    }
}

function getVehicleTrackingTypeTelematicData(state, action) {
    return {
        ...state,
        tripStatus: action.payload
    }
}

function getVehicleTrackingTelematicDataBegin(state, action) {
    return {
        ...state,
        vehicleTelematicData: action.payload
    }
}

function getVehicleTrackingOnTripTelematicDataBegin(state, action) {
    return {
        ...state,
        vehicleOnTripTelematicData: action.payload
    }
}


function getVehicleTrackingSimData(state, action) {
    return {
        ...state,
        vehicleSimData: action.payload
    }
}
function getVehicleTrackingTelematicSocketData(state, action) {
    const vehicle_id = action.payload.id
    if (Object.keys(state).length !== 0) {
        var vehicle_data = state.vehicleTelematicData[vehicle_id]
        if (vehicle_data != undefined) {
            vehicle_data.telematicData = action.payload.telematicData;
            return {
                ...state
            }
        } else {
            return {
                ...state
            }
        }
    }
    else {
        return {
            ...state
        }
    }

}

function getVehicleHistoricTrackingData(state, action) {
    return {
        ...state,
        historicvehicleTrackingData: action.payload
    }
}
function getVehicleSimHistoricTrackingData(state, action) {
    return {
        ...state,
        historicvehicleTrackingData: action.payload
    }
}
function getHeliosInitialData(state, action) {
    return {
        ...state,
        heliosTrackingData: action.payload.assetTrackingDetails,
        heliosLocationData: action.payload.locations,
        heliosClientsData: action.payload.customers,
        heliosAllClientsData: action.payload.allCustomers
    }
}
function getHeliosFilteredData(state, action) {

    return {
        ...state,
          heliosTrackingData: action.payload.assetTrackingDetails,
        // heliosTrackingData: [
        //     {
        //         "assetId": 874,
        //         "ownerName": "ANIL KUMAR MISHRA",
        //         "driverName": "ram nath",
        //         "vehicleNumber": "WB25J0060",
        //         "imei": "WB25J0060",
        //         "contactPersonName": "ANILKUMAR SINGH",
        //         "contactPersonMobile": "9431875543",
        //         "isAssetAvailable": true,
        //         "vehicleType": "20ft",
        //         "bodyType": "CLOSED",
        //         "isSimTracking": false,
        //         "isGps": true,
        //         "lastKnownLocation": {
        //             "latitude": 25.5811933,
        //             "longitude": 84.9555366,
        //             "lastKnownTime": 1637083636000,
        //             "ignitionStateChangeTime": 1637082248000,
        //             "imei": "358480081121099",
        //             "speed": 14,
        //             "ignition": true,
        //             "vehicleId": "profile_vehicle_KCQ337QA3361",
        //             "simTracker": false
        //         },
        //         "metaData": {
        //             "tripId": 2105991,
        //             "onTrip": false,
        //             "runTime": 0,
        //             "idleTime": 0,
        //             "stoppingTime": 0,
        //             "averageSpeed": 0,
        //             "tripStartDate": 1633353804310,
        //             "tripEndDate": 1633408252114,
        //             "pickupTime": 1633333847874,
        //             "tripType": "FTL",
        //             "routeName": "Flipkart_FTL_Bihta_Darbhanga_LineHaul"
        //         }
        //     },
        //     {
        //         "assetId": 336,
        //         "ownerName": "Shyam Shing",
        //         "driverName": "Alamgir",
        //         "vehicleNumber": "WB25J0060_1",
        //         "contactPersonName": "Shyam Shing",
        //         "contactPersonMobile": "7679045687",
        //         "isAssetAvailable": true,
        //         "isSimTracking": false,
        //         "isGps": false,
        //         "lastKnownLocation": {
        //             "latitude": 12.992303549999999,
        //             "longitude": 77.70593990523807,
        //             "lastKnownTime": 1637083636000,
        //             "ignitionStateChangeTime": 1637082248000,
        //             "imei": "358480081121099",
        //             "speed": 14,
        //             "ignition": true,
        //             "vehicleId": "profile_vehicle_KCQ337QA3361",
        //             "simTracker": false
        //         },
        //         "metaData": {
        //             "tripId": 250052,
        //             "onTrip": false,
        //             "runTime": 0,
        //             "idleTime": 0,
        //             "stoppingTime": 0,
        //             "averageSpeed": 0,
        //             "pickupTime": 1554583500139
        //         }
        //     },
        //     {
        //         "assetId": 981,
        //         "ownerName": "ANIL KUMAR MISHRA",
        //         "driverName": "ramnath",
        //         "vehicleNumber": "WB25J0060_2",
        //         "contactPersonName": "ANILKUMAR SINGH",
        //         "contactPersonMobile": "9431875543",
        //         "isAssetAvailable": true,
        //         "isSimTracking": false,
        //         "isGps": false,
        //         "lastKnownLocation": {
        //             "latitude": 23.0216238,
        //             "longitude": 72.5797068,
        //             "lastKnownTime": 1637083636000,
        //             "ignitionStateChangeTime": 1637082248000,
        //             "imei": "358480081121099",
        //             "speed": 14,
        //             "ignition": true,
        //             "vehicleId": "profile_vehicle_KCQ337QA3361",
        //             "simTracker": false
        //         },
        //         "metaData": {
        //             "tripId": 466550,
        //             "onTrip": false,
        //             "runTime": 0,
        //             "idleTime": 0,
        //             "stoppingTime": 0,
        //             "averageSpeed": 0,
        //             "pickupTime": 1566389340682,
        //             "tripType": "FTL"
        //         }
        //     }
        // ]

    }
}
function getEmptyData(state, action) {
    return {
        ...state,
        heliosTrackingData: [],
    }
}
function getHeliosCustomerData(state, action) {
    return {
        ...state,
        heliosClientsData: action.payload.customers
    }
}
function getHeliosTrackingDataBegin(state, action) {
    return {
        ...state,
        heliosTrackingData: action.payload.vehicleTrackerBOS,
    }
}
function getHeliosDistanceData(state, action) {
    return {
        ...state,
        heliosDistanceListData: action.payload.heliosDistanceListData,
    }
}
function getHeliosHistoryVehicleData(state, action) {
    return {
        ...state,
        HeliosHistoryTrackingData: action.payload.heliosVehicleHistoryData,
    }
}

function getManifestEventsBegin(state, action) {
    return {
        ...state,
        manifestEvents: {
            loading: true,
            error: "",
            events: []
        }
    };
};


function getManifestEventsSuccess(state, action) {
    return {
        ...state,
        manifestEvents: {
            loading: false,
            error: "",
            events: action.payload
        }
    };
};

function getManifestEventsFailure(state, action) {
    return {
        ...state,
        manifestEvents: {
            loading: false,
            error: action.payload,
            events: []
        }
    };
};

function bulkTrackingPopupOpen (state, action) {
    return {
        ...state,
        loadingWaybills: false,
        bulkTrackingPopup: {
            ...state.bulkTrackingPopup,
            ...action.payload
        }
    };
}

function bulkTrackingPopupClose (state, action) {
    return {
        ...state,
        bulkTrackingPopup: {
            show: false,
            s3Url: "",
            currentCount: 0,
            treshold: 0,
            title: ""
        }
    };
};