import * as CONSTANTS from './constants';
import * as apiUtils from '../../../common/utils/apiGateway';
import { USER_LOGGEDIN_INFO, LOCAL_STORAGE_STATE, Constants } from '../../../common/utils/constants';
import { localStorageUtils, getLoggedUserInfo, reloadApp } from '../../../common/utils/utils';

export const getLoggedInUserInfo = () => {
    if (localStorage.length < 2) {
        reloadApp();
    }
    if (localStorage.getItem(USER_LOGGEDIN_INFO.USER)) {
        const user = JSON.parse(atob(localStorage.getItem(USER_LOGGEDIN_INFO.USER)));
        return {
            type: CONSTANTS.GET_LOGGEDIN_USER_DATA,
            payload: user
        }
    }
    else if (Constants.sessionUser) {
        return {
            type    : CONSTANTS.GET_LOGGEDIN_USER_DATA,
            payload : Constants.sessionUser
        }
    }
}

export const getPartnerLocatinDetails = (params) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.GET_LOCATION_FETCH_BEGIN
        });
        return apiUtils.apiRequest({
            url: `/b2b/v1/partners/${params.partnerId}/locations`,
            params: params,
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.GET_LOCATION_FETCH_SUCCESS,
                    payload: response.data.response
                })
                return response.response.responses;
            })
            .catch(error => dispatch({
                type: CONSTANTS.GET_LOCATION_FETCH_FAILURE,
                payload: error
            }));
    }
}

export const fetchPartnerLocatinDetails = (params) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.GET_LOCATION_FETCH_BEGIN
        });
        return apiUtils.apiRequest({
            method: 'post',
            url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`,
            data: params,
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.GET_LOCATION_FETCH_SUCCESS,
                    payload: response.data.response
                })
                return response.response.responses;
            })
            .catch(error => dispatch({
                type: CONSTANTS.GET_LOCATION_FETCH_FAILURE,
                payload: error
            }));
    }
}

export const userLogout = () => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.LOGGED_OUT_BEGIN
        });
        return apiUtils.apiRequest({
            url: `/v1/logout/B2B`
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.LOGGED_OUT_SUCCESS,
                    payload: response.data.response
                })
                return response.response.responses;
            })
            .catch(error => dispatch({
                type: CONSTANTS.LOGGED_OUT_FAILURE,
                payload: error
            }));
    }
}

export const getPartnerLocatinDetailsV2 = (params) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.GET_LOCATION_FETCH_BEGIN
        });
        return apiUtils.apiRequest({
            url: `/b2b/v1/partners/${JSON.parse(localStorage.getItem(USER_LOGGEDIN_INFO.LOGGEDIN_PARTNER_ID))}/location_by_entityid`,
            params: params,
            overrideHeader: Constants.HTTP_HEADERS_LOGGED_IN
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.GET_LOCATION_FETCH_SUCCESS,
                    payload: response.data.response
                })
                return response.response.responses;
            })
            .catch(error => dispatch({
                type: CONSTANTS.GET_LOCATION_FETCH_FAILURE,
                payload: error
            }));
    }
}

export const appLogin = (param) => {
    /*
      Get all the local storage data and set it in Redux via reducer for
      entire react app
    */
    return dispatch => {
        dispatch({
            type: CONSTANTS.APP_LOGIN_BEGIN
        });
        return apiUtils.apiRequest({
            url: `/v1/b2b/login/${param.locationId}`,
            overrideHeader: Constants.HTTP_HEADERS_LOGGED_IN
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.APP_LOGIN_SUCCESS,
                    payload: {
                        data: response.data.response,
                        auto: param.auto
                    }
                })
                return response.response.responses;
            })
            .catch(error => dispatch({
                type: CONSTANTS.APP_LOGIN_FAILURE,
                payload: error
            }));
    }
}

export const getPartnerDetails = (currentPartnerId) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.GET_PARTNER_FETCH_BEGIN
        });
        const payload = { "filters": { "sourcePartnerIds": [currentPartnerId], "fetchObjects": ["PARTNER"] } }
        return apiUtils.apiRequest({
            method: 'POST',
            url: `b2b/v1/partners/mapping/fetch`,
            overrideHeader: Constants.HTTP_HEADERS_LOGGED_IN,
            data: payload
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.GET_PARTNER_FETCH_SUCCESS,
                    payload: response.data.response
                })
                localStorage.setItem('_partnerDetails', btoa(JSON.stringify(response.data.response.partners)));
                return response.data.response;
            })
            .catch(error => dispatch({
                type: CONSTANTS.GET_PARTNER_FETCH_FAILURE,
                payload: error
            }));
    }
}

export const updateLocalItemInRedux = (param) => {
    /*
      Get all the local storage data and set it in Redux via reducer for
      entire react app
    */
    return dispatch => {
        localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, key: param.key, value: param.value });
        const loggedUserInfo = getLoggedUserInfo(CONSTANTS.USER_LOGGEDIN_INFO_KEYS);
        dispatch({
            type: CONSTANTS.UPDATE_LOCALITEM_REDUX_SUCCESS,
            payload: loggedUserInfo
        });
    }
}

export const storeGetDataLocalStorage = () => {
    /*
      Get all the local storage data and set it in Redux via reducer for
      entire react app
    */
    return dispatch => {
        const loggedUserInfo = getLoggedUserInfo(CONSTANTS.USER_LOGGEDIN_INFO_KEYS);
        if (!loggedUserInfo || !loggedUserInfo.invalidUser) {
            dispatch({
                type: CONSTANTS.GET_SET_LOCALITEM_REDUX_SUCCESS,
                payload: loggedUserInfo
            })
        }
    }
}

export const openWaybillModal = (waybill, isOpen = false) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.TOGGLE_WAYBILL_MODAL,
            waybill,
            toggleVal: isOpen
        })
    }
}
