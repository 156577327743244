import { apiRequest } from "../../common/utils/apiGateway";

export default class InventoryPrintService {
    getLabelPrintingData(payload) {
        const url = "/b2b/v1/manifest/getLabelPrintingData";
        return apiRequest({
            url,
            method: "POST",
            data: payload
        })
    };

    getLabelPrintingTypes() {
        const url = "/b2c/v1/getLabelPrintingTypes";
        return apiRequest({
            url,
            method: "GET"
        })
    };
    printBulkAWBSticker(payload) {
        const url = "/b2b/v1/uploads/print/awb_sticker";
        return apiRequest({
            url,
            method: "POST",
            data: payload
        })
    }
    printSingleAWBSticker(waybillNo) {
        const url = `/b2c/v1/consignment/print/label/${waybillNo}`;
        return apiRequest({
            url,
            method: "GET"
        })
    }
}