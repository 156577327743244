import { Button } from "antd";
import moment from "moment";
import { useState } from "react";
import { Constants } from "../../../../common/utils/constants";
import { SETTLEMENT_ACTIVITY_COLUMN } from "../../transform.data";
import ViewCoDActiviy from "../view/view.activity";

export default function PartnerActionColumn({ partnerId, rowData, actions }) {

    const [ fetchObject, SetFetchObject ] = useState(undefined);
    const [ openActivityModal, SetOpenActivityModal ] = useState(false);
    const [ approveRejectActionTaken, SetApproveRejectActionTaken ] = useState(false);

    const onSettlementActionSuccess = _ => {
        SetFetchObject(undefined);
        SetApproveRejectActionTaken(true);
        SetFetchObject({
            startDate : moment().subtract(7, "days").startOf("day").valueOf(),
            endDate   : moment().valueOf(),
            partnerId : partnerId,
            rootPartnerId : [268,269].indexOf(Constants.PARTNER_ID) > -1 ? [ Constants.PARTNER_ID ] : undefined,
            approvalType  : [ "BRANCH_CUSTOMER", "PARTNER_CUSTOMER" ]
        });
    }

    return (
        <>

            <Button onClick={ _ => {
                SetFetchObject({
                    startDate : moment().subtract(7, "days").startOf("day").valueOf(),
                    endDate   : moment().valueOf(),
                    partnerId : partnerId,
                    rootPartnerId : [268,269].indexOf(Constants.PARTNER_ID) > -1 ? [ Constants.PARTNER_ID ] : undefined,
                    approvalType  : [ "BRANCH_CUSTOMER", "PARTNER_CUSTOMER" ]
                });
                SetOpenActivityModal(true);
            }} className="lsn-btn-secondary">
                View Activity
            </Button>

            {
                openActivityModal &&
                <ViewCoDActiviy rowData={rowData} fetchObject={fetchObject} viewType="PARTNER" 
                 openActivityModal={openActivityModal} SetOpenActivityModal={SetOpenActivityModal}
                 onModalClose={_ => {
                    if (approveRejectActionTaken && typeof actions.onAcceptRejectSuccess === "function") {
                        actions.onAcceptRejectSuccess();
                    }
                 }}
                 columns={SETTLEMENT_ACTIVITY_COLUMN({ onSettlementActionSuccess }).filter(c => !c.hidePartnerView)}/>
            }

        </>
    )
}