import React from "react";

import "./closure-scan-card.scss";

const ClosureScanCardBucket = ({bucket}) => {
    return(
        <div className="closure-scan-card-buket">
            { "primaryBucket" in bucket && bucket.primaryBucket && (
                <div className={`value scan-bucket
                        ${ "primaryBucketPriority" in bucket ? (
                            ["CRITICAL", "HIGH"].includes(bucket.primaryBucketPriority) ? "background-warning" : ""
                        ) : ""}
                        ${ "primaryBucketPriority" in bucket ? (
                            ["MEDIUM"].includes(bucket.primaryBucketPriority) ? "background-medium" : ""
                        ) : ""}
                        ${ "primaryBucketPriority" in bucket ? (
                            ["NORMAL", "LOW"].includes(bucket.primaryBucketPriority) ? "background-normal" : ""
                        ) : ""}
                    `}
                >
                        {bucket.primaryBucket}    
                </div>
            )}

            { "secondaryBucket" in bucket && bucket.secondaryBucket && (
                <div className={`value scan-bucket
                        ${ "secondaryBucketPriority" in bucket ? (
                            ["CRITICAL", "HIGH"].includes(bucket.secondaryBucketPriority) ? "background-warning" : ""
                        ) : ""}
                        ${ "secondaryBucketPriority" in bucket ? (
                            ["MEDIUM"].includes(bucket.secondaryBucketPriority) ? "background-medium" : ""
                        ) : ""}
                        ${ "secondaryBucketPriority" in bucket ? (
                            ["NORMAL", "LOW"].includes(bucket.secondaryBucketPriority) ? "background-normal" : ""
                        ) : ""}
                    `}
                >
                        {bucket.secondaryBucket}    
                </div>
            )}

            { "tertiaryBucket" in bucket && bucket.tertiaryBucket && (
                <div className={`value scan-bucket
                        ${ "tertiaryBucketPriority" in bucket ? (
                            ["CRITICAL", "HIGH"].includes(bucket.tertiaryBucketPriority) ? "background-warning" : ""
                        ) : ""}
                        ${ "tertiaryBucketPriority" in bucket ? (
                            ["MEDIUM"].includes(bucket.tertiaryBucketPriority) ? "background-medium" : ""
                        ) : ""}
                        ${ "tertiaryBucketPriority" in bucket ? (
                            ["NORMAL", "LOW"].includes(bucket.tertiaryBucketPriority) ? "background-normal" : ""
                        ) : ""}
                    `}
                >
                        {bucket.tertiaryBucket}    
                </div>
            )}
        </div>
    )
};

export default ClosureScanCardBucket;