import { Button } from "antd";
import React from "react";
import PodService from "../../../services/pod/pod.service";
import BulkOperation from "../../shared-components/bulk-operation";
import DataCard from "../../shared-components/data-card/DataCard";
import { useSelector } from "react-redux";

const podService = new PodService();

const PODLiveSparkLine = ({ data, createPOD, handleRefresh }) => {

    const sampleBookingDownloadLink = "https://loadshare-v2.s3.ap-south-1.amazonaws.com/dev/Bulk_POD.xlsx";

    const podLiveDashboardConfig = useSelector((state) => state.app.configurations)

    return (
        <div className="legacy-sparkline">
            <div className="legacy-sparkline-datacards">
                {
                    data && data.length > 0 ?
                        data.map((item, index) => {
                            return (
                                <div className="flex-1" key={`data-card-list-${index}`}>
                                    <DataCard {...item} />
                                </div>
                            )
                        }) :
                        null
                }
            </div>
            <div className="legacy-sparkline-ctas">
                <Button type="primary" className="lsn-btn-primary" onClick={createPOD} size="large" icon="plus">
                    Create POD
                </Button>
                {false &&
                    <BulkOperation
                        className="pod-live-bulk-pod-btn"
                        buttonLabel="Bulk  POD" icon="file"
                        title="Bulk POD" bulkType="Booking"
                        buttonStyles={{ width: "100%" }}
                        sampleDownloadLink={sampleBookingDownloadLink}
                        objKey="waybills"
                        showFileName="showBookingFileName" path="'bulk_pod_bookings'"
                        syncToServerAction="'UPLOAD_BOOKING'"
                        uploadBookingMethodParams={{}}
                        redirectUrl="/pod/dashboard/drs"
                        uploadMethodParams={{}}
                        uploadMethod={podService.uploadBookingPod}
                        callback={handleRefresh}
                    />
                }
            </div>
        </div>
    )
};
export default PODLiveSparkLine;