import React from 'react';
import { Button } from 'antd';

export const button = (props) => {
  return (
    <Button
      {...props}
    >{props.children}</Button>
  )
}
