import moment from "moment";

export const billingQueryReference = {
    SC_FORWARD : {
        dashboardNo  : 226,
        reportName   : "meesho-billing-mm-new-format",
        lockedParams :[ "location_id", "partner_id"],
        customParams :{
            month    : moment().subtract(1, 'month').format("MMMM")
        }
    },
    SC_RTO : {
        questionNo   : 1726,
        reportName   : "meesho-billing-mm-new-format-rto",
        lockedParams :[ "location_id", "partner_id"],
        customParams :{
            scan_type : "RTO_IN",
            flow_type : "RTO"
        }
    },
    SC_CROSSDOCK : {
        questionNo   : 1730,
        reportName   : "meesho-billing-cross-dock",
        lockedParams : [ "location_id" ]
    },
    LM_FORWARD  : {
        questionNo   : 1725,
        reportName   : "meesho-billing-lm-new-format",
        lockedParams : [ "location_id" ]
    },
    LM_RTO  : {
        questionNo   : 1732,
        reportName   : "meesho-billing-lm-rto-new-format",
        lockedParams : [ "location_id" ]
    },
    FM_FORWARD  : {
        questionNo   : 1840,
        reportName   : "meesho-billing-fm-forward-new-format",
        lockedParams : [ "location_id" ]
    },
    FM_RTO  : {
        questionNo   : 1841,
        reportName   : "meesho-billing-fm-rto-new-format",
        lockedParams : [ "location_id" ]
    },
    RVP_RIDER_WISE : {
        questionNo   : 3155,
        reportName   : "rvp-rider-wise-billing-data",
        lockedParams : [ "location_id", "partner_id"]
    }
}