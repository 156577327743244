import React, { useEffect, useState } from "react";
import { Icon, Table, Input } from "antd";
import { useSelector } from "react-redux";
import { removeItem } from "./utils";
import { capitalizeString } from "../../../common/utils/utils";
import { SoundNotificationService } from '../../../services/sound.service';

const soundService = new SoundNotificationService();

export default function ScannedList(props) {

    const scannedList = useSelector(state => state.scanListReducer[props.listId]?.scannedList);
    
    var { scanningDataIndex} = props

    const [scannedColumns, SetScannedColumns] = useState(props.scannedColumns);
    
    useEffect(_ => {
        var tempScannedColumns = scannedColumns.filter(c => c.title !== "Remove")
        tempScannedColumns.push({
            title  : "Remove",
            dataIndex : scanningDataIndex ? scanningDataIndex : props.listId + "_scanning",
            render : (data, rowData, index) => {
                return (
                <Icon type="delete" onClick={_ => {
                    if (typeof props.beforeRemove === "function") {
                        props.beforeRemove(rowData, index, props.listId)
                        .then(_ => {
                            removeItemCallback(rowData, index)
                        })
                        .catch(exp => {
                            console.log(exp);
                        });
                    }
                    else {
                        removeItemCallback(rowData, index);
                    }
                }} />
            )}
        })
        SetScannedColumns(tempScannedColumns);
    }, [ scannedList ])

    const removeItemCallback = (rowData, index) => {

        const onRemoveSuccess = (rowData, index) => {
            soundService.playSuccess();
            if (typeof props.onAdd === "function") {
                props.onRemove(rowData, index, props.listId)
            }
        }
        if (props.skipRemove) {
            onRemoveSuccess(rowData, index);
        }
        else {
            removeItem(rowData, index, props.listId)
            .then(_ => onRemoveSuccess(rowData, index));
        }
    }

    return (
        <div className="spacer-m white-bg border-grey flex-column flex-gap-l flex-1">
            <div className="flex-box justify-content-space-between align-items-center font-size-m-3"> 
                <div className="flex-box flex-gap-s flex-wrap flex-1 text-bold">
                    <div>
                        {
                            props.scannedListHeader ? props.scannedListHeader :
                            props.entityType ? "Scanned " + capitalizeString(props.entityType) : 
                            "Scanned Items"
                        }
                    </div>
                    {
                        typeof props.showCount === "boolean" ?
                        <div>( Count : { typeof props.scannedCount === "number" ? props.scannedCount : scannedList?.length} ) </div> :
                        typeof props.showCount === "object" ?
                        (props.showCount) :
                        <></>
                    }
                </div>
                {
                    props.enableScannedSearch && typeof props.onScannedSearch === "function" &&
                    <Input.Search
                     id={props.listId + "_scannedSearchBar"} allowClear={true}
                     placeholder={props.scannedSearchPlaceholder ? props.scannedSearchPlaceholder :
                                  props.entityType ? "Search Scanned " + capitalizeString(props.entityType) : 
                                  "Search scanned items" }
                     className="flex-1" onSearch={value => props.onScannedSearch(value?.trim())} />
                }
            </div>
            <Table rowKey={scanningDataIndex ? scanningDataIndex : props.listId + "_scanning"} columns={scannedColumns} dataSource={scannedList}
             pagination={props.scannedPagination ? props.scannedPagination : false} {...props.scannedTableProps}  />
        </div>
    )
}