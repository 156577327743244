import React, { Component } from 'react';
import { Button, Modal, Row, Col, Select, Radio, Spin, Alert, Collapse, List } from 'antd';
import './bulk-operation.scss';
import { UPLOAD_BUTTON_TYPE, Constants, BulkUploadStatus, ORGANISATIONLIST } from '../../../common/utils/constants';
import FileUpload from '../../ui/file-upload/file.upload';
import { connect } from 'react-redux';
import { consignmentLiteAction } from '../../inbound/scan/actions';
import { notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';
import { checkConfigurationsForBooleanPermission } from "../../../common/utils/utils";
class BulkOperation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadText: 'Download sample upload format ',
      processingText: `Processing the data. We will notify you once it's completed...`,
      errorResponseMsg: 'Show / Hide Error Response',
      totalBookingText: `Total Counts: `,
      successfulBookingText: `Successful Counts: `,
      unsuccessfulBookingText: `Unsuccessful Counts: `,
      bookingSuccessMsg: `Operation Success`,
      bookingFailMsg: `Operation Fail`,
      unsuccessfulAsyncText: 'Unsucessful Asset Assign: ',
      modalVisible: false,
      sampleLink: props.sampleDownloadLink,
      orglist: [],
      liteOptions: undefined,
      organisationSelection: undefined,
      selectedLiteOption: 'CREATE',
      lastFileToUpload: '',
      currentStatus: BulkUploadStatus.DEFAULT,
      bookingPartiallyFailed: false,
      clearFile: false
    }
  }

  componentWillMount() {
    if (this.props.config['bulk_booking_organisations'] && this.props['organizationFilteredValues']) {
      let orglist = []
      this.props.config['bulk_booking_organisations'].forEach(org => {
        this.props.organizationFilteredValues.forEach(orgfilter => {
          if (org === orgfilter.value) {
            orglist.push(orgfilter);
          }
        });
      });
      this.setState({
        orglist,
      })
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.bulkOperation !== prevProps.bulkOperation) {
      if (this.props.bulkOperation)
        this.setApiResponse(this.props.bulkOperation)
    }
  }

  toggleModal = () => {
    if (this.props.bulkType === 'RTO_UPLOAD' && checkConfigurationsForBooleanPermission('enabledRearchRTOBulkUpload')){
      this.props.redirectCallBack();
    } else {
      this.setState({
        acceptTypes: [".xlsx", ".xls"],
        modalVisible: !this.state.modalVisible,
      })
      if (!this.state.modalVisible) {
        this.resetData()
      }
    }
  }

  resetData = () => {
    this.setState({
      organisationSelection: undefined,
      liteOptions: undefined,
      errorMessage: '',
      currentStatus: BulkUploadStatus.DEFAULT,
      bulkType: '',
      bookingPartiallyFailed: false,
      clearFile: !this.state.clearFile
    })
  }

  onUploadFinish = (filePath) => {
    this.syncToServer(filePath);
  }

  callUploadMethod = (params) => {
    this.props.uploadMethod(params).then(resp => (resp.data?.response || resp)).then(responseData => {
      this.setApiResponse(typeof this.props.parseResponseObj === "function" ? this.props.parseResponseObj(responseData) : responseData);
    });
  }

  syncToServer(uploadedFilePath) {
    let lastFileToUpload = this.state.lastFileToUpload;
    let currentStatus = this.state.currentStatus;
    this.setState({
      currentStatus: BulkUploadStatus.SYNCING
    }, () => {
      if (uploadedFilePath) {
        lastFileToUpload = uploadedFilePath;
      }
      currentStatus = BulkUploadStatus.SYNCING;
      const referenceId = new Date().getTime();
      let params = {};
      // if organization list is present, then choose this
      if (
        this.props.organizationFilteredValues &&
        this.props.organizationFilteredValues?.length
      ) {
        // If ICIC
        if (this.state.organisationSelection === ORGANISATIONLIST.ICICI) {
          params = {
            ...params,
            ...this.props.uploadMethodParams,
            ...{
              orgCode: this.state.organisationSelection,
              url: uploadedFilePath || lastFileToUpload
            }
          };
          this.callUploadMethod(params)
        } else if ( // If DEALSHARE || BLUE DART
          this.state.organisationSelection === ORGANISATIONLIST.PNG ||
          this.state.organisationSelection === ORGANISATIONLIST.DEALSHARE ||
          this.state.organisationSelection === ORGANISATIONLIST.BLUEDART
        ) {
          params = {
            ...params,
            ...this.props.uploadMethodParams,
            ...{
              orgCode: this.state.organisationSelection,
              url: uploadedFilePath || lastFileToUpload
            }
          };
          this.callUploadMethod(params)
        } else if (this.state.organisationSelection === ORGANISATIONLIST.LITE) {
          if (this.state.selectedLiteOption) {
            params = {
              url: uploadedFilePath || lastFileToUpload,
              action: this.state.selectedLiteOption === 'CREATEIN' ? 'CREATE' : this.state.selectedLiteOption
            };

            if (this.state.selectedLiteOption === 'CREATE') {
              params['allowInscan'] = false;
            }
            // this.callUploadMethod(params)
            this.props.consignmentLiteAction(params)
          }
        } else {
          params = {
            ...this.props.uploadMethodParams,
            url: uploadedFilePath || lastFileToUpload,
            referenceId: referenceId
          };
          this.setState({
            currentStatus,
            lastFileToUpload
          })
          this.callUploadMethod(params)
        }
      } else {
        params = {
          ...this.props.uploadMethodParams,
          url: uploadedFilePath || lastFileToUpload,
          referenceId: referenceId
        };
        this.callUploadMethod(params)
      }
    })

  }

  setApiResponse(responseData) {
    let bookingPartiallyFailed = false;
    let currentStatus = "";
    let uploadResponseData = '';
    if (responseData.sync) {
      uploadResponseData = responseData;
      currentStatus = BulkUploadStatus.SYNCINED;

      if (uploadResponseData.successfulSync) {
        this.currentBulkSuccesfullScan += uploadResponseData.successfulSync;
      }

      if (
        !uploadResponseData.sync ||
        uploadResponseData.successfulSync === 0
      ) {
        bookingPartiallyFailed = true;
      }
    } else {
      notifyApiError(responseData.message, 'Error')
      currentStatus = BulkUploadStatus.RESYNC;
    }
    this.setState({
      currentStatus,
      bookingPartiallyFailed,
      uploadResponseData
    })
  }

  onChooseOrganisation = (val) => {
    const that = this;
    const liteOpt = [
      {
        name: 'BOOK',
        value: 'CREATE'
      },
      {
        name: 'BOOK + IN',
        value: 'CREATEIN'
      },
      {
        name: 'UPDATE',
        value: 'UPDATE'
      },
      {
        name: 'CANCEL',
        value: 'CANCEL'
      }
    ];
    if (!(typeof val === 'undefined' || val === null)) {
      that.setState({
        organisationSelection: val,
        sampleLink: that.state?.orglist?.filter(opt => opt.value === val)[0].sampleLink,
        liteOptions: (val !== 'LITE') ? '' : liteOpt,
        isS3Upload: (val !== 'LITE'),
        errorMessage: ''
      })
    }
  }

  afterScaningCompleted() {
    this.toggleModal()
    if (this.props.callback) {
      this.props.callback();
    }
    if(this.props.customcallback) {
      this.props.customcallback(this.state);
    }
  }

  bulkFooter = () => {
    const buttons = []
    const { currentStatus, bookingPartiallyFailed } = this.state;
    if ((currentStatus !== BulkUploadStatus.SYNCINED &&
      (currentStatus === BulkUploadStatus.DEFAULT ||
        currentStatus === BulkUploadStatus.RESYNC)) ||
      currentStatus === BulkUploadStatus.SYNCING) {
      buttons.push(<Button onClick={e => this.toggleModal()} type='default'>Cancel</Button>)
    }
    if (currentStatus === BulkUploadStatus.SYNCINED) {
      buttons.push(<Button onClick={e => { this.afterScaningCompleted(); this.resetData() }} type="primary" >Done</Button>)
    }
    if (currentStatus === BulkUploadStatus.RESYNC || bookingPartiallyFailed) {
      buttons.push(<Button type="default" onClick={e => this.syncToServer(this.state.uploadedFilePath)} >Re-Sync</Button>)
    }

    return buttons
  }
  render() {
    const { modalVisible, acceptTypes, clearFile, sampleLink, downloadText, orglist, liteOptions, selectedLiteOption, errorMessage, organisationSelection, uploadResponseData } = this.state;
    const { currentStatus, bookingFailMsg, processingText, errorResponseMsg, bookingSuccessMsg, successfulBookingText, totalBookingText, unsuccessfulAsyncText, unsuccessfulBookingText } = this.state;
    const { buttonLabel, newUIModule, title, path, objKey, organizationFilteredValues, icon, buttonStyles, bulkOperationStyles } = this.props;
    return (
      <div className="BulkOperation" style={bulkOperationStyles}>
        {
          (buttonLabel && buttonLabel?.length && !newUIModule) && <Button className="lsn-btn-primary" style={buttonStyles} icon={icon} type="primary" onClick={this.toggleModal} size="large">{buttonLabel}</Button>
        }
        {
          (buttonLabel && buttonLabel?.length && newUIModule) && <span onClick={this.toggleModal}> {buttonLabel} </span>
        }


        <Modal
          title={title}
          visible={modalVisible}
          maskClosable={false}
          footer={this.bulkFooter()}
          onCancel={
            currentStatus === BulkUploadStatus.SYNCINED ? 
            _ => {
              this.afterScaningCompleted();
              this.resetData() 
            } : 
            this.toggleModal 
          }
        >
          <Row gutter={24}>
            {
              sampleLink?.length ? <Col span={24}>
                <small>For accurate results</small>
                <p> {downloadText} <a href={sampleLink} download >here</a></p>
              </Col> : <></>
            }

            {
              (organizationFilteredValues && organizationFilteredValues?.length)
                ? <Col span={24}>
                  <Select value={organisationSelection} style={{ width: '100%', marginBottom: '15px' }} placeholder="Select Organisation / Type*" showSearch filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  } showArrow onChange={this.onChooseOrganisation}>
                    {
                      orglist.map((opt, i) => (
                        <Select.Option key={i} value={opt.value}> {opt.label}</Select.Option>
                      ))
                    }

                  </Select>
                  {
                    (organizationFilteredValues ? !(organisationSelection && organizationFilteredValues) : false) && <small style={{ color: 'red' }}>Please choose required fields</small>
                  }
                </Col>
                : <></>
            }

            {
              liteOptions ?
                <Col span={24}>
                  <div className='lite-option' style={{ paddingBottom: '15px' }}>
                    <Radio.Group onChange={(e) => this.setState({ selectedLiteOption: e.target.value })} value={selectedLiteOption}>
                      {
                        liteOptions.map((opt, i) => (
                          <Radio value={opt.value}> {opt.name} </Radio>
                        ))
                      }
                    </Radio.Group>
                  </div>
                </Col>
                : <></>
            }

            <Col span={24}>
              <Spin spinning={organizationFilteredValues ? !(organisationSelection && organizationFilteredValues) : false} indicator={<></>}>
                <FileUpload
                  fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                  directUpload={true}
                  disableUploadButton={false}
                  onUploadFinish={($events) => this.setState({ uploadedFilePath: $events }, () => { this.onUploadFinish(this.state.uploadedFilePath) })}
                  path={path}
                  acceptTypes={acceptTypes}
                  objKey={objKey}
                  clearFileAfterUpload={clearFile}
                />
              </Spin>
            </Col>
            {
              (currentStatus === BulkUploadStatus.SYNCING) ?
                <Col span={24}><Alert message={processingText} type="info" /></Col>
                : <></>
            }
            {
              (currentStatus === BulkUploadStatus.SYNCINED)
                ? <Col className="response-sync" span={24}>
                  {
                    errorMessage
                      ? <Alert message={errorMessage} type="error" />
                      : <></>
                  }
                  {
                    (uploadResponseData?.sync && (uploadResponseData?.successfulSync !== 0 || uploadResponseData?.successCount > 0))
                      ? <Alert message={bookingSuccessMsg} type="success" />
                      : <Alert message={bookingFailMsg} type="error" />
                  }
                  <br />
                  {
                    (uploadResponseData?.total || uploadResponseData?.totalCount) ? <p>
                      {totalBookingText} {uploadResponseData?.totalCount ? uploadResponseData.totalCount : uploadResponseData.total}
                    </p> : <></>
                  }
                  {
                    (uploadResponseData?.successfulSync || uploadResponseData?.successCount > 0) ? <p>
                      {successfulBookingText}
                      {uploadResponseData?.successCount ? uploadResponseData.successCount : uploadResponseData.successfulSync}
                    </p> : <></>
                  }
                  {
                    (uploadResponseData?.unsuccessfulSync || uploadResponseData?.failureCount > 0) ? <p>
                      {unsuccessfulBookingText}
                      {uploadResponseData?.failureCount ? uploadResponseData.failureCount : uploadResponseData.unsuccessfulSync}
                    </p> : <></>
                  }
                  {
                    (uploadResponseData?.unsuccessfulAssignSync) ? <p>
                      {unsuccessfulAsyncText}
                      {uploadResponseData.unsuccessfulAssignSync}
                    </p> : <></>
                  }
                  {
                    (uploadResponseData?.failureResponseUrl) ? <div>
                      <a
                        href={uploadResponseData.failureResponseUrl}
                        target="_blank"
                      >Click here to view failed upload waybills</a
                      >
                    </div> : <></>
                  }
                  {

                    (uploadResponseData.failureResponse?.length)
                      ? <Collapse bordered={false}>
                        <Collapse.Panel header={errorResponseMsg} key="1">
                          <List
                            size="small"
                            bordered
                            dataSource={uploadResponseData.failureResponse}
                            renderItem={failResponse => !failResponse.sync ? <List.Item>{failResponse.waybillNo} - {failResponse.reason}</List.Item> : <></>}
                          />
                        </Collapse.Panel>
                      </Collapse>
                      : <></>
                  }
                  {
                    (uploadResponseData.duplicateResponses?.length)
                      ? <Collapse bordered={false}>
                        <Collapse.Panel header={errorResponseMsg} key="1">
                          <List
                            size="small"
                            bordered
                            dataSource={uploadResponseData.duplicateResponses}
                            renderItem={duplicateResponse => !duplicateResponse.sync ? <List.Item>{duplicateResponse.waybillNo} - {duplicateResponse.reason}</List.Item> : <></>}
                          />
                        </Collapse.Panel>
                      </Collapse>
                      : <></>
                  }
                </Col>
                : <></>
            }
          </Row>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  config: state.app.configurations,
  bulkOperation: state.scan.bulkOperation,
  bulkOperationLoading: state.scan.bulkOperationLoading
});
const mapDispatchToProps = dispatch => ({
  consignmentLiteAction: payload => dispatch(consignmentLiteAction(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BulkOperation);