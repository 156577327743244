import { Input, Modal, Select, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { capitalizeString } from "../../../common/utils/utils";
import { fetchConsginmentsByManifestCodeAndStatus, fetchConsignmentsReasons, updatePendingReason } from "../service";

export default function ManifestScanSparkline({ manifestDetails }) {

    useEffect(() => fetchConsignmentsReasons(), []);

    const consignmentsReasons = useSelector(state => state.reasonsReducer.consignmentsReasons);

    const reasonCodesToHideFromUsers = [ "Delivered", "Lost" ];

    const [ modalDetails, SetModalDetails ] = useState({
        showModal : false,
        modalType : undefined,
        consignmentList : undefined
    });

    const [ updatingReason, SetUpdatingReason ] = useState(false);

    const callUpdatePendingReason = (waybillNo, reasonId) => {
        SetUpdatingReason(true);
        updatePendingReason(manifestDetails?.manifest?.code, waybillNo, reasonId)
        .then(_ => {
            SetModalDetails(current => {
                var tempConsignmentList = { ...current.consignmentList };
                var reasons = [];
                if (modalDetails.modalType == "PENDING") {
                    reasons = consignmentsReasons?.pending;
                }
                else if (modalDetails.modalType == "OVERAGE") {
                    reasons = consignmentsReasons?.overage;
                }
                const reasonDesc = reasons.find(r => r.id == reasonId).description;
                tempConsignmentList?.consignments.map(consignment => {
                    if (consignment.waybillNo == waybillNo) {
                        consignment.reason = reasonDesc;
                    }
                })
                return {
                    ...current,
                    consignmentList : tempConsignmentList
                }
            });
            SetUpdatingReason(false);
        })
    }

    const columns = [
        {
            title     : "Waybill No",
            dataIndex : "waybillNo"
        },
        {
            title     : <> {modalDetails.modalType == "SCANNED" ? "Next Location" : "Action"} </>,
            dataIndex : "reason",
            width     : 170,
            render    : (reason, consignment) => {
                
                var reasons = [];
                if (modalDetails.modalType == "PENDING") {
                    reasons = consignmentsReasons?.pending;
                }
                else if (modalDetails.modalType == "OVERAGE") {
                    reasons = consignmentsReasons?.overage;
                }

                return (
                    modalDetails.modalType == "SCANNED" ?
                    <> {consignment.nextLocation} </> :
                    <div>
                        {
                            updatingReason ?
                            <Select loading={true}></Select> :
                            reason ?
                            <Input disabled={true} value={reason} /> :
                            <Select style={{ width : "100%" }} placeholder="Select Reason"
                             onChange={value => callUpdatePendingReason(consignment?.waybillNo, value)}>
                                {
                                    reasons && reasons?.length > 0 &&
                                    reasons.map(reason => {
                                        if (!reasonCodesToHideFromUsers.includes(reason.code)) {
                                            return (
                                                <Select.Option value={reason.id} key={reason.id}>
                                                    {reason.description}
                                                </Select.Option>
                                            )
                                        }
                                    })
                                }
                            </Select>
                        }
                    </div>
                )
            }
        }
    ]


    const onSparklineClick = type => {
        if (manifestDetails?.manifest?.scannedAtDestination === false && manifestDetails?.manifest?.status == "PENDING") {
            return;
        }
        SetModalDetails({
            showModal : true,
            modalType : type,
            consignmentList : undefined
        })
        fetchConsginmentsByManifestCodeAndStatus(manifestDetails?.manifest?.code, type)
        .then(response => {
            SetModalDetails({
                showModal : true,
                modalType : type,
                consignmentList : response
            })
        })
    }

    var elementClass = "flex-column flex-gap-xs white-bg spacer-vxs align-items-center border-grey border-radius-m flex-1";

    if (manifestDetails?.manifest?.scannedAtDestination === true) {
        elementClass += " highlight-on-hover-clickable"
    }
    else {
        elementClass += " dark-grey-bg grey-text"
    }

    return (
        <div className="flex-box flex-gap-m spacer-hm align-items-center justify-content-space-between" style={{ color : "#000" }}>
            <div className={elementClass} onClick={_ => onSparklineClick("PENDING")} >
                <div>Pending</div>
                <div className="font-size-l-2">{manifestDetails?.pendingCount}</div>
            </div>
            <div className={elementClass} onClick={_ => onSparklineClick("SCANNED")} >
                <div>Scanned</div>
                <div className="font-size-l-2">{manifestDetails?.scannedCount}</div>
            </div>
            <div className={elementClass} onClick={_ => onSparklineClick("OVERAGE")} >
                <div>Overage</div>
                <div className="font-size-l-2">{manifestDetails?.overageCount}</div>
            </div>

            {
                modalDetails.showModal &&
                <Modal visible={modalDetails.showModal} className="remove-padding"
                 closable={false} onCancel={_ => {
                    SetModalDetails({
                        showModal : false,
                        modalType : undefined,
                        consignmentList : undefined
                    });
                 }}
                 okButtonProps={{ className : "display-none" }}
                 okText="Close"
                 title={modalDetails.modalType ? `${capitalizeString(modalDetails.modalType?.toLowerCase())} Consignments` : ""}>
                    <div style={{ overflowX : "scroll" }}>
                            {/* Filtering columns to remove action column for scanned */}
                        {
                            modalDetails.consignmentList ?
                            <Table columns={[...columns].filter(c => !(modalDetails.modalType === "SCANNED" && c.dataIndex == "reasons"))} dataSource={modalDetails.consignmentList?.consignments}
                             rowKey={"waybillNo"}
                             pagination={{
                                total    : modalDetails.consignmentList?.statusCount,
                                pageSize : 5
                             }} /> :
                            <div className="flex-box justify-content-center spacer-m">
                                <Spin spinning={true} />
                            </div>
                        }
                     </div>
                </Modal>
            }
        </div>
    )
}