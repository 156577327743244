import { storePaginatedData } from "../../../common/utils/utils";
import { ACTION_TYPES, INITIAL_STATE } from "./constants";

export const podLiveReducer = (state=INITIAL_STATE , action = {}) => {
    switch(action.type) {
        case ACTION_TYPES.FETCH_CONSIGNMENTS_BEGIN:
        case ACTION_TYPES.FETCH_SPARK_LINE_DATA__BEGIN:
        return {
                ...state,
                // loading:true
            };

        case ACTION_TYPES.FETCH_CONSIGNMENTS_SUCCESS:
            return{
                ...state,
                loading: false,
                // PaginatedPODLive: storePaginatedData(action?.payload?.currentPageNo,state.PaginatedPODLive ? state.PaginatedPODLive : {}, action?.payload?.drses || action?.payload?.viewResponse, action.isReset ),
                PaginatedPODLive: action?.payload?.drses ? ( { [action.payload.currentPageNo] : action.payload.drses}) : ( (state.PaginatedPODLive ? state.PaginatedPODLive : {}) || action?.payload?.viewResponse),
                ...action.payload
            };

       case ACTION_TYPES.FETCH_CONSIGNMENTS_FAILURE:
       case ACTION_TYPES.FETCH_SPARK_LINE_DATA__FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case ACTION_TYPES.FETCH_SPARK_LINE_DATA__SUCCESS:
                return {
                    ...state,
                    loading: false,
                    dataCardList: action.payload
                }
         default:
            return state;
        }
}

