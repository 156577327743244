import React, { useEffect, useState } from "react";
import { formatDate, formatDateoption, getValueFromKey } from "../../common/utils/utils";
import { typography as Typography } from '../../components/ui/typography/Typography';

export default function EventDetails({ event, singleColumn, scanDetails, eventType }) {

    const [displayArray, SetDisplayArray] = useState(undefined);

    useEffect(_ => {
        var tempScanDetails = [...scanDetails];
        const checkForLocation = d => d.label?.indexOf("Location") > 0 ||
            d.dataIndex?.indexOf("location.name") > 0 ||
            d.dataIndex?.indexOf("location_name") > 0 ||
            d.dataIndex?.indexOf("loc_name") > 0;
        if (tempScanDetails.filter(checkForLocation)?.length <= 0) {
            tempScanDetails.push({
                label: "Location",
                dataIndex: "data.location_name",
                render: _ => <> {
                    getValueFromKey(event, "data.location_name") ||
                    getValueFromKey(event, "data.loc_name") ||
                    getValueFromKey(event, "user.location.name") ||
                    "NA"
                } </>
            })
        }
        const checkForEventTime = d => d.label?.indexOf("Event Time") > 0 || d.dataIndex?.indexOf("eventTime") > 0;
        if (tempScanDetails.filter(checkForEventTime)?.length <= 0) {
            tempScanDetails.push({
                label: "Event Time",
                dataIndex: "eventTime",
                render: _ => <> {formatDate(event?.eventTime, formatDateoption())} </>
            })
        }
        SetDisplayArray(tempScanDetails);

    }, [scanDetails, eventType]);

    return (
        <div className={(singleColumn ? "flex-column" : "flex-box flex-wrap") + " flex-gap-l"}>
            {
                displayArray && displayArray.map((d, i) => {

                    const dataContent = d?.render && d.render()
                        ? d.render({ ...event })
                        : <Typography condition={getValueFromKey(event, d.dataIndex)}>{getValueFromKey(event, d.dataIndex)}</Typography>;

                    return (
                        <div key={d.dataIndex || i} className={"flex-column flex-gap-m" + (!singleColumn ? " flex-basis-50-gap-l" : "")}>
                            <div className="font-size-sm-2 light-grey-text text-semi-bold">{d.label}</div>
                            <div className="data">
                                {dataContent}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}