import { ACTION_TYPES, INITIAL_STATE } from "./constants";

export const rtoEligibleReducer = (state=INITIAL_STATE , action = {}) => {
    switch(action.type) {
        case ACTION_TYPES.FETCH_CONSIGNMENTS_BEGIN:
        case ACTION_TYPES.FETCH_CONFIGURATIONS_BEGIN:
            return {
                ...state,
                loading:true
            };
        
        case ACTION_TYPES.FETCH_CONSIGNMENTS_SUCCESS:
            return {
                    ...state,
                    loading: false,
                    PaginatedRTOEligible: action?.payload?.consignments ? ( { [action.payload.currentPageNo] : action.payload.consignments}) : ((state.PaginatedRTOEligible ? state.PaginatedRTOEligible : {}) || action?.payload?.viewResponse),
                    ...action.payload
            };

        case ACTION_TYPES.FETCH_CONSIGNMENTS_FAILURE:
        case ACTION_TYPES.FETCH_CONFIGURATIONS_FAILURE:
            return {
                    ...state,
                    loading: false,
                    error: action.payload
                };
        
        case ACTION_TYPES.FETCH_CONFIGURATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                rtoAction: action.payload.rtoAction,
                config: action.payload.config
            };

        default:
            return state;
    }
}