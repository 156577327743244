import { apiService } from '../../common/apiService/service';
import * as apiUtils from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';
import { checkHttpStatus } from '../../common/utils/utils';

export const fetchVendors = _ => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        method: 'GET',
        url: `/b2b/v1/partners/${Constants.PARTNER_ID}/vendors?fetch=STRICT_BASIC&showActualData=true`
    })
        .then(response => {
            resolve(response?.data?.response?.vendors);
        })
        .catch(response => reject(response))
})

export const fetchVendorOptions = _ => new Promise((resolve, reject) => {
    fetchVendors()
        .then(vendors => {
            const enums = [], enumNames = [];
            vendors.map(vendor => {
                enums.push(vendor?.id);
                enumNames.push(vendor?.name);
            })
            resolve({ enums, enumNames })
        })
        .catch(response => reject(response))
})

export const callVehicleTypesAPI = _ => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url: "b2b/v1/vehicle-types/getAllVehicleTypes",
        method: "POST",
        data: { filter: { isActive: true } }
    })
        .then(response => {
            if (checkHttpStatus(response?.data?.status, false)) {
                resolve(response?.data?.response?.vehicleTypes)
            }
            else {
                reject([]);
            }
        })
        .catch(e => reject(e))
})

export const fetchVehicleTypesEnums = withId => new Promise((resolve, reject) => {
    callVehicleTypesAPI().then(vehicleTypes => {
        var enums = [];
        var ids = [];
        var enumNames = [];
        vehicleTypes.map(vehicle => {
            const vehicleOption = vehicle.vehicleType + " - " + vehicle.vehicleCapacity + vehicle.vehicleCapacityUnit.substr(0, 1) + " - " + vehicle.bodyType;
            if (enums.indexOf(vehicleOption) < 0) {
                enums.push(vehicleOption);
            }
            ids.push(vehicle.id);
            enumNames.push(vehicleOption);
        });
        if (withId) {
            resolve([ids, enumNames]);
        }
        else {
            resolve(enums);
        }
    }).catch(e => reject(false))
})

export const fetchVendorsV2 = (partnerId) => {
    return apiService.get(`hub/entity/${partnerId}/get-all-vendor`)
}

export const fetchVehicleTypeEnumV2 = () => {
    return apiService.get('hub/entity/vehicle-type')
}

export const fetchRoutesV2 = () => {
    return apiService.get('hub/trip/routes')
}