import { Button, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { Constants } from "../../../../common/utils/constants";
import { capitalizeString } from "../../../../common/utils/utils";
import { fetchPartners } from "../../../reportsv2/services/fetch.services";
import { fetchPartnerLocations } from "../../services/fetch.service";
import { userType } from "../../utils";

export default function CoDViewMenu({ onSearch, menuType, applyPartnerFilters, applyLocationFilter, resetFilter, disableMenu, exportCoDView, downloadingReport }) {

    const [ filterModalOpen, SetFilterModalOpen ] = useState(false);
    const [ partnerOptions, SetPartnerOptions ] = useState([]);
    const [ locationOptions, SetLocationOptions ] = useState([]);
    const [ filteredPartners, SetFilteredPartners ] = useState([]);
    const [ filteredLocations, SetFilteredLocations ] = useState([]);
 
    useEffect(() => {
        if (userType() === "ROOT_PARTNER" || userType() === "ROOT_PARTNER_ADMIN") {
            fetchPartners()
            .then(SetPartnerOptions)
            .catch(e => console.log(e));
        }
        else if (userType() === "PARTNER_ADMIN") {
            fetchPartnerLocations(Constants.PARTNER_ID)
            .then(SetLocationOptions)
            .catch(e => console.log(e));
        }
    }, [])

    return (
        <>
            <div className="flex-box justify-content-space-between">
                {
                    typeof onSearch === "function" &&
                    <Input.Search className="flex-1" onSearch={onSearch} allowClear={true} disabled={disableMenu} id="searchInput"
                    placeholder={`Search ${capitalizeString((menuType || "PARTNER").toLowerCase()).split("_").join(" ")}`} />
                }
                <div className="flex-1 flex-box flex-gap-m align-items-center justify-content-flex-end">
                    <Button disabled={disableMenu} onClick={_ => SetFilterModalOpen(true)} icon="filter"> Filter </Button>
                    <Button disabled={disableMenu} onClick={exportCoDView} loading={downloadingReport} icon="download"> Export </Button>
                </div>
            </div>
            
            <Modal width={"40%"} okText="Filter" cancelText="Reset"
             visible={filterModalOpen} title="COD Filters" closable={false}
             onOk={_ => {
                if (userType() === "ROOT_PARTNER" || userType() === "ROOT_PARTNER_ADMIN") {
                    applyPartnerFilters(filteredPartners);
                }
                else if (userType() === "PARTNER_ADMIN") {
                    applyLocationFilter(filteredLocations);
                }
                SetFilterModalOpen(false);
             }}
             onCancel={_ => {
                SetFilteredPartners([]);
                SetFilteredLocations([]);
                resetFilter();
                SetFilterModalOpen(false);
             }} >
                <div className="flex-column flex-gap-m">
                    {
                        (userType() === "ROOT_PARTNER" || userType() === "ROOT_PARTNER_ADMIN") &&
                        <>
                            <div className="light-grey-text font-size-m-1"> Select Partner </div>
                            <Select placeholder="Select partners" value={filteredPartners} allowClear={true}
                             onChange={SetFilteredPartners} mode="multiple" showSearch={true}
                             filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 } >
                                {
                                    partnerOptions && partnerOptions?.length > 0 &&
                                    partnerOptions?.map(partner => {
                                        if (partner.linkPartnerBO && partner.linkPartnerBO?.name) {
                                            return (
                                                <Select.Option value={partner.linkPartnerBO.id} key={partner.linkPartnerBO.id}>
                                                    {partner.linkPartnerBO.name}
                                                </Select.Option>
                                            )
                                        }
                                    })
                                }
                            </Select>
                        </>
                    }
                    {
                        userType() === "PARTNER_ADMIN" &&
                        <>
                            <div className="light-grey-text font-size-m-1"> Select Locations </div>
                            <Select placeholder="Select locations" value={filteredLocations} allowClear={true}
                             onChange={SetFilteredLocations} mode="multiple" showSearch={true}
                             filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 } >
                                {
                                    locationOptions && locationOptions?.length > 0 &&
                                    locationOptions.map(l => <Select.Option value={l.id} key={l.id}>{l.name}</Select.Option>)
                                }
                            </Select>
                        </>
                    }
                </div>
            </Modal>
        </>
    )

}