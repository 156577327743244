import React from "react";
import { Icon } from "antd";
let images = require.context("../../../assets/img/icons", true);

const icons = {
  copy: "copy",
  poweroff: "poweroff",
  "menu-unfold": "menu-unfold",
  "menu-fold": "menu-fold",
  exclamation: "exclamation-circle",
  phone: "phone",
  warning: "warning",
  "plus-circle": "plus-circle",
  "minus-circle": "minus-circle"
};
export const icon = props => {
  if (icons[props.type]) {
    return <Icon type={icons[props.type]} {...props} />;
  } else {
    let src;
    try {
      src = images(`./${props.img}`);
    } catch (err) {
      return null;
    }

    return <img alt={props.alt} src={src} {...props} />;
  }
};
