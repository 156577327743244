import { Alert, Button, Icon, Input, Spin } from "antd";
import React, { useEffect } from "react";
import StartScan from "../../../assets/img/mweb/startscan.png";
import TamperedReasonSelect from "../../common/tampered-reason-select";

export default function MwebScanUI (props) {

    const { inBoundScanning, isAuditOn, InputSearchRef, waybillNumber, totalScannedItems, inputPlaceholder,
    scanWayBill, scanDrsByKeyboard, scanResponseStatus, scanResponseDataCurated, barcodeEnabled } = props;

    useEffect(() => {
        refocusOnInput();
    }, [ scanResponseStatus ])

    const refocusOnInput = _ => {
        const scanInput = document.getElementById("scanWaybillInput");
        if (scanInput) {
            scanInput.blur();
            scanInput.setAttribute("readonly", "readonly"); 
            scanInput.focus();
            scanInput.removeAttribute("readonly");  
        }
    }

    return (
        <div className="flex-column flex-gap-m" style={{ height : "calc(100vh - 50px)"}}>
            <div style={{ backgroundColor : "#dfe3f8"}} className=" spacer-m flex-box flex-gap-l">
                <div style={{color : "#3142AC"}}> Scanned Item Count : </div>
                <div> {totalScannedItems} </div>
            </div>
            <form className="spacer-s" onSubmit={e => { e.preventDefault(); scanWayBill(barcodeEnabled ? 'barcode' : 'waybill', true) }}>
                <Input size="large" disabled={(inBoundScanning || isAuditOn)}
                    placeholder={`Scan ${inputPlaceholder}`}
                    id={"scanWaybillInput"}
                    onChange={e => scanDrsByKeyboard(e, e.target.value)}
                    ref={InputSearchRef}
                    autoComplete={"off"}
                    value={waybillNumber}
                    suffix={<>
                        <Button onClick={_ => scanWayBill(barcodeEnabled ? 'barcode' : 'waybill')}
                        style={{
                            height: 38,
                            borderRadius: "0px 3px 3px 0px",
                            marginRight: -15
                        }} type="primary" className="lsn-btn-primary">
                            <Icon type="barcode" /> Scan
                        </Button>
                    </>}
                />
            </form>
            {
                inBoundScanning ?
                <Spin className="flex-box justify-content-center"/> : 
                <>
                    {
                        (scanResponseStatus.error || scanResponseStatus.success)  ?
                        <div className="spacer-s flex-column flex-gap-m">
                            <div> SHIPMENT DETAILS </div>
                            <div className="white-bg borde-radius-m spacer-s flex-column flex-gap-l">
                                {
                                    scanResponseStatus.error &&
                                    <Alert type="error" message={(
                                        <div className="flex-box flex-gap-m">
                                            <div> Status </div>
                                            <div> : </div>
                                            <div className="text-bold"> ADDED TO OVERAGE </div>
                                        </div>
                                    )} className={"error-color"} />
                                }
                                {
                                    scanResponseStatus.success &&
                                    <>
                                        <Alert message={(
                                            <div className="flex-box flex-gap-m">
                                                <div> Status </div>
                                                <div> : </div>
                                                <div className="text-bold"> IN </div>
                                            </div>
                                        )} type="success" className={"success-color"} />
                                        <div className="flex-column flex-gap-m">
                                            <div className="light-grey-text"> Next Location </div>
                                            <div className="font-size-l-1 text-bold"> {scanResponseDataCurated?.nextLocation?.value} </div>
                                        </div>
                                        <div className="flex-column flex-gap-m">
                                            <div className="light-grey-text"> Destination Pincode </div>
                                            <div className="font-size-m-3 text-bold"> {scanResponseDataCurated?.locationCode?.value} </div>
                                        </div>
                                        <div className="flex-column flex-gap-m">
                                            <div className="light-grey-text"> Waybill No. </div>
                                            <div className="font-size-m-3 text-bold"> {scanResponseDataCurated?.waybillNo?.value} </div>
                                        </div>
                                        {
                                            scanResponseDataCurated?.batchNumber?.value &&
                                            <div className="flex-column flex-gap-m">
                                                <div className="light-grey-text"> Batch Number </div>
                                                <div className="font-size-m-3 text-bold"> {scanResponseDataCurated?.batchNumber?.value}  </div>
                                            </div>
                                        }
                                        <div>
                                            {
                                                scanResponseDataCurated &&
                                                <TamperedReasonSelect scanType={"INSCAN"} onTamperSuccess={refocusOnInput}
                                                    customerId={scanResponseDataCurated?.consignmentScanBO?.customerId}
                                                    waybillNo={scanResponseDataCurated?.waybillNo?.value} />
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div> :
                        <div className="spacer-m flex-column justify-content-center align-items-center" style={{ marginTop : 40 }}>
                            <img src={StartScan} width="50%" height="auto" />
                            <div className="font-size-m-3"> SCAN WAYBILL NO </div>
                            <div className="grey-text"> Scan waybill numbers to add into manifest </div>
                        </div>
                    }
                </>
            }
        </div>
    )
}