import React, { useState } from "react";
import {
    Card,
    Table,
    Empty,
    Icon,
    Tag,
    Input,
    Button,
    Tooltip,
} from "antd";

import { connect, useSelector } from "react-redux";
import moment from "moment";
import * as _Constants from "../constants";
import { DownloadTags, ReportTypes } from "../../../../common/utils/constants";
import { capitalizeString, notifyApiError, notifyApiSuccess, reportsDownload } from "../../../../common/utils/utils";
import MiscService from "../../../../services/misc.service";
import RTOServices from "../../../../services/rto/rto.service";
import RTODownloadModal from "../rto-download-modal";
import DRSFilters from "../../../common/drs-filter";
import WaybillLink from "../../../shared-components/waybill-link";
import { deleteRTODRS } from "../services";
import { Link } from "react-router-dom";

const RTODRSTable = ({
    data,
    getPaginatedData,
    pageNo,
    newDrsData,
    applyPagination,
    editRTODRS
}) => {

    const rtoServices = new RTOServices();

    const [modalInfo, setModalInfo] = useState({
        show: false,
        selectedDrs: null,
    });
    const config = useSelector((state) => state?.app?.configurations);

    const showDeleteModal = (rowData) => {
        setModalInfo({
            ...modalInfo,
            show: true,
            selectedDrs: rowData,
        })
    };

    const handleDelete = () => {
        if(modalInfo.selectedDrs){
            const {selectedDrs} = modalInfo;
                return deleteRTODRS(selectedDrs.drsCode)
                .then(_ => {
                    notifyApiSuccess("Successfully deleted RTO DRS!");
                    applyPagination({ pageNo: pageNo}, false);
                    handleCancel();
                })
        }
        setModalInfo({
            ...modalInfo,
            show: false,
            selectedDrs: null,
        })
        handleCancel();
    };

    const handleCancel = () => {
        setModalInfo({
            ...modalInfo,
            show: false,
            selectedDrs: null,
        })    
    };

    const columns = [
        {
          title: "DRS ID",
          dataIndex: "drsCode",
          key: "drsId",
        },
        {
          title: "Waybills",
          dataIndex: "row.consignments.length",
          key: "waybills",
          width : 100,
          render: (text, row) => <span>{row.consignments && row.consignments.length ? row.consignments.length : 0}</span>
        },
        {
          title: "Employee",
          dataIndex: "drsUser",
          key: "drsUser",
          render: (data, row) => {
              return(
                <span>{data.name}<br /> <small>{data.contactNumber}</small></span>
              )
          }
        },
        { 
          title: "CreatedOn",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (data, row) => {
              return(
                <div>
                    {moment(data).format("DD/MM/YYYY h:mm a")} <br /> <small>{moment(row.createdAt).fromNow()}</small>
                </div>
              )
          }
        },
        {
            title: "Status",
            key: "drsStatus",
            dataIndex: "drsStatus",
            render: drsStatus => {
                let tagClassName = "info-tag";
                switch (drsStatus) {
                    case "CLOSED"       : tagClassName = "success-tag"; break;
                    case "PENDING"      : 
                    case "OPEN"         : tagClassName = "error-tag"; break;
                    case "HANDED_OVER"  : tagClassName = "secondary-info-tag"; break;
                    case "COMPLETED"    : tagClassName = "warning-tag"; break;
                    case "IN_TRANSIT"   : tagClassName = "info-tag"; break;
                    default             : return "";
                }  
                if(tagClassName){
                    return(
                        <Tag className={"ant-table-tag text-align-center " + tagClassName}> 
                            {
                                (drsStatus.split("_").map(s => capitalizeString(s.toLowerCase()))).join(" ")
                            }
                        </Tag>
                    )
                }
            }
        },
        { 
          title: "Action",
          dataIndex: "action",
          key: "action",
          render: (status, row) => {
            const drsId = true ? row.drsCode : row.id
            return (
                <div className="flex-box justify-content-space-evenly align-items-center">
                    {
                        (row.drsStatus === "PENDING" || row.drsStatus === "IN_TRANSIT") && 
                        row.consignments && row.consignments.length > 0 &&
                        <Link to={`/appv2/rto/form/drs/pod/${drsId}`}>
                            <Button type="primary">
                                Create POD
                            </Button>
                        </Link>
                    }
                    {
                        row.drsStatus === "PENDING" &&
                        <>
                            <Icon type="edit" onClick={_ => editRTODRS(row.id, row.drsCode)} />
                            {
                                (!row.consignments || row.consignments.length < 1) &&
                                <Icon type="delete" onClick={() => showDeleteModal(row)}/>
                            }
                        </>
                    }
                    {
                        (["PENDING", "IN_TRANSIT", "COMPLETED", "CLOSED"].indexOf(row.drsStatus) > -1) &&
                        (row.consignments && row.consignments.length > 0) &&
                        <Tooltip title={"Download DRS Report"}>
                            <Icon type="download" onClick={() => downloadDrsData(row.id)}/>
                        </Tooltip>
                    }
                </div>
            )
            }
        },
    ];

    const downloadDrsData = (drsId) => {
        const params = {
          drsIds: [drsId],
          documentType: "RTO_DRS_SHEET",
          drsType: "B2C"
        };
        
    
        MiscService.getReports({ type: ReportTypes.rtoDrsSheet, params: params }).then(({ data }) => {
          if (data != null) {
            reportsDownload(data.response.url, DownloadTags.s3);
          }
        });
    }

    const expandedRowRender = (row, t, i) => {
          const column2 = [
            {
                title: "Waybill",
                dataIndex: "waybillNo",
                key: "waybillNo",
                render : waybillNo => <WaybillLink>{waybillNo}</WaybillLink>
              },
              {
                title: "CreatedOn",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (data, row) => {
                    return(
                      <div>{moment(data).format("DD/MM/YYYY h:mm a")} <br /> <small>{moment(row.createdAt).fromNow()}</small></div>
                    )
                }
              },
              {
                title: "Status",
                key: "consignmentStatus",
                dataIndex: "consignmentStatus",
                render: consignmentStatus => {
                    return <Tag className={"ant-table-tag text-align-center warning-tag"}> { consignmentStatus } </Tag>
                }
            }
        
        ]
        return <Table size="middle" columns={column2} dataSource={row.consignments} pagination={false} />;
    };

    const onFormSubmit = (data, reset) => applyPagination(data, reset);

    const actionBtns = ( <DRSFilters onFormSubmit={onFormSubmit} /> );

    return(
        <Card className="rto-drs" title={<h2>DRS List</h2>} extra={actionBtns}>
            <Table bordered size="middle"
            rowKey={row => row.id} columns={columns} scroll={{ x: 800 }}
            locale={{
                emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
            }}
            defaultExpandAllRows={false}
            expandedRowRender={expandedRowRender}
            pagination={{
                onChange: getPaginatedData,
                total: data.totalCount,
                position: "bottom",
                current: pageNo,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                pageSize: data.pageSize
              }}
            dataSource={newDrsData} />
            {
                modalInfo.show && modalInfo.selectedDrs && (
                    <RTODownloadModal 
                        isModalVisible = {modalInfo.show}
                        handleCancel = {handleCancel}
                        handleDelete = {handleDelete}
                        showDeleteModal = {showDeleteModal}
                    />
                )
            }
        </Card>
    )
};

const mapStateToProps = state => ({
    data: state.rtoDrs
});

export default connect(
    mapStateToProps,
    null
)(RTODRSTable);