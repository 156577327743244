import { apiRequest } from "../../common/utils/apiGateway";
import { Constants } from '../../common/utils/constants';

const RouteService = {
    getRouteFetch: (payload = {}) => {
        return apiRequest({
            url: `/b2b/v1/partners/${Constants.PARTNER_ID}/routes/fetch`,
            method: "POST",
            data: {
                filters: {
                    ...payload?.filters,
                    partnerIds: payload?.emptyPartnerIdArray ? [] : [Constants.PARTNER_ID]
                }
            }
        })
    },

    getLocationsFetch(payload = {}) {
        return apiRequest({
            url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`,
            method: "POST",
            data: {
                filters: {
                    ...payload?.filters,
                }
            }
        })
    },


    deleteRoute: (RouteId) => {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/routes/${RouteId}`;
        return apiRequest({
            url,
            method: "DELETE",
        })

    },

    createRoute: (route) => {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/routes`;
        return apiRequest({
            url,
            method: "POST",
            data: route
        })
    },

    updateRoute: (routeId, route) => {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/routes/${routeId}`;
        return apiRequest({
            url,
            method: "PUT",
            data: route
        })
    },

    getRoute: (routeId) => {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/routes/${routeId}`;
        return apiRequest({
            url,
            method: "GET",
        })
    },

    getLocation: (param, payload) => {
        const url = `/b2b/v1/partners/${param}/locations/fetch`;
        return apiRequest({
            url,
            method: "POST",
            data: payload
        })
    },

    getStartLocation: (param, payload) => {
        const url = `/b2b/v1/partners/${param}/locations/fetch`;
        return apiRequest({
            url,
            method: "POST",
            data: payload
        })
    },

    getZones: (payload) => {
        const url = `/b2b/v1/zone/fetch`;
        return apiRequest({
            url,
            method: "POST",
            data: payload
        })
    },

    refreshserviceability: (payload) => {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/routes/refreshserviceability`;
        return apiRequest({
            url,
            method: "POST",
            data: payload
        })
    },
}

export default RouteService