import moment from "moment";
import { Constants } from "../../common/utils/constants";
import { previewMapping } from "./config.generator";
import { initialState } from "./state/reports.reducer";

const addField = (formFields, blueprint, additionalFieldConfigs, propsUiSchema, formData, requiredConfig, errorList) => {
    var propsSchema = {};
    const required = [];
    formFields.map(field => {
        if (blueprint[field]) {
            propsSchema[field] = blueprint[field].schema
            propsUiSchema[field] = blueprint[field].uiSchema
            if (blueprint[field].initValue !== undefined) {
                formData[field] = blueprint[field].initValue;
            }
            if (blueprint[field].errorList) {
                errorList[field] = blueprint[field].errorList;
            }
        }
        if (additionalFieldConfigs && additionalFieldConfigs[field]) {
            var fieldConfig = additionalFieldConfigs[field];
            if (fieldConfig.widget && blueprint[fieldConfig.widget]){
                propsSchema[field] = blueprint[fieldConfig.widget].schema;
                propsUiSchema[field] = blueprint[fieldConfig.widget].uiSchema;
                propsSchema[field].title = fieldConfig.field;
                if (blueprint[fieldConfig.widget].initValue !== undefined) {
                    formData[field] = blueprint[fieldConfig.widget].initValue;
                }
            }
            if(fieldConfig.schema){
                propsSchema[field] = fieldConfig.schema
            }
            if(fieldConfig.uiSchema){
                propsUiSchema[field] = fieldConfig.uiSchema
            }
            if (fieldConfig.title && propsSchema[field]) {
                propsSchema[field].title = fieldConfig.title;
            }
            if (fieldConfig.initValue !== undefined) {
                formData[field] = fieldConfig.initValue;
            }
        }
        if(requiredConfig && requiredConfig.indexOf(field) > -1) {
            required.push(field);
        }
    })
    return { propsSchema, required };
}

export const gernerateReportFormSchema = (config, blueprint) => {
    if (config.downloadType) {
        var oneOfOptions  = [];
        var propsUiSchema = {};
        var formData      = initialState.reportFormData;
        var errorList     = {};
        if (config.formFields) {
            config.downloadType.map(d => {
                if (config.formFields[d]) {
                    var {propsSchema, required} = addField(config.formFields[d], blueprint, config.additionalFieldConfigs, propsUiSchema, formData, config.required, errorList);
                    var oneOfSchema = {
                        required,
                        properties : {
                            ...propsSchema,
                            downloadType : {
                                enum : [ d ],
                            }
                        }
                    };
                    oneOfOptions.push(oneOfSchema);
                }
            })
        }

        const uiSchema = {
            ...propsUiSchema,
            classNames   : "hide-title",
            downloadType : {
                "ui:widget" : "radio"
            }
        }

        const schema = { 
            type         : "object",
            properties   : {
                downloadType : {
                    type  : "string",
                    title : "Download Type",
                    enum      : config.downloadType,
                    enumNames : config.downloadTypeLabel ? config.downloadTypeLabel : config.downloadType
                }
            },
            dependencies : {
                downloadType : {
                    oneOf : oneOfOptions
                }
                
            }
            
        }

        return { 
            schema, uiSchema,
            formData : {
                downloadType : config.downloadType[0],
                ...formData
            },
            errorList
        };
    }
    else {
        var propsUiSchema = {};
        var formData      = initialState.reportFormData;
        var errorList      = {};
        var {propsSchema, required} = addField(config.formFields.default, blueprint, config.additionalFieldConfigs, propsUiSchema, formData, config.required, errorList);
        const uiSchema = {
            classNames       : "hide-title",
            ...propsUiSchema
        }

        const schema = { 
            type         : "object",
            properties   : propsSchema,
            required
            
        }

        return { schema, uiSchema, formData, errorList };
    }
}

export const generatePostObject = (data, reportConfig, action, summaryEnum, emailTo) => {
    const { postObjReference, additionalPostAttributes, additionalPostAttributesByDownloadType } = reportConfig;
    var keyRef = [];
    if (reportConfig.downloadType && data.downloadType) {
        keyRef = reportConfig.formFields[data.downloadType];
    }
    else {
        keyRef = reportConfig.formFields.default;
    }
    const documentType = action.id === "greport" ? data.reportType : previewMapping[data.reportType];
    var postObj = {
        documentType        : documentType,
        loggedInUserTokken  : true,
    //  optimizedFetch      : true,
        drsType             : "B2C",
        partnerId           : Constants.PARTNER_ID,
        locationId          : Constants.userLocationId,
        userId              : Constants.sessionUser.id,
        summaryEnum         : action.id === "preview" ? summaryEnum : null,
        emailTo             : emailTo
    };
    if (postObjReference) {
        keyRef.map(key => {
            if (data[key]) {
                if (Array.isArray(data[key])) {
                    if (data[key].length === 1 && (data[key][0]?.key == -1 || data[key][0]?.key == undefined)) {
                        postObj[postObjReference[key]] = null;
                    }
                    else {
                        postObj[postObjReference[key]] = data[key]?.map(d => d.key || d.value);
                    }
                }
                else if (key === "dateRange") {
                    const fromDate = parseInt(data[key].split(",")[0]);
                    const toDate   =  parseInt(data[key].split(",")[1]);
                    postObj["dateRange"] = {
                        from : moment(fromDate).startOf("day").valueOf(),
                        to   : moment(toDate).endOf("day").valueOf()
                    }
                }
                else {
                    postObj[postObjReference[key]] = data[key];
                }
            }
        })
    }
    if (additionalPostAttributes) {
        postObj = {
            ...postObj,
            ...additionalPostAttributes
        }
    }
    if (additionalPostAttributesByDownloadType && data.downloadType) {
        postObj = {
            ...postObj,
            ...additionalPostAttributesByDownloadType[data.downloadType]
        }
    }
    if (action.additionalPostAttributes) {
        postObj = {
            ...postObj,
            ...action.additionalPostAttributes
        }
    }
    if (postObj.s3FileLink) {
        if (postObj.fileFilters) {
            postObj.fileFilters.s3Path = postObj.s3FileLink;
        }
        else {
            postObj.fileFilters = { s3Path  : postObj.s3FileLink };
        }
        postObj.filename = postObj.s3FileLink.split("/").pop()
    }
    return { ...postObj, documentSubType : null };
}

export const getKeyStrings = (obj, parentObjKey) => {
    var keyStrings = [];
    Object.keys(obj).map(key => {
        if (typeof obj[key] === "string") {
            keyStrings = Object.keys(parentObjKey ? parentObjKey + "." + obj : obj);
        }
        else if (typeof obj[key] === "object") {
            if (Array.isArray(obj[key])) {
                keyStrings.push(parentObjKey ? parentObjKey + "." + key : key);
            }
            else {
                keyStrings = [...keyStrings, ...getKeyStrings(obj[key], key)];
            }
        }
    })
    return keyStrings;
}

export const formPreviewColumns = header => header.reduce((current, next, currentIndex) => {
    if (typeof next === "string") {
        var title = next.split(".")[next.split(".")?.length - 1];
        current.push({
            title     : title,
            key       : next,
            dataIndex : next,
            fixed     : currentIndex === 0 && header.length > 1,
            width     : currentIndex === 0 && header.length > 1 ? 120 : undefined,
        })
    }
    else if (typeof next === "object") {
        const additionalColumns = Object.keys(next).map(key => {
            var title = key.split(".")[key.split(".")?.length - 1];
            return {
                title     : title,
                key       : key,
                dataIndex : key,
                children  : getKeyStrings(next).map(c => {
                    var _cTitle = c.split(".")[c.split(".")?.length - 1];
                    return {
                        title     : _cTitle,
                        key       : c,
                        dataIndex : c
                    }
                })
            }
        });
        current = [ ...current, ...additionalColumns ]
    }
    return current;
}, [])