import { apiRequest } from "../../common/utils/apiGateway";

export default class MonitoringReportsService {
    getEmbeddedURL(reportName, params) {
        const url = "/b2b/v1/embed-url";
        const payload = {
            reportName,
            ...params
        }
        return apiRequest({
            url,
            method: "POST",
            data: payload
        });
    }

    getEmbeddedURLByPayload(payload) {
        const url = "/b2b/v1/embed-url-generic";
        return apiRequest({
            url,
            method: "POST",
            data: payload
        });
    }
}