import React from "react";
import { Divider, Table, Radio } from "antd";
import RadioGroup from "antd/lib/radio/group";

export default function PreviewViewer (props) {
    
    const { previewColumns, previewData, summaryEnum, SetSummaryEnum } = props;

    return (
        <>
            <Divider style={{ margin : 0 }} />
            <div id="previewWrapper">
                <div className="flex-column flex-gap-l">
                    <div className="text-bold font-size-m-3"> Report Preview </div>
                    <div className="flex-box justify-content-space-between">
                        <div></div>
                        <div>
                            <RadioGroup onChange={event => SetSummaryEnum(event?.target?.value)} defaultValue={summaryEnum} value={summaryEnum} >
                                <Radio className="report-preview-radio" value="FE_WISE"> Overall </Radio>
                                <Radio className="report-preview-radio" value="LOCATION_WISE"> Location Wise </Radio>
                            </RadioGroup>
                        </div>
                    </div>
                    {
                        Object.keys(previewColumns).map(key => {
                          if (previewColumns[key] && previewData[key]) {
                            return  (
                                <div key={key} className="flex-column flex-gap-m">
                                    <div className="font-size-l-1"> {key} </div>
                                    <Table columns={previewColumns[key]} rowKey={previewColumns[key][0]?.key}
                                     dataSource={previewData[key]} bordered={true} size="middle" />
                                </div>
                            )
                          }
                        })
                    }
                </div>
            </div>
        </>
    )
}