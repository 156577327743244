const schema = {
    "type": "object",
    "required": [ "vendorId", "driverName", "vehicleNumber", "tripType", "driverContactNumber", "vehicleType", "tripCategory", "routeId" ],
    "properties": {
        "vendorId" : {
            "type"  : "string",
            "title" : "Vendor",
            "searchEnabled" : true
        },
        "vehicleNumber": {
            "type": "string",
            "title": "Vehicle Number",
            "pattern" : "^[a-zA-Z]{2,3}[0-9]{0,2}[a-zA-Z]{0,3}[0-9]{4}$"
        },
        "tripType": {
            "type": "string",
            "title": "Trip Type",
            "enum": ["FTL", "PTL", "Coloader"]
        },
        "driverName": {
            "type"      : "string",
            "title"     : "Driver Name",
            "pattern"   : "^[a-zA-Z .]*$"
        },
        "tripCategory": {
            "type": "string",
            "title": "Trip Category",
            "enum": ["Regular", "AdHoc"]
        },
        "driverContactNumber": {
            "type"  : "number",
            "title" : "Driver Contact"
        },
        "vehicleType": {
            "type": "string",
            "title": "Vehicle Type",
            "searchEnabled" : true
        },
        "routeId": {
            "type": "string",
            "title": "Route",
            "searchEnabled" : true
        }
    }
}

const uiSchema = {
        "classNames": "multi-row-field-objects hide-title",
        "vendorId": {
            "ui:placeholder"      : "Select Vendor"
        },
        "vehicleNumber" : {
            "ui:placeholder" : "Enter vehicle number"
        },
        "tripType"      : {
            "ui:placeholder" : "Select Trip Type",
            "ui:widget"      : "SelectWidget"
        },
        "driverName"    : {
            "ui:placeholder" : "Enter Driver Name",
        },
        "tripCategory"  : {
            "ui:placeholder" : "Select Trip Category"
        },
        "driverContactNumber" : {
            "ui:placeholder"  : "Enter Driver Contact Number",
            "type"    : "number",
            "maxLen"  : 10
        },
        "vehicleType"   : {
            "ui:placeholder" : "Select Vehicle Type"
        },
        "routeId"       : {
            "ui:placeholder"      : "Select Route",
            "dropdownPlaceholder" : "Start Typing to search (min 3 charachters)..."
        }
}

const errorList = {
    "vendorId"  : {
        "required"   : "Vendor is mandatory!",
        "ignoreType" : true
    },
    "driverName"  : {
        "required"  : "Driver Name is mandatory!",
        "pattern"   : "Driver Name can only contain alphabets!"
    },
    "tripType": {
        "required"  : "Trip Type is mandatory!"
    },
    "tripCategory": {
        "required"  : "Trip Category is mandatory!"
    },
    "vehicleType": {
        "required"   : "Vehicle Type is mandatory!",
        "ignoreType" : true
    },
    "vehicleNumber" : {
        "required"  : "Vehicle Number is mandatory!",
        "pattern"   : "Vehicle Number is entered incorrectly!"
    },
    "driverContactNumber" : {
        "required"  : "Driver Contact is mandatory!",
    },
    "routeId" : {
        "required"   : "Route is mandatory!",
        "ignoreType" : true
    }
}

export default { schema, uiSchema, errorList };