import moment from "moment";
import React from "react";

export default function PickupSparkline({ 
    keyReference,
    dataObject,
    dataStyleObj,
    lastRefreshed,
    refreshLeadInfo,
    onSparklineClick
}) { 

    return (
        <div className="spacer-s">
            <fieldset className="default-fieldset">
                <legend> Today's Events </legend>
                <div className="flex-box flex-gap-l flex-wrap">
                    {
                        Object.keys(keyReference).map((key, index) => {
                            if (dataObject && dataObject[key] !== undefined)  {
                                return (
                                    <div onClick={_ => typeof onSparklineClick === "function" ? onSparklineClick(key) : undefined} key={index}
                                    className={"flex-1 border-radius-l justify-content-space-between border-grey spacer-vs flex-column flex-gap-s" + (typeof onSparklineClick === "function" ? " cursor-pointer" : "")}>
                                        <div className="font-size-sm-2 grey-text spacer-hm"> {keyReference[key]} </div>
                                        <div style={dataStyleObj ? dataStyleObj[key] : {}}
                                        className="text-bold font-size-l-2 spacer-hm">
                                            {dataObject[key] || 0}
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                    {
                        dataObject && Object.keys(dataObject).length > 0 &&
                        <div key={keyReference?.length} className="flex-1 justify-content-space-between border-radius-l border-grey flex-column flex-gap-s">
                            <div className="flex-1 flex-column align-items-center flex-gap-xs font-size-sm-2 grey-text spacer-xs align-items-center justify-content-center">
                                <div> Last Refreshed </div>
                                <div> {moment(lastRefreshed).format("DD/MM/YYYY hh:mm A")} </div>
                            </div>
                            <div style={{ borderRadius : "0px 0px 15px 15px" }} onClick={refreshLeadInfo}
                            className="flex-1 spacer-xs flex-box  align-items-center justify-content-center info-tag cursor-pointer">
                                Refresh
                            </div>
                        </div>
                    }
                </div>
            </fieldset>
        </div>
    )
}