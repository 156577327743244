export const EXCEPTION_STATUS =  {
    OPEN      : {
        label : "Open",
        value : "OPEN",
        key   : "OPEN",
        class : "warning-tag"
    },
    NEEDS_ACTION : {
        label : "Needs Action",
        value : "NEEDS_ACTION",
        key   : "NEEDS_ACTION",
        class : "info-tag"
    },
    RESOLVED  :   {
        label : "Resolved",
        value : "RESOLVED",
        key   : "RESOLVED",
        class : "success-tag"
    },
    ESCALATED : {
        label : "Escalated",
        value : "ESCALATED",
        key   : "ESCALATED",
        class : "warning-tag"
    }
}

export const EXCEPTION_SCAN_TYPES = {
    EXCEPTION : {
        label : "Force Inscan Misrouted Waybill",
        value : "EXCEPTION",
        scanSuccessMsg  : "Waybill force inscanned successfully"
    },
    WRONG_FACILITY : {
        label  : "Scan as Wrong Facility Shipment",
        value  : "WRONG_FACILITY",
        apiURL : "/b2b/v1/consignments/scan",
        scanSuccessMsg : "Waybill scanned as Wrong Facility"
    }
}

export const EXCEPTION_TYPE =  {
    OVERAGE : {
        value : "OVERAGE",
        key   : "OVERAGE",
        label : "Overage"
    },
    SHORTAGE : {
        value : "SHORTAGE",
        key   : "SHORTAGE",
        label : "Shortage"
    },
    WRONG_FACILITY : {
        value : "WRONG_FACILITY",
        key   : "WRONG_FACILITY",
        label : "Wrong Facility"
    },
    MISROUTE  : {
        value : "MISROUTE",
        key   : "MISROUTE",
        label : "Misroute"
    },
    DAMAGED  : {
        value : "DAMAGED",
        key   : "Damaged",
        label : "Damaged"
    }
}