import { apiService } from "../../../common/apiService/service";
import { apiRequest } from "../../../common/utils/apiGateway"

export const fetchOutboundTripByCode = code => execPostURL({
    tripCode: code,
    tripFetchType: "OUTBOUND_TRIP"
}, "/hydra/v1/trip/fetch");

export const fetchInboundTripByCode = code => execPostURL({
    tripCode: code,
    tripFetchType: "INBOUND_TRIP"
}, "/hydra/v1/trip/fetch");

export const fetchPendingConnections = (tripId, params) => fetchConnectionsCodeStatus(tripId, params, "PENDING")

export const fetchScannedConnections = (tripId, params) => fetchConnectionsCodeStatus(tripId, params, "LINKED")

const fetchConnectionsCodeStatus = (tripId, params, status) => {
    const postObj = {
        pageNo: params && params.pageNo ? params.pageNo : 1,
        pageSize: params && params.pageSize ? params.pageSize : 10,
        tripId: tripId,
        locationIds: params && params.locationIds ? params.locationIds : [],
        connectionStatuses: status ? (typeof status === "string" ? [status] : status) : [],
        connectionCodeForSearch: params && params.connectionCodeForSearch ? params.connectionCodeForSearch : undefined
    }
    return execPostURL(postObj, "/b2c/v1/connection/sheet/fetch")
}

export const connectiontionToTrip = (tripId, connectionCode) => {
    const postObj = {
        code: connectionCode,
        tripId: tripId,
        scanEntityType: "CONNECTION"
    }
    return execPostURL(postObj, "/hydra/v1/trip/load")
}

export const delinkConnection = (tripId, connectionCode) => {
    const postObj = {
        code: connectionCode,
        tripId: tripId,
        scanEntityType: "CONNECTION"
    }
    return execPostURL(postObj, "/hydra/v1/trip/connection/delink");
}

export const loadTrip = tripId => {
    const postObj = {
        action: "LOAD_TRIP",
        tripId: tripId
    }
    return execPostURL(postObj, "/hydra/v1/trip/update");
}

const execPostURL = (data, url) => new Promise((resolve, reject) => {
    apiRequest({
        url: url,
        method: "POST",
        data: data
    })
        .then(response => {
            if (response.data?.status?.code == "200" || response.data?.status?.code == "202") {
                resolve(response.data?.response);
            }
            else {
                reject(response.data)
            }
        })
        .catch(e => {
            reject(e);
        })
})

export const linkConnection = (reqBody) => {
    return apiService.put('hub/trip/link/connection', reqBody)
}

export const delinkConnectionV2 = (tripCode, connectionCode) => {
    return apiService.delete(`hub/trip/${tripCode}/delink/${connectionCode}/connection`)
}

export const loadTripV2 = (tripCode) => {
    return apiService.put(`hub/trip/${tripCode}/load`)
}

export const fetchSingleTrip = (tripCode) => {
    return apiService.get(`hub/trip/${tripCode}`)
}

export const fetchTripConnList = (reqBody) => {
    return apiService.post('hub/trip/connection-list', reqBody)
}