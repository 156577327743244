import { checkHttpStatus, notifyApiSuccess } from "../../../common/utils/utils";
import ManifestClosureService from "../../../services/inventory/manifest-closure.service";
import { SoundNotificationService } from "../../../services/sound.service";
import { ACTION_TYPES, ENTITY_TYPES } from "./constants";

const closureService = new ManifestClosureService();
const soundNotificationService = new SoundNotificationService();

export const setResourceType = (resourceType) => dispatch => {
    dispatch({
        type: ACTION_TYPES.SET_ENTITY_TYPE,
        payload: resourceType
    });
};

const getEntityFromResponse = (response, entityType) => {
    const { pendingCount, overageCount, scannedCount} = response;
    let id = response[entityType].id;
    let code = response[entityType].code;
    let status = response[entityType].status;
    let flowType = response[entityType].flowType;
    let quantity = response[entityType].quantity ? response[entityType].quantity : 0;
    let overageStatus = false;
    if (entityType === ENTITY_TYPES.manifest.name) {
        overageStatus = response.overageStatus;
    }
    let scannedAtDestination = true;
    let nextAction   = undefined;
    let nextLocation = undefined;
    if(response[entityType].hasOwnProperty("scannedAtDestination")){
        scannedAtDestination = response[entityType].scannedAtDestination
    }
    if(response[entityType].hasOwnProperty("nextAction")){
        nextAction = response[entityType].nextAction
    }
    if(response[entityType].hasOwnProperty("nextLocation")){
        nextLocation = response[entityType].nextLocation
    }
    return {
        pendingCount,
        overageCount,
        scannedCount,
        id,
        code,
        status,
        quantity,
        nextLocation,
        nextAction,
        scannedAtDestination,
        overageStatus,
        flowType
    };
};

const getScannedEntityInfoFromResponse = (response, entityType) => {
    if(entityType === ENTITY_TYPES.connection.name){
        return response.manifest;
    } else {
        return response.consignment || response
    }
};

export const getEntityScanInfo = (selectedEntityType, payload) => dispatch => {
    dispatch(fetchConnectionBegin());
    const entityType = ENTITY_TYPES[selectedEntityType].name;
    closureService.scanConnection(entityType, payload).then(res => {
        if(checkHttpStatus(res?.data?.status)) {
            const response = res.data.response;
            const entityInfoPayload = getEntityFromResponse(response, entityType);
            dispatch(fetchConnectionSuccess(entityInfoPayload));
            if (entityInfoPayload?.flowType && entityInfoPayload?.flowType === "RTO") {
                soundNotificationService.playRTOSuccess();
            } else if (entityInfoPayload?.flowType && entityInfoPayload?.flowType === "FORWARD") {
                soundNotificationService.playForwardSuccess();
            } else {
                soundNotificationService.playSuccess();
            }
        } else {
            dispatch(fetchConnectionFailure(res?.data?.response));
            soundNotificationService.playGeneralWarning();
        }
    });
};


const fetchConnectionBegin = () => {
    return {
        type: ACTION_TYPES.FETCH_CONNECTION_INFO_BEGIN
    };
};

const fetchConnectionSuccess = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_CONNECTION_INFO_SUCCESS,
        payload
    };
};

const fetchConnectionFailure = (error) => {
    return {
        type: ACTION_TYPES.FETCH_CONNECTION_INFO_FAILURE,
        payload: error
    };
};

export const unloadEntity = (selectedEntityType, payload) => dispatch => {
    dispatch(entityUnloadBegin());
    const entityType = ENTITY_TYPES[selectedEntityType].name;
    closureService.unloadConnection(entityType, payload).then(res => {
        if(checkHttpStatus(res?.data?.status)) {
            const response = res.data.response;
            const entityInfoPayload = getEntityFromResponse(response, entityType);
            dispatch(entityUnloadSuccess(entityInfoPayload));
        } else {
            dispatch(entityUnloadFailure(res?.data?.response));
        }
    });

};

const entityUnloadBegin = () => {
    return {
        type: ACTION_TYPES.ENTITY_UNLOAD_BEGIN
    };
};

const entityUnloadSuccess = (payload) => {
    return {
        type: ACTION_TYPES.ENTITY_UNLOAD_SUCCESS,
        payload,
    };
};

const entityUnloadFailure = (error) => {
    return {
        type: ACTION_TYPES.ENTITY_UNLOAD_FAILURE,
        payload: error
    };
};

export const scanEntity = (selectedEntityType, payload, isMobile, legacyScan = false) => dispatch => {
    dispatch(scanEntityBegin());
    const entityType = ENTITY_TYPES[selectedEntityType].name;
    closureService.scanConsignment(entityType, payload, legacyScan).then(res => {
        if(res?.data?.status && res?.data?.status?.code == 206) {
            dispatch(scanActionRequired(res.data.response));
        } else if(checkHttpStatus(res?.data?.status)) {
            const response = res.data.response;
            const scannedEntityInfo = {
                ...getScannedEntityInfoFromResponse(response, entityType),
                alreadyScanned : response?.alreadyScanned || undefined
            }
            if (!legacyScan) {
                scannedEntityInfo.status = response.consignmentStatus;
                if (response.consignmentStatus?.indexOf("RTO") < 0) {
                    delete scannedEntityInfo.shipperName;
                    delete scannedEntityInfo.shipperAddress;
                }
            }
            var trackEventName = entityType === "connection" ? "manifest_unload_" : "consignment_unload_";
            if(scannedEntityInfo.status === "OVERAGE"){
                if (response.overageStatus) {
                    scannedEntityInfo.status = response.overageStatus;
                }
                dispatch(scanEntitySuccess(scannedEntityInfo));
                dispatch(incrementOverageCount());
                soundNotificationService.playWarning();
            } else {
                dispatch(scanEntitySuccess(scannedEntityInfo));
                if (scannedEntityInfo?.flowType && scannedEntityInfo?.flowType === "RTO") {
                    soundNotificationService.playRTOSuccess();
                } else if (scannedEntityInfo?.flowType && scannedEntityInfo?.flowType === "FORWARD") {
                    if(!isNaN(scannedEntityInfo?.batchNumber) && Number(scannedEntityInfo?.batchNumber) > 0) {
                        soundNotificationService.playGeneralSuccess();
                    }
                    else {
                        soundNotificationService.playForwardSuccess();
                    }
                } else {
                    soundNotificationService.playSuccess();
                }
                if (!response.alreadyScanned) {
                    dispatch(incrementScanCount());
                }
            }
        } else {
            if (legacyScan || res?.data?.status?.customErrorCode != "SCANERROR001") {
                soundNotificationService.playGeneralWarning();
            }
            dispatch(scanEntityFailure(res?.data?.response || { ...res?.data?.status, waybillNo : payload?.waybillNo }));
        }
    });
};

const scanEntityBegin = () => {
    return {
        type: ACTION_TYPES.SCAN_ENTITY_BEGIN,
    };
};

const scanEntitySuccess = (payload) => {
    return {
        type: ACTION_TYPES.SCAN_ENTITY_SUCCESS,
        payload,
    };
};

const scanActionRequired = (payload) => {
    return {
        type: ACTION_TYPES.ACTION_REQUIRED,
        payload,
    };
};

const scanEntityFailure = (error) => {
    return {
        type: ACTION_TYPES.SCAN_ENTITY_FAILURE,
        payload: error
    };
};

const incrementScanCount = () => {
    return {
        type: ACTION_TYPES.INCREMENT_SCAN_COUNT
    };
};

const incrementOverageCount = () => {
    return {
        type: ACTION_TYPES.INCREMENT_OVERAGE_COUNT
    }
}

export const fetchReasons = (selectedEntityType) => dispatch => {
    dispatch(fetchReasonsBegin());
    closureService.fetchReasons(selectedEntityType).then(res => {
        if(checkHttpStatus(res?.data?.status)) {
            const reasons = res.data.response;
            dispatch(fetchReasonsSuccess(reasons));
        } else {
            dispatch(fetchReasonsFailure(res?.data?.response));
        }
    });
};

const fetchReasonsBegin = () => {
    return {
        type: ACTION_TYPES.FETCH_REASONS_BEGIN,
    };
};

const fetchReasonsSuccess = (reasonsArray) => {
    return {
        type: ACTION_TYPES.FETCH_REASONS_SUCCESS,
        payload: reasonsArray,
    };
};

const fetchReasonsFailure = (error) => {
    return {
        type: ACTION_TYPES.FETCH_REASONS_FAILURE,
        payload: error,
    }
};

export const updateCurrentScannedEntityReason = (selectedEntityType, payload ) => dispatch => {
    dispatch(updateReasonStart());
    const entityType = ENTITY_TYPES[selectedEntityType].name;

    closureService.updateReason(entityType, payload).then(res => {
        if(checkHttpStatus(res?.data?.status)) {
            let response = res.data.response;
            //TODO: enable this once fixed in the backend
            // const entityInfoPayload = getEntityFromResponse(response, entityType);
            // dispatch(fetchConnectionSuccess(entityInfoPayload));
            const scannedEntityInfo = getScannedEntityInfoFromResponse(response, entityType);
            dispatch(updateReasonSuccess(scannedEntityInfo));
        } else {
            dispatch(updateReasonFailure(res?.data?.response));
        }
    });
};

const updateReasonStart = () => {
    return{
        type: ACTION_TYPES.UPDATE_REASON_BEGIN
    };
};

const updateReasonSuccess = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_REASON_SUCCESS,
        payload
    };
};

const updateReasonFailure = (error) => {
    return {
        type: ACTION_TYPES.UPDATE_REASON_FAILURE,
        payload: error
    };
};

export const fetchManifestConsignmentDataByStatus = (entityType, payload) => dispatch => {
    dispatch(fetchManifestConsignmentDataBegin());
    closureService.fetchManifestAndConsignment(entityType, payload).then(res => {
        if(checkHttpStatus(res?.data?.status)) {
            let response = res.data.response;
            const { totalCount, statusCount } = response;
            let payload = {
                totalCount,
                statusCount,
            }
            if(entityType === ENTITY_TYPES.connection.name){
                payload["manifests"] = response.manifests;
            } else {
                payload["consignments"] = response.consignments;
            }
            dispatch(fetchManifestConsignmentDataSuccess(payload));
        } else {
            dispatch(fetchManifestConsignmentDataFailure(res?.data?.response));
        }
    }).catch(err => {
        dispatch(fetchManifestConsignmentDataFailure(err));
    });
};

const fetchManifestConsignmentDataBegin = () => {
    return {
        type: ACTION_TYPES.FETCH_MANIFEST_CONSIGNMENT_DATA_BEGIN
    };
};

const fetchManifestConsignmentDataSuccess = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_MANIFEST_CONSIGNMENT_DATA_SUCCESS,
        payload
    };
};

const fetchManifestConsignmentDataFailure = (error) => {
    return {
        type: ACTION_TYPES.FETCH_MANIFEST_CONSIGNMENT_DATA_FAILURE,
        payload: error
    };
};

export const updateReasonsAndList = (entityType, payload, sparkLineDialogData) => dispatch => {
    dispatch(updateReasonsAndListBegin());
    closureService.updateReason(entityType, payload).then(res => {
        if(checkHttpStatus(res?.data?.status)) {
            let response = res.data.response;
            const { pendingCount, overageCount, scannedCount} = response;
            let newPayload = {
                pendingCount,
                overageCount,
                scannedCount
            };

            if(entityType === ENTITY_TYPES.connection.name){
                let newManifestData = response.manifest;
                newManifestData["pincode"] = newManifestData.destinationPincode;
                newManifestData["destination"] = newManifestData.destinationLocation;

                let { manifests } = sparkLineDialogData;
                const indexOfnewManifestDataInManifests = manifests.findIndex(manifest => manifest.code === newManifestData.code);
                if(indexOfnewManifestDataInManifests > -1){
                    manifests[indexOfnewManifestDataInManifests] = {...newManifestData};
                }
                newPayload["sparkLineDialogData"] = {
                    ...sparkLineDialogData,
                    manifests
                }
            } else {
                const newConsignmentData = response.consignment;
                let { consignments } = sparkLineDialogData;
                const indexOfnewConsignmentDataInConsignment = consignments.findIndex(consginment => consginment.waybillNo === newConsignmentData.waybillNo);
                if(indexOfnewConsignmentDataInConsignment > -1){
                    consignments[indexOfnewConsignmentDataInConsignment] = {...newConsignmentData};
                }
                newPayload["sparkLineDialogData"] = {
                    ...sparkLineDialogData,
                    consignments
                }
            }
            dispatch(updateReasonsAndListSuccess(newPayload));
        } else {
            dispatch(updateReasonsAndListFailure(res?.data?.response));
        }
    });
};

const updateReasonsAndListBegin = () => {
    return {
        type: ACTION_TYPES.UPDATE_REASONS_IN_LIST_BEGIN
    };
};

const updateReasonsAndListSuccess = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_REASONS_IN_LIST_SUCCESS,
        payload
    };
};

const updateReasonsAndListFailure = (error) => {
    return {
        type: ACTION_TYPES.UPDATE_REASONS_IN_LIST_FAILURE,
        payload: error
    };
};

export const resetSelectedSparkLineDialogData = () => dispatch => {
    dispatch(resetSparkLineDialogDataStart());
};

const resetSparkLineDialogDataStart = () => {
    return {
        type: ACTION_TYPES.RESET_SELECTED_SPARK_LINE_DATA
    };
};

export const validateEntity = (entityType, payload) => dispatch => {
    dispatch(validateEntityBegin());
    closureService.validateEntity(entityType, payload).then(res => {
        if(checkHttpStatus(res?.data?.status)) {
            dispatch({
                type : ACTION_TYPES.SCAN_ENTITY_FAILURE
            });
            const response = res.data.response;
            if(response.validation){
                const { validation } = response;
                const entityInfoPayload = getEntityFromResponse(response, entityType);
                if(validation.status){
                    soundNotificationService.playSuccess();
                    dispatch(validateEntitySuccess(entityInfoPayload));
                } else {
                    soundNotificationService.playGeneralWarning();
                    const countUpdatePaylod = {
                        pendingCount: entityInfoPayload.pendingCount,
                        overageCount: entityInfoPayload.overageCount,
                        scannedCount: entityInfoPayload.scannedCount
                    }
                    // In case if the validation fails, just update the counts
                    dispatch(updateEnityCount(countUpdatePaylod));

                    dispatch(validateEntityFailure(validation.message));
                }
            } else {
                soundNotificationService.playGeneralWarning();
            }
        } else {
            dispatch(validateEntityFailure(res?.data?.response));
            soundNotificationService.playGeneralWarning();
        }
    });
};

const updateEnityCount = (payload) => {
    return {
        type: ACTION_TYPES.UPDATE_ENTITY_COUNT,
        payload
    }
};

const validateEntityBegin = () => {
    return {
        type: ACTION_TYPES.VALIDATE_ENTITY_BEGIN
    };
};

const validateEntitySuccess = (payload) => {
    return {
        type: ACTION_TYPES.VALIDATE_ENTITY_SUCCESS,
        payload
    };
};

const validateEntityFailure = (error) => {
    return {
        type: ACTION_TYPES.VALIDATE_ENTITY_FAILURE,
        payload: error
    };
};

export const closeConnection = (entityType, payload) => dispatch => {
    dispatch(closeConnectionBegin());
    closureService.updateConnection(entityType, payload).then(res => {
        if(checkHttpStatus(res?.data?.status)) {
            notifyApiSuccess(entityType === ENTITY_TYPES.connection.name ? "Connection succesfully closed!" : "Manifest succesfully closed!", "Success");
            soundNotificationService.playSuccess();
            dispatch(closeConnectionSuccess());
        } else {
            dispatch(closeConnectionFailure(res?.data?.response));
            soundNotificationService.playGeneralWarning();
        }
    });
}

const closeConnectionBegin = () => {
    return {
        type: ACTION_TYPES.CLOSE_CONNECTION_BEGIN
    };
};

const closeConnectionSuccess = () => {
    return {
        type: ACTION_TYPES.CLOSE_CONNECTION_SUCCESS
    };
};

const closeConnectionFailure = (error) => {
    return {
        type: ACTION_TYPES.CLOSE_CONNECTION_FAILURE,
        payload: error
    };
};

export const closeErrorModal = () => dispatch => {
    dispatch(closeError());
};

export const closeActionModal = () => dispatch => {
    dispatch(closeAction());
};

const closeAction = () => {
    return {
        type: ACTION_TYPES.CLOSE_ACTION_REQUIRED
    };
};


const closeError = () => {
    return {
        type: ACTION_TYPES.CLOSE_ERROR_DIALOG_INFO
    };
};

export const generateSampleResponse = (entityInfo, scannedConsignement) => {
    return {
        ...entityInfo,
        pendingCount  : entityInfo.pendingCount -1 ,
        scannedCount  : entityInfo.scannedCount -1,
        manifest      : entityInfo,
        consignment   : scannedConsignement,
        overageStatus : "",
        reasonCode    : ""
    }
}