import { Constants } from "../../common/utils/constants";
import { checkHttpStatus, notifyApiError } from "../../common/utils/utils";
import MonitoringReportsService from "../../services/monitoring-report/monitoring-report.service";
import * as _Constants from "./constants";

const monitoringReportService = new MonitoringReportsService();

export const fetchEmbedUrlWithProperties = payload => dispatch => {
    dispatch(fetchMonitoringServiceBegin());
    monitoringReportService.getEmbeddedURLByPayload(payload).then(res => {
        if (res && res.data && checkHttpStatus(res?.data?.status)) {
            dispatch(fetchMonitoringServiceSuccess(res?.data?.response?.embedUrl))
        } else {
            notifyApiError(res?.data?.status, "Error: Unable to fetch resources");
        }
    });
}

export const fetchEmbeddedUrl = (payload, params = {}) => dispatch => {
    dispatch(fetchMonitoringServiceBegin());
    monitoringReportService.getEmbeddedURL(payload, params).then(res => {
        if (res && res.data && checkHttpStatus(res?.data?.status)) {
            dispatch(fetchMonitoringServiceSuccess(res?.data?.response?.embedUrl))
        } else {
            notifyApiError(res?.data?.status, "Error: Unable to fetch resources");
        }
    });
};

function fetchMonitoringServiceBegin() {
    return {
        type: _Constants.FETCH_MONITOR_REPORTS_BEGIN
    };
}

function fetchMonitoringServiceSuccess(payload) {
    return {
        type: _Constants.FETCH_MONITOR_REPORTS_SUCCESS,
        payload: payload
    }
}

function fetchMonitoringServiceFailure(error) {
    return {
        type: _Constants.FETCH_MONITOR_REPORTS_FAILURE,
        payload: error,
    }
};

export const getParsedEmbedUrl = (embedUrl) => {
    if(embedUrl){
        // embedUrl = embedUrl.slice(1, -1)
        // return embedUrl
        try {
            embedUrl = JSON.parse(embedUrl);
            return embedUrl
        } catch (error) {
            return embedUrl
        }
    }
    return ""
}

export const getQueryParamsObj = (params) => {
    let queryParamsArray = [];
    params.forEach((value, key) => {
        queryParamsArray.push({
            key: key,
            value: value
        })
    });
    return queryParamsArray;
};

export const construcFetchEmbedUrlPayload = (reportName, metadata) => {
    const resourceType = metadata.find((data) => data.key === "resourceType");
    const resourceId = metadata.find(data => data.key === "resourceId");
    const otherFilters = metadata.filter(data => data.key !== "resourceType" || data.key !== "resourceId");
    const params_obj = {};
    if(otherFilters){
        otherFilters.forEach(filter => {
            if(!["resourceType", "resourceId"].includes(filter.key)){
                if(filter.key === "location_id" && !filter.value){
                    params_obj[filter.key] = Constants.userLocationId ? Constants.userLocationId : Constants.LOGGED_PARTNER_LOCATION_ID;
                } else if(filter.key === "partner_id" && !filter.value){
                    let currentPartnerId = Constants.CURRENT_USER_ID;
                    if(!currentPartnerId){
                        currentPartnerId = Constants.PARTNER_ID
                    }
                    params_obj[filter.key] = currentPartnerId
                } else {
                    params_obj[filter.key] = filter.value
                }
            }
        })
    };
    const payload = {
        reportName: reportName,
        embedUrlGeneric: {
            resource: {[resourceType.value || "question"]: parseInt(resourceId.value)},
            params: params_obj
        }
    };
    return payload;
};