import React from "react";
import {
    Card,
    Table,
    Tag,
    Icon,
    Tooltip
} from "antd";
import moment from "moment";
import { getReports } from "./action";

const WaybillTable = ({
    data,
    getPaginatedData,
    pageNo,
    PaginatedWaybill,
    }) => {
    const columns = [
        {
            title: "Waybill No",
            dataIndex: "waybillNo",
            key: "waybillNo",
            width : 200
        },
        {
            title: "Attempts",
            dataIndex: "consignmentBO.attempts",
            key: "consignmentBO.attempts",
            width : 95
        },
        {
            title: "Customer",
            dataIndex: "consignmentBO.customer.name",
            key: "consignmentBO.customer.name",
        },
        {
            title: "Destination",
            dataIndex: "consignmentBO.consignee.pincode.city.name",
            key: "destination",
        },
        {
            title: "RTO Age",
            dataIndex: "consignmentBO.updatedAt",
            key: "consignmentBO.updatedAt",
            render: (text) =>
                <div className="flex-box flex-column">
                    <span>{moment(text).fromNow()}</span>
                    <span style={{fontSize: "smaller"}}>
                        {moment(text).format("DD/MM/YYYY h:mm a")}
                    </span>
                </div>
            
        },
        {
            title: "Booking Date",
            dataIndex: "consignmentBO.bookingDate",
            key: "consignmentBO.bookingDate",
            render: (text) =>
            <div className="flex-box flex-column">
                <span>{moment(text).fromNow()}</span>
                <span style={{fontSize: "smaller"}}>
                    {moment(text).format("DD/MM/YYYY h:mm a")}
                </span>
            </div>
        },
        {
            title: "Status",
            dataIndex: "consignmentBO.consignmentStatus",
            key: "status",
            render: text => <Tag style={{ whiteSpace : "normal"}} className="ant-table-tag text-align-center warning-tag">{text}</Tag>
        },
    ];

    return(
        <Card title={ <h2>RTO Waybill List</h2>} extra={ 
            <Tooltip title="Download PDF">
        <Icon onClick={getReports} style={{fontSize:25}}type="file-pdf" />
        </Tooltip>
         }>
            <Table bordered={true} rowKey={"id"}
                columns={columns}
                pagination={{
                    onChange: getPaginatedData,
                    total: data.totalCount,
                    position: "bottom",
                    current: pageNo,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    pageSize: data.pageSize
                }}
                dataSource={PaginatedWaybill ? PaginatedWaybill[pageNo] : []}
                scroll={{ x: 800 }}
             />
         </Card>
    )
};
export default WaybillTable;