import { Card, Icon, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import LocationOnboardingForm from "./locationAction/LocationOnboardingForm";
import VendorUpdateForm from "./vendorUpdate/VendorUpdateForm";
import ConnectionUpdateForm from "./connectionUpdate/ConnectionUpdateForm";
import { useNavigate } from "react-router-dom";
import LocationService from "../../../services/settings/location.service";

const LocationTabLayout = ({ }) => {

    let currentUrl = window.location.href
    currentUrl = currentUrl.split("/")
    const locationId = parseInt(currentUrl[currentUrl.length - 1])

    const navigate = useNavigate();

    const locationService = new LocationService;

    const nextTabValue = {
        "location": "vendor",
        "vendor": "connection"
    }

    const [tabKey, setTabKey] = useState("location");
    const [locationDetails, setLocationDetails] = useState(null);
    const [entityDetails, setEntityDetails] = useState(null)

    useEffect(() => {

        if (locationId) {
            locationService.getlocationById(locationId)
                .then(res => {
                    if (res?.data?.status?.code == 202) {
                        const locDetails = res?.data?.response?.location
                        setLocationDetails(locDetails)
                        const entityInfo = {
                            "partner": {
                                "id": locDetails.partnerId,
                                "name": locDetails.partnerName
                            },
                            "location": {
                                "id": locationId,
                                "name": locDetails.name,
                                "opsType": locDetails.locationOpsType
                            }
                        }
                        setEntityDetails(entityInfo)
                    } else {
                        console.error("fetchLocationDetails failed", res?.data?.status?.message)
                        notifyApiError("Internal Server Error " + res?.data?.status?.message)
                    }
                })
                .catch(error => notifyApiError(error));
        }

        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = ''; // Some browsers require a non-empty string
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };

    }, [])

    const navigateBetweenTab = (key) => {
        if (window.confirm("Are you sure you want to switch tabs? Your progress will be lost.")) {
            setTabKey(key);
        }
    }

    const storeEntityDetails = (locDetails) => {
        const locData = {
            "partner": {
                "id": locDetails.partnerId,
                "name": locDetails.partnerName
            },
            "location": {
                "id": locDetails.locationId,
                "name": locDetails.locationName,
                "opsType": locDetails.locationOpsType
            }
        }
        setEntityDetails(locData)
    }

    const changeTabOnSuccess = () => {
        if ((tabKey == "connection") ||
            (tabKey == "vendor" &&
                !entityDetails?.location?.id)) {
            navigate("/appv2/settings/dashboard/location")
        } else {
            setTabKey(nextTabValue[tabKey]);
        }
    }

    const captureEntityData = (entityData) => {

        const locData = {
            "partner": {
                "id": entityData.partnerId,
                "name": entityData.partnerName
            }
        }
        setEntityDetails(locData)
    }

    const tabs = {
        "location": {
            "component": <LocationOnboardingForm storeEntityDetails={storeEntityDetails}
                onSubmitSuccess={changeTabOnSuccess} captureEntityData={captureEntityData}
                locationDetails={locationDetails} />,
            "tabName": "Partner/Locations"
        },
        "vendor": {
            "component": <VendorUpdateForm partnerDetails={entityDetails?.partner}
                onSubmitSuccess={changeTabOnSuccess} activeTab={tabKey} />,
            "tabName": "Vendors",
            "checkKeyToDisplay": entityDetails?.partner?.id
        },
        "connection": {
            "component": <ConnectionUpdateForm locationDetails={entityDetails?.location}
                onSubmitSuccess={changeTabOnSuccess} existingConnectionDetails={locationDetails?.connectionDetails} />,
            "tabName": "Connections",
            "checkKeyToDisplay": entityDetails?.location?.id
        }
    }

    return (
        <>
            <Card title={<h3> <Icon onClick={_ => navigate("/appv2/settings/dashboard/location")}
                style={{ margin: 15, marginLeft: 0 }}
                type="arrow-left" />Entity Onboarding</h3>} className="layout-card" >
                <Tabs className="log10-tabs" defaultActiveKey="location" activeKey={tabKey}
                    onChange={navigateBetweenTab}>
                    {Object.keys(tabs).map((key) => {
                        const tab = tabs[key]
                        if ("checkKeyToDisplay" in tab) {
                            if (tab?.checkKeyToDisplay) {
                                return <Tabs.TabPane tab={tabs[key].tabName} key={key}>
                                    {tabs[key].component}
                                </Tabs.TabPane>
                            }
                        } else {
                            return <Tabs.TabPane tab={tabs[key].tabName} key={key}>
                                {tabs[key].component}
                            </Tabs.TabPane>
                        }
                    })}
                </Tabs>
            </Card>
        </>
    )

}

export default LocationTabLayout;