import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Card, Spin, Button } from 'antd';
import { getDashboardUrl } from './actions'
import { canAllow, getPermissionSubmodule, notifyApiError } from '../../common/utils/utils';
import { USER_LEVEL } from '../../common/utils/constants';
import moment from 'moment';


class DashboardApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urlSet: []
        }
        props.getDashboardUrl('ZOHO')
    }

    componentDidUpdate(prevProps) {
        if (this.props.urlList.length !== prevProps.urlList.length) {
            this.createZohoUrls(this.props.urlList)           
        }
    }

    getFieldValue = type => {
        switch (type) {
            case 'partner':
            case 'Partner':
            case 'root_partner':
            case 'partner_name':
                return { value: JSON.parse(atob(localStorage.getItem('user'))).partner.name, isShow: true }
            case 'destination_branch':
            case 'location_name':
                return { value: JSON.parse(atob(localStorage.getItem('user'))).location.name, isShow: [USER_LEVEL.BRANCH, USER_LEVEL.PARTNER_HUB].includes(JSON.parse(atob(localStorage.getItem('user'))).userLevel) }
            default: return '';
        }
    }

    getFieldName = data => {
        let count = 2;
        const keys = Object.keys(data).filter(item => {
            if (item === `criteriaKey_${count}`) {
                count += 1;
                return true
            }
            return false
        })
        return keys.map(k => {
            return {
                label: data[k],
                ...this.getFieldValue(data[k])
            }
        })

    }

    

    createZohoUrls = data => {
        if (data.length === 0) return 0
        let urlSet = []
        urlSet = data.map(url => {
            let _url = '';
            const fieldName = this.getFieldName(url)
            _url = `${url.url}/${url.openViewId}/${url.openViewSubId}`;
            if (url.criteriaKey)
                _url += `?${url.criteriaKey}=(`
            if (fieldName)
                fieldName.forEach((field, key) => {
                    _url += field.isShow ? `"${url.criteriaKey_1}"."${field.label}"='${field.value}'${key !== fieldName.length - 1 && fieldName[key + 1]['isShow'] ? ' and ' : ''}` : '';
                })
            if (url.criteriaKey)
                _url += ')'
           
            return { ...url, link: _url, isPermited: canAllow(getPermissionSubmodule("dashboard", url.permissionName)) };
            // return { ...url, link: _url, isPermited: canAllow(permissionTags.dashboard[url.permissionName]?.subModule) };
        })

        const permittedLink = urlSet.filter(i => i.isPermited)
        if (permittedLink.length === 0) {
            notifyApiError("You do not have permission to access this page!", "403")
            this.props.history.push("/appv2/tracking/dashboard/tracking");
            return;
        }
        this.setState({
            urlSet: permittedLink.sort(function (a, b) { return a.index - b.index }),
            selectedLink: { label: permittedLink[0].permissionName.replace('_', ' '), link: permittedLink[0].link }
        })
    }

    openTab = url => {       
        
        this.setState({ selectedLink: { label: url.permissionName.replace('_', ' '), link: url.link } })
    }

    render() {
        const { loading, urlList } = this.props
        const { urlSet } = this.state;
        return (
            <Spin spinning={loading}>
                {
                    urlSet.length ? urlSet.filter(url => url.isPermited).map(url => {
                        return <Button onClick={() => this.openTab(url)} type={this.state?.selectedLink?.label === url.permissionName.replace('_', ' ') ? 'primary' : 'default'}>{url.permissionName.replace('_', ' ')}</Button>
                    }) : <></>
                }
                <Card className="DashBoard" style={{ marginTop: 10 }}>
                    <iframe className="e2e-iframe-trusted-src"
                     width="100%"
                     style={{
                        border : "1px solid lightgray",
                        height : "calc(100vh - 250px)"
                     }}
                     src={this.state?.selectedLink?.link} />
                </Card>
            </Spin>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    loading: state.dashboard.loading,
    urlList: state.dashboard.urlList
});
const mapDispatchToProps = dispatch => ({
    getDashboardUrl: type => dispatch(getDashboardUrl(type))
});
export default connect(mapStateToProps, mapDispatchToProps)(DashboardApp);
