import * as CONSTANTS from './appConstants';
import { LOCAL_STORAGE_STATE, USER_LOGGEDIN_INFO, SELF, S3_REPORT_STATUS } from '../common/utils/constants';

export const appReducer = (
  state = { config: {}, batchingStatus: "Start Batching" },
  action) => {
  switch (action.type) {
    case CONSTANTS.GET_CONFIGURATIONS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case CONSTANTS.GET_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        configurations: action.payload
      };
    case CONSTANTS.GET_CONFIGURATIONS_FAILURE:
      return {
        ...state,
        loading: false
      }
    case CONSTANTS.GET_PERMISSIONS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case CONSTANTS.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        permissions: action.payload
      };
    case CONSTANTS.GET_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false
      }
    case CONSTANTS.S3_SYNC_STATUS_RESET:
      return {
        ...state,
        s3Status: "RESET"
      }
    case CONSTANTS.S3_SYNC_STATUS_BEGIN:
      return {
        ...state,
        s3Status: S3_REPORT_STATUS.BEGIN
      }
    case CONSTANTS.S3_SYNC_STATUS_FAILURE:
      return {
        ...state,
        s3Status: S3_REPORT_STATUS.FAILURE
      }
    case CONSTANTS.S3_SYNC_STATUS_SUCCESS:
      return {
        ...state,
        s3Status: S3_REPORT_STATUS.SUCCESS
      }
    case CONSTANTS.GET_APPLICATION_TYPE:
      return {
        ...state,
        applicationType: action.payload
      };

    case CONSTANTS.GET_PARTNERS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case CONSTANTS.GET_PARTNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        partnerDetails: action.payload
      };
    case CONSTANTS.GET_PARTNERS_FAILURE:
      return {
        ...state,
        loading: false,
        partnerDetails: null
      };

    case CONSTANTS.GET_VENDOR_BEGIN:
      return {
        ...state,
        loading: true
      };
    case CONSTANTS.GET_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorDetails: action.payload
      };
    case CONSTANTS.GET_VENDOR_FAILURE:
      return {
        ...state,
        loading: false,
        vendorDetails: null
      };

    case CONSTANTS.GET_CITIES_BEGIN:
      return {
        ...state,
        cities: {
          loading: true
        }
      };
    case CONSTANTS.GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: {
          loading: false,
          ...action.payload
        }
      };
    case CONSTANTS.GET_CITIES_FAILURE:
      return {
        ...state,
        cities: {
          loading: false,
          ...action.payload
        }
      }

    case CONSTANTS.GET_PINCODE_BEGIN:
      return {
        ...state,
        cities: {
          ...state.cities,
          pincode: {
            loading: true,
          }
        }
      };
    case CONSTANTS.GET_PINCODE_SUCCESS:
      return {
        ...state,
        cities: {
          ...state.cities,
          pincode: {
            loading: true,
            ...action.payload
          }
        }
      };
    case CONSTANTS.GET_PINCODE_FAILURE:
      return {
        ...state,
        cities: {
          ...state.cities,
          pincode: {
            loading: true,
            ...action.payload
          }
        }
      }
    case CONSTANTS.GET_SUMMARY_BEGIN:
      return {
        ...state
      };
    case CONSTANTS.GET_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.payload
      };
    case CONSTANTS.GET_SUMMARY_FAILURE:
      return {
        ...state,
      }
    case CONSTANTS.GET_REPORT_SUCCESS:
      return {
        ...state,
        reportResp: action.payload
      };
    case CONSTANTS.GET_REPORT_FAILURE:
      return {
        ...state,
      };
    case CONSTANTS.UPDATE_BATCHING_STATUS:
      return {
        ...state,
        batchingStatus: action.payload
      };

    default:
      return state;
  }
};
