import { apiService } from '../../../../common/apiService/service';
import * as apiUtils from '../../../../common/utils/apiGateway';
import { Constants } from '../../../../common/utils/constants';
import { checkHttpStatus } from '../../../../common/utils/utils';

export const fetchUsers = params => new Promise(resolve => {
    if (params) {
        const url = `/b2b/v1/partners/${params.partnerId}/locations/${params.locationId}/users?fetch_type=ASSIGNED_RTODRS_USERS`;
        apiUtils.apiRequest({
            url,
            method: "GET"
        }).then(response => {
            let users = [];
            users = response?.data?.response?.users;
            if (!users) {
                resolve(false);
            }
            else {
                resolve(users);
            }
        })
    }
    else {
        resolve(false);
    }
});

export const fetchShipment = params => new Promise(resolve => {
    if (params) {
        const url = `b2c/v1/rto/drs/fetch/${params.userId}`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data: {
                "filters": params.filters
            }
        }).then(response => {
            let drsShipment = [];
            drsShipment = response?.data?.response;
            if (!drsShipment) {
                resolve(false);
            }
            else {
                resolve(drsShipment);
            }
        })
    }
    else {
        resolve(false);
    }
});


export const scanAwb = params => new Promise(resolve => {
    if (params) {
        const url = `/b2c/v1/rto/drs/${params.drsId}/inwardScan/${params.waybill}`;
        apiUtils.apiRequest({
            url,
            method: "GET",

        }).then(response => {

            if (checkHttpStatus(response?.data?.status)) {
                let consignment = undefined;
                consignment = response?.data?.response.consignment;
                if (!consignment) {
                    resolve(false);
                }
                else {
                    resolve(consignment);
                }
            } else {
                resolve(false)
            }

        })
    }
    else {
        resolve(false);
    }
});

export const closeDRS = data => new Promise(resolve => {
    if (data) {
        const url = `b2c/v1/rto/drs/closeDRS`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data
        }).then(response => {
            let consignment = [];
            consignment = response?.data?.response?.responses;
            if (!consignment) {
                resolve(false);
            }
            else {
                resolve(consignment);
            }
        })
    }
    else {
        resolve(false);
    }
});

export const validateFe = (userId) => {
    return apiService.get("lm/v1/rto/drs/user/" + userId)
}

export const closeDrsV2 = (userId) => {
    return apiService.put("lm/v1/rto/drs/close/user/" + userId)
}

export const fetchFeList = () => {
    return apiService.get('fefmlm/riders')
}