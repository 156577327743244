import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const LastMileForward = () => {

    return (

        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">LM | Forward</div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-m-4 grey-text">Topline for Today - Location Level</div>
                <div className="flex-box flex-gap-m justify-content-center">

                    <MetabaseWidget text={"LM - Expected Shipments"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 810, reportName: "lm-expected-shipments",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - Received Shipments"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 813, reportName: "lm-received-shipments",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - Shipments Assigned to DRS"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 817, reportName: "lm-shipments-assigned-to-drs",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - Number of Waybill went OFD"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 824, reportName: "lm-number-of-waybill-went-ofd",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - Number of Waybill got DEL"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 826, reportName: "lm-number-of-waybill-got-del",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - Number of Waybill got UNDEL"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 825, reportName: "lm-number-of-waybill-got-undel",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"LM - Waybills Marked Lost"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 828, reportName: "lm-waybills-marked-lost",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - Number of DRS Created"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 815, reportName: "lm-number-of-drs-created",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM -Number of DRS Closed"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 823, reportName: "lm-number-of-drs-closed",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - COD Amount Collected - Location Level"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 827, reportName: "lm-cod-amount-collected-location-level",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>
            </div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-m-4 grey-text">First Attempt Delivery Today - Location Level</div>
                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"LM - First Attempt for OFD - Location Level"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1027, reportName: "lm-first-attempt-for-ofd-location-level",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - First Attempt Delivered - Location Level"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1028, reportName: "lm-first-attempt-delivered-location-level",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - FAD Percentage - Location Level"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1029, reportName: "lm-fad-percentage-location-level",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>
                <div>
                    <MetabaseWidget text={"LM - Performance Topline - Location Level"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1030, reportName: "lm-performance-topline-location-level",
                            lockedParams: ["location_id"], height: "320px"
                        }} />
                </div>
                <div>
                    <MetabaseWidget text={"LM - FE Level - Performance and COD Collected - Data Dump - Location Level"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1031, reportName: "lm-fe-level-performance-and-cod-collected-data-dump-location-level",
                            lockedParams: ["location_id"], height: "320px"
                        }} />
                </div>

            </div>


            <div className="flex-column flex-gap-l">

                <div className="font-size-m-4 grey-text">Pendency Reports - Location Level</div>
                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"LM -Overall Pendency - Total Count"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 834, reportName: "lm-overall-pendency-total-count",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM -Overall Pendency"}
                        className={"flex-3 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 832, reportName: "lm-overall-pendency",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>
                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"LM - Received not OFD - Pendency - Count"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 835, reportName: "lm-received-not-ofd-pendency-count",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - Received not OFD - Pendency"}
                        className={"flex-3 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 833, reportName: "lm-received-not-ofd-pendency",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>

            </div>

            <div>
                <div className="font-size-m-4 grey-text">Pendency Data Dump - Location Level</div>
                <MetabaseWidget text={"LM - Forward Pendency Data Dump"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1017, reportName: "lm-forward-pendency-data-dump",
                        lockedParams: ["location_id"], height: "320px"
                    }} />
            </div>

            <div>
                <div className="font-size-m-4 grey-text">DRS Data Dump for Today - Location Level</div>
                <MetabaseWidget text={"LM - DRS Summary Report (Today)"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1019, reportName: "lm-drs-summary-report-today",
                        lockedParams: ["location_id"], height: "320px"
                    }} />
            </div>

            <div>
                <div className="font-size-m-4 grey-text">COD Data Dump for Today - Location Level</div>
                <MetabaseWidget text={"LM - Cod Collectable Report (Today)"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1021, reportName: "lm-cod-collectable-report-today",
                        lockedParams: ["location_id"], height: "320px"
                    }} />
            </div>

        </div>

    )

}

export default LastMileForward;