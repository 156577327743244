import moment from "moment";

export const COD_ACTIONS = {
    UPDATE_TIME_STAMP : "UPDATE_TIME_STAMP"
}

export const CoDV2Reducer = (state = {
    // fromTimeStamp : moment().subtract(25, "days").startOf("days").valueOf(),
    // toTimeStamp   : moment().subtract(20, "days").endOf("days").valueOf(),
    fromTimeStamp : undefined,
    toTimeStamp   : moment().valueOf()
}, action) => {
    switch (action.type) {
        default: return state;
    }
}