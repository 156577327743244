import RTOServices from "../../../services/rto/rto.service";
import {
  ACTION_TYPES
} from "./constants";

const rtoServices = new RTOServices();

export const getConsignmentsData = (payload, isReset = false) => dispatch => {
  dispatch(fetchConsignmentsBegin());

  rtoServices.getAllRTOEligible(payload)
    .then((res) => {
      const response = res.data.response;
      dispatch(fetchConsignmentsSuccess(response, isReset));
    })
    .catch(err => {
      dispatch(fetchConsignmentsFailure(err));
    })
};

const fetchConsignmentsBegin = () => {
  return {
    type: ACTION_TYPES.FETCH_CONSIGNMENTS_BEGIN
  }
};

const fetchConsignmentsSuccess = (payload) => {
  return {
    type: ACTION_TYPES.FETCH_CONSIGNMENTS_SUCCESS,
    payload
  }
};

const fetchConsignmentsFailure = (payload) => {
  return {
    type: ACTION_TYPES.FETCH_CONSIGNMENTS_FAILURE,
    payload
  }
};

export const getConfigData = () => dispatch => {
  dispatch(fetchConfigBegin());

  rtoServices.configurations()
    .then((res) => {
      const config = res.data.response.config;
      let rtoAction = config["mark_rto_inventory"] ? true : false;
      dispatch(fetchConfigSuccess({config,rtoAction}));
    })
    .catch(err => {
      dispatch(fetchConfigFailure(err));
    })
};

const fetchConfigBegin = () => {
  return {
    type: ACTION_TYPES.FETCH_CONFIGURATIONS_BEGIN,
  }
};

const fetchConfigSuccess = (payload) => {
  return {
    type: ACTION_TYPES.FETCH_CONFIGURATIONS_SUCCESS,
    payload
  }
};

const fetchConfigFailure = (payload) => {
  return {
    type: ACTION_TYPES.FETCH_CONFIGURATIONS_FAILURE,
    payload
  }
};







