import { Button, Card, Empty, Icon, Modal, Spin, Table, Tag } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSummary } from '../../../app/appAction.js';
import { Constants, FILTER_INPUT_TYPE, FlowTypes, TRANSISTION_STATE } from '../../../common/utils/constants';
import { canAllow, capitalizeString, notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags.js';
import DrsService from '../../../services/inventory/drs.service';
import DRSFilters from "../../common/drs-filter";
import DataCard from '../../shared-components/data-card/DataCard';
import activeAwbIcon0 from './../../../assets/img/icons/images/3_5_Inventory.svg';
import activeAwbIcon1 from './../../../assets/img/icons/images/3_6_Inventory.svg';
import activeAwbIcon2 from './../../../assets/img/icons/images/3_7_Inventory.svg';
import { fetchdrs, updateDrs } from './actions';
import './drs.scss';
import BatchingModal from './BatchingModal.js';

class DRSDashboard extends Component {
  constructor(props) {
    super(props);
    const dataCardIcon = { activeAwbIcon0, activeAwbIcon1, activeAwbIcon2 };

    this.state = {
      drsSearchInput: undefined,
      pageNo: 1,
      application: "B2C",
      flowTypes: FlowTypes,
      flowType: FlowTypes.FORWARD,
      dataCardIcon: dataCardIcon,
      DataCardList: [
        {
          title: 'Open DRS',
          documentType: "OPEN_DRS",
          embedDashboard: "inventory-drs",
          icon: dataCardIcon[`activeAwbIcon${0}`]
        },
        {
          title: 'Added Today',
          documentType: 'DRS_ADDED_TODAY',
          embedDashboard: "inventory-drs",
          icon: dataCardIcon[`activeAwbIcon${1}`]
        },
        {
          title: 'Pending Local AWB',
          documentType: 'PENDING_CONSIGNMENT_LOC',
          embedDashboard: "inventory-drs",
          icon: dataCardIcon[`activeAwbIcon${2}`]
        }
      ],
      summaryLoader: false,
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
          fetchObjects: [
            'TRACKING_DEVICE', 'CONSIGNMENTS', 'ASSEST', 'USER'
          ],
          drsCodes: []
        }
      },
      drsPermission: {
        read: canAllow(permissionTags.inventory.drs.read),
        update: canAllow(permissionTags.inventory.drs.update),
        create: canAllow(permissionTags.inventory.drs.create),
        delete: canAllow(permissionTags.inventory.drs.delete),
      },
      columns: [
        {
          width: 230,
          title: 'DRS ID',
          dataIndex: 'drsCode',
          key: 1,
          render: (data, row) => {
            return <div className={props?.config?.enableXutilDRS == 'true' && row.isInScanPending && row?.drsStatus?.toLowerCase() == 'draft' ? 'color-red' : ''} >{data}</div>
          }
        },
        {
          width: 110,
          title: props?.config?.enableXutilDRS == 'true' ? 'Waybills/Scan Count' : 'Waybills',
          dataIndex: 'customObj.consignmentsCount',
          key: 2,
          render: (data, row) => {
            if (props?.config?.enableXutilDRS == 'true') {
              return data + " / " + (row?.drsStatus?.toLowerCase() == 'draft' ? row.scannedCount : data)
            } else {
              return data
            }

          }
        },
        {
          width: 90,
          title: 'Cash Summary',
          dataIndex: 'totalCodAmount',
          key: 5,
          render: (data, row) => !row.totalCodAmount ? (`0/0`) : (
            (row.collectedCodAmount == null) ? '₹ 0/' + row.totalCodAmount : '₹ ' + row.collectedCodAmount + '/' + row.totalCodAmount
          )
        },
        {
          title: 'Employee',
          width: 130,
          dataIndex: 'drsUser',
          key: 7,
          render: data => <div>{data.name ? data.name : 'N/A'} <br /> <small>{data.contactNumber ? data.contactNumber : 'N/A'}</small></div>
        },
        {
          title: 'Created On',
          width: 150,
          dataIndex: 'createdAt',
          key: 8,
          render: data => <div>{moment(data).format('DD/MM/YYYY h:mm a')} <br /> <small>{moment(data).fromNow()}</small></div>
        },
        {
          title: 'Status',
          width: 110,
          dataIndex: 'drsStatus',
          key: 9,
          render: status => {
            let tagClassName = "info-tag";
            switch (status) {
              case "CLOSED": tagClassName = "success-tag"; break;
              case "PENDING":
              case "DRAFT":
              case "OPEN": tagClassName = "error-tag"; break;
              case "HANDED_OVER": tagClassName = "secondary-info-tag"; break;
              case "COMPLETED": tagClassName = "warning-tag"; break;
              case "IN_TRANSIT": tagClassName = "info-tag"; break;
              default: return "";
            }
            if (tagClassName) {
              return (
                <Tag className={"ant-table-tag text-align-center " + tagClassName}>
                  {
                    (status.split("_").map(s => capitalizeString(s.toLowerCase()))).join(" ")
                  }
                </Tag>
              )
            }
          }
        },
        {
          title: "Action",
          dataIndex: 'id',
          width: 70,
          key: 10,
          colSpan: 2,
          render: (id, row) => {
            return <div className='flex-box justify-content-center'><Button type="primary" icon="download" className="lsn-btn-primary" onClick={() => this.downloadDrsData(id, row.drsCode)}></Button></div>
          }
        },
        {
          title: "Download",
          width: 60,
          colSpan: 0,
          dataIndex: '',
          key: 11,
          render: (_, row) => {

            const disableEditForStatus = new Set(['CLOSED', 'COMPLETED', 'IN_TRANSIT', 'HANDED_OVER', 'DRAFT'])

            if (props?.config?.enableXutilDRS == 'true') {
              disableEditForStatus.delete('DRAFT')
            }

            return <div className="flex-box align-items-center flex-gap-m">
              {
                (!disableEditForStatus.has(row.drsStatus)) &&
                <Link to={`/appv2/inventory/form/drs/${row.drsCode}`} >
                  <Icon type="edit" className="cursor-pointer" />
                </Link>
              }
              {
                row?.customObj?.consignmentsCount === 0 && (row.drsStatus === "PENDING" || row.drsStatus === "DRAFT") &&
                <Icon onClick={e => this.deletedrs(row)} type="delete" className="cursor-pointer" />
              }
            </div>
          }
        },
      ],
      openStartBatchingModal: false,
      donNotAllowBatching: true
    }

    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)
    const epochTime = currentDate.getTime() / 1000;
    const batchProcessedAt = localStorage.getItem('DrsBatchProcessedAt')

    console.log("batchProcessedAt", batchProcessedAt)

    if (batchProcessedAt == null || batchProcessedAt != epochTime) {
      this.state.donNotAllowBatching = false
    }

    if (props?.config?.enableXutilDRS == 'true') {

      delete this.state.columns[0]['width']

      this.state.columns.splice(3, 0,
        {
          width: 80,
          title: 'Batch Number',
          dataIndex: 'batchNumber',
          key: 6,
          render: (data, row) => data ? data : "N/A"
        }
      );

    }

    // this.props.getSummary('INVENTORY_DRS');
    this.applyPagination({ pageNo: 1 }, true);
  }

  onActionChange = (row, action) => {

    Modal.confirm({
      title: 'Are you sure?',
      content: `You are about to mark the DRS as ${action.toLowerCase()}.`,
      okText: 'GO',
      cancelText: 'No',
      onOk() {
        const payload = {
          requests: [{
            drsId: row.id,
            status: action
          }]
        };
        row['isLoading'] = true;
        (new DrsService).updateStatus(payload).then(({ data }) => {
          row['isLoading'] = false;
          if (data && data.response.responses[0].sync) {
            this.applyPagination({ pageNo: 1 }, true);

            notifyApiSuccess(`DRS has been updated to ${action.toLowerCase()}.`, 'Updated!')
          } else {

            notifyApiError(`DRS has not ${action.toLowerCase()} yet`, 'Error')
          }
        });
      },
      onCancel() {

      },
    })
  }

  isCloseDRSAllowed(drsStatus) {
    const notAllowedStatues = ['CLOSED', 'COMPLETED', 'OPEN'];
    if (this.state.application === 'B2B') {
      notAllowedStatues.push('PENDING');
    }
    return notAllowedStatues.indexOf(drsStatus) < 0;
  }

  deletedrs = row => {
    if (row.consignments != null) {
      notifyApiError('Delete DRS not allowed untill shipments are delinked', 'Validation')
      return false;
    }

    const that = this;
    Modal.confirm({
      title: 'Do you want to delete these items?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const payload = {
          request: [{
            drs: row,
            action: 'DELETE',
            drsCode: row.drsCode,
            referenceId: row.drsCode
          }]
        };

        that.props.updateDrs(payload).then(res => {
          that.applyPagination({ pageNo: 1 }, true);
        })
      },
      onCancel() {

      },
    })
  }

  componentDidUpdate(prevProps) {
    const { summary } = this.props;
    const { dataCardIcon } = this.state;
    if (summary !== prevProps.summary) {
      const DataCardList = [
        {
          title: 'Open DRS',
          documentType: "OPEN_DRS",
          description: summary['OPEN_DRS']?.count,
          icon: dataCardIcon[`activeAwbIcon${0}`]
        },
        {
          title: 'Added Today',
          documentType: 'DRS_ADDED_TODAY',
          description: summary['DRS_ADDED_TODAY']?.count,
          icon: dataCardIcon[`activeAwbIcon${1}`]
        },
        {
          title: 'Pending Local AWB',
          documentType: 'PENDING_CONSIGNMENT_LOC',
          description: summary['PENDING_CONSIGNMENT_LOC']?.count,
          icon: dataCardIcon[`activeAwbIcon${2}`]
        }
      ]
      this.setState({
        DataCardList,
        summaryLoader: false
      })
    }
  }

  downloadDrsData(drsId, drsCode) {

    const reportParams = btoa(JSON.stringify({
      resource: { question: 2140 },
      params: { drs_code: drsCode }
    }));

    window.open(`/appv2/reports/drs?reportParams=${reportParams}`);
  }

  getPaginatedData = (pageNo) => {
    const { PaginatedDrs, } = this.props;
    if (!PaginatedDrs ? !PaginatedDrs : !PaginatedDrs[pageNo]) {
      this.applyPagination({ pageNo })
    }
    this.setState({
      pageNo
    });
  }


  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }
    const filter = { ...this.state.filterPayload.filters, ...filters, totalCount: this.props?.drsData?.totalCount || 0 }
    if (this.state.flowType === FlowTypes.FORWARD) {
      filter['flowTypes'] = [FlowTypes.FORWARD];
    } else {
      filter['flowTypes'] = [FlowTypes.RTO, FlowTypes.RTS];
    }
    this.props.fetchdrs({ filters: filter }, isReset);
  }

  getFilterPayload = () => {
    const that = this;
    return [
      {
        title: 'Search By DRS ID\'s',
        type: FILTER_INPUT_TYPE.INPUT,
        id: "drsSearchId",
        onChange: e => {
          this.setState({
            drsSearchInput: e.target.value,
            filterPayload: {
              filters: {
                ...that.state.filterPayload.filters,
                drsCodes: [e.target.value]
              }
            }
          });
        },
        metadata: {
          defaultValue: this.state.filterPayload?.filters?.drsCodes ? this.state.filterPayload?.filters?.drsCodes[0] : '',
          value: this.state.drsSearchInput
        }
      },
      {
        title: "Status",
        type: FILTER_INPUT_TYPE.RADIO_BUTTON,
        onChange: e => {
          this.setState({
            filterPayload: {
              filters: {
                ...that.state.filterPayload.filters,
                drsStatuses: [e.target.value]
              }
            }
          });
        },
        metadata: {
          buttonStyle: "solid",
          defaultValue: this.state.filterPayload?.filters?.drsStatuses ? this.state.filterPayload?.filters?.drsStatuses[0] : '',
          values: [{
            'label': 'Pending',
            'value': TRANSISTION_STATE.STATE_PENDING
          }, {
            'label': 'Handed Over',
            'value': TRANSISTION_STATE.STATE_HANDED_OVER
          }, {
            'label': 'Completed',
            'value': TRANSISTION_STATE.STATE_COMPLETED
          }, {
            'label': 'Closed',
            'value': TRANSISTION_STATE.STATE_CLOSED
          }, {
            'label': 'In Transist',
            'value': TRANSISTION_STATE.STATE_IN_TRANSIT
          }]
        }
      },
      {
        title: "Created On",
        type: FILTER_INPUT_TYPE.DATERANGE,
        onChange: values => {
          that.setState({
            createdAtVal: values,
            filterPayload: {
              filters: {
                ...that.state.filterPayload.filters,
                createdAt: { from: moment(values[0]).startOf('day').valueOf(), to: moment(values[1]).endOf('day').valueOf() }
              }
            }
          });
        },
        metadata: {
          defaultValue: this.state?.createdAtVal
        }
      },
    ];
  }

  resetFilters = () => {
    const { filterPayload } = this.state;
    const { filters } = filterPayload
    delete filters.drsCodes
    delete filters.drsStatuses
    delete filters.createdAt

    this.setState({
      drsSearchInput: undefined,
      filterPayload: {
        filters: {
          ...filters
        }
      }
    }, () => this.applyPagination({ pageNo: 1 }, true))
  }

  reloadDRSList = () => {

    const { filterPayload } = this.state;
    const { filters } = filterPayload
    delete filters.drsCodes
    delete filters.drsStatuses
    delete filters.createdAt

    this.setState({
      pageNo: 1,
      drsSearchInput: undefined,
      filterPayload: {
        filters: {
          ...filters
        }
      }
    }, () => {
      this.applyPagination({ pageNo: 1 }, true);
    })
    // this.InputSearchRef.current.input.state.value = '';
  }

  onFormSubmit = (data, reset) => {
    this.setState({
      filterPayload: {
        filters: reset ? {
          pageNo: 1,
          pageSize: 10,
          fetchObjects: ['TRACKING_DEVICE', 'CONSIGNMENTS', 'ASSEST', 'USER'],
          drsCodes: []
        } : {
          ...this.state.filterPayload.filters,
          ...data
        }
      }
    }, _ => {
      this.applyPagination(data, true);
    })
  }

  handleBatchingModalClose = (action) => {
    this.setState({ openStartBatchingModal: false })
    if (action == 'success') {
      this.setState({ donNotAllowBatching: true })
    }
  }

  render() {
    const { DataCardList, drsPermission, columns, pageNo, application, expandedRowKeys,
      flowType, flowTypes, summaryLoader, donNotAllowBatching } = this.state
    const { drsData, PaginatedDrs, config, batchingStatus } = this.props
    const actionBtns = (<DRSFilters onFormSubmit={this.onFormSubmit} />);

    console.log("batchingStatus", batchingStatus)
    console.log("this.props", this.props)
    console.log("condition1", (this.props.auditStatus?.isAuditOn))
    console.log("condition2",  donNotAllowBatching)
    console.log("condition3", (batchingStatus !== "Start Batching"))
    console.log("final condition", (this.props.auditStatus?.isAuditOn || donNotAllowBatching || batchingStatus !== "Start Batching"))

    return (
      <>
        <div className="drs">
          <div className="legacy-sparkline">
            <div className="legacy-sparkline-datacards">
              {
                summaryLoader ?
                  <Spin spinning={summaryLoader} /> :
                  DataCardList && DataCardList.length > 0 ?
                    DataCardList.map(item => (
                      <div className="flex-1">
                        <DataCard {...item} />
                      </div>
                    )) :
                    <></>
              }
            </div>
            <div className="legacy-sparkline-ctas">
              {
                drsPermission.create &&
                <Button disabled={this.props.auditStatus?.isAuditOn} type="primary" className="lsn-btn-primary"
                  onClick={() => this.props.history.push('/appv2/inventory/form/drs')} size="large" block icon="plus">
                  Create DRS
                </Button>
              }
              {config?.crossUtilConfig?.crossUtilBookingEnabledLocations &&
                Array.isArray(config.crossUtilConfig.crossUtilBookingEnabledLocations) &&
                config.crossUtilConfig.crossUtilBookingEnabledLocations.includes(Constants.userLocationId) &&
                <Button disabled={(this.props.auditStatus?.isAuditOn || donNotAllowBatching || batchingStatus !== "Start Batching")} type="primary" className="lsn-btn-primary"
                  onClick={() => {
                    this.setState({ openStartBatchingModal: true })
                  }} size="large" block>
                  {batchingStatus}
                </Button>
              }
            </div>
          </div>
          <Card className="inventorydrs" title={<h2>{(flowType === flowTypes.RTO) ? 'Return ' : ''}DRS List</h2>} extra={actionBtns || []}>
            <Table size="middle"
              bordered
              locale={{
                emptyText: (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
              }}
              rowKey={(row => (row.id))}
              expandedRowRender={application === 'B2B' ? this.getExpandedRow : null}
              expandedRowKeys={expandedRowKeys}
              onExpand={(expanded, row) => { this.setState({ expandedRowKeys: expanded ? row.id.toString() : "0", currentAction: 'EXPANDED', currentConsigmentId: row.consignmentBO.id }); if (!this.props.selectedConsigment[row.consignmentBO.id]) { this.props.getConsignment(row.consignmentBO.id) } }}
              loading={drsData?.loading}
              pagination={{
                onChange: this.getPaginatedData,
                total: drsData?.totalCount,
                position: 'bottom',
                current: pageNo,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
              }}
              dataSource={PaginatedDrs ? PaginatedDrs[pageNo] : []}
              scroll={{ x: 800 }}
              columns={columns} />
          </Card>
        </div>

        {this.state.openStartBatchingModal &&
          <BatchingModal openModal={this.state.openStartBatchingModal}
            closeModal={this.handleBatchingModalClose} />
        }
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  drsData: state.inventoryDrs,
  PaginatedDrs: state.inventoryDrs.PaginatedDrs,
  summary: state.app.summary,
  config: state.app.configurations,
  auditStatus: state.audit?.auditStatus,
  batchingStatus: state.app.batchingStatus
});
const mapDispatchToProps = dispatch => ({
  getSummary: page => dispatch(getSummary(page)),
  updateDrs: payload => dispatch(updateDrs(payload)),
  fetchdrs: (payload, isReset) => dispatch(fetchdrs(payload, isReset))
});
export default connect(mapStateToProps, mapDispatchToProps)(DRSDashboard)