export const schemaBlueprint = { 
    "customers"     : {
        "schema"    : {
            "type"  : "string", "title" : "Customers",
        },
        "uiSchema"      : {
            "ui:widget" : "CustomerWidget"
        },
        "errorList"  : {
            "required" : "Customer(s) are mandatory!"
        }
    },
    "partners"      : {
        "schema"    : {
            "type"  : "string", "title" : "Partners",
        },
        "uiSchema"      : {
            "ui:widget" : "PartnerField"   
        },
        "errorList"  : {
            "required" : "Partner(s) are mandatory!"
        }
    },
    "destinations"  : {
        "schema"    : {
            "type"  : "string", "title" : "Destination Locations",
        },
        "uiSchema"      : {
            "ui:widget" : "DestinationWidget"
        },
        "errorList"  : {
            "required" : "Destination Location(s) are mandatory!"
        }
    },
    "dateRange"     : {
        "schema"    : {
            "type"      : "string",
            "title"     : "Date",
            "maxDateRange" : {
                "unit"     : "month",
                "amount"   : 1
            },
            "disableFutureDates" : true
        },
        "uiSchema"      : {
            "ui:widget" : "DateRangeWidget"   
        },
        "errorList"  : {
            "required" : "Date Range is mandatory!"
        }
    },
    "employees"  : {
        "schema"    : {
            "type"  : "string", "title" : "Employee (FE)",
        },
        "uiSchema"      : {
            "ui:widget" : "EmployeeWidget"   
        },
        "errorList"  : {
            "required" : "Employee (FE) are mandatory!"
        }
    },
    "waybill"    : {
        "schema" : {
            "type"   : "string",
            "title"  : "Upload Waybill",
            "path"   : "reports",
            "objKey" : "reports",
            "directUpload" : true,
            "disableUploadButton" : true
        },
        "uiSchema" : {
            "ui:widget"   : "file",
            "acceptTypes" :[ ".xlsx", ".xls" ]
        },
        "errorList"  : {
            "required" : "Waybill is mandatory!"
        }
    },
    "trip"    : {
        "schema" : {
            "type"   : "string",
            "title"  : "Trip Sheet",
            "path"   : "reports",
            "objKey" : "reports",
            "directUpload" : true,
            "disableUploadButton" : true
        },
        "uiSchema" : {
            "ui:widget"  : "file",
            "acceptTypes" :[ ".xlsx", ".xls" ]
        },
        "errorList"  : {
            "required" : "Trip Sheet is mandatory!"
        }
    },
    "yesNo"  : {
        "schema" : {
            "type"   : "boolean"
        },
        "uiSchema" : {
            "ui:widget"  : "radio"
        },
        "initValue"  : false
    },
    "vendors"     : {
        "schema"    : {
            "type"  : "string", "title" : "Vendors",
        },
        "uiSchema"      : {
            "ui:widget" : "VendorWidget"
        },
        "errorList"  : {
            "required" : "Vendor(s) are mandatory!"
        }
    }
}
