import React, { useEffect, useRef, useState } from "react";
import { Divider, Icon, Spin, Button } from "antd";

import { useSelector } from "react-redux";
import { Constants } from "../../../common/utils/constants";
import { createRTO, fetchRTOUploadList } from "./actions";
import { checkConfigurationsForBooleanPermission, notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import schemaTemplate from "./form.schema";
import { UPLOAD_BUTTON_TYPE } from "../../../common/utils/constants";
import FormWrapper from "../../ui/formComponent/form";
import { RtoUploadListTable } from "./rtoUploadListTable";
import { useNavigate } from "react-router-dom";

// import tableListData from "../../tableResponse.json";

var intervalId = undefined;

const RTOUpload = () => {

    const [loading, SetLoading] = useState(false);
    const [formData, SetFormData] = useState({});
    const [allSuccess, SetAllSuccess] = useState(undefined);
    const [formSchema, SetFormSchema] = useState(undefined);
    const [rtoUploadList, setRTOUploadList] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState(null);

    const sampleFileLinks = [`${Constants.s3BucketLink}/documents/templates/xlsx/rto-bulk-upload-template.xlsx`]
    const sampleFileTexts = ["Download Bulk Upload Sample File"]

    const errorMessageText = {
        RTO_BULK_UPLOAD: "RTO Upload",
    }

    const config = useSelector((state) => state.app.configurations)

    var formRef = useRef();

    const navigate = useNavigate();
    
    useEffect(() => {
        if(!checkConfigurationsForBooleanPermission('enabledRearchRTOBulkUpload')) {
            navigate(-1);
        }

        schemaTemplate.schema.dependencies.uploadType.oneOf.map((upload, index) => {
            upload.properties.s3path = {
                ...upload.properties.s3path,
                downloadSampleLink: sampleFileLinks[index],
                downloadSampleText: sampleFileTexts[index],
                fileUploadProps: {
                    path: "rto-upload",
                    objKey: "rto-upload",
                    showLoader: true,
                    acceptTypes: [".xlsx", ".xls"],
                    directUpload: true,
                    fileUploadType: UPLOAD_BUTTON_TYPE.BUTTON,
                    showUploadList: false,
                    doNotAllowMultiple: true,
                    uploadButtonHtml: (
                        <Button>
                            <Icon type="upload" /> Upload Waybill File - .xlsx or .xls format
                        </Button>
                    ),
                    disableUploadButton: true
                },
                onUploadFinish: (path, fileName) => {
                    setUploadedFileName(fileName)
                }
            }
        })
        SetFormSchema(schemaTemplate);
    }, []);

    useEffect(() => {
            triggerRTOUploadList()

            intervalId = setInterval(() => {
                triggerRTOUploadList()
            }, 10000)

            return _ => {
                clearInterval(intervalId)
            }
    }, [refreshData])

    const triggerRTOUploadList = () => {
        SetLoading(true)
        fetchRTOUploadList()
            .then((data) => {
                if (Array.isArray(data?.list) && data.list.length > 0) {
                    setRTOUploadList(data.list)
                    if (data.list.length == 0 ||
                        !data.list.some(uploadList => (uploadList.status == "PROCESSING" || uploadList.status == "IN_QUEUE"))) {
                        clearInterval(intervalId)
                    }
                }
            })
            .catch((error) => {
                notifyApiError(JSON.stringify(error))
                console.error("Following error occurred when calling the fetchPickupUploadList api",
                    error)
            })
        SetLoading(false)
    }

    const onCreateRTOComplete = _ => {
        const tempSchema = { ...formSchema };
        SetFormSchema(undefined);
        SetFormData(current => {
            delete current.s3path;
            return current;
        });
        setTimeout(() => {
            SetFormSchema(tempSchema);
            SetLoading(false);
        }, 100);
    }

    const handleCreateRTO = _ => {
        SetLoading(true);
            const reqBody = {
                s3FileUrl: formData.s3path,
                type: formData.uploadType,
                uploadfileName: uploadedFileName
            }

            createRTO(reqBody)
                .then((data) => {
                    notifyApiSuccess("Submitted successfully")
                    setRefreshData(!refreshData)
                })
                .catch((errors) => {
                    notifyApiError(errors.error)
                    console.error("createRTO api failed with following error -> ",
                        errors)
                })
            onCreateRTOComplete();
    };

    return (
        <Spin spinning={loading || !formSchema}>
            <div className="white-bg border-radius-s border-grey spacer-l flex-column flex-gap-l">
                <div className="font-size-l-2 text-bold">RTO Bulk Booking</div>
                <Divider style={{
                    margin: 0,
                    width: "calc(100% + 50px)",
                    left: -25
                }} />
                <div className="flex-column spacer-m flex-gap-l">
                    {
                        formSchema &&
                        <FormWrapper {...formSchema} formData={formData} hideSubmit={true}
                            onChange={SetFormData} onSubmit={handleCreateRTO} ref={formRef} />
                    }
                    <div className="flex-box flex-gap-l align-items-center">
                        <Button disabled={!formData?.s3path} className="lsn-btn-primary" type="primary" onClick={_ => {
                            formRef.current?.formReference?.current?.submit()
                        }}>
                            Upload
                        </Button>
                        {
                            allSuccess ?
                                <div className="spacer-s grey-bg flex-box flex-gap-m border-grey border-radius-m align-items-center">
                                    <Icon type="info-circle" />
                                    <div>File Has been processed!</div>
                                    <div className={allSuccess?.status === false ? "error-color" : allSuccess?.status === true ? "success-color" : undefined}>
                                        {
                                            allSuccess?.status === false ?
                                                <>Some {errorMessageText[allSuccess?.uploadType]} assignments have errors. Please check the response file </> :
                                                allSuccess?.status === true ?
                                                    <>FE(s) assigned successfully for {errorMessageText[allSuccess?.uploadType]}(s)</> :
                                                    <></>
                                        }
                                    </div>
                                </div> :
                                <></>
                        }
                    </div>
                </div>
                {true && rtoUploadList.length > 0 &&
                    <div>
                        <RtoUploadListTable tableData={rtoUploadList} handleRefreshList={() => { setRefreshData(!refreshData) }} />
                    </div>
                }
            </div>
        </Spin>
    )
};

export default RTOUpload;