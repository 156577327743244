import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./components/layouts/Sidebar";
import Header from "./components/shared/header/Header";
import * as CONSTANTS from './app/appConstants';
import { apiRequest } from "./common/utils/apiGateway";
import { useDispatch, useSelector } from "react-redux";
import SubmoduleTabs from "./components/layouts/SubmoduleTabs";
import MwebHeader from "./components/shared/header/mweb.header";
import AuditNotice from "./components/inventory/audit/audit-notice";
import { Spin } from "antd";

export default function RootLayout(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [ fetchingConfig, SetFetchingConfig ] = useState(true);
    const loader = useSelector(state => state.loaderReducer?.loader);

    useEffect(() => {
        if (localStorage.user) {
            const user = JSON.parse(atob(localStorage.user));
            if (window.clarity) {
              window.clarity("identify", user.id + "");
              window.clarity("set", "name", user.name);
              window.clarity("set", "number", user.number);
            }
            if (window.gtag) {
              window.gtag("set", {
                "userID"     : user.id + "",
                "userName"   : user.name,
                "userNumber" : user.number
              })
            }
        }
        apiRequest({ url : "/b2b/v1/config" })
        .then(response => {
          if (response.data.response.config) {
            localStorage.setItem('_configrations', btoa(JSON.stringify(response.data.response.config)));
            dispatch({
                type    : CONSTANTS.GET_CONFIGURATIONS_SUCCESS,
                payload : response.data.response.config
            });
            SetFetchingConfig(false);
          }
        })
        .catch(error => dispatch({
            type: CONSTANTS.GET_CONFIGURATIONS_FAILURE,
            payload: error
          }));
    }, [])

    const history = {
        push   : navigate,
        back   : _ => navigate(-1),
        goBack : _ => navigate(-1),
        location
    }

    const noSideBar = useSelector(state => state.layoutReducer?.noSideBar);
    const noHeader  = useSelector(state => state.layoutReducer?.noHeader);
    const noTabs    = useSelector(state => state.layoutReducer?.noTabs);
    const isMobile  = useSelector(state => state.layoutReducer?.isMobile);
    const moduleObj = useSelector(state => state.layoutReducer?.moduleObj);

    return (
        <div style={{ padding : 0, minHeight : "100vh" }}>
            {
                !noHeader &&
                <Header location={history.location} history={history} isMobile={isMobile} fetchingConfig={fetchingConfig} />
            }
            {
                isMobile &&
                <MwebHeader history={history} location={location} {...moduleObj}
                 pathname={location.pathname} onClick={_ => history.goBack()} 
                 backText={moduleObj?.backText} name={moduleObj?.name} />
            }
            <Spin spinning={fetchingConfig}>
                <div style={{ minHeight : `calc(100vh - ${isMobile ? "40" : "80"}px)` }} className="flex-box">
                    {
                        !noSideBar &&  <Sidebar />
                    }
                    <div className={"flex-5"} style={{ background : "#f0f2f5" }}>

                        <AuditNotice location={history.location} history={history} />
                        
                        {
                            !noTabs && <SubmoduleTabs />
                        }
                        {
                            !fetchingConfig && 
                            <div className={!isMobile ? " spacer-hm" : ""}>
                                <Spin spinning={loader}>
                                    <Outlet/>
                                </Spin>
                            </div>
                        }
                    </div>
                </div>
            </Spin>
        </div>
    )
}