import { combineReducers } from 'redux';
import { trackingReducer } from '../components/tracking/reducer';
import { trackingPopupReducer } from '../components/TrackingPopup/reducer';
import { suspiciousReducer } from '../components/suspicious/reducer';
import { appReducer } from '../app/appReducer'
import { auditReducer } from '../components/inventory/audit/reducer'
import { headerReducer } from '../components/shared/header/reducer';
import { trackingListingReducer } from '../components/tracking/TrackingListing/reducer';
import { settingsReducer } from '../components/settings/settingsReducer';
import { ingestReducer } from '../components/reports/ingest/reducer';
import { ReatilerCustomerReducer } from '../components/inbound/inbound-create/b2b-lite-booking/customer-management/reducer';
import { CustomerReducer } from '../components/settings/customer/reducer';
import { UserReducer } from '../components/settings/user/reducer';
import { RoleReducer } from '../components/settings/role/reducer';
import { LocationReducer } from '../components/settings/location/reducer';
import { PromiseTimeReducer } from '../components/shared-components/customer-promise-time/reducer';
import { AwbReducer } from '../components/inventory/awb/reducer';
import { PrintReducer } from '../components/print/reducer';
import { ExpectedReducer } from '../components/inbound/expected/reducer';
import { ScanReducer } from '../components/inbound/scan/reducer';
import { assetsReducer } from '../components/settings/asset/reducer';
import { DashboardReducer } from '../components/zoho-dashboard/reducer';
import { zohoReducer } from '../components/settings/zoho-dashboard/reducer';
import { CustomConfigReducer } from "../components/settings/config/reducer";
import { RouteReducer } from '../components/settings/route/reducer';
import { DrsReducer } from '../components/inventory/drs/reducer';
import { MonitoringServiceReducer } from "../components/monitoring-report/reducer";
import { InventoryClosureReducer } from '../components/inventory/closure/reducer';
import { podLiveReducer } from '../components/pod/pod-live/reducer';
import { rtoWaybillReducer } from '../components/rto/waybill/reducer';
import { uploadAssignedPickupReducer } from '../components/pickup/reducer';
import { labelPrintingReducer } from "../components/inventory/label-print/reducer";
import { rtoDrsReducer } from '../components/rto/rto-drs/reducer';
import { ScanListReducer } from '../components/ui/scan-list/controller';
import { LoaderReducer } from '../services/loader/reducer';
import { rtoEligibleReducer } from '../components/rto/rto–eligible/reducer';
import { ReportsReducer } from '../components/reportsv2/state/reports.reducer';
import { ExceptionReducer as inventoryException } from '../components/inventory/exceptions/state/reducer';
import { CoDV2Reducer } from '../components/codv2/state/reducer';
import { LayoutReducer } from '../components/layouts/state/reducer';
import { UserLocationMappingReducer } from '../components/operations/drs/state/reducer';
import { ReasonsReducer } from '../components/primary-scan/service/reasons.reducer';

const rootReducer = combineReducers({
    tracking: trackingReducer,
    trackingDetails: trackingPopupReducer,
    trackingListing: trackingListingReducer,
    suspicious: suspiciousReducer,
    app: appReducer,
    audit: auditReducer,
    header: headerReducer,
    settingsReducer: settingsReducer,
    ingest: ingestReducer,
    RetailerCustomer: ReatilerCustomerReducer,
    settingsCustomers: CustomerReducer,
    settingsUsers: UserReducer,
    settingsRoles: RoleReducer,
    settingLocation: LocationReducer,
    promiseTime: PromiseTimeReducer,
    inventoryAwb: AwbReducer,
    print: PrintReducer,
    expected: ExpectedReducer,
    scan: ScanReducer,
    assets: assetsReducer,
    dashboard: DashboardReducer,
    zoho: zohoReducer,
    customConfig: CustomConfigReducer,
    settingsRoute: RouteReducer,
    inventoryDrs: DrsReducer,
    monitoringService: MonitoringServiceReducer,
    inventoryClosure: InventoryClosureReducer,
    podLive: podLiveReducer,
    rtoWaybill: rtoWaybillReducer,
    uploadAssignedPickup: uploadAssignedPickupReducer,
    inventoryLabelPrinting: labelPrintingReducer,
    scanListReducer: ScanListReducer,
    loaderReducer: LoaderReducer,
    rtoEligible: rtoEligibleReducer,
    rtoDrs: rtoDrsReducer,
    reportsReducer: ReportsReducer,
    exceptions: inventoryException,
    codV2Reducer: CoDV2Reducer,
    layoutReducer: LayoutReducer,
    userLocationMappingReducer: UserLocationMappingReducer,
    reasonsReducer : ReasonsReducer
});

export default rootReducer;
