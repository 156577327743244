import React from "react";
import { Card } from "antd";
import Barcode from "../../../../ui/barcode";

import moment from "moment";
import "./label-sticker.scss";

export class LabelSticker extends React.PureComponent{
    render() {
        const { entityData } = this.props;
        return (
            <Card className="label-sticker-multi-page-card" >

                <div className="label-sticker-multi-page-container">
                    <div className="multi-sticker-code-container">
                        <div className="font-size-m-2 text-align-center text-semi-bold" style={{ color : "#000" }}>{entityData?.manifestCode}</div>
                        <div className="multi-sticker-barcode">
                            <Barcode width={3} format="CODE128"
                                value={entityData?.manifestCode}
                                showText={false} barCodeId="waybillNo"
                            />
                        </div>
                        <hr/>
                    </div>  
                </div>

                <div className="flex-column flex-gap-s spacer-hs" >
                    <div className="flex-box align-items-flex-start flex-gap-s justify-content-space-between">
                            <div className="flex-1 flex-column flex-gap-s align-items-flex-start">
                                <div className="font-size-sm-1">Origin</div>
                                <div className="text-semi-bold font-size-m-1">{entityData?.originLocation}</div>
                            </div>

                            <div className="flex-1 flex-column flex-gap-s align-items-flex-end">
                                <div className="font-size-sm-1">Destination</div>
                                <div className="text-semi-bold font-size-m-1">{entityData?.destinationLocation}</div>
                            </div>
                    </div>
                    <div className="flex-box align-items-flex-start flex-gap-s justify-content-space-between">
                        <div className="flex-1 flex-column flex-gap-s align-items-flex-start">
                            <div className="font-size-sm-1">Item Count </div>
                            <div className="text-semi-bold font-size-m-1">{entityData?.totalShipmentCount}</div>
                        </div>

                        <div className="flex-1 flex-column flex-gap-s align-items-flex-end">
                            <div className="font-size-sm-1">Flow type</div>
                            <div className="text-semi-bold font-size-m-1">{entityData?.flowType}</div>
                        </div>

                    </div>
                    <div className="flex-1 flex-row flex-gap-s">
                            <div className="font-size-sm-1">Lock Time</div>
                            <div className="text-semi-bold font-size-sm-1 ">{ entityData?.manifestLockTime ? moment(entityData.manifestLockTime).format("DD/MM/YY hh:mm A") : moment().format("DD/MM/YY hh:mm A")}</div>
                        </div>
                </div>
            </Card>
        )
    }
}