import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
    Card,
    Form,
    Select,
    Input,
    Checkbox,
    Icon,
    Alert,
    Spin,
} from "antd";
import {
    updateSelectedEntityType,
    fetchEntityLabelPrintingTypes,
    fetchEntityLabelPrintingData,
    updateAutoPrintFlag,
    printBulkAWBSticker,
    fetchAndPrintSingleStickerDispatcher,
    clearCurrentScannedEntityInfo,
    toggleLoader
} from "./action";

import LabelPrintingScanResponse from "./components/scan-response/scan-response";
import PrintButton from "./components/print-component/print-component";
import ScannedHistoryTable from "./components/scanned-history/scanned-history";
import RescanModal from "./components/rescan-modal/rescan-modal";

import { ACTION_TYPES, PRINT_ENTITY_TYPES, SCAN_INPUT_TYPES } from "./constants";
import BulkOperation from "../../shared-components/bulk-operation";

import { Constants } from "../../../common/utils/constants";
import InventoryPrintService from "../../../services/inventory/print.service";
import { downloadFile } from "../../../common/utils/utils";
import LabelTemplate from "../../common/label-rvp/label-template";
import LabelRvp from "../../common/label-rvp";
import LabelValmoXUtilMapping from "../../common/lable-valmo";

const { Option } = Select;

const LabelPrinting = ({
    loading,
    entityTypes,
    selectedEntity,
    updateSelectedEntityType,
    fetchEntityLabelPrintingTypes,
    fetchEntityLabelPrintingData,
    currentScannedEntityInfo,
    scannedEntityHistory,
    scanStatus,
    rescanModalState,
    isAutoPrintEnabled,
    updateAutoPrintFlag,
    clearCurrentScannedEntityInfo,
    sampleAWBDownloadLink = `${Constants.s3BucketLink
    }/documents/templates/xlsx/AWB+Sticker+Upload+Sample.xlsx`,
    printBulkAWBSticker,
    fetchAndPrintSingleStickerDispatcher,
    labelPrintingPDFUrl,
    toggleLoader
}) => {
    let componentRef = useRef(null);
    const { show, message } = rescanModalState;
    const [entityCode, setEntityCode] = useState("");
    const [printData, setPrintData] = useState({});
    const [uploadBookingMethodParams] = useState({
        partnerId: Constants.PARTNER_ID,
        locId: Constants.userLocationId,
    });

    const handleEntityChange = (value) => {
        updateSelectedEntityType(value);
        setEntityCode("");
    };

    const handleEntityCodeChange = (e) => {
        setEntityCode((e.target.value).trim());
    };

    const handleAutoPrintChange = (e) => {
        updateAutoPrintFlag(e.target.checked);
    }

    const handleScanCode = async (scanInputType) => {
        let payload = {};
        if (selectedEntity === PRINT_ENTITY_TYPES.MANIFEST_STICKER_PRINT) {
            const currentScanInfo = {
                manifestCode: entityCode,
                scanInputType: scanInputType,
            };
            payload = {
                manifestScans: [
                    {
                        ...currentScanInfo
                    }
                ]
            };
            fetchEntityLabelPrintingData(selectedEntity, payload, false);
        }
        else if (selectedEntity === PRINT_ENTITY_TYPES.SHIPMENT_LABEL_PRINT) {
            toggleLoader(false)
            fetchAndPrintSingleStickerDispatcher(selectedEntity, entityCode).then((printValue) => {
                if (printValue?.print) {
                    setPrintData(printValue)
                    clearCurrentScannedEntityInfo();
                } else {
                    toggleLoader(false)
                }
            })
        }
    }

    const handleScanCodeOnEnter = () => {
        handleScanCode(SCAN_INPUT_TYPES.BARCODE_SCANNER);
        setEntityCode(""); //clears the Input field after Scan
    };

    const handleScanCodeOnClick = () => {
        handleScanCode(SCAN_INPUT_TYPES.KEYBOARD);
        setEntityCode("");  //clears the Input field after Scan
    };

    const customHandlePrint = () => {
        downloadFile(labelPrintingPDFUrl, "_blank");
    }
    const handleCallBack = (ev) => {
        const payload = {
            "url": ev.uploadedFilePath
        }
        printBulkAWBSticker(payload);
    };

    useEffect(() => {
        fetchEntityLabelPrintingTypes();
    }, []);

    const handlePrintTask = () => {
        setPrintData({});
        clearCurrentScannedEntityInfo();
        toggleLoader(false)
    }

    return (
        <>
            <div>
                <Spin spinning={loading}>
                    <Card
                    >
                        <div className="flex-box flex-column justify-content-space-between">
                            <div className="print-label-container">
                                <Form layout="vertical" colon={false}>
                                    <Form.Item
                                        labelCol={{ span: 3 }}
                                        wrapperCol={{ span: 23 }}
                                    >
                                        <Select
                                            placeholder="Select Print Type"
                                            style={{ width: "25%" }}
                                            value={selectedEntity}
                                            onChange={handleEntityChange}
                                        >
                                            {
                                                entityTypes.map((entity, index) => {
                                                    return (
                                                        <Option
                                                            key={`entity-type-${entity.labelCode}-${index}`}
                                                            value={entity.labelCode}
                                                            disabled={!entity.isActive}
                                                        >
                                                            {entity.name}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>

                                    {
                                        selectedEntity && (
                                            <Form.Item
                                                label={selectedEntity === PRINT_ENTITY_TYPES.SHIPMENT_LABEL_PRINT ? "Scan Shipment ID" : "Scan Manifest No."}
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 23 }}
                                            >
                                                <div className="flex-box justify-content-flex-start" style={{ gap: "4rem" }}>
                                                    <Input.Search
                                                        placeholder="Scan Barcode to Print the Label"
                                                        style={{ width: "50%" }}
                                                        value={entityCode}
                                                        onChange={handleEntityCodeChange}
                                                        allowClear
                                                        onPressEnter={handleScanCodeOnEnter}
                                                        addonAfter={
                                                            <span
                                                                style={{
                                                                    cursor: !entityCode.length ? "no-drop" : "pointer",
                                                                    pointerEvents: !entityCode.length ? "none" : "auto",
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}
                                                                onClick={handleScanCodeOnClick}
                                                            >
                                                                <Icon type="barcode" /> &nbsp;Scan
                                                            </span>
                                                        }
                                                    />
                                                    <div className="flex-box justify-content-space-evenly flex-gap-xl align-items-center">
                                                        {
                                                            selectedEntity !== PRINT_ENTITY_TYPES.SHIPMENT_LABEL_PRINT && (
                                                                <Checkbox
                                                                    checked={isAutoPrintEnabled}
                                                                    onChange={handleAutoPrintChange}
                                                                >Auto Print</Checkbox>
                                                            )
                                                        }
                                                        <PrintButton
                                                            selectedEntity={selectedEntity}
                                                            labelPrintingPDFUrl={labelPrintingPDFUrl}
                                                            buttonText="Print"
                                                            isDisabled={isAutoPrintEnabled || !currentScannedEntityInfo || !entityCode.length}
                                                            entityData={currentScannedEntityInfo}
                                                            isAutoPrintEnabled={isAutoPrintEnabled}
                                                            afterPrint={_ => {
                                                                clearCurrentScannedEntityInfo();
                                                            }}
                                                        />
                                                        {
                                                            selectedEntity === PRINT_ENTITY_TYPES.SHIPMENT_LABEL_PRINT && (
                                                                <div className="flex-box justify-content-space-evenly flex-gap-xl align-items-center">
                                                                    <div className="element-splitter">
                                                                        {"OR"}
                                                                    </div>

                                                                    <BulkOperation
                                                                        buttonLabel="Bulk Print"
                                                                        title="Bulk Upload"
                                                                        parentComponentName="inventory-label-printing"
                                                                        bulkType="Booking"
                                                                        sampleDownloadLink={sampleAWBDownloadLink}
                                                                        objKey="waybills"
                                                                        path="inventory_label_printing"
                                                                        syncToServerAction="'UPLOAD_BOOKING'"
                                                                        uploadMethod={new InventoryPrintService().printBulkAWBSticker}
                                                                        uploadMethodParams={uploadBookingMethodParams}
                                                                        customcallback={handleCallBack}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                            </Form.Item>
                                        )
                                    }


                                </Form>

                                {selectedEntity && currentScannedEntityInfo?.waybillNo &&
                                    <div className="flex-box justify-content-space-between align-items-center">
                                        <LabelPrintingScanResponse />
                                    </div>
                                }

                                {
                                    (scanStatus.success || scanStatus.error) && (
                                        <div className="flex-box justify-content-flex-end">
                                            <div style={{ width: "40%" }}>
                                                <Alert
                                                    message={scanStatus.message}
                                                    type={scanStatus.success ? "success" : "error"}
                                                    closable
                                                    showIcon
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    {
                                        selectedEntity && (selectedEntity !== PRINT_ENTITY_TYPES.SHIPMENT_LABEL_PRINT) && scannedEntityHistory.length > 0 && (
                                            <ScannedHistoryTable />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            show && message && (
                                <RescanModal />
                            )
                        }
                    </Card>
                </Spin>
            </div>

            {
                printData?.print && (<>
                    {printData?.valmoXUtilMapping ? 
                    <LabelValmoXUtilMapping handlePrintTask={_ => handlePrintTask()} printData={printData} />  
                    : 
                    <LabelRvp handlePrintTask={_ => handlePrintTask()} printData={printData} />
                    }
                   
                </>)
                
            }
        </>
    )
};

const mapStateToProps = (state) => ({
    loading: state.inventoryLabelPrinting.loading,
    entityTypes: state.inventoryLabelPrinting.entityLabelTypes,
    selectedEntity: state.inventoryLabelPrinting.selectedEntity,
    currentScannedEntityInfo: state.inventoryLabelPrinting.currentScannedEntityInfo,
    scannedEntityHistory: state.inventoryLabelPrinting.scannedEntityHistory,
    scanStatus: state.inventoryLabelPrinting.scanStatus,
    rescanModalState: state.inventoryLabelPrinting.rescanModalState,
    isAutoPrintEnabled: state.inventoryLabelPrinting.isAutoPrintEnabled,
    labelPrintingPDFUrl: state.inventoryLabelPrinting.labelPrintingPDFUrl,
});

const mapDispatchToProps = dispatch => ({
    updateSelectedEntityType: (selectedEntity) => dispatch(updateSelectedEntityType(selectedEntity)),
    fetchEntityLabelPrintingTypes: () => dispatch(fetchEntityLabelPrintingTypes()),
    fetchEntityLabelPrintingData: (entityType, payload, isRescan) => dispatch(fetchEntityLabelPrintingData(entityType, payload, isRescan)),
    updateAutoPrintFlag: (payload) => dispatch(updateAutoPrintFlag(payload)),
    printBulkAWBSticker: (payload) => dispatch(printBulkAWBSticker(payload)),
    fetchAndPrintSingleStickerDispatcher: (entityType, waybillNo) => dispatch(fetchAndPrintSingleStickerDispatcher(entityType, waybillNo)),
    clearCurrentScannedEntityInfo: _ => dispatch(clearCurrentScannedEntityInfo()),
    toggleLoader: (payload) => dispatch(toggleLoader(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LabelPrinting);