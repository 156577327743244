import RTOServices from "../../../services/rto/rto.service";
import { ACTION_TYPES } from "./constants";
import activeAwbIcon0 from "../../../assets/img/icons/images/3_1_Inventory.svg";
import activeAwbIcon1 from "../../../assets/img/icons/images/3_2_Inventory.svg";
import activeAwbIcon2 from "../../../assets/img/icons/images/3_3_Inventory.svg";
import MiscService from "../../../services/misc.service";
import { checkConsignments } from "../../../common/utils/utils";
import { fetchRTODrses } from "./services";

const rtoServices = new RTOServices();
export const getDrsListData = (payload, isReset = false) => dispatch => {

    dispatch(fetchDrsListBegin());
        fetchRTODrses(payload).
        then(response => {
            dispatch(fetchDrsListSuccess(response));
        }).
        catch(response => {
            dispatch(fetchDrsListFailure(response));
        })
};

const fetchDrsListBegin = () => {
    return {
        type: ACTION_TYPES.FETCH_DRS_LIST_BEGIN
    }
};

const fetchDrsListSuccess = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_DRS_LIST_SUCCESS,
        payload
    }
};

const fetchDrsListFailure = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_DRS_LIST_FAILURE,
        payload
    }
};

export const getSparkLineDataFunction = () => dispatch => {
    dispatch(fetchSparkLineDataBegin());
    
    dispatch(fetchSparkLineDataSuccess([{
        icon: activeAwbIcon0,
        title: "Open DRS",
        embedDashboard : "rto-waybill"
    }]));
    return;
};

const fetchSparkLineDataBegin = () => {
return {
    type: ACTION_TYPES.FETCH_SPARK_LINE_DATA__BEGIN
}
};

const fetchSparkLineDataSuccess = (payload) => {
return {
    type: ACTION_TYPES.FETCH_SPARK_LINE_DATA__SUCCESS,
    payload
}
};

const fetchSparkLineDataFailure = (payload) => {
return {
    type: ACTION_TYPES.FETCH_SPARK_LINE_DATA__FAILURE,
    payload
}
};
