import * as CONSTANTS from './constants';
import _ from 'lodash';
import { removeDuplicateLocations } from '../../../common/utils/utils';


export const assetsReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSTANTS.GET_ASSETS_LIST_BEGIN: return getAssetsListBegin(state, action);

        case CONSTANTS.GET_ASSETS_LIST_SUCCESS: return getAssetsListSuccess(state, action);

        case CONSTANTS.GET_ASSETS_LIST_FAILURE: return getAssetsListFailure(state, action);

        case CONSTANTS.SET_ASSET_CREATE_BEGIN: return setAssetCreateBegin(state, action);

        case CONSTANTS.SET_ASSET_CREATE_SUCCESS: return setAssetCreateSuccess(state, action);

        case CONSTANTS.SET_ASSET_CREATE_FAILURE: return setAssetCreateFailure(state, action);


        case CONSTANTS.GET_ASSET_VENDOR_DATA_BEGIN: return getVendorsListBegin(state, action);
        case CONSTANTS.GET_ASSET_VENDOR_DATA_SUCCESS: return getVendorsListSuccess(state, action);
        case CONSTANTS.GET_ASSET_VENDOR_DATA_FAILURE: return getVendorsListFailure(state, action);


        case CONSTANTS.GET_ASSETS_LOCATIONS_LIST_BEGIN: return getLocationsListBegin(state, action);
        case CONSTANTS.GET_ASSETS_LOCATIONS_LIST_SUCCESS: return getLocationsListSuccess(state, action);
        case CONSTANTS.GET_ASSETS_LOCATIONS_LIST_FAILURE: return getLocationsListFailure(state, action);


        case CONSTANTS.GET_ASSETS_LIST_LOCATIONS_LIST_BEGIN: return getAssetLocationsListBegin(state, action);
        case CONSTANTS.GET_ASSETS_LIST_LOCATIONS_LIST_SUCCESS: return getAssetLocationsListSuccess(state, action);
        case CONSTANTS.GET_ASSETS_LIST_LOCATIONS_LIST_FAILURE: return getAssetLocationsListFailure(state, action);


        case CONSTANTS.GET_ASSETS_USERS_LIST_BEGIN: return getUsersListBegin(state, action);
        case CONSTANTS.GET_ASSETS_USERS_LIST_SUCCESS: return getUsersListSuccess(state, action);
        case CONSTANTS.GET_ASSETS_USERS_LIST_FAILURE: return getUsersListFailure(state, action);


        case CONSTANTS.GET_ASSET_DATA_BEGIN: return getAssetsBegin(state, action);
        case CONSTANTS.GET_ASSET_DATA_SUCCESS: return getAssetsSuccess(state, action);
        case CONSTANTS.GET_ASSET_DATA_FAILURE: return getAssetsFailure(state, action);


        case CONSTANTS.SET_ASSET_UPDATE_BEGIN: return setAssetUpdateBegin(state, action);

        case CONSTANTS.SET_ASSET_UPDATE_SUCCESS: return setAssetUpdateSuccess(state, action);

        case CONSTANTS.SET_ASSET_UPDATE_FAILURE: return setAssetUpdateFailure(state, action);


        case CONSTANTS.SET_ASSET_LOCATION_UPDATE_BEGIN: return setAssetLocationUpdateBegin(state, action);

        case CONSTANTS.SET_ASSET_LOCATION_UPDATE_SUCCESS: return setAssetLocationUpdateSuccess(state, action);

        case CONSTANTS.SET_ASSET_LOCATION_UPDATE_FAILURE: return setAssetLocationUpdateFailure(state, action);


        case CONSTANTS.SET_RESET_FIELDS: return resetFields(state);
        case CONSTANTS.SET_LOCATION_RESET: return setLocationReset(state);
        // case CONSTANTS.SET_ASSET_CREATE_BEGIN: return setAssetCreateBegin(state, action);

        // case CONSTANTS.SET_ASSET_CREATE_SUCCESS: return setAssetCreateSuccess(state, action);

        // case CONSTANTS.SET_ASSET_CREATE_FAILURE: return setAssetCreateFailure(state, action);


        // case CONSTANTS.GET_PARTNER_LOCATION_FETCH_BEGIN: return getLocationFetchBegin(state, action);

        // case CONSTANTS.GET_PARTNER_LOCATION_FETCH_SUCCESS: return getLocationFetchSuccess(state, action);

        // case CONSTANTS.GET_PARTNER_LOCATION_FETCH_FAILURE: return getLocationFetchFailure(state, action);


        // case CONSTANTS.GET_CUSTOMER_LOCATION_FETCH_BEGIN: return getCustomerFetchBegin(state, action);

        // case CONSTANTS.GET_CUSTOMER_LOCATION_FETCH_SUCCESS: return getCustomerFetchSuccess(state, action);

        // case CONSTANTS.GET_CUSTOMER_LOCATION_FETCH_FAILURE: return getCustomerFetchFailure(state, action);

        // case CONSTANTS.GET_CITIES_FETCH_BEGIN: return getCitiesFetchBegin(state, action);

        // case CONSTANTS.GET_CITIES_FETCH_SUCCESS: return getCitiesFetchSuccess(state, action);

        // case CONSTANTS.GET_CITIES_FETCH_FAILURE: return getCitiesFetchFailure(state, action);


        // case CONSTANTS.GET_PINCODES_FETCH_BEGIN: return getPincodesFetchBegin(state, action);

        // case CONSTANTS.GET_PINCODES_FETCH_SUCCESS: return getPincodesFetchSuccess(state, action);

        // case CONSTANTS.GET_PINCODES_FETCH_FAILURE: return getPincodesFetchFailure(state, action);



        // case CONSTANTS.SET_VENDOR_CREATE_BEGIN: return setVendorCreateBegin(state, action);

        // case CONSTANTS.SET_VENDOR_CREATE_SUCCESS: return setVendorCreateSuccess(state, action);

        // case CONSTANTS.SET_VENDOR_CREATE_FAILURE: return setVendorCreateFailure(state, action);



        // case CONSTANTS.SET_VENDOR_UPDATE_BEGIN: return setVendorUpdateBegin(state, action);

        // case CONSTANTS.SET_VENDOR_UPDATE_SUCCESS: return setVendorUpdateSuccess(state, action);

        // case CONSTANTS.SET_VENDOR_UPDATE_FAILURE: return setVendorUpdateFailure(state, action);


        // case CONSTANTS.GET_VENDORS_LIST_BEGIN: return getVendorsListBegin(state, action);

        // case CONSTANTS.GET_VENDORS_LIST_SUCCESS: return getVendorsListSuccess(state, action);

        // case CONSTANTS.GET_VENDORS_LIST_FAILURE: return getVendorsListFailure(state, action);


        // case CONSTANTS.GET_VENDOR_DATA_BEGIN: return getVendorBegin(state, action);

        // case CONSTANTS.GET_VENDOR_DATA_SUCCESS: return getVendorSuccess(state, action);

        // case CONSTANTS.GET_VENDOR_DATA_FAILURE: return getVendorFailure(state, action);



        default: return state
    }
}

function getAssetsListBegin(state, action) {
    return {
        ...state,
        assetslistloading: true,
    }
}

function getAssetsListSuccess(state, action) {
    return {
        ...state,
        assetslistloading: false,
        assetsList: action.payload
    }
}

function getAssetsListFailure(state, action) {
    return {
        ...state,
        assetslistloading: false
    }
}

function setAssetCreateBegin(state, action) {
    return {
        ...state,
        assetCreateLoading: true,
    }
}
function setAssetCreateSuccess(state, action) {
    return {
        ...state,
        assetCreateLoading: false,
        assetDetails: action.payload.assets
    }
}
function setAssetCreateFailure(state, action) {
    return {
        ...state,
        assetCreateLoading: false,
        assetCreateError: action.payload
    }
}

function setAssetUpdateBegin(state, action) {
    return {
        ...state,
        assetUpdateLoading: true,
    }
}
function setAssetUpdateSuccess(state, action) {
    return {
        ...state,
        assetUpdateLoading: false,
        updateAssetDetails: action.payload.assets
    }
}
function setAssetUpdateFailure(state, action) {
    return {
        ...state,
        assetUpdateLoading: false
    }
}


function getVendorsListBegin(state, action) {
    return {
        ...state,
        vendorslistloading: true
    }
}

function getVendorsListSuccess(state, action) {

    return {
        ...state,
        vendorslistloading: false,
        vendorsList: action.payload.vendors
    }
}

function getVendorsListFailure(state, action) {
    return {
        ...state,
        vendorslistloading: false
    }
}

function getLocationsListBegin(state, action) {
    return {
        ...state,
        locationslistloading: true,
    }
}

function getLocationsListSuccess(state, action) {
    return {
        ...state,
        locationslistloading: false,
        locationsList: removeDuplicateLocations(action.payload.locations, { nameWithRole: 'name' })
    }
}

function getLocationsListFailure(state, action) {
    return {
        ...state,
        locationslistloading: false
    }
}


function getAssetLocationsListBegin(state, action) {
    return {
        ...state,
        assetLocationslistloading: true,
    }
}

function getAssetLocationsListSuccess(state, action) {
    return {
        ...state,
        assetLocationslistloading: false,
        assetLocationsList: action.payload.locations
        // assetLocationsList: removeDuplicateLocations(action.payload.locations, { nameWithRole: 'name' })
    }
}

function getAssetLocationsListFailure(state, action) {
    return {
        ...state,
        assetLocationslistloading: false
    }
}

function getUsersListBegin(state, action) {
    return {
        ...state,
        userslistloading: true,
    }
}

function getUsersListSuccess(state, action) {
    let newUsers = [], assignedNewUsers = [];
    const assetAssignedUsers = action.payload.assetAssignedUsers;
    const usersList = action.payload.users;
    if (assetAssignedUsers) {
        assignedNewUsers = _.map(assetAssignedUsers, user => user.id);
    }

    if (assignedNewUsers) {
        newUsers = usersList.map(user => {
            if (assignedNewUsers.includes(user.id)) {
                user.assigned = true;
            } else {
                user.assigned = false;
            }            
            return user;
        })
    } else {
        newUsers = usersList.map(user => {
            user.assigned = false;
            return user;
        })
    }
   
    newUsers = _.sortBy(newUsers, (user) => {
        return user.assigned;
    })

    return {
        ...state,
        userslistloading: false,
        usersList: action.payload.users,
        assetAssignedUsers: action.payload.assetAssignedUsers,
        newUsers: newUsers,

    }
}

function getUsersListFailure(state, action) {
    return {
        ...state,
        userslistloading: false
    }
}

function getAssetsBegin(state, action) {
    return {
        ...state,
        assetsDataLoading: true,
    }
}

function getAssetsSuccess(state, action) {
    return {
        ...state,
        assetsDataLoading: false,
        assetsData: action.payload.assets,
        identityDocumentBOS: action.payload.identityDocumentBOS
    }
}

function getAssetsFailure(state, action) {
    return {
        ...state,
        assetsDataLoading: false
    }
}



function setAssetLocationUpdateBegin(state, action) {
    return {
        ...state,
        assetLocationUpdateLoading: true,
    }
}
function setAssetLocationUpdateSuccess(state, action) {
    return {
        ...state,
        assetLocationUpdateLoading: false,
        assetLocation: action.payload
    }
}
function setAssetLocationUpdateFailure(state, action) {
    return {
        ...state,
        assetLocationUpdateLoading: false
    }
}

function resetFields(state) {
    return {};
}

function setLocationReset(state) {
    return {
        ...state,
        assetLocationUpdateLoading: false,
        assetLocation: undefined,
        assetsList: undefined
    }
}





// function getLocationFetchBegin(state, action) {
//   return {
//     ...state,
//     partnerLocationLoading: true,
//   }
// }
// function getLocationFetchSuccess(state, action) {
//   return {
//     ...state,
//     partnerLocationLoading: false,
//     partnerLocationDetails: removeDuplicateLocations(action.payload.locations, { nameWithRole: 'name' })
//   }
// }
// function getLocationFetchFailure(state, action) {
//   return {
//     ...state,
//     partnerLocationLoading: false
//   }
// }



// function getCustomerFetchBegin(state, action) {
//   return {
//     ...state,
//     customerLocationLoading: true,
//   }
// }
// function getCustomerFetchSuccess(state, action) {
//   return {
//     ...state,
//     customerLocationLoading: false,
//     customerLocationDetails: action.payload
//   }
// }
// function getCustomerFetchFailure(state, action) {
//   return {
//     ...state,
//     customerLocationLoading: false
//   }
// }

// function getCitiesFetchBegin(state, action) {
//   return {
//     ...state,
//     cityloading: true
//   }
// }

// function getCitiesFetchSuccess(state, action) { 
//   return {
//     ...state,
//     cityloading: false,
//     cities: action.payload.citiesDetails
//   }
// }

// function getCitiesFetchFailure(state, action) {
//   return {
//     ...state,
//     cityloading: false
//   }
// }


// function getPincodesFetchBegin(state, action) {
//   return {
//     ...state,
//     pincodeloading: true
//   }
// }

// function getPincodesFetchSuccess(state, action) {  

//   return {
//     ...state,
//     pincodeloading: false,
//     allPincodes: action.payload.groupedByCityId,
//     pincodeDetails: action.payload.pincodedetails,
//   }
// }

// function getPincodesFetchFailure(state, action) {
//   return {
//     ...state,
//     pincodeloading: false
//   }
// }

// function setVendorCreateBegin(state, action) {
//   return {
//     ...state,
//     vendorCreateLoading: true,
//   }
// }
// function setVendorCreateSuccess(state, action) {
//   return {
//     ...state,
//     vendorCreateLoading: false,
//     vendorDetails: action.payload.vendors
//   }
// }
// function setVendorCreateFailure(state, action) {
//   return {
//     ...state,
//     vendorCreateLoading: false
//   }
// }


// function getVendorsListBegin(state, action) {
//   return {
//     ...state,
//     vendorslistloading: true
//   }
// }

// function getVendorsListSuccess(state, action) {  

//   return {
//     ...state,
//     vendorslistloading: false,
//     vendorsList: action.payload.vendors
//   }
// }

// function getVendorsListFailure(state, action) {
//   return {
//     ...state,
//     vendorslistloading: false
//   }
// }


// function getVendorBegin(state, action) {
//   return {
//     ...state,
//     vendorDataLoading: true
//   }
// }

// function getVendorSuccess(state, action) {    
//   return {
//     ...state,
//     vendorDataLoading: false,
//     vendorData: action.payload
//   }
// }

// function getVendorFailure(state, action) {
//   return {
//     ...state,
//     vendorDataLoading: false
//   }
// }

// function setVendorUpdateBegin(state, action) {
//   return {
//     ...state,
//     vendorUpdateLoading: true
//   }
// }

// function setVendorUpdateSuccess(state, action) {    
//   return {
//     ...state,
//     vendorUpdateLoading: false,
//     vendorUpdateData: action.payload
//   }
// }

// function setVendorUpdateFailure(state, action) {
//   return {
//     ...state,
//     vendorUpdateLoading: false
//   }
// }