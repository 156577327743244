import { initialState, FETCH_MONITOR_REPORTS_BEGIN, FETCH_MONITOR_REPORTS_SUCCESS, FETCH_MONITOR_REPORTS_FAILURE } from "./constants";

export const MonitoringServiceReducer = ( state = initialState, action ) => {
    switch (action.type) {
        case FETCH_MONITOR_REPORTS_BEGIN:
            return fetchMonitoringServiceBegin(state);
        case FETCH_MONITOR_REPORTS_SUCCESS:
            return fetchMonitoringServiceSuccess(state, action);
        case FETCH_MONITOR_REPORTS_FAILURE:
            return fetchMonitoringServiceFailure(state, action);
        default:
            return state;
    }
};

const fetchMonitoringServiceBegin = ( state ) => {
    return {
        ...state,
        loading: true
    }
};

const fetchMonitoringServiceSuccess = ( state, action ) => {
    return {
        ...state,
        loading: false,
        embedUrl: action.payload
    }
}

const fetchMonitoringServiceFailure = ( state, action ) => {
    return {
        ...state,
        loading: false,
        error: action.payload
    }
}