import React, { useEffect, useState } from 'react';
import { Table, Icon } from "antd";
import { drsSummaryColumns, drsSummaryExpandedColumns } from "./common/columns";
import "./index.scss";
import { useSelector } from 'react-redux';


function DRSTable(props) {
    const { data, setFilters } = props;

    const [paginationProps, setPaginationProps] = useState({})
    const config = useSelector((state) => state?.app?.configurations)

    useEffect(() => {

        const pagProps = true
            ? {}
            : {
                onChange: (pageNo, pageSize) => { setFilters({ pageNo, pageSize }) },
                pageSize: data?.currentPageSize,
                current: data?.currentPageNo,
                total: data?.totalCount,
                showTotal: (total, range) => <span className="total-pagination">{total}</span>,
                showSizeChanger: true,
                onShowSizeChange: (current, pageSize) => setFilters({ pageNo: current, pageSize }),
            }

        setPaginationProps(pagProps)

    }, [])

    const customExpandIcon = props => {

        if (props.expanded) {
            return <a style={{ color: 'black' }} onClick={e => {
                props.onExpand(props.record, e);
            }}><Icon type="caret-down" /></a>
        } else {
            return <a style={{ color: 'black' }} onClick={e => {
                props.onExpand(props.record, e);
            }}><Icon type="caret-right" /></a>
        }
    }

    const expandableTable = record => (
        <Table
            columns={drsSummaryExpandedColumns()}
            dataSource={record.consignments}
            pagination={false}
            rowKey={(record, index) => { return index }}
        />
    )

    return (
        <div className="drs-table">
            <Table
                columns={drsSummaryColumns()}
                expandedRowRender={record => expandableTable(record)}
                expandIcon={(props) => customExpandIcon(props)}
                dataSource={data.drs.sellerData}
                pagination={{
                    showSizeChanger: true,
                    ...paginationProps
                }}
                rowKey={(record, index) => { return index }}
            />,
        </div>

    )
}



export default DRSTable;