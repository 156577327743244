import { Button, Card, DatePicker, Empty, Form, Icon, Input, Modal, PageHeader, Radio, Select, Spin, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSummary } from '../../../app/appAction.js';
import { Constants } from '../../../common/utils/constants.js';
import { canAllow } from '../../../common/utils/utils.js';
import { permissionTags } from '../../../configs/permissionTags.js';
import DataCard from '../../shared-components/data-card/DataCard';
import WaybillLink from '../../shared-components/waybill-link/index.js';
import activeAwbIcon0 from './../../../assets/img/icons/images/3_1_Inventory.svg';
import activeAwbIcon1 from './../../../assets/img/icons/images/3_2_Inventory.svg';
import activeAwbIcon2 from './../../../assets/img/icons/images/3_3_Inventory.svg';
import { fetchConsigmentUnassigned } from './actions.js';
import './awb.scss';
import ForwardWaybillList from '../../metabase-dashboards/inventory/ForwardWaybillList.js';

class Awb extends Component {
  constructor(props) {
    super(props);
    const dataCardIcon = { activeAwbIcon0, activeAwbIcon1, activeAwbIcon2 };
    this.state = {
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
          deviceFilter: { deviceType: "DESKTOP", view: "LITE_UNASSIGNED_WAYBILLS_LIST" },
          isArrived: true,
          isBookedOnly: true
        }
      },
      awbPermission: {
        drs: canAllow(permissionTags.inventory.drs.create),
        manifest: canAllow(permissionTags.inventory.manifest.create),
      },
      dataCardIcon: dataCardIcon,
      DataCardList: [
        {
          title: 'Pending AWB',
          documentType: "PENDING_CONSIGNMENT_LOC",
          embedDashboard: "inventory-waybill",
          icon: dataCardIcon[`activeAwbIcon${0}`]
        }
      ],
      summaryLoader: false,
      columns: [],
      searchValue: '',
      pageNo: 1,
      showFilterModal: false
    }
    this.InputSearchRef = React.createRef();
    // this.props.getSummary('INVENTORY_AWB');
  }

  componentDidMount() {
    const columns = [
      {
        title: 'Waybill No',
        dataIndex: 'waybillNo',
        key: 'waybillNo',
        width: '20%',
        render: (data, row) => {
          return (
            <>
              <WaybillLink>{data ? data : row.consignmentBO?.waybillNo}</WaybillLink> &nbsp;
              {
                row.consignmentBO?.returnConsignmentBos?.length > 0 ?
                  <Tag color="#108ee9">Return ({row.consignmentBO?.returnConsignmentBos?.length}) </Tag> :
                  row.consignmentBO?.undelConsignmentBos?.length > 0 ?
                    <Tag color="#108ee9">Undel ({row.consignmentBO?.undelConsignmentBos?.length}) </Tag> :
                    <></>
              }
            </>
          )
        }
      },
      // {
      //   title: 'Attempts',
      //   dataIndex: 'consignmentBO',
      //   key: 'Attempts',
      //   render: (data, row) => (row.consignmentBO?.attempts ? row.consignmentBO?.attempts : 'NA')
      // },
      {
        title: 'Destination',
        dataIndex: 'consignmentBO',
        key: 'Destination',
        render: data => (`${data?.consignee?.address?.pincode?.city?.cityCode || "NA "}-${data?.consignee?.address?.pincode?.zipcode || " NA"}`)
      },
      {
        title: 'Type',
        dataIndex: 'consignmentBO',
        key: 'Type',
        render: data => (data !== null ? 'Line Haul' : 'NA')
      },
      {
        title: 'Payment',
        dataIndex: 'consignmentBO.payableAmount',
        key: 'Payment',
        render: data => data ? Constants.b2cPaymentTypes[1].name : Constants.b2cPaymentTypes[0].name
      },
      // {
      //   title: 'CPD',
      //   dataIndex: 'consignmentBO',
      //   key: 'CPD',
      //   render: (data, row) => row?.consignmentBO?.expectedDeliveryDate ? <div>{moment(row.consignmentBO?.expectedDeliveryDate).format('DD/MM/YYYY h:mm a')} <br /> <small>{moment(row.consignmentBO?.expectedDeliveryDate).fromNow()}</small></div> : <div>NA</div>
      // },
      {
        title: 'Booking Date',
        dataIndex: 'consignmentBO.bookingDate',
        key: 'Booking_Date',
        render: data => data ? <div>{moment(data).format('DD/MM/YYYY h:mm a')} <br /> <small>{moment(data).fromNow()}</small></div> : <div>NA</div>
      },
      {
        title: 'Status',
        dataIndex: 'consignmentBO.consignmentStatus',
        key: 'Status',
        render: data => data ? <Tag color="#FFB64D"> {data}</Tag> : 'NA'
      }
    ];
    this.setState({
      columns: [...columns]
    })

    this.applyPagination({ pageNo: 1 }, true);
  }

  componentDidUpdate(prevProps) {
    const { summary } = this.props;
    const { dataCardIcon } = this.state;
    if (summary !== prevProps.summary) {
      const DataCardList = [
        {
          title: 'Pending AWB',
          documentType: "PENDING_CONSIGNMENT_LOC",
          description: summary['PENDING_CONSIGNMENT_LOC']?.count,
          icon: dataCardIcon[`activeAwbIcon${0}`]
        }
      ]
      this.setState({
        DataCardList,
        summaryLoader: false
      })
    }
  }

  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }
    if (filters?.waybills) {
      if (filters.waybills[0] === "")
        delete filters.waybills;
    }
  }

  reloadAwbList = this.cancelFilterModal;

  getPaginatedData = (pageNo, paginatedData) => {
    const { PaginatedConsigment, } = this.props;
    if (!PaginatedConsigment ? !PaginatedConsigment : !PaginatedConsigment[pageNo]) {
      this.applyPagination({ pageNo })
    }
    this.setState({
      pageNo
    });
  }

  disable = current => current && current > moment().endOf('second');

  cancelFilterModal = () => {
    const { filterPayload } = this.state;
    const { filters } = filterPayload;
    if (filters?.movementTypes) {
      delete filters?.movementTypes;
    }
    this.InputSearchRef.current.input.state.value = '';

    if (filters?.destinationLocationIds) {
      delete filters?.destinationLocationIds;
    }

    if (filters?.locationIds) {
      delete filters?.locationIds;
    }

    if (filters?.bookingDate) {
      delete filters?.bookingDate;
    }


    this.setState({
      showFilterModal: false,
      bookingDate: undefined,
      bookingType: undefined,
      pickupLoc: undefined,
      destinationLoc: undefined,
      filterPayload: {
        ...filterPayload,
        filters: {
          ...filters
        }
      }
    }, this.applyPagination({}, true))
  }

  applyFilter = () => {
    const filter = {};
    const { filterPayload } = this.state;
    const { filters } = filterPayload;
    if (this.state.bookingType) {
      if (this.state.bookingType !== 'ALL')
        filter['movementTypes'] = [`${this.state.bookingType}`]
      if (this.state.bookingType === 'ALL')
        filter['movementTypes'] = [];
    }
    if (this.state.destinationLoc) {
      filter['destinationLocationIds'] = [`${this.state.destinationLoc}`]
    }
    if (this.state.pickupLoc) {
      filter['locationIds'] = [`${this.state.pickupLoc}`]
    }
    if (this.state.bookingDate) {
      filter['bookingDate'] = this.state.bookingDate
    }

    this.setState({
      filterPayload: {
        ...filterPayload,
        filters: {
          ...filters,
          ...filter
        }
      },
      showFilterModal: false
    }, _ => this.applyPagination(filter, true));
  }

  render() {
    const { DataCardList, awbPermission, columns, pageNo, awbList, searchValue, summaryLoader } = this.state
    const { awbData, PaginatedConsigment } = this.props

    const entityActionButtons = (
      <div className="flex-1 flex-column flex-gap-l align-items-center justify-content-center">
        {
          awbPermission.drs &&
          <Button icon="plus" style={{ maxWidth: 240 }} className="lsn-btn-primary" disabled={this.props.auditStatus?.isAuditOn} type="primary"
            size="large" block={true}
            onClick={() => this.props.history.push('/appv2/inventory/form/drs')} >
            Create DRS
          </Button>
        }
        {
          awbPermission.manifest &&
          <Button icon="plus" style={{ maxWidth: 240 }} className="lsn-btn-primary" disabled={this.props.auditStatus?.isAuditOn} type="primary"
            onClick={() => this.props.history.push('/appv2/inventory/form/manifest-partner')} size="large" block={true}>
            Create Manifest
          </Button>
        }
      </div>
    )

    return (
      <div className="flex-column flex-gap-l">

        <ForwardWaybillList entityActionButtons={entityActionButtons} />

      </div>
    )
  }
}

const mapStateToProps = state => ({
  awbData: state.inventoryAwb,
  PaginatedConsigment: state.inventoryAwb.PaginatedConsigment,
  summary: state.app.summary,
  config: state.app.configurations,
  header: state?.header,
  locations: state.header.locationHeaderDetails,
  auditStatus: state.audit?.auditStatus
});
const mapDispatchToProps = dispatch => ({
  getSummary: page => dispatch(getSummary(page)),
  fetchConsigmentUnassigned: (payload, isReset) => dispatch(fetchConsigmentUnassigned(payload, isReset))
});
export default connect(mapStateToProps, mapDispatchToProps)(Awb);