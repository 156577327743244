import React, { Component } from "react";
import { table as Table } from "../ui/table/Table";
import { connect } from "react-redux";
import * as actions from "./action";
import { input as Input } from "../../components/ui/input/input";
import { Icon, Card, Divider } from "antd";
import { button as Button } from "../../components/ui/button/Button";
import { formatDate, formatTime } from "../../common/utils/utils";
import { Constants } from "../../common/utils/constants";
import "./Suspicious.scss";
import TrackingPopupModal from "../shared-components/tracking-popup-modal";

class Suspicious extends Component {
  state = {
    showModal: false,
    searchText: "",
    selectedStreamId: "",
    selectedWaybill: "",
    selectedReasonId: "",
    columns: [
      {
        title: "WAYBILL NO.",
        dataIndex: "waybillNo",
        render: (text, row) => {
          return (
            <a
              href="javascript:;"
              className="selected-waybill"
              onClick={this.showModal.bind(
                this,
                row.id,
                text,
                row.reasonId,
                row.status,
                row.shouldResolve
              )}
            >
              {text}
            </a>
          );
        }
      },
      {
        title: "F.E NAME",
        className: "greyed-out",
        dataIndex: "feName"
      },
      {
        title: "Tentative Sync Time",
        dataIndex: "tentativeSyncTime",
        render: text => (
          <div>
            {formatDate(text)} at {formatTime(text)}
          </div>
        )
      },
      {
        title: "UNDEL. Reason",
        className: "greyed-out",
        dataIndex: "reason"
      },
      {
        title: "Alert Type",
        dataIndex: "explanation"
      },
      {
        title: "Action",
        dataIndex: "action",
        render: (waybillNo, row) => {
          if (row.status === "RESOLVED_INVALID") {
            return (
              <Button className="suspicious-action disabled">
                Marked as Invalid
              </Button>
            );
          }
          if (row.status === "RESOLVED_VALID") {
            return (
              <Button className="suspicious-action disabled">
                Marked as Valid
              </Button>
            );
          }
          if (!row.shouldResolve) {
            return (
              <Button className="suspicious-action disabled">Synced</Button>
            );
          }
          return (
            <Button
              className="suspicious-action"
              onClick={this.showModal.bind(
                this,
                row.id,
                row.waybillNo,
                row.reasonId,
                row.status
              )}
            >
              Resolve
            </Button>
          );
        }
      }
    ]
  };
  componentDidMount() {
    this.props.getSuspiciousWaybills();
    Constants.SUSPICIOUS_CLASSNAME = this.constructor.name;
  }
  showModal = (streamId, waybillNo, reasonId, status, shouldResolve) => {
    this.setState({
      showModal: true,
      selectedStreamId: streamId,
      selectedWaybill: waybillNo,
      selectedReasonId: reasonId,
      selectedStatus: status,
      shouldResolve: shouldResolve
    });
  };
  hideModal = () => {
    this.setState({
      showModal: false
    });
    this.props.getSuspiciousWaybills();
  };
  onChangeSearchText = e => {
    this.setState({
      searchText: e.target.value
    });
    this.props.searchSuspiciousWaybills(e.target.value);
  };
  onPageChange = (page, pageSize) => {
    const params = {
      pageNo: page,
      pageSize: pageSize
    };
    this.props.getSuspiciousWaybills(params);
  };
  getRowclassName(record) {
    const greyedStatuses = ["RESOLVED_INVALID", "RESOLVED_VALID"];
    const className = ["table-row"];
    if (greyedStatuses.indexOf(record.status) > -1 || !record.shouldResolve) {
      className.push("grey-row");
    }
    return className.join(" ");
  }
  render() {
    const { searchText } = this.state;
    return (
      <Card className="Suspicious">
        <div className="suspicious-header">
          <div className="suspicious-heading">Suspicious UNDEL'S</div>
          <Input
            className="search-bar"
            placeholder="Search"
            suffix={<Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />}
            value={searchText}
            onChange={this.onChangeSearchText}
          />
        </div>
        <Divider />
        <Table
          pagination={{
            total: this.props?.suspiciousWaybills?.totalCount,
            onChange: this.onPageChange
          }}
          columns={this.state.columns}
          dataSource={this.props?.suspiciousWaybills?.responses}
          bordered={true}
          rowClassName={this.getRowclassName}
        />
        <TrackingPopupModal onCancel={this.hideModal}
          visible={this.state.showModal} input={this.state.selectedWaybill}
          status={this.state.selectedStatus} shouldResolve={this.state.shouldResolve}
          reasonId={this.state.selectedReasonId}
          waybillNo={this.state.selectedWaybill} source={Constants.SUSPICIOUS_CLASSNAME} />
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  suspiciousWaybills: state.suspicious.suspiciousWaybills,
  loading: state.suspicious.loading
});
const mapDispatchToProps = dispatch => ({
  getSuspiciousWaybills: param =>
    dispatch(actions.getSuspiciousWaybills(param)),
  searchSuspiciousWaybills: params =>
    dispatch(actions.searchSuspiciousWaybills(params))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Suspicious);
