import { apiRequest } from "../../../common/utils/apiGateway";
import { checkHttpStatus } from "../../../common/utils/utils";
import store from "../../../utils/store";
import { ACTION_TYPES } from "./reasons.reducer";

const reasonsReducer = store.getState()?.reasonsReducer;
const dispatch =  store.dispatch;

export const scanManifests = manifestCode => callApiRequest("/b2c/v1/manifest/scan",  { scanInputType : "MOBILE_SCANNER", manifestCode })

export const unloadManifest = manifestCode => callApiRequest("/b2c/v1/manifest/unload", { manifestCode })

export const validateManifest = manifestCode => callApiRequest("/b2c/v1/manifest/validate", { manifestCode })

export const closeManifest = manifestCode => callApiRequest("/b2c/v1/manifest/update_new", { manifestCode, status : "COMPLETED" })

export const scanConsignment = (manifestCode, waybillNo, advancedResponse) => new Promise((resolve, reject) => {
    const postObject =  { 
        scanInputType : "MOBILE_SCANNER", 
        manifestCode, 
        waybillNo, 
        advancedResponse
    }
    callApiRequest("/b2c/v1/manifest/consignment/scan/v2", postObject, true, false)
    .then(resolve)
    .catch(errorObject => {
        if (errorObject.customErrorCode === "SCANERROR001") {
            callApiRequest("/b2c/v1/manifest/consignment/scan",  { scanInputType : "MOBILE_SCANNER", manifestCode, waybillNo })
            .then(response => {
                var resolvedResponse = {
                    scanStatus   : true,
                    manifestCode : response?.manifest?.code,
                    waybillNo    : response?.consignment?.waybillNo,
                    nextLocation : response?.consignment?.nextLocation,
                    destinationPincode : response?.consignment?.destinationPincode,
                    customerId         : response?.consignment?.customerId,
                    consignmentStatus  : response?.consignment?.status,
                    consignmentServiceable : false,
                    alreadyScanned     : response?.alreadyScanned
                }
                resolve(resolvedResponse);
            })
            .catch(e => reject(e))
        }
        else {
            reject(errorObject);
        }
    })
})

export const callApiRequest = (apiUrl, postObject, ignoreStatus, showMessage) => new Promise((resolve, reject) => {
    if (!postObject || !postObject.manifestCode) {
        reject("Manifest Code cannot be empty!")
    }
    else {
        apiRequest({
            url     : apiUrl,
            method  : "POST",
            data    : postObject,
            ignoreStatus,
            showMessage
        })
        .then(response => {
            if (checkHttpStatus(response?.data?.status)) {
                resolve(response?.data?.response);
            }
            else {
                reject(response?.data?.status);
            }
        })
        .catch(reject)
    }
})

export const fetchConsginmentsByManifestCodeAndStatus = (manifestCode, status) => callApiRequest("/b2c/v1/manifest/consignment/fetch", { manifestCode, status })


export const fetchConsignmentsReasons = _ => {

    if (!reasonsReducer.consignmentsReasons) {
        apiRequest({
            url    : "b2c/v1/consignment/reason",
            method : "GET"
        })
        .then(response => {
            if (checkHttpStatus(response?.data?.status)) {
                dispatch({
                    type    : ACTION_TYPES.CACHE_CONSIGNMENT_REASONS,
                    payload : response?.data?.response
                })
            }
        });
    }
}

export const updatePendingReason = (manifestCode, waybillNo, reasonId) => callApiRequest("/b2c/v1/consignment/update/reason", { manifestCode, waybillNo, reasonId })