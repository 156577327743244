import React, { useState } from "react";
import {
    Button,
    Card,
    Form,
    Input,
    Icon,
    Checkbox,
} from "antd";

import "./login-card.styles.scss";

const CSS_PREFIX = "login-card";

const LoginCardForm = ({ form, callLogin }) => {
    const { getFieldDecorator, setFieldsValue, getFieldsValue } = form;

    const handleChange = event => {
        const filedName = event.target.name;
        const fieldValue = event.target.value;
        setFieldsValue({
            [filedName]: fieldValue
        });
    };

    const [ loading, SetLoading ] = useState(false);

    const handleChangeRememberMe = (event) => {
        setFieldsValue({
            remember: event.target.checked
        });
    };

    const handleSubmit = (e, data) => {
        e.preventDefault();
        SetLoading(true);
        const { username, password, remember } = getFieldsValue(["username", "password", "remember"]);
        callLogin({ username, password, remember }, SetLoading)
    };

    const { username, password, remember } = getFieldsValue(["username", "password", "remember"]);

    return(
        <div className="login-card-container">
            <Card
                title={<span className="login-card-title"> Sign In </span>}
                className="login-card-card-container"
            >
                <div className="login-card-body">
                    <Form  className="login-card-form" onSubmit={handleSubmit}>
                        <Form.Item>
                            {getFieldDecorator("username", {
                                rules: [{ required: true, message: "User Name can't be blank" }],
                            })(
                                <Input
                                prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                                placeholder="User Name"
                                name="username"
                                onChange={handleChange}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("password", {
                                rules: [{ required: true, message: "Password can't be blank" }],
                            })(
                                <Input
                                prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                                type="password"
                                placeholder="Password"
                                name="password"
                                onChange={handleChange}
                                />,
                            )}
                        </Form.Item>

                        <Form.Item>
                            <Form.Item>
                                {getFieldDecorator("remember", {
                                    valuePropName: "checked",
                                    initialValue: true,
                                })(
                                    <Checkbox checked={remember} onChange={handleChangeRememberMe}>
                                        Remember me for next 30 days in this browser
                                    </Checkbox>
                                )}
                            </Form.Item>
                            <Button  loading={loading} type="primary" 
                                htmlType="submit"  className="login-card-button"
                                disabled={!username || !password }>
                                Sign In
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Card>
        </div>
    )
};

const LoginCard = Form.create({name: "legacy-login"})(LoginCardForm)

export default LoginCard;