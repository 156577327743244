import React, { useEffect, useRef, useState } from "react";
import {
   Card,
   Button,
   Divider
} from "antd";
import FormWrapper from "../../ui/formComponent/form"
import { UPLOAD_BUTTON_TYPE } from "../../../common/utils/constants";
import { useSelector } from "react-redux";


const PODForm = ({
   submitPOD,
   formData,
   rtoDrsPod,
   goBack,
   creating,
   setFormData
}) => {

   const { relations } = JSON.parse(window.atob(localStorage.getItem("_configrations")));
   var relationCodes = [];
   var relationNames = [];
   relations.forEach(relation => {
      relationCodes.push(relation.relationCode)
      relationNames.push(relation.relations)
   });

   const appConfig = useSelector(state => state?.app?.configurations)
   const user = JSON.parse(atob(localStorage.user))

   const { reasons, allow_otp_confirmation_reason_codes } = JSON.parse(window.atob(localStorage.getItem("_configrations")));
   var reasonDescriptions = [];
   var reasonId = [];
   if (rtoDrsPod) {
      reasons?.RTO_UNDELIVERED?.forEach(reason => {
         if (!allow_otp_confirmation_reason_codes || allow_otp_confirmation_reason_codes.indexOf(reason.reasonCode) < 0) {
            reasonDescriptions.push(reason.reasonDescription);
            reasonId.push(reason.reasonId);
         }
      });
   }
   else {
      reasons?.UNDELIVERED?.forEach(reason => {
         if (!allow_otp_confirmation_reason_codes || allow_otp_confirmation_reason_codes.indexOf(reason.reasonCode) < 0) {
            reasonDescriptions.push(reason.reasonDescription);
            reasonId.push(reason.reasonId);
         }
      });
   }

   var formRef = useRef();

   const schema1 = {
      "consigneeName": {
         "type": "string",
         "title": "Name",
         "default": ""
      },
      "shipmentCount": {
         "type": "number",
         "title": "Shipment Count"
      },
      "phone": {
         "type": "number",
         "title": "Phone"
      },
      "date": {
         "type": "string",
         "title": "Date",
         "format": "Date"
      },
      "email": {
         "type": "string",
         "title": "Email",
      },
      "locId": {
         "type": "string",
         "title": "Location"
      },
   }

   const delievered = {
      "required": [
         "receivedBy",
         "relationContact",
         "podImpLink",
         "idProofType",
         "paymentType",
         "relationShipStatus"
      ],
      "properties": {
         "receivedBy": {
            "type": "string",
            "title": "Received By"
         },
         "relationShipStatus": {
            "type": "string",
            "title": "Relation with consingee",
            "enum": relationCodes,
            "enumNames": relationNames

         },
         "relationContact": {
            "type": "string",
            "title": "Phone Number"
         },
         ...(!rtoDrsPod && {
            "awbCount": {
               "type": "number",
               "title": "Waybill Count"
            }
         }),
         "podImpLink": {
            "type": "string",
            "title": "POD Image",
            "path": "consignment/",
            "objKey": "consignment/",
            "directUpload": true,
            "uploadButtontype": UPLOAD_BUTTON_TYPE.BUTTON,
            "disableUploadButton": true
         },
         ...(!rtoDrsPod && {
            "idProofType": {
               "type": "string",
               "title": "Consignee ID Proof Type",
               "enum": [
                  "pancard",
                  "dl",
                  "adhar"
               ],
               "enumNames": [
                  "PanCard",
                  "Driving License",
                  "Aadhar"
               ]
            }
         }),
         ...(!rtoDrsPod && {
            "idProofNo": {
               "type": "string",
               "title": "Consginee ID Proof No."
            }
         }),
         ...(!rtoDrsPod && {
            "paymentType": {
               "type": "string",
               "title": "Payment",
               "enum": [
                  "cash",
                  "UPI"
               ],
               "enumNames": [
                  "Cash",
                  "UPI"
               ]
            }
         }),
         ...(!rtoDrsPod && {
            "codAmount": {
               "type": "number",
               "title": "COD Amount",
            }
         }),
         "remarks": {
            "type": "string",
            "title": "Remarks"
         }
      }
   }

   const undelivered = {
      "required": ["reason"],
      "properties": {
         "reason": {
            "type": "string",
            "title": "Reason",
            "enum": reasonId,
            "enumNames": reasonDescriptions
         },
         "remarks": {
            "type": "string",
            "title": "Remarks"
         }
      }
   }

   const uiSchema = {
      "classNames": "multi-row-field-objects",
      "status": {
         "classNames": "single-field-multi-row-form",
         "ui:widget": "radio"
      },
      "consigneeName": {
         "ui:placeholder": "Enter Consignee Name",
         "ui:disabled": true
      },
      "shipmentCount": {
         "ui:placeholder": "Enter Shipment Count",
         "ui:disabled": true
      },
      "phone": {
         "ui:placeholder": "Enter Consignee Phone Number",
         "ui:disabled": true
      },
      "date": {
         "ui:placeholder": "Enter Date",
         "ui:disabled": true
      },
      "email": {
         "ui:placeholder": "Enter Consignee Email ID",
         "ui:disabled": true
      },
      "locId": {
         "ui:placeholder": "Enter Location",
         "ui:disabled": true
      },
      "receivedBy": {
         "ui:placeholder": "Enter Receiver Name"
      },
      "relationShipStatus": {
         "ui:placeholder": "Select Relation Status"

      },
      "relationContact": {
         "ui:placeholder": "Enter Receiver Contact",
         "type": "number",
         "maxLen": 10
      },
      "awbCount": {
         "ui:placeholder": "Waybill Count",
         "ui:disabled": true
      },
      "idProofType": {
         "ui:placeholder": "Select ID Proof"
      },
      "idProofNo": {
         "ui:placeholder": "Enter ID Proof Number"
      },
      "paymentType": {
         "ui:placeholder": "Select Payment Type"
      },
      "podImpLink": {
         "ui:widget": "file",
         "acceptTypes": [".jpg", ".jpeg", ".png", ".svg", ".gif"],
         "doNotAllowMultiple": true
      },
      "codAmount": {
         "ui:placeholder": "Enter Cash Amount",
         "ui:disabled": true
      },
      "reason": {
         "ui:placeholder": "Select Reason",

      },
      "remarks": {
         "ui:placeholder": "Enter Remarks"
      }
   }

   const errorList = {
      "receivedBy": {
         "required": "Receiver Name can't be blank"
      },
      "relationShipStatus": {
         "required": "Relation status can't be empty"
      },
      "relationContact": {
         "required": "Please enter valid number"
      },
      "podImpLink": {
         "required": "Not uploaded"
      },
      "idProofType": {
         "required": "Proof Type can't be blank"
      },
      "idProofNo": {
         "required": "ID Proof Number can't be blank"
      },
      "paymentType": {
         "required": "Payment can't be blank"
      },
      "codAmount": {
         "required": "Invalid Amount"
      },
   }

   const [orgSchema, setOrgSchema] = useState({});

   useEffect(() => {

      const orgSchema = {
         "type": "object",
         "properties": {
            "status": {
               "type": "string",
               "title": "Status",
               "enum": ["DEL", "UNDEL"],
               "enumNames": ["Delivered", "Undelivered"]
            }
         },
         "dependencies": {
            "status": {
               "oneOf": [
                  {
                     "properties": {
                        "status": {
                           "enum": ["DEL"]
                        },
                        ...(!rtoDrsPod && schema1),
                        ...delievered.properties
                     },
                     "required": delievered.required
                  },

                  {
                     "properties": {
                        "status": {
                           "enum": ["UNDEL"]
                        },
                        ...(!rtoDrsPod && schema1),
                        ...undelivered.properties
                     },
                     "required": undelivered.required
                  }
               ]
            }
         }
      }

      if (!rtoDrsPod && user?.location?.id && appConfig?.unblockERPDeliverOptionLocationId) {
         if (!appConfig?.unblockERPDeliverOptionLocationId?.includes(String(user.location.id)) && !appConfig?.unblockERPDeliverOptionLocationId?.includes("0")){
            const enumIndex = orgSchema.properties.status.enum.findIndex(value => value === "DEL")
            const enumNameIndex = orgSchema.properties.status.enumNames.findIndex(value => value === "Delivered")
            const propertyIndex = orgSchema?.dependencies?.status?.oneOf?.findIndex(obj => obj?.properties?.status?.enum[0] === "DEL");
   
            if (propertyIndex !== -1 && orgSchema?.dependencies?.status?.oneOf) {
               orgSchema.dependencies.status.oneOf.splice(propertyIndex, 1);
            }
   
            if (enumIndex !== -1 && orgSchema?.properties?.status?.enum) {
               orgSchema.properties.status.enum.splice(enumIndex, 1)
            }
   
            if (enumNameIndex !== -1 && orgSchema?.properties?.status?.enumNames) {
               orgSchema.properties.status.enumNames.splice(enumNameIndex, 1)
            }
         }
      }

      if (rtoDrsPod && orgSchema?.dependencies?.status?.oneOf?.length > 0) {
         var tempReq = orgSchema.dependencies.status.oneOf[0].required.filter(r => ["idProofType", "paymentType"].indexOf(r) < 0)
         orgSchema.dependencies.status.oneOf[0].required = tempReq;
      }

      setOrgSchema(orgSchema)

   }, [appConfig])

   const onSubmit = (data) => {
      if (data.status === "UNDEL") {
         if (rtoDrsPod) {
            reasons?.RTO_UNDELIVERED?.map(r => {
               if (r.reasonId === data.reason) {
                  data.reason = r.reasonDescription;
                  data.reasonId = r.reasonId
               }
            })
         }
         else {
            reasons?.UNDELIVERED?.map(r => {
               if (r.reasonId === data.reason) {
                  data.reason = r.reasonDescription;
                  data.reasonId = r.reasonId
               }
            })
         }
      }
      submitPOD(data);
   }

   const transformErrors = (errors) => {
      errors = errors.map(error => {
         if (error.name === "type" || error.name === "enum") {
            error.message = undefined;
         }
         return error;
      });
      return errors;

   }

   return (
      <>

         {
            formData &&
            <Card>
               <FormWrapper
                  schema={orgSchema} uiSchema={uiSchema}
                  errorList={errorList} formData={formData}
                  fieldsPerRow={2}
                  hideSubmit={true} ref={formRef}
                  onSubmit={onSubmit}
                  transformErrors={transformErrors}
                  onChange={data => {
                     if (data.status != formData.status) {
                        if (data.status === "UNDEL") {
                           [
                              "receivedBy",
                              "relationContact",
                              "podImpLink",
                              "idProofType",
                              "paymentType",
                              "relationShipStatus"
                           ].map(key => delete data[key]);
                        }
                        else if (data.status === "DEL") {
                           delete data.reason;
                        }
                     }
                     setFormData(data);
                  }}
               />
               <Divider />
               <div className="flex-box flex-gap-m justify-content-flex-end">
                  <Button disabled={creating}
                     onClick={goBack}> Cancel </Button>
                  <Button loading={creating} onClick={_ => {
                     formRef.current.formReference.current.submit()
                  }} type="primary"> Create POD </Button>
               </div>
            </Card>

         }
      </>
   )
};

export default PODForm;