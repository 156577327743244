import React from "react";

const lastScanConnection = ({ lastScannedConnection }) => {
    return (
        <div className="flex-column flex-gap-m">
            <div className="text-bold"> Last Scanned Connection details </div>
            <div className="flex-box flex-gap-xl spacer-m grey-bg border-radius-m">
                {
                    lastScannedConnection ?
                    <>
                        <div className="flex-column flex-gap-l">
                            <div> Connection ID </div>
                            <div className="text-bold"> {lastScannedConnection?.connectionCode} </div>
                        </div>
                        <div className="flex-column flex-gap-l">
                            <div> No. of manifests </div>
                            <div className="text-bold"> {lastScannedConnection?.manifestCount} </div>
                        </div>
                        <div className="flex-column flex-gap-l">
                            <div> No. of items </div>
                            <div className="text-bold"> {lastScannedConnection?.awbCount} </div>
                        </div>
                        <div className="flex-column flex-gap-l">
                            <div> Destination </div>
                            <div className="text-bold"> {lastScannedConnection?.destinationLocation} </div>
                        </div>
                        <div className="flex-column flex-gap-l">
                            <div> Status </div>
                            <div className="text-bold"> {lastScannedConnection?.connectionStatus} </div>
                        </div>
                    </> :
                    <div className="spacer-l flex-box align-items-center justify-content-center flex-1 light-grey-text">
                        Last scanned Connection Sheet details will appear here.
                    </div>
                }
            </div>
        </div>
    )
}

export default lastScanConnection;