import { Button } from "antd";
import { useEffect, useState } from "react";

export default function ManifestScanHeader({ manifestDetails, buttonLoading, callUnloadManifest, callValidateManifest, callCloseManifest }) {

    const [ enableManifestClose, SetEnableManifestClose ] = useState();

    useEffect(() => {
        SetEnableManifestClose(manifestDetails?.validation?.status === true);
    }, [ manifestDetails ])

    return (
        <div className="flex-column flex-gap-xs spacer-m" style={{ backgroundColor : "#dfe3f8" }}>
            <div className="flex-box justify-content-space-between align-items-center">
                <div>
                    <div className="font-size-l-2 text-bold" style={{color: "#000"}}>
                        {manifestDetails?.manifest?.code}
                    </div>
                    <div className="text-semi-bold" style={{color: "#2233A5"}}>
                        Number of shipments - {manifestDetails?.pendingCount + manifestDetails?.scannedCount}
                    </div>
                </div>
                {
                    manifestDetails?.manifest?.status == "ARRIVED" && manifestDetails?.manifest?.scannedAtDestination &&
                    <Button size="large" onClick={_ => callUnloadManifest(manifestDetails?.manifest?.code)}
                     className="lsn-btn-primary" loading={buttonLoading} >
                        Unload
                    </Button>
                }
                {
                    manifestDetails?.manifest?.status == "UNLOADING" ?
                        enableManifestClose ?
                        <Button size="large" onClick={_ => callCloseManifest(manifestDetails?.manifest?.code)}
                         className="lsn-btn-primary" loading={buttonLoading} >
                            Close
                        </Button> :
                        <Button size="large" onClick={_ => callValidateManifest(manifestDetails?.manifest?.code)}
                         className="lsn-btn-primary" loading={buttonLoading} >
                            Validate
                        </Button>
                    : <></>
                }
            </div>
        </div>
    )

}