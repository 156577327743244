import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Icon,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tag
} from "antd";
import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Constants,
  locationOwners,
  validationRules
} from "../../../common/utils/constants";
import {
  canAllow,
  checkHttpStatus,
  disableFutureDates,
  notifyApiError,
  notifyApiSuccess
} from "../../../common/utils/utils";
import { permissionTags } from "../../../configs/permissionTags";
import UserService from "../../../services/settings/user.service";
import * as assetActions from "../asset/action";
import {
  createRole,
  getRole,
  getRoles,
  resetRoles,
  updateRole
} from "../role/actions";
import RoleForm from "../role/roleForm";
import * as actions from "./actions";
import "./form.scss";
import moment from "moment";


const userService = new UserService();

const { confirm } = Modal;

class UserForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAssetModalVisible: false,
      assetParams: {},
      assetCreate: {},
      driverName: undefined,
      selectedVehicle: undefined,
      vehicleNumber: undefined,
      vehicleTypes: [],
      newAsset: false,
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
          userTypes: ["USER"],
          fetchObjects: [
            "PARTNERS",
            "LOCATIONS",
            "ADDRESSES",
            "PINCODES",
            "CITIES"
          ]
        }
      },
      userPermission: {
        read: canAllow(permissionTags.settings.user.read),
        update: canAllow(permissionTags.settings.user.update),
        create: canAllow(permissionTags.settings.user.create),
        delete: canAllow(permissionTags.settings.user.delete),
        view: canAllow(permissionTags.settings.user.view)
      },
      locationOwnerList: locationOwners.filter(loc => loc.value !== "VENDOR"),
      userId: props.match.params.id,
      isEdit: !(props.match.params.id > 0),
      selectedRoles: [],
      role: null,
      roleModalVisible: false,
      isSubmitDisabled: false,
      doNotCall: false,

      showErrors: false,
      errors: [],
      setAsset: false
    };
    if (!this.state.userPermission.read) {
      window.location.replace("/appv2/settings/dashboard/");
    }
  }

  componentDidMount() {
    this.props.getRoles();
    this.getLocations();
    this.fetchReferrerTypes();
    if (!this.state.isEdit) {
      this.props.getUser(this.state.userId);
    }
  }

  componentWillUnmount() {
    this.props.clearSelectedUserData();
    this.props.resetUser();
    this.props.resetFields()
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_.isEmpty(this.props.user) && this.props.user !== prevProps.user) {
      if (this.state.newAsset) {
        if (this.props.form.getFieldValue("asset")) {
          let { assetCreate } = this.state;
          let { asset } = assetCreate;
          if (!this.state.isEdit) {
            assetCreate = {
              ...assetCreate,
              asset: {
                ...asset,
                assignedUserId: this.props.user?.id
              }
            };
          } else {
            assetCreate = {
              ...assetCreate,
              asset: {
                ...asset,
                assignedUserId: this.props.user?.users[0].id
              }
            };
          }

          this.props.createAsset(assetCreate);
        }
        // this.setState({ isAssetModalVisible: true, driverName: this.props.form.getFieldValue('name') });
      } else {
        if (this.props.form.getFieldValue("asset")?.key) {
          if (!this.state.newAsset) {
            // Call Asset update API
            let assetKey = this.props.form.getFieldValue("asset")["key"];
            let asset = this.props.assetsList?.assets?.filter(
              a => a.id === assetKey
            )[0];
            const {
              id,
              driverName,
              vehicleNumber,
              contactPersonMobile,
              contactPersonName,
              vehicleType,
              capacity,
              registrationNumber,
              ownerName,
              vendorId,
              capacityUnit
            } = asset;
            let params = {};
            if (!this.state.isEdit) {
              params = {
                asset: {
                  id,
                  driverName,
                  vehicleNumber,
                  contactPersonName,
                  contactPersonMobile,
                  vehicleType,
                  capacity,
                  registrationNumber,
                  ownerName,
                  vendorId,
                  capacityUnit,
                  assignedUserId: this.props.user?.id,
                  originLocationId: this.props.user?.locationId
                }
              };
            } else {
              params = {
                asset: {
                  id,
                  driverName,
                  vehicleNumber,
                  contactPersonName,
                  contactPersonMobile,
                  vehicleType,
                  capacity,
                  registrationNumber,
                  ownerName,
                  vendorId,
                  capacityUnit,
                  assignedUserId: this.props.user?.users[0].id,
                  originLocationId: this.props.user?.users[0].locationId
                }
              };
            }

            this.props.updateAsset(params.asset.id, params);
          }
        } else {
          if (
            this.props.form.getFieldValue("assetType") !==
            this.state?.selectedAsset?.vehicleType
          ) {
            const assetPayload = {
              asset: {
                id: !this.state.isEdit ? this.props.user?.assetId : undefined,
                ownerName: Constants.sessionUser.name,
                vendorId: null,
                driverName: this.props.form.getFieldValue("name"),
                // vehicleNumber:
                //   this.props.form.getFieldValue("assetType") !== "Bicycle"
                //     ? this.state.vehicleNumber
                //     : `BICYCLE-${getFieldValue("name")}-${getFieldValue(
                //         "contactNumber"
                //       ).substring(6, 10)}`,
                contactPersonMobile: this.props.form.getFieldValue(
                  "contactNumber"
                ),
                contactPersonName: this.props.form.getFieldValue("name"),
                originLocationId: this.props.form.getFieldValue("locationId"),
                vehicleType: this.props.form.getFieldValue("assetType"),
                capacity:
                  this.props.form.getFieldValue("assetType") !== "Bicycle"
                    ? "20.0"
                    : "1",
                capacityUnit: "Ton",
                assignedUserId:
                  this.props.user?.users?.[0].id || this.props.user?.id,
                isDummy: true
              }
            };
            if (!this.state.isEdit) {
              this.props.updateAsset(this.props.user?.assetId, assetPayload);
            } else {
              this.props.createAsset(assetPayload);
            }
          }
        }
      }
      this.setState({ showErrors: false, errors: [], isSubmitDisabled: false });
      this.props.fetchUsers(this.state.filterPayload);
      if (this.props.user && Object.keys(this?.props?.user || {}).length)
        this.props.history.push("/appv2/settings/dashboard/user");
    } else {
      if (this.state.isSubmitDisabled) {
        this.setState({ showErrors: false, errors: [], isSubmitDisabled: false });
      }
    }
    if (this.props.roleId !== prevProps.roleId) {
      if (this.props.roleId) this.props.getRole(this.props.roleId);
    }
    if (
      this.props.selectedRole?.permissions?.length !==
      prevProps.selectedRole?.permissions?.length
    ) {
      const keyLength = Object.keys(this.props.selectedRole).length;
      if (keyLength === 0) return 0;
      let selectedNewRoles = [];
      const { selectedRoles } = this.state;
      if (
        selectedRoles.filter(role => role.id === this.props.selectedRole.id)
          .length > 0
      ) {
        selectedNewRoles = selectedRoles.map(role => {
          if (role.id === this.props.selectedRole.id) {
            return { ...this.props.selectedRole, status: true };
          }
          return role;
        });
      } else {
        this.setState(
          {
            role: { ...this.props.selectedRole }
          },
          () => {
            this.addRole(false);
          }
        );
        selectedNewRoles = [...selectedRoles];
      }
      this.setState(
        {
          selectedRoles: selectedNewRoles,
          selectedRole: { ...this.props.selectedRole }
        },
        () => {
          if (
            selectedRoles.filter(role => role.id === this.props.selectedRole.id)
              .length > 0
          ) {
            this.closeRoleMangeModal();
            this.closeRoleModal();
          } else {
            this.closeRoleModal();
            this.openRoleMangeModal({ ...this.props.selectedRole }, true);
          }
        }
      );
    }

    if (this.props.selectedUser !== prevProps.selectedUser) {
      const { selectedUser, form } = this.props;
      this.setState(
        {
          selectedUser
        },
        () => {
          let filters = {
            filters: {
              originLocationIds: [selectedUser?.locationId],

              fetchObjects: ["ASSET_LOCATION"],
              partnerIds: [Constants.PARTNER_ID]
            }
          };
          this.props.getAssets(filters);
        }
      );
      form.setFieldsValue(
        {
          name: selectedUser?.name,
          contactNumber: selectedUser?.contactNumber,
          secondaryNumber: selectedUser?.secondaryNumber,
          email: selectedUser?.email,
          secondaryEmail: selectedUser?.secondaryEmail,
          locationOwner: selectedUser?.location?.entityType,
          locationId: selectedUser?.locationId,
          username: selectedUser?.username,
          userLevel: selectedUser?.userLevel,
          status: selectedUser?.status,
          accessExportData: selectedUser?.accessExportData,
          password: selectedUser?.password,
          confirmPassword: selectedUser?.password,
          referrerType: selectedUser?.referrerType,
          referrerId: selectedUser?.referrerId
        },
        () => {
          selectedUser?.referrerType &&
            this.fetchReferrers(selectedUser?.referrerType);
          form.setFieldsValue({
            referrerType: selectedUser?.referrerType,
            referrerId: selectedUser?.referrerId
          });
        }
      );
    }

    if (this.props?.roles?.length > 0 && !_.isEmpty(this.state.selectedUser)) {
      this.setState(
        {
          roles: this.props.roles
        },
        () => {
          if (!this.state.doNotCall) {
            const that = this;
            this.setState(
              {
                doNotCall: true
              },
              () => {
                const { selectedUser, roles } = this.props;
                if (selectedUser) {
                  selectedUser?.rolesBOs?.forEach(roleBo => {
                    if (roles && roles.length > 0) {
                      roles.forEach(roleObj => {
                        if (roleObj.id === roleBo.id) {
                          this.setState(
                            {
                              role: roleObj
                            },
                            () => {
                              that.addRole(false);
                            }
                          );
                        }
                      });
                    }
                  });
                }
              }
            );
          }
        }
      );
    }

    if (
      Object.keys(this.state?.selectedUser || {})?.length &&
      this.props.assetsList &&
      !this.state.isEdit &&
      _.isUndefined(this.props.form.getFieldValue("asset")) &&
      !this.state.setAsset
    ) {
      const { selectedUser } = this.state;
      let asset = this.props.assetsList.assets.filter(val => {
        return val.id === selectedUser?.assetId;
      })[0];

      this.props.form.setFieldsValue(
        {
          asset: { key: asset?.id, label: asset?.vehicleNumber },
          assetType: asset?.vehicleType
        },
        () => {
          this.setState({ setAsset: true, selectedAsset: asset });
        }
      );
    }

    if (
      this.props.locationList !== prevProps.locationList ||
      this.props.selectedUser !== prevProps.selectedUser
    ) {
      this.props?.selectedUser?.locationId &&
        this.setState(
          {
            selectedLocation: this.props?.locationList?.find(
              item => item.id === this.props?.selectedUser?.locationId
            )
          },
          () => {
            this.props.form.setFieldsValue({
              referrerType: this.props.selectedUser?.referrerType,
              referrerId: this.props.selectedUser?.referrerId
            });
          }
        );
    }

  }

  isMangingRolesAllowed = () => {
    return Constants.sessionUser.userLevel === "ADMIN";
  };

  updateSelectedRoleData = (roleId, type, data) => {
    const { selectedRoles } = this.state;
    const newRoles = selectedRoles.map((role, index) => {
      if (role.id === roleId) {
        selectedRoles[index][type] = data;
      }
    });
    this.setState({
      selectedRoles: newRoles
    });
  };

  addRole(canToast = true) {
    const { role, selectedRoles } = this.state;
    const selectedRolesSet = [...selectedRoles];
    if (role) {
      let roleAdded = false;
      selectedRoles.forEach(roleBo => {
        if (roleBo.id === role.id) {
          if (canToast) notifyApiError("Role was already added!!", "Info");
          roleAdded = true;
        }
      });
      if (!roleAdded) {
        if (canToast) {
          notifyApiSuccess("Role was added successfully!!", "Success");
        }
        role["status"] = true;
        selectedRolesSet.push(role);
      }
      this.setState(
        {
          selectedRoles: selectedRolesSet
        },
        () => {
          this.onSelectedRolesChange();
          this.setState({
            role: null
          });
          this.props.form.setFieldsValue({
            selectedRole: undefined
          });
        }
      );
    } else {
      notifyApiError("Please select role", "Info");
    }
  }

  onSelectedRolesChange(actionRole = null) {
    const { selectedRoles } = this.state;
    const selectedRolesSet = [...selectedRoles];
    if (actionRole && "index" in actionRole) {
      selectedRolesSet[actionRole["index"]].status = !selectedRolesSet[
        actionRole["index"]
      ].status;

      this.setState({
        selectedRoles: [...selectedRolesSet]
      });
    }
    let permissionsCount = 0;
    const locationId = this.props.form.getFieldValue("locationId");
    let locationRoleMapping = {};
    this.activateOnlyOneRole(actionRole);
    selectedRolesSet.forEach((role, index) => {
      if (role.status) {
        const permissionCount = role.permissions.length;
        permissionsCount += permissionCount;
        if (permissionCount) {
          locationRoleMapping[role.id] = [{ id: locationId }];
        }
      }
    });
    locationRoleMapping = permissionsCount === 0 ? null : locationRoleMapping;
    this.props.form.setFieldsValue({
      rolesToLocationsMap: locationRoleMapping
    });
  }

  activateOnlyOneRole(actionRole = null) {
    const { selectedRoles } = this.state;
    const roleLength = selectedRoles.length;
    if (roleLength) {
      selectedRoles.forEach((role, roleIndex) => {
        role.status = true;
        if (actionRole == null && roleIndex === roleLength - 1) {
          role.status = true;
        } else if (actionRole != null && actionRole.id === role.id) {
          role.status = true;
        } else {
          role.status = false;
        }
      });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const _this = this;
    this.props.form.validateFieldsAndScroll((err, values) => {
      const user = { ...values };
      if (!err) {
        let payload = _this.props.selectedUser
          ? { ..._this.props.selectedUser }
          : {};
        this.setState({
          isSubmitDisabled: true
        });
        payload = {
          ...payload,
          ...{
            name: user.name,
            contactNumber: user.contactNumber,
            secondaryNumber: user.secondaryNumber,
            email: user.email,
            secondaryEmail: user.secondaryEmail,
            locationId: user.locationId,
            username: user.contactNumber,
            userLevel: user.userLevel,
            // 'status': user.status,
            accessExportData: user.accessExportData,
            rolesToLocationsMap: user.rolesToLocationsMap,
            referrerType: user.referrerType,
            referrerId: user.referrerId
          }
        };
        let userId = 0;

        if (this.props.form.getFieldValue("userLevel") === "FIELD_EXECUTIVE") {
          // this.setState({ isAssetModalVisible: true })
        }

        if (!_this.state.isEdit) {
          const params = {
            id: payload.id,
            code: payload.code,
            name: payload.name,
            contactNumber: payload.contactNumber,
            email: payload.email,
            secondaryContactNumber: payload.secondaryNumber,
            secondaryEmail: payload.secondaryEmail,
            locationId: payload.locationId,
            // "status": payload.status,
            userLevel: payload.userLevel,
            accessExportData: payload.accessExportData,
            entityType: payload.entityType,
            entityId: payload.entityId,
            rolesToLocationsMap: payload.rolesToLocationsMap,
            referrerType: payload.referrerType,
            referrerId: payload.referrerId
          };
          if (user.password) params["password"] = user.password;
          _this.props.updateUser(payload.id, params);
        } else {
          payload["dobEpoch"] = user.dob.unix()
          payload["password"] = user.contactNumber;

          _this.props.createUser(payload);
        }
      }
    });
  };

  goBack = () => {
    this.props.fetchUsers(this.state.filterPayload);
    this.props.history.goBack();
  };

  getLocations = e => {
    const { setFieldsValue, getFieldValue } = this.props.form;
    // const value = e?.target?.value ? e.target.value : this.state.locationOwnerList[0].value
    const value = "PARTNER";

    setFieldsValue({
      locationOwner: value,
      locationId: undefined
    });
    const payload = {
      filters: {
        isActive: true,
        status: true,
        isConsiderCreatorId: false,
        deviceFilter: {
          view: "LOCATION_LIST_ERP"
        },
        entityIds: [Constants.PARTNER_ID],
        entityTypes: [value]
      }
    };
    if (typeof e === "string") {
      payload.filters["partialContentSearch"] = e;
    }
    this.props.getLocations(payload);
  };

  validatePassword = (pass, cpass) => {
    return pass === cpass;
  };

  onRoleChanges = val => {
    const { roles } = this.props;

    roles.map(role => {
      if (role.id === val) {
        this.setState({
          role
        });
      }
    });
  };

  openRoleModal = (row, isUpdate = false) => {
    this.props.form.setFieldsValue({
      roleName: row?.name
    });
    this.setState({
      isRoleModalVisble: true,
      isRoleUpdate: isUpdate,
      selectedRole: row
    });
  };

  closeRoleModal = () => {
    this.setState({
      isRoleModalVisble: false,
      isRoleUpdate: false
    });
  };

  openRoleMangeModal = (row, isUpdate = false) => {
    this.setState({
      isRoleMangeModalVisble: true,
      isRoleMangeUpdate: isUpdate,
      selectedRole: row
    });
  };

  closeRoleMangeModal = () => {
    this.setState({
      isRoleMangeModalVisble: false,
      isRoleMangeUpdate: false
    });
  };

  checkForRoleDuplicate = roleData => {
    let returnValue = false;
    const { roles } = this.props;
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].name?.toLowerCase() === roleData.name?.toLowerCase()) {
        if (roleData.status && roles[i].id === roleData.id) {
          continue;
        }
        returnValue = true;
        break;
      }
    }
    return returnValue;
  };

  checkUserLevelVisibility = level => {
    const { getFieldValue } = this.props.form;
    if (
      locationOwners[0].value === getFieldValue("locationOwner") &&
      level === "ADMIN"
    ) {
      return false;
    }
    const locationId = getFieldValue("locationId");
    const selectedLocation = this.props?.locationList?.filter(
      loc => loc.id === locationId
    )[0];
    if (!locationId || !selectedLocation) {
      return true;
    }
    let isAllowed = true;

    const roles = selectedLocation ? selectedLocation.roles : [];
    if (roles && roles.indexOf("HEAD_OFFICE") === -1) {
      if (level !== "BRANCH") {
        isAllowed = false;
      }
    }
    return isAllowed;
  };

  onRoleSubmit = e => {
    const {
      selectedRole,
      isRoleUpdate,
      isRoleMangeUpdate,
      newPermissions
    } = this.state;
    const { getFieldValue } = this.props.form;
    if (!isRoleUpdate && !isRoleMangeUpdate) {
      if (!getFieldValue("roleName")) {
        notifyApiError("Role name cann't be blank", "Validation");
      }
      const roleData = {
        role: {
          name: getFieldValue("roleName"),
          permissions: []
        }
      };
      this.props.createRole(roleData);
    } else {
      if (isRoleUpdate) {
        if (this.checkForRoleDuplicate(selectedRole)) {
          notifyApiError("Role name already existed.", "Validation");
          return false;
        }
      }
      const roleData = {
        role: {
          ...selectedRole
        }
      };

      if (isRoleMangeUpdate) roleData.role["permissions"] = newPermissions;
      if (isRoleUpdate) roleData.role["name"] = getFieldValue("roleName");
      this.props.updateRole(roleData);
    }
  };

  onSelectedPermissionsChange = permissions => {
    this.setState({
      newPermissions: permissions
    });
    this.onSelectedRolesChange();
  };

  handleLocationSearch = val => {
    if (val.length > 3) {
      this.getLocations(val);
    }
  };

  onSelectVehicleType = selectedVehicle => {
    const selected = this.state.vehicleTypes.filter(vehicle => vehicle.id === selectedVehicle?.key)[0];

    this.setState({ selectedVehicle: { key: selected?.vehicleCapacity, label: selected?.vehicleType } });
  };

  changeVehicleNumber = e => {
    this.setState({ vehicleNumber: e.target.value });
  };

  createAsset = () => {
    let { errors, showErrors, selectedVehicle } = this.state;
    showErrors = false;
    errors = [];
    if (
      (selectedVehicle?.label !== "Bicycle" &&
        _.isEmpty(this.state.vehicleNumber)) ||
      _.isEmpty(selectedVehicle)
    ) {
      showErrors = true;
      errors.push("All fields are mandatory");
    }
    let vehicleTest = new RegExp(validationRules.vehicleNumber);
    if (selectedVehicle?.label !== "Bicycle") {
      if (!vehicleTest.test(this.state.vehicleNumber)) {
        showErrors = true;
        errors.push("Vehicle Number Invalid");
      }
    } else {
      let bicycyleTest = new RegExp(validationRules.bicycleNumber);
      if (!bicycyleTest.test(this.state.vehicleNumber)) {
        showErrors = true;
        errors.push("Bicycle Number Invalid! Should be 4 digits");
      }
    }
    this.setState({ showErrors, errors });
    if (showErrors) {
      return;
    } else {
      this.setState({ errors: [] });
    }

    if (this.state.selectedVehicle) {
      const { form } = this.props;
      const { getFieldValue, setFieldsValue } = form;
      let params = {
        asset: {
          ownerName: Constants.sessionUser.name,
          vendorId: null,
          driverName: this.state.driverName,
          vehicleNumber:
            selectedVehicle?.label !== "Bicycle"
              ? this.state.vehicleNumber
              : `BICYCLE-${getFieldValue("name")}-${getFieldValue(
                "contactNumber"
              ).substring(6, 10)}`,
          contactPersonMobile: getFieldValue("contactNumber"),
          contactPersonName: getFieldValue("name"),
          originLocationId: getFieldValue("locationId"),
          vehicleType: selectedVehicle?.label,
          capacity:
            selectedVehicle?.label !== "Biccyle" ? selectedVehicle?.key : "1",
          capacityUnit: "Ton",
          registrationNumber: this.state.vehicleNumber
          // "assignedUserId": users[0]?.id
        }
      };

      setFieldsValue({
        asset: { key: -1, label: params.asset.vehicleNumber }
      });
      this.setState({
        assetCreate: params,
        isAssetModalVisible: false,
        errors: [],
        showErrors: false
      });
    } else {
      return;
    }
  };

  openAssetForm = () => {
    if (this.state.vehicleTypes.length <= 0) {
      userService.getVehiclesTypes().then(res => {
        this.setState(
          { vehicleTypes: res?.data?.response?.vehicleTypes },
          () => {

            this.setState({
              isAssetModalVisible: true,
              driverName: this.props.form.getFieldValue("name"),
              assetCreate: {},
              newAsset: true
            });
          }
        );
      });
    } else {
      this.setState({
        isAssetModalVisible: true,
        driverName: this.props.form.getFieldValue("name"),
        assetCreate: {},
        newAsset: true
      });
    }

  };

  fetchReferrerTypes = () => {
    userService.getReferrerTypes().then(resp => {
      if (checkHttpStatus(resp?.data?.status)) {
        this.setState({
          referrerTypesList: resp?.data?.response?.referrerTypes
        });
      }
    });
  };
  fetchReferrers = type => {
    this.setState({});
    userService.getReferrers(type).then(resp => {
      if (checkHttpStatus(resp?.data?.status)) {
        this.setState({
          referrersList: resp?.data?.response?.[`${type.toLowerCase()}BOS`]
        });
      }
    });
  };

  cancelAssetModal = () => {
    this.setState({
      isAssetModalVisible: false,
      driverName: undefined,
      assetCreate: {},
      newAsset: false,
      selectedVehicle: false,
      vehicleNumber: undefined
    });
  };

  changeLocation = id => {
    this.setState({
      selectedLocation: this.props?.locationList?.find(item => item.id === id)
    });
    let filters = {
      filters: {
        originLocationIds: [id],

        fetchObjects: ["ASSET_LOCATION"],
        partnerIds: [Constants.PARTNER_ID]
      }
    };
    this.props.form.setFieldsValue({ userLevel: undefined });
    this.props.getAssets(filters);
  };

  render() {
    const {
      isEdit,
      locationOwnerList,
      selectedRoles,
      userPermission,
      isSubmitDisabled,
      selectedLocation,
      referrerTypesList,
      referrersList
    } = this.state;
    const {
      form,
      isUserLoading,
      config,
      roles,
      isRoleLoading,
      selectedUser,
      locationloading,
      assetsList
    } = this.props;
    const { getFieldDecorator, getFieldValue, setFields } = form;
    const { userLevel, userRoles } = config;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      labelAlign: "left",
      colon: false
    };

    return (
      <>
        {/* <PageHeader
          onBack={() => this.goBack()}
          title={<h3>{isEdit ? 'Create' : 'Update'} User</h3>}
        /> */}
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Card
            title={
              <h3>
                {" "}
                <Icon
                  onClick={this.goBack}
                  style={{ margin: 15, marginLeft: 0 }}
                  type="arrow-left"
                />{" "}
                {isEdit ? "Create" : "Update"} User
              </h3>
            }
          >
            <Row gutter={16}>
              <Col span={14}>
                <Card bordered={false} className="UserForm">
                  <Spin spinning={isUserLoading}>
                    <Form.Item label="Name">
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            message: "Please input name!"
                          }
                        ]
                      })(
                        <Input
                          placeholder="Enter name"
                          disabled={
                            isEdit
                              ? false
                              : !userPermission.update && userPermission.view
                          }
                        />
                      )}
                    </Form.Item>

                    {/* Displaying DOB field only during creating new user */}
                    {isEdit &&
                      <Form.Item label="Date of Birth (DOB)">
                        {getFieldDecorator("dob", {
                          rules: [
                            {
                              required: true,
                              message: "Please select DOB!"
                            },
                            {
                              validator: async (rule, value) => {
                                if (moment().diff(value, 'years') < 18) {
                                  throw new Error("User must be 18+");
                                }
                              }
                            }
                          ]
                        })(
                          <DatePicker
                            placeholder="Enter Date of birth"
                            disabled={
                              (!userPermission.update && userPermission.view) ?
                                true : false
                            }
                            disabledDate={disableFutureDates}
                            format="DD/MM/YYYY"
                            style={{ width: '100%' }}
                          />
                        )}
                      </Form.Item>
                    }

                    <Col span={16} className="no-padding pr-8">
                      <Form.Item
                        labelCol={{
                          xs: { span: 12 },
                          sm: { span: 8 }
                        }}
                        wrapperCol={{
                          xs: { span: 24 },
                          sm: { span: 12, offset: 4 }
                        }}
                        label="Phone number"
                      >
                        {getFieldDecorator("contactNumber", {
                          rules: [
                            {
                              required: true,
                              message: "Please input mobile number!"
                            },
                            {
                              pattern: validationRules.mobile,
                              max: 10,
                              min: 10,
                              message: "Please enter valid mobile number"
                            }
                          ]
                        })(
                          <Input
                            placeholder="Enter Phone number"
                            disabled={
                              isEdit
                                ? false
                                : !userPermission.update && userPermission.view
                            }
                            maxLength={10}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8} className="no-padding pl-8">
                      <Form.Item
                        wrapperCol={{
                          xs: { span: 24 },
                          sm: { span: 24 }
                        }}
                      >
                        {getFieldDecorator("secondaryNumber", {
                          rules: [
                            {
                              required: false,
                              message: "Please input mobile number"
                            },
                            {
                              pattern: validationRules.mobile,
                              max: 10,
                              min: 10,
                              message: "Please enter valid mobile number"
                            }
                          ]
                        })(
                          <Input
                            placeholder="Secondary number"
                            disabled={
                              isEdit
                                ? false
                                : !userPermission.update && userPermission.view
                            }
                            maxLength={10}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={16} className="no-padding pr-8">
                      <Form.Item
                        labelCol={{
                          xs: { span: 12 },
                          sm: { span: 8 }
                        }}
                        wrapperCol={{
                          xs: { span: 24 },
                          sm: { span: 12, offset: 4 }
                        }}
                        label="Email"
                      >
                        {getFieldDecorator("email", {
                          rules: [
                            {
                              required: true,
                              message: "Please input primary email!"
                            },
                            {
                              pattern: validationRules.email,
                              message: "Please enter valid email"
                            }
                          ]
                        })(
                          <Input
                            placeholder="Enter Email ID"
                            disabled={
                              isEdit
                                ? false
                                : !userPermission.update && userPermission.view
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col span={8} className="no-padding pl-8">
                      <Form.Item
                        wrapperCol={{
                          xs: { span: 24 },
                          sm: { span: 24 }
                        }}
                      >
                        {getFieldDecorator("secondaryEmail", {
                          rules: [
                            {
                              required: false,
                              message: "Please input secondary email"
                            },
                            {
                              pattern: validationRules.email,
                              message: "Please enter valid email"
                            }
                          ]
                        })(
                          <Input
                            placeholder="Secondary Email"
                            disabled={
                              isEdit
                                ? false
                                : !userPermission.update && userPermission.view
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Divider />
                    {/* <Form.Item label="User Name">
                      {getFieldDecorator("username", {
                        rules: [
                          {
                            required: true,
                            message: "User name can't be blank!",
                            disabled: !isEdit
                          }
                        ]
                      })(
                        <Input
                          placeholder="Enter User Name"
                          disabled={!isEdit}
                        />
                      )}
                    </Form.Item> */}
                    {/* <Form.Item label="Password">
                      {getFieldDecorator("password", {
                        rules: [
                          {
                            required: selectedUser?.password !== "",
                            message: "Password can't be blank!"
                          },
                          {
                        //      {
                        //   validator: (rule, val) => selectedUser ?.password !== '' ? this.validatePassword(val, getFieldValue('comfirmPassword')) : selectedUser ?.password === '',
                        //   message: 'Password not matching'
                        // } 
                          }
                        ]
                      })(
                        <Input.Password
                          disabled={
                            isEdit
                              ? false
                              : !userPermission.update && userPermission.view
                          }
                          placeholder="Enter Password"
                          onChange={e => {
                            if (
                              !this.validatePassword(
                                e.target.value,
                                getFieldValue("comfirmPassword")
                              )
                            ) {
                              setFields({
                                comfirmPassword: {
                                  value: getFieldValue("comfirmPassword"),
                                  errors: [new Error("Password not matching")]
                                }
                              });
                            }
                          }}
                        />
                      )}
                    </Form.Item> */}
                    {/* <Form.Item label="Confirm Password">
                      {getFieldDecorator("comfirmPassword", {
                        rules: [
                          {
                            required: selectedUser?.password !== "",
                            message: "Confirm Password can't be blank!"
                          },
                          {
                            required: selectedUser?.password !== "",
                            validator: (rule, val) =>
                              selectedUser?.password !== ""
                                ? this.validatePassword(
                                  getFieldValue("password"),
                                  val
                                )
                                : selectedUser?.password === "",
                            message: "Password not matching"
                          }
                        ]
                      })(
                        <Input.Password
                          disabled={
                            isEdit
                              ? false
                              : !userPermission.update && userPermission.view
                          }
                          placeholder="Enter Confirm Password"
                        />
                      )}
                    </Form.Item> */}
                    {/* <Form.Item label="Location Owner">
                      {getFieldDecorator('locationOwner', {
                        rules: [
                          {
                            required: false,
                            message: "Location Owner can\'t be blank!"
                          }
                        ],
                      })(
                        <Radio.Group onChange={this.getLocations} disabled={(locationloading || (isEdit ? false : (!userPermission.update && userPermission.view)))}>
                          {
                            locationOwnerList && locationOwnerList.map((loc, i) => <Radio key={i} checked={loc.checked} value={loc.value}>{loc.name}</Radio>)
                          }

                        </Radio.Group>
                      )}
                    </Form.Item> */}
                    <Form.Item label="Location">
                      {/* <AutoComplete
                          dataSource={this.props ?.locationList}
                          style={{ width: '100%' }}
                          optionLabelProp={'name'}
                          onSearch={this.handleLocationSearch}
                          placeholder="Select Location"
                        >
                          {this.props ?.locationList ?.map(org => (
                            <AutoComplete.Option
                              key={org.id}
                            >
                              {org.name}
                            </AutoComplete.Option>
                          ))}
                        </AutoComplete> */}
                      {getFieldDecorator("locationId", {
                        rules: [
                          {
                            required: true,
                            message: "Please select Location"
                          }
                        ]
                      })(
                        <Select
                          loading={locationloading}
                          disabled={
                            locationloading ||
                            (isEdit
                              ? false
                              : !userPermission.update && userPermission.view)
                          }
                          placeholder={
                            locationloading ? "Loading..." : "Select Location"
                          }
                          showSearch
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showArrow
                          onChange={this.changeLocation}
                        >
                          {_.isArray(this.props?.locationList) && this.props?.locationList?.map(org => (
                            <Select.Option
                              key={org.id}
                              value={org.id}
                              label={org.name}
                            >
                              {org.name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item label="User Level">
                      {getFieldDecorator("userLevel", {
                        rules: [
                          {
                            required: true,
                            message: "Please select User Level"
                          }
                        ]
                      })(
                        <Select
                          disabled={
                            (isEdit
                              ? false
                              : !userPermission.update &&
                              userPermission.view) ||
                            (!isEdit && selectedUser?.userLevel === "ADMIN")
                          }
                          placeholder="Select User Level"
                          showSearch
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showArrow
                          onChange={this.onPartnerChange}
                        >
                          {userLevel?.map(level =>
                            this.checkUserLevelVisibility(level) ? (
                              <Select.Option
                                key={level}
                                value={level}
                                label={level}
                              >
                                {level.replace("_", " ")}
                              </Select.Option>
                            ) : null
                          )}
                        </Select>
                      )}
                    </Form.Item>
                    {selectedLocation?.createDummyAssetAllowed === "true" ? (
                      <>
                        {getFieldValue("locationId") && (
                          <Form.Item label="Asset Type">
                            {getFieldDecorator("assetType", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please select Asset Type"
                                }
                              ]
                            })(
                              <Radio.Group>
                                <Radio value={"Bicycle"}>Bicycle</Radio>
                                <Radio value={"Motorcycle"}>Motorcycle</Radio>
                              </Radio.Group>
                            )}
                          </Form.Item>
                        )}
                      </>
                    ) : (
                      <>
                        {getFieldValue("locationId") && (
                          <Form.Item label="Asset">
                            {getFieldDecorator("asset", {
                              rules: [
                                {
                                  required: false,
                                  message: "Please select asset"
                                }
                              ]
                            })(
                              <Select
                                disabled={
                                  (isEdit
                                    ? false
                                    : !userPermission.update &&
                                    userPermission.view) ||
                                  (!isEdit &&
                                    selectedUser?.userLevel === "ADMIN")
                                }
                                placeholder="Select Asset"
                                showSearch
                                labelInValue
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                showArrow
                                onChange={this.onPartnerChange}
                                dropdownRender={menu => (
                                  <div>
                                    {menu}
                                    <Divider style={{ margin: "4px 0" }} />
                                    <div
                                      style={{
                                        padding: "4px 8px",
                                        cursor: "pointer"
                                      }}
                                      onMouseDown={e => e.preventDefault()}
                                      onClick={this.addItem}
                                    >
                                      <Button onClick={this.openAssetForm}>
                                        <Icon type="plus" /> Add Asset{" "}
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              >
                                {assetsList?.assets?.map(asset => (
                                  <Select.Option
                                    key={asset.id}
                                    value={asset.id}
                                    label={asset.vehicleNumber}
                                  >
                                    {asset.vehicleNumber}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          </Form.Item>
                        )}
                      </>
                    )}

                    {selectedLocation?.addReferralAllowed === "true" &&
                      getFieldValue("locationId") && (
                        <Form.Item label="Referrer Type">
                          {getFieldDecorator("referrerType", {
                            rules: [
                              {
                                required: true,
                                message: "Please select Referrer Type"
                              }
                            ]
                          })(
                            <Select
                              disabled={
                                isEdit
                                  ? false
                                  : !userPermission.update &&
                                  userPermission.view
                              }
                              placeholder="Select Referrer Type"
                              showSearch
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              showArrow
                              onChange={e => this.fetchReferrers(e)}
                            >
                              {referrerTypesList?.map(level => (
                                <Select.Option
                                  key={level}
                                  value={level}
                                  label={level}
                                >
                                  {level.replace("_", " ")}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      )}
                    {selectedLocation?.addReferralAllowed === "true" &&
                      getFieldValue("locationId") && (
                        <Form.Item label="Referrer">
                          {getFieldDecorator("referrerId", {
                            rules: [
                              {
                                required: true,
                                message: "Please select referrer"
                              }
                            ]
                          })(
                            <Select
                              disabled={
                                isEdit
                                  ? false
                                  : !userPermission.update &&
                                  userPermission.view
                              }
                              placeholder="Select Referrer"
                              showSearch
                              filterOption={(input, option) =>
                                option.props.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              showArrow
                            >
                              {referrersList?.map(item => (
                                <Select.Option
                                  key={item?.id}
                                  value={item?.id}
                                  label={item?.name}
                                >
                                  {item?.name} {getFieldValue("referrerType") === "INFLUENCER" && `(${item.phoneNumber})`}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      )}

                    <Divider />
                    {/* <Form.Item label="Status">
                      {getFieldDecorator('status', {
                        initialValue: true,
                        rules: [
                          {
                            required: false,
                            message: "Please select status"
                          }
                        ],
                      })(
                        <Switch disabled={isEdit ? false : (!userPermission.update && userPermission.view)} checked={getFieldValue('status')} checkedChildren="Active" unCheckedChildren="Inactive" />
                      )}
                    </Form.Item> */}
                    <Form.Item label="Access Export Data">
                      {getFieldDecorator("accessExportData", {
                        initialValue: false,
                        rules: [
                          {
                            required: false,
                            message: "Please select Access Export Data"
                          }
                        ]
                      })(
                        <Switch
                          disabled={
                            isEdit
                              ? false
                              : !userPermission.update && userPermission.view
                          }
                          checked={getFieldValue("accessExportData")}
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                        />
                      )}
                    </Form.Item>

                    <Row gutter={16}>
                      <Col
                        span={4}
                        offset={
                          (isEdit
                            ? false
                            : !userPermission.update && userPermission.view)
                            ? 20
                            : 16
                        }
                      >
                        <Button size="large" onClick={this.goBack}>
                          CANCEL
                        </Button>
                      </Col>
                      {(isEdit ? (
                        true
                      ) : (
                        !(!userPermission.update && userPermission.view)
                      )) ? (
                        <Col span={3}>
                          <Button
                            size="large"
                            disabled={isSubmitDisabled}
                            htmlType="submit"
                            type="primary"
                          >
                            {isEdit ? "CREATE" : "UPDATE"}
                          </Button>
                        </Col>
                      ) : null}
                    </Row>
                  </Spin>
                </Card>
              </Col>
              <Col span={10}>
                <Card
                  bordered={false}
                  className="UserRoles"
                  style={{ height: "100vh" }}
                >
                  <Spin spinning={isRoleLoading}>
                    <Form.Item label="Role">
                      {getFieldDecorator("selectedRole", {
                        rules: [
                          {
                            required: false,
                            message: "Please select Role"
                          }
                        ]
                      })(
                        <Select
                          disabled={
                            isEdit
                              ? false
                              : !userPermission.update && userPermission.view
                          }
                          placeholder="Select Role"
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.props.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                            );
                          }}
                          showArrow
                          onChange={this.onRoleChanges}
                        >
                          {roles &&
                            roles?.map(role => (
                              <Select.Option key={role.id} value={role.id}>
                                {role.name}
                              </Select.Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                    <Row gutter={16}>
                      <Col span={7} offset={18}>
                        <Button
                          disabled={
                            isEdit
                              ? false
                              : !userPermission.update && userPermission.view
                          }
                          size="large"
                          type="primary" className="lsn-btn-primary"
                          onClick={e => this.addRole()}
                        >
                          <Icon type={"plus"} /> Add Role
                        </Button>
                      </Col>
                    </Row>
                    <Divider />
                    <h2>Roles</h2>
                    <Form.Item
                      labelCol={{
                        xs: { span: 24 },
                        sm: { span: 24 }
                      }}
                      wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 26 }
                      }}
                    >
                      {getFieldDecorator("rolesToLocationsMap", {
                        rules: [
                          {
                            required: true,
                            message: "Please select atleast one role"
                          }
                        ]
                      })(
                        <Table
                          locale={{
                            emptyText: (
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                          }}
                          loading={isRoleLoading}
                          dataSource={selectedRoles}
                          columns={[
                            {
                              title: "Name",
                              dataIndex: "name",
                              key: "name",
                              render: (data, row) => (
                                <>
                                  {data}
                                  <Tag
                                    style={{ marginLeft: 5 }}
                                    color="#108ee9"
                                  >
                                    {" "}
                                    <Icon type={"user"} />{" "}
                                    {row?.permissions?.length}
                                  </Tag>
                                </>
                              )
                            },
                            {
                              title: "Status",
                              dataIndex: "status",
                              key: "status",
                              render: (data, row, index) => (
                                <Switch
                                  checked={row?.status}
                                  onChange={isChecked =>
                                    this.onSelectedRolesChange({
                                      ...row,
                                      index
                                    })
                                  }
                                  checkedChildren=""
                                  unCheckedChildren=""
                                />
                              )
                            },
                            {
                              title: "Action",
                              dataIndex: "action",
                              key: "action",
                              render: (data, row, index) => {
                                return (
                                  <div id={`roles-action-${index}`} className="display-none">
                                    <Icon
                                      className="icon-set"
                                      onClick={() =>
                                        this.openRoleMangeModal(row, true)
                                      }
                                      type={"setting"}
                                    />
                                    <Icon
                                      className="icon-set"
                                      onClick={() =>
                                        this.openRoleModal(row, true)
                                      }
                                      type={"edit"}
                                    />
                                  </div>
                                );
                              }
                            }
                          ]}
                        />
                      )}
                    </Form.Item>
                  </Spin>
                </Card>
              </Col>
            </Row>
          </Card>
        </Form>
        <Modal
          title={this.state.isRoleUpdate ? "UPDATE ROLE" : "CREATE ROLE"}
          visible={this.state.isRoleModalVisble}
          onCancel={this.closeRoleModal}
          okText={this.state.isRoleUpdate ? "Update" : "Create"}
          onOk={this.onRoleSubmit}
          okButtonProps={{
            loading: isRoleLoading
          }}
        >
          <Form
            labelCol={formItemLayout.labelCol}
            wrapperCol={formItemLayout.wrapperCol}
          >
            <Form.Item label="Role Name">
              {getFieldDecorator("roleName", {
                rules: [
                  {
                    required: false,
                    message: "Please input role name!"
                  }
                ]
              })(<Input placeholder="Enter role name" />)}
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={this.state.isRoleMangeUpdate ? "UPDATE ROLE" : "CREATE ROLE"}
          visible={this.state.isRoleMangeModalVisble}
          onOk={() =>
            (isEdit
              ? true
              : !(!userPermission.update && userPermission.view))
              ? this.onRoleSubmit()
              : this.closeRoleMangeModal()
          }
          onCancel={this.closeRoleMangeModal}
          okText={this.state.isRoleMangeUpdate ? "Update" : "Create"}
        >
          <RoleForm
            selectedPermission={this.state?.selectedRole?.permissions}
            onSelectedPermissionsChange={this.onSelectedPermissionsChange}
          />
        </Modal>
        <Modal
          title={"Create Asset"}
          visible={this.state.isAssetModalVisible}
          onOk={this.createAsset}
          onCancel={this.cancelAssetModal}
          maskClosable={false}
          okText={"Add"}
        >
          <Form.Item
            style={{ marginBottom: 0 }}
            wrapperCol={{ span: 24 }}
            label={
              <span className="form-label-text">
                Driver Name <sup style={{ color: "red" }}>*</sup>
              </span>
            }
          >
            <Input
              placeholder="Enter Driver Name"
              value={this.state.driverName}
              disabled
            />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: 0 }}
            className="asset-form-item"
            wrapperCol={{ span: 24 }}
            label={
              <span className="form-label-text">
                Vehicle Type <sup style={{ color: "red" }}>*</sup>
              </span>
            }
          >
            <Select
              style={{ width: "100%" }}
              showSearch
              labelInValue
              onSelect={this.onSelectVehicleType}
              value={this.state.selectedVehicle}
              placeholder="Select Vehicle Type"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {/* <Select.Option
                value={"Motorcycle"}>
                Motorcycle
                </Select.Option>
              <Select.Option
                value={"Bicycle"}>
                Bicycle
              </Select.Option> */}
              {this.state?.vehicleTypes?.map(assetsType => {
                return (
                  <Select.Option
                    key={assetsType.vehicleCapacity}
                    value={assetsType.id}
                    label={assetsType.vehicleCapacity}
                  >
                    {assetsType.vehicleType}
                  </Select.Option>
                )
              }
              )}
            </Select>
          </Form.Item>
          {this.state.selectedVehicle !== "Bicycle" && (
            <Form.Item
              style={{ marginBottom: 0 }}
              className="asset-form-item"
              wrapperCol={{ span: 24 }}
              label={<span className="form-label-text">Vehicle Number</span>}
            >
              <Input
                placeholder="Enter Vehicle Number"
                value={this.state.vehicleNumber}
                onChange={this.changeVehicleNumber}
              />
            </Form.Item>
          )}
          {this.state.showErrors && (
            <Row style={{ top: 15, right: 25 }}>
              <Col span={24}>
                <ul>
                  {this.state.errors.map(err => (
                    <li style={{ color: "#ff4d4f" }}>{err}</li>
                  ))}
                </ul>
              </Col>
            </Row>
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isUserLoading: state.settingsUsers.loading,
  locationloading: state.settingsUsers.locationloading,
  selectedUser: state.settingsUsers.selectedUser,
  user: state.settingsUsers.user,
  config: state.app.configurations,
  locationList: state.settingsUsers.locationList,
  roles: state.settingsRoles.roles,
  isRoleLoading: state.settingsRoles.loading,
  selectedRole: state.settingsRoles.selectedRole,
  roleId: state.settingsRoles.roleId,
  assetsList: state.assets?.assetsList,
  appConfig: state?.app?.configurations
});
const mapDispatchToProps = dispatch => ({
  getUser: id => dispatch(actions.getUser(id)),
  createUser: customer => dispatch(actions.createUser(customer)),
  updateUser: (id, customer) => dispatch(actions.updateUser(id, customer)),
  fetchUsers: (param, isReset = true) =>
    dispatch(actions.fetchUsers(param, isReset)),
  getLocations: payload => dispatch(actions.getLocations(payload)),
  getRoles: () => dispatch(getRoles()),
  getRole: id => dispatch(getRole(id)),
  createRole: payload => dispatch(createRole(payload)),
  updateRole: payload => dispatch(updateRole(payload)),
  clearSelectedUserData: () => dispatch(actions.clearSelectedUserData()),
  resetRoles: () => dispatch(resetRoles()),
  resetFields: () => dispatch(assetActions.resetFields()),
  getAssets: filters => dispatch(assetActions.getAssets(filters)),
  updateAsset: (id, params) => dispatch(assetActions.updateAsset(id, params)),
  createAsset: params => dispatch(assetActions.createAsset(params)),
  resetUser: () => dispatch(actions.resetUser())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "User",
    onValuesChange: (props, val) => {
    }
  })(UserForm)
);
