import { Button, DatePicker, Icon, Input, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { generateID } from "../../../common/utils/utils";
import AppliedFilters from "../../common/applied-filters";
import SelectFilters from "../../common/select-filters";
import Radio from "../../ui/radio/radio";

const ReversePickupTableMenu = ({
    assignSuccess,
    setFilterEnabledButton,
    handleFilterChange,
    filterPayload,
    filteredLeadData,
    openAssignFEModal,
    onInputSearch,
    selectedLength,
    downloadLoading,
    downloadAllLeadInfo,
    resetFilters,
    totalWaybillCnt,
    handleSelectAll,
    selectAll,
    selectedWaybillData,
    isFilterApplied,
    dateRange,
    setIsSearch
}) => {

    const attemptCountOptions = [
        {
            value: "0",
            key: 0,
            label: "0"
        }, {
            value: 1,
            key: 1,
            label: "1"
        }, {
            value: 2,
            key: 2,
            label: "2"
        }, {
            value: 3,
            key: 3,
            label: "3"
        }
    ]

    const leadStatusOptions = [
        {
            value: "ALL_STATUS",
            key: "ALL_STATUS",
            label: "All Status"
        },
        {
            value: "OFP",
            key: "OFP",
            label: "Assigned"
        },
        {
            value: "PSUCC",
            key: "PSUCC",
            label: "Picked"
        },
        {
            value: "PCANC",
            key: "PCANC",
            label: "Not Picked"
        },
        {
            value: "UNASSIGNED",
            key: "UNASSIGNED",
            label: "Unassigned"
        }
    ];

    const searchRadioValues = [
        {
            id: generateID(),
            label: "Shipments",
            value: "waybills",
        },
        {
            id: generateID(),
            label: "Packets",
            value: "packets",
        }
    ];

    const [searchInput, setSearchInput] = useState("");
    const [filterOptions, SetFilterOptions] = useState({ createdAt: filterPayload?.createdAt });
    const [selectedStartDate, SetSelectedStartDate] = useState(undefined);
    const [searchInputRadio, setSearchInputRadio] = useState("waybills");

    useEffect(() => {
        if (assignSuccess === true) {
            SetFilterOptions({
                createdAt: {
                    from: moment().subtract(dateRange, "days").startOf("day").valueOf(),
                    to: moment().endOf("day").valueOf()
                }
            });
            setSearchInput("");
            resetFilters();
        }
    }, [assignSuccess])

    let filtersForAssignApplied = (
        selectAll ?
            filterPayload?.statuses?.length === dateRange && filterPayload?.statuses[0] === "PCREATED" :
            selectedWaybillData.every(waybillInfo => waybillInfo.status === "PCREATED" || waybillInfo.status === "PPEND" || waybillInfo.status === "BOOK")
    )

    const reAssignmentStatus = ["OFP", "PCANC"]
    let filtersForReassignApplied = (
        selectAll ?
            filterPayload?.statuses?.length > 0 && reAssignmentStatus.includes(filterPayload?.statuses[0]) :
            selectedWaybillData.every(waybillInfo => reAssignmentStatus.includes(waybillInfo.status))
    )

    let disableFEAssignment = (!selectedLength || (!filtersForAssignApplied && !filtersForReassignApplied))

    let disabledFEAssignmentTitle = !filtersForAssignApplied && !filtersForReassignApplied ?
        <>
            Apply only <strong>'Unassigned'</strong> (or) <strong>'Assigned'/'Not Picked'</strong> status filter to Assign (or) Reassign FE!
        </> : !selectedLength ? "Select rows to Assign FE!" : undefined

    const onFilterChange = (key, value) => {

        let selectedVals = []

        if (key != "statuses" && key != "attemptCount") {
            selectedVals = value?.map(val => val.key || val.value);
        }

        if (key === "createdAt") {
            selectedVals = {
                from: value?.[0]?.valueOf(),
                to: value?.[1]?.valueOf()
            }
            value = selectedVals;
        } else if (key === "statuses") {

            if (value && value.length > 0) {

                if (value.reverse()[0].key == "ALL_STATUS") {
                    value = leadStatusOptions
                    selectedVals = []
                } else {

                    const statusIndex = value.findIndex((status) => status.key == "ALL_STATUS")

                    if (filterOptions?.statuses?.length > 0 &&
                        filterOptions.statuses[0].key == "ALL_STATUS" && statusIndex == -1) {
                        value = []
                        selectedVals = []
                    } else {

                        let valueInfo = []

                        if (statusIndex != -1) {
                            value.splice(statusIndex, 1)
                        }

                        value?.map(val => {
                            valueInfo.push({
                                ...val,
                                label: typeof val?.label === "string" || val?.label?.props?.children?.[1]
                            })

                            if (val.key == "UNASSIGNED") {
                                selectedVals = [...selectedVals, ...["PCREATED", "PPEND", "BOOK"]]
                            } else {
                                selectedVals.push(val.key)
                            }
                        });

                        value = valueInfo

                    }
                }

            }

        } else if (key === "attemptCount") {
            selectedVals = value?.key;
        } else {
            value = value?.map(val => {
                return {
                    ...val,
                    label: typeof val?.label === "string" || val?.label?.props?.children?.[1]
                }
            });
        }

        SetFilterOptions({
            ...filterOptions,
            [key]: value
        })
        setFilterEnabledButton(key==="createdAt"?true:false)
        handleFilterChange(key, selectedVals)
    }

    const onSearch = _ => {

        let filterData = {}

        if (searchInputRadio == "SHIPMENT") {
            filterData["waybills"] = searchInput.trim()
        } else {
            filterData["packets"] = searchInput.trim()
        }

        SetFilterOptions(current => {
            return {
                ...current,
                ...filterData
            }
        });
        setIsSearch(true)
        onInputSearch(searchInput.trim(), searchInputRadio);

    }

    const callResetFilters = _ => {
        SetFilterOptions({
            createdAt: {
                from: moment().subtract(dateRange, "days").startOf("day").valueOf(),
                to: moment().endOf("day").valueOf()
            }
        });
        setSearchInput("");
        resetFilters();
    }

    const onCalendarChange = dates => {
        if (dates.length > 1) {
            SetSelectedStartDate(undefined);
            return;
        }
        SetSelectedStartDate(dates[0]);
    }

    const disableDate = date => {
        if (date.valueOf() > moment().endOf("day").valueOf()) return true;
        if (selectedStartDate === undefined) return false;
        const maxEndDate = moment(selectedStartDate).startOf("day").add(dateRange, "days").endOf("day");
        if ((date.valueOf() >= maxEndDate.valueOf()) || date.valueOf() < moment(selectedStartDate).startOf("day").valueOf()) {
            return true;
        }
        return false;
    }

    return (
        <div className="flex-column flex-gap-l flex-1 align-items-stretch">
            <div style={{ backgroundColor: "#EEF3FB" }}>
                <div className="align-items-center flex-box flex-gap-m flex-wrap spacer-m">
                    <DatePicker.RangePicker style={{ width: 275 }} className="pickup-date-filter font-size-m-1 border-radius-m" onChange={value => {
                        if (!value || !value?.[0] || !value?.[1]) {
                            onFilterChange("createdAt", [moment().subtract(dateRange, "days").startOf("day").valueOf(), moment().endOf("day").valueOf()])
                        }
                        else {
                            onFilterChange("createdAt", value)
                        }
                    }} separator={"to"} showTime={true} format="DD/MM/YYYY HH:mm"
                        onCalendarChange={onCalendarChange} disabledDate={disableDate}
                        value={[moment(filterOptions?.createdAt?.from), moment(filterOptions?.createdAt?.to)]} />

                    <SelectFilters onChange={onFilterChange} options={filteredLeadData?.customers}
                        placeholder={"Search Customer"}
                        selectedOptions={filterOptions?.customerIds} filterKey={"customerIds"} />

                    <SelectFilters onChange={onFilterChange}
                        options={filteredLeadData?.pincode && filteredLeadData?.pincode?.length > 0 ?
                            filteredLeadData.pincode.map(pincode => ({
                                id: pincode.id,
                                label: pincode.zipcode,
                            })) : []}
                        placeholder={"Search Pincodes"} selectedOptions={filterOptions?.pincodeIds} filterKey={"pincodeIds"} />

                    <SelectFilters onChange={onFilterChange} placeholder={"Search Consignee"}
                        options={filteredLeadData?.consignees && filteredLeadData?.consignees?.length > 0 ?
                            filteredLeadData.consignees.map(consignee => ({
                                id: consignee.name,
                                label: consignee.name
                            })) : []}
                        selectedOptions={filterOptions?.consignees} filterKey={"consignees"} />

                    <SelectFilters onChange={onFilterChange} placeholder={"Search FE"}
                        options={filteredLeadData?.users && filteredLeadData?.users?.length > 0 ?
                            filteredLeadData.users.map(fe => ({
                                id: fe.id,
                                label: <div className="flex-column">
                                    <div> {fe.name} </div>
                                    <div className="font-size-sm-2"> {fe.number} </div>
                                </div>,
                                searchText: fe.name + " " + fe.contactNumber
                            })) : []}
                        selectedOptions={filterOptions?.feIds} filterKey={"feIds"} />

                    <SelectFilters onChange={onFilterChange} options={leadStatusOptions}
                        filterKey={"statuses"} placeholder={"Search Status"}
                        selectedOptions={filterOptions?.statuses} />

                    <SelectFilters onChange={onFilterChange} options={attemptCountOptions}
                        filterKey={"attemptCount"} placeholder={"Select Attempt Count"}
                        singleOptionOnly={true}
                        selectedOptions={filterOptions?.attemptCount} />

                    <Button onClick={callResetFilters} className="lsn-btn-error" style={{ padding: "0 25px" }} >
                        Clear All Filters
                    </Button>

                    <AppliedFilters showLabel={true} filteredOptions={{
                        ...filterOptions,
                        attemptCount: filterPayload?.attemptCount != undefined ? [filterPayload.attemptCount] : undefined,
                        waybills: filterPayload?.waybills && filterPayload?.waybills?.length > 0 ? filterPayload?.waybills : false,
                        packets: filterPayload.packets && filterPayload?.packets?.length > 0 ? filterPayload?.packets : false,
                        feIds: filterOptions?.feIds && filterOptions?.feIds?.length > 0 ?
                            filterOptions?.feIds.map(asset => {
                                let labelArray = asset?.label?.props?.label.split(" ");
                                return {
                                    ...asset,
                                    label: labelArray.splice(0, labelArray.length - 1)?.join(" ")
                                };
                            }) :
                            []
                    }} maxCount={5} keyToText={{
                        "packets": "Packets",
                        "waybills": "Waybills",
                        "statuses": "Status",
                        "consignees": "Consignees",
                        "customerIds": "Customers",
                        "pincodeIds": "Pincodes",
                        "feIds": "FEs",
                        "attemptCount": "Attempt Count"
                    }} />
                </div>
            </div>

            {totalWaybillCnt ?
                <div className="flex-box flex-gap-l spacer-hm align-items-center">
                    <Button className="lsn-btn-primary" disabled={isFilterApplied} type="primary" onClick={() => handleSelectAll()}>{selectAll ? "Deselect " : "Select "} All {totalWaybillCnt} Waybills</Button>
                    <div style={{ padding: "8px 16px" }} className="font-size-l-1">
                        <span className="info-color text-bold"> {selectedLength ? selectedLength : 0} </span>
                        AWB(s) selected
                    </div>
                </div>
                :
                <></>
            }

            <div className="flex-box justify-content-space-between spacer-hm flex-wrap flex-gap-l">
                <div className="flex-5 flex-box flex-gap-l align-items-center" style={{ minWidth: 300 }}>
                    <Input.TextArea className="border-radius-m flex-1 scan-awb-box"
                        placeholder={searchInputRadio == "waybills" ? "Search or Scan AWB(s)" : "Search Packet Ids"} autoSize={false}
                        value={searchInput} style={{ height: 35, resize: 'none' }}  
                        onChange={e => {
                            if (searchInput.trim() !== "" && e.currentTarget.value.trim() === "") {
                                onInputSearch("", searchInputRadio);
                            }
                            setSearchInput(e.currentTarget.value)
                        }} disabled={isFilterApplied} />
                    <Radio values={searchRadioValues}
                        onChange={(event) => setSearchInputRadio(event.target.value)}
                        defaultValue={searchInputRadio} />
                    <Button onClick={onSearch} disabled={isFilterApplied} className="lsn-btn-primary" type="primary" icon="search" > Search </Button>
                </div>
                <div className="flex-2 flex-box flex-gap-l align-items-center justify-content-flex-end">
                    <Button onClick={_ => downloadAllLeadInfo()} disabled = {isFilterApplied}  loading={downloadLoading} className="lsn-btn-secondary">
                        {downloadLoading ? "Exporting" : "Export"}
                        {!downloadLoading && <Icon type="download" />}
                    </Button>
                    <Tooltip title={disabledFEAssignmentTitle}>
                        <Button onClick={_ => openAssignFEModal(filtersForReassignApplied && selectedLength ? "reassign" : "assignAll")}
                            icon="plus" className="lsn-btn-primary" type="primary" disabled={disableFEAssignment} >
                            {filtersForReassignApplied && selectedLength ? "Reassign FE" : "Assign FE"}
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default ReversePickupTableMenu;