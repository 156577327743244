import CustomArrayFieldTemplate from "../components/CustomArrayField/CustomArrayFieldTemplate";

const schema = {
    "type": "object",
    "required": ["isExistingPartnerRadio", "location"],
    "properties": {
        "isExistingPartnerRadio": {
            "type": "boolean",
            "title": "Existing Partner ?"
            // "default": true
        },

        // Branch user details
        "branchUser": {
            "title": "Enter branch user details for location",
            "properties": {
                "userName": {
                    "title": "Name",
                    "type": "string"
                },
                "userPhoneNumber": {
                    "title": "Phone Number",
                    "type": "number"
                },
                "email": {
                    "title": "Email",
                    "type": "string"
                }
            }
        },
        // Location details
        "location": {
            "title": "Enter location details",
            "required": ["isValmoLocation", "locationName", "locationAddress", "locationPincode",
                "locationStaffPayCity", "locationOpsType", "clientLocationName"],
            "properties": {
                "isValmoLocation": {
                    "type": "boolean",
                    "title": "Is Valmo Location ?"
                },
                "locationOpsType": {
                    "title": "Ops Type",
                    "type": "string",
                    "enum": ["FM", "SC", "LM"],
                    "enumNames": ["FM", "SC", "LM"]
                },
                "locationName": {
                    "title": "Name",
                    "type": "string"
                },
                "clientLocationName": {
                    "title": "Client location name",
                    "type": "string"
                },
                "locationGstNumber": {
                    "title": "GST number",
                    "type": "string"
                },
                "locationAddress": {
                    "title": "Address",
                    "type": "string"
                },
                "locationPincode": {
                    "title": "Pincode",
                    "type": "number"
                },
                "locationStaffPayCity": {
                    "title": "Select StaffPay city for location",
                    "type": "integer",
                    "searchEnabled": true
                },
            }

        },
    },

    // If Condition of existing partner
    "allOf": [
        {
            "if": {
                "properties": {
                    "isExistingPartnerRadio": {
                        "const": true
                    }
                }
            },
            "then": {
                "required": ["existingPartnerSelect"],
                "properties": {
                    "existingPartnerSelect": {
                        "title": "Select the Partner",
                        "type": "integer",
                        // Setting enum and enumNames in the form initial load
                        "searchEnabled": true
                    },
                }
            }
        },

        // Displaying fields only required for FM.
        {
            "if": {
                "properties": {
                    "location": {
                        "properties": {
                            "locationOpsType": {
                                "const": "FM"
                            }
                        }
                    }
                }
            },
            "then": {
                "properties": {
                    "location": {
                        "required": ["locationClient"],
                        "properties": {
                            "locationClient": {
                                "title": "Add Client Pickup pincodes",
                                "type": "object",

                                "properties": {
                                    "new": {
                                        "title": "",
                                        "type": "array",
                                        "items": {
                                            "required": ["clientName", "clientPickupPincodes", "isSelfServed"],
                                            "properties": {
                                                "clientName": {
                                                    "title": "Name",
                                                    "type": "integer",
                                                    // Setting enums when the form is loaded.
                                                    "searchEnabled": true
                                                },
                                                "isSelfServed": {
                                                    "type": "boolean",
                                                    "title": "Is self served ?"
                                                },
                                                "clientPickupPincodes": {
                                                    "title": "Pickup Pincodes (Seller pincode)",
                                                    "type": "string"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },

        // Displaying fields only required for LM.
        {
            "if": {
                "properties": {
                    "location": {
                        "properties": {
                            "locationOpsType": {
                                "const": "LM"
                            }
                        }
                    }
                }
            },
            "then": {
                "properties": {
                    "location": {
                        "required": ["locationDeliveryPincode"],
                        "properties": {

                            "locationDeliveryPincode": {
                                "title": "Serviceable Pincodes (Delivery Pincode)",
                                "type": "string"
                            },

                            "rvpLocationClient": {
                                "title": "Add Client RVP pincodes",
                                "type": "object",
                                "properties": {
                                    "new": {
                                        "title": "",
                                        "type": "array",
                                        "items": {
                                            "required": ["clientName", "clientPickupPincodes", "isSelfServed"],
                                            "properties": {
                                                "clientName": {
                                                    "title": "Name",
                                                    "type": "integer",
                                                    // Setting enums when the form is loaded.
                                                    "searchEnabled": true
                                                },
                                                "isSelfServed": {
                                                    "type": "boolean",
                                                    "title": "Is self served ?"
                                                },
                                                "clientPickupPincodes": {
                                                    "title": "Pickup Pincodes (Seller pincode)",
                                                    "type": "string"
                                                }
                                            }
                                        }
                                    }
                                }
                            },

                            "locationClient": {
                                "title": "Add Client Pickup pincodes",
                                "type": "object",

                                "properties": {
                                    "new": {
                                        "title": "",
                                        "type": "array",
                                        "items": {
                                            "required": ["clientName", "clientPickupPincodes", "isSelfServed"],
                                            "properties": {
                                                "clientName": {
                                                    "title": "Name",
                                                    "type": "integer",
                                                    // Setting enums when the form is loaded.
                                                    "searchEnabled": true
                                                },
                                                "isSelfServed": {
                                                    "type": "boolean",
                                                    "title": "Is self served ?"
                                                },
                                                "clientPickupPincodes": {
                                                    "title": "Pickup Pincodes (Seller pincode)",
                                                    "type": "string"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },

        // If Condition for new partner
        {
            "if": {
                "properties": {
                    "isExistingPartnerRadio": {
                        "const": false
                    }
                }
            },
            "then": {
                "required": ["loadsharePartnerRadio"],
                "properties": {
                    // Partner details
                    "partner": {
                        "title": "Enter Partner Details",
                        "required": ["partnerName", "partnerPhoneNumber"],
                        "properties": {
                            "partnerName": {
                                "title": "Name",
                                "type": "string",
                                "searchEnabled": true
                            },
                            "partnerPhoneNumber": {
                                "title": "Phone Number",
                                "type": "number"
                            }
                        }
                    },
                    // User details
                    "user": {
                        "title": "Enter Admin user details",
                        "required": ["userName", "userPhoneNumber"],
                        "properties": {
                            "userName": {
                                "title": "Name",
                                "type": "string"
                            },
                            "userPhoneNumber": {
                                "title": "Phone Number",
                                "type": "number"
                            },
                            "email": {
                                "title": "Email",
                                "type": "string",
                                // "format": "email"
                            }
                        },
                    },
                    "loadsharePartnerRadio": {
                        "type": "boolean",
                        "title": "Is Loadshare Partner ?"
                    },
                }
            }
        }
    ],


}

const uiSchema = {
    "classNames": "multi-row-field-objects form-container",
    "isExistingPartnerRadio": {
        "ui:widget": "radio",
        "classNames": "single-field-multi-row-form existing-partner-radio"
    },
    "loadsharePartnerRadio": {
        "ui:widget": "radio"
    },
    "existingPartnerSelect": {
        "ui:placeholder": "Select partner."
    },
    "partner": {
        "classNames": "multi-row-field-objects partner-container",
        "partnerName": {
            "ui:placeholder": "Select partner name."
        },
        "partnerPhoneNumber": {
            "ui:placeholder": "Select partner phone number.",
            "type": "number",
            "maxLen": 10
        }
    },
    "user": {
        "classNames": "user-container",
        "userName": {
            "ui:placeholder": "Enter user name."
        },
        "userPhoneNumber": {
            "ui:placeholder": "Enter user phone number.",
            "type": "number",
            "maxLen": 10
        },
        "email": {
            "ui:placeholder": "Enter user email."
        }
    },
    "location": {
        "classNames": "location-group",
        "ui:options": {
            "orderable": false
        },
        "locationName": {
            "ui:placeholder": "Enter location name."
        },
        "clientLocationName": {
            "ui:placeholder": "Enter client location name."
        },
        "locationAddress": {
            "ui:placeholder": "Enter location address.",
            "ui:widget": "textarea"
        },
        "locationPincode": {
            "ui:placeholder": "Enter location pincode."
        },
        "locationDeliveryPincode": {
            "ui:widget": "textarea",
            "ui:placeholder": "Enter location delivery pincode."
        },
        "locationStaffPayCity": {
            "ui:placeholder": "Select location city"
        },
        "locationOpsType": {
            "ui:placeholder": "Select location ops type."
        },
        "locationGstNumber": {
            "ui:placeholder": "Enter GST number"
        },
        "isValmoLocation": {
            "ui:widget": "radio",
            "classNames": "single-field-multi-row-form existing-partner-radio"
        },
        "rvpLocationClient": {
            "classNames": "rvp-location-client",
            "existing": {
                "ui:options": {
                    "orderable": false,
                    "addable": false,
                    "displayToggleBtn": true
                },
                "items": {
                    "clientName": {
                        "ui:placeholder": "Select client name."
                    },
                    "isSelfServed": {
                        "ui:widget": "radio",
                        "classNames": "single-field-multi-row-form existing-partner-radio"
                    },
                    "clientPickupPincodes": {
                        "ui:widget": "textarea",
                        "ui:placeholder": "Enter client pickup pincode."
                    }
                }
            },
            "new": {
                "ui:options": {
                    "orderable": false
                },
                "items": {
                    "clientName": {
                        "ui:placeholder": "Select client name."
                    },
                    "isSelfServed": {
                        "ui:widget": "radio",
                        "classNames": "single-field-multi-row-form existing-partner-radio"
                    },
                    "clientPickupPincodes": {
                        "ui:widget": "textarea",
                        "ui:placeholder": "Enter client pickup pincode."
                    }
                }
            }
        },
        "locationClient": {
            "classNames": "location-client",
            "ui:options": {
                "orderable": false
            },
            "existing": {
                "ui:options": {
                    "orderable": false,
                    "addable": false,
                    "displayToggleBtn": true
                },
                "items": {
                    "clientName": {
                        "ui:placeholder": "Select client name."
                    },
                    "isSelfServed": {
                        "ui:widget": "radio",
                        "classNames": "single-field-multi-row-form existing-partner-radio"
                    },
                    "clientPickupPincodes": {
                        "ui:widget": "textarea",
                        "ui:placeholder": "Enter client pickup pincode."
                    }
                }
            },
            "new": {
                "items": {
                    "clientName": {
                        "ui:placeholder": "Select client name."
                    },
                    "isSelfServed": {
                        "ui:widget": "radio",
                        "classNames": "single-field-multi-row-form existing-partner-radio"
                    },
                    "clientPickupPincodes": {
                        "ui:widget": "textarea",
                        "ui:placeholder": "Enter client pickup pincode."
                    }
                }
            }
        }
    },
    "branchUser": {
        "userName": {
            "ui:placeholder": "Enter branch user name.",
        },
        "userPhoneNumber": {
            "ui:placeholder": "Enter branch user phone number.",
            "type": "number",
            "maxLen": 10
        },
        "email": {
            "ui:placeholder": "Enter branch user email."
        }
    },
    // Determining field display order
    "ui:order": [
        "isExistingPartnerRadio",
        "existingPartnerSelect",
        "*",
        "branchUser",
        "location"
    ],
}


const errorList = {
    "isExistingPartnerRadio": {
        "required": "Select if partner is existing or new."
    },
    "existingPartnerSelect": {
        "required": "Please select the partner"
    },
    "location": {
        "locationName": {
            "required": "Location Name required!",
            "pattern": "Location Name must contain only alphabets"
        },
        "clientLocationName": {
            "required": "Client Location name required!"
        },
        "locationAddress": {
            "required": "Location address is required!"
        },
        "locationPincode": {
            "required": "Location pincode is required!",
            "pattern": "Pincode must contain only digits"
        },
        "locationStaffPayCity": {
            "required": "Location city is required!"
        },
        "locationOpsType": {
            "required": "Location ops type is required!"
        },
        "rvpLocationClient": {
            "required": "RVP client details required for FM location",
            "clientName": {
                "required": "Please select client"
            },
            "clientPickupPincodes": {
                "required": "Please enter pickup pincodes!"
            },
            "isSelfServed": {
                "required": "Please select self served"
            }
        },
        "locationClient": {
            "required": "Client details required for FM location",
            "clientName": {
                "required": "Please select client"
            },
            "clientPickupPincodes": {
                "required": "Please enter pickup pincodes!"
            },
            "isSelfServed": {
                "required": "Pleas select self served"
            }
        },
        "locationDeliveryPincode": {
            "required": "Please enter delivery pincodes!"
        }
    },
    "partner": {
        "partnerName": {
            "required": "Partner Name required!",
            "pattern": "Partner Name must contain only alphabets"
        },
        "partnerPhoneNumber": {
            "required": "Partner phone number required!",
            "ignoreType": true
        },
    },
    "user": {
        "userName": {
            "required": "User Name required!",
            "pattern": "User Name must contain only alphabets"
        },
        "userPhoneNumber": {
            "required": "User phone number required!",
            "ignoreType": true
        },
    },
    "loadsharePartnerRadio": {
        "required": "Please choose whether partner is LSN partner or not"
    }
}

export default { schema, uiSchema, errorList };