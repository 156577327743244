import { Alert } from "antd";
import React from "react";

export default function LastScanManifestBlock({ lastScannedManifest, isMobile }) {
    return (
        <div className="flex-column flex-gap-m">
            <div className="text-bold"> Last Scanned Manifest details </div>
            {
                lastScannedManifest ? 
                isMobile ?
                <div className="flex-column flex-gap-l spacer-s white-bg">
                    <Alert message="Scan Successful" type="success" showIcon className={"success-color"} />
                    <div className="flex-column flex-gap-s">
                        <div className="light-grey-text"> Next Location</div> 
                        <div className="font-size-l-3"> 
                            {
                                typeof lastScannedManifest?.nextLocation === "string" ? lastScannedManifest?.nextLocation : 
                                typeof lastScannedManifest?.nextLocation === "object" ? lastScannedManifest?.nextLocation?.name : 
                                <></>
                            }
                        </div>
                    </div>
                    <div className="flex-column flex-gap-m">
                        <div className="flex-box justify-content-space-between">
                            <div className="flex-column flex-gap-s flex-2 align-items-start">
                                <div className="light-grey-text"> Manifest ID</div> 
                                <div className="font-size-m-3 break-all"> {lastScannedManifest?.code ? lastScannedManifest?.code : lastScannedManifest?.manifestCode} </div>
                            </div>
                            <div className="flex-column flex-gap-s flex-1 align-items-end">
                                <div className="light-grey-text"> Status </div> 
                                <div className="font-size-m-3"> {lastScannedManifest?.status ? lastScannedManifest?.status : lastScannedManifest?.manifestStatus} </div>
                            </div>
                            {
                                 lastScannedManifest?.flowType && 
                                 <div className="flex-column flex-gap-s flex-1 align-items-end">
                                 <div className="light-grey-text"> Flow Type </div> 
                                 <div className="font-size-m-3"> {lastScannedManifest?.flowType ? lastScannedManifest?.flowType : ""} </div>
                            </div>
                            }
                        </div>
                    </div>
                </div> :
                <div className="flex-box flex-gap-xl spacer-m grey-bg border-radius-m">
                    <div className="flex-column flex-gap-l">
                        <div> Manifest ID </div>
                        <div className="text-bold"> {lastScannedManifest?.code ? lastScannedManifest?.code : lastScannedManifest?.manifestCode} </div>
                    </div>
                    {
                        lastScannedManifest?.flowType &&
                        <div className="flex-column flex-gap-l">
                            <div> Flow Type </div>
                            <div className="text-bold"> {lastScannedManifest?.flowType} </div>
                        </div>
                    }
                    {
                        lastScannedManifest?.quantity &&
                        <div className="flex-column flex-gap-l">
                            <div> Item Count </div>
                            <div className="text-bold"> {lastScannedManifest?.quantity} </div>
                        </div>
                    }
                    {
                        lastScannedManifest?.nextLocation && 
                        <div className="flex-column flex-gap-l">
                            <div> Next Location </div>
                            <div className="text-bold">
                                {
                                    typeof lastScannedManifest?.nextLocation === "string" ? lastScannedManifest?.nextLocation : 
                                    typeof lastScannedManifest?.nextLocation === "object" ? lastScannedManifest?.nextLocation?.name : 
                                    <></>
                                }
                            </div>
                        </div>
                    }
                    <div className="flex-column flex-gap-l">
                        <div> Status </div>
                        <div className={"text-bold" + (lastScannedManifest?.status === "OVERAGE" || lastScannedManifest?.status === "MISROUTE" ? " error-color" : "")}> {lastScannedManifest?.status ? lastScannedManifest?.status : lastScannedManifest?.manifestStatus} </div>
                    </div>
                </div> :
                <div className="spacer-l flex-box align-items-center justify-content-center flex-1 light-grey-text">
                    Last scanned Manifest details will appear here.
                </div>
            }
        </div>
    )
}