import { apiService } from "../../../../common/apiService/service";
import { apiRequest } from "../../../../common/utils/apiGateway";
import { checkHttpStatus, notifyApiSuccess } from "../../../../common/utils/utils";

export const fetchConnectionSheets = payload => new Promise((resolve, reject) => {
    apiRequest({
        url  : "/b2c/v1/connection/sheet/fetch",
        data : payload,
        method : "POST"
    })
    .then(response => {
        if (checkHttpStatus(response.data.status)) {
            resolve(response.data.response);
        }
        else {
            reject(response.data.response);
        }
    })
    .catch(response =>  reject(response?.data?.response))
})

export const fetchConnectionDetails=(payload)=>{
    return apiService.post(`hub/connection/fetch`, payload)
}

export const fetchConnectionDestinationsOptions = _ => new Promise((resolve, reject) => {
    apiRequest({
        url    : "/b2c/v1/connection/fetch/destinationLocations",
        method : "GET"
    })
    .then(response => {
        if (checkHttpStatus(response.data.status)) {
            resolve(response.data?.response?.locations);
        }
        else {
            reject(response.data)
        }
    })
    .catch(response =>  reject(response?.data?.response))
});

export const deleteConnection = (connectionId) => new Promise((resolve, reject) => {
    apiRequest({
        url: `/b2c/v1/connection/delete/${connectionId}`,
        method: "DELETE"
    })
    .then((response) => {
        if (checkHttpStatus(response.data.status)) {
            resolve();
            notifyApiSuccess(response.data?.status?.message);
        }
        else {
            reject(response.data);
        }
    })
    .catch((e) => reject(e))
});

export const deleteConnectionFromSheet=(connectionCode)=>{
    return apiService.delete(`hub/connection/${connectionCode}`)
}

export const fetchShipmentCountByConnections = payload => new Promise((resolve, reject) => {
    apiRequest({
        url    : "/b2c/v1/connection/sheet/fetch/shipmentCount",
        method : "POST",
        data   : payload
    })
    .then(response => {
        if (checkHttpStatus(response.data.status)) {
            resolve(response.data.response);
        }
        else {
            reject(response.data);
        }
    })
    .catch(response =>  reject(response?.data?.response))
})

export const fetchConnectionSheetDetails = connectionIds => apiRequest({
    url    : "b2c/v1/connection/fetchDetails",
    method : "POST",
    data   : { connectionIds }
})
