import { Button, Divider, Icon, Input, Select, Spin } from "antd";
import React, { useRef, useState } from "react";
import { notifyApiError } from "../../../../common/utils/utils";
import { SoundNotificationService } from "../../../../services/sound.service";
import { manifestExceptionScan } from "../services/post.service";
import MetabaseReport from "../../../operations/components/metabase.report";
import MetabaseQuestion from "../../../common/metabase-question/metabase.question";

const soundNotificationService = new SoundNotificationService();

export default function ManifestExceptions({ reloadListWithFilters }) {

    const inputRef = useRef();

    const [scanResponse, SetScanResponse] = useState(undefined);
    const [scanningInProgress, SetScanningInProgress] = useState(undefined);
    const [exceptionType, SetExceptionType] = useState('overage');

    const scanWaybill = _ => {
        const manifestId = inputRef?.current?.state?.value?.trim();
        if (manifestId === undefined || manifestId === "") {
            return notifyApiError("Enter waybill number to scan!");
        }
        SetScanningInProgress(true);
        manifestExceptionScan(manifestId)
            .then(response => {
                SetScanResponse(response?.manifest);
                SetScanningInProgress(false);
                document.getElementById("scanManifestIdInput")?.focus();
                soundNotificationService.playGeneralSuccess();
            })
            .catch(_ => {
                SetScanningInProgress(false);
                SetScanResponse(undefined);
                document.getElementById("scanManifestIdInput")?.focus();
                soundNotificationService.playGeneralWarning();
            })
    }

    return (
        <div className="flex-column spacer-hm flex-gap-m">
            <div className="flex-column flex-2 flex-gap-s">
                <div> Scan Exception Bags </div>
                <Input disabled={scanningInProgress} placeholder={"Scan Manifest ID"}
                    onPressEnter={scanWaybill} ref={inputRef} id={"scanManifestIdInput"} suffix={
                        <Button loading={scanningInProgress} onClick={scanWaybill} type="primary"
                            className="lsn-btn-primary input-suffix-button">
                            <Icon type="barcode" /> Scan
                        </Button>
                    } />
            </div>
            {
                scanResponse &&
                <Spin spinning={scanningInProgress}>
                    <div className="flex-column flex-gap-m border-grey grey-bg spacer-vs" style={{ borderWidth: "1px 0px" }}>
                        <div className="font-size-m-3 spacer-hl text-bold">
                            Last Scanned Entity
                        </div>
                        <Divider style={{ margin: 0 }} />
                        <div className="flex-box justify-content-space-between">
                            <div className="flex-box flex-gap-xl spacer-hm">
                                <div className="flex-1 flex-column flex-gap-s spacer-hl">
                                    <div className="font-size-m-1 text-semi-bold"> Manifest ID </div>
                                    <div className="font-size-m-3 text-bold"> {scanResponse?.code} </div>
                                </div>
                                {
                                    scanResponse?.status &&
                                    <div className="flex-column flex-gap-s spacer-hm">
                                        <div className="font-size-m-1 text-semi-bold"> Manifest Status </div>
                                        <div className="font-size-m-3 text-bold"> {scanResponse?.status} </div>
                                    </div>
                                }
                                {
                                    scanResponse?.nextLocation &&
                                    <div className="flex-column flex-gap-s">
                                        <div className="font-size-m-1 text-semi-bold"> Next Location </div>
                                        <div className="font-size-m-3 text-bold"> {scanResponse?.nextLocation} </div>
                                    </div>
                                }
                                {
                                    (scanResponse?.totalShipmentCount &&
                                     Number(scanResponse?.totalShipmentCount) && 
                                     Number(scanResponse?.totalShipmentCount) > 0) ?
                                    <div className="flex-column flex-gap-s">
                                        <div className="font-size-m-1 text-semi-bold"> Shipment Count </div>
                                        <div className="font-size-m-3 text-bold"> {scanResponse?.totalShipmentCount} </div>
                                    </div> :
                                    <></>
                                }
                            </div>
                        </div>
                    </div>
                </Spin>
            }
            <div className="flex-column flex-gap-m">
                    <div> Exception Type </div>
                    <Select value={exceptionType} onChange={SetExceptionType}>
                        <Select.Option value={"overage"} key={"overage"} > Overage </Select.Option>
                        <Select.Option value={"shortage"} key={"shortage"} > Shortage </Select.Option>
                        <Select.Option value={"misroute"} key={"misroute"} > Misroute </Select.Option>
                    </Select>
                </div>
            {
                exceptionType == "shortage" ?
                <MetabaseReport reportName={"bag-shortages"} hideBackButton={true} iframeHeight={"calc(100vh - 300px)"}/> :
                exceptionType == "overage" ?
                <MetabaseQuestion reportName={"bag-shortages"} questionNo={2859} lockedParams={"location_id"} iframeHeight={"calc(100vh - 400px)"}/> :
                exceptionType == "misroute" ?
                <MetabaseQuestion reportName={"bag-misroute"} dashboardNo={268} lockedParams={"location_id"} iframeHeight={"calc(100vh - 400px)"}/> :
                <></>
            }
        </div>
    )
}