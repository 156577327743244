import { apiRequest } from "../../../common/utils/apiGateway";
import { checkHttpStatus } from "../../../common/utils/utils";
import { generatePostObject, formPreviewColumns } from "../util";

export const postReports = (data, reportConfig, action, summaryEnum, emailTo) => new Promise((resolve, reject) => {
    const postObject = generatePostObject(data , reportConfig, action, summaryEnum, emailTo);
    apiRequest({
        ...action.endPoint,
        data : postObject
    })
    .then(response => {
        if (action.id === "preview") {
            if (checkHttpStatus(response?.data?.status)) {
                const header = JSON.parse(response?.data?.response?.data?.header);
                const metaInfo = response?.data?.response?.data?.metaInfo;
                if (metaInfo) {
                    var { tempColumns,tempPreviewData } = generatePreviewObjects(header, metaInfo);
                    if (Object.keys(tempColumns).map(cKey => tempPreviewData[cKey]).filter(data => data && data?.length > 0).length) {
                        resolve({ tempColumns,tempPreviewData });
                    }
                    resolve({ tempColumns : [], tempPreviewData : [] })
                }
                else {
                    resolve({ tempColumns : undefined, tempPreviewData : undefined })
                }
            }
            else {
                reject(response?.data?.status);
            }
        }
        else {
            if (checkHttpStatus(response?.data?.status)) {
                resolve(response?.data?.response)
            }
            else {
                reject(response?.data?.status);
            }
        }
    })
    .catch(e => {
        console.log(e);
        reject(e)
    })
})

const generatePreviewObjects = (header, metaInfo) => {
    var tempColumns = {};
    var tempPreviewData = {};
    Object.keys(header).map(key => {
        if (typeof header[key] == "object" && typeof metaInfo[key] === "object") {
            tempColumns[key] = formPreviewColumns(header[key])
            tempPreviewData[key] = metaInfo[key]?.summary
        }
        else if (typeof header[key] == "object" && typeof metaInfo[key] === "string") {
            tempColumns[key] = formPreviewColumns(header[key])
            tempPreviewData[key] = JSON.parse(metaInfo[key])?.summary
        }
    });
    return { tempColumns, tempPreviewData };
}