import { apiRequest } from "../../common/utils/apiGateway";

export default class RTOServices {
    getAllRTODRSes(payload) {
        const url = "b2c/v1/rto/drs/fetch"
        return apiRequest({
            url,
            method: "POST",
            data: payload
        });
    };
    getAllRTOConsignments(payload) {
        const url = "b2c/v1/rto/unassigned"
        return apiRequest({
            url,
            method: "POST",
            data: payload
        });
    };
    updateDrs(data, application = "b2c") {
        const url = `/${application}/v1/rto/drs/update`;
        return apiRequest({
            url,
            method: "POST",
            data
        });
    }

    getAllRTOEligible(payload) {
        const url = "b2b/v1/consignments/unassigned"
        return apiRequest({
            url,
            method: "POST",
            data: payload
        });
    };
    consignmentUpdate(payload) {
        const url = `/b2b/v1/consignments/update`;
        return apiRequest({
            url,
            method: "POST",
            data: payload
        });
    };
    configurations() {
        const url = "/b2b/v1/config";
        return apiRequest({
           url,
           method: "GET"
        });
    };
}
