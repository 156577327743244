import { Button, Input, Modal, Select, Tag } from "antd";
import { useRef, useState } from "react";
import { Constants } from "../../../../common/utils/constants";
import moment from "moment";
import { codTransactionModes, formatCurrency, notifyApiError, notifyApiSuccess } from "../../../../common/utils/utils";
import { approveRejectSetllement } from "../../services/post.service";

export default function SettlementActions({ settlementInfo, onSettlementActionSuccess }) {

    var remarksRef = useRef();

    const [ rejectReason, SetRejectReason ] = useState(false);
    const [ loading, SetLoading ] = useState(false);
    const config = localStorage.getItem("_configrations") ? JSON.parse(atob(localStorage.getItem("_configrations"))) : {};
    var isUserApprover = false;
    if (config.codApproverUserIds) {
        isUserApprover = config.codApproverUserIds.indexOf(Constants.sessionUser.id + "") > -1
    }

    const rejectReasonOptions = [
        { label: "Amount not matching slip", value: "Amount not matching slip" },
        { label: "Amount not received", value: "Amount not received" },
        { label: "Others", value: "Others" }
    ];

    const [ action, SetAction ] = useState(false);

    const callSetllementAction = _ => {
        
        var paymentMeta = settlementInfo.paymentInfo?.paymentMeta ? JSON.parse(settlementInfo.paymentInfo?.paymentMeta) : {}

        if (action === "REJECTED") {
            if (!rejectReason) {
                notifyApiError("Choose a reason for rejecting settlement!");
                return;
            }
            paymentMeta["rejected_reason"]  = rejectReason;
            paymentMeta["rejected_remarks"] = remarksRef.current ? remarksRef.current.state?.value : "";
        }

        let data = {
            remitter: {},
            amount: {
              amount   : action === "APPROVED" ? settlementInfo.actualAmount : 0,
              currency : "INR"
            },
            payee: {},
            paymentEntity: {},
            settlementInfo: {
                ...settlementInfo,
                expectedAmount : action === "APPROVED" ? settlementInfo.actualAmount : 0,
                approvalStatus : action,
                paymentInfo    : {
                    ...settlementInfo.paymentInfo,
                    actualAmount : action === "APPROVED" ? settlementInfo.actualAmount : 0,
                    paymentMeta  : JSON.stringify(paymentMeta)
                }
            },
            remitanceType : "PARTNER_TO_CUSTOMER_REMITTANCE",
            approvalUser  : {
                id        : Constants.sessionUser.id
            }
        };
    
        SetLoading(true);
        approveRejectSetllement(data)
        .then(response => {
            notifyApiSuccess(action == "APPROVED" ? "Settlement Accepted Successfully!" : "Settlement Rejected Successfully", settlementInfo?.id);
            if (typeof onSettlementActionSuccess == "function") {
                onSettlementActionSuccess(action, response, data);
            }
            SetLoading(false);
        })
        .catch(e => console.log(e))
    }
    
    return (
        <div className="flex-box flex-gap-m">
            {
               isUserApprover && settlementInfo?.approvalStatus === "REQUEST_FOR_APPROVAL" ?
               <>
                <Button onClick={_ => SetAction("APPROVED")} className="lsn-btn-secondary"> Accept </Button>
                <Button onClick={_ => SetAction("REJECTED")} className="lsn-btn-secondary"> Reject </Button>
               </> :
               <Tag className={`font-size-m-2 ant-table-tag ${settlementInfo?.approvalStatus === "APPROVED" ? "success-tag" : settlementInfo?.approvalStatus === "REJECTED" ? "error-tag" : "warning-tag"}`}>
                    {
                        settlementInfo?.approvalStatus === "APPROVED" ? "Accepted" : 
                        settlementInfo?.approvalStatus === "REJECTED" ? "Rejected" : 
                        settlementInfo?.approvalStatus
                    }
               </Tag>
            }

            <Modal width={"40%"} title={ action == "APPROVED" ? "Accept Settlement" : "Reject Settlement" } visible={["APPROVED", "REJECTED"].indexOf(action) > -1} 
             okText={ action == "APPROVED" ? "Accept" : "Reject" } 
             okButtonProps={{ 
                className : action == "APPROVED" ? "lsn-btn-success" : "lsn-btn-error",
                type : "default",
                loading : loading
             }}
             cancelText="Cancel" cancelButtonProps={{ className : "lsn-btn-secondary", type : "default", loading : loading }}
             onCancel={_ => SetAction(false)}
             onOk={callSetllementAction} >
                <div className="flex-column flex-gap-xl">
                    <div className="flex-box flex-gap-l flex-wrap">
                        <div className="flex-column flex-gap-xs align-items-flex-start flex-basis-50-gap-l">
                            <div className="text-bold">Remitted to</div>
                            <div>{settlementInfo?.approverPartner?.custObject?.name}</div>
                        </div>

                        <div className="flex-column flex-gap-xs align-items-flex-start flex-basis-50-gap-l">
                            <div className="text-bold">Amount Remitted</div>
                            <div>{formatCurrency(settlementInfo?.actualAmount)}</div>
                        </div>

                        <div className="flex-column flex-gap-xs align-items-flex-start flex-basis-50-gap-l">
                            <div className="text-bold">Transaction ID</div>
                            <div>{settlementInfo?.id}</div>
                        </div>

                        <div className="flex-column flex-gap-xs align-items-flex-start flex-basis-50-gap-l">
                            <div className="text-bold">Remit Request At</div>
                            <div>{moment(settlementInfo?.createdAt).format("DD/MM/YYYY hh:mm A")}</div>
                        </div>

                        <div className="flex-column flex-gap-xs align-items-flex-start flex-basis-50-gap-l">
                            <div className="text-bold">Remitted by</div>
                            <div>{settlementInfo?.user?.name}</div>
                        </div>

                        <div className="flex-column flex-gap-xs align-items-flex-start flex-basis-50-gap-l">
                            <div className="text-bold">Transaction Mode</div>
                            <div>
                                {
                                    codTransactionModes() && codTransactionModes()?.length > 1 ?
                                    codTransactionModes().find(mode => mode.value === settlementInfo?.receiptReqRemarks)?.name || settlementInfo?.receiptReqRemarks :
                                    settlementInfo?.receiptReqRemarks
                                }
                            </div>
                        </div>

                    </div>
                    {
                        action === "REJECTED" &&
                        <div className="flex-column flex-gap-l">
                            <div className="flex-column flex-gap-s">
                                <div className="font-size-m-3 text-bold"> Reason </div>
                                <Select onChange={SetRejectReason} placeholder="Select Reason for Rejection">
                                    {
                                        rejectReasonOptions.map(reason => (
                                            <Select.Option key={reason.value} value={reason.value} >
                                                {reason.label}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </div>
                            
                            <div className="flex-column flex-gap-s">
                                <div className="font-size-m-3 text-bold">  Remarks <small> (Optional) </small> </div>
                                <Input.TextArea ref={remarksRef} style={{ resize : "none" }}
                                 placeholder="Enter any additional info if necessary" />
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        </div>
    )

}