import React, { useEffect } from "react";
import LoginCard from "./login-card/login-card";
import { apiUrl } from "../../environments/node-react-environment";
import moment from "moment";
import { getRedirectedUrl, notifyApiError, parsePermission } from "../../common/utils/utils";import { useLocation, useParams } from "react-router-dom";
import { Spin } from "antd";
;

const LoginForm = _ => {

    const location = useLocation();
    const { search } = location;
    
    useEffect(_ => {
        if (window.location.host.indexOf("meesho-atlas") > - 1) {
          window.location.href = "https://log10-atlas.loadshare.net/";
        }
    }, [])

    useEffect(_ => {
        if (search.indexOf("username") > -1 && search.indexOf("password") > -1) {
            var username = search.substring(search.indexOf("=") + 1, search.indexOf("&"));
            var password = search.substring(search.lastIndexOf("=") + 1);
            login({ username, password, remember : true }, _ => {});
        }
    })

    const login = (data, SetLoading) => {
        localStorage.clear();
        const url = `${apiUrl}/v1/login`;
        fetch(url, {
            method  : "POST",
            body    : JSON.stringify(data),
            headers : {'Content-Type': 'application/json', 'deviceId':'866409030924340'}
        })
        .then(response => response.json())
        .then(responseData => {
            SetLoading(false);
            if (responseData.status?.code == "200") {
                const response = responseData.response;
                localStorage.setItem('primaryAccessToken', response.token.accessToken);
                localStorage.setItem('primaryTokenId', response.token.tokenId);
                localStorage.setItem('accessToken', response.token.accessToken);
                localStorage.setItem('refreshToken', response.token.refreshToken);
                localStorage.setItem('tokenId', response.token.tokenId);
                localStorage.setItem("refreshFlag", moment().valueOf());
                localStorage.setItem('user', btoa(JSON.stringify(response.user)));
                const permissions = parsePermission(response.user.permissions);
                if (!permissions.B2C || permissions.B2C?.length === 0) {
                    return notifyApiError("User does not have role. Kindly contact your admin.");
                }
                localStorage.setItem("permissions", btoa(JSON.stringify(permissions)));
                const allApplications = Object.keys(permissions);
                const selectedApplication = allApplications[0];
                localStorage.setItem("application", btoa(selectedApplication));
                setTimeout(() => {
                    const locationParam = window.location.search;
                    if (locationParam && locationParam.indexOf("redirectTo") > 0) {
                        window.location.href = locationParam.substr(locationParam.indexOf("=") + 1);
                    }
                    else {
                        window.location.href = getRedirectedUrl(permissions);
                    }
                }, 500);
            }
            else if (responseData.status?.code == "400"){
                notifyApiError(responseData.status.message, "Error")
            }
        })
        .catch(exp => {
            SetLoading(false);
            console.log(exp);
        })
        .catch(exp => {
            SetLoading(false);
            console.log(exp);
        })
    }

    return(
        <div className="flex-box justify-content-space-between align-items-center" style={{ height : "100vh" }} >
            {
                search.indexOf("username") > -1 && search.indexOf("password") > -1 ?
                <Spin spinning={true} /> :
                <>
                    <div className="flex-1 flex-box">
                        <LoginCard callLogin={login}/>
                    </div>
                    <div className="flex-1 login-page-right"></div>
                </>
            }
        </div>
    )
};

export default LoginForm;