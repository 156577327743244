import { apiRequest } from "../../../common/utils/apiGateway"
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import {apiService} from "../../../common/apiService/service";

export const fetchConnectionByCode = code => new Promise((resolve, reject) => {
    apiRequest({
        url  : "/b2c/v1/connection/sheet/fetch",
        data : 
        {
            pageNo   : 1,
            pageSize : 10,
            connectionCodes : [ code ]
        },
        method : "POST"
    })
    .then(response => {
        if (response.data?.response && response.data.response?.connectionSheets && response.data.response.connectionSheets.length > 0) {
            resolve(response.data.response.connectionSheets[0]);
        }
        else {
            reject(response.data)
        }
    })
})

export const fetchConnectionDetailsByCode=(connectionCode)=>{
    return apiService.get(`hub/connection/fetch/${connectionCode}`)
}

export const createConnectionSheet = postObj => execPostURL(postObj,"/b2c/v1/connection/create")

export const createConnection = (payload) => {
    return apiService.post("hub/connection", payload)
}

export const fetchPendingManifest = (connectionCode, params) => {
    const postObj = {
        pageNo         : params && params.pageNo ? params.pageNo : 1,
        pageSize       : params && params.pageSize ? params.pageSize :  10,
        manifestCode   : params && params.manifestCode ? params.manifestCode : undefined,
        connectionCode : connectionCode
    }
    return execPostURL(postObj,"/b2c/v1/connection/manifest/unassigned")
}

export const fetchUnassignedManifest = (connectionCode, params) => {
    const payload = {
        pageNo         : params && params.pageNo ? params.pageNo : 1,
        pageSize       : params && params.pageSize ? params.pageSize :  10
    }
    return apiService.get(`hub/connection/unassignedManifest/${connectionCode}`, {queryParams:payload})
}

export const fetchScannedManifest = (connectionCode, params) => {
    const postObj = {
        pageNo         : params && params.pageNo ? params.pageNo : 1,
        pageSize       : params && params.pageSize ? params.pageSize :  10,
        manifestCode   : params && params.manifestCode ? params.manifestCode : undefined,
        connectionCode : connectionCode,
        manifestStatus : "SCANNED"
    }
    return execPostURL(postObj,"/b2c/v1/connection/manifest/fetch")
}

export const fetchAssignedManifest = (connectionCode, params) => {
    const payload = {
        pageNo         : params && params.pageNo ? params.pageNo : 1,
        pageSize       : params && params.pageSize ? params.pageSize :  10
    }
    return apiService.get(`hub/connection/assignedManifest/${connectionCode}`, {queryParams:payload});
}

export const fetchManifestList = (payload) => {
    return apiService.post(`hub/connection/manifest-list`, payload);
}

export const manifestToConnection = (data, action) => {

    const linkPostObj = {
        scanInputType  : "KEYBOARD",
        connectionCode : data.connectionCode,
        manifestCode   : data.code
    }
    return execPostURL(linkPostObj,`/b2c/v1/connection/manifest/${action}`);
}

export const linkDelinkManifest=(data, action)=>{
    const payload={scanInputType  : 'KEYBOARD'}
    const connectionCode = data.connectionCode
    const manifestCode   = data.code
    if (action === "link") {
        return apiService.put(`hub/connection/${connectionCode}/manifest/${manifestCode}`, null, {queryParams:payload})
    }
    else{
        return apiService.delete(`hub/connection/${connectionCode}/manifest/${manifestCode}`, null, {queryParams:payload})
    }
}

export const fetchConnectionDestinationsOptions = _ => new Promise((resolve, reject) => {
    apiRequest({
        url    : "/b2c/v1/connection/fetch/destinationLocations",
        method : "GET"
    })
    .then(response => {
        if (response.data?.status?.code == "200" || response.data?.status?.code == "202") {
            resolve(response.data?.response?.locations);
        }
        else {
            reject(response.data)
        }
    })
    .catch(e => {
        reject(e);
    })
})

export const fetchDestinationLocations = () => {
    return apiService.get("hub/connection/destinationLocations")
}

export const deleteConnection = (connectionId) => new Promise((resolve, reject) => {
    // Using showMessage to display backend error message.
    apiRequest({
        url: `/b2c/v1/connection/delete/${connectionId}`,
        method: "DELETE",
        showMessage: true
    })
    .then((response) => {
        if (response.data?.status?.code == "200" || response.data?.status?.code == "202") {
            resolve();
            notifyApiSuccess(response.data?.status?.message);
        }
        else {
            reject();
        }
    })
    .catch((e) => {
        reject(e);
        notifyApiError(e);
    })
})

const execPostURL = (data, url) => new Promise((resolve, reject) => {
    apiRequest({
        url    : url,
        method : "POST",
        data   : data
    })
    .then(response => {
        if (response.data?.status?.code == "200" || response.data?.status?.code == "202") {
            resolve(response.data?.response);
        }
        else {
            reject(response.data)
        }
    })
    .catch(e => {
        reject(e);
    })
})