import * as apiUtils from '../common/utils/apiGateway';
import { S3_REPORT_STATUS, USER_LOGGEDIN_INFO } from '../common/utils/constants';
import { notifyApiSuccess, parsePermission } from '../common/utils/utils';
import MiscService from '../services/misc.service';
import * as CONSTANTS from './appConstants';

export const getConfigurations = () => {
  return dispatch => {
    dispatch(getConfigurationsBegin());
    apiUtils
      .apiRequest({
        url: `/b2b/v1/config`
      })
      .then(response => {
        if (response.data.response.config) {
          localStorage.setItem('_configrations', btoa(JSON.stringify(response.data.response.config)));
          dispatch(getConfigurationsSuccess(response.data.response.config));
        }
      })
      .catch(error => dispatch(getConfigurationsFailure(error)));
  };
};

const getConfigurationsBegin = () => {
  return {
    type: CONSTANTS.GET_CONFIGURATIONS_BEGIN
  };
};

const getConfigurationsSuccess = config => {
  return {
    type: CONSTANTS.GET_CONFIGURATIONS_SUCCESS,
    payload: config
  };
};

const getConfigurationsFailure = error => {
  return {
    type: CONSTANTS.GET_CONFIGURATIONS_FAILURE,
    payload: error
  };
};

export const getPermissions = () => {
  return dispatch => {
    dispatch(getPermissionsBegin());
    apiUtils
      .apiRequest({
        method: 'GET',
        url: `/b2b/v1/user/getPermissionsForUser`
      })
      .then(response => {
        if (response.data.response.permissions) {
          const permissions = parsePermission(response.data.response.permissions)
          localStorage.setItem('permissions', btoa(JSON.stringify(permissions)));
          dispatch(getPermissionsSuccess(permissions));
        }
      })
      .catch(error => dispatch(getPermissionsFailure(error)));
  };
};

const getPermissionsBegin = () => {
  return {
    type: CONSTANTS.GET_PERMISSIONS_BEGIN
  };
};

const getPermissionsSuccess = permission => {
  return {
    type: CONSTANTS.GET_PERMISSIONS_SUCCESS,
    payload: permission
  };
};

const getPermissionsFailure = error => {
  return {
    type: CONSTANTS.GET_PERMISSIONS_FAILURE,
    payload: error
  };
};

export const getPartnerDetails = (params, uri) => dispatch => {
  dispatch(getPartnerDetailsBegin);
  apiUtils
    .apiRequest({
      method: "get",
      url: `/b2b/v1/partners/${uri}`
    })
    .then(response => {
      dispatch(getPartnerDetailsSuccess(response.data.response));
    })
    .catch(error => dispatch(getPartnerDetailsFailure(error)));
};

function getPartnerDetailsBegin() {
  return {
    type: CONSTANTS.GET_PARTNERS_BEGIN
  };
}

function getPartnerDetailsSuccess(response) {
  return {
    type: CONSTANTS.GET_PARTNERS_SUCCESS,
    payload: response.customers
  };
}

function getPartnerDetailsFailure() {
  return {
    type: CONSTANTS.GET_PARTNERS_FAILURE
  };
}

export const getVendorDetails = (params, uri) => dispatch => {
  dispatch(getVendorDetailsBegin);
  apiUtils
    .apiRequest({
      method: "get",
      url: `/b2b/v1/partners/${USER_LOGGEDIN_INFO.LOGGEDIN_PARTNER_ID}/vendors`
    })
    .then(response => {
      dispatch(getVendorDetailsSuccess(response.data.vendors));
    })
    .catch(error => dispatch(getVendorDetailsFailure(error)));
};

function getVendorDetailsBegin() {
  return {
    type: CONSTANTS.GET_VENDOR_BEGIN
  };
}

function getVendorDetailsSuccess(response) {
  return {
    type: CONSTANTS.GET_VENDOR_SUCCESS,
    payload: response.customers
  };
}

function getVendorDetailsFailure() {
  return {
    type: CONSTANTS.GET_VENDOR_FAILURE
  };
}

export const setS3SyncStatus = (status) => {
  return dispatch => {
    switch (status) {
      case S3_REPORT_STATUS.BEGIN:
        dispatch(getS3SyncStatusBegin());
        break
      case S3_REPORT_STATUS.SUCCESS:
        dispatch(getS3SyncStatusSuccess());
        break
      case S3_REPORT_STATUS.FAILURE:
        dispatch(getS3SyncStatusFailure());
        break
      default:
        return ''
    }
  }
}

export const setS3SyncStatusReset = () => dispatch => {
  dispatch({
    type: CONSTANTS.S3_SYNC_STATUS_RESET
  })
}

const getS3SyncStatusBegin = () => {
  return {
    type: CONSTANTS.S3_SYNC_STATUS_BEGIN
  }
}
const getS3SyncStatusSuccess = () => {
  return {
    type: CONSTANTS.S3_SYNC_STATUS_SUCCESS
  }
}
const getS3SyncStatusFailure = () => {
  return {
    type: CONSTANTS.S3_SYNC_STATUS_FAILURE
  }
}

export const getCities = () => {
  return dispatch => {
    dispatch(getCitiesBegin());
    MiscService.getCitites()
      .then(response => {
        if (response.data.response) {
          dispatch(getCitiesSuccess(response.data.response));
        }
      })
      .catch(error => dispatch(getCitiesFailure(error)));
  };
};

const getCitiesBegin = () => {
  return {
    type: CONSTANTS.GET_CITIES_BEGIN
  };
};

const getCitiesSuccess = payload => {
  return {
    type: CONSTANTS.GET_CITIES_SUCCESS,
    payload
  };
};

const getCitiesFailure = error => {
  return {
    type: CONSTANTS.GET_CITIES_FAILURE,
    payload: error
  };
};

export const getPincodesByCity = (cityid) => {
  return dispatch => {
    dispatch(getPincodeBegin());
    MiscService.getPincodesByCity(cityid)
      .then(response => {
        if (response.data.response) {
          dispatch(getPincodeSuccess(response.data.response));
        }
      })
      .catch(error => dispatch(getPincodeFailure(error)));
  };
};

const getPincodeBegin = () => {
  return {
    type: CONSTANTS.GET_PINCODE_BEGIN
  };
};

const getPincodeSuccess = payload => {
  return {
    type: CONSTANTS.GET_PINCODE_SUCCESS,
    payload
  };
};

const getPincodeFailure = error => {
  return {
    type: CONSTANTS.GET_PINCODE_FAILURE,
    payload: error
  };
};


export const getSummary = (page) => {
  return dispatch => {
    dispatch(getSummaryBegin());
    MiscService.getSummary(page)
      .then(response => {
        if (response) {
          dispatch(getSummarySuccess(response));
        }
      })
      .catch(error => dispatch(getSummaryFailure(error)));
  };
};

const getSummaryBegin = () => {
  return {
    type: CONSTANTS.GET_SUMMARY_BEGIN
  };
};

const getSummarySuccess = data => {
  return {
    type: CONSTANTS.GET_SUMMARY_SUCCESS,
    payload: data
  };
};

const getSummaryFailure = error => {
  return {
    type: CONSTANTS.GET_SUMMARY_FAILURE,
    payload: error
  };
};

export const getReport = (report) => {
  return dispatch => {
    MiscService.getReports(report)
      .then(response => {
        if (response.data.response) {
          dispatch(getReportuccess(response.data.response));
        }
      })
      .catch(error => dispatch(getReportFailure(error)));
  };
};

const getReportuccess = data => {
  return {
    type: CONSTANTS.GET_REPORT_SUCCESS,
    payload: data
  };
};

const getReportFailure = error => {
  return {
    type: CONSTANTS.GET_REPORT_FAILURE,
    payload: error
  };
};


export const callTpApi = (organisation, eventType) => {
  return dispatch => {
    MiscService.callTpApi(organisation, eventType)
      .then(resp => {
        if (resp.data.status.message === 'SUCCESS') {
          notifyApiSuccess('API Call successfully intialted', 'Done');
        }
      })
  }
}