import React, { useEffect, useState } from "react";
import PodService from "../../../services/pod/pod.service";
import * as RtoDrsService from "../rto-drs/services/rto.drs.service"
import PODForm from "../../pod/pod.form/pod.form";
import { Constants } from "../../../common/utils/constants";
import moment from "moment";
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import { Icon } from "antd";
import "./rto-pod.scss";

import { useSelector } from "react-redux";
import * as Transformer from "../rto-drs/services/transformer";
import { v4 as uuidv4 } from 'uuid';

const podService = new PodService();

const RTOPodForm = ({
    match,
    history
}) => {
    const [drsData, setDrsData] = useState(false);
    const [formData, setFormData] = useState(false);
    const [creating, setCreating] = useState(false);

    const config = useSelector((state) => state?.app?.configurations);

    useEffect(_ => {

        if (match.params.drsId) {

                RtoDrsService.getRtoDrs(match.params.drsId)
                    .then((rtoDrsData) => {

                        if (rtoDrsData.consignments && rtoDrsData.consignments.length > 0) {
                            const tempData = {
                                "status": "DEL",
                            }

                            rtoDrsData['consignments'] = rtoDrsData.consignments.filter((consignment) => {
                                if (consignment?.status === "RTO OUT" || consignment?.status === "RTO_OUT") {
                                    return consignment
                                }
                            })

                            if (rtoDrsData.consignments?.length == 0) {
                                notifyApiError("POD already created for all Waybills!", "WARNING")
                                history.push(`/appv2/rto/dashboard/drs`)
                            }
                            else {
                                setDrsData(Transformer.formatRtoDrsData(rtoDrsData));
                                setFormData(tempData);
                            }
                        }
                        else {
                            notifyApiError("No consignments to create POD ");
                            history.push(`/appv2/rto/dashboard/drs`);
                        }

                    })
                    .catch((error) => {
                        console.error("Error while triggering getRtoDrs", error)
                    })

        }
    }, []);

    const goBack = () => {
        history.push(`/appv2/rto/dashboard/drs`)
    }

    const submitPOD = (pod) => {

        let paymentRadioType = null,
            drsConsignment = drsData.consignments,
            consignments = [];
        setCreating(true);

        drsConsignment.forEach(element => {

                const { relations } = JSON.parse(window.atob(localStorage.getItem("_configrations")));
                const selectedRelation = relations.filter((relation) => relation.relationCode == pod.relationShipStatus)

                if (element.status === "RTO OUT" || element.status === "RTO_OUT") {
                    let consignmentReq = {
                        "podUpdatedDate": moment().valueOf(),
                        "drsCode": drsData.drsCode,
                        "waybillNo": element.waybillNo,
                        "userId": Constants.sessionUser.id,
                        "callDetails": [],
                        "isMobility": false,
                        "time": moment().valueOf(),
                        "shipmentStatus": "RTO" + pod.status,
                        "otpVerified": false,
                    }

                    if (pod.status == 'DEL') {
                        consignmentReq["phoneNumber"] = pod.relationContact
                        consignmentReq['receivedBy'] = pod.receivedBy
                        consignmentReq['relationshipId'] = selectedRelation.length > 0 && selectedRelation[0]?.id ? selectedRelation[0].id : null
                        consignmentReq['relation'] = pod.relationShipStatus
                        consignmentReq['podImpLink'] = pod.podImpLink
                    }

                    if (pod.reasonId) {
                        consignmentReq["reasonId"] = pod.reasonId
                    }

                    if (pod.remarks) {
                        consignmentReq["remarks"] = pod.remarks
                    }

                    let uuid = uuidv4();

                    let rtoPodWaybillReq = {
                        "consignmentPodBO": consignmentReq,
                        'referenceId': element.waybillNo + "-" + uuid
                    }

                    consignments.push(rtoPodWaybillReq)
                }
        });


        if (consignments.length) {
                RtoDrsService.createRtoPod({ "request": consignments })
                    .then((data) => {
                        notifyApiSuccess("POD Created Successfully");
                        history.push("/appv2/rto/dashboard/drs")
                        setCreating(false);
                    })
                    .catch((error) => {
                        console.error("Error while triggering createRtoPod api", error)
                        setCreating(false);
                    })
        }
        else {
            setCreating(false);
            notifyApiError("Consignment should be in RTO_OUT")
        }
    }

    return (
        <div className="flex-column flex-gap-l">
            <div className="flex-box flex-gap-l align-items-center">
                <Icon type="arrow-left" onClick={goBack} />
                <div className="flex-box flex-gap-l align-items-center" style={{ flexWrap: "wrap" }}>
                    <div className="flex-box flex-gap-m align-items-center">
                        <div className="font-size-m-3 text-bold"> Manage POD </div>
                        <div> for </div>
                    </div>
                    <div className="flex-box justify-content-center font-size-l-1 spacer-xs text-bold border-radius-m primary-bg white-color"> {drsData?.drsCode} </div>
                    <div className="flex-box justify-content-center font-size-m-3 spacer-xs text-bold border-radius-m rto-pod-drs-waybills">
                        Waybill(s) :
                        {
                            drsData?.consignments && drsData?.consignments?.length > 0 &&
                            drsData?.consignments?.filter(c => c?.status === "RTO OUT" || c?.status === "RTO_OUT")?.map(c => c.waybillNo).join(", ")
                        }
                    </div>
                </div>
            </div>
            <PODForm submitPOD={submitPOD} rtoDrsPod={true} setFormData={setFormData}
                formData={formData} goBack={goBack} creating={creating} />
        </div>
    );

}

export default RTOPodForm;