import { checkConsignments, checkHttpStatus } from "../../../common/utils/utils";
import DrsService from "../../../services/inventory/drs.service";
import * as _Constants from './constants';
import { deleteDRS, fetchDrses } from "./service";

const drsServices = new DrsService


export const fetchdrs = (payload, isReset) => dispatch => {
    dispatch(fetchdrsBegin());
    var config = localStorage._configrations ? JSON.parse(atob(localStorage._configrations)) : {};
        fetchDrses(payload).
        then(response => {
            dispatch(fetchdrsSuccess(response));
        }).
        catch(response => {
            dispatch(fetchdrsFailure(response));
        })
};
export const updateDrs = (payload) => dispatch => {
    var config = localStorage._configrations ? JSON.parse(atob(localStorage._configrations)) : {};
    if (payload?.request[0]?.action == "DELETE" && true) {
        return deleteDRS(payload?.request[0]?.drsCode)
    }
    return drsServices.updateDrs(payload, "B2C".toLowerCase()).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            return res
        }
    });
};

function fetchdrsBegin() {
    return {
        type: _Constants.FETCH_DRSS_BEGIN
    };
}

function fetchdrsSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_DRSS_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchdrsFailure(error) {
    return {
        type: _Constants.FETCH_DRSS_FAILURE,
        payload: error
    };
}
