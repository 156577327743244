import React from "react";
import {
    Modal,
    Button,
} from "antd";
import { connect } from "react-redux";

const RTODownloadModal = ({
    handleDelete,
    handleCancel,

}) => {
    return(
        <div>
            <Modal
                title="Delete"
                visible
                onOk={handleDelete}
                width={400}
                onCancel={handleCancel}
                footer={[
                    <Button key="close"  onClick={handleCancel}>Close</Button>,
                    <Button key="submit" type="primary" onClick={handleDelete}>
                    Delete
                    </Button>,
                ]}
            >
                <div>
                    <h3>Are you sure?</h3>
                    <p>Do you want to delete this drs?</p>
                </div>
            </Modal>
        </div>

    )
};

const mapStateToProps = (state) => ({
    closeModal: state.rtoDrs,
})

export default connect(
    mapStateToProps, 
)(RTODownloadModal);