import { apiRequest } from "./../common/utils/apiGateway";
import { Constants } from './../common/utils/constants';
import { notifyApiSuccess, notifyApiError } from "../common/utils/utils";

const MiscService = {
    getCitites: () => {
        const url = '/b2b/v1/cities';
        return apiRequest({
            url,
            method: "GET"
        })
    },
    getPincodesByCity: (cityId) => {
        const url = `/b2b/v1/city/${cityId}/pincodes`;
        return apiRequest({
            url,
            method: "GET"
        })
    },
    getAreas: () => {
        const url = '/b2b/v1/areas';
        return apiRequest({
            url,
            method: "GET"
        })
    },
    getReports: (reports) => {
        let url = `/b2b/v1/reports/${reports.type}/get`;
        const user = Constants.sessionUser;
        let params = {
            partnerId: Constants.CURRENT_USER_ID,
            locationId: Constants.userLocationId,
            userId: user.id,
            documentSubType: reports.subtype
        };

        if (reports.params !== undefined) {
            params = Object.assign(params, reports.params);
        }

        return apiRequest({
            method: 'POST',
            url,
            data: params
        });
    },
    getServiceableAreaPinCodes: () => {
        const url = `/b2b/v1/serviceable-areas`;
        return apiRequest({
            method: 'GET',
            url,
        });
    },
    getSummary: (page = null) => {
        let summary = '';
        switch (page) {
            case 'LEADS': {
                summary = 'OPEN_LEADS,LEADS_ADDED_TODAY,LIVE_DRS';
                break;
            }
            case 'INBOUND_SCAN': {
                summary = 'PENDING_CONSIGNMENT_LOC';
                break;
            }
            case 'INBOUND_EXPECTED': {
                summary = 'EXPECTED_TODAY_LINE_HAUL_LOC,AVAILABLE_TRUCKS,LIVE_PICKUPS';
                break;
            }
            case 'INVENTORY_AWB': {
                summary = 'PENDING_CONSIGNMENT_LOC';
                break;
            }
            case 'INVENTORY_BAG': {
                summary = 'PENDING_BAG,BAG_ADDED_TODAY,PENDING_CONSIGNMENT_LOC';
                break;
            }
            case 'INVENTORY_DRS': {
                summary = 'OPEN_DRS,DRS_ADDED_TODAY,PENDING_CONSIGNMENT_LOC';
                break;
            }
            case 'INVENTORY_MANIFEST': {
                summary = 'PENDING_MANIFEST,MANIFEST_ADDED_TODAY,PENDING_CONSIGNMENT_LOC';
                break;
            }
            case 'INVENTORY_MANIFEST_B2C': {
                summary = 'PENDING_MANIFEST_B2C,MANIFEST_B2C_ADDED_TODAY,PENDING_BAG';
                break;
            }
            case 'INVENTORY_HIRE_TRUCK': {
                summary = 'PENDING_MANIFEST,PENDING_CONSIGNMENT_LOC,AVAILABLE_TRUCKS';
                break;
            }
            case 'TRACKING_LINE_HAUL': {
                summary = 'TOTAL_LIVE_TRUCKS,HALTED_TRUCKS,TRUCKS_REACHED';
                break;
            }
            case 'TRACKING_INTRACITY': {
                summary = 'TOTAL_LIVE_TRUCKS,HALTED_TRUCKS,TRUCKS_REACHED';
                break;
            }
            case 'POD_DRS': {
                summary = 'OPEN_DRS,PENDING_CONSIGNMENT_LOC';
                break;
            }
            case 'POD_LIVE': {
                summary = 'OPEN_DRS,PENDING_CONSIGNMENT_LOC';
                break;
            }
            case 'POD_HIRE_TRUCK': {
                summary = 'PENDING_CHALLANS,PENDING_MANIFEST';
                break;
            }
            case 'BILLING_ZONE': {
                summary = 'ACTIVE_ZONES';
                break;
            }
            case 'BILLING_PRODUCT': {
                summary = 'ACTIVE_PRODUCTS,PRODUCTS_ADDED_TODAY';
                break;
            }
            case 'BILLING_RATE_CARD': {
                summary = 'ACTIVE_RATES,RATE_CARDS_ADDED_TODAY';
                break;
            }
            case 'BILLING_PENDING_AWB': {
                summary = 'ACTIVE_ZONES,LEADS_ADDED_TODAY,LIVE_DRS';
                break;
            }
            case 'BILLING_INVOICES': {
                summary = 'ACTIVE_ZONES,LEADS_ADDED_TODAY,LIVE_DRS';
                break;
            }
            case 'BILLING_P_L': {
                summary = 'ACTIVE_ZONES,LEADS_ADDED_TODAY,LIVE_DRS';
                break;
            }
            case 'SETTINGS_CUSTOMER': {
                summary = 'ACTIVE_CUSTOMERS,INACTIVE_CUSTOMERS,CUSTOMERS_COUNT';
                break;
            }
            case 'SETTINGS_VENDOR': {
                summary = 'ACTIVE_USERS,LEADS_ADDED_TODAY,LIVE_DRS';
                break;
            }
            case 'SETTINGS_ASSET': {
                summary = 'ACTIVE_ASSETS,LIVE_ASSESTS,IDLE_ASSETS';
                break;
            }
            case 'SETTINGS_USER': {
                summary = 'ACTIVE_ROUTES,UNDER_PERFORMING_ROUTES,LIVE_DRS';
                break;
            }
            case 'SETTINGS_ROUTE': {
                summary = 'ACTIVE_ROUTES,UNDER_PERFORMING_ROUTES,LIVE_DRS';
                break;
            }
            case 'SETTINGS_LOCATION': {
                summary = 'ACTIVE_LOCATIONS,LEADS_ADDED_TODAY,LIVE_DRS';
                break;
            }
            case 'RTO_WAYBILL': {
                summary = 'RTO_WAYBILLS_PENDING';
                break;
            }
            case 'RTO_DRS': {
                summary = 'RTO_WAYBILLS_PENDING,RTO_DRS_ADDED_TODAY,RTO_OFD';
                break;
            }
            case 'RTO_BAG': {
                summary = 'RTO_BAGGED,RTO_DEBAGGED,RTO_BAG_OUT_SCANNED';
                break;
            }
            case 'RTO_MANIFEST': {
                summary = 'RTO_MANIFEST_PENDING,RTO_MANIFEST_ADDED_TODAY';
                break;
            }
            case 'PENDING_AWB': {
                summary = 'PENDING_INVOICE_AWB,INVOICED_TODAY,INVOICE_PAYABLE';
                break;
            }
            case 'IN_CARD_HO_DATA': {
                summary = 'PARTNER_IN_DEPOSIT,PARTNER_IN_PENDING_DEPOSIT';
                break;
            }
            case 'OUT_CARD_HO_DATA': {
                summary = 'PARTNER_OUT_PENDING_DEPOSIT,PARTNER_OUT_DEPOSITED';
                break;
            }
            case 'IN_CARD_BRANCH_DATA': {
                summary = 'BRANCH_COLLECTED, BRANCH_IN_PENDING_AMOUT, BRANCH_IN_REMITTED_AMOUNT';
                break;
            }
            case 'OUT_CARD_BRANCH_DATA': {
                summary = 'BRANCH_OUT_AMOUT_PENDING_DEPOSIT, BRANCH_OUT_DEPOSIT_PAYABLE';
                break;
            }
            case 'INVOICE': {
                summary = 'INVOICED_TODAY';
                break;
            }
            case 'RTS_WAYBILL': {
                summary = 'RTS_WAYBILLS_PENDING';
                break;
            }
            case 'RTS_DRS': {
                summary = 'RTS_DRS_PENDING,RTS_WAYBILLS_PENDING,RTS_DRS_ADDED_TODAY';
                break;
            }
            default: {
                summary = null;
                break;
            }
        }
        if (summary == null) {
            return null;
        }
        const url = '/b2b/v1/fetch/summary';
        const params = { summaryIds: summary };
        const promise = new Promise((response, reject) => {
            apiRequest({ url, params, method: 'GET' }).then((responseData) => {
                if (responseData == null) {
                    response(null);
                } else {
                    const summaries = responseData?.data?.response?.summaryResult;
                    const summaryObj = getSummaryDetails(summaries);
                    response(summaryObj);
                }
            });
        });
        return promise;
    },

    getContactDetails: (contactNumber) => {
        const url = `/b2b/v1/contacts/${contactNumber}`;
        return apiRequest({
            url,
            method: "GET"
        })
    },
    getPincodeDetails: (pincode) => {
        const url = `/b2b/v1/pincodes/${pincode}`;
        return apiRequest({
            url,
            method: "GET"
        })
    },
    validateSyncData: (data, showMessage = true) => {
        let allSyncStatus = true;
        let reason = '<ul>';
        data.forEach(element => {
            if (element.sync === false) {
                allSyncStatus = false;
                if (element.reason) {
                    reason = reason + `<li>${element.reason}</li>`;
                }
            }
        });
        reason = reason + '</ul>';
        if (showMessage) {
            if (allSyncStatus) {
                notifyApiSuccess('', 'Success');
            } else {
                notifyApiError(reason, 'Error');
            }
        }
        return allSyncStatus;
    },
    callTpApi: (organisation, eventType) => {
        const url = `/v1/tp/organisations/${organisation}/partners/${Constants.PARTNER_ID}/${eventType}`;
        // this.apiGateway.get(url).then(responseData => {
        //     if (responseData) {
        //         this.putToastMsg('Done', 'API Call successfully intialted', ToastMsgTypes.success);
        //     }
        // });
        return apiRequest({
            method: 'GET',
            url,
        });
    },

    getCode: (code) => {
        const url = '/b2b/v1/fetch/code';
        return apiRequest({
            method: 'GET',
            url,
            params: { code }
        });
    }

}

function getSummaryDetails(summary) {
    const returnSummary = {};
    summary.forEach(item => {
        const key = item.type;
        returnSummary[item.type] = { count: item.count, downloadLink: '' };
    });
    return returnSummary;

}

export default MiscService