
import { apiRequest } from '../../common/utils/apiGateway';

export default class ScanService {

  consigmentScan(data) {
    var url = `/b2b/v1/consignments/scan`;
    if (localStorage._configrations && JSON.parse(atob(localStorage._configrations))?.useNewScan) {
      url = `/b2b/v1/consignments/scanNew`;
    }
    return apiRequest({
      url,
      method: "POST",
      data
    });
    // return this.apiGateway.post(url, data, HostTypes.ERP_ID);
  }

  consigmentUnassigned(params) {
    const url = `/b2b/v1/consignments/unassigned`;
    return apiRequest({
      url,
      method: "GET",
      params
    });
  }

  consignmentUpdate(data) {
    const url = `/b2b/v1/consignments/update`;
    return apiRequest({
      url,
      method: "POST",
      data
    });
  }

  consignmentLiteAction(params) {
    const url = `/b2b/v1/uploads/liteBooking`;
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }

  getAwbCode(params) {
    const url = `/b2b/v1/partners/waybill`;
    return apiRequest({
      url,
      method: "GET",
      params
    });
  }

  getConsignment(id) {
    const url = `/b2b/v1/consignments/${id}`;
    return apiRequest({
      url,
      method: "GET"
    });
  }

  rtoUnassigned(params) {
    const url = `/b2c/v1/rto/unassigned`;
    return apiRequest({
      url,
      method: "GET",
      params
    });
  }

  rtoUnassignedPaginated(params) {
    const url = `/b2c/v1/rto/unassigned`;
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }

  rtsUnassigned(params) {
    const url = `/b2c/v1/rto/unassigned`;
    return apiRequest({
      url,
      method: "GET",
      params
    });
  }

  rtsUnassignedPaginated(params) {
    const url = `/b2c/v1/rto/unassigned`;
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }

  validateBarcodes(data) {
    const url = `/b2b/v1/barcodes/valid`;
    return apiRequest({
      url,
      method: "POST",
      data
    });
  }

  expected(queryString) {
    const url = `/b2b/v1/fetch/expected?${queryString}`;
    return apiRequest({
      url,
      method: "POST"
    });
  }

  /**
   * fetch all expected waybills based filter payload
   * @param payload
   */
  fetchExpectedConsignments(payload) {
    const url = `/b2b/v1/fetch/expected`;
    return apiRequest({
      url,
      method: "POST",
      data: payload
    });
  }

  fetchConsigmentUnassigned(params) {
    const url = `/b2b/v1/consignments/unassigned`;
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }

  uploadBooking(params) {
    const url = `/b2b/v1/uploads/booking`;
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }

  getPartnerCenter(params) {
    const url = `/b2c/v1/rto/drs/locations`;
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }

  uploadPickup(params) {
    const url = "/b2b/v1/uploads/pickup";
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }

  uploadInscan(params) {
    const url = "/b2b/v1/uploads/inscan";
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }

  uploadRtoRtsBooking(params) {
    const url = `/b2b/v1/uploads/update_status`;
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }

  uploadBookingManifest(params) {
    const url = `/b2b/v1/uploads/manifest`;
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }

  uploadBookingDrs(params) {
    const url = `/b2b/v1/uploads/drs`;
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }
  getManifest(drsId, type = 'b2b') {
    const url = `/${type}/v1/manifest/${drsId}`;
    return apiRequest({
      url,
      method: "GET",

    });
  }

  getWaybills(params) {
    const url = `/b2b/v1/tracking`;
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }

}
