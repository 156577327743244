import RTOServices from "../../../services/rto/rto.service";
import { ACTION_TYPES } from "./constants";
import "antd/dist/antd.css";

import activeAwbIcon0 from "../../../assets/img/icons/images/3_1_Inventory.svg";
import activeAwbIcon1 from "../../../assets/img/icons/images/3_2_Inventory.svg";
import activeAwbIcon2 from "../../../assets/img/icons/images/3_3_Inventory.svg";

import MiscService from "../../../services/misc.service";
import { DownloadTags, ReportTypes } from "../../../common/utils/constants";
import { reportsDownload } from "../../../common/utils/utils";
const rtoServices = new RTOServices();

export const getConsignmentsData = (payload, isReset=false) => dispatch => {
    dispatch(fetchConsignmentsBegin());

    rtoServices.getAllRTOConsignments(payload)
        .then((res) => {
            const response = res.data.response;
            dispatch(fetchConsignmentsSuccess(response, isReset));
        })
        .catch(err => {
            dispatch(fetchConsignmentsFailure(err));
        })
};

const fetchConsignmentsBegin = () => {
    return {
        type: ACTION_TYPES.FETCH_CONSIGNMENTS_BEGIN
    }
};

const fetchConsignmentsSuccess = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_CONSIGNMENTS_SUCCESS,
        payload
    }
};

const fetchConsignmentsFailure = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_CONSIGNMENTS_FAILURE,
        payload
    }
};


export const getSparkLineDataFunction = () => dispatch => {
    dispatch(fetchSparkLineDataBegin());
    
    MiscService.getSummary("RTO_WAYBILL")
        .then((summaryDetails) => {
            const dataMapping = {
                "RTO_WAYBILLS_PENDING": {
                    icon: activeAwbIcon0,
                    title: "Pending RTO Waybill"
                },
                "RTO_SLA_BREACH_APPROACHING": {
                    icon: activeAwbIcon1,
                    title: "SLA Breach Approaching"
                },
                "RTO_SLA_BREACHED": {
                    icon: activeAwbIcon2,
                    title: "SLA Breached"
                }
            }
                let dataCardList = [];
                Object.entries(summaryDetails).forEach((summaryDetail, index) => {
                    const key = summaryDetail[0];
                    const value = summaryDetail[1];
                    dataCardList.push({
                        icon: dataMapping[key].icon,
                        title: dataMapping[key].title,
                        description: value.count, 
                        count: value.count, 
                        downloadType: key,
                        documentType: key
                    })
                })
                dispatch(fetchSparkLineDataSuccess(dataCardList));
        })
        .catch(err => {
            dispatch(fetchSparkLineDataFailure(err));
        })

};

const fetchSparkLineDataBegin = () => {
    return {
        type: ACTION_TYPES.FETCH_SPARK_LINE_DATA__BEGIN
    }
};

const fetchSparkLineDataSuccess = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_SPARK_LINE_DATA__SUCCESS,
        payload
    }
};

const fetchSparkLineDataFailure = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_SPARK_LINE_DATA__FAILURE,
        payload
    }
};

export const getReports= () => {
    MiscService.getReports({type: ReportTypes.awb})
    .then( responseData => {
            if(responseData){
                reportsDownload(responseData.data.response.url,DownloadTags.s3);
            }
        }
    )
}

export const resetPaginatedCacheData = () => dispatch => {
    dispatch(resetPaginatedCacheDataDispatcher());
};

const resetPaginatedCacheDataDispatcher = () => {
    return {
        type: ACTION_TYPES.RESET_PAGINATED_CACHE_DATA
    };
};