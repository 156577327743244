import { apiRequest, thirdPartyGet } from "../../../common/utils/apiGateway";
import { DownloadTags } from "../../../common/utils/constants";
import { apiService } from "../../../common/apiService/service";
import { checkHttpStatus } from "../../../common/utils/utils";


const startPollingForResponseFile = url => new Promise(resolve => {
    var pollingForResponse = setInterval(_ => {
        thirdPartyGet(url, DownloadTags.type)
            .then(response => {
                window.open(url, "blank");
                clearInterval(pollingForResponse);
                setTimeout(() => {
                    resolve(response);
                }, 250);
            })
            .catch(err => {
                console.log(err);
            })
    }, 1500)
})

export const fetchRTOUploadList = () => {
    return apiService.get("hub/rto/upload/list")
}

export const createRTO = (reqBody) => {
    return apiService.post("hub/rto/upload", reqBody)
}

export const deleteUploadedRTOFileEntry = (uploadId) => {
    return apiService.delete(`hub/rto/upload/${uploadId}`)
}