import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import store from './utils/store';
import RootLayout from './RootLayout';
import "./app/app.scss";
import appRoutes from "./routes";
import WrappedRoute from "./WrapperdRoute";
import Redirect from "./Redirect";
import MobileHome from "./components/mweb/mobile.home";
import Login from "./auth/Login";
import LoginForm from "./components/login/login";
import ReactDOM from 'react-dom';
import { getRedirectedUrl } from './common/utils/utils';
import { Constants } from './common/utils/constants';

if (Constants.isProdEnv) {
  const clarityScript = document.createElement("script");
  clarityScript.type = "text/javascript"
  clarityScript.innerHTML = `(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "do09msyswr");`
  window.document.head.appendChild(clarityScript);

//   const gaScript = document.createElement("script");
//   gaScript.async = true;
//   gaScript.src="https://www.googletagmanager.com/gtag/js?id=G-RHZETF2PTF";

//   const gaConfig = document.createElement("script");
//   gaConfig.innerHTML = `
//       window.dataLayer = window.dataLayer || [];
//       function gtag(){dataLayer.push(arguments);}
//       gtag('js', new Date());
//   +
//       gtag('config', 'G-RHZETF2PTF');
//   `
//   window.document.head.appendChild(gaScript);
//   window.document.head.appendChild(gaConfig);
}

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Routes>
                <Route element={<Login/>} path="/appv2/login" />
                <Route element={<LoginForm/>} path="/appv2/legacy-login" />
                <Route element={<Redirect to={getRedirectedUrl()}/>} path="*" />
                <Route element={<RootLayout/>} path="appv2/">
                    {
                        appRoutes.map((r, i) => {
                            return <Route key={i} element={<WrappedRoute {...r} />} path={r.path} />
                        })
                    }
                    <Route element={<Redirect to="/appv2/mweb/home"/>} path="/appv2/mweb" />
                    <Route key={appRoutes.length} path={"/appv2/mweb/home"}
                     element={<WrappedRoute component={MobileHome} noSideBar={true} noTabs={true} isMobile={true} />}/>
                </Route>
            </Routes>
        </Router>
    </Provider>,
    document.getElementById("root")
);
