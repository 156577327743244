import { apiRequest } from "../../../../common/utils/apiGateway"

export const validateUnloadTrip = tripId => new Promise((resolve, reject) => {
    apiRequest({
        url    : "/hydra/v1/trip/unload/validate",
        method : "POST",
        data   : { tripId }
    })
    .then(response => {
        resolve(response?.data?.response);
    })
    .catch(response => {
        reject(response);
    })
})

export const updateManifestReason = params => new Promise((resolve, reject) => {
    apiRequest({
        url    : "/b2c/v1/manifest/update/reason",
        method : "POST",
        data   : params
    })
    .then(response => {
        resolve(response);
    })
    .catch(exp => reject(exp))
})

export const fetchReasons = _ => new Promise((resolve, reject) => {
    apiRequest({
        url    : "/b2c/v1/manifest/reason",
        method : "GET"
    })
    .then(response => {
        resolve(response?.data?.response)
    })
    .catch(exp => reject(exp))
})

export const fetchTripManifest = (tripId, status, params) => new Promise((resolve, reject) => {
    apiRequest({
        url    : "/b2c/v1/connection/manifest/fetch",
        method : "POST",
        data   : {
            pageNo         : params && params.pageNo ? params.pageNo : 1,
            pageSize       : params && params.pageSize ? params.pageSize :  10,
            manifestCode   : params && params.manifestCode ? params.manifestCode : undefined,
            tripId         : tripId,
            manifestStatus : status
        }
    })
    .then(response => {
        resolve(response?.data?.response)
    })
    .catch(exp => reject(exp))
})

export const unloadManifestTrip = (tripId, code) => new Promise((resolve, reject) => {
    apiRequest({
        url    : "/hydra/v1/trip/unload",
        method : "POST",
        data   : {
            scanEntityType : "MANIFEST",
            code           : code,
            tripId         : tripId
        },
        ignoreStatus : true,
        showMessage  : false
    })
    .then(response => {
        resolve(response?.data)
    })
    .catch(exp => reject(exp))
})