import { Button } from "antd";
import moment from "moment";
import { useState } from "react";
import { Constants } from "../../../../common/utils/constants";
import { SETTLEMENT_ACTIVITY_COLUMN } from "../../transform.data";
import { userType } from "../../utils";
import RemitForm from "../forms/remit.form";
import ViewCoDActiviy from "../view/view.activity";

export default function RemitActionColumn ({ entityId, rootPartnerData, actions, groupCriteria, parentEntityId }) {
    
    const [ openModal, SetOpenModal ] = useState(false);
    const [ fetchObject, SetFetchObject ] = useState(undefined);
    const [ openActivityModal, SetOpenActivityModal ] = useState(false);

    return (
        <div className="flex-box flex-gap-m">
            {
                groupCriteria === "ROOT_PARTNER" &&
                <Button onClick={_ => SetOpenModal(true)} className="lsn-btn-secondary"> Remit </Button>
            }
            {
                !(userType() === "PARTNER_ADMIN" && groupCriteria === "ROOT_PARTNER") &&
                <Button onClick={ _ => {
                    SetFetchObject({
                        startDate   : moment().subtract(7, "days").startOf("day").valueOf(),
                        endDate     : moment().valueOf(),
                        partnerId   : Constants.PARTNER_ID,
                        locationIds : userType() === "BRANCH" ? Number(Constants.LOGGED_PARTNER_LOCATION_ID) : [ entityId ],
                        approvalType  : [ "BRANCH_PARTNER", "BRANCH_CUSTOMER" ],
                        customerIds   : userType() === "BRANCH" ? [ entityId ] : undefined
                    });
                    SetOpenActivityModal(true);
                }} className="lsn-btn-secondary">
                    View Activity
                </Button>
            }
            
            {
                openActivityModal &&
                <ViewCoDActiviy rowData={rootPartnerData} fetchObject={fetchObject} viewType="BRANCH"
                 openActivityModal={openActivityModal} SetOpenActivityModal={SetOpenActivityModal}
                 columns={SETTLEMENT_ACTIVITY_COLUMN().filter(c => !c.hideInRootPartnerView)}/>
            }
            
            {
                openModal &&
                <RemitForm onSuccess={_ => {
                    if (typeof actions?.onRemitSuccess === "function") {
                        actions?.onRemitSuccess();
                    }
                }} openModal={openModal} SetOpenModal={SetOpenModal} partner={{
                    id   : Constants.PARTNER_ID,
                    name : Constants.PARTNER_NAME
                }} customerId={entityId} entityId={entityId} locationId={parentEntityId} rowData={{
                    ...rootPartnerData,
                    netOutstanding  : rootPartnerData.amountMap.NET_OUTSTANDING,
                    pendingApproval : rootPartnerData.amountMap.PENDING_FOR_APPROVAL
                }} />
            }

        </div>
    )
}