export const ENTITY_TYPES = {
    pickrr: {
        label: "PICKRR",
        name: "pickrr",
    },
    meesho: {
        label: "MEESHO",
        name: "meesho",
    }
};

export const INITIAL_STATE = {
    loading: false,
    error: "",
    selectedCustomer: undefined,
    selectedUploadBooking: null,
    uploadedBookings: [],
    customerList: [],
    s3path: "",
};

export const ACTION_TYPES = {
    UPLOAD_PICKUP_FILE_BEGIN: "UPLOAD_PICKUP_FILE_BEGIN",
    UPLOAD_PICKUP_FILE_SUCCESS: "UPLOAD_PICKUP_FILE_SUCCESS",
    UPLOAD_PICKUP_FILE_FAILURE: "UPLOAD_PICKUP_FILE_FAILURE",

    SET_SELECTED_CUSTOMER_VALUES: "SET_SELECTED_CUSTOMER_VALUES",
    SET_UPLOADED_BOOKING_FILE: "SET_UPLOADED_BOOKING_FILE",

    RESET_UPLOAD_PICKUP_DATA: "RESET_UPLOAD_PICKUP_DATA",

    CREATE_ASSIGNED_PICKUP_BEGIN: "CREATE_ASSIGNED_PICKUP_BEGIN",
    CREATE_ASSIGNED_PICKUP_SUCCESS: "CREATE_ASSIGNED_PICKUP_SUCCESS",
    CREATE_ASSIGNED_PICKUP_FAILURE: "CREATE_ASSIGNED_PICKUP_FAILURE",

    FETCH_CUSTOMER_LIST_BEGIN: "GET_CUSTOMER_LIST_BEGIN",
    FETCH_CUSTOMER_LIST_SUCCESS: "GET_CUSTOMER_LIST_SUCCESS",
    FETCH_CUSTOMER_LIST_FAILURE: "GET_CUSTOMER_LIST_FAILURE",

    UPDATE_S3_PATH: "UPDATE_S3_PATH",

}