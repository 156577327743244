import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifyApiError } from "../../../../common/utils/utils";
import * as RtoDrsService from "../services/index";
import CreateDRSComponent from "../../../inventory/drs/create.drs.component";
import { Constants, FlowTypes } from "../../../../common/utils/constants";

export default function CreateRTODRS(props) {

    const navigate = useNavigate();

    const [ rtoDrsCode, SetRtoDrsCode ] = useState(`RD-${Constants.userLocationId}-${moment().format("DDMMYYYYhhmmss")}${Math.floor(10 + Math.random() * 90)}`);
    const [ rtoDrsDetails, SetRtoDrsDetails ] = useState({});
    const [ isAllowedToAddWaybills, SetIsAllowedToAddWaybills ] = useState(true);

    useEffect(() => {
        if (props?.match?.params?.rtoDrsCode) {
            SetRtoDrsCode(props?.match?.params?.rtoDrsCode);
            callDRSDetails(props?.match?.params?.rtoDrsCode);
        }

    }, []);

    const callDRSDetails = code => {
        RtoDrsService.fetchDRSDetails(code)
        .then(_drsDetails => {
            SetRtoDrsDetails(_drsDetails);
            if (moment(_drsDetails?.createdAt).startOf("day").diff(moment().startOf("day"), "days") != 0 ) {
                SetIsAllowedToAddWaybills(false);
                notifyApiError("Cannot edit DRS created earlier than today!");
            }
            else if (["COMPLETED", "CLOSED"].indexOf(_drsDetails?.drsStatus) > -1 ) {
                SetIsAllowedToAddWaybills(false);
                notifyApiError("DRS has been " + _drsDetails?.drsStatus.toLowerCase() + ". Cannot be edited.");
            }
        })
        .catch(response => {
            if (response.errorCode === "E102") {
                notifyApiError("DRS not found!");
                navigate("/appv2/rto/dashboard/drs")
            }
        })
    }

    const createDRS = drsUser => {
        const postObject = {
            rtoDrsCode   : rtoDrsCode,
            rtoDrsUserId : drsUser?.key
        };
        return RtoDrsService.createRTODRS(postObject)
    }

    const generateDRSCode = _ => SetRtoDrsCode((`RD-${Constants.userLocationId}-${moment().format("DDMMYYYYhhmmss")}${Math.floor(10 + Math.random() * 90)}`));

    const fetchUnassignedCount = _ => RtoDrsService.fetchUnassignedCount()
    
    const fetchUnassignedList = (pageNo = 1) => RtoDrsService.fetchUnassignedList(pageNo, 5);

    const scanWaybill = waybill => RtoDrsService.linkAWBtoDRS(rtoDrsDetails?.rtoDrsCode, waybill);

    const delinkWaybill = waybill => RtoDrsService.delinkAWBfromDRS(rtoDrsDetails?.rtoDrsCode, waybill);

    return (
        <>
            <CreateDRSComponent drsDetails={{
                drsCode   : rtoDrsDetails?.rtoDrsCode,
                drsStatus : rtoDrsDetails?.rtoDrsStatus,
                drsUser   : rtoDrsDetails?.rtoDrsUser,
                consignments : rtoDrsDetails?.consignments
            }} isAllowedToAddWaybills={isAllowedToAddWaybills}
             drsCode={rtoDrsCode} flowType={FlowTypes.RTO} generateDRSCode={generateDRSCode}
             callDRSDetails={callDRSDetails} SetDrsDetails={SetRtoDrsDetails} createDRS={createDRS} SetDRSCode={SetRtoDrsCode}
             delinkWaybill={delinkWaybill} scanWaybill={scanWaybill} callUploadDrs={RtoDrsService.uploadDrs}
             fetchUnassignedCount={fetchUnassignedCount} fetchUnassignedList={fetchUnassignedList} isAllowedToDelinkWaybills={true} />
        </>
    )
}