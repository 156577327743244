import moment from "moment";
import { Constants, USER_LEVEL_HIGER_PARTNERS } from "../../common/utils/constants";
import { DATE_RANGE_MAPPING_KEYS, entityColumns, groupCriteriaCoulmns } from "./transform.data";

export const userType = _ => {
    if (localStorage.user)  {
        const user = JSON.parse(atob(localStorage.getItem("user")));
        const role = user.userLevel;

        if (USER_LEVEL_HIGER_PARTNERS.indexOf(role) > -1) {
            if ([268,269].indexOf(Number(Constants.PARTNER_ID)) > -1 ) {
                return "ROOT_PARTNER"
            }
            else {
                return "PARTNER_ADMIN"
            }
        }
        else if (role === "BRANCH") {
            return "BRANCH"
        }
    }
}

export const convertAmountMappings = (mappingArray, fromTimeStamp, toTimeStamp) => {
    var dateRangeMap = {};
    mappingArray.map(m => {
        if (DATE_RANGE_MAPPING_KEYS[m]) {
            dateRangeMap[m] = DATE_RANGE_MAPPING_KEYS[m].timeline;
            if (DATE_RANGE_MAPPING_KEYS[m].overWriteWithTimesStamps && (fromTimeStamp || toTimeStamp)) {
                dateRangeMap[m] = {
                    from : fromTimeStamp,
                    to   : toTimeStamp,
                }
            }
        }
    })
    return dateRangeMap;
}

export const fetchColumnsBasedOnPostObject = (data, actions) => {
    var columns = [ ...entityColumns[`GROUP_CRITERIA_${data.groupCriteria}_COLUMNS`] ];
    var dateRangeMap = Object.keys(data.filters.dateRangeMap);
    dateRangeMap.map(range => {
        if (DATE_RANGE_MAPPING_KEYS[range]) {
            columns.push({
                title : DATE_RANGE_MAPPING_KEYS[range].title,
                key   : range,
                dataIndex : `amountMap.${range}`,
                render    : DATE_RANGE_MAPPING_KEYS[range].render
            })
        }
    })
    if (groupCriteriaCoulmns[data.groupCriteria]) {
        columns = [ ...columns, ...groupCriteriaCoulmns[data.groupCriteria](actions) ];
    }
    return [ ...columns ];
}

export const genrateFetchObject = data => {
    
    var dateRangeMap = data.dateRangeMap ? data.dateRangeMap :  [ "NET_OUTSTANDING", "PENDING_FOR_APPROVAL"] ;
    const fromTimeStamp = data.fromTimeStamp ? data.fromTimeStamp : undefined;
    const toTimeStamp   = data.toTimeStamp   ? data.toTimeStamp   : moment().valueOf();

    return {
        filters : {
            searchText  : data.searchText,
            partnerIds  : data.partnerIds  ? data.partnerIds  : [],
            locationIds : data.locationIds ? data.locationIds : [],
            rootPartnerIds : data.rootPartnerIds ? data.rootPartnerIds : [],
            dateRangeMap   : convertAmountMappings(dateRangeMap, fromTimeStamp, toTimeStamp),
            remittanceStatus : [ "PENDING", "PARTIAL_APPROVE", "REQUEST_FOR_APPROVAL" ],
        },
        groupCriteria : data.groupCriteria ? data.groupCriteria : "PARTNER",
        fromTimeStamp : data.fromTimeStamp ? data.fromTimeStamp : undefined,
        toTimeStamp   : data.toTimeStamp   ? data.toTimeStamp   : moment().valueOf()
    }
}

export const convertResponseToView = repsonse => {
    console.log(repsonse);
}