import React, { Component } from 'react';
import './audit-notice.scss';
import * as actions from '../action';
import { connect } from 'react-redux';
import { openNotification, destroyNotification } from '../../../ui/notification/Notification';
import { Constants } from '../../../../common/utils/constants';
import { Modal, Button } from 'antd';

class AuditNotice extends Component {

  state = {
    showModal: false,
    username: ''
  }

  componentDidMount() {
    this.props.getAuditStatus({ locationId: Constants.LOGGED_PARTNER_LOCATION_ID });
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auditStatus && nextProps.auditStatus.username !== this.props.auditStatus?.username) {
      this.setState({
        username: nextProps.auditStatus.username
      })
    }
    if (nextProps.auditStatus && nextProps.auditStatus.isAuditOn !== this.props.auditStatus?.isAuditOn) {
      if (nextProps.auditStatus.isAuditOn) {
        destroyNotification()
        openNotification({
          message: 'Information',
          description: 'Audit is still in progress',
          className: 'error-notification',
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userInfo && this.props.userInfo.loggedPartnerId !== prevProps.userInfo?.loggedPartnerId) {
      this.recheckAuditStatus();
    }
  }

  recheckAuditStatus = () => {
    this.props.getAuditStatus({ locationId: Constants.LOGGED_PARTNER_LOCATION_ID });
  }


  render() {
    return ((this.props.auditStatus?.isAuditOn) && (!this.props.location?.pathname.includes("/appv2/inventory/form/audit"))) ? (
      <React.Fragment>
        <div className="Audit-Notice">
          <p>
            Some of the operations are halted because
        <span> {this.state.username || 'NA'} </span>
            initiated Audit , <span className="clickHere" onClick={this.toggleModal}> click  here </span> to know more
           </p>
          <div className="recheck">
            <Button type="primary"
              onClick={this.recheckAuditStatus}
              loading={this.props.loading}
            >Re-Check Audit Status</Button>
          </div>
        </div>
        <Modal
          wrapClassName="audit-modal"
          onCancel={this.toggleModal}
          onOk={this.toggleModal}
          visible={this.state.showModal}
          footer={null}>
          <div className="header">AUDIT IN PROGRESS</div>
          <p>When the audit is in progress at your location, you are not allowed to receive or dispatch shipments from your location. Please wait until the audit operation get completed Any further queries please reach out to LSN</p>
          <div className="footer">
            <Button
              className="btn"
              onClick={this.toggleModal}
            >Got It</Button>
          </div>
        </Modal>
      </React.Fragment>
    ) : ''
  }
}

const mapStateToProps = state => ({
  auditStatus: state.audit?.auditStatus,
  loading: state.audit?.auditStatus?.loading
})

const mapDispatchToProps = dispatch => ({
  getAuditStatus: (param) => dispatch(actions.getAuditStatus(param)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuditNotice);
