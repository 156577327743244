import { Button } from "antd";
import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import NumberFormat from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import logo from '../assets/img/logo_log10.svg';
import * as apiUtils from "../common/utils/apiGateway";
import { checkHttpStatus, getRedirectedUrl, notifyApiError } from "../common/utils/utils";
import { useInterval } from './CustomHookUseInterval';
import "./login.scss";

const LOGIN_STATUS = {
  PAGE_LOADED    : 1,
  OTP_GENERATING : 2,
  OTP_GENERATED  : 3,
  WAIT_TIME      : 4,
  RESEND_OTP_ON  : 5,
  OTP_RESENT     : 6,
  CALL_FOR_OTP   : 7,
  SIGNING_IN     : 8
}

export default function LoginForm(props) {
  
  const [count, setCount] = useState(0);
  const [seconds, setSeconds] = useState(2);
  const [otpOnCallMobileNumber, SetOtpOnCallMobileNumber] = useState(false);
  const [loginStatus, SetLoginStatus] = useState(LOGIN_STATUS.PAGE_LOADED);
  const [otpInput, SetOtpInput] = useState("");
  const [userNumber, SetUserNumber] = useState("");

  const navigate = useNavigate();

  useEffect(()=> {
    if (window.location.host.indexOf("meesho-atlas") > - 1) {
      window.location.href = "https://log10-atlas.loadshare.net/";
    }
    if (!!localStorage.getItem("accessToken")) {
      navigate(getRedirectedUrl());
    }
  },[]) 

  const getAllPersmission = () => {
    const permissions = localStorage.getItem("permissions");
    if (!permissions) {
      return null;
    }
    return JSON.parse(atob(permissions));
  };

  const store = (permissions) => {
    if (permissions == null) {
      return null;
    }
    localStorage.setItem("permissions", btoa(JSON.stringify(permissions)));
    var _permissions = getAllPersmission();
    const allApplications = Object.keys(_permissions);
    let selectedApplication = allApplications[0];
    localStorage.setItem("application", btoa(selectedApplication));
  };

  const parsePermission = (permissions) => {
    if (!permissions) {
      return null;
    }
    const pObj = {};
    permissions.forEach((permission) => {
      if (!pObj[permission.application]) {
        pObj[permission.application] = {};
      }

      if (!pObj[permission.application][permission.module]) {
        pObj[permission.application][permission.module] = { permissions: [] };
      }

      if (permission.submodule == null) {
        pObj[permission.application][permission.module].permissions.push(
          permission.action
        );
      } else {
        if (
          !pObj[permission.application][permission.module][permission.submodule]
        ) {
          pObj[permission.application][permission.module][
            permission.submodule
          ] = { permissions: [] };
          pObj[permission.application][permission.module][
            permission.submodule
          ] = { permissions: [] };
        }
        pObj[permission.application][permission.module][
          permission.submodule
        ].permissions.push(permission.action);
      }
    });
    return pObj;
  };

  const submitOTPForLogin = _ => {
    var tempStatus = loginStatus;
    SetLoginStatus(LOGIN_STATUS.SIGNING_IN);
    let params = {
      otp    : otpInput,
      number : userNumber,
      callingSystem : "B2B",
    };
    apiUtils.apiRequest({
        url    : "/v1/login/otp",
        method : "POST",
        data   : params,
    })
    .then((_response) => {
      if(!checkHttpStatus(_response?.data?.status)) {
        return SetLoginStatus(tempStatus);
      }
      const response = _response?.data?.response;
      if (!response.user.permissions || response.user.permissions?.length === 0) {
        SetLoginStatus(tempStatus);
        return notifyApiError("User does not have role. Kindly contact your admin.");
      }
      store(parsePermission(response.user.permissions));
      cookie.save("LOGGED_IN", true, { path: "/", secure : true, httpOnly : true });
      localStorage.setItem("primaryAccessToken", response.token.accessToken);
      localStorage.setItem("primaryTokenId", response.token.tokenId);
      localStorage.setItem("accessToken", response.token.accessToken);
      localStorage.setItem("refreshToken", response.token.refreshToken);
      localStorage.setItem("tokenId", response.token.tokenId);
      localStorage.setItem("user", btoa(JSON.stringify(response.user)));
      localStorage.setItem("refreshFlag", JSON.stringify(new Date().valueOf()));
      window.location.href = getRedirectedUrl();
    })
    .catch(error => {
      SetLoginStatus(tempStatus);
      console.log(error)
    });
  };

  const interval = useInterval(() => {      
    if(seconds === 0){
        SetLoginStatus(count ?  LOGIN_STATUS.OTP_RESENT : LOGIN_STATUS.RESEND_OTP_ON);
        clearInterval(interval.current);
    } else {
        setSeconds(s => s - 1)
    }
  }, loginStatus === LOGIN_STATUS.WAIT_TIME ? 1000 : null)

  useEffect(() => () => clearInterval(interval.current), [])

  const generateOTP = () => {
    SetLoginStatus(LOGIN_STATUS.OTP_GENERATING);
    apiUtils.apiRequest({
        method: "GET",
        url: `/v1/otp/${userNumber}`,
    })
    .then((response) => {
      if (!checkHttpStatus(response?.data?.status)) {
        return SetLoginStatus(LOGIN_STATUS.PAGE_LOADED)
      }
      SetOtpOnCallMobileNumber(response?.data?.otpOnCallMobileNumber);
      SetLoginStatus(LOGIN_STATUS.WAIT_TIME);
    })
    .catch(_ => SetLoginStatus(LOGIN_STATUS.PAGE_LOADED))
  };

  const resendOTP = () => {
    SetLoginStatus(LOGIN_STATUS.OTP_GENERATING);
    apiUtils.apiRequest({
        url    : `/v1/otp/${userNumber}`,
        method : "GET"
    })
    .then((response) => {
      if (!checkHttpStatus(response?.data?.status)) {
        SetLoginStatus(LOGIN_STATUS.PAGE_LOADED)
      }
      SetLoginStatus(LOGIN_STATUS.WAIT_TIME);
      setCount(count => count + 1);
      setSeconds(2);
    })
    .catch(_ => SetLoginStatus(LOGIN_STATUS.PAGE_LOADED))
  };

  return (
    <div className="flex-box" style={{ height : "100vh" }}>
      <div className="flex-1 flex-column flex-gap-l align-items-stretch justify-content-center">
        <div className="flex-box justify-content-center">
          <img className="logo-otp" src={logo} alt="logo" width={"40%"} style={{ minWidth : 200 }} />
        </div>
        <div className="flex-box justify-content-center">
          <h1 id="title-opt">Login</h1>
        </div>
        <div className="flex-box justify-content-center">
          <div className="flex-column spacer-vl flex-gap-xl align-items-stretch" style={{ width : "50%", minWidth : 250 }}>
            <NumberFormat onValueChange={valueObj => SetUserNumber(valueObj.value)}
              value={userNumber} disabled={loginStatus >= LOGIN_STATUS.OTP_GENERATING}
              format="###-###-####" type="tel"
              placeholder="Phone Number" className="ant-input" />

            {
              loginStatus >= LOGIN_STATUS.OTP_GENERATED &&
              <NumberFormat placeholder="Enter OTP Received" format="####"
                value={otpInput} onValueChange={valueObj => SetOtpInput(valueObj.value)}
                className="ant-input" />
            }

            {
              loginStatus >= LOGIN_STATUS.OTP_GENERATED ?
              <Button className="lsn-btn-primary" type="primary" onClick={submitOTPForLogin}
                htmlType="submit" disabled={userNumber.length != 10} loading={loginStatus === LOGIN_STATUS.SIGNING_IN} >
                Sign In
              </Button> : 
              <Button className="lsn-btn-primary" type="primary" onClick={generateOTP}
                htmlType="submit" disabled={userNumber.length != 10} loading={loginStatus === LOGIN_STATUS.OTP_GENERATING} >
                Submit
              </Button>
            }

            {
              loginStatus > LOGIN_STATUS.OTP_GENERATED  && loginStatus < LOGIN_STATUS.CALL_FOR_OTP &&
              <div className="flex-box justify-content-space-between">
                {
                  count <= 3 ?
                  <>
                    {
                      loginStatus === LOGIN_STATUS.WAIT_TIME &&
                      <Button disabled={true} className="lsn-btn-secondary">
                        Resend OTP in 0:{seconds < 10 ? `0${seconds}` : seconds}
                      </Button>
                    }
                    {
                      loginStatus >= LOGIN_STATUS.RESEND_OTP_ON &&
                      <Button type="link" onClick={resendOTP} className="lsn-btn-secondary">
                        Resend OTP
                      </Button>
                    }
                  </> :
                  <span> Maximum Count exceeded </span>
                }
                {
                  loginStatus === LOGIN_STATUS.OTP_RESENT &&
                  <div className="flex-box justify-content-flex-end">
                    <Button type="link" onClick={_ => SetLoginStatus(LOGIN_STATUS.CALL_FOR_OTP)}
                      style={{ padding : 0 }}>
                      Get OTP on Call
                    </Button>
                  </div>
                }  
              </div> 
            }
            {
              loginStatus == LOGIN_STATUS.CALL_FOR_OTP &&
              <div  className="info-color"> Please call on {otpOnCallMobileNumber} to get the latest OTP </div>
            }
          </div>
        </div>
      </div>
      <div className="flex-1 login-page-right"></div>
    </div>
  );
}
