import { checkHttpStatus } from '../../../common/utils/utils';
import MiscService from '../../../services/misc.service';
import CustomerService from '../../../services/settings/customer.service';
import * as _Constants from "./constants";

export const fetchCustomers = (payload, isReset) => dispatch => {
    dispatch(fetchCustomersBegin());
    CustomerService.getCustomersFetch(payload).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(fetchCustomersSuccess(res?.data?.response, isReset));
        } else {
            dispatch(fetchCustomersFailure(res?.data?.response));
        }
    });
};


export const fetchShippers = (payload, isReset) => dispatch => {
    dispatch(fetchShippersBegin());
    CustomerService.getShippers(payload).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(fetchShippersSuccess(res?.data?.response, isReset));
        } else {
            dispatch(fetchShippersFailure(res?.data?.response));
        }
    });
};

export const deleteCustomer = (id, payload) => dispatch => {
    dispatch(fetchCustomersBegin());
    CustomerService.deleteCustomer(id).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            fetchCustomers(payload);
        } else {
            dispatch(fetchCustomersFailure(res?.data?.response));
        }
    });
}

function fetchCustomersBegin() {
    return {
        type: _Constants.FETCH_CUSTOMERS_BEGIN
    };
}

function fetchCustomersSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_CUSTOMERS_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchCustomersFailure(error) {
    return {
        type: _Constants.FETCH_CUSTOMERS_FAILURE,
        payload: error
    };
}


function fetchShippersBegin() {
    return {
        type: _Constants.FETCH_SHIPPER_BEGIN
    };
}

function fetchShippersSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_SHIPPER_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchShippersFailure(error) {
    return {
        type: _Constants.FETCH_SHIPPER_FAILURE,
        payload: error
    };
}

export const getOrganisations = () => dispatch => {
    dispatch(fetchOrganisationsBegin());
    CustomerService.getOrganisations().then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(fetchOrganisationsSuccess(res?.data?.response));
        } else {
            dispatch(fetchOrganisationsFailure(res?.data?.response));
        }
    });
}

function fetchOrganisationsBegin() {
    return {
        type: _Constants.FETCH_ORGANISATION_BEGIN
    };
}

function fetchOrganisationsSuccess(payload) {
    return {
        type: _Constants.FETCH_ORGANISATION_SUCCESS,
        payload: payload
    };
}

function fetchOrganisationsFailure(error) {
    return {
        type: _Constants.FETCH_ORGANISATION_FAILURE,
        payload: error
    };
}

export const createCustomer = (payload) => dispatch => {
    dispatch(customerModificationBegin());
    CustomerService.createCustomer(payload).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(customerModificationSuccess(res?.data?.response));
        } else {
            dispatch(customerModificationFailure(res?.data?.response));
        }
    });
}

export const updateCustomer = (id, payload) => dispatch => {
    dispatch(customerModificationBegin());
    CustomerService.updateCustomer(id, payload).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(customerModificationSuccess(res?.data?.response));
        } else {
            dispatch(customerModificationFailure(res?.data?.response));
        }
    });
}

function customerModificationBegin() {
    return {
        type: _Constants.CUSTOMER_MODIFICATION_BEGIN
    };
}

function customerModificationSuccess(payload) {
    return {
        type: _Constants.CUSTOMER_MODIFICATION_SUCCESS,
        payload: payload
    };
}

function customerModificationFailure(error) {
    return {
        type: _Constants.CUSTOMER_MODIFICATION_FAILURE,
        payload: error
    };
}

export const getCustomer = (id) => dispatch => {
    dispatch(fetchCustomerBegin());
    CustomerService.getCustomer(id).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(fetchCustomerSuccess(res?.data?.response));
        } else {
            dispatch(fetchCustomerFailure(res?.data?.response));
        }
    });
}

function fetchCustomerBegin() {
    return {
        type: _Constants.FETCH_CUSTOMER_BEGIN
    };
}

function fetchCustomerSuccess(payload) {
    return {
        type: _Constants.FETCH_CUSTOMER_SUCCESS,
        payload: payload
    };
}

function fetchCustomerFailure(error) {
    return {
        type: _Constants.FETCH_CUSTOMER_FAILURE,
        payload: error
    };
}

export const getPincodesByCity = (cityid, type) => {
    if (type === 'ho')
        return dispatch => {
            dispatch(getHoPincodeBegin());
            MiscService.getPincodesByCity(cityid)
                .then(response => {
                    if (response.data.response) {
                        dispatch(getHoPincodeSuccess(response.data.response));
                    }
                })
                .catch(error => dispatch(getHoPincodeFailure(error)));
        };
    else
        return dispatch => {
            dispatch(getBPincodeBegin());
            MiscService.getPincodesByCity(cityid)
                .then(response => {
                    if (response.data.response) {
                        dispatch(getBPincodeSuccess(response.data.response));
                    }
                })
                .catch(error => dispatch(getBPincodeFailure(error)));
        };
};

const getHoPincodeBegin = () => {
    return {
        type: _Constants.GET_HOPINCODE_BEGIN
    };
};

const getHoPincodeSuccess = payload => {
    return {
        type: _Constants.GET_HOPINCODE_SUCCESS,
        payload
    };
};

const getHoPincodeFailure = error => {
    return {
        type: _Constants.GET_HOPINCODE_FAILURE,
        payload: error
    };
};

const getBPincodeBegin = () => {
    return {
        type: _Constants.GET_BPINCODE_BEGIN
    };
};

const getBPincodeSuccess = payload => {
    return {
        type: _Constants.GET_BPINCODE_SUCCESS,
        payload
    };
};

const getBPincodeFailure = error => {
    return {
        type: _Constants.GET_BPINCODE_FAILURE,
        payload: error
    };
};


export const getAccessKeys = (id) => dispatch => {
    dispatch(getAccessKeysBegin());
    CustomerService.getAccessKeys(id).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(getAccessKeysSuccess(res?.data?.response));
        } else {
            dispatch(getAccessKeysFailure(res?.data?.response));
        }
    });
};

export const resetAccessKeys = () => dispatch => {
    dispatch({
        type: _Constants.GET_RESET_ACCESS_KEYS
    })
}

const getAccessKeysBegin = () => {
    return {
        type: _Constants.GET_ACCESS_KEYS_BEGIN
    };
};

const getAccessKeysSuccess = payload => {
    return {
        type: _Constants.GET_ACCESS_KEYS_SUCCESS,
        payload
    };
};

const getAccessKeysFailure = error => {
    return {
        type: _Constants.GET_ACCESS_KEYS_FAILURE,
        payload: error
    };
};