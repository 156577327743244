import Tracking from "../src/components/tracking/Tracking";
import PartnersOnboarding from "../src/components/settings/partners-onboarding/PartnersOnboarding";
import Ingest from "../src/components/reports/ingest/Ingest";
import Suspicious from "../src/components/suspicious/Suspicious";
import CustomerDashboard from "../src/components/settings/customer/CustomerDashboard";
import CustomerForm from "../src/components/settings/customer/CustomerForm";
import LocationDashboard from "../src/components/settings/location/LocationDashboard";
import RouteDashboard from "../src/components/settings/route/RouteDashboard";
import RouteForm from "../src/components/settings/route/RouteForm";
import TrackingContainerPop from "../src/components/TrackingPopup/TrackingContainerPop";
import UserDashboard from "./components/settings/user/UserDashboard";
import UserForm from "./components/settings/user/UserForm";
import InventoryAwb from "./components/inventory/awb/index";
import Print from "./components/print/print";
import InboundScan from "./components/inbound/scan";
import DashboardApp from "./components/zoho-dashboard/Dashboard";
import ZohoDashboard from "./components/settings/zoho-dashboard/zohoDashboard";
import ZohoForm from "./components/settings/zoho-dashboard/zohoForm";
import PaymentStatus from "./components/digital-payment/payment-status";
import ShipperDashboard from "./components/settings/customer/shipper/shipperDashboard";
import CustomConfig from "../src/components/settings/config/config";
import ConfigEditor from "../src/components/settings/config/configEditor";
import DownloadFile from "./components/shared-components/download-file";
import ManifestSheet from "./components/reports/manifest-sheet";
import Audit from "./components/inventory/audit";
import AuditForm from "./components/inventory/audit/audit-form";
import InventoryDrs from "./components/inventory/drs";
import InventoryPartnerManifest from "./components/inventory/manifest-partner/index";
import ManifestPartnerForm from "./components/inventory/manifest-partner/manifestForm";
import DRSClosure from "./components/rto/drs-closure/index";
import MonitoringReport from "./components/monitoring-report/monitoringReport";
import ManagePickup from "./components/leads/manage-pickup/ManagePickup";
import ManifestClosure from "./components/inventory/closure";
import ReportsContainer from "./components/reports/reports.container";
import { permissionTags, pModules } from "./configs/permissionTags";
import RTOWaybill from "./components/rto/waybill/waybill";
import UploadAssignedPickup from "./components/pickup/upload-assigned-pickup";
import PODLive from "./components/pod/pod-live/podlive";
import LabelPrinting from "./components/inventory/label-print/label-printing";
import ConsignmentPOD from "./components/pod/consignment.pod/consignment.pod";
import RTOPodForm from "./components/rto/rto-pod-form/rto-pod-form";
import RTODRS from "./components/rto/rto-drs/rto-drs";
import CreateConnectionSheet from "./components/trips/connection-sheet/create-connection/create.connecitons";
import OutboundLoad from "./components/trips/outbound-trips/outbound.load";
import TripManifestClosure from "./components/inventory/closure/trip-closure/trip.manifest.closure";
import RTOEligible from "./components/rto/rto–eligible/rto-eligible";
import ReportsV2 from "./components/reportsv2/reports";
import ManifestMWebHome from "./components/inventory/manifest-partner/manifest.home";
import InventoryException from "./components/inventory/exceptions";
import CODModuleV2 from "./components/codv2"
import PodDrsClosureV2 from "./components/pod/drs-closure-v2/index";

import Unloading from "./assets/img/mweb/unloading.png";
import InboundScanIcon from "./assets/img/mweb/inbound.png";
import ManifestBagging from "./assets/img/mweb/bagging.png";
import ModuleLauncher from "./components/layouts/ModuleLauncher";
import DRSView from "./components/operations/drs";
import DAFView from "./components/operations/daf";
import LMHealthDashboard from "./components/operations/health";
import CreateDRS from "./components/inventory/drs/create.drs";
import CreateRTODRS from "./components/rto/rto-drs/pages/create.rto.drs";
import MetabaseReport from "./components/operations/components/metabase.report";
import PickupReport from "./components/leads/pickup-report";
import BillingModule from "./components/billing";
import ManageReversePickup from "./components/leads/manage-reverse-pickup/ManageReversePickup";
import RtoDrsReport from "./components/rto/rto-drs-report";
import PrimaryScan from "./components/primary-scan";
import EmbedComponent from "./components/operations/components/embed.component";
import OutboundTripsV2 from "./components/trips/v2/outbound.trips";
import InboundTripsV2 from "./components/trips/v2/inbound.trips";
import ExpectedComponent from "./components/inbound/expected/expected.component";
import ReversePickupReport from "./components/leads/reverse-pickup-report";
import ConnectionsSheetV2 from "./components/trips/connection-sheet/v2/connections.view";
import CreateTrip from "./components/trips/create-trip/create.trip";
import LocationTabLayout from "./components/settings/location/LocationTabLayout";
import MetabaseQuestion from "./components/common/metabase-question/metabase.question";
import FMDashboard from "./components/operations/fm/dashboard";
import FMRTODashboard from "./components/operations/fm/rto.dashboard";
import RTOUpload from "./components/rto/rto-upload/rto-upload";
import ConsignmentsView from "./components/operations/drs/components/consignments.view";
import RVPPRSComponent from "./components/operations/components/rvp.prs";

const leadsRoutes = [
    {
        path: "/appv2/leads/dashboard/pickup-report",
        name: "Leads",
        component: PickupReport
    },
    {
        path: "/appv2/leads/dashboard/upload-pickup",
        component: UploadAssignedPickup,
        name: "UploadPickupDashboard"
    },
    {
        path: "/appv2/leads/dashboard/manage-pickup",
        component: ManagePickup,
        name: "Leads"
    },
    {
        path: "/appv2/leads/dashboard",
        component: _ => <ModuleLauncher module={pModules.leads.title} />,
        name: "Leads"
    },
    {
        path: "/appv2/leads/dashboard/manage-reverse-pickup",
        component: ManageReversePickup,
        name: "ManageReversePickup"
    },
    {
        path: "/appv2/leads/dashboard/reverse-image-audit",
        name: "ManageReversePickup",
        component: _ => <MetabaseQuestion questionNo={3568} reportName={"rvp-success-image-audit"} lockedParams={["location_alias"]} />,
    },
    {
        path: "/appv2/leads/dashboard/reverse-pickup-report",
        name: "ManageReversePickup",
        component: ReversePickupReport
    }
]

const inboundRoutes = [
    {
        path: "/appv2/inbound/dashboard/scan",
        name: "Inbound",
        component: InboundScan,
        access: permissionTags.inbound.scan.read
    },
    {
        path: "/appv2/inbound/dashboard/expected",
        component: ExpectedComponent,
        name: "Inbound",
        access: permissionTags.inbound.expected.read
    },
    {
        path: "/appv2/inbound/dashboard/rto-expected",
        component: ExpectedComponent,
        name: "Inbound",
        access: permissionTags.inbound.expected.read
    },
    {
        path: "/appv2/inbound/dashboard",
        component: _ => <ModuleLauncher module={pModules.inboud.title} />,
        name: "Inbound",
        access: permissionTags.inbound.scan.read
    },
]

const inventoryRoutes = [
    {
        path: "/appv2/inventory/dashboard/exceptions",
        component: InventoryException,
        name: "Inventory",
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/inventory/dashboard/awb",
        component: InventoryAwb,
        name: "Awb",
        access: permissionTags.inventory.waybill.read
    },
    {
        path: "/appv2/inventory/dashboard/audit",
        name: "Inventory",
        component: Audit,
    },
    {
        path: "/appv2/inventory/dashboard/audit-report",
        name: "Inventory",
        component: _ => <MetabaseQuestion dashboardNo={280} reportName={"audit-dashboard-meesho"} />
    },
    {
        path: "/appv2/inventory/form/audit/:id",
        name: "Inventory",
        component: AuditForm
    },
    {
        path: "/appv2/inventory/dashboard/drs",
        component: InventoryDrs,
        name: "DRS",
        access: permissionTags.inventory.drs.read
    },
    {
        path: "/appv2/inventory/form/drs/:drsCode?",
        component: CreateDRS,
        name: "DRS"
    },
    {
        path: "/appv2/inventory/form/manifest-partner/:id?",
        component: ManifestPartnerForm,
        name: "Inventory"
    },
    {
        path: "/appv2/inventory/dashboard/manifest-partner",
        component: InventoryPartnerManifest,
        name: "Inventory"
    },
    {
        path: "/appv2/inventory/dashboard/manifest-closure/trips/:tripCode?",
        component: TripManifestClosure,
        name: "Inventory"
    },
    {
        path: "/appv2/inventory/dashboard/manifest-closure",
        component: ManifestClosure,
        name: "Inventory"
    },
    {
        path: "/appv2/inventory/dashboard/label-printing",
        component: LabelPrinting,
        name: "Inventory"
    },
    {
        path: "/appv2/inventory/dashboard",
        component: _ => <ModuleLauncher module={pModules.inventory.title} />,
        name: "Inventory"
    }
]

const trackingRoutes = [
    {
        path: "/appv2/tracking/popup/:waybillNo?",
        name: "Waybill Tracking",
        component: TrackingContainerPop,
        noHeader: true,
        noSideBar: true,
        noTabs: true
    },
    {
        path: "/appv2/tracking/dashboard/call-masking-adoption",
        access: permissionTags.tracking.tracking.read,
        name: "Tracking",
        component: _ => <MetabaseQuestion dashboardNo={298} reportName={"call-masking-adoption"} />
    },
    {
        path: "/appv2/tracking/dashboard/tracking",
        access: permissionTags.tracking.tracking.read,
        name: "Tracking",
        component: Tracking
    }
]

const podRoutes = [
    {
        path: "/appv2/pod/form/drs/pod/create/:id?/:drsCode?/:drsId?",
        name: "ConsignmentPOD",
        component: ConsignmentPOD
    },
    {
        path: "/appv2/pod/form/drs/pod/create",
        name: "ConsignmentPOD",
        component: ConsignmentPOD
    },
    {
        path: "/appv2/pod/dashboard/drs",
        name: "Live",
        component: PODLive
    },
    {
        path: "/appv2/pod/dashboard/drs-closure",
        name: "DRS Closure",
        component: PodDrsClosureV2
    },
    {
        path: "/appv2/pod/dashboard/pod-audit",
        name: "POD Audit",
        component: DRSView
    },
    {
        path: "/appv2/pod/dashboard/pending-suspicious-pods",
        name: "POD Audit",
        component: _ => <ConsignmentsView expanded={true}/>
    },
    {
        path: "/appv2/pod/dashboard/pod-audit-validation",
        name: "POD Audit",
        component: _ => <MetabaseQuestion dashboardNo={316} reportName={"pod-audit-validation-area-manager"} lockedParams={["area_manager"]} />,
    },
    {
        path: "/appv2/pod/dashboard",
        name: "POD",
        component: _ => <ModuleLauncher module={pModules.pod.title} />
    }
]

const codRoutes = [
    {
        path: "/appv2/cod/dashboard/cod",
        name: "COD",
        component: CODModuleV2
    },
    {
        path: "/appv2/cod/dashboard",
        name: "COD",
        component: _ => <ModuleLauncher module={pModules.cod_new.title} />
    }
]

const reportRoutes = [
    {
        path: "/appv2/reports/dashboard/download/reportv2",
        name: "Reports",
        component: ReportsV2,
        access: permissionTags.reports.reports.read
    },
    {
        path: "/appv2/reports/dashboard/download",
        name: "Reports",
        component: ReportsContainer,
        access: permissionTags.reports.reports.read
    },
    {
        path: "/appv2/reports/dashboard/ingest",
        name: "Reports",
        component: Ingest,
        access: permissionTags.reports.ingest.read
    },
    {
        path: "/appv2/reports/dashboard/manifest-sheet",
        name: "Reports",
        component: ManifestSheet,
        access: permissionTags.reports.manifestSheet.read
    },
    {
        path: "/appv2/reports/dashboard",
        name: "Reports",
        component: _ => <ModuleLauncher module={pModules.reports.title} />
    }
]

const rtoRoutes = [
    {
        path: "/appv2/rto/dashboard/rtodrs-report",
        component: RtoDrsReport,
        name: "RTODrsReport"
    },
    {
        path: "/appv2/rto/form/manifest-partner/:id?",
        component: ManifestPartnerForm,
        name: "Inventory"
    },
    {
        path: "/appv2/rto/dashboard/manifest-partner",
        component: InventoryPartnerManifest,
        name: "Inventory"
    },
    {
        path: "/appv2/rto/dashboard/drs-closure",
        component: DRSClosure,
        name: "RTO"
    },
    {
        path: "/appv2/rto/dashboard/drs",
        name: "DRS",
        component: RTODRS,
    },
    {
        path: "/appv2/rto/form/drs/pod/:drsId?",
        component: RTOPodForm,
        name: "RTOPodForm"
    },
    {
        path: "/appv2/rto/form/drs/:rtoDrsCode?",
        name: "DRS",
        component: CreateRTODRS,
    },
    {
        path: "/appv2/rto/dashboard/waybill",
        component: RTOWaybill,
        name: "Waybill"
    },
    {
        path: "/appv2/rto/dashboard/rto-eligible",
        name: "RTO Eligible",
        component: RTOEligible,
    },
    {
        path: "/appv2/rto/dashboard/upload-rto",
        name: "RTO Upload",
        component: RTOUpload,
    },
    {
        path: "/appv2/rto/dashboard",
        name: "Waybill",
        component: _ => <ModuleLauncher module={pModules.rto.title} />
    }
]

const settingRoutes = [
    {
        path: "/appv2/settings/dashboard/partners-onboarding",
        name: "Partners Onboarding",
        access: permissionTags.settings.partners_onboarding.read,
        component: PartnersOnboarding
    },
    {
        path: "/appv2/settings/dashboard/route",
        component: RouteDashboard,
        access: permissionTags.settings.route.read,
        name: "Settings"
    },
    {
        path: "/appv2/settings/form/route/:id?",
        component: RouteForm,
        name: "Settings",
        access: permissionTags.settings.route.read,
        noSideBar: true
    },
    {
        path: "/appv2/settings/dashboard/location",
        component: LocationDashboard,
        access: permissionTags.settings.location.read,
        name: "Settings"
    },
    {
        path: "/appv2/settings/form/location/:id?",
        component: LocationTabLayout,
        access: permissionTags.settings.location.create,
        name: "Settings",
        noSideBar: true,
        noTabs: true
    },
    {
        path: "/appv2/settings/dashboard/customer/shippers",
        component: ShipperDashboard,
        access: permissionTags.settings.customer.shippers,
        name: "Settings"
    },
    {
        path: "/appv2/settings/dashboard/customer",
        component: CustomerDashboard,
        access: permissionTags.settings.customer.read,
        name: "Settings"
    },
    {
        path: "/appv2/settings/dashboard/custom-config/edit",
        component: ConfigEditor,
        name: "Settings",
        noSideBar: true
    },
    {
        path: "/appv2/settings/dashboard/custom-config",
        component: CustomConfig,
        name: "Settings"
    },
    {
        path: "/appv2/settings/form/customer/:id?",
        component: CustomerForm,
        access: permissionTags.settings.customer.create,
        name: "Settings",
        noSideBar: true
    },
    {
        path: "/appv2/settings/dashboard/zoho-dashboard",
        component: ZohoDashboard,
        name: "Settings",
        access: permissionTags.settings.zoho.read
    },
    {
        path: "/appv2/settings/form/zoho-dashboard/:id?",
        component: ZohoForm,
        access: permissionTags.settings.zoho.read,
        name: "Settings"
    },
    {
        path: "/appv2/settings/dashboard/user",
        component: UserDashboard,
        access: permissionTags.settings.user.read,
        name: "Settings"
    },
    {
        path: "/appv2/settings/form/user/:id?",
        component: UserForm,
        name: "Settings",
        access: permissionTags.settings.user.read,
        noSideBar: true
    },
    {
        path: "/appv2/settings/dashboard",
        component: _ => <ModuleLauncher module={pModules.settings.title} />,
        name: "Settings"
    },
]

const dashboardRoutes = [
    {
        path: "/appv2/dashboard/dashboard/home",
        name: "Dashboard",
        component: DashboardApp
    },
    {
        path: "/appv2/dashboard/dashboard",
        name: "Dashboard",
        component: _ => <ModuleLauncher module={pModules.dashboard.title} />
    }
]

const tripRoutes = [
    {
        path: "/appv2/trips/dashboard/outbound",
        component: OutboundTripsV2,
        access: permissionTags.trips.outbound.read,
        name: "Outbound Trips",
    },
    {
        path: "/appv2/trips/form/outbound/create",
        component: CreateTrip,
        access: permissionTags.trips.outbound.create,
        name: "Connection Sheets"
    },
    {
        path: "/appv2/trips/form/outbound/:tripCode?",
        component: OutboundLoad,
        access: permissionTags.trips.outbound.read,
        name: "Outbound Trips",
    },
    {
        path: "/appv2/trips/dashboard/inbound",
        component: InboundTripsV2,
        access: permissionTags.trips.inbound.read,
        name: "Inbound Trips",
    },
    {
        path: "/appv2/trips/dashboard/connection-sheet",
        component: ConnectionsSheetV2,
        access: permissionTags.trips.connection_sheet.read,
        name: "Connection Sheet",
    },
    {
        path: "/appv2/trips/form/connection-sheet/:connectionCode?",
        component: CreateConnectionSheet,
        access: [permissionTags.trips.connection_sheet.create, permissionTags.trips.connection_sheet.update],
        name: "Connection Sheets"
    },
    {
        path: "/appv2/trips/form/connection-sheet",
        component: CreateConnectionSheet,
        access: [permissionTags.trips.connection_sheet.create, permissionTags.trips.connection_sheet.update],
        name: "Connection Sheets"
    },
    {
        path: "/appv2/trips/dashboard/",
        component: _ => <ModuleLauncher module={pModules.trips.title} />,
        access: permissionTags.trips.module,
        name: "Connection Sheets"
    }
]

const digitalPaymentRoutes = [
    {
        path: "/appv2/digital-payment/dashboard/payment-status",
        name: "Payment Status",
        component: PaymentStatus
    },
    {
        path: "/appv2/digital-payment/dashboard",
        name: "Digital Payment",
        component: _ => <ModuleLauncher module={pModules.digital_payment.title} />
    },
]

const miscRoutes = [
    {
        path: "/appv2/download",
        component: DownloadFile,
        name: "Download",
        noHeader: true,
        noSideBar: true,
        noTabs: true
    },
    {
        path: "/appv2/print_information",
        component: Print,
        name: "Print",
        noHeader: true,
        noSideBar: true,
        noTabs: true
    },
    {
        path: "/appv2/reports/:embedDashboard",
        name: "report",
        component: EmbedComponent,
        noHeader: false,
        noSideBar: true,
        noTabs: true
    }
]

const monitoringReportRoutes = [
    {
        path: "/appv2/monitoring-reports/dashboard/:reportName",
        name: "Monitoring Reports",
        component: MonitoringReport
    },
    {
        path: "/appv2/monitoring-reports/dashboard",
        name: "Monitoring Reports",
        component: _ => <ModuleLauncher module={pModules.monitoring_reports.title} />
    },
]

const operationsRoutes = [
    {
        path: "/appv2/lm-ops/dashboard/lm-health",
        name: "LM Operations",
        component: LMHealthDashboard,
        noSideBar: true
    },
    {
        path: "/appv2/lm-ops/dashboard/live-drs-audit",
        name: "POD Audit",
        component: _ => <MetabaseQuestion dashboardNo={328} reportName={"live-drs-audit"} lockedParams={["cluster_manager"]} />,
    },
    {
        path: "/appv2/lm-ops/dashboard/rvp-prs",
        component: RVPPRSComponent,
        name: "RVP PRS Report"
    },
    {
        path: "/appv2/lm-ops/dashboard/fe-performance",
        name: "LM Operations",
        component: _ => <MetabaseQuestion dashboardNo={228} reportName={"fe-performance-with-date-filter"} />
    },
    {
        path: "/appv2/lm-ops/dashboard/live-drs",
        name: "LM Operations",
        component: DRSView
    },
    {
        path: "/appv2/lm-ops/dashboard/daily-action",
        name: "LM Operations",
        component: DAFView
    },
    {
        path: "/appv2/lm-ops/dashboard/rvp-daily-action",
        name: "LM Operations",
        component: _ => <MetabaseQuestion dashboardNo={314} reportName={"rvp-daf-central-team-views"} />
    },
    {
        path: "/appv2/lm-ops/dashboard/rvp-operation",
        name: "LM Operations",
        component: _ => <MetabaseQuestion dashboardNo={264} reportName={"rvp-operations"}
            lockedParams={["cluster_manager"]} />
    },
    {
        path: "/appv2/lm-ops/dashboard",
        name: "LM Operations",
        component: _ => <ModuleLauncher module={pModules.lm_operation.title} />
    },
    {
        path: "/appv2/lm-ops/dashboard/pendency",
        name: "LM Operations",
        component: _ => <MetabaseQuestion questionNo={2378} reportName={"lm-pendency-view"} />
    },
    {
        path: "/appv2/lm-ops/dashboard/performance",
        name: "LM Operations",
        component: _ => <MetabaseQuestion questionNo={3693} reportName={"lm-performence-view"} />
    },
    {
        path: "/appv2/lm-ops/dashboard/rvp-performance",
        name: "LM Operations",
        component: _ => <MetabaseQuestion dashboardNo={253} reportName={"rvp-global-performance-monitoring-dashboard"} dateParams={new Set(["startDate", "endDate"])} />
    },
    {
        path: "/appv2/lm-ops/dashboard/rvp-overview",
        name: "LM Operations",
        component: _ => <MetabaseQuestion dashboardNo={271} reportName={"rvp-health"} lockedParams={['cluster_manager']} />
    },
    {
        path: "/appv2/lm-ops/dashboard/fe-onboarding-status",
        name: "LM Operations",
        component: _ => <MetabaseQuestion dashboardNo={290} reportName={"fe-onboarding-status-dashboard"} />
    },
    {
        path: "/appv2/sc-ops/dashboard/sc-overview",
        name: "SC Operations",
        component: _ => <MetabaseReport reportName={"sorting-centre-overview"} />
    },
    {
        path: "/appv2/sc-ops/dashboard/sc-daily-action",
        name: "SC Operations",
        component: _ => <MetabaseReport reportName={"sc-daily-action-file"} />
    },
    {
        path: "/appv2/sc-ops/dashboard/sc-rto-daily-action",
        name: "SC Operations",
        component: _ => <MetabaseReport reportName={"sc-daily-action-file-rto-view"} />
    },
    {
        path: "/appv2/sc-ops/dashboard/ipp-dashboard",
        name: "SC Operations",
        component: _ => <MetabaseReport reportName={"individual-performance-plan-dashboard"} autoRefresh={true} autoRefreshTimetout={60} />,
        noSideBar: true
    },
    {
        path: "/appv2/sc-ops/dashboard/user-scan-count",
        name: "SC Operations",
        component: _ => <MetabaseReport reportName={"user-level-scan-count"} autoRefresh={true} autoRefreshTimetout={60} dateParams={new Set(["startDate", "endDate"])} />,
        noSideBar: true
    },
    {
        path: "/appv2/settings/dashboard/network-visibility",
        name: "SC Operations",
        component: _ => <MetabaseQuestion dashboardNo={208} reportName={"network-visibility"} lockedParams={["location_alias"]} />
    },
    {
        path: "/appv2/sc-ops/dashboard/shipment-discrepancy",
        name: "SC Operations",
        component: _ => <MetabaseQuestion dashboardNo={248} reportName={"shipment-discrepancy-at-a-location-rca"} lockedParams={["location_alias"]} dateParams={new Set(["startDate", "endDate"])} />
    }
]

const fmOpsRoutes = [
    {
        path: "/appv2/fm-ops/dashboard/fe-onboarding-status",
        name: "FM Operations",
        component: _ => <MetabaseQuestion dashboardNo={290} reportName={"fe-onboarding-status-dashboard"} />
    },
    {
        path: "/appv2/fm-ops/dashboard/forward",
        name: "FM Operations",
        component: FMDashboard
    },
    {
        path: "/appv2/fm-ops/dashboard/rto",
        name: "FM Operations",
        component: FMRTODashboard
    }
]

const billingRoutes = [
    {
        path: "/appv2/billing/dashboard/forward",
        component: BillingModule,
        name: "Forward Billing"
    },
    {
        path: "/appv2/billing/dashboard/rto",
        component: BillingModule,
        name: "RTO Billing"
    },
    {
        path: "/appv2/billing/dashboard/cross-dock",
        component: BillingModule,
        name: "Crossdock Billing"
    },
    {
        path: "/appv2/billing/dashboard",
        name: "Billing",
        component: _ => <ModuleLauncher module={pModules.billing.title} />
    },
    {
        path: "/appv2/billing/dashboard/rvp-rider-wise",
        name: "RVP Rider wise",
        component: BillingModule,
    }
]

const mWebRoutes = [
    {
        path: "/appv2/mweb/inbound/dashboard/scan",
        name: "Inbound",
        component: InboundScan,
        noSideBar: true,
        noTabs: true,
        noHeader: true,
        access: permissionTags.inbound.scan.read,
        //mWeb home page details/
        backText: "Scan shipment",
        isMobile: true,
        isRootPage: true,
        mwebName: "Inbound Scan",
        description: "Scan incoming waybills and view their details.",
        imgSrc: InboundScanIcon,
        iconProps: {
            backgroundColor: "#90DBAD"
        }
    },
    {
        path: "/appv2/mweb/inventory/dashboard/manifest-closure/trips/:tripCode?",
        component: TripManifestClosure,
        name: "Inventory",
        backText: "Scan to Unload",
        noSideBar: true,
        noTabs: true,
        noHeader: true,
        //mWeb home page details/
        isMobile: true,
        isRootPage: false,
        mwebName: "Unload",
        description: "Scan connection sheets and manifest to unload"
    },
    {
        path: "/appv2/mweb/inventory/dashboard/manifest-closure",
        component: ManifestClosure,
        name: "Inventory",
        backText: "Scan to Unload",
        noSideBar: true,
        noTabs: true,
        noHeader: true,
        //mWeb home page details/
        isMobile: true,
        isRootPage: true,
        mwebName: "Unload",
        description: "Scan connection sheets and manifest to unload",
        imgSrc: Unloading,
        iconProps: {
            backgroundColor: "#8FCDDB"
        }
    },
    {
        path: "/appv2/mweb/inventory/dashboard/manifest-partner",
        component: ManifestMWebHome,
        name: "Manifest Scan",
        noSideBar: true,
        noTabs: true,
        noHeader: true,
        //mWeb home page details/
        backText: "Manifest Scan",
        isMobile: true,
        isRootPage: true,
        mwebName: "Manifest Scan",
        description: "Create new Manifests or scan waybills into existing Manifests",
        imgSrc: ManifestBagging,
        iconProps: {
            backgroundColor: "#EDAA9F"
        }
    },
    {
        path: "/appv2/mweb/inventory/form/manifest-partner/:id?",
        component: ManifestPartnerForm,
        name: "Manifest Scan",
        noSideBar: true,
        noTabs: true,
        noHeader: true,
        //mWeb home page details/
        backText: "Scan to add to Manifest",
        isMobile: true,
        isRootPage: false
    },
    {
        path: "/appv2/mweb/rto/form/manifest-partner/:id?",
        component: ManifestPartnerForm,
        name: "Manifest Scan",
        noSideBar: true,
        noTabs: true,
        noHeader: true,
        //mWeb home page details/
        backText: "Scan to add to Manifest",
        isMobile: true,
        isRootPage: false
    },
    {
        path: "/appv2/mweb/inventory/dashboard/primary-scan",
        component: PrimaryScan,
        name: "Inventory",
        backText: "Scan to Unload",
        noSideBar: true,
        noTabs: true,
        noHeader: true,
        //mWeb home page details/
        isMobile: true,
        isRootPage: true,
        mwebName: "Primary Scan",
        description: "Unload shipments from manifest",
        imgSrc: Unloading,
        iconProps: {
            backgroundColor: "#AFEDFB"
        },
        configKeyCheck: "enablePrimaryScanV2"
    }
]

const routes = [
    ...leadsRoutes,
    ...inboundRoutes,
    ...inventoryRoutes,
    ...trackingRoutes,
    ...tripRoutes,
    ...podRoutes,
    ...codRoutes,
    ...settingRoutes,
    ...rtoRoutes,
    ...reportRoutes,
    ...dashboardRoutes,
    ...digitalPaymentRoutes,
    ...monitoringReportRoutes,
    ...miscRoutes,
    ...mWebRoutes,
    ...operationsRoutes,
    ...billingRoutes,
    ...fmOpsRoutes
];

export default routes;
