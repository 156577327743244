import React, { Component } from 'react';
import './download-file.css';
import { Form, Input, Button, Row, Col, Alert } from 'antd';
import { DownloadTags } from '../../../common/utils/constants';
import { thirdPartyGet } from '../../../common/utils/apiGateway';

class DownloadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
      downloadStatus: { downloading: 'downloading', maxTime: 'maxTime', downloaded: 'downloaded' },
      currentStatus: 'downloading',
      maxIntervalTime: 1000 * 60 * 7, // 7 minutes
      intervalGap: 1000 * 10, // 10 seconds
      reportUrl: props.location.search.split('=')[1],
      inputDownloadStr: decodeURIComponent(props.location.search.split('=')[1]),
      submittedReq: true,
    }
  }

  componentDidMount() {
    if(this.state.reportUrl.includes("&title")){
      this.setState({
        reportUrl: this.state.reportUrl.replace("&title", ""),
        inputDownloadStr: this.state.inputDownloadStr.replace("&title", "")
      })
    }
    let intervalTime = 0;
    // this.downloadReport();
    this.state.intervalId = setInterval(() => {
      if (intervalTime == this.state.maxIntervalTime) {
        this.currentStatus = this.state.downloadStatus.maxTime;
        this.componentWillUnmount();
        this.setState({
          currentStatus: this.state.downloadStatus.maxTime
        })
      }
      intervalTime = intervalTime + this.state.intervalGap;
      this.downloadReport();
    }, this.state.intervalGap);
  }

  componentWillUnmount() {
    this.state.submittedReq = false;
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  downloadReport = () => {

    if (this.state.reportUrl != null) {

      if (this.state.currentStatus === this.state.downloadStatus.downloading) {
        this.submittedReq = true;
      } else {
        this.submittedReq = false;
      }
      const that = this;
      let url = decodeURIComponent(this.state.reportUrl);
      thirdPartyGet(url, DownloadTags.type).then(responseData => {
        that.setState({
          currentStatus: that.state.downloadStatus.downloaded
        }, () => {
          that.componentWillUnmount();
          window.open(url);
          setTimeout(() => {
            window.close();
          }, 100);
        })
      }).catch((err) => {

      });
    }
  }

  reloadPage = () => {
    window.location.reload();
  }

  render() {
    const { inputDownloadStr, submittedReq, currentStatus, downloadStatus } = this.state
    const queryParams = new URLSearchParams(this.props.location.search);
    const title = queryParams.get("title");
    return (
      <div className="download-box">
        <Form>
          <Row>
            {
              title ? (
                <Col span={24}>
                  <h2>{title}</h2>
                </Col>
              ) : null
            }
            <Col span={14} offset={3}>
              <Input size="large" value={inputDownloadStr} style={{ width: '100%' }} placeholder="Enter file url to download" />
            </Col>
            <Col span={4}>
              <Button size="large" type="primary">Download File</Button>
            </Col>
          </Row>
        </Form>

        {
          (inputDownloadStr && submittedReq && currentStatus == downloadStatus.downloading)
            ? <div className="download-msg-box">
              <Alert message="Downloading requested file...." type="info" />
              <Alert message="Please don't close window untill the download completes." type="danger" />
            </div>
            : <></>
        }
        {
          (inputDownloadStr && currentStatus == downloadStatus.downloaded)
            ? <div className="download-msg-box" >
              <Alert message="Downloaded!!" type="success" />
            </div>
            : <></>
        }
        {
          (inputDownloadStr && currentStatus == downloadStatus.maxTime)
            ? <div className="download-msg-box" >
              <Alert message="Maximum time limit reached! Please reduce your search count and try again." type="danger" />
              <Button type="primary" onClick={e => this.reloadPage()} > Try Again</Button>
            </div>
            : <></>
        }
        {
          (!inputDownloadStr && !submittedReq)
            ? <div className="download-msg-box">
              <Alert message="Please enter provided S3 link to download file" type="success" />
            </div>
            : <></>
        }

      </div>
    )
  }
}

export default DownloadFile;