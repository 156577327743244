import { apiRequest } from "../../../../common/utils/apiGateway";
import { checkHttpStatus, getIfArray } from "../../../../common/utils/utils"

export const ENTITY_EXCEPTION_ACTIONS = {
    EEA_01 : {
        label : "Pincode Correction",
        actionSuccess : "Pincode corrected successfully!"
    },
    EEA_02 : {
        label : "Inform Previous Location",
        actionSuccess : "Informed Previous location successfully!"
    },
    EEA_03 : {
        label : "Mark as Lost",
        actionSuccess : "Entity marked lost successfully!"
    },
    EEA_04 : {
        label : "Mark as Damaged",
        actionSuccess : "Entity marked as damaged successfully!"
    },
    EEA_05 : {
        label : "Mark as Liquidated",
        actionSuccess : "Entity marked as liquidated successfully!"
    },
    EEA_06 : {
        label : "Mark as Orphan",
        actionSuccess : "Entity marked orphan successfully!"
    },
    EEA_07 : {
        label : "Mark as Reopen",
        actionSuccess : "Exception reopened!"
    },
    EEA_08 : {
        label : "Mark as Duplicate",
        actionSuccess : "Entity marked duplicate successfully!"
    },
    EEA_09 : {
        label : "Inform Next location",
        actionSuccess : "Informed Next location successfully!"
    }
}

/* 
    ${exception_type} : {
        ${current_consignment_status} : {
            ${exception_status}
        }
    }
*/

const ENTITY_EXCEPTION_ACTIONS_MAPPINGS = {}

export const getActionArray =  entityException => {
    if (!entityException) {
        return [];
    }
    let fetchKey = entityException?.updatedStatus + "." + entityException?.entityStatus + "." + entityException?.status,
        fetchAllKey = "*", 
        fetchExceptionTypeActions = entityException?.updatedStatus + ".*",
        fetchExceptionTypeStatusActions = entityException?.updatedStatus + ".*." + entityException?.status,
        fetchConsignmentStatusActions = entityException?.updatedStatus + "." + entityException?.entityStatus + ".*";
    return  [
                ...(getIfArray(ENTITY_EXCEPTION_ACTIONS_MAPPINGS, fetchKey) || []),
                ...(getIfArray(ENTITY_EXCEPTION_ACTIONS_MAPPINGS, fetchAllKey) || []),
                ...(getIfArray(ENTITY_EXCEPTION_ACTIONS_MAPPINGS, fetchExceptionTypeActions) || []),
                ...(getIfArray(ENTITY_EXCEPTION_ACTIONS_MAPPINGS, fetchExceptionTypeStatusActions) || []),
                ...(getIfArray(ENTITY_EXCEPTION_ACTIONS_MAPPINGS, fetchConsignmentStatusActions) || [])
            ];
}

export const generateActionOptions = actionArray => {
    if (!actionArray || actionArray.length < 1) {
        return [];
    }
    return actionArray.map(actionConstants => ({
        label : ENTITY_EXCEPTION_ACTIONS[actionConstants]?.label || actionConstants,
        value : actionConstants,
        key   : actionConstants
    }))
}

export const performAction = actionObject => new Promise((resolve, reject) => {
    apiRequest({
        url    : "/v1/entity-exceptions",
        method : "PUT",
        data   : actionObject
    })
    .then(resp =>{
        if (!checkHttpStatus(resp?.data?.status)) {
            reject(resp?.data?.status)
        }
        const response = resp?.data?.response;
        if (response?.responses[0]?.proceed || response?.responses[0]?.processed) {
            resolve(response?.responses[0])
        }
        else {
            reject(response?.responses[0]);
        }
    })
    .catch(error => reject(error))
})