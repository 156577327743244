import { Checkbox, Icon, Select } from "antd";
import React, { useState } from "react";

export default function SelectFilters ({
    onChange,
    options,
    placeholder,
    selectedOptions,
    filterKey,
    noLabelInValue,
    singleOptionOnly
}) {

    const [ selectedPlaceholder, SetSelectedPlaceholder ] = useState(placeholder)

    return (
        <Select className="select-filter border-radius-m" style={{ width : 200 }} showSearch={true}
            allowClear={true} labelInValue={noLabelInValue ? false : true}
            dropdownStyle={{ borderRadius : 10, marginTop : 10 }} dropdownMenuStyle={{ padding : 0 }}
            showArrow={true} menuItemSelectedIcon={<></>}
            filterOption={(input, option) => option?.props?.label?.toLowerCase().indexOf(input?.toLowerCase()) > -1}
            mode={singleOptionOnly ? "single" : "multiple"} maxTagCount={0} optionFilterProp="children" placeholder={placeholder}
            maxTagPlaceholder={selectedPlaceholder} value={singleOptionOnly ? selectedOptions : (selectedOptions || [])}
            onChange={value => onChange(filterKey, value)}
            onSearch={val => {
                if (val.trim() === "") {
                    SetSelectedPlaceholder(placeholder);
                }
                else {
                    SetSelectedPlaceholder(" ");
                }
            }} onBlur={_ => SetSelectedPlaceholder(placeholder)} >
            {
                options && options.map((opt, index) => (
                    <Select.Option className="spacer-s" value={opt.value || opt.id} key={index}
                    style={{
                        padding      : "8px 12px",
                        borderBottom : index != options?.length - 1 ? "1px solid #DFE2E5" : "none",
                        borderRadius : index == 0 ? "10px 10px 0px 0px" : index == options?.length - 1 ? "0px 0px 10px 10px" : undefined
                    }} label={opt.searchText || opt.label || opt.name} >
                        <div className={"flex-box flex-gap-l align-items-center" +
                        (
                            singleOptionOnly ?
                            (selectedOptions == (opt.value || opt.id) ? " info-color" : "") :
                            (selectedOptions?.map(selectedOpt => selectedOpt.key || selectedOpt)?.indexOf(opt.value || opt.id) > -1 ? " info-color" : ""))
                        }>
                        {
                        !singleOptionOnly 
                        && <Checkbox checked={selectedOptions?.map(opt => opt.key || opt)?.indexOf(opt.value || opt.id) > -1}/> 
                        }
                        <div label={opt.searchText || opt.label || opt.name} className="break-all"> {opt.label || opt.name} </div>
                        </div>
                    </Select.Option>
                ))
            }
        </Select>
    )
}