import { useState } from "react"
import { canAllow } from "../../common/utils/utils"
import { permissionTags } from "../../configs/permissionTags"
import { Button } from "antd"
import { apiService } from "../../common/apiService/service"

export default function PodOtp({ waybill }) {

    const [ otpData, SetOtpData ] = useState({
        fetchingPodOtp : false,
        otp : undefined,
        error : false
    })

    const requestForOtp = _ => {
        apiService.get(`helix/control/otp?waybillNo=${waybill}`)
        .then(response => {
            SetOtpData({
                error : false,
                otp : response?.otp,
                fetchingPodOtp : false
            });
        })
        .catch(error => {
            SetOtpData({
                error : error?.errors,
                otp : false,
                fetchingPodOtp : false
            });
        })
    }

    return (
        <div>
            {
                canAllow(permissionTags.pod.otp.read) ? 
                    otpData.otp === undefined ?
                    <Button type='primary' loading={otpData.fetchingPodOtp}
                    onClick={requestForOtp}> Show OTP </Button> :
                    otpData.error != false ?
                    <>{otpData.error}</> :
                    <strong>{otpData.otp}</strong> :
                <>You do not have access to view Delivery OTP</>
            }
        </div>
    )
}