import { checkHttpStatus } from "../../../common/utils/utils";
import ScanService from "../../../services/inbound/scan.service";
import * as _Constants from './constants';

const scanService = new ScanService

export const consigmentScan = (payload) => dispatch => {
    dispatch(consigmentScanBegin());
    scanService.consigmentScan(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(consigmentScanSuccess(res ?.data ?.response));
        } else {
            dispatch(consigmentScanFailure(res ?.data ?.response));
        }
    });
};

function consigmentScanBegin() {
    return {
        type: _Constants.CONSIGMENTS_SCAN_BEGIN
    };
}

function consigmentScanSuccess(payload) {
    return {
        type: _Constants.CONSIGMENTS_SCAN_SUCCESS,
        payload: payload
    };
}

function consigmentScanFailure(error) {
    return {
        type: _Constants.CONSIGMENTS_SCAN_FAILURE,
        payload: error
    };
}

export const uploadBooking = (payload) => dispatch => {
    dispatch(uploadBookingBegin());
    scanService.uploadBooking(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(uploadBookingSuccess(res ?.data ?.response));
        } else {
            dispatch(uploadBookingFailure(res ?.data ?.response));
        }
    });
};

export const uploadInscan = (payload) => dispatch => {
    dispatch(uploadBookingBegin());
    scanService.uploadInscan(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(uploadBookingSuccess(res ?.data ?.response));
        } else {
            dispatch(uploadBookingFailure(res ?.data ?.response));
        }
    });
};

export const consignmentLiteAction = (payload) => dispatch => {
    dispatch(uploadBookingBegin());
    scanService.consignmentLiteAction(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(uploadBookingSuccess(res ?.data ?.response));
        } else {
            dispatch(uploadBookingFailure(res ?.data ?.response));
        }
    });
};

function uploadBookingBegin() {
    return {
        type: _Constants.UPLOAD_BOOKING_BEGIN
    };
}

function uploadBookingSuccess(payload) {
    return {
        type: _Constants.UPLOAD_BOOKING_SUCCESS,
        payload: payload
    };
}

function uploadBookingFailure(error) {
    return {
        type: _Constants.UPLOAD_BOOKING_FAILURE,
        payload: error
    };
}