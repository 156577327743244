import { Spin } from "antd";
import React from "react";
import { formatCurrency } from "../../../common/utils/utils";

export default function SparklineCard({ title, highlightText, loading, showSparklineSummary, toggleSparklineView }) {
    
    return (
        <div className="flex-1 flex-column border-grey flex-gap-l justify-content-space-between white-bg spacer-m border-radius-m">
            <div className="flex-box align-items-center flex-gap-m">
                <div className="light-grey-text font-size-m-3" style={{ textTransform : "uppercase" }}> {title} </div>
            </div>
            {
                showSparklineSummary ?
                <div className="text-semi-bold font-size-l-3">
                    {
                        loading ? <Spin spinning={true} /> : formatCurrency(highlightText)
                    }
                </div> :
                <a className="font-size-l-1" onClick={_ =>  toggleSparklineView()}> View Numbers </a>
            }
        </div>
    )
}