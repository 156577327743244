import React from "react";
import {
    Dropdown,
    Button,
    Menu,
    Icon,
    Tooltip,
} from "antd";

import "./reasons-dropdown.scss";

const ManifestReasonDropDown = ({reasons, entityCode, handleUpdate, disabled=false, isMobile, reason }) => {
    const handleSelect = (e) => {
        handleUpdate(e.key, entityCode);
    }

    const reasonCodesToHideFromUsers = [ "Delivered", "Lost" ];
    
    return(
        <>
            <Dropdown
                trigger={["click"]}
                placement={isMobile ? "bottomLeft" : "bottomCenter"}
                disabled={disabled}
                overlay={
                    (<Menu
                        selectable
                        onSelect={handleSelect}
                    >
                        <Menu.Item key="-1" disabled>Select Reason</Menu.Item>
                        {
                            reasons.map(reason => {
                                if (!reasonCodesToHideFromUsers.includes(reason.code)) {
                                    return(
                                        <Menu.Item  key={reason.id}>
                                            {reason.description}
                                        </Menu.Item>
                                    )
                                }
                            })
                        }
                    </Menu>)
                }
            >
                {
                    isMobile ? 
                    <Button className="lsn-btn-secondary" style={{height : "auto"}}> 
                        <div className="flex-box flex-gap-s align-items-center">
                            <Icon type="down"/>
                            <div style={{
                                maxWidth   : 100,
                                whiteSpace : "break-spaces",
                                wordBreak  : "break-word"
                            }}>
                                {reason ? reason : "Select a reason" }
                            </div>
                        </div>
                    </Button> :
                    <Button size="small" icon="more" className="reason-dropdown-button"/>
                }
            </Dropdown>
        </>
    )
};

export default ManifestReasonDropDown;
