import React, { useState } from "react";
import { connect } from "react-redux";
import {
    Table,
} from "antd";
import moment from "moment";
import { PRINT_ENTITY_TYPES } from "../../constants";
import PrintButton from "../print-component/print-component";

function ScannedHistoryTable({
    selectedEntity,
    scannedEntityHistory,
}) {
    const [pageSize, setPageSize] = useState(5);
    const column_types = {
        MANIFEST_STICKER_PRINT: [
            {
                key: "manifestCode",
                dataIndex: "manifestCode",
                title: "Manifest No"
            },
            {
                key: "originLocation",
                dataIndex: "originLocation",
                title: "Source"
            },
            {
                key: "destinationLocation",
                dataIndex: "destinationLocation",
                title: "Destination"
            },
            {
                key: "nextLocation",
                dataIndex: "nextLocation",
                title: "Next Location"
            },
            {
                key: "scanTime",
                dataIndex: "scanTime",
                title: "Scan Date & Time",
                render: (text) => <span>{moment(text).format("DD/MM/YYYY HH:mm:ss")}</span>
            },
            {
                key: "reScan",
                dataIndex: "rescan",
                title: "Reprint",
                render: (isReScan, row) => {
                    if(isReScan){
                        return(
                            <PrintButton 
                                buttonText="Print" 
                                isIconButton={true} 
                                entityCode={selectedEntity === PRINT_ENTITY_TYPES.MANIFEST_STICKER_PRINT ? row.manifestCode : ""}
                                entityData={row}
                            />
                        )
                    }
                }
            },
        ]
    };

    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
    };

    return(
        <Table
            columns={selectedEntity === PRINT_ENTITY_TYPES.MANIFEST_STICKER_PRINT ? column_types.MANIFEST_STICKER_PRINT : []}
            dataSource={scannedEntityHistory}
            rowKey={(row, index) => `${row.manifestCode}-${index}`}
            pagination={{
                showSizeChanger: true,
                position: "bottom",
                defaultPageSize: 5,
                pageSize: pageSize,
                total: scannedEntityHistory.length,
                defaultCurrent: 1,
                onShowSizeChange: onShowSizeChange,
                pageSizeOptions: ["5", "10", "15", "20", "30", "50"],
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
            }}
        />
    )
};

const mapStateToProps = (state) => ({
    selectedEntity: state.inventoryLabelPrinting.selectedEntity,
    scannedEntityHistory: state.inventoryLabelPrinting.scannedEntityHistory,
});

export default connect(
    mapStateToProps,
    null
)(ScannedHistoryTable);