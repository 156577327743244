import { apiRequest } from "../../../../common/utils/apiGateway";
import { Constants } from "../../../../common/utils/constants";
import { checkHttpStatus } from "../../../../common/utils/utils";

export const fetchExceptions = params => new Promise((resolve, reject) => {
    apiRequest({
        url    : `/v1/entity-exceptions/fetch?locationId=${Constants.userLocationId}&pageNo=${params.pageNo}&pageSize=${params.pageSize}`,
        method : "POST",
        data   : params
    })
    .then(response => {
        if(!checkHttpStatus(response?.data?.status)) {
            reject(response?.data?.status);
            return;
        }
        if(response?.data?.response) {
            resolve(response?.data?.response);
        }
    })
    .catch(error => reject(error))
})