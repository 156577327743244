import { Table, Tag } from "antd";
import moment from "moment";
import React from "react";
import { capitalizeString } from "../../../../common/utils/utils";
import WaybillLink from "../../../shared-components/waybill-link";
import { generateActionOptions, getActionArray } from "../services/action.service";
import { EXCEPTION_TYPE } from "../state/constants";
import ExceptionsActionModal from "./action.modal";

export default function ExceptionsTable({ exceptionResponseObject, onPageChange }) {

    const columns = [
        {
            title : "AWB No",
            key   : "entityValue",
            dataIndex : "entityValue",
            render    : (entityValue, exception) => {
                if (exception?.entityType === "WAYBILL") {
                    return <WaybillLink>{entityValue}</WaybillLink>
                }
                else {
                    return <> {entityValue} </>
                }
            }
        },
        {
            title : "Exception Type",
            key   : "updatedStatus",
            dataIndex : "updatedStatus",
            render    : updatedStatus => updatedStatus && EXCEPTION_TYPE[updatedStatus] ? EXCEPTION_TYPE[updatedStatus].label : updatedStatus
        },
        {
            title : "Consignment Status",
            key   : "entityStatus",
            dataIndex : "entityStatus"
        },
        {
            title : "Movement Type",
            key   : "movementType",
            dataIndex : "movementType",
            render    : movementType => movementType ? (movementType === "RTO" ? movementType : capitalizeString(movementType.toLowerCase())) : ""
        },
        {
            title : "Last Updated At",
            key   : "lastUpdatedAt",
            dataIndex : "lastUpdatedAt",
            render    : date => moment(date).format("DD/MM/YYYY hh:mm A")
        },
        {
            title : "Expected Location",
            key   : "expectedLocation",
            dataIndex : "expectedLocation"
        },
        {
            title : "Current Location",
            key   : "escalatedLocation",
            dataIndex : "escalatedLocation"
        },
        {
            title : "Exception Status",
            key   : "status",
            dataIndex : "status",
            render    : status => {
                const tagReference = {
                    OPEN      : {
                        label : "Open",
                        class : "warning-tag"
                    },
                    NEEDS_ACTION : {
                        label    : "Needs Action",
                        class    : "info-tag"
                    },
                    RESOLVED  :   {
                        label : "Resolved",
                        class : "success-tag"
                    }
                }
                return <Tag className={"ant-table-tag " + tagReference[status]?.class}> {tagReference[status]?.label || status} </Tag>
            }
        },
        {
            title : "Action",
            key   : "entityId",
            dataIndex : "entityId",
            render    : (id, row) => <ExceptionsActionModal entityCount={1} entityId={id}
                                      exception={row} actionArray={generateActionOptions(getActionArray(row))} 
                                      onActionComplete={onPageChange}/>
        }
        
    ]

    return (
        <div className="spacer-m">
            <Table columns={columns} bordered={true} size="medium"
             dataSource={exceptionResponseObject ? exceptionResponseObject?.entityExceptions : []}
             pagination={{
                total    : exceptionResponseObject ? exceptionResponseObject?.totalCount : 0,
                pageSize : exceptionResponseObject ? exceptionResponseObject?.currentPageSize : 0,
                current  : exceptionResponseObject ? exceptionResponseObject?.currentPageNo : 0,
                onChange : pageNo => onPageChange(pageNo),
                showTotal : (total, range) => {
                    return <>{range[0]} - {range[1]} of {total}</>;
                }
             }} />
        </div>
    )
}