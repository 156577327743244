import { apiRequest } from "../../common/utils/apiGateway";

export default class PaymentStatusService {
  getPayments(data) {
    const url = `/b2c/v1/payments/fetch`;
    return apiRequest({
      url,
      method: "POST",
      data
    });
  }
}
