const INITIAL_STATE = {
    noHeader  : false,
    noSideBar : false,
    noTabs    : false,
    selectedModule : "leads",
    selectedSubmodule : "manage-pickup",
    moduleDetails  : {}
}

export const LAYOUT_CONSTANTS = {
    UPDATE_SELECTED_MODULE    : "UPDATE_SELECTED_MODULE",
    UPDATE_LAYOUT             : "UPDATE_LAYOUT",
    UPDATE_SUBMODULE_TABS     : "UPDATE_SUBMODULE_TABS",
    UPDATE_SELECTED_SUBMODULE : "UPDATE_SELECTED_SUBMODULE"
}

export const LayoutReducer = (state = INITIAL_STATE, action) => {
    switch (action?.type) {
        case LAYOUT_CONSTANTS.UPDATE_SELECTED_MODULE : return {
            ...state,
            selectedModule : action.payload
        }
        case LAYOUT_CONSTANTS.UPDATE_SELECTED_SUBMODULE : return {
            ...state,
            selectedSubmodule : action.payload
        }
        case LAYOUT_CONSTANTS.UPDATE_LAYOUT : return {
            ...state,
            noHeader  : action.payload?.noHeader,
            noSideBar : action.payload?.noSideBar,
            noTabs    : action.payload?.noTabs,
            isMobile  : action.payload?.isMobile,
            moduleObj : action.payload
        }
        case LAYOUT_CONSTANTS.UPDATE_SUBMODULE_TABS : return {
            ...state,
            moduleDetails : action.payload
        }
        default : return state;
    }
}