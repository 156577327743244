import { apiRequest } from '../../../../common/utils/apiGateway';
import { Constants } from '../../../../common/utils/constants';
import { notifyApiError } from '../../../../common/utils/utils';
import UserService from '../../../../services/settings/user.service';
import ScanService from '../../../../services/inbound/scan.service';
import store from '../../../../utils/store';
import { ACTION_TYPES } from '../constants';
import { apiService } from '../../../../common/apiService/service';

const userService = new UserService();
const scanService = new ScanService();

const dispatch = store.dispatch;
const rtoDrsStore = store.getState().rtoDrs;

export const updateRTODRSCode = _ => new Promise(resolve => {
    dispatch({
        type: ACTION_TYPES.FETCH_SPARK_LINE_DATA__BEGIN
    })
    generateCode().then(drsCode => {
        dispatch({
            type: ACTION_TYPES.UPDATE_RTO_DRS_CODE,
            drsCode: drsCode
        })
        resolve(drsCode);
    })
})

export const generateCode = _ => new Promise(resolve => {
    apiRequest({
        url: "b2b/v1/fetch/code?code=RTO_DRS",
        method: "GET"
    })
        .then(response => {
            if (response.data.response) {
                resolve(response.data.response.code)
            }
        })
})

export const fetchDRSDetails = id => new Promise(resolve => {
    apiRequest({
        url: `b2c/v1/rto/drs/${id}`,
        method: "GET"
    })
        .then(response => {
            if (response.data.response) {
                resolve(response.data.response.drses[0])
            }
        })
})

export const getFEUsers = refresh => new Promise(resolve => {
    if (refresh || !rtoDrsStore.feUsers) {
        fetchFEUsers().then(({ users }) => {
            const enums = [];
            const enumNames = [];
            users.map(({ id, name }) => {
                enums.push(id);
                enumNames.push(name)
            })
            dispatch({
                type: ACTION_TYPES.FETCH_FE_EMPLOYEES_RTO,
                payload: { enum: enums, enumNames }
            })
            resolve({ enum: enums, enumNames })
        })
    }
    else if (rtoDrsStore.feUsers) {
        return rtoDrsStore.feUsers;
    }
})

export const fetchFEUsers = _ => new Promise((resolve, reject) => {
    userService.getUsersByLocation(Constants.userLocationId, { loggedInUserTokken: true }).then(response => {
        if (response.data.response) {
            resolve(response.data.response)
        }
        else {
            reject(response.data)
        }
    })
})

export const getCustomerLocationsOptions = refresh => new Promise(resolve => {
    if (refresh || !rtoDrsStore.pickupLocations) {
        fetchCustomerLocations().then(({ locations }) => {
            const enums = [];
            const enumNames = [];
            locations.map(({ id, name }) => {
                enums.push(id);
                enumNames.push(name)
            })
            dispatch({
                type: ACTION_TYPES.FETCH_CUSTOMER_LOCATION,
                payload: { enum: enums, enumNames }
            })
            resolve({ enum: enums, enumNames })
        })
    }
    else if (rtoDrsStore.pickupLocations) {
        return rtoDrsStore.pickupLocations;
    }
})

export const fetchCustomerLocations = _ => new Promise((resolve, reject) => {
    scanService.getPartnerCenter({
        locIds: [Constants.userLocationId],
        partnerIds: [Constants.PARTNER_ID]
    }).then(response => {
        if (response.data.response) {
            resolve(response.data.response)
        }
        else {
            reject(response.data)
        }
    })
})

export const fetchPendingConsignments = _ => new Promise((resolve, reject) => {
    scanService.rtsUnassigned({ is_arrived: true }).then(response => {
        if (response.data.response) {
            resolve(response.data.response)
        }
        else {
            reject(response.data)
        }
    })
})

const individualRequestObjectGen = (updatedData, action, consignments) => {
    return {
        "drs": {
            "id": updatedData.id,
            "drsStatus": "PENDING",
            "drsType": "B2C",
            "partner": { "id": Constants.PARTNER_ID },
            "originatedLoc": { "id": Constants.userLocationId },
            "drsCode": updatedData.drsCode,
            "drsUser": { id: updatedData.drsUser.key },
            "customerLocation": { id: updatedData.customerLocation.key },
            "consignments": consignments ? consignments.map(wb => ({
                "id": wb.id,
                "waybillNo": wb.waybillNo
            })) : [],
        },
        "drsCode": updatedData.drsCode,
        "referenceId": updatedData.drsCode,
        "dontBotherArrival": true,
        "action": action
    }
}
export const formRTODRSRequestObjecsts = (updatedData, originalDrs) => {
    if (updatedData.action === "CREATE") {
        return {
            "request": [individualRequestObjectGen(updatedData, updatedData.action, [])],
            "is_arrived": true
        }
    }
    else {
        const originalWaybills = originalDrs.selectedWaybills.map(wb => wb.waybillNo)
        const updatedWaybills = updatedData.selectedWaybills.map(wb => wb.waybillNo)
        const linkWaybills = updatedData.selectedWaybills.filter(wb => originalWaybills.indexOf(wb.waybillNo) < 0)
        const delinkWaybills = originalDrs.selectedWaybills.filter(wb => updatedWaybills.indexOf(wb.waybillNo) < 0);
        var request = [];
        if (linkWaybills.length > 0) {
            request.push(individualRequestObjectGen(updatedData, "LINK", linkWaybills))
        }
        if (delinkWaybills.length > 0) {
            request.push(individualRequestObjectGen(updatedData, "DELINK", delinkWaybills))
        }
        if (request.length > 0) {
            return {
                request,
                is_arrived: true
            }
        }
        else {
            return false;
        }
    }
}

export const createUpdateRTODRS = postObj => new Promise((resolve, reject) => {
    apiRequest({
        url: "b2c/v1/rto/drs/update",
        method: "POST",
        data: postObj
    }).then(response => {
        if (response.data && response.data.response && response.data.response.responses && response.data.response.responses[0]) {
            if (response.data.response.responses[0].sync) {
                resolve(response.data.response.responses[0]);
            }
            else {
                notifyApiError(response.data.response.responses[0].reason)
                reject(response.data.response.responses[0])
            }
        }
    })
        .catch(e => reject(e))
})

export const bulkUploadRTODRS = data => apiRequest({
    url: "b2b/v1/uploads/rtodrs",
    method: "POST",
    data: data
})

export const formValidateObject = (drs, consignment, action) => {
    return {
        "request": [
            {
                drs: {
                    "id": drs.id,
                    "drsStatus": "PENDING",
                    "drsType": "B2C",
                    "partner": { "id": Constants.PARTNER_ID },
                    "originatedLoc": { "id": Constants.userLocationId },
                    "drsCode": drs.drsCode,
                    "drsUser": { id: drs.drsUser.key },
                    "customerLocation": { id: drs.customerLocation.key },
                    consignments: (action == "DELINK" ? [
                        {
                            id: consignment.id,
                            waybillNo: consignment.waybillNo,
                        }
                    ] : [
                        {
                            waybillNo: consignment.waybillNo,
                            scanInputType: "KEYBOARD"
                        }
                    ])

                },
                "drsCode": drs.drsCode,
                "referenceId": drs.drsCode,
                "dontBotherArrival": true,
                "action": action
            }
        ]
    }
}

export const linkOrDelinkConsignment = postObject => new Promise((resolve, reject) => {
    apiRequest({
        url: "b2c/v1/rto/drs/update",
        method: "POST",
        data: postObject
    })
        .then(response => {
            if (response.data && response.data.response.responses && response.data.response.responses.length > 0) {
                if (response.data.response.responses[0].sync) {
                    if (response.data.response.responses[0]?.drsBO?.consignments?.[0]?.id) {
                        resolve(response.data.response.responses[0].drsBO?.consignments?.[0])
                    }
                    else {
                        reject({
                            ...response.data.response.responses[0],
                            noWaybill: true
                        });
                    }
                }
                else {
                    reject(response.data.response.responses[0])
                    notifyApiError(response.data.response.responses[0].reason, "Error")
                }
            } else {
                notifyApiError(response.data, "Error")
            }

        })
})

export const createRtoPod = (reqBody) => {
    return apiService.post("lm/v1/rto/drs/consignment/pod", reqBody)
}

export const getRtoDrs = (drsCode) => {
    return apiService.get(`lm/v1/rto/drs/${drsCode}`)
}

export const fetchFeList = () => {
    return apiService.get('fefmlm/riders')
}