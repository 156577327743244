import React from "react";
import { useSelector } from "react-redux";
import Barcode from "../../../../ui/barcode";
import { originDetails, orderDetails, RowDetails } from "../trip.sheet";

export default function ConnectionPrint({trip, connection}) {

    const destinationDetails = [
        {
            name : "Destination Hub Code",
            value : "locationName"
        },
        {
            name : "Destination Address",
            value : "address"
        },
        {
            name : "Movement Type",
            value : "tripType"
        }
    ]
    
    const transporterDetails = [
        {
            name  : "Transporter Name",
            value : "transporterName"
        },
        {
            name  : "Driver Name",
            value : "driverName"
        },
        {
            name  : "Vehicle Number",
            value : "vehicleNumber"
        },
        {
            name  : "Vehicle Type",
            value : "vehicleType"
        }
    ]

    const appConfig = useSelector(state => state?.app?.configurations)
    const { is_unload_validation_enabled } = appConfig ? appConfig : {};
    const isUnloadValidationEnabled = is_unload_validation_enabled + "" === 'true';

    return (
        <div style={{
            width     : "100%",
            padding   : "0 10%",
            textAlign : "center",
            boxSizing : "border-box",
            marginBottom : 25
        }}>

            <table className="black-bordered" width={"100%"} style={{marginBottom : 20}}>
                <tbody>
                    <tr className="text-align-center">
                        <th className="spacer-s"> {isUnloadValidationEnabled ? "Challan/Trip ID" : "Connection ID" } </th>
                    </tr>
                    <tr className="text-align-center">
                        <th>
                            <div className="spacer-vxs flex-column justify-content-center align-items-center">
                                <Barcode
                                width={2} format="CODE128"
                                value={isUnloadValidationEnabled ? trip?.tripCode : connection?.connectionCode}
                                showText={false}
                                // height={isUnloadValidationEnabled ? 60 : undefined}
                                barCodeId={`${trip?.tripCode}-${connection?.connectionCode}`}
                                />
                                <div className="font-semi-bold font-size-l-1" style={{ color : "#000", fontWeight : "400", letterSpacing: 1}}>
                                    {isUnloadValidationEnabled ? trip?.tripCode : connection?.connectionCode}
                                </div>
                            </div>
                        </th>
                    </tr>
                </tbody>
            </table>
            
            <div className="flex-box align-items-stretch flex-gap-xl">

                <div className="flex-1">
                    <table className="black-bordered" width={"100%"} height={"100%"}>
                        <tbody>
                            <tr className="text-align-center">
                                <th className="spacer-s" colSpan={4} > Origin Details </th>
                            </tr>
                            {
                                originDetails.map(d => {
                                    return RowDetails(d?.name, connection?.originDetails?.[d?.value], d?.dataType);
                                })
                            }

                            <tr className="text-align-center">
                                <th className="spacer-s" colSpan={4} > Order Details </th>
                            </tr>
                            {
                                orderDetails.map(d => {
                                    return RowDetails(d?.name, connection?.orderDetails?.[d?.value]);
                                })
                            }
                        </tbody>
                    </table>
                </div>

                <div className="flex-1">
                    <table className="black-bordered" width={"100%"}>
                        <tbody>
                            <tr className="text-align-center">
                                <th className="spacer-s" colSpan={4} > Destination Details </th>
                            </tr>
                            {
                                destinationDetails.map(d => {
                                    return RowDetails(d?.name, connection?.destinationDetails?.[d?.value]);
                                })
                            }

                            <tr className="text-align-center">
                                <th className="spacer-s" colSpan={4} > Transporter Details </th>
                            </tr>
                            {
                                transporterDetails.map(d => {
                                    return RowDetails(d?.name, connection?.transporterDetails?.[d?.value]);
                                })
                            }
                            {
                                isUnloadValidationEnabled &&
                                <tr>
                                    <th className="spacer-s" colSpan={1} > Connection ID </th>
                                    <th colSpan={3} > 
                                        <div className="spacer-vxs flex-column justify-content-center align-items-center">
                                            <Barcode
                                            width={2} format="CODE128"
                                            value={connection?.connectionCode} 
                                            showText={false}
                                            //  height={40}
                                            barCodeId={`__${connection?.connectionCode}-barcode`}/>
                                            <div className="font-semi-bold font-size-l-1" style={{ color : "#000", fontWeight : "400", letterSpacing: 1}}>
                                                {connection?.connectionCode}
                                            </div>
                                         </div>
                                    </th>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}