import React, { useEffect } from "react";
import { Alert, Button, Form, Icon, Input } from "antd";
import ClosureScanCard from "../closure-scan-card";
import { ENTITY_STATUS, ENTITY_TYPES, REASONS, SPARK_LINE_TYPES } from "../constants";
import ManifestUnloadSparkline from "../sparkline";

export default function ManifestUnloadCardNoWrapper({
    entityType,
    status,
    pendingCount,
    scannedCount,
    overageCount,
    isValidated,
    handleCloseConnection,
    handleSparklineClick,
    isMobile,
    code,
    scannedAtDestination,
    handleScanEntity,
    handleScanEntityChange,
    scannedEntityCode,
    currentScannedEntityInfo,
    handleValidate,
    handleUnloadEntity,
    failedWaybillList,
    loading,
    entityInfo,
    pickupData
}) {

    useEffect(() => {
        refocusOnInput();
    }, [ currentScannedEntityInfo ])

    const refocusOnInput = _ => {
        const scanInput = document.getElementById("scanInput");
        if (!scanInput) return;
        scanInput.blur();
        scanInput.setAttribute("readonly", "readonly");
        scanInput.focus();
        scanInput.removeAttribute("readonly");
    }

    return (
        <>
            <div className={"manifest-unload-card-body-container" + (isMobile ? " mweb-wrapper" : "")} >
                <div className="mainfest-unload-card-info-container">
                    <div className={"manifest-unload-card-header"}>
                        <span className="flex-box align-items-flex-end flex-gap-m">
                            <div>{ entityType === ENTITY_TYPES.connection.name ? "Connection ID" : "Manifest ID"}: {code}</div>
                            { entityInfo.overageStatus == "OVERAGE" && (<div className="error-color font-size-m-2"> Overage Bag</div>) }
                            { !isMobile && status === ENTITY_STATUS[entityType].unloading && (<div className="manifest-unload-card-flag-span"> Unloading</div>)}
                        </span>
                        {
                            isMobile &&
                            <div style={{color: "#2233A5"}}> Total No. of {entityType === ENTITY_TYPES.connection.name ? "manifests" : "waybill"} - {pendingCount + scannedCount} </div>
                        }
                    </div>
                    <div className="manifest-unload-card-info-buttons">
                        {
                            !isValidated 
                            && status === ENTITY_STATUS[entityType].unloading && (
                            <Button type="primary" onClick={handleValidate} > Validate </Button>
                        )}
                        {isValidated && (
                            <Button
                                type="primary"
                                disabled={!isValidated}
                                onClick={handleCloseConnection}
                            >Close { entityType === ENTITY_TYPES.connection.name ? "Connection" : "Manifest"}</Button>
                        )}
                        {
                            !(scannedAtDestination === false) && 
                            <Button type="primary" onClick={handleUnloadEntity}
                             disabled={status !== (entityType === ENTITY_TYPES.connection.name ? ENTITY_STATUS[entityType].started : ENTITY_STATUS[entityType].arrived) } >
                                Unload
                            </Button>
                        }
                    </div>
                </div>

                <div className={"manifest-unload-card-sparkline-container" }>
                    <ManifestUnloadSparkline 
                        title={SPARK_LINE_TYPES[entityType].pending.label}
                        count={pendingCount} 
                        handleSparklineClick={() => handleSparklineClick(SPARK_LINE_TYPES[entityType].pending.label, REASONS.pending)}
                        reasonStatus={REASONS.pending}
                        disabled={!scannedAtDestination}
                        isMobile={isMobile}
                    />
                    <ManifestUnloadSparkline 
                        title={SPARK_LINE_TYPES[entityType].scanned.label}
                        count={scannedCount}
                        handleSparklineClick={() => handleSparklineClick(SPARK_LINE_TYPES[entityType].scanned.label, REASONS.scanned)} 
                        reasonStatus={REASONS.scanned}
                        disabled={!scannedAtDestination}
                        isMobile={isMobile}
                    />
                    <ManifestUnloadSparkline
                        title={SPARK_LINE_TYPES[entityType].overage.label}
                        count={overageCount} 
                        handleSparklineClick={() => handleSparklineClick(SPARK_LINE_TYPES[entityType].overage.label, REASONS.overage)} 
                        reasonStatus={REASONS.overage}
                        disabled={!scannedAtDestination}
                        isMobile={isMobile}
                    />
                </div>
                
                {
                    entityInfo && entityInfo?.scannedAtDestination === false && 
                    <div style={{ marginTop : 10 }} className="flex-box flex-gap-xl spacer-m grey-bg border-grey border-radius-s">
                        {
                            entityInfo?.nextLocation &&
                            <div className="flex-column flex-gap-s">
                                <div className="font-size-m-1 text-semi-bold"> Next Location </div>
                                <div className="font-size-m-3"> {entityInfo?.nextLocation} </div>
                            </div>
                        }
                        {
                            entityInfo?.status &&
                            <div className="flex-column flex-gap-s spacer-hm">
                                <div className="font-size-m-1 text-semi-bold"> Manifest Status </div>
                                <div className="font-size-m-3"> {entityInfo?.status} </div>
                            </div>
                        }
                        {
                            entityInfo?.pendingCount &&
                            <div className="flex-column flex-gap-s">
                                <div className="font-size-m-1 text-semi-bold"> Shipment Count </div>
                                <div className="font-size-m-3"> {entityInfo?.pendingCount} </div>
                            </div>
                        }
                        {/* {
                            entityInfo?.nextAction &&
                            <div className="flex-column flex-gap-s">
                                <div className="font-size-m-1 text-semi-bold"> Next Action </div>
                                <div className="font-size-m-3"> {entityInfo?.nextAction} </div>
                            </div>
                        } */}
                    </div>
                }

                <div className="manifest-unload-card-scan-input">
                    { status === ENTITY_STATUS[entityType].unloading && (
                        <Form layout="vertical"
                         onSubmit={e => {
                            e.preventDefault();
                            handleScanEntity();
                        }} >
                            <Form.Item style={{marginBottom: 0}}
                                label={entityType === ENTITY_TYPES.connection.name ? "Scan Manifest Number" : "Scan Waybill Number"}
                            >
                                <Input.Search
                                    placeholder={entityType === ENTITY_TYPES.connection.name ? "Scan Manifest Number" : "Scan Waybill Number"}
                                    allowClear autoComplete="off"
                                    onChange={handleScanEntityChange}
                                    value={scannedEntityCode}
                                    disabled={loading}
                                    autoFocus={true}
                                    id="scanInput"
                                    addonAfter={
                                        <span 
                                            onClick={handleScanEntity}
                                            style={{
                                                cursor: !scannedEntityCode.length ? "no-drop" : "pointer",
                                                pointerEvents: !scannedEntityCode.length ? "none" : "auto",
                                                display: "flex", 
                                                alignItems: "center",
                                                gap : 5
                                            }}
                                        >
                                            <Icon type="barcode" /> Scan
                                        </span>
                                    }
                                />
                            </Form.Item>
                        </Form>
                    )}
                </div>

                {
                    isMobile && failedWaybillList && failedWaybillList.length > 0 &&
                    <div className="spacer-hm">
                        <div className="error-bg error-border border-radius-s spacer-xs flex-column flex-gap-xs">
                            <div className="error-color text-semi-bold"> Failed Waybill List </div>
                            {
                                failedWaybillList.map(l => <div key={l}>{l}</div>)
                            }
                        </div>
                    </div>
                }

                <div className="manifest-unload-card-list">
                    {currentScannedEntityInfo && (
                        <ClosureScanCard  refocusOnInput={refocusOnInput}
                            scannedEntityInfo={currentScannedEntityInfo}
                            entityType={entityType}
                            isMobile={isMobile}
                            pickupData={pickupData}
                        />
                    )}
                </div>
                
                {isValidated && (
                    <div className="manifest-unload-validated">
                        <div className="manifest-unload-validated-info">
                            <Alert message="Successfully Validated!" type="success" showIcon />
                        </div>
                    </div>
                )}
                
            </div>
        </>
    )
}