import { apiService } from "../../../common/apiService/service";
import { Constants } from "../../../common/utils/constants";

export const fetchDeliveryExecutives = () => {

    const queryParams = {
        "loggedInUserTokken": true
    }

    return apiService.get(`b2b/v1/partners/${Constants.PARTNER_ID}/locations/${Constants.userLocationId}/users`, { queryParams: queryParams })
}

export const validateFe = (userId) => {
    return apiService.get(`lm/v1/drs/user/${userId}`)
}

export const scanWaybill = (reqBody) => {
    return apiService.post('b2b/v1/consignments/scan', reqBody)
}

export const closeDrs = (feId, reqBody) => {
    return apiService.put(`lm/v1/drs/close/user/${feId}`, reqBody)
}

export const undelScan = (waybillNo, scanType) => {

    const queryParams = {
        "scanInputType": scanType
    }
    return apiService.put(`lm/v1/drs/consignment/${waybillNo}/undel-scan` , { queryParams: queryParams })
}

export const cancelOrder = (waybillNo) => {
    return apiService.put(`lm/v1/drs/consignment/${waybillNo}/reject
    `)
}

export const reattemptOrder = (waybillNo) => {
    return apiService.put(`lm/v1/drs/consignment/${waybillNo}/reattempt`)
}

export const fetchFeList = () => {
    return apiService.get('fefmlm/riders')
}