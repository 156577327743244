import * as ACTION_CONSTANTS from "./constants";
import {
  Constants,
  LOCAL_STORAGE_STATE,
  USER_LOGGEDIN_INFO
} from "../../common/utils/constants";
import { localStorageUtils } from "../../common/utils/utils";
import * as utils from "../../common/utils/utils";
import * as apiUtils from "../../common/utils/apiGateway";

export const getSuspiciousWaybills = (paramData = {}) => {
  return dispatch => {
    dispatch(getSuspiciousWaybillsBegin());
    let filterParams = utils.getFilterParams();
    const partnerId = JSON.parse(
      localStorageUtils({
        key: USER_LOGGEDIN_INFO.CURRENT_PARTNER_ID,
        type: LOCAL_STORAGE_STATE.GET
      })
    );
    const locationId = JSON.parse(
      localStorageUtils({
        key: USER_LOGGEDIN_INFO.CURRENT_PARTNER_LOCATION_ID,
        type: LOCAL_STORAGE_STATE.GET
      })
    );
    filterParams = {
      ...filterParams,
      conStatus: "UNDEL",
      partnerIds: Constants.PARTNER_ID,
      locationIds: Constants.LOGGED_PARTNER_LOCATION_ID,
      isSuspicious: true,
      // currentDatetime: 1551033000000,
      ...paramData,
      currentDatetime: new Date().setHours(0, 0, 0, 0)
    };
    return apiUtils
      .apiRequest({
        url: "/b2b/v1/consignmentpod/streams",
        params: filterParams
      })
      .then(response => {
        dispatch(getSuspiciousWaybillsSuccess(response.data.response));
      })
      .catch(error => dispatch(getSuspiciousWaybillsFailure(error)));
  };
};

export const searchSuspiciousWaybills = searchText => {
  return dispatch => {
    dispatch(getSuspiciousWaybillsBegin());
    let filterParams = utils.getFilterParams();
    filterParams = {
      ...filterParams,
      conStatus: "UNDEL",
      isSuspicious: 1,
      sq: searchText,
      currentDatetime: new Date().setHours(0, 0, 0, 0)
    };
    return apiUtils
      .apiRequest({
        url: "/b2b/v1/consignmentpod/streams",
        params: filterParams
      })
      .then(response => {
        dispatch(getSuspiciousWaybillsSuccess(response.data.response));
      })
      .catch(error => dispatch(getSuspiciousWaybillsFailure(error)));
  };
};

export const getSuspiciousWaybillsBegin = () => {
  return {
    type: ACTION_CONSTANTS.GET_SUSPICIOUS_WAYBILLS_BEGIN
  };
};

export const getSuspiciousWaybillsSuccess = response => {
  return {
    type: ACTION_CONSTANTS.GET_SUSPICIOUS_WAYBILLS_SUCCESS,
    payload: response
  };
};

export const getSuspiciousWaybillsFailure = error => {
  return {
    type: ACTION_CONSTANTS.GET_SUSPICIOUS_WAYBILLS_FAILURE,
    payload: error
  };
};

export const getSuspiciousWaybillDetails = streamId => {
  return dispatch => {
    dispatch(getSuspiciousWaybillDetailsBegin());
    apiUtils
      .apiRequest({
        url: `/b2b/v1/consignmentpod/streams/${streamId}`
      })
      .then(response => {
        dispatch(getSuspiciousWaybillDetailsSuccess(response.data.response));
      })
      .catch(error => dispatch(getSuspiciousWaybillDetailsFailure(error)));
  };
};

const getSuspiciousWaybillDetailsBegin = () => {
  return {
    type: ACTION_CONSTANTS.GET_SUSPICIOUS_WAYBILL_DETAILS_BEGIN
  };
};

const getSuspiciousWaybillDetailsSuccess = response => {
  return {
    type: ACTION_CONSTANTS.GET_SUSPICIOUS_WAYBILL_DETAILS_SUCCESS,
    payload: response
  };
};

const getSuspiciousWaybillDetailsFailure = error => {
  return {
    type: ACTION_CONSTANTS.GET_SUSPICIOUS_WAYBILL_DETAILS_FAILURE,
    payload: error
  };
};
