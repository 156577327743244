import { useEffect, useState } from "react";
import MonitoringReportsService from "../../../services/monitoring-report/monitoring-report.service";
import { Constants } from "../../../common/utils/constants";

export default function RtoDrsReport() {

    const [ embedUrl, SetEmbedUrl ] = useState(false);

    useEffect(() => {
        const user = localStorage.user ? JSON.parse(atob(localStorage.user)) : {};
        (new MonitoringReportsService()).getEmbeddedURLByPayload({
            reportName: user.partner?.id == 268 ? "rto-drs-report-all-locations" : "rto-drs-report",
            embedUrlGeneric: {
                resource : { dashboard : user.partner?.id == 268 ? 181 : 176  },
                params   : user.partner?.id == 268 ? {} : { location_id  : Constants.userLocationId }
            }
        })
        .then(response => {
            SetEmbedUrl(response?.data?.response?.embedUrl);
        })
    }, []);

    return (
        <div className="white-bg" style={{ height : "calc(100vh - 150px)", marginBottom : 25 }}>
            <iframe height={"100%"} src={`${embedUrl}&refresh=600`} frameBorder={0} width="100%" />
        </div>
    )
}