
export const initialState = {
    loading: false,
    selectedConsigment: {}
}

export const FETCH_CONSIGMENTS_BEGIN = "FETCH_CONSIGMENTS_BEGIN";
export const FETCH_CONSIGMENTS_SUCCESS = "FETCH_CONSIGMENTS_SUCCESS";
export const FETCH_CONSIGMENTS_FAILURE = "FETCH_CONSIGMENTS_FAILURE";

export const FETCH_CONSIGMENT_BEGIN = "FETCH_CONSIGMENT_BEGIN";
export const FETCH_CONSIGMENT_SUCCESS = "FETCH_CONSIGMENT_SUCCESS";
export const FETCH_CONSIGMENT_FAILURE = "FETCH_CONSIGMENT_FAILURE";

export const UPDATE_CONSIGMENT_BEGIN = "UPDATE_CONSIGMENT_BEGIN";
export const UPDATE_CONSIGMENT_SUCCESS = "UPDATE_CONSIGMENT_SUCCESS";
export const UPDATE_CONSIGMENT_FAILURE = "UPDATE_CONSIGMENT_FAILURE";