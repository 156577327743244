import React, { useEffect, useState } from "react";
import formSchema from './VendorUpdateFromSchema';
import '../form.scss';
import FormWidgets from '../../../ui/formComponent/formWidgets';
import FormWrapper from '../../../ui/formComponent/form';
import LocationService from "../../../../services/settings/location.service";
import { notifyApiSuccess } from "../../../../common/utils/utils";
import CustomArrayFieldTemplate from '../components/CustomArrayField/CustomArrayFieldTemplate';

const VendorUpdateForm = ({ partnerDetails, activeTab, onSubmitSuccess }) => {

    let formSchemaCopy = JSON.parse(JSON.stringify(formSchema))

    const locationService = new LocationService;
    const [formSchemaValue, setFormSchemaValue] = useState(null);
    const [formData, setFormData] = useState({});
    const [partnerInfo, setPartnerInfo] = useState({})

    useEffect(() => {
        setPartnerInfo(partnerDetails)
    }, [partnerDetails])

    useEffect(() => {

        setFormData({})

        if (activeTab == "vendor" && partnerInfo?.id) {
            locationService.fetchPartnerVendorList(partnerInfo.id)
                .then((res) => {
                    loadFormDataOnUpdate(res?.vendorData)
                })
                .catch((e) => console.error("Vendor fetch api failed", e))
        }

    }, [activeTab, partnerInfo])

    const loadFormDataOnUpdate = (vendorDetails) => {

        if (vendorDetails && vendorDetails.length > 0) {

            const existingVendorDetails = {
                "title": "Existing Vendor Details",
                "type": "array",
                "items": {
                    "required": ["vendorName"],
                    "properties": {
                        "vendorId": {
                            "title": "",
                            "type": "string"
                        },
                        "vendorName": {
                            "title": "Name",
                            "type": "string"
                        }
                    }
                }
            }

            formSchemaCopy.schema.properties.vendorDetails.properties = {
                existing: existingVendorDetails,
                ...formSchema.schema.properties.vendorDetails.properties
            }

            const vendorName = []

            vendorDetails.map((vendor, index) => {
                vendorName.push({
                    "vendorId": vendor.id,
                    "vendorName": vendor.name
                })
            })

            const formData = {
                vendorDetails: {
                    existing: vendorName
                }
            }

            setFormData(formData);
            setFormSchemaValue(formSchemaCopy)

        } else {
            setFormSchemaValue(formSchema)
        }

    }

    const onSubmit = fromDataSet => {

        const req = {}
        const vendorData = []

        if (fromDataSet?.vendorDetails?.existing &&
            fromDataSet.vendorDetails.existing.length > 0) {
            fromDataSet.vendorDetails.existing.map((vendor) => {
                vendorData.push({
                    id: vendor.vendorId,
                    name: vendor.vendorName
                })
            })
        }

        if (fromDataSet?.vendorDetails?.new &&
            fromDataSet?.vendorDetails?.new.length > 0) {
            fromDataSet.vendorDetails.new.map((vendor) => {
                vendorData.push({
                    name: vendor.vendorName
                })
            })
        }

        req["vendorData"] = vendorData

        locationService.updateVendorForPartner(req, partnerInfo.id)
            .then(resp => {
                notifyApiSuccess("Vendor updated successfully")
                onSubmitSuccess()
            })
            .catch(error => {
                console.error(error)
                setFormData(fromDataSet)
            });

    }

    const validate = (formData, errors) => {

        var nameRegex = /^(?=[a-zA-Z0-9])[a-zA-Z\s0-9()\/_-]{3,60}$/;

        if (formData.vendorDetails.existing && formData.vendorDetails.existing.length > 0) {
            formData.vendorDetails.existing.map((vendorDetail, index) => {
                if (!nameRegex.test(vendorDetail.vendorName)) {
                    errors.vendorDetails.existing[index].vendorName.addError("Invalid vendor name")
                }
            })
        }

        if (formData.vendorDetails.new && formData.vendorDetails.new.length > 0) {
            formData.vendorDetails.new.map((vendorDetail, index) => {
                if (!nameRegex.test(vendorDetail.vendorName)) {
                    errors.vendorDetails.new[index].vendorName.addError("Invalid vendor name")
                }
            })
        }

        if (!formData.vendorDetails.new?.length && formData.vendorDetails.existing == undefined) {
            errors.vendorDetails.new.addError("Vendor details are required")
        }

        return errors

    }

    return (
        <>
            <div className="vendor-info-text" >Updating vendor details for partner <b>{partnerInfo.name}</b></div>
            <div className='flex-box' style={{ padding: "20px" }}>
                <div className='flex-1'>
                    {formSchemaValue &&
                        <FormWrapper {...formSchemaValue} validate={validate}
                            onSubmit={onSubmit} widgets={FormWidgets} formData={formData}
                            ArrayFieldTemplate={CustomArrayFieldTemplate}
                        />
                    }
                </div>
                <div className='flex-1'></div>
            </div>
        </>
    )
}

export default VendorUpdateForm;