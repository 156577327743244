import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const RVPGlobalPendencyPartnerLvlDashboard = () => {

    return (
        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"RVP Overview - Partner Level"} className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 3127, reportName: "rvp-overview-partner-level",
                        lockedParams: ["partner_id"], height: "300px"
                    }} />
            </div>

            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"RVP Pickup Performance - Partner Level"} className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 3128, reportName: "rvp-pickup-performance-partner-level",
                        lockedParams: ["partner_id"], height: "600px",
                        dateParams: new Set(['startDate', 'endDate'])
                    }} />
            </div>

            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"RVP LM Pendency - Partner level"} className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 3129, reportName: "rvp-lm-pendency-partner-level",
                        lockedParams: ["partner_id"], height: "500px"
                    }} />
            </div>
        </div>
    )

}


export default RVPGlobalPendencyPartnerLvlDashboard;