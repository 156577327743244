import React, { useEffect, useState } from "react";
import { Button, Icon, Input } from "antd";
import ScanList from "../scan-list";
import "./index.scss"
import { addItem } from "../scan-list/utils";
import { SoundNotificationService } from "../../../services/sound.service";

const soundService = new SoundNotificationService();

export default function ScanListInput(props) {

    const id = props.listId ? props.listId : generateID(10)

    useEffect(_ => {
        if (!props.loading) {
            const scanInput = document.getElementById(id + "_scan-list-input-box");
            if (scanInput) {
                scanInput.blur();
                scanInput.setAttribute("readonly", "readonly"); 
                scanInput.focus();
                scanInput.removeAttribute("readonly");  
            }
        }
    }, [ props.loading ])

    const [inputValue, SetInputValue] = useState("");
    const onPressEnter = _ => {
        const onAddSuccess = (data, index) => {
            SetInputValue("");
            soundService.playSuccess();
            if (typeof props.onAdd === "function") {
                props.onAdd(data, index, id)
            }
        }
        if (typeof props.onInputScan === "function") {
            props.onInputScan(inputValue?.trim())
            .then(({data, index}) => {
                if (props.skipAdd) {
                    onAddSuccess(data, index);
                }
                else {
                    addItem(data, index, id)
                    .then(_ => {
                        onAddSuccess(data, index)
                    })
                }
            })
            .catch(e => {
                SetInputValue("");
                console.log(e);
            });
        }
    }

    return (
        <div className="flex-column flex-gap-m">
            {
                typeof props.onInputScan === "function" &&
                <div className="white-bg border-grey spacer-m flex-column flex-gap-l border-radius-s">
                    { props.inputHeader && <div className="font-size-m-3 text-bold"> {props.inputHeader} </div> }
                    <Input className="scan-list-input-box border-radius-m" id={id + "_scan-list-input-box"}
                    placeholder={props.inputPlaceholder} value={inputValue}
                    onChange={e => SetInputValue(e.target.value)}
                    onPressEnter={onPressEnter} allowClear={true}
                    disabled={props.loading}
                    suffix={<>
                        <Button onClick={onPressEnter} disabled={props.loading}
                        style={{ height: "100%", borderRadius: "0px 6px 6px 0px", marginLeft: 5}}
                        type="primary" className="lsn-btn-primary">
                            <Icon type="barcode" /> Scan
                        </Button>
                    </>}
                    style={{ width : "50%" }}
                    />
                    {props.lastScanBlock}
                </div>
            }
            <ScanList {...props} listId={id} />
        </div>
    )
}