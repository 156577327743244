import { DatePicker, Icon, Input, Modal, Radio, Spin } from "antd";
import RadioGroup from "antd/lib/radio/group";
import React, { useState } from "react";
import { UPLOAD_BUTTON_TYPE } from "../../../../common/utils/constants";
import { codTransactionModes, disableFutureDates, formatCurrency, notifyApiError } from "../../../../common/utils/utils";
import FileUpload from "../../../ui/file-upload/file.upload";
import { generateRemitPostObject, remitAmount } from "../../services/post.service";
import moment from "moment";
import debounce from "lodash/debounce";

/*
    Not using FormWrapper as this is in a different format & style from the rest of the ERP
*/

export default function RemitForm({ customerId, partner, approverPartnerId, openModal, SetOpenModal, rowData, onSuccess, locationId }) {

    const [ formData, SetFormData ] = useState({
        remitTo     : rowData.entityName,
        paymentMode : "CD",
        pendingPayment : rowData.netOutstanding - rowData.pendingApproval
    });

    const [ submitting, SetSubmitting ] = useState(false);
    const [ openConfirmModal, SetOpenConfirmModal ] = useState(false);

    const handleDataChange = (e, v) => {
        if (typeof e === "string") {
            SetFormData(current => {
                var temp = { ...current };
                return {
                    ...temp,
                    [e] : v
                }
            })
        }
        else {
            e.persist();
            SetFormData(current => {
                var temp = { ...current };
                return {
                    ...temp,
                    [e.target?.id] : e.target?.value
                }
            })
        }
    }

    const callRemitAmount = debounce(_ => {
        remitAmount(generateRemitPostObject({
            ...formData,
            partner,
            locationId,
            customerId,
            approverPartnerId
        }))
        .then(response => {
            if(typeof onSuccess == "function") {
                onSuccess(response);
            }
            SetSubmitting(false);
            SetOpenConfirmModal(false);
        })
        .catch(e => {
            SetSubmitting(false);
            SetOpenConfirmModal(false);
            console.log(e);
        })
    }, 500);

    const confirmRemit = _ => {
        var invalid = false;
        if (!formData.amount) {
            notifyApiError("Enter Remitted Amount!");
            invalid = "amount";
        }
        else if (!formData.transactionDate) {
            notifyApiError("Select Transaction Date!");
            invalid = "transactionDate";
        }
        else if (!formData.proofFileLink) {
            notifyApiError("Upload proof of amount remitted!");
            invalid = "proofFileLink";
        }

        if (invalid !== false) {
            if ( ["amount", "transactionDate"].indexOf(invalid) > -1 ) {
                document.getElementsByClassName("ant-modal-wrap ant-modal-centered")[0].scrollTo({
                    top : invalid == "amount" ? 0 : 50,
                    behavior : "smooth"
                })
            }
            return;
        }

        SetOpenConfirmModal(true);
    }

    const closePopup = _ => SetOpenModal(false);

    return (
        <>
            <Modal zIndex={999} title="Remit Amount" visible={openModal} width={"50vw"} centered={true}
            okText="Confirm" okButtonProps={{ className : "lsn-btn-success", type : "default", loading : submitting, disabled : (!formData.amount || (formData.amount  <=0) || (formData.pendingPayment - formData.amount  < 0))}}
            cancelButtonProps={{ className : "lsn-btn-error", type : "default", loading : submitting }}
            onOk={confirmRemit} onCancel={closePopup} >
                <Spin spinning={submitting}>
                    <div className="flex-column flex-gap-xl">
                        <div className="flex-column flex-gap-s">
                            <div className="light-grey-text font-size-m-1"> Remit To </div>
                            <div className="text-bold"> { formData.remitTo } </div>
                        </div>

                        <div className="flex-column flex-gap-s">
                            <div className="light-grey-text font-size-m-1"> Pending Payment (A) </div>
                            <div className="text-bold"> { formatCurrency(formData.pendingPayment) } </div>
                        </div>

                        <div className="flex-column flex-gap-s">
                            <div className="light-grey-text font-size-m-1"> Remitting Amount (B) </div>
                            <Input placeholder="Enter amount to be Remitted" type="number" autoComplete="off"
                            className={
                                (formData.pendingPayment - formData.amount < 0) || 
                                (formData.amount !== undefined && (formData.amount.trim() === "" || Number(formData.amount) <= 0)) ? 
                                "error-field" : ""
                            }
                            max={formData.pendingPayment} id="amount" onChange={handleDataChange} />
                            {
                                formData.pendingPayment - formData.amount < 0 &&
                                <div className="error-text"> Remit amount should not be greater than Pending Payment </div>
                            }
                            {
                                formData.amount !== undefined && (formData.amount.trim() === "" || Number(formData.amount) <= 0) &&
                                <div className="error-text"> Remit amount should a non zero number </div>
                                
                            }
                        </div>

                        <div className="flex-column flex-gap-s">
                            <div className="light-grey-text font-size-m-1"> Balance to Remit (A-B) </div>
                            <div className="text-bold">
                                {
                                    formData.amount ? 
                                    formData.pendingPayment - formData.amount > 0 ? formatCurrency(formData.pendingPayment - formData.amount) : 0 :
                                    formatCurrency(formData.pendingPayment)
                                }
                            </div>
                        </div>

                        <div className="flex-column flex-gap-s">
                            <div className="light-grey-text font-size-m-1"> Transaction Date </div>
                            <DatePicker format={"DD-MM-YYYY"} disabledDate={disableFutureDates}
                            onChange={date => handleDataChange("transactionDate", date ? moment(date).format("DD-MM-YYYY") : undefined)} />
                        </div>

                        <div className="flex-column flex-gap-s">
                            <div className="light-grey-text font-size-m-1"> Payment Mode </div>
                            <RadioGroup defaultValue={"CD"} buttonStyle="solid" className="radio-button-spacer"
                            onChange={selectedMode => handleDataChange("paymentMode", selectedMode.target.value)}>
                                {
                                    codTransactionModes().map(mode => <Radio.Button value={mode.value} key={mode.value}> {mode?.name} </Radio.Button>)
                                }
                            </RadioGroup>
                        </div>

                        <div className="flex-column flex-gap-s">
                            <div className="light-grey-text font-size-m-1"> Reference No. (CMS Transaction No) </div>
                            <Input placeholder="Enter Transaction Reference Number" autoComplete="off"
                            id="transactionNumber" onChange={handleDataChange} />
                        </div>

                        <div className="flex-column flex-gap-s">
                            <div className="light-grey-text font-size-m-1"> Upload Proof </div>
                            <div>
                                <FileUpload fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                                directUpload={true} disableUploadButton={false}
                                onUploadFinish={s3Link => handleDataChange("proofFileLink", s3Link)}
                                handleClearCallBack={_ => handleDataChange("proofFileLink", undefined)}
                                path="partners" objKey="partners" multiple={false} 
                                acceptTypes={[".png", ".jpg", ".pdf", ".jpeg"]} />
                            </div>
                        </div>

                        <div className="flex-column flex-gap-s" style={{ marginTop : 8 }}>
                            <div className="light-grey-text font-size-m-1"> Bankname (Optional) </div>
                            <Input placeholder="Enter Bank Name" autoComplete="off"
                            id="bankName" onChange={handleDataChange} />
                        </div>

                        <div className="flex-column flex-gap-s">
                            <div className="light-grey-text font-size-m-1"> Remarks (Optional) </div>
                            <Input.TextArea placeholder="Enter any remarks if necessary" autoComplete="off"
                            id="transactionRemarks" onChange={handleDataChange} />
                        </div>
                    </div>
                </Spin>
            </Modal>
            <Modal zIndex={1000} title="Confirm" visible={openConfirmModal} centered={true} okText="Confirm"  closable={false}
            okButtonProps={{ className : "lsn-btn-success", type : "default", loading : submitting }}
            cancelButtonProps={{ className : "lsn-btn-error", type : "default", loading : submitting }}
            onOk={_ => {
                SetSubmitting(true);
                callRemitAmount();
            }} onCancel={closePopup} >
                <div className="flex-box align-items-center flex-gap-m">
                    <Icon type="question-circle" theme="twoTone" twoToneColor={"#faad14"} style={{ fontSize : 22 }} />
                    <div> Are you sure want to submit? </div>
                </div>
            </Modal>
        </>
    )

}