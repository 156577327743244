import { Button, Dropdown, Menu, Tooltip } from "antd";
import { TRIP_UPDATE_ACTIONS, TRIP_UPDATE_ACTION_LABELS } from "../../trips.utils";
import TripActionModal from "./trip.action.modal";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function TripActionColumn({ trip, action, onActionSuccess, tripType, handleMenuClick }) {

    const navigate = useNavigate();

    const [ actionTrip, SetActionTrip ] = useState(undefined);
    const [ visible, SetVisible ] = useState(false);

    const restrictTripChallanPrint = localStorage._configrations ? JSON.parse(atob(localStorage._configrations)).restrictTripChallanPrint : false
    
    const performTripAction = (action, trip) => {
        if (action === TRIP_UPDATE_ACTIONS.UNLOAD) {
            navigate(`/appv2/inventory/dashboard/manifest-closure/trips/${trip.tripCode}`);
        }
        else if (action === TRIP_UPDATE_ACTIONS.LOAD_TRIP) {
            addLoad(trip.tripCode);
        }
        else {
            SetActionTrip({...trip, action : action});
        }
    }

    const addLoad = tripCode => navigate(`/appv2/trips/form/outbound/${tripCode}`);

    const printTrip = trip => handleMenuClick("inboundShipmentLabelPrint", trip);

    const MenuOverlay = _ =>{
        return (
            <Menu onClick={e => {
                handleMenuClick(e.key, trip);
                SetVisible(false);
            }}>
                <Menu.Item key="tripSheet" disabled={trip.tripStatus === "LOADED" && restrictTripChallanPrint === 'true'}>
                    <Tooltip title={trip.tripStatus === "LOADED" && restrictTripChallanPrint === 'true' ? "Dispatch the trip to download the Challan print" : undefined}>
                       Connection Sheets Print
                    </Tooltip>
                </Menu.Item>
                <Menu.Item key="shipmentLabelPrint" disabled={trip.tripStatus === "LOADED" && restrictTripChallanPrint === 'true'}
                 title={trip.tripStatus === "LOADED" && restrictTripChallanPrint === 'true' ? "Dispatch the trip to download the Challan print" : undefined}>
                    <Tooltip title={trip.tripStatus === "LOADED" ? "Dispatch the trip to download the Challan print" : undefined}>
                        Shipment List Print
                    </Tooltip>
                </Menu.Item>
            </Menu>
        )
    } ;

    return (
        <>
            <div className="flex-box flex-wrap flex-gap-m align-items-center">
                {
                    action && action !== TRIP_UPDATE_ACTIONS.CONVERT_TO_OUTBOUND ? (
                        <Button key={trip.tripId} type="primary" size="small"
                         onClick={() => performTripAction(action, trip)} >
                            {TRIP_UPDATE_ACTION_LABELS[action]}
                        </Button>
                    ): (null) 
                }
                {
                    !action && trip.tripStatus === "LOADED" ? (
                        <Button key={trip.tripId} type="primary" size="small"
                         onClick={() => performTripAction("DISPATCH", trip)} >
                            Dispatch
                        </Button>
                    ): (null) 
                }
                {
                    (tripType === "OUTBOUND_TRIP" && trip && trip.tripStatus &&
                    (trip.tripStatus === "LOADED" || trip.tripStatus === "ARRIVED") &&  
                    action!=TRIP_UPDATE_ACTIONS.COMPLETE_TRIP) && 
                    <Button className="lsn-btn-primary" type="primary"
                     icon="edit" size="small" onClick={() =>addLoad(trip.tripCode)} />
                }
                {
                    (tripType === "OUTBOUND_TRIP" && ["LOADED", "DISPATCHED", "COMPLETED"].includes(trip.tripStatus))  &&
                    <Dropdown visible={visible} onVisibleChange={SetVisible}
                     overlay={<MenuOverlay trip={trip} />} trigger={["click"]}>
                        <Button type="default" icon="more" size="small" />
                    </Dropdown>
                }
                {
                    tripType === "INBOUND_TRIP" &&
                    <Button className="lsn-btn-secondary" icon="printer"
                     size="small" onClick={() => printTrip(trip)} />
                }
            </div>

            {
                actionTrip &&
                <TripActionModal tripId={actionTrip?.tripId} tripCode={actionTrip?.tripCode} action={action}
                 onSuccessFunction={onActionSuccess} onCancelFunction={_ => SetActionTrip(undefined)}/>
            }
        </>       
    )
}