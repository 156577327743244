import ScanWarning from '../assets/audio/scan-warning.mp3';
import ScanSuccess from '../assets/audio/scan-success.mp3';
import ScanGSuccess from '../assets/audio/scan-general-success.m4a';
import ScanGWarning from '../assets/audio/scan-general-warning.m4a';
import ScanSuccessForward from '../assets/audio/insacn-forward.m4a';
import ScanSuccessRTO from '../assets/audio/insacn-rto.m4a';

export class SoundNotificationService {
    soundWarning;
    soundSuccess;
    soundGeneralSuccess;
    soundGeneralFailure;
    scanSuccessForward;
    scanSuccessRTO;

    constructor() {
        this.soundWarning = new Audio(ScanWarning);
        this.soundSuccess = new Audio(ScanSuccess);
        this.soundGeneralSuccess = new Audio(ScanGSuccess);
        this.soundGeneralFailure = new Audio(ScanGWarning);
        this.scanSuccessForward = new Audio(ScanSuccessForward);
        this.scanSuccessRTO = new Audio(ScanSuccessRTO);

    }

    playWarning() {
        this.soundWarning.load();
        this.soundWarning.play();
    }

    playSuccess() {
        this.soundSuccess.load();
        this.soundSuccess.play().catch(err => {
            console.error("Play interrupted", err)
        });
    }

    playGeneralSuccess() {
        this.soundGeneralSuccess.load();
        this.soundGeneralSuccess.play();
    }

    playGeneralWarning(count = 1) {
        this.soundGeneralFailure.load();
        this.soundGeneralFailure.play().then(_ => {
            if (count > 1) {
                this.soundWarning.play();
            }
        })
            .catch(err => { console.error("Play interrupted", err) });
    }

    playForwardSuccess() {
        this.scanSuccessForward.load();
        this.scanSuccessForward.play();
    }

    playRTOSuccess() {
        this.scanSuccessRTO.load();
        this.scanSuccessRTO.play();
    }

}