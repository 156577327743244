import { Alert, Button, Input, Spin } from "antd";
import { useEffect, useState } from "react";
import TamperedReasonSelect from "../../common/tampered-reason-select";
import { apiRequest } from "../../../common/utils/apiGateway";
import { checkHttpStatus } from "../../../common/utils/utils";
import { Constants } from "../../../common/utils/constants";

export default function ShipmentScanForm({ callScanConsignment, scanInProgress, scanResponse, manifestDetails }) {

    const [waybillNo, SetWaybillNo] = useState(undefined);
    const [pickupData, setPickupData] = useState("");

    useEffect(() => {
        SetWaybillNo(undefined)
        refocusOnInput();
    }, [scanResponse, scanInProgress])

    const locationOpsType = localStorage?.user ? JSON.parse(atob(localStorage.user))?.location?.locationOpsType : undefined;

    useEffect(() => {
        if (locationOpsType == "FM" && manifestDetails?.manifest?.code) {
            apiRequest({
                url    : `b2c/v1/manifest/${manifestDetails?.manifest?.code}/pickup-fe-data`,
                method : "GET"
            }).then(response => {
                if (checkHttpStatus(response?.data?.status)) {
                    setPickupData(response?.data?.response?.waybillPickupDataBOList);
                }
            });
        }
    }, [ manifestDetails?.manifest?.code ])

    const refocusOnInput = _ => {
        const scanInput = document.getElementById("scanWaybillInput");
        if (scanInput) {
            scanInput.blur();
            scanInput.setAttribute("readonly", "readonly");
            scanInput.focus();
            scanInput.removeAttribute("readonly");
        }
    }


    return (
        <div className="flex-column flex-gap-l align-items-stretch spacer-m">
            <Input onChange={e => SetWaybillNo(e.target.value)} value={waybillNo}
                id={"scanWaybillInput"}
                suffix={(
                    <Button icon="barcode" type="primary" className="lsn-btn-primary"
                        style={{ borderRadius: "0px 3px 3px 0px", marginRight: -15 }}
                        loading={scanInProgress} onClick={_ => callScanConsignment(waybillNo)}>
                        Scan
                    </Button>
                )}
                disabled={scanInProgress}
                onPressEnter={_ => callScanConsignment(waybillNo)}
                placeholder="Scan Waybill Number to unload"
            />
            {
                scanResponse && scanResponse.scanStatus ?
                    <Spin spinning={scanInProgress}>
                        <div className="spacer-m border-grey border-radius-s flex-column flex-gap-xl white-bg">
                            {
                                scanResponse?.alreadyScanned ?
                                <Alert message="The consignment was already scanned against the manifest" type="info" className={"info-color"} /> :
                                <Alert message="Scan Successful" type="success" className={"success-color"} />
                            }
                            {
                                scanResponse?.nextLocation &&
                                <div className="flex-column flex-gap-xs">
                                    <div className="light-grey-text"> Next Location </div>
                                    <div className="font-size-l-3"> {scanResponse?.nextLocation} </div>
                                </div>
                            }
                            {
                                pickupData && pickupData.find(c => c.waybillNo == scanResponse?.waybillNo) &&
                                <div className="flex-column flex-gap-xs">
                                    <div className="light-grey-text"> Picked up by </div>
                                    <div className="font-size-l-3"> 
                                        { pickupData.find(c => c.waybillNo == scanResponse?.waybillNo)?.feName }
                                    </div>
                                </div>
                            }
                            {
                                locationOpsType == "FM" && scanResponse.shipperName &&
                                scanResponse?.consignmentStatus?.indexOf("RTO") > -1 &&
                                <div className="flex-column flex-gap-xs">
                                    <div className="light-grey-text"> Seller </div>
                                    <div className="font-size-l-2"> 
                                        { scanResponse.shipperName }
                                    </div>
                                </div>
                            }
                            <div className="flex-box justify-content-space-between flex-gap-l" style={{ flexWrap: "wrap" }}>
                                <div className="flex-column flex-gap-xs flex-2 align-items-start">
                                    <div className="light-grey-text"> Waybill No </div>
                                    <div className="font-size-m-3 break-all"> {scanResponse?.waybillNo} </div>
                                </div>
                                <div className="flex-column flex-gap-xs flex-1 align-items-end">
                                    <div className="light-grey-text"> Status </div>
                                    {
                                        scanResponse?.consignmentStatus?.indexOf("IN") > -1 ?
                                            <div className="font-size-m-3 success-color" > {scanResponse?.consignmentStatus} </div> :
                                            <div className="font-size-m-3 error-color" > {scanResponse?.consignmentStatus} </div>
                                    }
                                </div>
                            </div>

                            <TamperedReasonSelect scanType={"UNLOAD"} customerId={scanResponse?.customerId} waybillNo={scanResponse?.waybillNo} />

                        </div>
                    </Spin> :
                    <></>
            }
        </div>
    )
}