import React, { Component } from 'react';
import Form from "@rjsf/core";
import './forms.scss';
import FormWidgets from './formWidgets';
import { addCancelButton, getCancelButtonOptions, getGenericErrorMessages, getSubmitButtonOptions, setSubmitButtonProps } from './form.utils';
import customFields from './customFields';

class FormWrapper extends Component {

    constructor(props) {
        super(props);
        this.formReference = React.createRef();
        this.state = {
            showErrorList: false,
            fieldsPerRow: props.fieldsPerRow ? props.fieldsPerRow : 1
        }
    }

    setFieldWidth() {
        if (this.state.fieldsPerRow > 1) {
            const splitElement = document.createElement("div");
            splitElement.classList.add("column-splitter");
            splitElement.style = {};
            var fieldsDiv = [];
            if (this.formReference.current?.formElement) {
                fieldsDiv = this.formReference.current.formElement.querySelectorAll(".multi-row-field-objects");
            }
            else {
                return;
            }
            const scale = parseInt(100 / this.state.fieldsPerRow);
            if (this.props.showColumnSplitter) {
                for (var i = 1; i < this.state.fieldsPerRow; ++i) {
                    fieldsDiv.forEach(fd => {
                        var splitElem = splitElement.cloneNode();
                        splitElem.style.left = (scale * i) + "%";
                        if (fd.querySelectorAll(".form-group.field").length > 1) {
                            fd.appendChild(splitElem);
                        }
                    });
                }
            }
            var formFields = [];
            if (this.formReference.current?.formElement) {
                formFields = this.formReference.current.formElement.querySelectorAll(".multi-row-field-objects > fieldset > .form-group.field");
            }
            else {
                formFields = document.querySelectorAll(".multi-row-field-objects > fieldset > .form-group.field");
            }
            var width = parseInt(100 / this.state.fieldsPerRow) - 2;
            for (let i = 0; i < formFields.length; i++) {
                const element = formFields[i];
                if (!element.classList.contains("row-object")) {
                    element.style["flex-basis"] = width + "%";
                    if (!element.classList.contains("single-field-multi-row-form")) {
                        element.style["max-width"] = width + "%";
                    }
                }
            }
        }
    }

    componentDidMount() {
        if (this.formReference.current?.formElement) {
            const addButton = this.formReference.current?.formElement.querySelector("button[type='submit']");
            setSubmitButtonProps(addButton, getSubmitButtonOptions(this.props.uiSchema));
            if (this.props.addCancelButton && typeof this.props.onCancel === "function") {
                addCancelButton(addButton, getCancelButtonOptions(this.props.uiSchema), this.cancel)
            }
        }
        this.setFieldWidth();
    }

    cancel = () => {
        if (typeof this.props.onCancel === "function") {
            typeof this.props.onCancel();
        }
    }

    onChange = formProperties => {
        if (typeof this.props.onChange == "function") {
            this.props.onChange(formProperties.formData);
        }
        this.setFieldWidth();
    }

    onSubmit = formProperties => {

        if (typeof this.props.onSubmit == "function") {
            this.props.onSubmit(formProperties.formData, formProperties);
        }
    }

    onError = errors => {
        if (typeof this.props.onError == "function") {
            this.props.onError(errors);
        }
    }

    transformErrors = errors => {
        const errorList = this.props.errorList ? this.props.errorList : {}
        errors = errors.map(error => {
            var propertyLabel = error.property.substr(1);
            if (error.property.indexOf('[') > -1 && error.property.indexOf(']') > error.property.indexOf('[')) {
                propertyLabel = propertyLabel.substr(0, error.property.indexOf('[') - 1) + propertyLabel.substr(error.property.indexOf(']'));
            }
            var errorJSON = errorList;
            var fieldName = "";
            propertyLabel.split(".").map(p => {
                if (errorJSON) {
                    errorJSON = errorJSON[p];
                }
                fieldName = p;
            })
            if (errorJSON && errorJSON[error.name]) {
                error.message = errorJSON[error.name];
            }
            else if (errorJSON && errorJSON.ignoreType === true && error.name === "type") {
                error.message = undefined;
            }
            else {
                error.message = getGenericErrorMessages(error.name, fieldName)
            }
            return error;
        });

        if (typeof this.props.transformErrors == "function") {
            errors = this.props.transformErrors(errors);
        }
        return errors;
    }

    validate = (data, errorsObject) => {
        if (typeof this.props.validate === "function") {
            return this.props.validate(data, errorsObject)
        }
        return errorsObject;
    }

    onBlur = (id, value) => {
        const newID = id.split("_")[2]; // To get Exact ID
        if (typeof this.props.onBlur == "function") {
            this.props.onBlur(newID, value);
        }
    }

    render() {

        const { fieldsPerRow } = this.state;
        var formClass = "lsn-form";
        if (fieldsPerRow > 1) {
            formClass += " row-fields";
        }
        else {
            formClass += " column-fields";
        }

        if (this.props.readonly) {
            formClass += " read-only";
        }

        if (this.props.hideSubmit) {
            formClass += " hide-submit";
        }

        if (this.props.loading) {
            formClass += " loading-form";
        }

        if (!this.props.bordered) {
            formClass += " unbordered";
        }

        const removeEmptyString = obj => {
            Object.keys(obj).map(key => {
                if (typeof obj[key] === "string" && obj[key].trim() === "") {
                    obj[key] = undefined;
                }
                else if (obj[key] != null && typeof obj[key] === "object") {
                    removeEmptyString(obj[key])
                }
            })
        }
        removeEmptyString(this.props.formData)

        const customFormats = {
            "alphaNumeric": "^[a-zA-Z0-9 ]*$",
            "alphaNumericWithHyphen": "^[a-zA-Z0-9 \-]*$"
        }

        return <Form schema={this.props.schema} uiSchema={this.props.uiSchema} onBlur={(id, value) => this.onBlur(id, value)}
            widgets={this.props.widgets ? this.props.widgets : FormWidgets}
            fields={this.props.fields ? { ...this.props.fields, ...customFields } : customFields}
            showErrorList={false} customFormats={customFormats}
            formData={this.props.formData} className={formClass}
            onSubmit={this.onSubmit} onChange={this.onChange} validate={this.validate}
            readonly={this.props.readonly} ref={this.formReference} onError={this.onError}
            transformErrors={this.transformErrors} ArrayFieldTemplate={this.props.ArrayFieldTemplate}
            id={this.props?.id || this.props.formid ? this.props?.id || this.props.formid : undefined} />
    }
}

export default FormWrapper;