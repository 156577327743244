import { Radio } from "antd";
import RadioGroup from "antd/lib/radio/group";
import { useState } from "react";
import MetabaseReport from "../components/metabase.report";

export default function FMDashboard() {

    const subTabs = [
        {
            label : "Pickup Planning",
            reportName : "fm-pickup-planning-dashboard"
        },
        {
            label : "FE Monitoring",
            reportName : "fm-pickup-operations-monitoring-dashboard"
        },
        {
            label : "Hub Monitoring",
            reportName : "fm-hub-operations"
        }
    ];

    const [selectedTab, SetSelectedTab] = useState("fm-pickup-planning-dashboard");

    const onTabChange = key => {
        SetSelectedTab(key);
    }

    return (
        <div className="flex-column flex-gap-m align-items-stretch" style={{ marginBottom : 15 }}>
            <RadioGroup buttonStyle="solid" onChange={event => onTabChange(event?.target?.value)} value={selectedTab} >
                {
                    subTabs.map(t => <Radio.Button className="report-preview-radio" value={t.reportName}> {t.label} </Radio.Button> )
                }
            </RadioGroup>
            <MetabaseReport reportName={selectedTab} hideBackButton={true} autoRefresh={true} autoRefreshTimetout={300} />
        </div>
    )
}