import { Icon } from "antd";
import React from "react";

export default function MwebHeader(props) {
    
    const { pathname, onClick, backText, name } = props;

    return (
        pathname.indexOf("/home") === -1 ?
        <div className="white-bg spacer-s flex-box flex-gap-m align-items-center navbar-bg" onClick={onClick}>
            <Icon type="arrow-left" style={{color : "white" }} />
            <div className="font-size-m-3" style={{color : "white"}}> {backText || name}</div>
        </div> :
        <></>
    )
}