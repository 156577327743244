const schema = {
    "type": "object",
    "properties": {
        "vendorDetails": {
            "title": "",
            "type": "object",
            "properties": {
                "new": {
                    "title": "New Vendor Details",
                    "type": "array",
                    "items": {
                        "required": ["vendorName"],
                        "properties": {
                            "vendorName": {
                                "title": "Name",
                                "type": "string"
                            }
                        }
                    }
                }
            }
        }
    }
}

const uiSchema = {
    "ui:options": {
        "orderable": false
    },
    "vendorDetails": {
        "classNames": "vendor-container",
        "existing": {
            "ui:options": {
                "orderable": false,
                "addable": false,
                "displayToggleBtn": true
            },
            "items": {
                "className": "vendor-array",
                "vendorId": {
                    "ui:widget": "hidden"
                },
                "vendorName": {
                    "ui:placeholder": "Enter name."
                }
            }
        },
        "new": {
            "items": {
                "className": "vendor-array",
                "vendorName": {
                    "ui:placeholder": "Enter name."
                }
            }
        }
    }
}

const errorList = {
    "vendorDetails": {
        "existing": {
            "items": {
                "vendorName": {
                    "required": "Location Name required!",
                    "pattern": "Location Name must contain only alphabets"
                }
            }
        },
        "new": {
            "items": {
                "vendorName": {
                    "required": "Location Name required!",
                    "pattern": "Location Name must contain only alphabets"
                }
            }
        }
    }
}

export default { schema, uiSchema, errorList };