import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "../../common/utils/constants";
import SparklineCard from "./components/sparkline.card";
import { fetchCodSparklineDetails } from "./services/fetch.service";
import { userType } from "./utils";
import PartnerCoDView from "./components/view/partner.view";
import RootPartnerCoDView from "./components/view/root.partner.view";
import BranchCoDView from "./components/view/branch.view";
import "./index.scss";

/*
 * ./transform.data.js for column and timeline mappings
 * ./services/fetchService and ./utils.js have converting basic data to request object based on `userType`
 */

export default function CODModuleV2() {

    const [ sparklineSummary, SetSparklineSummary ] = useState(undefined);
    const [ showSparklineSummary, SetShowSparklineSummary ] = useState(false);

    const fromTimeStamp = useSelector(state => state.codV2Reducer?.fromTimeStamp);
    const toTimeStamp   = useSelector(state => state.codV2Reducer?.toTimeStamp);

    const loadSparkline = _ => {
        SetSparklineSummary(undefined);
        fetchCodSparklineDetails(fromTimeStamp, toTimeStamp)
        .then(SetSparklineSummary)
        .catch(e => console.log(e));
    }

    // useEffect(loadSparkline, []);

    const toggleSparklineView = _ => {
        SetShowSparklineSummary(true);
        loadSparkline();
    }

    return (
        <div className="flex-column flex-gap-l white-bg border-grey border-radius-m spacer-l">
            {
                showSparklineSummary ?
                <div className="legacy-sparkline">
                    <SparklineCard title={"Total Outstanding (A)"} loading={sparklineSummary === undefined}
                     highlightText={sparklineSummary ? sparklineSummary.totalPending : 0} showSparklineSummary={showSparklineSummary}  />
                    <SparklineCard title={"Pending For Approval (B)"} loading={sparklineSummary === undefined}
                     highlightText={sparklineSummary ? sparklineSummary.approvalPending : 0} showSparklineSummary={showSparklineSummary}  />
                    <SparklineCard
                     title={`${userType() === "BRANCH" ? "Pending Payment" : "Pending Collection"}(C = A - B)`}
                     loading={sparklineSummary === undefined} showSparklineSummary={showSparklineSummary} 
                     highlightText={sparklineSummary ? sparklineSummary.netOutstandingAmount : 0} />
                </div> :
                <div className="legacy-sparkline">
                    <SparklineCard title={"Total Outstanding (A)"} loading={sparklineSummary === undefined}
                     showSparklineSummary={showSparklineSummary} toggleSparklineView={toggleSparklineView} />
                    <SparklineCard title={"Pending For Approval (B)"} loading={sparklineSummary === undefined}
                     showSparklineSummary={showSparklineSummary} toggleSparklineView={toggleSparklineView} />
                    <SparklineCard
                     title={`${userType() === "BRANCH" ? "Pending Payment" : "Pending Collection"}(C = A - B)`}
                     loading={sparklineSummary === undefined}
                     showSparklineSummary={showSparklineSummary} toggleSparklineView={toggleSparklineView} />
                </div> 
            }

            {
                ["ROOT_PARTNER_ADMIN","ROOT_PARTNER"].indexOf(userType()) > -1 ?
                <PartnerCoDView reloadSparkline={loadSparkline} sparklineSummary={sparklineSummary} isRootView={true}
                 rootPartnerId={userType() === "ROOT_PARTNER" ? Constants.PARTNER_ID : undefined} showSparklineSummary={showSparklineSummary} /> :

                userType() === "PARTNER_ADMIN" ?
                <BranchCoDView partnerId={Constants.PARTNER_ID} reloadSparkline={loadSparkline} isRootView={true}
                 expanded={true} showMenu={true} sparklineSummary={sparklineSummary} showSparklineSummary={showSparklineSummary} /> :
                
                userType() === "BRANCH" ?
                <RootPartnerCoDView partnerId={Constants.PARTNER_ID} locationId={Constants.LOGGED_PARTNER_LOCATION_ID}
                 expanded={true} reloadSparkline={loadSparkline} isRootView={true} showSparklineSummary={showSparklineSummary} />  : <></>
            }
            
        </div>
    )

}