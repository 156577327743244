import MetabaseWidget from "../components/metabase.widget";

const ForwardWaybillList = ({ entityActionButtons }) => {

    return (
        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>
            <div className="flex-box flex-gap-m justify-content-center" >
                <MetabaseWidget text={"Inventory - Waybill Count"} className={"flex-1 flex-box justify-content-center"}
                    reportJson={{ questionNo: 2045, reportName: "inventory-waybill", lockedParams: ["location_id"], height: "200px" }} />
                {entityActionButtons &&
                    <div className={"flex-1 flex-box justify-content-center"}>{entityActionButtons}</div>
                }
            </div>

            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"Inventory - Waybill"} className={"flex-1 flex-box justify-content-center"}
                    reportJson={{ questionNo: 1953, reportName: "inventory-waybill", lockedParams: ["location_id"], height: "500px" }} />
            </div>
        </div>
    )

}

export default ForwardWaybillList;
