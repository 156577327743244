import React, { useState } from "react";
import {
    Card,
    Table,
    Tag,
    Select
} from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { RTO_COLORS } from "./constants";
import { checkHttpStatus, getLocationData, notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import BulkOperation from "../../shared-components/bulk-operation";
import { Constants } from "../../../common/utils/constants";
import ScanService from "../../../services/inbound/scan.service";
import RTODownloadModal from "./download-modal";
import RTOServices from "../../../services/rto/rto.service";
import { getConsignmentsData } from "./action";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const scanService = new ScanService();
const rtoService = new RTOServices();

const RTOEligibleTable = ({
     data,
     getPaginatedData,
     pageNo,
     rtoAction,
     applyPagination,
     config,
     PaginatedRTOEligible,
     sampleBookingDownloadLink=`${
        Constants.s3BucketLink
      }/documents/templates/xlsx/rto-bulk-upload-template.xlsx`,
     getConsignmentsFunction
}) => {
    const navigate = useNavigate();

    const [modalInfo, setModalInfo] = useState({
        show: false,
        reasonList:[],
        shipmentParams:null,
        loadingModal: false,
    })
     
    const handleCancel = () => {
        setModalInfo({
            show:false,
            reasonList:[],
            shipmentParams:null,
            loadingModal: false,
        })
    }

    const handleCallback = () => {
        applyPagination({pageNo}, false);
        // window.location.reload();
    }

    const redirectCallBack = () => {
        navigate("/appv2/rto/dashboard/upload-rto")
    }

    const handleConfirm = () => {
        const { shipmentParams } = modalInfo;
        rtoService.consignmentUpdate(shipmentParams)
            .then(responseData => {
                if(checkHttpStatus(responseData?.data?.status)){
                    const res = responseData.data.response.responses[0];
                    if(res["sync"]){
                        const payload = {
                            filters: {
                                pageNo: 0,
                                pageSize: 10,
                                totalCount: 0,
                                isEligibleForRTO: true,
                                deviceFilter: {
                                    deviceType: "DESKTOP"
                                }
                            }
                        }
                        getConsignmentsFunction(payload, false);
                        handleCancel();
                        notifyApiSuccess("Successfully marked the waybill", "Succes");
                    } else {
                        notifyApiError(
                            res["reason"] ? res["reason"] : "Something went wrong, please contact loadshare",
                            "Error"
                        );
                    }
               }
            })
    }

    const handleOptionSelected = (value,row) => {
        const shipmentParams = {
            request: [
              {
                consignment: {
                id: row.id,
                waybillNo: row.waybillNo,
                consignmentStatus: value,
                lastStatusReasonCode: null
            },
                action: "UPDATE_STATUS",
                referenceId: new Date().getTime()
              }
            ]
        };

        let _reasonList = config["reasons"][value];
          if (_reasonList && _reasonList.length) {
              _reasonList = _reasonList.map((data, index) => ({
              value: Number(data.reasonCode),
              label: data.reasonDescription,
              id: Number(data.reasonCode)
            }));
          } 
          setModalInfo({
            ...modalInfo,
            show:true,
            reasonList:_reasonList,
            shipmentParams:shipmentParams
          })
    }
    const columns = [
        {
            title: "Waybill No",
            dataIndex: "waybillNo",
            key: "waybillNo",
            width : 200
        },
        {
            title: "Attempts",
            dataIndex: "consignmentBO.attempts",
            key: "consignmentBO.attempts",
            application: "B2C",
            width : 85,
            render: (data,row) => <span>{row.consignmentBO? row.consignmentBO.attempts : ""} </span>
        },
        {
            title: "Destination",
            dataIndex: "consignmentBO.consignee.address.pincode.city.cityCode",
            key: "destination",
            render: (data,row) => <span>{row.consignmentBO? getLocationData(row?.consignmentBO?.consignee?.address?.pincode) : ""} </span>
        },
        {
            title: "Type",
            dataIndex: "consignmentBO",
            key: "type",
            render: (data,row) => <span>{row.consignmentBO? "Line Haul" : ""}</span>
        },
        {
            title: "Payment",
            dataIndex: "consignmentBO.paymentType",
            key: "consignmentBO.payableAmount",
            application:"B2C",
            render: (data,row) => row.consignmentBO?row.consignmentBO.paymentType:""
        },
        {
            title: "CPD",
            dataIndex: "consignmentBO.expectedDeliveryDate",
            key: "consignmentBO.expectedDeliveryDate",
            render: (data,row) => <span>{row.consignmentBO? <span>{moment(data).format("DD/MM/YYYY")}</span> : ""}</span>
        },
        {
            title: "Booking Date",
             dataIndex: "consignmentBO.bookingDate",
             key: "consignmentBO.bookingDate",
             render: (text,row) =>
             <span>{row.consignmentBO? <span>
             {<div className="flex-box flex-column">
                 <span style={{
                     fontSize: "smaller"
                }}>{moment(text).format("DD/MM/YYYY")}</span>
                 <span style={{fontSize:"smaller"}}>{moment(text).fromNow()}</span>
             </div>}
             </span> : ""}</span>
        },
        {
            title: "Status",
             dataIndex: "consignmentBO.consignmentStatus",
             key: "status",
             render: text => <Tag className="ant-table-tag text-align-center warning-tag"> {text} </Tag>
                     
        }
    ];
    return(
        <Card title={ <h2>RTO Eligible</h2>} extra={
            <BulkOperation
                buttonLabel="RTO Bulk Booking"
                title="Upload RTO Data"
                bulkType="RTO_UPLOAD"
                sampleDownloadLink={sampleBookingDownloadLink}
                objKey="waybills"
                showFileName="showBookingFileName"
                path="'rto_bulk_bookings'"
                syncToServerAction="'UPLOAD_BOOKING'"
                redirectUrl= "rto/dashboard/rto-eligible"
                uploadMethodParams={{}}
                uploadMethod={scanService.uploadRtoRtsBooking}
                callback= {handleCallback}
                redirectCallBack = {redirectCallBack}
            />
        }>
            <Table bordered={true} size="middle"
                rowKey={row => row.id}
                columns={columns}
                pagination={{
                    onChange: getPaginatedData,
                    total: data.totalCount,
                    position: "bottom",
                    current: pageNo,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    pageSize: data.pageSize
                }}
                dataSource={PaginatedRTOEligible? PaginatedRTOEligible[pageNo] : []}
                scroll={{ x: 800 }}
            />
            {
                modalInfo.show && (
                    <RTODownloadModal 
                        show={modalInfo.show && modalInfo.reasonList ? true : false}
                        reasonList={modalInfo.reasonList}
                        shipmentParams={modalInfo.shipmentParams}
                        handleCancel={handleCancel}
                        handleConfirm={handleConfirm}
                    />
                )
            }
        </Card>
    )
};

const mapStateToProps = state => ({
    rtoAction: state.rtoEligible.rtoAction,
    config: state.rtoEligible.config
});

const mapDispatchToProps = dispatch => ({
    getConsignmentsFunction: (payload, isReset) =>
      dispatch(getConsignmentsData(payload, isReset))
});

export default connect(
   mapStateToProps, 
   mapDispatchToProps
)(RTOEligibleTable);