import { Alert, Button, Icon, Modal, Select } from "antd";
import React, { useState } from "react";
import { notifyApiSuccess } from "../../../../common/utils/utils";
import { ENTITY_EXCEPTION_ACTIONS, performAction } from "../services/action.service";

export default function ExceptionsActionModal({ actionArray, exception, onActionComplete, entityCount }) {

    const [ visible, SetVisibile ] = useState(false);
    const [ selectedAction, SetSelectedAction ] = useState(undefined);
    const [ apiSuccess, SetApiSuccess ] = useState(false);
    const [ apiError, SetApiError ] = useState(false);
    const [ postingAction, SetPostingAction ] = useState(false);

    if (!actionArray || actionArray.length == 0) {
        return <></>
    }

    const postActionCall = _ => {
        SetPostingAction(true);
        SetApiError(undefined);
        performAction({
            data : [
                {
                    entityValue  : exception?.entityValue,
                    actionCode   : selectedAction,
                    actionParams : {}
                }
            ]
        })
        .then(_ => {
            SetPostingAction(false);
            SetApiSuccess(true);
            SetVisibile(false);
            notifyApiSuccess(ENTITY_EXCEPTION_ACTIONS[selectedAction]?.actionSuccess || "Action completed successfully!")
            onActionComplete();
        })
        .catch(error => {
            if (error.exceptionMessage) {
                SetApiError(error.exceptionMessage)
            }
            SetPostingAction(false);
            SetApiSuccess(false);
        })
    }

    const closeActionModal = _ => SetVisibile(false);

    return (
        <>
            <Button className="lsn-btn-action" onClick={_ =>  {
                SetSelectedAction(undefined);
                SetApiSuccess(undefined);
                SetVisibile(true);
            }} >
                <div className="flex-box flex-gap-s align-items-center">
                    <div> Take Action </div>
                    <Icon type="arrow-right" />
                </div>
            </Button>
            <Modal className="remove-padding" title={"Exception Actions"} closable={true} maskClosable={false}
             okButtonProps={{
                loading  : postingAction,
                disabled : !selectedAction
             }}
             cancelButtonProps={{
                disabled : postingAction
             }}
             onOk={postActionCall} onCancel={closeActionModal} visible={visible} okText="Take Action" bodyStyle={{ minHeight : 120 }} >
                <div className="flex-column flex-gap-l align-items-stretch">
                    <div className="spacer-s dark-grey-bg">
                        <div className="spacer-hs">
                            Selected Shipment Count : {entityCount}
                        </div>
                    </div>
                    <div className="spacer-m flex-box">
                        <Select className="flex-1" value={selectedAction} disabled={apiSuccess}
                         onChange={SetSelectedAction} placeholder={"Select Action"} loading={postingAction} >
                            {
                                actionArray?.map(action => {
                                    return <Select.Option key={action.key} value={action.value}>
                                        {action.label}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </div>
                    <div className="spacer-m">
                        {
                            apiSuccess === true ?
                            <Alert type="success" message={ENTITY_EXCEPTION_ACTIONS[selectedAction]?.actionSuccess || "Action completed successfully!"} /> :
                            apiSuccess === false ?
                            <Alert type="error" message={apiError || "Error occurred while taking action!"} /> :
                            <></>
                        }
                    </div>
                </div>
            </Modal>
        </>
    )
}