import * as CONSTANTS from './constants';
import AuditService from '../../../services/audit/audit.service';
import { checkHttpStatus } from '../../../common/utils/utils';

const auditService = new AuditService

export const getAuditStatus = (param) => {
  return dispatch => {
    dispatch(getAuditStatusBegin());
    auditService.getAuditStatus(param.locationId)
      .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
          dispatch(getAuditStatusSuccess(response.data.response))
        } else {
          dispatch(getAuditStatusFailure(response.data.response))
        }
      })
      .catch(error => dispatch(getAuditStatusFailure(error)));
  }
}

const getAuditStatusBegin = () => {
  return {
    type: CONSTANTS.AUDIT_CHECK_BEGIN
  }
}

const getAuditStatusSuccess = (config) => {
  return {
    type: CONSTANTS.AUDIT_CHECK_SUCCESS,
    payload: config
  }
}

const getAuditStatusFailure = (error) => {
  return {
    type: CONSTANTS.AUDIT_CHECK_FAILURE,
    payload: error
  }
}

export const fetchAuditRecords = (payload, id) => dispatch => {
  return auditService.fetchAuditRecords(payload, id).then(res => {
    return res;
  });
};

export const finishAudit = (payload) => dispatch => {
  return auditService.finishAudit(payload).then(res => {
    dispatch({
      type : CONSTANTS.AUDIT_CHECK_FINISH
    })
    return res;
  });
};

export const updateAudit = (payload) => dispatch => {
  return auditService.updateAudit(payload).then(res => {
    return res;
  });
};

export const fetchAudits = (payload, isReset) => dispatch => {
  dispatch(fetchAuditsBegin());
  auditService.getAuditList(payload).then(res => {
    if (checkHttpStatus(res?.data?.status)) {
      dispatch(fetchAuditsSuccess(res?.data?.response, isReset));
    } else {
      dispatch(fetchAuditsFailure(res?.data?.response));
    }
  });
};

function fetchAuditsBegin() {
  return {
    type: CONSTANTS.FETCH_AUDITS_BEGIN
  };
}

function fetchAuditsSuccess(payload, isReset) {
  return {
    type: CONSTANTS.FETCH_AUDITS_SUCCESS,
    payload: payload,
    isReset
  };
}

function fetchAuditsFailure(error) {
  return {
    type: CONSTANTS.FETCH_AUDITS_FAILURE,
    payload: error
  };
}