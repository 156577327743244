import { Button, DatePicker, Input, Modal, Spin, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { DownloadTags } from "../../../../common/utils/constants";
import { formatCurrency, notifyApiError, notifyApiSuccess, reportsDownload } from "../../../../common/utils/utils";
import { fetchSettlementActivity } from "../../services/fetch.service";
import { exportSetllementInfoReport } from "../../services/post.service";
import { userType } from "../../utils";
import SummaryData from "../summary.data";
import CoDActiviyFilter from "./view.activity.filters";

export default function ViewCoDActiviy({ fetchObject, rowData, columns, viewType, onModalClose, openActivityModal, SetOpenActivityModal }) {

    const [ settlementActivity, SetSettlementActivity ] = useState(undefined);
    const [ selectedDateRange, SetSelectedDateRange ] = useState([ moment({...fetchObject}.startDate), moment({...fetchObject}.endDate) ])

    const [ downloadingReport, SetDownloadingReport ] = useState(false);

    const [ postRequestObject, SetPostRequestObject ] = useState(undefined)

    const loadDetails = (params = {}) => {
        SetSettlementActivity(undefined);
        var updatedPostObject = {
            ...postRequestObject,
            pageNo    : params.pageNo ? params.pageNo : 1,
            pageSize  : params.pageSize ? params.pageSize : 10,
            startDate : selectedDateRange && selectedDateRange[0] ? selectedDateRange[0].valueOf() : null,
            endDate   : selectedDateRange && selectedDateRange[1] ? selectedDateRange[1].valueOf() : null
        };
        fetchSettlementActivity(updatedPostObject)
        .then(SetSettlementActivity)
        .catch(e => console.log(e));
    }
    
    useEffect(() => {
        if (fetchObject) {
            SetPostRequestObject({ ...fetchObject });
        }
    }, [ fetchObject ])

    useEffect(() => {
        if (openActivityModal && postRequestObject) {
            loadDetails();
        }
    }, [ postRequestObject ])

    const closeModal = _ => {
        SetOpenActivityModal(false);
        if (typeof onModalClose === "function") {
            onModalClose();
        }
    }
    
    const disabledDate = date =>  date.valueOf() >= moment().endOf("day").valueOf();

    const downloadReport = _ => {
        SetDownloadingReport(true);
        exportSetllementInfoReport({
            ...fetchObject,
            startDate : selectedDateRange && selectedDateRange[0] ? selectedDateRange[0].valueOf() : null,
            endDate   : selectedDateRange && selectedDateRange[1] ? selectedDateRange[1].valueOf() : null
        })
        .then(response => {
            if(response.url) {
                notifyApiSuccess("Report generated successfully!")
                reportsDownload( response.url, DownloadTags.s3 );
            }
            SetDownloadingReport(false);
        })
        .catch(e => {
            SetDownloadingReport(false);
            console.log(e);
        })
    }

    const onSearch = searchTransactionId => {
        if (searchTransactionId) {
            searchTransactionId = searchTransactionId.trim().split(" ");
            searchTransactionId = searchTransactionId.reduce((c, n) => [...c, ...n.split(",")], [])
            searchTransactionId = searchTransactionId.filter(c => c.trim() !=="" );
            if (searchTransactionId.filter(c => isNaN(c)).length > 0) {
                return notifyApiError("Transaction IDs should be numbers!")
            }
            SetPostRequestObject(currnet => {
                var temp = {
                    ...currnet,
                    transactionIds : searchTransactionId
                }
                return temp;
            })
        }
        else {
            SetPostRequestObject(currnet => {
                var temp = {
                    ...currnet,
                    transactionIds : undefined
                }
                return temp;
            })
        }
    }

    return (
        <Modal title="Partner Activity" visible={openActivityModal} 
         onOk={closeModal} onCancel={closeModal} okText="Close" maskClosable={false}
         width={"80%"} centered={true} closable={false} destroyOnClose={true}
         cancelButtonProps={{ 
            style : {
                display : "none"
            }
         }} >
            <div className="flex-column flex-gap-l">
                <div className="flex-box">
                    <DatePicker.RangePicker format="DD/MM/YYYY" disabledDate={disabledDate}
                    onChange={date => {
                        SetPostRequestObject(current => {
                            var temp = { ...current };
                            if (date && date.length > 0) {
                                temp.startDate = date[0].startOf("day").valueOf();
                                temp.endDate   = date[1].endOf("day").valueOf();
                                SetSelectedDateRange([ date[0], date[1] ])
                            }
                            else {
                                temp.startDate = null;
                                temp.endDate   = null;
                                SetSelectedDateRange([ null, null ]);
                            }
                            return temp;
                        })
                        
                    }} separator={"to"} className="flex-2"
                    value={selectedDateRange} />
                    <div className="flex-3"></div>
                </div>

                <SummaryData data={[
                    {
                        label : userType() === "PARTNER_ADMIN" ? "Location name" : "Partner Name",
                        value : rowData?.entityName
                    },
                    {
                        label : "Total Outstanding",
                        value : formatCurrency(rowData?.amountMap?.NET_OUTSTANDING) || 0
                    },
                    {
                        label : "Pending for Approval",
                        value : formatCurrency(rowData?.amountMap?.PENDING_FOR_APPROVAL) || 0
                    }
                ]} />

                <div className="flex-box justify-content-space-between align-items-center">
                    <Input.Search className="flex-1" placeholder="Search by Transaction ID" onSearch={onSearch} allowClear={true} />

                    <div className="flex-1 flex-box flex-gap-l justify-content-flex-end align-items-center">
                        
                        {
                            openActivityModal &&
                            <CoDActiviyFilter partnerId={rowData?.entityId} viewType={viewType}
                                applyFilter={(params = {}) => {
                                SetPostRequestObject(currnet => {
                                    var temp = {
                                        ...currnet,
                                        approvalStatus : params.approvalStatus ?
                                                            params.approvalStatus === [] ? undefined : params.approvalStatus : 
                                                            null,
                                        locationIds    : params.locationId ?
                                                            params.locationId === [] ? undefined : params.locationId : 
                                                            null
                                    }
                                    return temp;
                                })
                                }}
                                resetFilters={_ => {
                                    SetPostRequestObject({ ...fetchObject });
                                }}
                                buttonDisabled={settlementActivity === undefined}/>
                        }
                        
                        <Button disabled={settlementActivity === undefined || settlementActivity?.totalCount === 0}
                        onClick={downloadReport} loading={downloadingReport} className="lsn-btn-secondary" icon="download">
                            Export Data
                        </Button>
                        
                    </div>
                </div>

                {
                    settlementActivity === undefined ? 
                    <Spin spinning={true} /> :
                    <Table bordered={true} dataSource={settlementActivity.settlementInfos}
                    size={"middle"} columns={columns}
                    scroll={{ x : "auto" }} rowKey="id"
                    pagination={settlementActivity.maxPage <=1 ? false : {
                        total    : settlementActivity.totalCount,
                        onChange : pageNo => loadDetails({ pageNo }),
                        current  : settlementActivity.currentPageNo
                    }} />
                }
            </div> 
        </Modal>
    )
}