export const ACTION_TYPES = {
    CACHE_CONSIGNMENT_REASONS : "CACHE_CONSIGNMENT_REASONS"
}

export const ReasonsReducer = (state = { consignmentsReasons : undefined } , action = {}) => {

    switch (action.type) {
        case ACTION_TYPES.CACHE_CONSIGNMENT_REASONS : return {
            ...state,
            consignmentsReasons : action.payload
        }
        default : return state;
    }
}
