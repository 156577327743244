import { ACTION_TYPES, INITIAL_STATE } from "./constants";

export const uploadAssignedPickupReducer = (state = INITIAL_STATE, action = {}) => {
    switch(action.type){
        case ACTION_TYPES.SET_SELECTED_CUSTOMER_VALUES:
            return {
                ...state,
                selectedCustomer: action.payload
            };
        case ACTION_TYPES.SET_UPLOADED_BOOKING_FILE:
            return {
                ...state,
                selectedUploadBooking: action.payload
            };
        case ACTION_TYPES.FETCH_CUSTOMER_LIST_BEGIN:
        case ACTION_TYPES.UPLOAD_PICKUP_FILE_BEGIN:
        case ACTION_TYPES.CREATE_ASSIGNED_PICKUP_BEGIN:
            return {
                ...state,
                loading: true
            };
        case ACTION_TYPES.UPLOAD_PICKUP_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                ...action.payload
            };
        case ACTION_TYPES.UPLOAD_PICKUP_FILE_FAILURE:
        case ACTION_TYPES.FETCH_CUSTOMER_LIST_FAILURE:
        case ACTION_TYPES.CREATE_ASSIGNED_PICKUP_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case ACTION_TYPES.CREATE_ASSIGNED_PICKUP_SUCCESS: 
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case ACTION_TYPES.FETCH_CUSTOMER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                customerList: action.payload,
            };
        case ACTION_TYPES.UPDATE_S3_PATH:
            return {
                ...state,
                s3path: action.payload
            }
        case ACTION_TYPES.RESET_UPLOAD_PICKUP_DATA:
            return {
                ...INITIAL_STATE,
                customerList: state.customerList
            };
        default:
            return state;
    }
};
