import {
  devEnvType,
  s3Connections
} from "../../environments/node-react-environment.js";

export class Constants {
  static get HTTP_HEADERS() {
    return {
      "Content-Type": "application/json",
      token: localStorage.getItem("accessToken"),
      tokenId: localStorage.getItem("tokenId")
    };
  }

  static get HTTP_HEADERS_LOGGED_IN() {
    return {
      "Content-Type": "application/json",
      token: localStorage.getItem("primaryAccessToken"),
      tokenId: localStorage.getItem("primaryTokenId")
    };
  }

  static get SUCCESS_HTTP_CODES() {
    return [200, 201, 202];
  }

  static get MAP_URL() {
    return "https://maps.googleapis.com/maps/api/js?key=AIzaSyBV_gUgzZyRxqspe6gNnFxIrK7GElIDMxY&v=3.exp&libraries=geometry,drawing,places";
  }

  static get SUSPICIOUS_CLASSNAME() {
    return this.suspiciousClassName;
  }
  static set SUSPICIOUS_CLASSNAME(className) {
    if (typeof className === "string") {
      this.suspiciousClassName = className.toUpperCase();
    }
  }
  static get TRACKING_CLASSNAME() {
    return this.trackingClassName;
  }
  static set TRACKING_CLASSNAME(className) {
    if (typeof className === "string") {
      this.trackingClassName = className.toUpperCase();
    }
  }
  static get HEADER_CLASSNAME() {
    return this.headerClassName;
  }
  static set HEADER_CLASSNAME(className) {
    if (typeof className === "string") {
      this.headerClassName = className.toUpperCase();
    }
  }
  static get sessionUser() {
    // to get the session Object
    if (!localStorage.getItem("user")) {
      return {};
    }
    return JSON.parse(atob(localStorage.getItem("user")));
  }
  static get PARTNER_ID() {
    // to get the logged in partner location ID or session Partner ID
    const user = this.sessionUser;
    if (!user.partner) {
      return null;
    }
    return Number(user.partner.id);
  }

  static get LOGGED_PARTNER_LOCATION_ID() {
    // to get the logged in partner location ID
    const locationId = localStorage.getItem("loggedPartnerLocationId");
    return Number(locationId);
  }
  static get CURRENT_USER_ID() {
    // get Selected partner Id in Admin View
    var partnerId = localStorage.getItem('currentPartnerId');
    if (!partnerId) {
      partnerId = this.sessionUser?.partner?.id;
    }
    return Number(partnerId);
  }
  static get userLocationId() {
    // to get the current partner location ID
    let locationId = localStorage.getItem("currentPartnerLocationId");
    if (!locationId) {
      locationId = this.sessionUser?.location?.id;
    }
    return parseInt(locationId, 0);
  }
  /**
   * it will return the s3link with bucket name attached
   */
  static get s3BucketLink() {
    return `https://${s3Connections.direct.region}/${s3Connections.direct.bucketName
      }`;
  }
  static get s3Connection() {
    return s3Connections;
  }
  static get ENV() {
    return devEnvType;
  }

  static get isProdEnv() {
    return devEnvType === "prod";
  }

  static get isDevEnv() {
  return ["dev", "local", "perf"].indexOf(devEnvType) > -1;
  }

  static HEADER_TIMEOUT_MS = 60 * 5;

  static get paymentTypes() {
    return [
      { label: 'Prepaid', value: 'PREPAID', checked: false },
      { label: 'Topay', value: 'TOPAY', checked: false },
      { label: 'Credit', value: 'CREDIT', checked: false }
    ];
  }

  static get podPayments() {
    return [
      { name: 'Cash', value: 'cash', checked: true }
      // ,{ name: 'Card', value: 'card', checked: false }
    ];
  }

  static get b2cPaymentTypes() {
    return [
      { name: 'Prepaid', value: 'PREPAID', checked: true },
      { name: 'COD', value: 'COD', checked: false }
    ];
  }

  static get organizationFilteredValues() {
    return [
      {
        value: 'Default',
        name: 'DEFAULT',
        label: 'DEFAULT',
        sampleLink: 'https://loadshare-v2.s3.ap-south-1.amazonaws.com/documents/templates/xlsx/bulk_booking/BulkBooking_Default.xlsx'
      },
      {
        value: 'BLUEDART',
        name: 'BLUEDART',
        label: 'BLUEDART',
        sampleLink: 'https://loadshare-v2.s3.ap-south-1.amazonaws.com/documents/templates/xlsx/bulk_booking/BulkBooking_BlueDart.xlsx'
      },
      {
        value: 'DEALSHARE',
        name: 'DEALSHARE',
        label: 'DEALSHARE',
        sampleLink: 'https://loadshare-v2.s3.ap-south-1.amazonaws.com/documents/templates/xlsx/bulk_booking/BulkBooking_Dealshare1.xlsx'
      },
      {
        value: 'ICICI',
        name: 'ICICI',
        label: 'ICICI',
        sampleLink: 'https://loadshare-v2.s3.ap-south-1.amazonaws.com/documents/templates/xlsx/bulk_booking/BulkBooking_ICICI.xlsx'
      },
      {
        value: 'SNAPDEAL',
        name: 'SNAPDEAL',
        label: 'SNAPDEAL',
        sampleLink: 'https://loadshare-v2.s3.ap-south-1.amazonaws.com/documents/templates/xlsx/bulk_booking/BulkBooking_Snapdeal.xlsx'
      },
      {
        value: 'PNG',
        name: 'P&G',
        label: 'P&G',
        sampleLink: 'https://loadshare-v2.s3.ap-south-1.amazonaws.com/documents/templates/xlsx/bulk_booking/BulkBooking_PnG.xlsx'
      },
      {
        value: 'LITE',
        name: 'LITE',
        label: 'LITE',
        sampleLink: 'https://loadshare-v2.s3.ap-south-1.amazonaws.com/documents/templates/xlsx/bulk_booking/LiteBooking.xlsx'
      }
    ];
  }

  static get rolesTypesHub() {
    return [
      { label: 'BILLING', value: 'BILLING' },
      { label: 'PICKUP', value: 'PICKUP' },
      { label: 'DELIVERY', value: 'DELIVERY' },
      { label: 'HEAD_OFFICE', value: 'HEAD_OFFICE' },
      { label: 'BOOKING_OFFICE', value: 'BOOKING_OFFICE', disabled: false }
    ];
  }

  static get rolesTypesBranch() {
    return [
      { label: 'BILLING', value: 'BILLING' },
      { label: 'PICKUP', value: 'PICKUP' },
      { label: 'DELIVERY', value: 'DELIVERY' },
      { label: 'BOOKING_OFFICE', value: 'BOOKING_OFFICE', disabled: false }
    ];
  }

  static get locationOwners() {
    return [
      { name: 'Partner', value: 'PARTNER', checked: true },
      { name: 'Customer', value: 'CUSTOMER', checked: false },
      { name: 'Vendor', value: 'VENDOR', checked: false }
    ];
  }

  static get ApiActions() {
    return Object.freeze({
      create: 'CREATE',
      update: 'UPDATE_DETAILS',
      link: 'LINK',
      delink: 'DELINK'
    })
  }


  static get permissionConfigAndModuleMapping() {
      return {
        "SETTINGS": {
          "LOCATION": {
            "configKeyName": "partnerLocOnboardingEnabledUsers",
            "allowedActionForNonEnabledUsers": ['READ']
        }
      }
    }
  }

}

export const LOCAL_STORAGE_STATE = Object.freeze({
  SET: "SET",
  GET: "GET",
  DELETE: "DELETE"
});

export const USER_LOGGEDIN_INFO = Object.freeze({
  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
  TOKEN_ID: "tokenId",
  USER: "user",
  PERMISSION: "permissions",
  APPLICATION: "application",
  LOGGED_IN: "LOGGED_IN",
  _CONFIGURATIONS: "_configrations",
  COOKIEBASEPATH: "/",
  INVALID_USER: "invalidUser",
  CURRENT_PARTNER_ID: "currentPartnerId",
  CURRENT_PARTNER_LOCATION_ID: "currentPartnerLocationId",
  LOGGEDIN_PARTNER_ID: "loggedPartnerId",
  LOGGED_PARTNER_LOCATION_ID: "loggedPartnerLocationId",
  REFRESH_FLAG: "refreshFlag",
  CUSTOMER: "CUSTOMER",
  CURRENT_PARTNER_NAME: "currentPartnerName",
  CURRENT_LOCATION_NAME: "currentLocationName",
  PRIMARY_ACCESS_TOKEN: "primaryAccessToken",
  PRIMARY_ACCESS_ID: "primaryTokenId"
});

export const USER_LEVEL = Object.freeze({
  BRANCH: "BRANCH",
  HEAD_OFFICE: "HEAD_OFFICE",
  PARTNER_HUB: "PARTNER_HUB",
  CUSTOMER: "CUSTOMER",
  ADMIN: "ADMIN",
  LSN: "LSN",
  PARTNER: "PARTNER"
});

export const SELF = "Self";

export const DownloadTags = Object.freeze({
  s3: "s3_report_url",
  type: "download_url"
});

export const SELECTED_ENTITY_OPTIONS = {
  COLOADER: "coloader",
  VENDOR: "vendor"
};

export const FILTER_INPUT_TYPE = {
  DATERANGE: "dateRange",
  RADIO_BUTTON: "radioButton",
  RADIO: "radio",
  SELECT: "select",
  INPUT: "input",
  SWITCH: 'switch'
};

export const USER_LEVEL_HIGER_PARTNERS = [
  USER_LEVEL.HEAD_OFFICE,
  USER_LEVEL.ADMIN,
  USER_LEVEL.PARTNER_HUB
];

export const TIMELINES_KEYS = Object.freeze({
  T: "T",
  T_1: "T_1",
  T_2: "T_2",
  T_2_GREATER: "T_2_GREATER",
  T_3: "T_3",
  T_3: "T_3_LESSER",
  PENDING_FOR_APPROVAL: "PENDING_FOR_APPROVAL",
  NET_OUTSTANDING: "NET_OUTSTANDING"
});

export const TIMELINES = Object.freeze({
  T: "T",
  T_1: "T-1",
  T_2: "T-2",
  T_2_GREATER: ">T-2",
  T_3: "T-3",
  T_3_LESSER: "<T-3",
  PENDING_FOR_APPROVAL: "PENDING_FOR_APPROVAL",
  NET_OUTSTANDING: "NET_OUTSTANDING"
});

export const RISKVALUE = [
  { label: "HIGH", value: "HIGH" },
  { label: "LOW", value: "LOW" },
  { label: "MEDIUM", value: "MEDIUM" },
  { label: "NORMAL", value: "NORMAL" },
  { label: "NONE", value: "NONE" }
];

export const MODEL_TYPE = Object.freeze({
  BRANCH: "branchTableModel",
  LSN: "lsnTableModel",
  PARTNER: "partnerTableModel"
});

export const INPUT_TYPE = {
  TEXT: "TEXT",
  TEXTAREA: "TEXTAREA"
};

export const UPLOAD_BUTTON_TYPE = {
  DRAGDROP: "DRAGDROP",
  DRAGDROPWITHBUTTON: "DRAGDROPWITHBUTTON",
  BUTTON: "UPLOAD_BUTTON",
  PREVIEW: "PREVIEW"
};

export const S3_REPORT_STATUS = {
  BEGIN: 'BEGIN',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
}

export const CONTACT_NUMBER = {
  PRIMARY   : 'PRIMARY',
  SECONDARY : 'SECONDARY',
  ADDRESS   : 'ADDRESS',
  SHIPPER_PRIMARY   : 'SHIPPER_PRIMARY',
}
export const sourceType = {
  COLLECT: "COLLECT",
  REMIT: "REMIT"
};

export const LSN_APPROVAL_STATUS = [
  { label: "REQUESTED FOR APPROVAL", value: "REQUEST_FOR_APPROVAL" },
  { label: "REJECTED", value: "REJECTED" },
  { label: "APPROVED", value: "APPROVED" },
];

export const emptyMessageList = {
  notAvailable: "NA"
};

export const COD_ACTIVITY_STATUS = {
  PENDING: "PENDING",
  REQUEST_FOR_APPROVAL: "REQUEST_FOR_APPROVAL",
  PARTIAL_APPROVE: "PARTIAL_APPROVE",
  REJECT: "REJECT",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED"
}

export const locationOwners = [
  { name: 'Partner', value: 'PARTNER', checked: true },
  { name: 'Customer', value: 'CUSTOMER', checked: false },
  { name: 'Vendor', value: 'VENDOR', checked: false }
];

export const validationRules = {
  // email: "[a-zA-Z0-9._\-]+[@]+[a-zA-Z0-9._\-]+?([.]{1}[a-zA-Z]{2,6}){1,2}",
  email: "([a-zA-Z0-9]+)([\_\.\{1}])?([a-zA-Z0-9]+)\@([a-zA-Z0-9]+)([\.])([a-zA-Z\.]+)",
  newEmail:"^[^\s@]+@[^\s@]+\.[^\s@]+$",
  domainEmail: "[a-zA-Z0-9._\-]+[@]+loadshare.net",
  number: "([0-9]+)?.?[0-9]+",
  name: "[a-zA-Z][a-zA-Z ]{3,}",
  nameNumber: "^[a-zA-Z0-9_ :-]*$",
  nameNumberContactPerson: "^[a-z A-Z]{0,50}$",  
  nameNumberDash: "^(?=.*[a-zA-Z])[A-Za-z0-9]+$",
  password: "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s).{6,12}",
  // mobile: "([0|\+[0-9]{1,5})?([6-9][0-9]{9})",
  mobile: '^((\\+91-?)|0)?[0-9]{10}$',
  mobileWithRegex: "^[6-9]{1}[0-9]{9}$",
  moreThanZero: "^[1-9][0-9]{0,6}$",
  decimal: "^[.\\d]+$",
  gstNumber: "^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$",
  shortCode: "^[a-zA-Z0-9_:-]*$",
  vehicleNumber: "^[a-zA-Z0-9]{7,}$",
  newVehicle: "[a-zA-Z]{2}[0-9]{2}[A-Za-z]{1,2}[0-9]{3,4}$",
  bicycleNumber: "^\d{4}$",
  ifsc: "^[A-Za-z]{4}\d{7}$",
  lbh: "^([0-9]+)?\.{0,1}?[0-9]+$",
  durationWidgetRegex: "^\\d{0,3}[,]\\d{0,14}",
  pincode: "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$",
  contractEmail: "^[a-zA-Z0-9\._-]+([\.-]?\w+)*@[a-zA-Z0-9]+([\.-]?[a-zA-Z]+)*(\.[a-zA-Z]{2,6})+$",
  contractName:"^[a-z A-Z0-9]*$"
};

export const BulkUploadStatus = {
  DEFAULT: 'DEFAULT',
  SYNCING: 'SYNCING',
  SYNCINED: 'SYNCINED',
  RESYNC: 'RESYNC'
}

export const ORGANISATIONLIST = {
  ICICI: 'ICICI',
  DEALSHARE: 'DEALSHARE',
  BLUEDART: 'BLUEDART',
  LITE: 'LITE',
  SNAPDEAL: 'SNAPDEAL',
  PNG: 'PNG'
}

export const TpOrganisation = Object.freeze({
  fk: devEnvType === 'prod' ? 4 : 1
})

export const TpEventTypes = Object.freeze({
  booking: 'BOOKING',
  inscan: 'INSCAN',
  ofd: 'OFD',
  pod: 'POD',
  delivered: 'DELIVERED',
  undelivered: 'UNDELIVERED',
  rto: 'RTO',
  rto_inscan: 'RTO_INSCAN',
  rto_ofd: 'RTO_OFD',
  rto_pod: 'RTO_POD'
})


export const ReportTypes = Object.freeze({
  awb: 'AWB',
  drs: 'DRS',
  manifest: 'MANIFEST',
  sparkline: 'SPARKLINE',
  bag: 'BAG',
  drsSheet: 'DRS_SHEET',
  rtoDrsSheet: 'RTO_DRS_SHEET',
  rtsDrsSheet: 'RTS_DRS_SHEET'
});

export const FlowTypes = Object.freeze({
  FORWARD: 'FORWARD',
  RTO: 'RTO',
  RTS: 'RTS'
});

export const ApiActions = Object.freeze({
  create: 'CREATE',
  update: 'UPDATE_DETAILS',
  link: 'LINK',
  delink: 'DELINK'
})

export const TRANSISTION_STATE = Object.freeze({
  STATE_PENDING: 'PENDING',
  STATE_COMPLETED: 'COMPLETED',
  STATE_CLOSED: 'CLOSED',
  STATE_IN_TRANSIT: 'IN_TRANSIT',
  STATE_HANDED_OVER: 'HANDED_OVER'
})

export const GenerateCodes = Object.freeze({
  MANIFEST: 'MANIFEST',
  RTO_MANIFEST: 'RTO_MANIFEST',
  DRS: 'DRS',
  RTO_DRS: 'RTO_DRS'
})