import { useEffect, useState } from "react";
import ShipperCoDView from "./shipper.view";
import ViewComponent from "./view.component";

export default function RootPartnerCoDView({ partnerId, locationId, expanded, reloadSparkline, className, showMenu, parentEntityId, successCallback, showSparklineSummary }) {

    const expandedRowRender = (rootParnterRow, index, indent, expanded) => {
        return <ShipperCoDView
         rootPartnerId={rootParnterRow.entityId} rootPartner={rootParnterRow}
         locationId={locationId} partnerId={partnerId} expanded={expanded} />
    }
      
    useEffect(() => {
        if (partnerId && expanded && locationId) {
            SetPostObject({
                dateRangeMap   : [ "T", "T-1", "T-2", ">T-2", "NET_OUTSTANDING", "PENDING_FOR_APPROVAL"],
                partnerIds     : [ partnerId ],
                rootPartnerIds : [ 268, 269 ],
                locationIds    : [ locationId ],
                groupCriteria  : "ROOT_PARTNER"
            });
        }
    }, [ partnerId, expanded]);
    
    const [ postObject, SetPostObject ] = useState({
        dateRangeMap   : [ "T", "T-1", "T-2", ">T-2", "NET_OUTSTANDING", "PENDING_FOR_APPROVAL"],
        partnerIds     : [ partnerId ],
        rootPartnerIds : [ 268, 269 ],
        locationIds    : [ locationId ],
        groupCriteria  : "ROOT_PARTNER"
    })

    return (
        <>
            {
                partnerId && expanded && locationId &&
                <ViewComponent expandedRowRender={expandedRowRender} showMenu={showMenu} showSparklineSummary={showSparklineSummary}
                 postObject={postObject} className={className} parentEntityId={parentEntityId}
                 actions={{
                    onRemitSuccess : _ => {
                        if (typeof reloadSparkline === "function") {
                            reloadSparkline();
                        }
                        if (typeof successCallback === "function") {
                            successCallback();
                        }
                    }
                 }} />
            }
        </>
    )
}