import { apiService } from "../../../../common/apiService/service";
import { Constants } from "../../../../common/utils/constants";
import { checkHttpStatus } from "../../../../common/utils/utils";
import UserService from "../../../../services/settings/user.service";
import { transformRtoDrsList } from "./transformer";

export const fetchRTODrses = payload => new Promise((resolve,reject) => {
    apiService.post("lm/v1/rto/drs/fetch/pod-list", payload)
    .then(response => {
        if (response.rtoDeliveryRunSheets) {
            const responseObject = transformRtoDrsList(response);
            resolve(responseObject);
        }
        else if (response.totalCount == 0) {
            resolve(response);
        }
    })
    .catch(reject);
})

export const createRTODRS = postObj => {
    if (!postObj.rtoDrsCode) {
        throw "Enter a DRS Code to create DRS!"
    }
    else if (!postObj.rtoDrsUserId) {
        throw "Choose user to create DRS!"
    }

    return apiService.post("lm/v1/rto/drs", postObj);
}

export const fetchDRSDetails = rtoDrsCode => apiService.get(`lm/v1/rto/drs/${rtoDrsCode}`)

export const deleteRTODRS = rtoDrsCode => apiService.delete(`lm/v1/rto/drs/${rtoDrsCode}`)

export const fetchUsers = _ => new Promise((resolve,reject) => {
    (new UserService)
    .getUsersByLocation(Constants.userLocationId)
    .then(({ data }) => {
        if (checkHttpStatus(data?.status)) {
            resolve(data?.response?.users)
        }
    })
}) 

export const fetchUnassignedCount = _ => apiService.get(`lm/v1/rto/drs/consignments/count`)

export const fetchUnassignedList = (pageNo, pageSize) => apiService.get(`lm/v1/rto/drs/consignments?pageNo=${pageNo}&pageSize=${pageSize}`)

export const linkAWBtoDRS = (rtoDrsCode, awb) => apiService.put(`lm/v1/rto/drs/${rtoDrsCode}/waybill?waybillNo=${awb}`)

export const delinkAWBfromDRS = (rtoDrsCode, awb) => apiService.delete(`lm/v1/rto/drs/${rtoDrsCode}/waybill/${awb}`)

export const uploadDrs = postObj => apiService.post("lm/v1/rto/drs/upload", postObj)