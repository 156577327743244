import { useEffect, useState } from "react"
import { fetchDRSData, fetchSparkline } from "./service"
import _ from "lodash";
import DRSDashboardSparkline from "./components/sparkline";
import DrsDashboardView from "./components/drs.view";
import DrsDashboardMenu from "./components/drs.menu";
import { Constants } from "../../../common/utils/constants";
import { fetchUserLocationMappings } from "./state/actions";
import { useSelector } from "react-redux";

const ALL_CLUSTER_REF = "ALL";

export default function DRSView() {

    const [ noLiveDrs, SetNoLiveDrs ] = useState(false);
    const [ drsData, SetDrsData ] = useState(undefined);
    const [ sparklineData, SetSparklineData ] = useState(undefined);
    const [ locationOptions, SetLocationOptions ] = useState(undefined);
    const [ clusterNameOptions, SetClusterNameOptions ] = useState(undefined);
    const [ postObject, SetPostObject ] = useState({
        locationIds  : undefined,
        clusterNames : undefined,
        tags         : [],
        feIds        : []
    });
    const [ pageNo, SetPageNo ] = useState(1);

    const userLocationMapping = useSelector(state => state.userLocationMappingReducer.userLocationMapping);

    const fetchData = _ => {
        fetchDRSData({
            ...postObject,
            pageNo
        })
        .then(d => {
            SetNoLiveDrs(false);
            SetDrsData(d);
        })
        .catch(e => {
            if (e.code === 404) {
                SetDrsData([]);
                SetNoLiveDrs(true);
            }
        })
    }

    const fetchSparklineData = _ => {
        fetchSparkline(postObject)
        .then(SetSparklineData)
        .catch(e => {
            if (e.code === 404) {
                SetSparklineData({});
            }
        });
    }

    useEffect(() => {
        fetchUserLocationMappings()
        .then(userLocationMappings => {

            var clusterKeys = Object.keys(userLocationMappings);
            if (clusterKeys.length == 0) {
                SetLocationOptions([]);
                SetPostObject(current => {
                    return {
                        ...current,
                        locationIds : [ Constants.userLocationId ]
                    }
                });
            }
            else if (clusterKeys.length == 1) {
                SetLocationOptions(userLocationMappings[clusterKeys[0]]);
                SetPostObject(current => {
                    return {
                        ...current,
                        clusterNames : [ clusterKeys[0] ]
                    }
                });
            }
            else if (clusterKeys.length > 1) {
                SetPostObject(current => {
                    return {
                        ...current,
                        clusterNames : clusterKeys
                    }
                });
                //To differentiate between keys in mapping and display label for clusters
                SetClusterNameOptions(_ => {
                    return clusterKeys.map(cluster => ({
                        key   : cluster,
                        value : cluster,
                        label : cluster.split("__").join(" "),
                    }))
                })
            }

        })
        .catch(e => {
            SetPostObject(current => ({
                ...current,
                locationIds : []
            }))
        })
    }, [])

    useEffect(_ => {
        if (postObject.clusterNames) {
            SetLocationOptions(_ => {
                var _locations = [];
                postObject.clusterNames.map(ulm => {
                    userLocationMapping[ulm].map(l => {
                        _locations.push(l)
                    });
                });
                return _locations;
            })
        }

    }, [ postObject.clusterNames ])

    useEffect(_ => {
        if (postObject.locationIds || postObject.clusterNames) {
            SetSparklineData(undefined);
            SetDrsData(undefined);
            fetchSparklineData();
            fetchData();
        }
    }, [ postObject ]);

    useEffect(_ => {
        if (postObject.locationIds || postObject.clusterNames) {
            SetDrsData(undefined);
            fetchData();
        }
    }, [ pageNo ]);

    return (
        <div className="flex-column flex-gap-xl white-bg spacer-vl">
            
            <DRSDashboardSparkline sparklineData={sparklineData} noLiveDrs={noLiveDrs} />

            {
                (postObject.locationIds || postObject.clusterNames) &&
                <DrsDashboardMenu selectedTags={postObject.tags} selectedClusters={postObject.clusterNames}
                 locationOptions={locationOptions} clusterNameOptions={clusterNameOptions}
                 selectedLocation={postObject.locationIds} selectedFes={postObject.feIds}
                 sparklineData={sparklineData} ALL_CLUSTER_REF={ALL_CLUSTER_REF}
                 onFilterChange = {(value, key) => {
                    if (key === "clusterNames") {
                        var tempValue = [ ...value ];
                        if (value.length === 1 && value[0] === ALL_CLUSTER_REF) {
                            tempValue = Object.keys(userLocationMapping);
                        }
                        SetPostObject(current => {
                            return {
                                ...current,
                                [key]       : tempValue,
                                locationIds : undefined,
                                pageNo      : 1
                            }
                        });
                    }
                    else {
                        SetPostObject(current => {
                            return {
                                ...current,
                                [key]  : value,
                                pageNo : 1
                            }
                        });
                    }
                 }}/>
            }

            <DrsDashboardView drsData={drsData} selectedTags={postObject.tags} noLiveDrs={noLiveDrs} onPageChange={SetPageNo} />

        </div>
    )
}