import MetabaseWidget from "../components/metabase.widget";

export default function LastMileMetabaseDashboard() {

    return (
        <div className="flex-column flex-gap-l" style={{ paddingBottom : 24 }}>
            <div className="font-size-m-3 grey-text"> <strong>Note:</strong> This is combined data of all hubs managed by this "PARTNER". </div>
            <div className="flex-box flex-gap-m">
                <MetabaseWidget text={"First Attempt Count"} className={"flex-1 flex-box justify-content-center"}
                 reportJson={{ questionNo : 829, reportName : "lm-first-attempt-for-ofd", lockedParams: ["partner_id"], height : "300px" }} />
                 <MetabaseWidget text={"First Attempt Percentage"} className={"flex-1 flex-box justify-content-center"}
                  reportJson={{ questionNo : 831, reportName : "lm-fad-percentage", lockedParams: ["partner_id"], height : "300px" }} />
                  <MetabaseWidget text={"COD Amount Collected"} className={"flex-1 flex-box justify-content-center"}
                   reportJson={{ questionNo : 1033, reportName : "lm-cod-amount-collected", lockedParams: ["partner_id"], height : "300px" }} />
            </div>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"LM - Today Performance Topline"} className={"flex-1 flex-box justify-content-center"}
                    reportJson={{ questionNo : 836, reportName : "lm-performance-topline", lockedParams: ["partner_id"], height : "500px" }} />
            </div>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"LM - FE Level - Performance and COD Collected - Today"} className={"flex-1 flex-box justify-content-center"}
                    reportJson={{ questionNo : 837, reportName : "lm-fe-level-performance-and-cod-collected-data-dump", lockedParams: ["partner_id"], height : "500px" }} />
            </div>
            <div className="flex-box flex-gap-m">
                <MetabaseWidget text={"COD Collectable Report - with Date"} className={"flex-1 flex-box justify-content-center"}
                    reportJson={{ questionNo : 21, reportName : "cod-collectable-report", lockedParams: ["partner_id"], height : "500px", dateParams: new Set(["startDate","endDate"]) }} />
                <MetabaseWidget text={"Location Pendency Report - with Date"} className={"flex-1 flex-box justify-content-center"}
                        reportJson={{ questionNo : 39, reportName : "location-pendency-report", lockedParams: ["partner_id"], height : "500px", dateParams: new Set(["startDate"]) }} />
            </div>
            <div className="flex-box flex-gap-m">
                <MetabaseWidget text={"DRS Summary Report - with Date"} className={"flex-1 flex-box justify-content-center"}
                    reportJson={{ questionNo : 50, reportName : "drs-summary-report", lockedParams: ["partner_id"], height : "500px" }} />
            </div>
            <div className="flex-box flex-gap-m">
                <MetabaseWidget text={"LM RTO Pendency - with Date"} className={"flex-1 flex-box justify-content-center"}
                    reportJson={{ questionNo : 65, reportName : "lm-rto-pendency-report", lockedParams: ["partner_id"], height : "500px", dateParams: new Set(["startDate"]) }} />
            </div>
            
        </div>
    )

}