import { ReactComponent as Work } from "../../../../assets/img/icons/work.svg";
import { Divider, Icon, Spin } from "antd";

export default function DRSDashboardSparkline ({ sparklineData, noLiveDrs }) {

    return (
        <div className="spacer-hl">
            <div className="flex-box flex-gap-xl border-grey white-bg justify-content-center align-items-center border-radius-xl" style={{ position : 'relative' }}>

                {
                    sparklineData === undefined ?
                    <div className="flex-box align-items-center" style={{ minHeight : 146 }}>
                        <Spin spinning={true} />
                    </div> :
                    noLiveDrs ?
                    <div style={{ minHeight : 146 }} className="spacer-vl">No Shipments out for delivery.</div> :
                    <>
                        <div className="flex-column flex-4 flex-gap-m ">
                            
                            <div className="flex-box flex-gap-xl justify-content-space-between align-items-center spacer-m">

                                <div className="flex-box flex-gap-l flex-2 align-items-center">
                                    <Work style={{ width : 60, height : 60 }} className="spacer-hm border-grey border-radius-m" />
                                    <div className="flex-column flex-gap-s">
                                        <div className="light-grey-text font-size-m-3" style={{ fontWeight : 200 }}> Total OFD </div>
                                        <div className="font-size-l-2 text-semi-bold"> {sparklineData.totalShipmentCount} </div>
                                    </div>
                                </div>

                                <div className="flex-box flex-gap-l flex-1 justify-content-space-between align-items-center flex-wrap">
                                    <div className="flex-box flex-1 justify-content-center align-items-center flex-gap-m spacer-hm curved-end"
                                     style={{
                                        backgroundColor : "#E7FEF0"
                                     }}>
                                        <label> Delivered : </label>
                                        <label style={{ fontWeight : 500 }} className="font-size-m-3">
                                            {sparklineData?.deliveredMetaData?.count}({sparklineData?.deliveredMetaData?.percentage}%)
                                        </label>
                                    </div>
                                    <div className="flex-box flex-1 justify-content-center align-items-center flex-gap-m spacer-hm curved-end"
                                     style={{
                                        backgroundColor : "#EBF3FF"
                                     }}>
                                        <label> Attempted: </label>
                                        <label style={{ fontWeight : 500 }} className="font-size-m-3">
                                            {sparklineData?.attemptedMetaData?.count}({sparklineData?.attemptedMetaData?.percentage}%)
                                        </label>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <Divider type="vertical" style={{
                            height: '100%',
                            position: 'absolute',
                            left: '35%'
                        }} />

                        <div className="flex-column flex-4 flex-gap-m ">

                            <div className="flex-box flex-gap-xl justify-content-space-between align-items-center spacer-m">
                                <div className="flex-box flex-gap-l flex-2 align-items-center">
                                    <Work style={{ width : 60, height : 60 }} className="spacer-hm border-grey border-radius-m" />
                                    <div className="flex-column flex-gap-s">
                                        <div className="light-grey-text font-size-m-3" style={{ fontWeight : 200 }}> First OFD </div>
                                        <div className="font-size-l-2 text-semi-bold"> {sparklineData?.fadData?.totalFirstAttemptCount} </div>
                                    </div>
                                </div>

                                <div className="flex-box flex-gap-l flex-1 justify-content-space-between align-items-center flex-wrap">
                                    <div className="flex-box flex-1 justify-content-center align-items-center flex-gap-m spacer-hm curved-end"
                                     style={{
                                        backgroundColor : "#E7FEF0"
                                     }}>
                                        <label> FAD : </label>
                                        <label style={{ fontWeight : 500 }} className="font-size-m-3">
                                            {sparklineData?.fadData?.totalFirstAttemptDeliveryCount}({parseInt(sparklineData?.fadData?.firstAttemptDeliveryPercentage)}%)
                                        </label>
                                    </div>
                                    <div className="flex-box flex-1 justify-content-center align-items-center flex-gap-m spacer-hm curved-end error-bg">
                                        <label> Unattempted: </label>
                                        <label style={{ fontWeight : 500 }} className="font-size-m-3">
                                            {sparklineData?.fadData?.unattemptedCount}({sparklineData?.fadData?.totalFirstAttemptCount ? parseInt(100 * sparklineData?.fadData?.unattemptedCount / sparklineData?.fadData?.totalFirstAttemptCount) : 0}%)
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <Divider type="vertical" style={{
                            height: '100%',
                            position: 'absolute',
                            left: '70%'
                        }} />

                        <div className="flex-box justify-content-center align-items-center spacer-l flex-3 ">
                            <div className="flex-box flex-1 justify-content-center align-items-center border-radius-xl error-bg spacer-m" style={{ position : 'relative' }}>

                                <div className="flex-box flex-gap-l align-items-center">
                                    <Icon type="thunderbolt" theme="filled" className="error-icon" style={{ fontSize : 38 }} />
                                    <div className="flex-column flex-gap-xs">
                                        <div className="font-size-l-1" style={{ fontWeight : 200 }}> Suspicious shipments </div>
                                        <div className="font-size-l-2 text-semi-bold"> {sparklineData.suspiciousShipmentCount} </div>
                                    </div>
                                </div>

                                {/* <Divider type="vertical" style={{
                                height : 64,
                                margin : 0,
                                width  : 2
                                }} /> */}


                                {/* <div className="flex-box flex-1 flex-gap-l align-items-center">
                                    <Icon type="thunderbolt" theme="filled" className="warning-icon" style={{ fontSize : 38 }} />
                                    <div className="flex-column flex-gap-xs">
                                        <div className="font-size-l-1" style={{ fontWeight : 200 }}> Critical non resolved </div>
                                        <div className="font-size-l-2 text-semi-bold"> {sparklineData.criticalShipmentCount} </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </>
                }

            </div>
        </div>
    )
}