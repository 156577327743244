import { apiRequest } from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';
export default class PodService {
    fetchAllThcs(data) {
      const url = "/b2b/v1/thc/fetch";
      return apiRequest({
          url,
          method: "POST",
          data
        });
    }

    getWaybillData(waybillId) {
        const url = `/b2b/v1/tracking/${waybillId}`;
        return apiRequest({
            url,
            method: "GET",
          });
      }

      getManifestData(manifestId) {
        const url = `/b2b/v1/manifest/${manifestId}`;
        return apiRequest({
            url,
            method: "GET",
          });
      }

      updateManifestPod(data) {
        const url = "/b2b/v1/manifestpod/update";
        return apiRequest({
            url,
            method: "POST",
            data
          });
      }

      getConsignmentData(consignmentId) {
        const url = `/b2b/v1/consignments/${consignmentId}`;
        return apiRequest({
            url,
            method: "GET",
          });
      }

      getRTODRSData(drsId) {
        const url = `/b2c/v1/rto/drs/${drsId}`;
        return apiRequest({
          url,
          method: "GET"
        })
      }

      updateConsignmentPod(data) {
        const url = "/b2b/v1/consignmentpod/update";
        return apiRequest({
            url,
            method: "POST",
            data
          });
      }

      uploadBookingPod(params) {
        const url = "/b2b/v1/uploads/pod";
        return apiRequest({
            url,
            method: "POST",
            data: {
              ...params,
              partnerId : params?.partnerId ? params?.partnerId : Constants.PARTNER_ID,
              locId     : params?.locId ? params?.locId : Constants.userLocationId
            }
          });
      }
    
      allManifestPod() {
        const url = "/b2b/v1/manifestpod/all";
        return apiRequest({
            url,
            method: "GET",
          });
      }
    
      allConsigmentPod() {
        const url = "/b2b/v1/consignmentpod/all";
        return apiRequest({
            url,
            method: "GET",
          });
      }
    
    
      getConsigmentPodByLimit(params) {
        const url = "/b2b/v1/consignmentpod/all";
        return apiRequest({
            url,
            method: "GET",
            params
          });
      }
    
      getConsignment(payload) {
        const url = "/b2b/v1/consignmentpod/fetch";
        return apiRequest({
            url,
            method: "POST",
            data: payload
          });
      }

      podCorrection(params) {
        const url = "/b2b/v1/consignmentpod/correction";
        return apiRequest({
            url,
            method: "POST",
            data: params
          });
      }

      validateDrs(payload) {
        const url = "/b2c/v1/drs/validate";
        return apiRequest({
          url,
          method: "POST",
          data: payload
        })
      }

      closeDrs(payload) {
        const url = "b2c/v1/drs/close";
        return apiRequest({
          url,
          method: "POST",
          data: payload
        });
      }
}

