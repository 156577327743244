import { useEffect, useState } from "react";
import MonitoringReportsService from "../../../services/monitoring-report/monitoring-report.service";
import { Constants } from "../../../common/utils/constants";

const ReversePickupReport = () => {

    const [ embedUrl, SetEmbedUrl ] = useState(false);

    useEffect(() => {

        const user = localStorage.user ? JSON.parse(atob(localStorage.user)) : {};

        (new MonitoringReportsService()).getEmbeddedURLByPayload({
            reportName: "rvp-dashboard",
            embedUrlGeneric: {
                resource : { dashboard : 202  },
                params   : { location_id  : Constants.userLocationId }
            }
        })
        .then(response => {
            SetEmbedUrl(response?.data?.response?.embedUrl);
        })

    }, [])

    return (
        <div className="white-bg" style={{ height : "calc(100vh - 150px)", marginBottom : 25 }}>
            <iframe height={"100%"} src={`${embedUrl}&refresh=120`} frameBorder={0} width="100%" />
        </div>
    )
}

export default ReversePickupReport;