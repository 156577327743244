import { Button, Icon, Modal, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import FormWrapper from "../../ui/formComponent/form";
import tripFormSchema from "./trip.form.schema";
import "./index.scss"
import UserService from "../../../services/settings/user.service";
import {
    fetchVendorOptions, fetchVendorsV2,
    fetchVehicleTypeEnumV2, fetchRoutesV2
} from "../../../services/common/fetch.services";
import { callCreateTrip, createTrip } from "./service";
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import RouteService from "../../../services/settings/route.service";
import { Constants } from "../../../common/utils/constants";
import { useSelector } from "react-redux";
import CreateVendor from "../vendor/create-vendor";

const userService = new UserService();
export default function CreateTrip(props) {

    const [formSchema, SetFormSchema] = useState(undefined);
    const [formData, SetFormData] = useState({});
    const [tripCreating, setTripCreating] = useState(undefined);
    const [ vendorCreateModelOpen, setVendorCreateModelOpen ] = useState(false);
    const config = useSelector(state => state?.app?.configurations);
    var formRef = useRef();

    const AddVendor = () => <div className="flex-box flex-gap-m align-items-center"><Icon type="plus"/> Add Vendor</div>
    const AddVendorClick = _ => setVendorCreateModelOpen(true);

    const onVendorSuccess = newVendor => {
        var _tripFormSchema = { ...tripFormSchema };
        SetFormSchema(undefined);
        _tripFormSchema.schema.properties.vendorId.enum.push(newVendor.id);
        _tripFormSchema.schema.properties.vendorId.enumNames.push(newVendor.name);
        setVendorCreateModelOpen(false);
        setTimeout(() => {
            SetFormSchema(_tripFormSchema);
        }, 100);
    }

    const onVendorCancel = _ => {
        setVendorCreateModelOpen(false);
    }

    const loadFormData = async () => {

        let response = []

        const vehicleTypeEnum = [], vehicleTypeEnumNames = [];
        const routesEnum = [], routeEnumNames = [];

        if (config?.enableRearch?.outboundTripWriteOps === 'true') {

            try {
                const currentPartnerId = Constants.CURRENT_USER_ID

                const responseList = await Promise.all([fetchVendorsV2(currentPartnerId), fetchVehicleTypeEnumV2(), fetchRoutesV2()])

                const enums = []
                const enumNames = []
                responseList[0].vendorData.map((vendorDetails) => {
                    enums.push(vendorDetails.id)
                    enumNames.push(vendorDetails.name)
                })
                response.push({ enums: enums, enumNames: enumNames })

                responseList[1].vehicleDetails.map((vehicleTypeDetails) => {
                    vehicleTypeEnum.push(vehicleTypeDetails.id)
                    vehicleTypeEnumNames.push(vehicleTypeDetails.vehicleType)
                })

                responseList[2].routes.map((routeDetails) => {
                    routesEnum.push(routeDetails.id)
                    routeEnumNames.push(routeDetails.name)
                })

            } catch (error) {
                console.error(error);
            }

        } else {
            const routesPayload = {
                filters: {
                    isActive: true,
                    fetchObjects: [],
                    sourceLocationIds: [Constants.userLocationId],
                    canAllowEmptyFetchObjects: true
                }
            };

            response = await Promise.all([fetchVendorOptions(), userService.getVehiclesTypes(), RouteService.getRouteFetch(routesPayload)])
        }
        const { enums: vendorEnums, enumNames: vendorEnumNames } = response[0];
        if (vendorEnums.length === 0) {
            notifyApiError("No vendor found for partner")
            return props.history.push("/appv2/trips/dashboard/outbound");
        }
        tripFormSchema.schema.properties.vendorId.enum = vendorEnums;
        tripFormSchema.schema.properties.vendorId.enumNames = vendorEnumNames;

        if (config?.enableRearch?.outboundTripWriteOps !== 'true') {
            response[1].data?.response?.vehicleTypes.map(vehicleType => {
                vehicleTypeEnum.push(vehicleType?.id);
                vehicleTypeEnumNames.push(vehicleType?.vehicleType);
            })

            response[2].data?.response?.routes.map(route => {
                routesEnum.push(route?.id);
                routeEnumNames.push(route?.name);
            })
        }

        tripFormSchema.schema.properties.vehicleType.enum = vehicleTypeEnum;
        tripFormSchema.schema.properties.vehicleType.enumNames = vehicleTypeEnumNames;

        tripFormSchema.schema.properties.routeId.enum = routesEnum;
        tripFormSchema.schema.properties.routeId.enumNames = routeEnumNames;
        SetFormSchema(tripFormSchema);
    }
    useEffect(_ => {
        loadFormData();
    }, []);

    const validate = (data, errorsObject) => {
        if (data.driverContactNumber && (data.driverContactNumber + "").length != 10) {
            errorsObject.driverContactNumber.addError("Drive Contact number should contain 10 digits!")
        }
        return errorsObject;
    }

    const onSubmit = data => {
        setTripCreating(true);

        if (config?.enableRearch?.outboundTripWriteOps === 'true') {

            const reqBody = {
                vendorId: data.vendorId,
                tripType: data.tripType,
                tripCategory: data.tripCategory,
                vehicle: {
                    typeId: data.vehicleType,
                    number: data.vehicleNumber
                },
                driver: {
                    name: data.driverName,
                    contactNumber: data.driverContactNumber,
                },
                routeId: data.routeId
            }
            createTrip(reqBody)
                .then(response => {
                    setTripCreating(false);
                    notifyApiSuccess("Trip created successfully", response?.tripCode);
                    props.history.push(`/appv2/trips/form/outbound/${response?.tripCode}`)
                })
                .catch(_ => setTripCreating(false))

        } else {

            callCreateTrip(data)
                .then(response => {
                    setTripCreating(false);
                    notifyApiSuccess("Trip created successfully", response?.code);
                    props.history.push(`/appv2/trips/form/outbound/${response?.code}`)
                })
                .catch(_ => setTripCreating(false))
        }
    }

    return (
        <div className="white-bg border-grey flex-column flex-gap-xl spacer-m create-trip-header">
            <div className="flex-box justify-content-space-between align-items-center">
                <div className="flex-box align-items-center flex-gap-m">
                    <Icon type="arrow-left" onClick={_ => props.history.push("/appv2/trips/dashboard/outbound")} />
                    <div className="font-size-l-1 text-bold"> Create Trip </div>
                </div>
                <div className="flex-box align-items-center flex-gap-m">
                    <Button loading={tripCreating} onClick={_ => props.history.push("/appv2/trips/dashboard/outbound")} className="lsn-btn-secondary">
                        Cancel
                    </Button>
                    <Button loading={tripCreating} onClick={_ => {
                        formRef.current.formReference.current.submit();
                    }} type="primary" className="lsn-btn-primary">
                        Add Load
                    </Button>
                </div>
            </div>

            <div className="spacer-m">
                {
                    formSchema ?
                    <FormWrapper {...formSchema} formData={formData} fieldsPerRow={2} hideSubmit={true}
                     onSubmit={onSubmit} ref={formRef} onChange={SetFormData} validate={validate} /> :
                    <Spin spinning={true} />
                }
            </div>
            {
                vendorCreateModelOpen &&  <CreateVendor onVendorSuccess={onVendorSuccess} onVendorCancel = {onVendorCancel}/>
            }
        </div>
    )
}