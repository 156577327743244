import { Button, Table, Icon, Tag } from "antd";
import React, { useState } from "react";
import { apiService } from "../../common/apiService/service";
import { notifyApiError, notifyApiSuccess, formatDate, formatDateoption, capitalizeString } from "../../common/utils/utils";
import { openNotification } from '../ui/notification/Notification';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { deleteUploadedPickupFileEntry } from "./actions";

export const UploadListTable = ({ tableData, handleRefreshList }) => {

    const columns = [
        {
            title: 'Sl No.',
            dataIndex: 'serialNumber',
            key: 1,
            render: (_, __, index) => index + 1,
        },
        {
            title: "File Name",
            dataIndex: 'uploadFileName',
            key: 2,
            width: "20%"
        },
        {
            title: "Uploaded By",
            dataIndex: 'uploadUserName',
            key: 3
        },
        {
            title: "Upload Time",
            dataIndex: 'uploadTimeEpoch',
            key: 4,
            render: (data, row) => (
                <div>{formatDate(data, formatDateoption())}</div>
            )
        },
        {
            title: "Upload Type",
            dataIndex: 'uploadType',
            key: 5,
            render: (data, row) => (
                <div>{capitalizeString(data)}</div>
            )
        },
        {
            title: "Progress",
            dataIndex: 'id',
            key: 6,
            width: "8%",
            render: (data, row) => {
                return (
                    <div>
                        {row.processedRowCount ? row.processedRowCount : 0}
                        /
                        {row.totalRowCount ? row.totalRowCount : 0}
                    </div>
                )
            }
        },
        {
            title: "Status",
            dataIndex: 'status',
            key: 7,
            render: (data, row) => {
                let tagClassName = "info-tag";
                switch (data) {
                    case "COMPLETED": tagClassName = "success-tag"; break;
                    case "PROCESSING": tagClassName = "info-tag"; break;
                    case "IN_QUEUE": tagClassName = "warning-tag"; break;
                    case "CANCELLED": tagClassName = "error-tag"; break;
                    case "FAILED": tagClassName = "error-tag"; break;
                    default: return "";
                }
                if (tagClassName) {
                    return (
                        <Tag className={"ant-table-tag text-align-center " + tagClassName}>
                            {
                                (data.split("_").map(s => capitalizeString(s.toLowerCase()))).join(" ")
                            }
                        </Tag>
                    )
                }
            }
        },
        {
            title: "Uploaded file",
            dataIndex: 'uploadFileLink',
            key: 8,
            render: (data, row) => {
                return (
                    <div>
                        <Button className="lsn-btn-primary" block={true} icon="download"
                            onClick={() => window.open(data, "_blank")}>
                            Download
                        </Button>
                    </div>
                )
            }
        },
        {
            title: "Response file",
            dataIndex: 'responseFileLink',
            key: 9,
            render: (data, row) => {
                return (
                    (row && row?.status == "COMPLETED" ? <div className="flex-box align-items-center justify-content-flex-end flex-gap-m">
                        <Button className="lsn-btn-primary" block={true} icon="download"
                            onClick={() => window.open(data, "_blank")}>
                            Download
                        </Button>
                    </div> : <div>N/A</div>
                    )
                )
            }
        },
        {
            title: "Action",
            dataIndex: 'id',
            key: 10,
            render: (data, row) => {
                return (
                    <div>
                        {row?.enableCancelButton &&
                            <Icon type="delete" onClick={_ => handleDeleteAction(data)} />
                        }
                    </div>
                )
            }
        }
    ]

    const handleDeleteAction = (uploadId) => {

        deleteUploadedPickupFileEntry(uploadId)
            .then(() => {
                notifyApiSuccess("Deleted successfully!")
                handleRefreshList()
            })
            .catch((errors) => {
                notifyApiError(errors.error)
                console.error("deleteUploadedPickupFileEntry api failed with following error -> ", errors)
            })

    }

    return (
        <>
            <div className="flex-box justify-content-end mb-16">
                <Button className="lsn-btn-secondary" icon="reload" onClick={() => handleRefreshList()}>
                    Refresh
                </Button>
            </div>
            <Table bordered={true}
                size="middle"
                pagination={{
                    position: 'bottom'
                }}
                rowKey={row => row.id}
                dataSource={tableData}
                columns={columns} />
        </>
    )

}