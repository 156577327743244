import { storePaginatedData } from "../../../common/utils/utils";
import { ACTION_TYPES, INITIAL_STATE } from "./constants";

export const rtoWaybillReducer = (state = INITIAL_STATE, action = {}) => {
    switch(action.type){
        case ACTION_TYPES.FETCH_CONSIGNMENTS_BEGIN:
        case ACTION_TYPES.FETCH_SPARK_LINE_DATA__BEGIN:
            return {
                ...state,
                loading: true
            };

        case ACTION_TYPES.FETCH_CONSIGNMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                PaginatedWaybill: storePaginatedData(action?.payload?.currentPageNo, state.PaginatedWaybill ? state.PaginatedWaybill : {}, action?.payload?.consignments || action?.payload?.viewResponse, action.isReset),
                ...action.payload
            };

        case ACTION_TYPES.FETCH_CONSIGNMENTS_FAILURE:
        case ACTION_TYPES.FETCH_SPARK_LINE_DATA__FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case ACTION_TYPES.FETCH_SPARK_LINE_DATA__SUCCESS:
            return {
                    ...state,
                    loading: false,
                    dataCardList: action.payload
                }
        case ACTION_TYPES.RESET_PAGINATED_CACHE_DATA:
            return {
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};