import React, { useEffect, useState } from "react";
import { Button, Icon, Input, Table, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { addItem } from "./utils";
import { capitalizeString } from "../../../common/utils/utils";
import { SoundNotificationService } from '../../../services/sound.service';

const soundService = new SoundNotificationService();

export default function PendingLists(props) {

    const pendingList = useSelector(state => state.scanListReducer[props.listId]?.pendingList);

    var { pendingDataIndex} = props
    const [pendingColumns, SetPendingColumns] = useState(props.pendingColumns);

    useEffect(_ => {
        var tempPendingColumns = pendingColumns.filter(c => c.title !== "Add")
        tempPendingColumns.push({
            title     : "Add",
            dataIndex : pendingDataIndex ? pendingDataIndex : props.listId + "_pending",
            render    : (data, rowData, index) => (
                <Icon type="plus" onClick={_ => {
                    if (typeof props.beforeAdd === "function") {
                        props.beforeAdd(rowData, index, props.listId)
                        .then(_ => {
                            addItemCallback(rowData, index);
                        })
                        .catch(exp => {
                            console.log(exp);
                        });
                    }
                    else {
                        addItemCallback(rowData, index);
                    }
                }} />
            )
        })
        SetPendingColumns(tempPendingColumns)
    }, [pendingList])

    const addItemCallback = (rowData, index) => {
        
        const onAddSuccess = _ => {
            soundService.playSuccess();
            if (typeof props.onAdd === "function") {
                props.onAdd(rowData, index, props.listId)
            }
        }
        if (props.skipAdd) {
            onAddSuccess();
        }
        else {
            addItem(rowData, index, props.listId)
            .then(_ => onAddSuccess(rowData, index));
        }
    }

    return (
        <div className="spacer-m white-bg border-grey flex-column flex-gap-l flex-1">
            <div className="flex-box justify-content-space-between align-items-center font-size-m-3 flex-gap-m"> 
                <div className="flex-box flex-gap-s flex-wrap flex-1 text-bold">
                    <div>
                        {
                            props.pendingListHeader ? props.pendingListHeader :
                            props.entityType ? "Pending " + capitalizeString(props.entityType) : 
                            "Pending Items"
                        }
                    </div>
                    {
                        typeof props.showCount === "boolean" ?
                        <div>( Count : { typeof props.pendingCount === "number" ? props.pendingCount : pendingList?.length} ) </div> :
                        typeof props.showCount === "object" ?
                        (props.showCount) :
                        <></>
                    }
                </div>
                {
                    props.viewPendingList && typeof props.onViewPendingList === "function" &&
                    <Tooltip title="View Pending List">
                        <Button className="lsn-btn-primary" icon="eye" onClick={props.onViewPendingList} />
                    </Tooltip>
                }
                {
                    props.enablePendingSearch && typeof props.onPendingSearch === "function" &&
                    <Input.Search
                     id={props.listId + "_pendingSearchBar"} allowClear={true}
                     placeholder={props.pendingSearchPlaceholder ? props.pendingSearchPlaceholder :
                                  props.entityType ? "Search Pending " + capitalizeString(props.entityType) : 
                                  "Search pending items" }
                     className="flex-1" onSearch={value => props.onPendingSearch(value?.trim())} />
                }
            </div>
            <Table rowKey={pendingDataIndex ? pendingDataIndex : props.listId + "_pending"} columns={pendingColumns} dataSource={pendingList}
             pagination={props.pendingPagination ? props.pendingPagination : false} {...props.pendingTableProps} />
        </div>
    )
}