import React, { useEffect, useRef } from 'react';
import { Modal } from 'antd';
import bwipjs from 'bwip-js';

const ScanActionRequiredPopupModal = ({ open, handleClose, qrText, message }) => {
  const canvasRef = useRef(null);
  const canvasRefApp = useRef(null);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        if (open && canvasRef.current && qrText.length > 0) {
          bwipjs.toCanvas(canvasRef.current, {
            bcid: 'qrcode', // Barcode type
            text: qrText,  // Text to encode
            scale: 3,      // 3x scaling factor
            includetext: false, // No human-readable text
        });

        bwipjs.toCanvas(canvasRefApp.current, {
          bcid: 'qrcode', // Barcode type
          text: "https://play.google.com/store/apps/details?id=net.loadshare.operations&pli=1",  // Text to encode
          scale: 1,      // 3x scaling factor
          includetext: false, // No human-readable text
        });
        }
      } catch (e) {
          console.error(e);
      }
    }, 100); 
    
  }, [open, qrText]);

  return (
    <Modal className="remove-padding" title={
        <div className="font-size-l-3 flex-box flex-gap-l align-items-center spacer-s">
          <div>Action Required</div> 
        </div>
      }
      onCancel={handleClose} visible={open}
      width="50vw" footer={false} centered={true}
      destroyOnClose={true} >
        <div className="flex-column flex-gap-s" style={{ margin  : 30 }}>
          
        <h2>Download the app</h2>

          <div className="flex-row  align-items-center " style={{maxWidth:'100%', marginBottom: 30}}>
                <div className='flex-basis-60 margin-label font-size-l-2' style={{maxWidth:'70%'}}>
                          <div style={{ wordWrap:'break-word'}}>
                              <ul>
                                <li>Scan QR code and install</li>
                              </ul>  
                          </div>
                </div>
                <div className='flex-basis-40' style={{maxWidth:'30%', display: "flex", justifyContent: "flex-end"}}>
                       <canvas ref={canvasRefApp} id="qrCanvasApp"></canvas>
                </div>    
          </div>
          
          <h2>Scan the QR code in the app</h2>
          <div className="flex-row  align-items-center " style={{maxWidth:'100%', marginBottom: 30}}>
                <div className='flex-basis-60 margin-label font-size-l-2' style={{maxWidth:'70%'}}>
                    <div style={{ wordWrap:'break-word'}}>
                                  <ul>
                                    <li>Click on scan icon</li>
                                    <li>Scan QR code</li>
                                    <li>Do required action</li>
                                  </ul>  
                              </div>
                    </div>
                <div className='flex-basis-40' style={{maxWidth:'30%', display: "flex", justifyContent: "flex-end"}}>
                       <canvas ref={canvasRef} id="qrCanvas"></canvas>
                </div>    
          </div>
        </div>
      
    </Modal>
  )
}

export default ScanActionRequiredPopupModal;