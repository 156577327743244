import { Form, Icon, Input } from "antd";
import React from "react";
import { capitalizeString } from "../../../common/utils/utils";
import { ENTITY_TYPES } from "./constants";

export default function ScanForm({ entityType, handleChange, code, handleScanCode, isMobile, history }) {

    return (
        <Form layout="horizontal" colon={false}
         onSubmit={e => {
            e.preventDefault();
            handleScanCode(isMobile);
         }} >
            <Form.Item 
                label={isMobile ? undefined : `${entityType == ENTITY_TYPES.connection.name ? "Connection ID" : entityType === ENTITY_TYPES.trip.name ? "Trip ID" : "Manifest ID"}`} 
                labelCol={{span: 4}} 
                wrapperCol={{span: 20}}
            >
                {
                    (entityType === ENTITY_TYPES.manifest.name && isMobile) ?
                    <div className="error-color text-semi-bold" style={{ lineHeight : 1.5 }}>
                        Kindly use the "Primary Scan" option from 
                        <a role="button" onClick={_ => history.push("/appv2/mweb/home")}> Home Page </a>
                        to unload Manifests.
                    </div> :
                    <Input.Search
                        placeholder={isMobile ? `Scan ${capitalizeString(entityType)} ID` :
                        entityType === ENTITY_TYPES.connection.name ? "Enter Connection ID to unlock it and receive manifests" :
                        entityType === ENTITY_TYPES.trip.name ? "Enter Trip ID to unload manifests" : "Enter Manifest ID to unlock it and receive shipments"}
                        onChange={handleChange}
                        value={code}
                        allowClear
                        autoFocus={true}
                        addonAfter={
                            <span 
                                style={{
                                    cursor: !code.length ? "no-drop" : "pointer",
                                    pointerEvents: !code.length ? "none" : "auto",
                                    display: "flex", 
                                    alignItems: "center",
                                    gap : 5
                                }}
                                onClick={handleScanCode}
                            >
                                <Icon type="barcode" /> Scan
                            </span>
                        }
                    />
                }
            </Form.Item>
        </Form>
    )
}