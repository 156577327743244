import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Constants, FlowTypes } from "../../../common/utils/constants";
import { notifyApiError } from "../../../common/utils/utils";
import CreateDRSComponent from "./create.drs.component";
import * as DrsService from "./service";
import { useSelector } from "react-redux";

export default function CreateDRS(props) {

    const navigate = useNavigate();

    const appConfig = useSelector(state => state?.app?.configurations)

    const [drsCode, SetDRSCode] = useState(`D-${Constants.userLocationId}-${moment().format("DDMMYYYYhhmmss")}${Math.floor(100 + Math.random() * 900)}`);
    const [drsDetails, SetDrsDetails] = useState({});
    const [isAllowedToAddWaybills, SetIsAllowedToAddWaybills] = useState(true);
    const [isAllowedToDelinkWaybills, setIsAllowedToDelinkWaybills] = useState(true);

    useEffect(() => {
        if (props?.match?.params?.drsCode) {
            SetDRSCode(props?.match?.params?.drsCode);
            callDRSDetails(props?.match?.params?.drsCode);
        }
    }, []);

    const callDRSDetails = code => {
        DrsService.fetchDRSDetails(code)
            .then(_drsDetails => {
                SetDrsDetails(_drsDetails);

                const disableActionForStatus = new Set(["COMPLETED", "CLOSED"]);
                const disableLinkForStatus = new Set(["DRAFT"]);

                const isSameDay = moment(_drsDetails?.createdAt).startOf("day").isSame(moment().startOf("day"), "day");
                const isStatusDisabled = disableActionForStatus.has(_drsDetails?.drsStatus);
                const isLinkDisabled = disableLinkForStatus.has(_drsDetails?.drsStatus);

                if (!isSameDay) {
                    SetIsAllowedToAddWaybills(false);
                    setIsAllowedToDelinkWaybills(false);
                    notifyApiError("Cannot edit DRS created earlier than today!");
                } else if (isStatusDisabled || isLinkDisabled) {
                    const msg = isLinkDisabled ? "Only delinking is allowed" : "Cannot be edited.";
                    setIsAllowedToDelinkWaybills(isLinkDisabled);
                    SetIsAllowedToAddWaybills(false);
                    notifyApiError(`DRS is in ${_drsDetails?.drsStatus.toLowerCase()} status. ${msg}`);
                }
            })
            .catch(response => {
                if (response.errorCode === "E102") {
                    notifyApiError("DRS not found!");
                    navigate("/appv2/inventory/dashboard/drs")
                }
            })
    }

    const createDRS = drsUser => {
        const postObject = {
            drsCode: drsCode,
            drsUserId: drsUser?.key
        };
        return DrsService.createDRS(postObject)
    }

    const generateDRSCode = _ => SetDRSCode((`D-${Constants.userLocationId}-${moment().format("DDMMYYYYhhmmss")}${Math.floor(100 + Math.random() * 900)}`));

    const fetchUnassignedCount = _ => DrsService.fetchUnassignedCount()

    const fetchUnassignedList = (pageNo = 1) => DrsService.fetchUnassignedList(pageNo, 5);

    const scanWaybill = waybill => DrsService.linkAWBtoDRS(drsDetails?.drsCode, waybill);

    const delinkWaybill = waybill => DrsService.delinkAWBfromDRS(drsDetails?.drsCode, waybill);

    return (
        <>
            <CreateDRSComponent drsDetails={drsDetails} isAllowedToAddWaybills={isAllowedToAddWaybills}
                drsCode={drsCode} flowType={FlowTypes.FORWARD} generateDRSCode={generateDRSCode} SetDRSCode={SetDRSCode}
                callDRSDetails={callDRSDetails} SetDrsDetails={SetDrsDetails} createDRS={createDRS}
                delinkWaybill={delinkWaybill} scanWaybill={scanWaybill} callUploadDrs={DrsService.uploadDrs}
                fetchUnassignedCount={fetchUnassignedCount} fetchUnassignedList={fetchUnassignedList}
                isAllowedToDelinkWaybills={isAllowedToDelinkWaybills} />
        </>
    )
}