import { Button, Input, Icon, Divider, Select } from "antd";
import React, { useRef, useState } from "react";
import { Constants } from "../../../../common/utils/constants";
import { notifyApiError, notifyApiSuccess } from "../../../../common/utils/utils";
import { SoundNotificationService } from "../../../../services/sound.service";
import { exceptionScanEntity } from "../services/post.service";
import { EXCEPTION_SCAN_TYPES } from "../state/constants";
import TamperedReasonSelect from "../../../common/tampered-reason-select";

const soundNotificationService = new SoundNotificationService();

export default function ExceptionsScan({ reloadListWithFilters }) {

    const inputRef = useRef();

    const [scanType, SetScanType] = useState("EXCEPTION");
    const [scanResponse, SetScanResponse] = useState(undefined);
    const [scanningInProgress, SetScanningInProgress] = useState(undefined);

    const _configrations = localStorage.getItem("_configrations") && JSON.parse(atob(localStorage.getItem("_configrations")));
    const enableWrongFacilityShipmentScan = _configrations && _configrations.enableWrongFacilityShipmentScan &&
        _configrations.enableWrongFacilityShipmentScan.indexOf(Constants.userLocationId + "") > -1

    const scanWaybill = _ => {
        const waybillInput = inputRef?.current?.state?.value?.trim();
        if (waybillInput === undefined || waybillInput === "") {
            return notifyApiError("Enter waybill number to scan!");
        }
        const scanPostObject = {
            request: [
                {
                    consignmentScan: {
                        scanType: "IN_SCAN",
                        waybillNo: waybillInput,
                        scanInputType: "BARCODE_SCANNER",
                        isBarcodeScan: false
                    },
                    type: "IN_SCAN",
                    referenceId: waybillInput,
                    isWaybill: true,
                    // isException : true,
                    exceptionScanType: scanType,
                    shipmentScans: [
                        {
                            scanType: "IN_SCAN",
                            shipmentCode: waybillInput,
                            scanInputType: "BARCODE_SCANNER",
                            shipmentCodeType: "BARCODE"
                        }
                    ]
                }
            ]
        };
        SetScanningInProgress(true);
        exceptionScanEntity(scanPostObject, EXCEPTION_SCAN_TYPES[scanType])
            .then(response => {
                inputRef.current.state.value = "";
                if (response?.responses && response?.responses[0]) {
                    let tempScanResponse = response?.responses[0];
                    if (tempScanResponse?.sync) {
                        if (tempScanResponse.scanResponse) {

                            soundNotificationService.playSuccess();
                            notifyApiSuccess(`${EXCEPTION_SCAN_TYPES[scanType]?.scanSuccessMsg} - ${waybillInput}`, "SUCCESS")
                            SetScanResponse(tempScanResponse);
                        }
                        else {
                            soundNotificationService.playWarning();
                            notifyApiError("Waybill does not exist!", "Scan failed");
                        }
                    }
                    else {
                        soundNotificationService.playWarning();
                        notifyApiError(tempScanResponse.reason ? tempScanResponse.reason : "Scan failed! Please contact support.");
                    }
                }
                SetScanningInProgress(false);
                reloadListWithFilters();
                document.getElementById("scanWaybillInput")?.focus();
            })
            .catch(_ => {
                SetScanningInProgress(false);
                SetScanResponse(undefined);
                document.getElementById("scanWaybillInput")?.focus();
            })
    }

    return (
        <div className="flex-column flex-gap-l">
            <div className="flex-box spacer-hm flex-gap-l">
                {
                    enableWrongFacilityShipmentScan &&
                    <div className="flex-column flex-1 flex-gap-m">
                        <div> Scan Type </div>
                        <Select className="stretch-select" defaultValue={"EXCEPTION"} onChange={SetScanType}>
                            {
                                Object.values(EXCEPTION_SCAN_TYPES).map(option => {
                                    return <Select.Option value={option.value}> {option.label} </Select.Option>
                                })
                            }
                        </Select>
                    </div>
                }
                <div className={`flex-column flex-${enableWrongFacilityShipmentScan ? 2 : 4} flex-gap-m`}>
                    <div>
                        {scanType && EXCEPTION_SCAN_TYPES[scanType] ? EXCEPTION_SCAN_TYPES[scanType].label : ""}
                    </div>
                    <Input disabled={scanningInProgress} placeholder={"Enter/Scan Waybill Number"}
                        onPressEnter={scanWaybill} ref={inputRef} id={"scanWaybillInput"} suffix={
                            <Button loading={scanningInProgress} onClick={scanWaybill} type="primary"
                                className="lsn-btn-primary input-suffix-button">
                                <Icon type="barcode" /> Scan
                            </Button>
                        } />
                </div>
            </div>
            {
                scanResponse &&
                <div className="flex-column flex-gap-l border-grey grey-bg spacer-vm" style={{ borderWidth: "1px 0px" }}>
                    <div className="font-size-m-3 spacer-hl text-bold">
                        Last Scanned Entity
                    </div>
                    <Divider style={{ margin: 0 }} />
                    <div className="flex-box justify-content-space-between">
                        <div className="flex-box flex-gap-l flex-5">
                            <div className="flex-1 flex-column flex-gap-s spacer-hl">
                                <div className="font-size-sm-2 light-grey-text"> Waybill Number </div>
                                <div className="text-bold"> {scanResponse?.consignmentScanBO?.waybillNo} </div>
                            </div>
                            {
                                scanResponse?.consignmentScanBO?.consignment && scanResponse?.consignmentScanBO?.consignment?.consignmentStatus &&
                                <div className="flex-1 flex-column flex-gap-s spacer-hl">
                                    <div className="font-size-sm-2 light-grey-text"> Current Consignment Status </div>
                                    <div className="text-bold">
                                        {scanResponse?.consignmentScanBO?.consignment?.consignmentStatus}
                                    </div>
                                </div>
                            }
                            {
                                scanResponse?.consignmentScanBO?.consignment?.consignmentStatus === "WRONG_FACILITY" ?
                                    <>{
                                        scanResponse?.consignmentScanBO?.consignment?.consignee?.address?.address1 &&
                                        <div className="flex-1 flex-column flex-gap-s spacer-hl">
                                            <div className="font-size-sm-2 light-grey-text">  Consignee Address </div>
                                            <div className="text-bold">
                                                {
                                                    scanResponse?.consignmentScanBO?.consignment?.consignee?.address?.address1 +
                                                    scanResponse?.consignmentScanBO?.consignment?.consignee?.address?.address2
                                                }
                                            </div>
                                        </div>
                                    }</> :
                                    <>{
                                        scanResponse?.consignmentScanBO?.consignment?.nextLocation &&
                                        <div className="flex-1 flex-column flex-gap-s spacer-hl">
                                            <div className="font-size-sm-2 light-grey-text">  Next Location </div>
                                            <div className="text-bold"> {scanResponse?.consignmentScanBO?.consignment?.nextLocation?.name} </div>
                                        </div>
                                    }</>
                            }
                        </div>
                        <div className='flex-1 spacer-hm'>
                            {
                                scanResponse && scanResponse?.consignmentScanBO?.consignment?.consignmentStatus != "WRONG_FACILITY" &&
                                <TamperedReasonSelect scanType={"EXCEPTION_INSCAN"}
                                    customerId={scanResponse?.consignmentScanBO?.customerId}
                                    waybillNo={scanResponse?.consignmentScanBO?.waybillNo} />
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}