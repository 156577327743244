import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const SortCenterVisibilityDashboard = () => {

    return (
        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">Sort Center - Visibility - Overview</div>

            <div className="flex-column flex-gap-xl">
                <div className="font-size-m-4 grey-text mt-20">Today's Summary</div>
                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC -Overview - Shipments Arriving"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1264, reportName: "sc-overview-shipments-arriving",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC - Overview - Bags Arriving"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1265, reportName: "sc-overview-bags-arriving",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC - Overview - Total Scans"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1266, reportName: "sc-overview-total-scans",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>
                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC - Overview - Shipments Scanned and not Bagged"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1267, reportName: "sc-overview-shipments-scanned-and-not-bagged",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC - Overview - Shipments Bagged and not Shipped"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1268, reportName: "sc-overview-shipments-bagged-and-not-shipped",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>
            </div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-m-4 grey-text mt-20">Performance Summary</div>
                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC - Overview - Average Scan Per Hour"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1271, reportName: "sc-overview-average-scan-per-hour",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC - Overview - Average Bagging Per Hour"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1272, reportName: "sc-overview-average-bagging-per-hour",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC - Overview - Average Bags Connected"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1273, reportName: "sc-overview-average-bags-connected",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>
                <div>
                    <MetabaseWidget text={"SC - Overview - Action - Time Bucket"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1270, reportName: "sc-overview-action-time-bucket",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                </div>
            </div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-m-4 grey-text mt-20">User Level Analysis</div>
                <div>
                    <MetabaseWidget text={"SC - Overview - User Level Performance"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1269, reportName: "sc-overview-user-level-performance",
                            lockedParams: ["location_id"], height: "600px"
                        }} />
                </div>
            </div>

        </div>
    )

}

export default SortCenterVisibilityDashboard;