import { ACTION_TYPES, INITIAL_STATE } from "./constants";

export const labelPrintingReducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_SELECTED_ENTITY_TYPE:
            return {
                ...INITIAL_STATE,
                entityLabelTypes: state.entityLabelTypes,
                selectedEntity: action.payload,
            };
        case ACTION_TYPES.FETCH_LABEL_PRINTING_DATA_BEGIN:
        case ACTION_TYPES.PRINT_SINGLE_AWB_STICKER_BEGIN:
        case ACTION_TYPES.FETCH_LABEL_PRINTING_TYPES_BEGIN:
        case ACTION_TYPES.UPLOAD_PRINTING_BEGIN:
        case ACTION_TYPES.PRINT_BULK_AWB_STICKER_BEGIN:
            return {
                ...state,
                loading: true,
                bulkPrintingLoading: true,
                bulkPrinting: undefined
            };
        case ACTION_TYPES.FETCH_LABEL_PRINTING_TYPES_FAILURE:
        case ACTION_TYPES.UPLOAD_PRINTING_FAILURE:
        case ACTION_TYPES.PRINT_BULK_AWB_STICKER_FAILURE:
        case ACTION_TYPES.PRINT_SINGLE_AWB_STICKER_FAILURE:
            return {
                ...state,
                loading: false,
                bulkPrintingLoading: false,
                error: action.payload
            };
        case ACTION_TYPES.FETCH_LABEL_PRINTING_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                scanStatus: {
                    ...state.scanStatus,
                    error: true,
                    message: action.payload
                }
            };
        case ACTION_TYPES.CLEAR_FETCH_PRINT_DATA_ALERT:
            return {
                ...state,
                scanStatus: {
                    ...state.scanStatus,
                    error: false,
                    message: ""
                }
            };
        case ACTION_TYPES.FETCH_LABEL_PRINTING_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                entityLabelTypes: action.payload
            };
        case ACTION_TYPES.FETCH_LABEL_PRINTING_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                currentScannedEntityInfo: action.payload,
                scanStatus: {
                    ...state.scanStatus,
                    success: true,
                    message: "Scan Successful"
                },
                scannedEntityHistory: [
                    { ...action.payload },
                    ...state.scannedEntityHistory
                ]
            };
        case ACTION_TYPES.PRINT_BULK_AWB_STICKER_SUCCESS:
            return {
                ...state,
                loading: false,
                labelPrintingPDFUrl: action.payload,
                excelUploadResponseUrl: action.payload,
            };
        case ACTION_TYPES.PRINT_SINGLE_AWB_STICKER_SUCCESS:
            return {
                ...state,
                loading: false,
                labelPrintingPDFUrl: action.payload.labelPrintingPDFUrl,
                currentScannedEntityInfo: action.payload,
                scanStatus: {
                    ...state.scanStatus,
                    success: true,
                    message: "Scan Successful"
                },
                scannedEntityHistory: [
                    { ...action.payload },
                    ...state.scannedEntityHistory
                ]

            };
        case ACTION_TYPES.UPDATE_RESCAN_MODAL_STATE:
            return {
                ...state,
                loading: false,
                rescanModalState: {
                    ...action.payload
                }
            };
        case ACTION_TYPES.CLEAR_RESCAN_MODAL_STATE:
            return {
                ...state,
                rescanModalState: {
                    ...INITIAL_STATE.rescanModalState
                }
            }
        case ACTION_TYPES.UPDATE_AUTO_PRINT_FLAG:
            return {
                ...state,
                isAutoPrintEnabled: action.payload
            };
        case ACTION_TYPES.UPLOAD_PRINTING_SUCCESS:
            return {
                ...state,
                bulkPrintingLoading: false,
                bulkPrinting: action.payload
            }
        case ACTION_TYPES.CLEAR_CURRENT_SCANNED_ENTITY: {
            return {
                ...state,
                currentScannedEntityInfo: null
            }
        }
        case ACTION_TYPES.TOGGLE_LOADER:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}