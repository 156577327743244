import React from "react";
import Barcode from "../../../../ui/barcode";
import { originDetails, orderDetails, RowDetails } from "../trip.sheet";

export default function TripDetails ({ trip }) {

    return (
        <div style={{
            width     : "100%",
            padding   : "0 10%",
            textAlign : "center",
            boxSizing : "border-box",
            marginBottom : 25
        }}>

            <table className="black-bordered" width={"100%"} style={{marginBottom : 20}}>
                <tbody>
                    <tr className="text-align-center">
                        <th className="spacer-s"> Challan/Trip ID </th>
                    </tr>
                    <tr className="text-align-center">
                        <th>
                            <div className="spacer-vxs flex-column justify-content-center align-items-center">
                                <Barcode
                                width={2} format="CODE128"
                                value={trip?.tripCode} 
                                showText={false}
                                barCodeId={"tripId-barcode"}
                                />
                                <div className="font-semi-bold font-size-l-1" style={{ color : "#000", fontWeight : "400", letterSpacing: 1}}>
                                    {trip?.tripCode}
                                </div>
                            </div>
                        </th>
                    </tr>
                </tbody>
            </table>

            <table className="black-bordered" width={"100%"}>
                <tbody>
                    <tr className="text-align-center">
                        <th className="spacer-s" colSpan={4} > Origin Details </th>
                    </tr>
                    {
                        originDetails.map(d => {
                            return RowDetails(d?.name, trip?.originDetails?.[d?.value], d?.dataType);
                        })
                    }

                    <tr className="text-align-center">
                        <th className="spacer-s" colSpan={4} > Order Details </th>
                    </tr>
                    {
                        orderDetails.map(d => {
                            return RowDetails(d?.name, trip?.orderDetails?.[d?.value]);
                        })
                    }
                </tbody>
            </table>

        </div>
    )
}