import React, { useEffect, useState } from 'react';
import "./CustomArrayFieldTemplate.scss";
import { Button, Popconfirm } from 'antd';
import {
    MinusOutline
} from '@ant-design/icons';


const CustomArrayFieldTemplate = (props) => {
    const {
        canAdd,
        items,
        onAddClick,
        TitleField,
        DescriptionField,
        TitleFieldGrid,
        DescriptionFieldGrid,
        className,
        idSchema,
        TitleFieldProps,
        uiSchema,
        schema,
        onDropIndexClick, // Add the onDropIndexClick handler
    } = props;

    const [displayItems, setDisplayItems] = useState(true);
    const [displayBtnText, setDisplayBtnText] = useState("Show ");

    useEffect(() => {

        if (uiSchema["ui:options"]?.displayToggleBtn) {
            setDisplayItems(false)
        }

    }, [])

    const handleRemoveAction = (element, index) => {
        const dropFunc = element.onDropIndexClick(index)
        dropFunc()
    }

    const handleItemsDisplay = () => {
        setDisplayBtnText((prev) => (prev == "Show " ? "Hide " : "Show "))
        setDisplayItems(!displayItems)
    }

    return (
        <div className={className}>
            <div className="row">
                {TitleField && (
                    <div className={TitleFieldGrid}>
                        <TitleField
                            id={`${idSchema.$id}__title`}
                            title={schema.title}
                            {...TitleFieldProps}
                        />
                    </div>
                )}
                {DescriptionField && (
                    <div className={DescriptionFieldGrid}>
                        <DescriptionField
                            id={`${idSchema.$id}__description`}
                            description={schema.description}
                            {...props}
                        />
                    </div>
                )}
            </div>
            <ul className="list-group">
                {displayItems && items && items.map((element, index) => {
                    return <li key={index} className={"list-group-item " +
                        (element.className ? element.className : "")}  >
                        {element.children}
                        {element.hasRemove &&
                            <Popconfirm
                                title="Are you sure to remove this item?"
                                onConfirm={() => handleRemoveAction(element, index)}
                                onCancel={() => console.log("Cancelled")}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    className='remove-btn'
                                    shape="round"
                                >
                                    -
                                </Button>
                            </Popconfirm>
                        }
                    </li>
                })}
            </ul>
            {canAdd && (
                <button
                    ant-click-animating-without-extra-node={false}
                    type='primary'
                    className="add-item-btn"
                    onClick={onAddClick}
                >
                </button>
            )}
            {uiSchema["ui:options"]?.displayToggleBtn && items?.length > 0 &&
                <Button type="primary" onClick={handleItemsDisplay}>
                    {displayBtnText + (schema.title ? schema.title.toLowerCase() : "")}
                </Button>
            }
        </div>
    );
};

export default CustomArrayFieldTemplate;
