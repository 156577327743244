import store from "../../../utils/store";
import { addArrayElem, removeArrayElem } from '../../../common/utils/utils';
import { ACTION_TYPES } from "./controller";
import React from "react";
import { notifyApiSuccess } from "../../../common/utils/utils";
import { Icon } from "antd";

const dispatch = store.dispatch;

export const updateScanLists = (listId, tempPendingList, tempScannedList) => {
    dispatch({
        type        : ACTION_TYPES.UPDATE_SCAN_LISTS,
        listId      : listId,
        pendingList : tempPendingList,
        scannedList : tempScannedList
    })
}


export const updatePendingList = (listId, pendingList) => {
    dispatch({
        type    : ACTION_TYPES.UPDATE_PENDING_SCAN_LIST,
        listId  : listId,
        payload : pendingList
    })
}


export const updateScannedList = (listId, scannedList) => {
    dispatch({
        type    : ACTION_TYPES.UPDATE_SCANNED_SCAN_LIST,
        listId  : listId,
        payload : scannedList
    })
}

export const addItem = (data, index, listId) => new Promise(resolve => {
    const scanListState = store.getState().scanListReducer;
    if (!scanListState[listId] || data === undefined || data === null) return;
    const { pendingList, scannedList } = scanListState[listId];
    var tempScannedList = [...scannedList];
    var tempPendingList = [...pendingList];
    tempScannedList = addArrayElem(tempScannedList, data, tempScannedList.length);
    if (index !== undefined || index === null) {
        tempPendingList = removeArrayElem(tempPendingList, index);
    }
    updateScanLists(listId, tempPendingList, tempScannedList);
    resolve({ listId, tempPendingList, tempScannedList })
})

export const removeItem = (data, index, listId) => new Promise(resolve =>  {
    const scanListState = store.getState().scanListReducer;
    if (!scanListState[listId] || data === undefined || data === null) return;
    const { pendingList, scannedList } = scanListState[listId];
    var tempPendingList = [...pendingList];
    var tempScannedList = [...scannedList];
    tempPendingList = addArrayElem(tempPendingList, data, tempPendingList.length)
    tempScannedList = removeArrayElem(tempScannedList, index)
    updateScanLists(listId, tempPendingList, tempScannedList);
    resolve(listId, tempPendingList, tempScannedList);
})

export const fetchPaginationObject = (data, onChange, entity) => {
    return {
        total     : data?.totalCount,
        pageSize  : data?.currentPageSize,
        current   : data?.currentPageNo,
        showSizeChanger : false,
        onChange  : onChange,
        showTotal : entity ? total => ( <span className="total-pagination"> {total} {entity}(s) </span> ) : undefined
    }
}

export const notifyScanSuccess = entity => {
    notifyApiSuccess("", 
    <div className="flex-box flex-gap-m align-items-center"> 
        <Icon type="check-circle" theme="twoTone" twoToneColor="#01a870" style={{ fontSize: 20 }} />
        <div> Successfully scanned {entity}! </div>
    </div>);
}
export const notifyRemoveSuccess = entity => {
    notifyApiSuccess("", 
    <div className="flex-box flex-gap-m align-items-center"> 
        <Icon type="check-circle" theme="twoTone" twoToneColor="#01a870" style={{ fontSize: 20 }} />
        <div> Successfully unlinked {entity}! </div>
    </div>);
}