import { Button, Input, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Constants } from "../../../common/utils/constants";
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import { SoundNotificationService } from "../../../services/sound.service";
import { summaryScanedItems } from "./common/columns";
import { fetchUsers, scanAwb, fetchFeList } from "./common/services";
import { DRSSummary } from "./summary";
import { useSelector } from "react-redux";

const soundNotificationService = new SoundNotificationService();

function DRSBasic(props) {
    const [users, setUsers] = useState([]);
    const { userId, setUserId, scanned, drsId, setScanner, setCloseDrs, setuserInfo, drsSummary, drsStatus } = props;
    const [scannedItem, setScannedItem] = useState(undefined);
    const [scanSummary, setScanSummary] = useState({});
    const nameInput = useRef(null);

    const config = useSelector((state) => state?.app?.configurations)

    useEffect(() => {
        if (true) {
            fetchFeList()
                .then(res => {
                    setUsers(res?.userDetails);
                })
                .catch(e => console.error("fetchFeList failed with following error -> " + e))
        } else {
            const fetchData = async () => {
                try {
                    const params = {
                        partnerId: Constants.PARTNER_ID,
                        locationId: Constants.userLocationId
                    }
                    const response = await fetchUsers(params);
                    const data = await response;

                    setUsers(data);
                } catch (error) {
                    notifyApiError(error, "Error")
                }
            };
            fetchData();
        }
    }, []);

    const scannAwb = async () => {
        try {
            const params = {
                drsId: drsId,
                waybill: scannedItem.trim()
            }
            const response = await scanAwb(params);
            const data = await response;
            if (data) {
                setScanner(true);
                const summary = {
                    waybillNo: data.waybillNo,
                    seller: data.shipper.name,
                    isPodDone: data.isPodDone ? "Submitted" : "Not Submitted",
                    consignmentStatus: data.consignmentStatus
                }
                setScanSummary(summary);
                setScannedItem(undefined);
                nameInput.current.focus();
                soundNotificationService.playSuccess();
                notifyApiSuccess("Scan successful", "Success");
            } else {
                setScannedItem(undefined);
                soundNotificationService.playWarning();
                nameInput.current.focus();

            }
        } catch (error) {
            soundNotificationService.playWarning();
            notifyApiError(error, "Error")
        }
    }
    return (
        <div className="flex-column drs-basic">
            <div className="white-bg flex-column flex-gap-l spacer-m border-radius-s">
                <div className="title-header">
                    <h3 className="icon-size-xl"> Close DRS </h3>
                </div>

                <div className="flex-box flex-column flex-gap-xl">

                    <div className="flex-6 flex-box align-items-center justify-content-between flex-gap-m">
                        <div className="flex-3 flex-box align-items-center flex-gap-m">

                            <span className="drs-fields-label">Field Executive</span>
                            <Select placeholder="Select Field Executive"
                                showSearch={true} value={userId}
                                onChange={value => {
                                    setUserId(value);
                                    setScanner(false);
                                    setScanSummary({});
                                    const summary = users.filter(user => user.id === value);
                                    setuserInfo(summary[0]);
                                }}
                                style={{ width: 200 }}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {
                                    users &&
                                    users.map(user => (
                                        <Select.Option value={user.id} key={user.id}>
                                            {user.name}
                                        </Select.Option>
                                    ))
                                }

                            </Select>

                        </div>


                        {
                            userId &&
                            <>
                                <div className="flex-6">
                                    <Input
                                        placeholder="Enter or scan waybills to add items"
                                        value={scannedItem}
                                        disabled={!drsId}
                                        ref={nameInput}
                                        onChange={e => setScannedItem(e.target.value)}
                                        onPressEnter={scannAwb}
                                    />
                                </div>
                                <div className="flex-1">

                                    <Button onClick={() => setCloseDrs(true)} className="lsn-btn-primary"
                                        disabled={true ? !(drsStatus != "CLOSED")
                                            : !drsId}
                                    >
                                        Close DRS
                                    </Button>

                                </div>
                            </>
                        }

                    </div>

                </div>

            </div>
            {
                scanned &&
                <div className="drs-basic-scanned">
                    <DRSSummary title="Scanned Item" columns={summaryScanedItems()} dataSource={scanSummary} />
                </div>
            }
        </div>

    )
}

export default DRSBasic;