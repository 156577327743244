import { apiRequest } from "../../../../common/utils/apiGateway";
import { checkHttpStatus } from "../../../../common/utils/utils";

export const exceptionScanEntity = (scanPost, scanType = {}) => new Promise((resolve, reject) => {
    var postURL = "/b2b/v1/consignments/exception/scan";
    if (scanType.apiURL) {
        postURL = scanType.apiURL;
    }
    apiRequest({
        url     : postURL,
        method  : "POST",
        data    : scanPost
    })
    .then(response => {
        if (!checkHttpStatus(response?.data?.status)) {
            reject(response?.data?.status);
            return;
        }
        resolve(response?.data?.response);
    })
    .catch(error => reject(error))
})

export const manifestExceptionScan = manifestCode => new Promise((resolve, reject) => {
    var postURL = "/b2c/v1/manifest/scan";
    apiRequest({
        url     : postURL,
        method  : "POST",
        data    :   { 
            scanInputType : "BARCODE_SCANNER",
            manifestCode 
        }
    })
    .then(response => {
        if (!checkHttpStatus(response?.data?.status)) {
            reject(response?.data?.status);
            return;
        }
        resolve(response?.data?.response);
    })
    .catch(error => reject(error))
})