import React, { useEffect, useState } from "react";
import {
    Alert,
    Spin
} from "antd";
import { connect } from "react-redux";
import PODLiveSparkLine from "./pod-live.sparkline";
import PODLiveTable from "./pod-live.table";
import { getConsignmentsData, getSparkLineDataFunction } from "./action";
import { fetchCodSparklineDetails } from "../../codv2/services/fetch.service";
import moment from "moment";
import { userType } from "../../codv2/utils";
import { canAllow, formatCurrency } from "../../../common/utils/utils";
import { permissionTags } from "../../../configs/permissionTags";
import * as PodLiveService from "./service/index";
import activeAwbIcon0 from "../../../assets/img/icons/images/3_1_Inventory.svg";
import activeAwbIcon1 from "../../../assets/img/icons/images/3_2_Inventory.svg";


const PODLive = ({
    loading,
    consignments,
    getConsignmentsFunction,
    getSparkLineDataFunction,
    dataCardList,
    PaginatedPODLive,
    stateData,
    history,
    config
}) => {

    const [filterObject, setFilterObject] = useState({
        filters: {
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
        },
        pageNo: 1,
    });

    const [pendingRemittance, SetPendingRemittance] = useState(0);
    const [paginatedPODLiveValue, setPaginatedPODLivValue] = useState({});
    const [stateDataValue, setStateDataValue] = useState({});

    useEffect(() => {
        applyPagination({ pageNo: 1 }, true)
        // getSparkLineDataFunction();
        if (["BRANCH", "PARTNER_ADMIN"].indexOf(userType()) > -1) {
            fetchCodSparklineDetails(undefined, moment().valueOf())
                .then(sparklineSummary => {
                    if (sparklineSummary && sparklineSummary.netOutstandingAmount) {
                        SetPendingRemittance(sparklineSummary.netOutstandingAmount);
                    }
                })
        }
    }, []);

    useEffect(() => {
            setPaginatedPODLivValue(PaginatedPODLive)
            setStateDataValue(stateData)
    }, [PaginatedPODLive, stateData])

    const applyPagination = (filters, isReset = false) => {

        var filter = {
            ...filterObject.filters,
            ...filters,
            totalCount: consignments?.totalCount || 0
        };
        if (isReset) {
            filter = {
                pageNo: 1,
                pageSize: 10,
                totalCount: consignments?.totalCount || 0
            };
        }

        // Determining api based on rearch config.
            // Removing createdAt key when date contents are null or empty string
            if (filter?.createdAt && (!Boolean(filter.createdAt?.from) || !Boolean(filter.createdAt?.to))) {
                delete filter.createdAt
            }

            PodLiveService.fetchPODList({ filters: filter })
                .then((data) => {

                    const paginatedPodData = {
                        [data.pageNo]: data?.deliveryRunSheets && Array.isArray(data.deliveryRunSheets) ?
                            data.deliveryRunSheets : []
                    }
                    setPaginatedPODLivValue(paginatedPodData)

                    const stateData = {
                        totalCount: data?.totalCount,
                        pageSize: data?.pageSize
                    }
                    setStateDataValue(stateData)

                }).catch((error) => {
                    console.error("Error while triggering fetchPODList", error);
                });

        setFilterObject({
            filters: filter,
            pageNo: filter.pageNo
        });
    }

    const getPaginatedData = (pageNo) => {
        applyPagination({ pageNo: pageNo }, false);

        setFilterObject({
            ...filterObject,
            pageNo
        });
    };

    const handleRefresh = (pageNo) => {
        if (pageNo) {
            applyPagination({ pageNo: pageNo }, false);
            setFilterObject({
                ...filterObject,
                pageNo
            });
        } else {
            applyPagination({ pageNo: 1 }, true)
        }
    }

    return (
        <Spin spinning={loading}>
            {
                canAllow(permissionTags.cod_new.module) && pendingRemittance > 0 &&
                <Alert type="warning" className="cod-banner"
                    message={(
                        <div onClick={_ => history.push("/appv2/cod/dashboard/cod")} style={{
                            color: "#285aff",
                            fontWeight: "bold",
                            cursor: "pointer"
                        }}>
                            <label style={{ color: "#fa372b", fontSize: 15 }}>
                                {formatCurrency(pendingRemittance)}
                            </label>  COD is pending for payment. Pay and upload receipts in COD section now!
                        </div>
                    )} />
            }
            <div className="flex-box flex-column flex-gap-xl">
                <PODLiveSparkLine data={[
                    {
                        title: "Open DRS",
                        documentType: "OPEN_DRS",
                        embedDashboard : "inventory-drs",
                        icon: activeAwbIcon0
                    },
                    {
                        title: "Pending AWB",
                        documentType: "PENDING_CONSIGNMENT_LOC",
                        embedDashboard : "inventory-waybill",
                        icon: activeAwbIcon1
                    }
                ]} handleRefresh={handleRefresh}
                    createPOD={_ => history.push("/appv2/pod/form/drs/pod/create")} />
                <div>
                    <PODLiveTable
                        data={stateDataValue}
                        getPaginatedData={getPaginatedData}
                        pageNo={filterObject.pageNo}
                        PaginatedPODLive={paginatedPODLiveValue}
                        applyPagination={applyPagination}
                        history={history}
                        configPodLive={true}
                    />
                </div>
            </div>
        </Spin>
    );
};

const mapStateToProps = (state) => ({
    loading: state.podLive.loading,
    consignments: state.podLive.consignments,
    paginationData: state.podLive.paginationData,
    dataCardList: state.podLive.dataCardList,
    stateValue: state.podLive,
    PaginatedPODLive: state.podLive.PaginatedPODLive,
    stateData: state.podLive,
    config: state.app.configurations
});

const mapDispatchToProps = dispatch => ({
    getConsignmentsFunction: (payload, isReset) =>
        dispatch(getConsignmentsData(payload, isReset)),
    getSparkLineDataFunction: () => dispatch(getSparkLineDataFunction())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PODLive);