import React from 'react'
import moment from 'moment'
import WaybillLink from '../../../shared-components/waybill-link'
export const formatDate = date => {
    return (
        <>
            <div className="text-bold flex-box align-items-center flex-gap-m">
                <div>
                    {moment(Number(date)).format('DD/MMM/YYYY')}
                </div>
                <div className="bullet"></div>
                <div>{moment(Number(date)).format('HH:mm')}</div>
            </div>
            <div className="date-from">
                {moment(Number(date)).fromNow()}
            </div>
        </>
    )

}
export function summaryCurrentDRS() {
    return [
        {
            "label": "Sellers",
            "key": "totalSellers",
            "isVisible": true
        },
        {
            "label": "Total Waybills",
            "key": "totalWaybills",
            "isVisible": true
        },
        {
            "label": "Total Weight",
            "key": "totalWeight",
            "suffix": "Kg",
            "isVisible": true
        },
        {
            "label": "Total PIN Code",
            "key": "totalPincode",
            "isVisible": true
        }
    ]
}

export function summaryDRSStatus() {
    return [
        {
            "label": "Total AWB",
            "key": "totalWaybills",
            "isVisible": true
        },
        {
            "label": "Unattempted",
            "key": "totalDockets",
            "isVisible": false
        },
        {
            "label": "Undelivered",
            "key": "undeliveredCount",
            "isVisible": true
        },
        {
            "label": "Delivered",
            "key": "deliveredCount",
            "isVisible": true
        }
    ]
}

export function drsSummaryColumns() {
    return [
        {
            "title": "Seller",
            "key": "sellerName",
            "dataIndex": "sellerName"
        },
        {
            "title": "No of Items",
            "key": "totalWaybills",
            "dataIndex": "totalWaybills"
        },
        {
            "title": "Updated At",
            "key": "lastUpdatedAt",
            "dataIndex": "lastUpdatedAt",
            render: (data) => (formatDate(data))
        },
        {
            "title": <div className="flex-column flex-gap-s">
                <div>
                    Status Info
                </div>
                <div>
                    <span className="drs-table-del">DEL</span> /
                    <span className="drs-table-undel">UNDEL</span>
                    {/* <span>NA</span> */}
                </div>

            </div>,

            "key": "status",
            "dataIndex": "status",
            render: (data, row) => (
                <div className="flex-column flex-gap-s">
                    <div>
                        <span className="drs-table-del">{row.deliveredCount}</span> /
                        <span className="drs-table-undel">{row.undeliveredCount}</span>
                        {/* <span>NA</span> */}
                    </div>

                </div>
            )
        }
    ]
}

export function drsSummaryExpandedColumns() {
    return [
        {
            "title": "AWB No",
            "key": "waybillNo",
            "dataIndex": "waybillNo",
            render: data => {
                const waybill = data;
                return <WaybillLink>{waybill}</WaybillLink>
            }
        },
        // {
        //     "title": "Shipment",
        //     "key": "totalShipmentCount",
        //     "dataIndex": "totalShipmentCount"
        // },
        {
            "title": "Created On",
            "key": "createdAt",
            "dataIndex": "createdAt",
            render: data => formatDate(data)
        },
        {
            "title": "Status",
            "key": "consignmentStatus",
            "dataIndex": "consignmentStatus",
            render: (row, data) => {
                const consignmentStatus = data?.consignmentStatus ? data.consignmentStatus : data?.status
                return <div>{consignmentStatus}</div>
            }
        }
    ]
}

export function summaryScanedItems() {
    return [
        {
            "label": "Awb No",
            "key": "waybillNo",
            "isVisible": true
        },
        {
            "label": "Sellers",
            "key": "seller",
            "isVisible": true
        },
        {
            "label": "POD",
            "key": "isPodDone",
            "isVisible": true
        },
        {
            "label": "Status",
            "key": "consignmentStatus",
            "isVisible": true
        }
    ]
}