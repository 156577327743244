import { apiRequest, thirdPartyGet } from "../../common/utils/apiGateway";
import { DownloadTags } from "../../common/utils/constants";
import { checkHttpStatus } from "../../common/utils/utils";
import { apiService } from "../../common/apiService/service";

export const createAssignedPickup = (payload) => new Promise((resolve, reject) => {
    apiRequest({
        url: "/b2b/v1/leads/assignLeadNew",
        method: "POST",
        data: payload,
        ignoreStatus: true,
        showMessage: false
    })
        .then(response => {
            if (checkHttpStatus(response?.data?.status, false)) {
                if (response?.data?.response?.url) {
                    startPollingForResponseFile(response?.data?.response?.url)
                        .then(_ => resolve(response?.data?.response))
                }
                else {
                    resolve(response?.data?.response)
                }
            }
            else {
                reject(response?.data?.status)
            }
        })
        .catch(err => reject(err))
})


const startPollingForResponseFile = url => new Promise(resolve => {
    var pollingForResponse = setInterval(_ => {
        thirdPartyGet(url, DownloadTags.type)
            .then(response => {
                window.open(url, "blank");
                clearInterval(pollingForResponse);
                setTimeout(() => {
                    resolve(response);
                }, 250);
            })
            .catch(err => {
                console.log(err);
            })
    }, 1500)
})

export const fetchPickupUploadList = () => {
    return apiService.get("fm/pickup/upload/list")
}

export const createPickup = (reqBody) => {
    return apiService.post("fm/pickup/upload", reqBody)
}

export const deleteUploadedPickupFileEntry = (uploadId) => {
    return apiService.delete(`fm/pickup/upload/${uploadId}`)
}