import { Spin } from "antd";
import { useEffect, useState } from "react"
import { Constants } from "../../../common/utils/constants";
import MonitoringReportsService from "../../../services/monitoring-report/monitoring-report.service"
import { useSelector } from "react-redux";

const monitoringReportsService = new MonitoringReportsService();

export default function LMHealthDashboard() {

    const [ embedUrl, SetEmberUrl ] = useState(false);

    const userLocationMapping = useSelector(state => state.userLocationMappingReducer.userLocationMapping);

    useEffect(() => {

        const config = localStorage.getItem("_configrations") ? JSON.parse(atob(localStorage.getItem("_configrations"))) : {};
        const opsAdminUserIds = config?.opsAdminUserIds || [];
        const valmoOpsAdminUserIds = config?.valmoOpsAdminUserIds || [];
        const user = localStorage.user ? JSON.parse(atob(localStorage.user)) : {};
        
        const userLocationMappingExistsForUser = userLocationMapping && Object.keys(userLocationMapping)?.length > 0;
        var clusterKeys = userLocationMapping ? Object.keys(userLocationMapping) : [];
        var source = 'LSN';

        var valmoManager = false, lsnManager = false, valmoAdmin = false, lsnAdmin = false;

        if (opsAdminUserIds.indexOf(Constants.sessionUser?.id + "") > -1) {
            lsnAdmin = true
            source = 'LSN'
        } 
        else if (valmoOpsAdminUserIds.indexOf(Constants.sessionUser?.id + "") > -1) {
            valmoAdmin = true;
            source = 'VALMO'
        }
        else if (userLocationMappingExistsForUser) {
            if (clusterKeys.length >= 1) {
                const locations = userLocationMapping[clusterKeys[0]];
                if (locations.find(l => l.source == 'LSN')) {
                    lsnManager = true;
                    source = 'LSN';
                }
                else{
                    valmoManager = true;
                    source = 'VALMO';
                }
            }
        }
        

    
        if (valmoManager || lsnManager || valmoAdmin || lsnAdmin) {
            let params = { source, user_id : null };
            if (valmoManager || lsnManager) {
                params.user_id = user?.id
            }
            monitoringReportsService.getEmbeddedURLByPayload({
                reportName: "lm-delivery-conversion-score",
                embedUrlGeneric: {
                    resource : { dashboard : 110  },
                    params
                }
            })
            .then(res => {
                if (res?.data?.response?.embedUrl) {
                    SetEmberUrl(res?.data?.response?.embedUrl);
                }
            })
        }
        else {
            monitoringReportsService.getEmbeddedURL("lm-health-delivery-score-location")
            .then(res => {
                if (res?.data?.response?.embedUrl) {
                    SetEmberUrl(res?.data?.response?.embedUrl);
                }
            })
        }
    }, [])

    return (
        <div className="white-bg">
            {
                embedUrl ?
                <iframe src={`${embedUrl}&refresh=300}`} frameBorder={0} width="100%" style={{ height: "calc(100vh - 180px)" }} /> :
                <Spin spinning={true}></Spin>
            }
        </div>
    )
}