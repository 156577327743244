import { Alert, Modal } from "antd";
import { useEffect, useState } from "react";
import { notifyApiError, notifyApiSuccess } from "../../common/utils/utils";
import ManifestScanHeader from "./components/header";
import ManifestScanForm from "./components/manifest.scan";
import ShipmentScanForm from "./components/shipment.scan";
import ManifestScanSparkline from "./components/sparkline";
import { closeManifest, scanConsignment, scanManifests, unloadManifest, validateManifest } from "./service";
import { SoundNotificationService } from "../../services/sound.service";
import { Constants } from "../../common/utils/constants";

const soundService = new SoundNotificationService();

export default function PrimaryScan() {

    const [manifestDetails, SetManifestDetails] = useState(undefined);
    const [buttonLoading, SetButtonLoading] = useState(false);

    const [scanInProgress, SetScanInProgress] = useState(false);
    const [scanResponse, SetScanResponse] = useState(undefined);

    useEffect(() => {
        if (manifestDetails?.validation && manifestDetails?.validation?.status === false && manifestDetails?.validation?.message) {
            soundService.playGeneralWarning();
            Modal.error({
                title: manifestDetails?.validation?.message
            })
        }
        else if (manifestDetails?.validation && manifestDetails?.validation?.status === true) {
            soundService.playSuccess();
            notifyApiSuccess("Manifest validated.", manifestDetails?.manifest?.code);
        }
    }, [manifestDetails])

    const callScanManifests = manifestCode => {
        if (!manifestCode || manifestCode.trim() === "") {
            soundService.playGeneralWarning();
            return notifyApiError("Manifest ID cannot be empty!");
        }
        SetButtonLoading(true);
        scanManifests(manifestCode.trim())
            .then(SetManifestDetails)
            .catch(_ => SetManifestDetails(undefined))
            .then(_ => SetButtonLoading(false))
    }

    const callUnloadManifest = manifestCode => {
        SetButtonLoading(true);
        unloadManifest(manifestCode)
            .then(SetManifestDetails)
            .catch(_ => SetManifestDetails(undefined))
            .then(_ => SetButtonLoading(false))
    }

    const callValidateManifest = manifestCode => {
        SetButtonLoading(true);
        validateManifest(manifestCode)
            .then(SetManifestDetails)
            .catch(_ => SetManifestDetails(undefined))
            .then(_ => SetButtonLoading(false))
    }

    const callCloseManifest = manifestCode => {
        SetButtonLoading(true);
        closeManifest(manifestCode)
            .then(_ => {
                notifyApiSuccess("Manifest closed succesfully!", manifestCode);
                SetManifestDetails(undefined);
            })
            .catch(e => console.log(e))
            .then(_ => SetButtonLoading(false))
    }

    const callScanConsignment = waybillNo => {
        if (!waybillNo || waybillNo.trim() === "") {
            soundService.playGeneralWarning();
            return notifyApiError("Waybill Number cannot be empty!");
        }
        SetScanInProgress(true);
        const locationOpsType = localStorage?.user ? JSON.parse(atob(localStorage.user))?.location?.locationOpsType : undefined;
        let advancedResponse = locationOpsType == "FM";
        scanConsignment(manifestDetails?.manifest?.code, waybillNo.trim(), advancedResponse)
            .then(response => {
                soundService.playSuccess();
                if (!response.alreadyScanned) {
                    SetManifestDetails(current => {
                        var tempManifest = { ...current };
                        delete tempManifest.validation;
                        tempManifest.scannedCount += 1;
                        if (tempManifest.pendingCount > 0 && ["IN", "INWARD", "RTO_IN", "RTO_INWARD"].indexOf(response?.consignmentStatus) > -1) {
                            tempManifest.pendingCount -= 1;
                        }
                        if (response.consignmentStatus == "OVERAGE") {
                            tempManifest.overageCount += 1;
                        }
                        return tempManifest;
                    });
                }
                SetScanResponse(response);
            })
            .catch(e => {
                soundService.playGeneralWarning();
                SetScanResponse(undefined);
                if (e) {
                    notifyApiError(e.message);
                }
            })
            .then(_ => SetScanInProgress(false))
    }


    return (
        manifestDetails?.manifest?.code ?
            <div className="flex-column flex-gap-m" style={{ padding: 0, margin: 0 }}>
                <ManifestScanHeader manifestDetails={manifestDetails} buttonLoading={buttonLoading}
                    callUnloadManifest={callUnloadManifest} callValidateManifest={callValidateManifest} callCloseManifest={callCloseManifest} />

                <ManifestScanSparkline manifestDetails={manifestDetails} />

                {
                    manifestDetails?.manifest?.status == "UNLOADING" &&
                    <ShipmentScanForm manifestDetails={manifestDetails} callScanConsignment={callScanConsignment}
                     scanResponse={scanResponse} scanInProgress={scanInProgress} />
                }

                {
                    manifestDetails?.manifest?.scannedAtDestination === false && manifestDetails?.manifest?.status == "PENDING" &&
                    <div className="spacer-m">
                        <div className="flex-column flex-gap-l spacer-m white-bg border-radius-m border-grey">
                            <Alert message="Scan Successful" type="success" showIcon className={"success-color"} />
                            <div className="flex-column flex-gap-s">
                                <div className="light-grey-text"> Next Location</div>
                                <div className="font-size-l-3"> {manifestDetails?.manifest?.nextLocation} </div>
                            </div>
                            <div className="flex-column flex-gap-s">
                                <div className="light-grey-text"> Status </div>
                                <div className="font-size-m-3"> {manifestDetails?.manifest?.status} </div>
                            </div>
                        </div>
                    </div>
                }

            </div> :
            <ManifestScanForm callScanManifests={callScanManifests} buttonLoading={buttonLoading} />
    )
}