import { Button, Modal, Spin, Table } from "antd";
import Search from "antd/lib/input/Search";
import React, { useEffect, useState } from "react";
import { capitalizeString } from "../../../../common/utils/utils";
import ManifestReasonDropDown from "../reasons-dropdown";
import * as tripClosureActions from "./service";

export default function TripManifests(props) {

    const [ manifestList, SetManifestList ] = useState(undefined);
    const [ loading, SetLoading ] = useState(true);

    const { modalStatus, reasons, tripId, isMobile } = props;

    const handleReasonUpdate = (reasonId, code) => {
        SetLoading(true);
        let postObject = {
            reasonId     : reasonId,
            manifestCode : code,
            tripId       : tripId
        }
        tripClosureActions.updateManifestReason(postObject)
        .then(_ => {
            fetchTripManifest({ pageNo : manifestList.currentPageNo });
        })
        .catch(response => console.log(response))
    }

    const columns = [
        {
          title     : "Manifest ID",
          dataIndex : "code",
          className : "break-all"
        },
        ...(!isMobile ? [
            {
              title     : "Destination",
              dataIndex : "destination"
            },
            {
              title     : "Pincode",
              dataIndex : "pincode"
            },
            {
                title     : "Quantity",
                dataIndex : "quantity"
            }
        ] : []),
        ...(
            modalStatus !== "SCANNED" ? 
            [
                ...(!isMobile ? [{
                    title     : "Reason",
                    dataIndex : "reason"
                }] : []),
                {
                    title     : "Action",
                    dataIndex : "id",
                    hidden    : false,
                    render    : (data, row) => {
                        return (
                            reasons && modalStatus && reasons[modalStatus.toLowerCase()]?
                            <ManifestReasonDropDown
                                reasons={ reasons[modalStatus.toLowerCase()] }
                                entityType={"MANIFEST"}
                                entityCode={row.code}
                                isMobile={isMobile}
                                reason={row.reason}
                                disabled={row.reason}
                                handleUpdate={handleReasonUpdate}
                            /> :
                            <></>
                        )
                    }
                }
            ] :
            []
        )
    ];

    const fetchTripManifest = params => {
        SetLoading(true);
        tripClosureActions.fetchTripManifest(tripId, modalStatus, params)
        .then(manifestDetails => {
            SetManifestList(manifestDetails);
            SetLoading(false);
        })
    }

    useEffect(fetchTripManifest, []);

    return (
        <Modal visible={typeof modalStatus === "string"} destroyOnClose={true} width={isMobile ? "95%" : 700}
         bodyStyle={{
            padding : "16px 8px"
         }}
         title={modalStatus && capitalizeString(modalStatus.toLowerCase()) + " Manifests"} centered={isMobile}
         closable={true} onCancel={props.closeModal}
         footer={
            <div className="flex-box justify-content-end">
                <Button className="lsn-btn-secondary" onClick={props.closeModal}>
                    Close
                </Button>
            </div>
         }
         >
            <Spin spinning={loading}>
                <div className="flex-column flex-gap-l">
                    <Search
                        placeholder="Search"
                        style={{width: "100%"}}
                        onSearch={value => fetchTripManifest({ manifestCode : value })}
                        enterButton
                        allowClear
                    />
                    <Table
                        columns={columns}
                        dataSource={manifestList?.manifests}
                        bordered={true}
                        pagination={{
                            total     : manifestList?.totalCount,
                            pageSize  : manifestList?.currentPageSize,
                            current   : manifestList?.currentPageNo,
                            onChange  : pageNo => fetchTripManifest({ pageNo: pageNo }),
                            showSizeChanger : false
                        }}
                    />
                </div>
            </Spin>
        </Modal>
    )
}