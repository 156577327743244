import { Button, Icon } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import routes from "../../routes";
import { LAYOUT_CONSTANTS } from "../layouts/state/reducer";

export default function MobileHome(props) {

    const dispatch = useDispatch();

    const configurations = localStorage._configrations ? JSON.parse(atob(localStorage._configrations)) : false;

    const redirectTo = (route, routeObj) => {
        dispatch({
            type    : LAYOUT_CONSTANTS.UPDATE_LAYOUT,
            payload : routeObj
        });
        route = route.indexOf("/:") > 0 ? route.substr(0, route.indexOf("/:")) : route;
        props.history.push(route);
    }

    return (
        <div className="flex-box flex-column flex-gap-l white-bg"
         style={{ height : "calc(100vh - 100px)", width : "100vw", padding: "20px 5px" }}>
            {
                routes.map((route, key) => {
                    if (route.isMobile && route.isRootPage && route.path?.indexOf("mweb") > -1 &&
                         (!route.configKeyCheck || configurations[route.configKeyCheck] == "true")) {
                        return (
                            <Button key={key} onClick={_ => redirectTo(route.path, route)}
                             className="lsn-btn-secondary" style={{height : "auto", padding : 0, borderRadius : 11}}>
                                <div className="flex-box align-items-stretch flex-gap-l justify-content-space-between">
                                    {
                                        route?.imgSrc && 
                                        <div className="flex-1 flex-box justify-content-center align-items-center" style={{
                                            ...route?.iconProps,
                                            borderRadius : "10px 0 0 10px",
                                            padding      : "0 5px"
                                        }}>
                                            <img src={route?.imgSrc} width={"auto"} height={75} />
                                        </div>
                                    }
                                    <div className="flex-column flex-4 spacer-s flex-gap-s" style={{textAlign: "left"}}>
                                        <div className="font-size-m-3"> {route.mwebName ? route.mwebName : route.name} </div>
                                        <div className="grey-text break-word"> {route.description} </div>
                                    </div>
                                    <div className="flex-box align-items-center spacer-xs">
                                        <Icon type="right" />
                                    </div>
                                </div>
                            </Button>
                        )
                    }
                })
            }
        </div>
    )
}