import { USER_LOGGEDIN_INFO } from "../common/utils/constants";
import { expireCookie } from "../common/utils/utils";

export class AuthenticationService {
  static logoutUser(redirectTo) {
    localStorage.clear();
    expireCookie(USER_LOGGEDIN_INFO.LOGGED_IN);
    window.location.href = redirectTo ? `/appv2/login?redirectTo=${window.location.pathname}` : "/appv2/login";
  }
}
