import React, { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Icon } from "antd";
import { LabelSticker } from "../label-sticker/label-sticker";
import { PRINT_ENTITY_TYPES } from "../../constants";
import { downloadFile } from "../../../../../common/utils/utils";

const pageStyle = `@media print {
    @page {
        size    : 3in 2in; 
        margin  : 0; 
        padding : 0;
    }
}`;
function PrintButton({entityData, buttonText, isIconButton=false, isDisabled=false, isAutoPrintEnabled=false, labelPrintingPDFUrl, selectedEntity, afterPrint}) {
    const ref = useRef();
    const handlePrint = useReactToPrint({
        content: () => ref.current,
        pageStyle : pageStyle,
        onAfterPrint : _ => {
            if (typeof afterPrint == "function") {
                afterPrint();
            }
        }
    });

    const handleClick = () => {
        if(selectedEntity == PRINT_ENTITY_TYPES.SHIPMENT_LABEL_PRINT){
            downloadFile(labelPrintingPDFUrl, "_blank");
        }
        else{
            handlePrint();
        }
    }
    useEffect(() => {
        if(entityData && isAutoPrintEnabled){
            handlePrint();
        }
    }, [entityData, isAutoPrintEnabled]);

    return(
        <>
            <div style={{display: "none"}}>
                {
                    entityData && (
                        <LabelSticker ref={ref} entityData={entityData} />
                    )
                }
            </div>
            
            {
                isIconButton ? (
                    <Icon
                        onClick={handleClick}
                        shape="circle-outline"
                        type="printer"
                        theme="twoTone"
                        disabled={isDisabled}
                    />
                ) : (
                    <Button 
                        onClick={handleClick}
                        type="primary"
                        size="large"
                        disabled={isDisabled}
                    >{buttonText}</Button>
                )
            }
            
        </>
    )
};

export default PrintButton;