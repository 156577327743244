import React from 'react';
import "./index.scss";

const DRSSummary = props => {
    const { title, columns, dataSource } = props;
    return (
        <>
            {dataSource && !(_.isEmpty(dataSource)) &&
                <div className="drs-summary grey-bg flex-column flex-gap-l spacer-m border-radius-s" >
                    {title &&
                        <div className="drs-summary-title">
                            {title}
                        </div>
                    }
                    <div className="flex-box flex-gap-m">
                        {columns.map((col, index) => (
                            col.isVisible &&
                            <div key={index} className="flex-column flex-1">
                                <div className="drs-summary-label">
                                    {col.label}
                                </div>
                                <div className="drs-summary-value">
                                    {dataSource[col.key]} {col.suffix ? col.suffix : ''}
                                </div>
                            </div>
                        ))}
                    </div>
                </div >
            }
        </>
    )
}

const DRSCloseSummary = props => {
    const { columns, dataSource } = props;
    return (
        <>
            {dataSource && !(_.isEmpty(dataSource)) &&
                <div className="drs-summary grey-bg flex-column flex-gap-l spacer-m border-radius-s">
                    <div className="flex-grid flex-gap-m">
                        {columns.map((col, index) => (
                            col.isVisible &&
                            <div key={index} className="grid-item flex-1">
                                <div className="drs-summary-label">
                                    {col.label}
                                </div>
                                <div className="drs-summary-value">
                                    {dataSource[col.key]} {col.suffix ? col.suffix : ''}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </>
    )
}

export {
    DRSSummary,
    DRSCloseSummary
}