import bwipjs from 'bwip-js';
import React, { useEffect } from "react";
import Barcode from "../../ui/barcode";


const LabelTemplate = React.forwardRef(({ data, flag }, ref) => {

    useEffect(() => {
        // The return value is the canvas element
        bwipjs.toCanvas('mycanvas', {
            bcid: 'datamatrix',       // Barcode type
            text: data?.waybillNo,    // Text to encode
            scale: 3.5,               // 3x scaling factor
            height: 12,              // Bar height, in millimeters
            includetext: true,            // Show human-readable text
            textxalign: 'center',        // Always good to set this
        });
    }
        , [data])

    return (
        <>
            <div className=" flex-box flex-wrap flex-column justify-content-space-between" ref={ref}>
                <div className=" margin-label spacer-hl">
                    <h1>RVP</h1>
                </div >
                <div className=" text-align-center barcode-margin-bottom" style={{ maxWidth: '100%' }}>
                    <Barcode width={3} format="CODE128"
                        value={data?.waybillNo}
                        showText={true} barCodeId="waybillNo"
                    />
                </div>

                <div className="flex-row  align-items-center " style={{ maxWidth: '100%' }}>
                    <div className='flex-basis-60 margin-label' style={{ maxWidth: '70%' }}>

                        <div className='word-wrap-break-word line-height-1'>
                            <h3>AWB:</h3>
                            <h2><b>{data?.waybillNo}</b></h2>
                        </div>


                        {data?.parentOrderId &&
                            <div className='word-wrap-break-word line-height-1'>
                                <h3>Order ID:</h3>
                                <h2><b>{data?.parentOrderId}</b></h2>
                            </div>
                        }


                        {data?.returnCode &&
                            <div className='word-wrap-break-word line-height-1'>
                                <h3>Return Code:</h3>
                                <h2><b>{data?.returnCode}</b></h2>
                            </div>
                        }

                        {data?.packetId &&
                            <div className='word-wrap-break-word line-height-1'>
                                <h3>Packet ID:</h3>
                                <h2><b>{data?.packetId}</b></h2>
                            </div>
                        }

                    </div>

                    <div className='flex-basis-40' style={{ maxWidth: '30%' }}>
                        <canvas id="mycanvas" className='canvas-align'></canvas>
                    </div>
                </div>

                <div className="margin-label" style={{ wordWrap: 'break-word', maxWidth: '100%' }}>
                    <h3>Ship To:</h3>
                    <h2><b>{data?.sellerName}</b></h2>
                    <h3><b>{data?.shipperAddress}</b></h3>
                </div>
            </div>
        </>

    )

});
export default LabelTemplate;