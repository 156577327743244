import React from "react";
import {
    Button,
    Icon,
} from "antd";

const ManifestUnloadSparkline = ({ title, count, handleSparklineClick, disabled=false, isMobile }) => {
    return(
        <div className="flex-1 unload-sprakiline-button ">
            <Button
                type="default"
                block
                onClick={handleSparklineClick}
                disabled={disabled}
            >
                <div className={isMobile ? "flex-column flex-gap-l" : "flex-box justify-content-space-between"}>
                    <div>{isMobile ? title.split(" ")[0] :title}</div>
                    <div className="flex-box justify-content-space-between align-items-center flex-gap-s">
                        <div className={isMobile ? "font-size-l-1" : undefined}>{count}</div>
                        <Icon type="right"/>
                    </div>
                </div>
            </Button>
        </div>
    )
};

export default ManifestUnloadSparkline;